import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useEffect, useState} from 'react';
import {Polyline, Marker} from 'src/Modules/Native/MapView';
import constants from 'src/constants';

import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {cacheDirections} from 'src/Redux/reducers/user_location.reducer';

interface MapViewDirectionsProps {
  destination: {latitude: number; longitude: number};
  mode?: string;
  originLocation?: {latitude: number; longitude: number} | null;
}

const MapViewDirections: React.FC<MapViewDirectionsProps> = ({
  destination,
  mode = 'walking', // Mapbox uses 'driving', 'walking', 'cycling'
  originLocation = null,
}) => {
  const language = 'en';
  const directionsServiceBaseUrl =
    'https://api.mapbox.com/directions/v5/mapbox';

  // Use your Mapbox API key
  const apiKey =
    'pk.eyJ1IjoibWZoYXJkaW5nIiwiYSI6ImNqbGlicnQ2MzBkeDgzcm9lbnQxb2dhNGoifQ.oWHnl0D4-eSNX-43SHqK8A';
  const [coordinates, setCoordinates] = useState<Array<{
    latitude: number;
    longitude: number;
  }> | null>(null);
  const region = useTypedSelector((state) => state.location?.region);

  const origin = originLocation?.latitude
    ? `${originLocation?.longitude},${originLocation?.latitude}`
    : !!region
    ? `${region?.longitude},${region?.latitude}`
    : null;

  const [mounted, setMounted] = useState<boolean>(true);

  const resetState = () => {
    if (mounted) {
      setCoordinates(null);
    }
  };

  const fetchRoute = async (
    directionsServiceBaseUrl: string,
    origin: string,
    destination: string,
    mode: string,
    language: string,
  ) => {
    try {
      const profile = mode; // Mapbox mode profiles
      const url = `${directionsServiceBaseUrl}/${profile}/${origin};${destination}?geometries=geojson&access_token=${apiKey}&language=${language}`;

      console.log('fetching directions');

      const last10Directions = getReduxState(
        (state) => state.location?.last10Directions,
      );

      const cachedDirections = last10Directions?.find(
        (entry) =>
          entry.originString === origin &&
          entry.destinationString === destination,
      );

      if (cachedDirections) {
        // Use cached directions
        console.log('Using cached directions', {
          cachedDirections,
          origin,
          destination,
        });

        setCoordinates(cachedDirections.directions);
      } else {
        console.log('Not Using cached directions', {
          last10Directions,
          origin,
          destination,
        });
        const response = await fetch(url);
        const json = await response.json();

        if (json.code !== 'Ok') {
          const errorMessage = json.message || 'Unknown error';
          return console.error(`MapViewDirections Error: ${errorMessage}`);
        }

        if (json.routes.length) {
          const route = json.routes[0];
          console.log({route});
          const coordinates = route.geometry.coordinates.map(
            (coord: number[]) => ({
              latitude: coord[1] || 0,
              longitude: coord[0] || 0,
            }),
          );

          setCoordinates(coordinates);

          console.log({coordinates});

          dispatchAction(
            cacheDirections({
              originString: origin,
              destinationString: destination,
              directions: coordinates,
            }),
          );

          console.log('fetchRoute done', {coordinates});
        }

        console.log({directionsJSON: json, destination});
      }
    } catch (fetchRouteError) {
      console.log({fetchRouteError});
    }
  };

  const fetchAndRenderRoute = () => {
    if (!origin || !destination) {
      return;
    }

    console.log('fetchAndRenderRoute');

    const stringDestination = `${destination.longitude},${destination.latitude}`;

    fetchRoute(
      directionsServiceBaseUrl,
      origin,
      stringDestination,
      mode,
      language,
    );
  };

  useEffect(() => {
    fetchAndRenderRoute();

    return () => {
      setMounted(false);
    };
  }, [destination]);

  if (!coordinates) {
    return (
      <Marker
        coordinate={{
          latitude: Number(0),
          longitude: Number(0),
        }}
      />
    );
  }
  
  return (
    <Polyline
      coordinates={coordinates || []}
      strokeWidth={3}
      strokeColor={constants?.color?.orange}
    />
  );
};

export default MapViewDirections;

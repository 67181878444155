// used to get all personal assistant chats
// and community chats

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TPAChatReducerState, TPAChatThread} from 'src/types/TPAChat';

const initialState: TPAChatReducerState = {
  paChats: [],
  currentChat: '',
};

const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    updatePAChats(state, action: PayloadAction<TPAChatThread[]>) {
      state.paChats = action?.payload;
    },
    setCurrentChat(state, {payload: currentChat}: PayloadAction<string>) {
      state.currentChat = currentChat;
    },
  },
});

export const {updatePAChats, setCurrentChat} = chatsSlice.actions;
export default chatsSlice.reducer;

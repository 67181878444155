import useTypedSelector from 'src/Hooks/useTypedSelector';
import React from 'react';
import {View, ScrollView} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import ExpoImage from 'src/Utils/ExpoImage';

///////////////////////////
///////////// components
import {ContinueButton, BodyText, HtmlBlock} from 'src/Modules/CommonView';

///////////////////////////
///////////// utils
import {setLocationCompleteTime} from 'src/Utils/huntFunctions_v2';

import {
  useLocation,
  useLocationId,
  usePercentageComplete,
} from 'src/Hooks/gameHooks';

import {AnimatedPointsEarned} from 'src/Modules/CommonView/Text';
import ChallengeFeedbackElement from 'src/ScavengerHunt_v2/Game/ChallengeFeedbackElement';

const LocationComplete: React.FC = () => {
  const {width} = useWindowDimensionsSafe();

  const allChallenges =
    useTypedSelector((state) => state.game_v2?.allChallenges) || {};
  const groupPhoto = useTypedSelector((state) => state.group?.info?.groupPhoto);

  const locationId = useLocationId();

  console.log({locationId});

  const location = useLocation();

  const groupPointsEarned = location?.groupPointsEarned ?? 0;
  const challengeList = location?.challengeList ?? [];
  const photoLarge = location?.photoLarge;
  const postContent = location?.postContent;

  const challengeScore =
    (challengeList || []).reduce((score, key) => {
      const {playerPointsEarned = 0, groupPointsEarned = 0} =
        allChallenges[key] || {};
      return score + (playerPointsEarned || 0) + (groupPointsEarned || 0);
    }, 0) || 0;

  const score = groupPointsEarned + challengeScore;

  console.log('LocationComplete Render', {
    groupPointsEarned,
    challengeScore,
    score,
  });

  const percentageComplete = usePercentageComplete();

  const largeDisplayedPhoto = photoLarge
    ? `https://photos.letsroam.com${photoLarge && photoLarge.substring(1)}`
    : groupPhoto;

  return (
    <ScrollView style={{flex: 1, backgroundColor: 'white'}}>
      <View style={{flex: 1, paddingBottom: 60, backgroundColor: 'white'}}>
        <View
          style={{
            alignItems: 'center',
            paddingVertical: 20,
            position: 'relative',
          }}>
          {!!largeDisplayedPhoto && (
            <ExpoImage
              style={{
                width: width - 20,
                height: 200,
              }}
              source={{uri: largeDisplayedPhoto}}
              resizeMode={'cover'}
            />
          )}
        </View>
        <BodyText
          text={'Congratulations!'}
          textStyle={{
            fontSize: 20,
            lineHeight: 22,
            fontWeight: '700',
            textAlign: 'center',
          }}
        />
        <BodyText
          text={`You have finished ${percentageComplete}% of your hunt. Your team finished this location with:`}
          textStyle={{
            fontWeight: '700',
            textAlign: 'center',
            fontSize: 16,
          }}
        />
        {score > 0 && <AnimatedPointsEarned pointsEarned={score || 0} />}
        <View style={{width: width - 40, marginHorizontal: 20}}>
          {!!postContent && (
            <HtmlBlock
              html={
                '<b style="font-weight: bold;">Did You Know</b>: ' + postContent
              }
              htmlStyle={{fontSize: 16, lineHeight: 18}}
              replaceText={''}
            />
          )}
          <View style={{alignItems: 'center'}}>
            <ContinueButton
              text={'Continue'}
              containerStyle={{width: 220}}
              handlePress={() => {
                locationId && setLocationCompleteTime(locationId);
              }}
            />
          </View>
          <ChallengeFeedbackElement />
        </View>
      </View>
    </ScrollView>
  );
};

export default LocationComplete;

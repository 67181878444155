import {
  ImageBackground,
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import constants from 'src/constants';
import ExpoImage from 'src/Utils/ExpoImage';
import React, {useEffect} from 'react';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {setIntroSliderVisited} from 'src/Redux/reducers/user_info.reducer';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {dispatchAction} from 'src/Utils/helpers';
import getAppType from 'src/Utils/getAppType';

const bgImage = [
  require('src/Images/AppIntroSwiper/appSwiper5.jpg'),
  require('src/Images/AppIntroSwiper/appSwiperAA4.jpg'),
];
const screenIcons = [
  require('src/Images/Icons/closeIconLight.png'),
  require('src/Images/Icons/diamond.png'),
  require('src/Images/Icons/globe.png'),
  require('src/Images/Icons/user.png'),
  require('src/Images/Icons/lr_plus_compass.png'),
  require('src/Images/Icons/chatting.png'),
  require('src/Images/Icons/listView.png'),
  require('src/Images/Icons/3users.png'),
  require('src/Images/Icons/love.png'),
  require('src/Images/Icons/clock_circle2.png'),
  require('src/Images/Icons/badge_percentage.png'),
  require('src/Images/Icons/warning-circular.png'),
];

const FreeTrialScreen = ({onDone = () => {}}) => {
  useEffect(() => {
    //Set that to be false, so that this modal won't show up again.
    //It only loads if a user sees the intro slider and then logs in
    dispatchAction(setIntroSliderVisited(false));
  }, []);

  const playTestState = useTypedSelector(
    (state) => state?.app_info?.playTestState,
  );

  const membershipPrice = getAppType() == 'lr' ? '19.99' : '49.99';

  useEffect(() => {
    if (playTestState !== false) {
      navigationRef.navigate('LandingPageMain', null, 'FreeTrialScreen.tsx');
    }
  }, [playTestState]);
  if (playTestState !== false) return <></>;

  return (
    <ImageBackground
      testID={'freeTrialPrompt'}
      source={getAppType() === 'lr' ? bgImage[0] : bgImage[1]}
      style={{
        flex: 1,
      }}>
      <SafeAreaView style={{flex: 1, alignItems: 'flex-start'}}>
        <TouchableOpacity
          testID="CloseButton"
          onPress={() =>
            navigationRef.navigate(
              'LandingPageMain',
              null,
              'FreeTrialScreen.tsx',
            )
          }>
          <ExpoImage
            style={{width: 24, height: 24, marginLeft: 20, marginTop: 20}}
            source={screenIcons[0]}></ExpoImage>
        </TouchableOpacity>
      </SafeAreaView>
      {getAppType() === 'lr' ? (
        <View
          style={{
            paddingHorizontal: 20,
            bottom: 60,
            position: 'absolute',
            width: '100%',
          }}>
          <ExpoImage
            source={screenIcons[1]}
            style={{width: 29, height: 26, marginBottom: 20}}
          />
          <Text
            style={{
              flex: 1,
              textAlign: 'left',
              fontFamily: 'JakartaBold',
              fontSize: 23,
              lineHeight: 28,
              color: constants?.color?.white,
            }}>
            Travel smarter with Let&apos;s Roam+
          </Text>
          <View style={{gap: 20, marginVertical: 20}}>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[2]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 100,
                  }}>
                  Unlimited in-app activities
                </Text>
              </View>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[3]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 100,
                  }}>
                  Personal travel assistant
                </Text>
              </View>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[4]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 50,
                  }}>
                  Local guide
                </Text>
              </View>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[5]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 100,
                  }}>
                  Local conversations
                </Text>
              </View>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[6]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 100,
                  }}>
                  Curated Wanderlists
                </Text>
              </View>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[7]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 100,
                  }}>
                  Bring up to 5 friends free
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              fontFamily: 'Jakarta',
              fontSize: 15,
              color: constants?.color?.white,
              lineHeight: 22,
              marginBottom: 20,
            }}>
            Auto-renew.{'\n'}
            Cancel anytime.
          </Text>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              fontFamily: 'Jakarta',
              fontSize: 15,
              color: constants?.color?.white,
              lineHeight: 22,
              marginBottom: 20,
            }}>
            Billing starts at the end of the free trial.
          </Text>
          <View style={{flex: 1}}>
            <TouchableOpacity
              onPress={() =>
                navigationRef.navigate(
                  'PurchaseModal',
                  {showMembershipOnly: true},
                  'FreeTrialScreen.tsx',
                )
              }
              style={{
                flex: 1,
                flexDirection: 'column',
                backgroundColor: constants.color.orange,
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5,
                padding: 8,
                borderRadius: 5,
                marginTop: 24,
                paddingVertical: 16,
                elevation: 3,
                shadowColor: 'rgba(167, 168, 170, 1)', //gray2
                shadowRadius: 2,
                shadowOpacity: 0.3,
              }}>
              <Text
                style={{
                  color: constants.color.white,
                  fontSize: 15,
                  fontFamily: 'JakartaBold',
                  textAlign: 'center',
                  height: 40,
                }}>
                Start your 7 day free trial,{'\n'}
                then ${membershipPrice}/month
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              testID="MaybeLater"
              onPress={() => {
                navigationRef.navigate(
                  'LandingPageMain',
                  null,
                  'FreeTrialScreen.tsx 2',
                );
              }}
              style={{
                flex: 1,
                flexDirection: 'column',
                backgroundColor: constants.color.teal,
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5,
                padding: 8,
                borderRadius: 5,
                marginTop: 24,
                paddingVertical: 16,
                elevation: 3,
                shadowColor: 'rgba(167, 168, 170, 1)', //gray2
                shadowRadius: 2,
                shadowOpacity: 0.3,
              }}>
              <Text
                style={{
                  color: constants.color.white,
                  fontSize: 17,
                  fontFamily: 'JakartaBold',
                  textAlign: 'center',
                  height: 30,
                }}>
                Maybe Later
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      ) : (
        <View
          style={{
            paddingHorizontal: 20,
            bottom: 60,
            position: 'absolute',
            width: '100%',
          }}>
          <ExpoImage
            source={screenIcons[1]}
            style={{width: 29, height: 26, marginBottom: 20}}
          />
          <Text
            style={{
              flex: 1,
              textAlign: 'left',
              fontFamily: 'JakartaBold',
              fontSize: 23,
              lineHeight: 28,
              color: constants?.color?.white,
            }}>
            Start your membership today
          </Text>
          <View style={{gap: 20, marginVertical: 20}}>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[3]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 120,
                  }}>
                  Dedicated {'\n'} personal assistant
                </Text>
              </View>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[8]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 120,
                  }}>
                  Personalized recommendations
                </Text>
              </View>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[9]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 100,
                  }}>
                  Spend less {'\n'} time planning
                </Text>
              </View>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[10]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 120,
                  }}>
                  Save money with {'\n'} price comparisons
                </Text>
              </View>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[6]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 100,
                  }}>
                  Bespoke itineraries
                </Text>
              </View>
              <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                <View
                  style={{
                    borderColor: constants?.color?.white,
                    borderRadius: 30,
                    height: 30,
                    width: 30,
                    borderWidth: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <ExpoImage
                    style={{height: 15, width: 15}}
                    resizeMode="contain"
                    source={screenIcons[11]}></ExpoImage>
                </View>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.white,
                    textAlign: 'center',
                    width: 100,
                  }}>
                  Near 24/7 support
                </Text>
              </View>
            </View>
          </View>
          <Text
            style={{
              flex: 1,
              textAlign: 'center',
              fontFamily: 'Jakarta',
              fontSize: 15,
              color: constants?.color?.white,
              lineHeight: 22,
              marginBottom: 20,
            }}>
            Auto-renew.{'\n'}
            Cancel anytime.
          </Text>
          <View style={{flex: 1}}>
            <TouchableOpacity
              onPress={() =>
                navigationRef.navigate(
                  'PurchaseModal',
                  {showMembershipOnly: true, handleDone: () => onDone()},
                  'FreeTrialScreen.tsx',
                )
              }
              style={{
                flex: 1,
                flexDirection: 'column',
                backgroundColor: '#648A87',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5,
                padding: 8,
                borderRadius: 5,
                marginTop: 24,
                paddingVertical: 20,
                marginBottom: 30,
              }}>
              <Text
                style={{
                  color: constants.color.white,
                  fontSize: 15,
                  fontFamily: 'JakartaBold',
                  textAlign: 'center',
                }}>
                Become a member
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
    </ImageBackground>
  );
};

export default FreeTrialScreen;

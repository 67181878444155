import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect, useRef, useState} from 'react';
import {View, Text} from 'react-native';

import {BodyText} from 'src/Modules/CommonView/Text';

import {TouchableOpacity} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/FontAwesome';
import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';
// import {RtcSurfaceView} from 'src/Modules/Native/Agora';
import {THostMessage} from 'src/types/THostMessage';

// const channel = 'test';

// https://ottverse.com/free-hls-m3u8-test-urls/

interface HostChatButtonProps {
  peerIds?: number[]; // Assuming peerIds are an array of strings. Adjust if needed.
  onPress: () => void; // Assuming onPress is a function without arguments. Adjust if needed.
  messageData: {[key: string]: THostMessage}; // Assuming it's an object with string keys. Adjust the value type if needed.
  successfullyConnected?: boolean; // As a default value is provided, it's an optional prop.
}

const HostChatButton: React.FC<HostChatButtonProps> = ({
  peerIds = [],
  onPress,
  messageData,
  successfullyConnected = false,
}) => {
  const hostName = useTypedSelector((state) => state.event?.info?.hostName);
  const [newMessage, setNewMessage] = useState<THostMessage | null>(null);

  const userId = useTypedSelector((state) => state.user?.userId);
  const previousLength = useRef<number>(0);
  const messageDataObject = Object.values(messageData || {}).sort((a, b) => {
    return (a.sentAt || 0) > (b.sentAt || 0) ? 1 : -1;
  });
  useEffect(() => {
    if (
      !!previousLength?.current &&
      messageDataObject?.length != previousLength.current
    ) {
      console.log('message data has changed', {
        messageDataLength: messageDataObject?.length,
        previousLength: previousLength.current,
        messageDataObject,
      });
      const newMessageData = messageDataObject[messageDataObject?.length - 1];
      // navigationRef.devAlert(newMessageData);
      if (newMessageData?.to == 'Everyone' || newMessageData?.to == userId) {
        setNewMessage(newMessageData);
        setTimeout(() => {
          setNewMessage(null);
        }, 2000);
      }
    } else {
      console.log('message data has not changed', {
        messageDataLength: messageDataObject?.length,
        // previousLength: previousLength.current,
        // messageDataObject,
      });
    }
    previousLength.current = messageDataObject?.length;
  }, [messageDataObject?.length]);

  // testing if a modal is open
  const isModal = useTypedSelector(
    (state) =>
      ['FoxShop', 'PhotoVideoCamera', 'QuestionFeedBackModal']?.includes?.(
        state.current_screen?.currentScreen,
      ) ||
      state.current_screen?.currentScreen?.includes('Modal') ||
      state.current_screen?.isDrawerOpen,
  );

  if (!isModal) {
    return (
      <View
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}>
        <TouchableOpacity
          testID="chatOpenButton"
          onPress={() => {
            onPress();
          }}
          // delayLongPress={500}
          // onLongPress={() => {
          //   console.log('on long press');
          // }}
          style={{
            backgroundColor: constants?.color.teal,
            width: 45,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 45,
            left: 7,
            marginLeft: 7,
            marginRight: 7,
            borderRadius: 22.5,
            zIndex: 1,
            elevation: 2,
            borderColor: constants?.color.gray3,
            borderStyle: 'solid',
            borderWidth: successfullyConnected ? 1 : 0,
          }}>
          <Icon style={{}} name={'comment'} size={25} color={'white'} />
        </TouchableOpacity>
        {!!newMessage && <RenderMessage newMessage={newMessage} />}
      </View>
    );
  } else if (!peerIds?.[0] && isModal) {
    return <View></View>;
  }
  return <View></View>;
  console.log('host online', {peerIds});
  // return (
  //   <TouchableOpacity
  //     activeOpacity={0.9}
  //     onPress={() => {
  //       onPress?.();
  //     }}>
  //     <View
  //       style={{
  //         width: 160,
  //         height: 160,
  //       }}>
  //       {peerIds?.map?.((peerId) => {
  //         console.log('RtcRemoteView', {peerId});
  //         return (
  //           <View
  //             key={peerId}
  //             style={{
  //               width: 140,
  //               marginLeft: 10,
  //               height: 160,
  //               position: 'absolute',
  //               top: 0,
  //               left: 0,
  //             }}>
  //             <View
  //               style={{
  //                 width: 140,
  //                 height: 160,
  //                 borderRadius: 20,
  //                 shadowColor: '#000000',
  //                 shadowOpacity: 0.8,
  //                 shadowRadius: 2,
  //                 shadowOffset: {
  //                   height: 1,
  //                   width: 0,
  //                 },
  //               }}>
  //               <View
  //                 style={{
  //                   width: 140,
  //                   height: 160,
  //                   borderRadius: 20,
  //                   overflow: 'hidden',
  //                   position: 'relative',
  //                 }}>
  //                 <RtcSurfaceView
  //                   canvas={{uid: Number(peerId)}}
  //                   style={{
  //                     flex: 1,
  //                     backgroundColor: constants?.color?.orange,
  //                     width: 160,
  //                     height: 160,
  //                   }}
  //                 />
  //                 <SimpleLineIcons
  //                   name={'size-fullscreen'}
  //                   size={12}
  //                   color={constants?.color?.white}
  //                   style={{position: 'absolute', left: 10, top: 10}}
  //                 />
  //               </View>
  //             </View>
  //           </View>
  //         );
  //       })}

  //       <BodyText
  //         text={hostName ? `Your Host: ${hostName}!` : `Your Event Host`}
  //         center
  //         textStyle={{
  //           position: 'absolute',
  //           bottom: 5,
  //           left: 0,
  //           right: 0,
  //           color: 'white',
  //           fontFamily: constants?.font?.circularBold,
  //           fontSize: 14,
  //         }}
  //       />
  //     </View>
  //   </TouchableOpacity>
  // );
};

const RenderMessage = ({newMessage}: {newMessage: THostMessage}) => {
  return (
    <TouchableOpacity
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        width: 300,
        backgroundColor: constants?.color.gray1,
        padding: 10,
        borderRadius: 20,
      }}>
      <Text style={{}}>
        New Message From {newMessage?.fromName} to {newMessage?.toName} -{' '}
        {newMessage?.message}
      </Text>
    </TouchableOpacity>
  );
};

export default HostChatButton;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useCallback} from 'react';
import {View} from 'react-native';

import AudioPlayer from 'src/ScavengerHunt_v2/AudioTour/AudioPlayer';
import AudioMap from 'src/ScavengerHunt_v2/AudioTour/AudioMap';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import AudioChallenge from 'src/ScavengerHunt_v2/AudioTour/AudioChallenge';
import {setDataAtPath} from 'src/Utils/fireUtils';
import {TAudioTourItem} from 'src/types/TAudioTourItem';

const AudioTourMain: React.FC = () => {
  const insets = useSafeAreaInsets();

  const currentAudioTourIndex = useTypedSelector(
    (state) => state?.group?.info?.currentAudioTourIndex || 0,
  );

  const currentAudioTourItem = useTypedSelector(
    (state) => state?.game_v2?.audioTourOrder?.[currentAudioTourIndex] || {},
  ) as TAudioTourItem;

  console.log({currentAudioTourItem, currentAudioTourIndex});

  const groupId = useTypedSelector(
    (state) => state.user?.info?.currentHuntInfo?.groupId,
  );

  const handleNext = useCallback(() => {
    console.log('handleNext fired');
    setDataAtPath(
      `scavengerhunt/groups/${groupId || 'error'}/currentAudioTourIndex`,
      currentAudioTourIndex + 1,
    );
  }, [currentAudioTourIndex]);

  const handleBack = () => {
    console.log('handleBack fired');
    setDataAtPath(
      `scavengerhunt/groups/${groupId || 'error'}/currentAudioTourIndex`,
      currentAudioTourIndex - 1,
    );
  };

  if (!currentAudioTourItem) {
    return <></>;
  }

  return (
    <View style={{flex: 1, paddingBottom: insets.bottom}}>
      <AudioMap />

      <AudioChallenge
        key={JSON.stringify(currentAudioTourItem)}
        currentAudioTourItem={currentAudioTourItem}
        handleNext={handleNext}
      />
      <AudioPlayer
        // key={JSON.stringify(currentAudioTourItem)}
        currentAudioTourIndex={currentAudioTourIndex}
        currentAudioTourItem={currentAudioTourItem}
        handleNext={handleNext}
        handleBack={handleBack}
      />
    </View>
  );
};

export default AudioTourMain;

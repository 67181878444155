import constants from 'src/constants';
import {Alert, Platform, Linking} from 'react-native';

import {store} from 'src/Redux/ReduxStore';

import {crashlytics} from 'src/Utils/db';
import {setUseLocalHost} from 'src/Redux/reducers/app_info.reducer';

import * as StoreReview from 'expo-store-review';
import {RootState} from 'src/Redux/reducers/rootReducer';
import {AnyAction} from '@reduxjs/toolkit';

export const dispatchAction = (action: AnyAction) => {
  console.log('dispatchAction: ', action?.type);
  store.dispatch(action);
};

export const fetchWithTimeout = async (
  url: string,
  options: RequestInit,
  timeout: number,
) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(url, {...options, signal: controller.signal});
    clearTimeout(timeoutId);
    return response;
  } catch (error: unknown) {
    clearTimeout(timeoutId);
    throw error;
  }
};

export const checkIfLocalServerAvailable = async () => {
  if (__DEV__) {
    console.log('testing if local host is available on dev');

    const url = 'https://letsroam.local.com/api/test';
    const options: RequestInit = {
      // Your other options here
    };

    const currentUseLocalHost = getReduxState(
      (state) => state.app_info.useLocalHost,
    );

    try {
      const localHostApi = await fetchWithTimeout(url, options, 10000);
      console.log({localHostApi});
      !currentUseLocalHost && dispatchAction(setUseLocalHost(true));
    } catch (error: unknown) {
      console.log('localHostApi ping failed code', {error});
      !!!currentUseLocalHost && dispatchAction(setUseLocalHost(false));
    }
  }
};

export const appStoreRatingRequest = async () => {
  console.log('asking for app store rating');

  const isAvailable = await StoreReview.isAvailableAsync();
  if (isAvailable) {
    StoreReview?.requestReview?.();
  } else {
    const reviewUrl =
      Platform.OS == 'ios'
        ? 'https://itunes.apple.com/us/app/lets-roam-scavenger-hunts/id1338916789?ls=1&mt=8'
        : 'market://details?id=com.barhuntv2&hl=en_US';
    Alert.alert(
      'LEAVE REVIEW',
      'We are glad you enjoyed the hunt. Can you please leave us a review?',
      [
        {text: 'Maybe Later', onPress: () => null},
        {
          text: 'Yes',
          onPress: () => Linking?.openURL(reviewUrl),
        },
      ],
      {cancelable: false},
    );
  }
};

export const logForCrashlytics = async (log: string, log_2: unknown = '') => {
  console.log(log, log_2);
  try {
    crashlytics()?.log?.(log || 'No log info passed');
  } catch (crashlyticsError) {
    console.error({crashlyticsError});
    if (__DEV__) {
      console.log('logForCrashlytics', log);
      Alert.alert('Could not log crashlyticsError log' + log);
    }
  }
};

export function ordinalSuffixOf(i: number) {
  logForCrashlytics('ordinalSuffixOf fired in helpers');
  const j = i % 10,
    k = i % 100;

  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }

  return i + 'th';
}

export const randomPhotoColor = (altColors?: boolean) => {
  logForCrashlytics('randomPhotoColor fired in helpers');

  const colors = [
    constants?.color?.teal,
    constants?.color?.blue,
    constants?.color?.gray3,
    constants?.color?.gray2,
  ];

  const colors2 = [
    constants?.color?.gray2,
    constants?.color?.teal,
    constants?.color?.blue,
    constants?.color?.orange,
    constants?.color?.yellow,
    constants?.color?.orange,
  ];

  const colorSet = altColors ? colors2 : colors;

  return colorSet[Math?.floor(Math?.random() * colorSet?.length)];
};

export function parseHtmlEntities(str = '') {
  let newString = str;
  newString = newString
    ?.replace('&quot;', "'")
    .replace('&quot;', "'")
    .replace('&quot;', "'")
    .replace('&#7000;', "'")
    .replace('&quot;', "'");
  newString =
    newString?.replace?.(/&#([0-9]{1,3});/gi, function (match, numStr) {
      const num = parseInt(numStr, 10); // read num as normal number
      return String.fromCharCode(num);
    }) || '';

  return unescape(newString);
}

function random(seed = 0) {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

export const hashCode = (str = '') => {
  console.log(str);
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0,
    );
};

type ArrayToSort = {
  key: number;
  [key: string]: unknown; // Allow all other keys with unknown values
};

export function seededShuffle(array: ArrayToSort[] = [], seedSting = '') {
  // <-- ADDED ARGUMENT
  let seed = hashCode(seedSting + 'string');

  let m = array?.length,
    t,
    i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--); // <-- MODIFIED LINE

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed; // <-- ADDED LINE
  }
  array.map?.((item, index) => {
    array[index]['key'] = index;
  });

  return array;
}

export const getReduxState = <T>(
  // this is fine
  // eslint-disable-next-line
  selector: (appState: RootState) => T,
): T | undefined => {
  // get instant point of time state
  const appState: RootState = store.getState();
  // returning the subState with the selector
  return selector(appState);
};
export const isNumeric = (str: string | number | null) => {
  return !isNaN(Number(str));
};

export function isConvertibleToNumber(value: unknown): boolean {
  const number = Number(value);
  return !isNaN(number);
}

export const calculateFormatWalkingTime = (distanceMiles: number): string => {
  if (distanceMiles < 2) {
    // based on avg of 10 minutes to walk 0.6 miles
    const walkingTime = Math?.floor((distanceMiles / 0.6) * 10);
    return `${walkingTime} min walk`;
  } else if (isConvertibleToNumber(distanceMiles)) {
    return `${Math.round(distanceMiles * 10) / 10} miles away`;
  } else {
    return '';
  }
};

// using cloudflare to process images
// and resize them
export const cloudFlareOptimizeImage = (
  imageURI: string = '',
  width = 720,
  quality = 20,
) => {
  let processedImage = imageURI;
  if (
    // ignore already processed images
    !imageURI?.includes('cdn-cgi') &&
    // ignore urls that are too short
    imageURI?.length > 5 &&
    // ignore local urls
    !imageURI?.includes('letsroam.local.com:8888')
  ) {
    processedImage =
      `https://www.letsroam.com/cdn-cgi/image/width=${width},quality=${quality}/` +
      processedImage;
  }
  // console.log({processedImage});
  return processedImage;
};

export default {
  ordinalSuffixOf,
};

export const levenshteinDistance = (str1: string, str2: string): number => {
  const len1 = str1.length;
  const len2 = str2.length;

  // Create a 2D array to store the distances
  const matrix: number[][] = Array.from({length: len1 + 1}, () =>
    Array(len2 + 1).fill(0),
  );

  // Initialize the matrix
  for (let i = 0; i <= len1; i++) {
    matrix[i][0] = i;
  }
  for (let j = 0; j <= len2; j++) {
    matrix[0][j] = j;
  }

  // Fill in the matrix
  for (let i = 1; i <= len1; i++) {
    for (let j = 1; j <= len2; j++) {
      const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
      matrix[i][j] = Math.min(
        matrix[i - 1][j] + 1, // Deletion
        matrix[i][j - 1] + 1, // Insertion
        matrix[i - 1][j - 1] + cost, // Substitution
      );
    }
  }

  // The Levenshtein distance is the value in the bottom-right cell of the matrix
  return matrix[len1][len2];
};

import constants from 'src/constants';
import {Linking} from 'react-native';
import React from 'react';
import {TouchableOpacity, Platform, View} from 'react-native';
import {BodyText} from 'src/Modules/CommonView/Text';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import DelayedLoad from 'src/Nav/DelayedLoad';

const PermissionsWarning: React.FC<{requestPermissions: Function}> = ({
  requestPermissions,
}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <DelayedLoad delay={2000}>
      <View
        style={{
          position: 'absolute',
          top: 120,
          width: width - 80,
          left: 40,
          paddingVertical: 10,
        }}>
        <BodyText
          text={'Error: Camera / Video Permissions Not Given'}
          textStyle={{
            color: constants?.color?.white,
            marginBottom: 50,
            fontSize: 30,
            lineHeight: 30,
          }}
        />
        <TouchableOpacity
          style={{backgroundColor: constants?.color?.teal}}
          onPress={() => {
            Platform.OS === 'ios'
              ? Linking?.openSettings?.().catch((error) =>
                  console.warn('cannot open settings', error),
                )
              : requestPermissions();
          }}
          activeOpacity={0.8}>
          <BodyText
            text={'Open Settings'}
            textStyle={{
              color: constants?.color?.white,
              marginBottom: 10,
              textAlign: 'center',
            }}
          />
        </TouchableOpacity>
      </View>
    </DelayedLoad>
  );
};

export default PermissionsWarning;

import React from 'react';
import {SafeAreaView, Text} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import ExpoImage from 'src/Utils/ExpoImage';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';

const StoryRoundPage: React.FC = () => {
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <ExpoImage
        source={{
          uri: cloudFlareOptimizeImage(
            'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1703866686_original.png',
            600,
          ),
        }}
        style={{width: 200, height: 200}}></ExpoImage>
      <Text
        style={{
          marginTop: 10,
          width: '80%',
          textAlign: 'center',
          fontWeight: '600',
        }}>
        Please stand by while your host makes an announcement
      </Text>
    </SafeAreaView>
  );
};

export default StoryRoundPage;

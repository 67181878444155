import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import {navigationRef} from 'src/Nav/navigationHelpers';
import React, {useEffect} from 'react';

import {Text, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import ExpoImage from 'src/Utils/ExpoImage';
import {getReduxState} from 'src/Utils/helpers';
const logo = require('src/Images/logo.png');
const logo3 = require('src/Images/logo3.png');

const HuntLoading: React.FC = () => {
  const companyURL = useTypedSelector(
    (state) => state.event?.info?.companyURL || '',
  );
  const corporateBrandingUrl = useTypedSelector(
    (state) => state.game_v2?.corporateBrandingUrl || '',
  );
  const eventInfoData = useTypedSelector((state) => state.event?.info);

  let clearbitLogoURL;

  if (window?.location?.href?.includes?.('isDemo')) {
    const urlParams = new URLSearchParams(window.location.search);
    const companyUrlParams = urlParams.get('companyURL');
    clearbitLogoURL = `https://logo.clearbit.com/${companyUrlParams}?size=800`;
  } else {
    clearbitLogoURL = companyURL
      ? `https://logo.clearbit.com/${companyURL}?size=800`
      : '';
  }

  const logoURI = corporateBrandingUrl || clearbitLogoURL;

  const logoURL = logoURI ? {uri: logoURI} : logo;

  const backgroundColor =
    eventInfoData?.branding?.eventColor || constants?.color?.orange;

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentScreen = getReduxState(
        (state) => state?.current_screen?.currentScreen,
      );
      if (currentScreen == 'HuntLoading') {
        navigationRef.navigate(
          'ConfirmModal',
          {
            titleText: `This Is Taking Longer Than Expected`,
            titleBodyText:
              'Please try joining the team again from the main lobby. If this persists, please contact support.',
            confirmText: 'Go Back To Main Screen',
            cancelText: 'Cancel',
            handleConfirm: () => {
              navigationRef?.goBack('ScavengerHunt_v2/HuntLoading.tsx');
              navigationRef?.goBack('ScavengerHunt_v2/HuntLoading.tsx');
              navigationRef.navigate('LandingPageMain', null, 'Hunt Loading');
            },
          },
          'HuntLoading.tsx',
        );
      }
    }, 20 * 1000);

    return () => {
      timeout && clearTimeout(timeout);
    };
  }, []);

  return (
    <SafeAreaView
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: backgroundColor,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <View style={{justifyContent: 'center', alignItems: 'center'}}>
        <ExpoImage
          style={{height: 168.75, width: 300}}
          source={logoURL}
          resizeMode={'contain'}
        />
        <ExpoImage
          style={{height: 95, width: 225, marginTop: 10}}
          source={logo3}
        />
        <Text
          style={{
            marginVertical: 10,
            color: 'white',
            fontSize: 20,
            fontFamily: constants?.font.circular,
          }}>
          Your Scavenger Hunt is Loading
        </Text>
        <CustomActivityIndicator
          size="large"
          color="white"
          style={{marginTop: 30}}
        />
      </View>
    </SafeAreaView>
  );
};

export default HuntLoading;

import React, {useEffect, useState} from 'react';
import {View} from 'react-native';

import {BodyText} from 'src/Modules/CommonView';
import {TChallenge} from 'src/types/TChallenge';

interface LightningTimerProps {
  questionData: TChallenge; // You might want to specify a more specific type for this prop
  onTimeUp?: () => void; // Optional callback when the time is up
}

const LightningTimer: React.FC<LightningTimerProps> = ({
  questionData,
  onTimeUp,
}) => {
  const calculateTimeRemaining = (timerSeconds: number, timerStart: number) => {
    const elapsedTime = (Date.now() - timerStart) / 1000;
    const remainingTime = timerSeconds - elapsedTime;

    // Ensure we don't return negative values
    return remainingTime > 0 ? remainingTime : 0;
  };

  const [timeRemaining, setTimeRemaining] = useState<number>(
    calculateTimeRemaining(
      Number(questionData?.lightningTimerSeconds || 0),
      questionData?.startTime || Date.now(), // Default to current time if missing
    ),
  );

  const calculateBonusPoints = (
    timeRemaining: number,
    timerSeconds: number,
  ) => {
    return Math.max(0, Math.floor((timeRemaining / timerSeconds) * 100));
  };

  const [lightningRoundBonusPoints, setLightningRoundBonusPoints] =
    useState<number>(
      calculateBonusPoints(
        calculateTimeRemaining(
          Number(questionData?.lightningTimerSeconds || 90),
          questionData?.startTime || 90,
        ),
        Number(questionData?.lightningTimerSeconds || 90),
      ),
    );

  let timer: NodeJS.Timeout | undefined = undefined;

  useEffect(() => {
    if (timeRemaining > 0) {
      setTimer(timeRemaining);
    } else if (timeRemaining === 0 && onTimeUp) {
      onTimeUp(); // Call `onTimeUp` if the time is already 0
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  const setTimer = (counter: number) => {
    setTimeRemaining(counter);
    timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          if (onTimeUp) onTimeUp(); // Call the function when time is up
          return 0;
        }
        return prevTime - 1;
      });

      setLightningRoundBonusPoints((prevPoints) =>
        calculateBonusPoints(
          timeRemaining || 0,
          Number(questionData?.lightningTimerSeconds || 90),
        ),
      );

      console.log('timer running');
    }, 1000);
  };

  return (
    <View>
      <BodyText
        text={
          timeRemaining > 0
            ? '⏱️ ' + Math.floor(timeRemaining) + ' seconds left!'
            : 'Time is up!'
        }
        textStyle={{textAlign: 'center'}}
      />
    </View>
  );
};

export default LightningTimer;

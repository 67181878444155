import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect, useState} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {
  TouchableOpacity,
  View,
  TextInput,
  Platform,
  Linking,
  Alert,
} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import * as StoreReview from 'expo-store-review';
import {ContinueButton, HeaderText, BodyText} from 'src/Modules/CommonView';

import {apiCESReview} from 'src/Utils/apiCalls';

import 'react-native-get-random-values';

import isIphoneX from 'src/Utils/IphoneXHelper';
import StarRating from 'src/Modules/Native/StarRating';
import useParams from 'src/Hooks/useParams';
import {navigationRef} from 'src/Nav/navigationHelpers';

const CESModal: React.FC = () => {
  const [feedbackText, setFeedbackText] = useState<string | undefined>();
  const [reviewSubmitLoading, setReviewSubmitLoading] = useState<
    number | undefined
  >();
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const userId = useTypedSelector((state) => state.user?.userId);

  const eventId = useTypedSelector((state) => state.group?.info?.eventId);
  const groupId = useTypedSelector((state) => state.group?.info?.groupId);
  const huntId = useTypedSelector((state) => state.group?.info?.huntId);

  const {
    title = '',
    subTitle = '',
    type = '',
    additionalData = {},
    initialRating,
  } = useParams<'CESModal'>();

  const [starRating, setStarRating] = useState<number | undefined>(
    initialRating,
  );

  console.log({starRating, initialRating});

  useEffect(() => {
    if (initialRating) {
      setStarRating(initialRating);
    }
  }, [initialRating]);

  const appStoreRatingRequest = async () => {
    console.log('asking for app store rating');

    const isAvailable = await StoreReview.isAvailableAsync();
    if (isAvailable) {
      StoreReview?.requestReview?.();
    } else {
      const reviewUrl =
        Platform.OS == 'ios'
          ? 'https://itunes.apple.com/us/app/lets-roam-scavenger-hunts/id1338916789?ls=1&mt=8'
          : 'market://details?id=com.barhuntv2&hl=en_US';
      Alert.alert(
        'LEAVE REVIEW',
        'We are glad you enjoyed the hunt. Can you please leave us a review?',
        [
          {text: 'Maybe Later', onPress: () => null},
          {
            text: 'Yes',
            onPress: () => Linking?.openURL(reviewUrl),
          },
        ],
        {cancelable: false},
      );
    }
  };

  const handleReviewSubmit = async () => {
    if (feedbackText && !starRating) {
      Alert.alert('A star rating is required when you leave feedback text!');
    }

    ////////// handle empty feedback here...
    if (starRating) {
      setReviewSubmitLoading(1);
      try {
        const params = {
          groupId,
          eventId,
          userId: userId,
          huntId: String(huntId),
          r_metric: String(starRating),
          type,
          additionalComments: feedbackText,
          additionalData,
          play_test_state: !!playTestState ? 1 : 0,
        };

        console.log({params});

        const response = await apiCESReview(params);

        response && setReviewSubmitLoading(0);
        navigationRef?.goBack('Modules/CESModal.tsx');
        if (starRating === 5) {
          appStoreRatingRequest();
        }
      } catch (error: unknown) {
        console.log({error});
        setReviewSubmitLoading(0);
        navigationRef?.goBack('Modules/CESModal.tsx');
      }
    } else {
      console.log('No star rating');
    }
  };

  if (playTestState) {
    navigationRef?.goBack('Modules/CESModal.tsx');
    return <View></View>;
  }

  return (
    <SafeAreaView style={{flex: 1}}>
      <View
        style={{
          alignItems: 'center',
          position: 'relative',
          marginTop: isIphoneX() ? 60 : 30,
        }}>
        <View
          style={{
            minHeight: 300,
            width: 300,
            backgroundColor: 'white',
            alignItems: 'center',
          }}>
          {!!title && (
            <HeaderText
              text={title}
              textStyle={{fontSize: 24, marginTop: 10}}
              bodyStyle={{width: 240}}
            />
          )}
          <BodyText text={subTitle} textStyle={{fontSize: 18, marginTop: 10}} />

          <StarRating
            containerStyle={{marginBottom: 10, marginTop: 20}}
            disabled={false}
            maxStars={5}
            startingValue={initialRating}
            rating={starRating}
            onFinishRating={(star_rating: number) => setStarRating(star_rating)}
            fullStarColor={constants?.color?.yellow}
            emptyStarColor={constants?.color?.gray2}
          />
          <TextInput
            style={{
              backgroundColor: constants?.color?.white1,
              color: constants?.color?.gray3,
              height: 150,
              justifyContent: 'center',
              marginTop: 20,
              paddingHorizontal: 10,
              paddingVertical: 10,
              textAlignVertical: 'top',
              width: 250,
            }}
            allowFontScaling={false}
            editable={true}
            maxLength={400}
            multiline={true}
            placeholder={
              'What did you like/dislike about this question/challenge/item?'
            }
            onChangeText={(text) => setFeedbackText(text)}
            placeholderTextColor={constants?.color?.gray2}
            underlineColorAndroid={'white'}
            blurOnSubmit
          />
          <View
            style={{
              flexDirection: 'row',
              height: 50,
              marginHorizontal: 10,
              marginTop: 30,
              marginBottom: 0,
            }}>
            <ContinueButton
              text={'Submit Feedback'}
              containerStyle={{
                backgroundColor: constants?.color?.orange,
                height: 40,
                minWidth: 200,
              }}
              handlePress={() => handleReviewSubmit()}
              loading={!!reviewSubmitLoading}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              height: 50,
              marginTop: 5,
              marginHorizontal: 10,
              marginBottom: 10,
            }}>
            <ContinueButton
              text={'Skip'}
              testID={'skipCESModal'}
              containerStyle={{
                backgroundColor: constants?.color?.gray3,
                height: 40,
                minWidth: 200,
              }}
              handlePress={() => navigationRef?.goBack('Modules/CESModal.tsx')}
            />
          </View>
          <TouchableOpacity
            onPress={() => navigationRef?.goBack('Modules/CESModal.tsx')}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: 50,
              height: 50,
              padding: 10,
            }}>
            <Icon name="times" size={25} color={constants?.color?.orange} />
          </TouchableOpacity>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default CESModal;

import React, {useEffect, useState} from 'react';
import {
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  Text,
  Platform,
} from 'react-native';
import {BodyText, TextEntry} from 'src/Modules/CommonView';

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import {useUserId} from 'src/Hooks/reduxHooks';
import {updateDataAtPath} from 'src/Utils/fireUtils';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {TAPAChatMessage, TPAChatThread} from 'src/types/TPAChat';
import ExpoImage from 'src/Utils/ExpoImage';
import {openExternalLink, parseMessageForLinks} from 'src/Utils/chatHelper';
import getAppType from 'src/Utils/getAppType';
import { Path, Svg } from 'react-native-svg';

const signInImage = require('src/Images/signInImage.png');
const submitArrow = require('src/Images/compass.png');
const submitArrowAA = require('src/Images/compass2.png');

export default function PAChat() {
  const userId = useUserId();
  const chatId = useTypedSelector((state) => state.chats?.currentChat || []);
  const [threadDate, setThreadDate] = useState('');

  const [request, setRequest] = useState<string>('');
  const firstName = useTypedSelector(
    (state) => state.user?.info?.firstName || 'test',
  );

  const userPhoto = useTypedSelector(
    (state) => state.user?.info?.customPhotoUrl || state.user?.info?.photoUrl,
  );

  const userChats = useTypedSelector((state) => state.chats?.paChats || []);

  const currentThread = userChats.find((chat) => chat.UUID === chatId);

  const isLR = getAppType() === 'lr';

  const sendMessage = async () => {
    if (!request || !currentThread) {
      return console.log('no request');
    }

    if (!userId) {
      return console.log('no userId');
    }

    const now = Date.now();

    const newMessage: TAPAChatMessage = {
      sentAt: now,
      from: userId,
      fromName: firstName,
      fromImage: userPhoto,
      message: request,
      UUID: `${now}-${userId}`,
      createdAt: now,
      photoAttachment: '',
    };

    const data = [...currentThread.messages, newMessage];

    const updatePath = `paChats/${chatId || 'error'}/messages`;
    updateDataAtPath(`${updatePath || 'error'}`, data);

    setRequest('');
  };

  useEffect(() => {
    if (!!currentThread?.messages && !!currentThread?.messages.length) {
      const date = new Date(currentThread.createdAt || '');
      setThreadDate(
        date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hourCycle: 'h12',
        }),
      );
    }
  }, [currentThread]);

  return (
    <View
      style={{
        backgroundColor: isLR ? 'transparent' : '#F9F7F2',
        flex: 1,
        flexDirection: 'column',
        paddingTop: 28,
        paddingHorizontal: 12,
      }}>
      <ScrollView
        style={{flex: 1, maxHeight: '85%'}}
        contentContainerStyle={{flexGrow: 1}}>
        <Text
          style={{
            opacity: 0.3,
            fontSize: 12,
            fontFamily: constants?.font?.Jakarta,
            textAlign: 'center',
          }}>
          {threadDate}
        </Text>
        {currentThread?.messages?.map((message) => {
          return (
            <UserMessage
              key={message.fromName + message.sentAt}
              title={message.message}
              userId={message.from}
              currentThread={currentThread}
              userPhoto={message.fromImage}
              fromName={message.fromName}
              attachedPhoto={message.photoAttachment}
            />
          );
        })}
      </ScrollView>

      {currentThread?.status?.toLowerCase() !== 'completed' ? (
        <View
          style={{
            position: 'absolute',
            bottom: 20,
            width: '100%',
            alignSelf: 'center',
          }}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              margin: 0,
              padding: 0,
            }}>
            <View
              style={{
                backgroundColor: 'white',
                borderRadius: 8,
                flex: 1,
              }}>
              <TextEntry
                testID={'search locations'}
                value={request}
                handleChange={(request) => setRequest(request)}
                containerStyle={{
                  backgroundColor: 'transparent',
                  height: 50,
                }}
                inputStyle={{
                  backgroundColor: 'white',
                  textAlign: 'left',
                  paddingHorizontal: 10,
                  marginTop: 10,
                  borderRadius: 8,
                }}
              />
            </View>
            <TouchableOpacity
              style={{
                backgroundColor: constants?.color?.white,
                height: 50,
                marginHorizontal: 5,
                borderRadius: 10,
                justifyContent: 'center',
                alignItems: 'center',
                marginVertical: 5,
                width: 50,
                minWidth: 50,
              }}
              onPress={() => {
                sendMessage();
              }}>
              <Image
                source={isLR ? submitArrow : submitArrowAA}
                style={{width: 50, height: 50, resizeMode: 'contain'}}
              />
            </TouchableOpacity>
          </View>
        </View>
      ) : (
        <View style={{maxHeight: 50, height: '100%', justifyContent: 'center',alignItems:'center',flexDirection:'row',gap:5}}>
          {Platform.OS === 'web' ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none">
              <path
                d="M11.575 2.7L10.175 1.3C10.1283 1.25419 10.0654 1.22853 10 1.22853C9.93457 1.22853 9.87174 1.25419 9.82501 1.3L3.92501 7.2C3.87828 7.24581 3.81545 7.27146 3.75001 7.27146C3.68457 7.27146 3.62174 7.24581 3.57501 7.2L2.17501 5.8C2.12828 5.75419 2.06545 5.72853 2.00001 5.72853C1.93457 5.72853 1.87174 5.75419 1.82501 5.8L0.42501 7.2C0.379204 7.24673 0.353546 7.30956 0.353546 7.375C0.353546 7.44044 0.379204 7.50327 0.42501 7.55L3.57501 10.7C3.62174 10.7458 3.68457 10.7715 3.75001 10.7715C3.81545 10.7715 3.87828 10.7458 3.92501 10.7L11.575 3.05C11.6208 3.00327 11.6465 2.94044 11.6465 2.875C11.6465 2.80956 11.6208 2.74673 11.575 2.7Z"
                fill="#EACD72"
              />
            </svg>
          ) : (
            <Svg width={12} height={12} viewBox="0 0 12 12" fill="none">
              <Path
                d="M11.575 2.7L10.175 1.3C10.1283 1.25419 10.0654 1.22853 10 1.22853C9.93457 1.22853 9.87174 1.25419 9.82501 1.3L3.92501 7.2C3.87828 7.24581 3.81545 7.27146 3.75001 7.27146C3.68457 7.27146 3.62174 7.24581 3.57501 7.2L2.17501 5.8C2.12828 5.75419 2.06545 5.72853 2.00001 5.72853C1.93457 5.72853 1.87174 5.75419 1.82501 5.8L0.42501 7.2C0.379204 7.24673 0.353546 7.30956 0.353546 7.375C0.353546 7.44044 0.379204 7.50327 0.42501 7.55L3.57501 10.7C3.62174 10.7458 3.68457 10.7715 3.75001 10.7715C3.81545 10.7715 3.87828 10.7458 3.92501 10.7L11.575 3.05C11.6208 3.00327 11.6465 2.94044 11.6465 2.875C11.6465 2.80956 11.6208 2.74673 11.575 2.7Z"
                fill="#EACD72"
              />
            </Svg>
          )}
          <Text
            style={{
              textAlign: 'center',
              alignSelf: 'center',
              fontFamily: 'JakartaBold',
              color: '#EACD72',
            }}>
            Request Complete
          </Text>
        </View>
      )}
    </View>
  );
}

interface UserMessageProps {
  title: string;
  userId: string;
  userPhoto?: string | null;
  fromName: string;
  currentThread: TPAChatThread;
  attachedPhoto: string;
}

const UserMessage = ({
  title = '',
  userId = '',
  userPhoto = '',
  fromName = '',
  currentThread,
  attachedPhoto = '',
}: UserMessageProps) => {
  const [messageFragments, urlKeys] = parseMessageForLinks(title);

  const isLR = getAppType() === 'lr';

  return (
    <View style={{display: 'flex', alignItems: 'flex-start'}}>
      {attachedPhoto ? (
        <TouchableOpacity
          style={{width: '75%', marginTop: 20, height: 200}}
          onPress={() => openExternalLink(attachedPhoto)}>
          <ExpoImage
            style={{
              width: '100%',
              height: '100%',
              borderRadius: 8,
              backgroundColor: isLR ? '#53A6C4' : '#97838C',
            }}
            resizeMode="contain"
            source={{uri: attachedPhoto}}
          />
        </TouchableOpacity>
      ) : (
        <View
          style={{
            backgroundColor:
              userId === currentThread?.userId
                ? isLR
                  ? '#6AAEAA'
                  : '#648A87'
                : isLR
                ? '#53A6C4'
                : '#97838C',
            paddingVertical: 17,
            paddingHorizontal: 10,
            borderRadius: 8,
            alignSelf:
              userId === currentThread?.userId ? 'flex-end' : 'flex-start',
            display: 'flex',
            marginTop: 12,
            maxWidth: '75%',
          }}>
          <Text>
            {(messageFragments as string[]).map((message, ix) => {
              if ((urlKeys as number[]).includes(ix)) {
                return (
                  <Text key={'msg-' + ix + message}>
                    <Text>
                      <TouchableOpacity
                        onPress={() => openExternalLink(message)}>
                        <Text
                          style={{
                            textDecorationLine: 'underline',
                          }}>
                          {message}
                        </Text>
                      </TouchableOpacity>
                    </Text>
                  </Text>
                );
              } else {
                return (
                  <Text
                    key={'msg-' + ix + message}
                    style={{
                      color: 'white',
                      fontSize: 15,
                      fontWeight: '400',
                      marginTop: 0,
                      marginBottom: 0,
                      fontFamily: 'Jakarta',
                    }}>
                    {message}
                  </Text>
                );
              }
            })}
          </Text>
        </View>
      )}
      {userId !== currentThread?.userId && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 8,
          }}>
          <Image
            source={userPhoto ? userPhoto : signInImage}
            style={{width: 30, height: 30, resizeMode: 'cover'}}
          />
          <BodyText
            text={(fromName ? fromName : 'Joyce') + ", Let's Roam"}
            textStyle={{
              color: 'black',
              fontSize: 11,
              fontWeight: '400',
              marginTop: 5,
              marginLeft: 5,
              fontFamily: 'Jakarta',
            }}
          />
        </View>
      )}
    </View>
  );
};

// this reduce is for player challenges

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TChallenge} from 'src/types/TChallenge';
import {TPhoto} from 'src/types/TPhoto';

interface PlayerChallengeState {
  playerChallengeData: Record<string, TChallenge>;
  playerChallengePhotos: TPhoto[];
  playerChallengeDataActive: TChallenge[];
  playerChallengeDataCompleted: TChallenge[];
  playerChallengeDataActiveCount: number;
  playerChallengeDataCount: number;
}

const initialState: PlayerChallengeState = {
  playerChallengeData: {},
  playerChallengePhotos: [],
  playerChallengeDataActive: [],
  playerChallengeDataCompleted: [],
  playerChallengeDataActiveCount: 0,
  playerChallengeDataCount: 0,
};

const gameSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    updatePlayerChallengeData(
      state,
      action: PayloadAction<PlayerChallengeState>,
    ) {
      console.log('updatePlayerChallengeData', action);
      return {...state, ...action.payload};
    },
  },
});

export const {updatePlayerChallengeData} = gameSlice.actions;
export default gameSlice.reducer;

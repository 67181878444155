import * as t from 'io-ts';
import {validateResult} from 'src/types/typesHelpers';

// io-ts type for Theme
const TThemeIO = t.type({
  theme: t.string,
  text: t.string,
  short_description: t.string,
  description: t.string,
  coin_price: t.number,
  show_in_backpack: t.boolean,
  published: t.boolean,
  icon_source: t.string,
  default_players: t.number,
  characters: t.array(t.string),
  dollar_price: t.number,
});

// Derive the TypeScript type from the io-ts type for Theme
export type TTheme = t.TypeOf<typeof TThemeIO>;

// io-ts type for Character
const TCharacterIO = t.intersection([
  t.type({
    theme: t.string,
    character: t.string,
    description: t.string,
    source: t.string,
  }),
  t.partial({
    role_type: t.string,
  }),
]);

// Derive the TypeScript type from the io-ts type for Character
export type TCharacter = t.TypeOf<typeof TCharacterIO>;

// io-ts type for ThemesState
const TThemesStateIO = t.type({
  themes: t.record(t.string, TThemeIO),
  characters: t.record(t.string, TCharacterIO),
});

// Derive the TypeScript type from the io-ts type for ThemesState
export type TThemesState = t.TypeOf<typeof TThemesStateIO>;

// Type guard for runtime validation for Theme
export const isTTheme = (input: unknown): input is TTheme => {
  return validateResult(input, TThemeIO, 'TTheme', false);
};

// Type guard for runtime validation for Character
export const isTCharacter = (input: unknown): input is TCharacter => {
  return validateResult(input, TCharacterIO, 'TCharacter', false);
};

// Type guard for runtime validation for ThemesState
export const isTThemesState = (input: unknown): input is TThemesState => {
  return validateResult(input, TThemesStateIO, 'TThemesState', false);
};

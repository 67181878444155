import React from 'react';
import {ImageSourcePropType, Text, View} from 'react-native';
import constants from 'src/constants';
import ExpoImage from 'src/Utils/ExpoImage';

type EmptyDataCardProps = {
  title: string;
  icon: ImageSourcePropType;
  description: string;
  backgroundColor?: string;
};

const EmptyDataCard = ({
  title,
  icon,
  description,
  backgroundColor = constants.color.orange,
}: EmptyDataCardProps) => {
  return (
    <View
      style={{
        backgroundColor,
        marginHorizontal: 24,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        gap: 8,
        height: 282,
        marginTop: 20,
      }}>
      {!!icon && (
        <ExpoImage
          resizeMode={'cover'}
          source={icon}
          style={{
            width: 30,
            height: 30,
            marginHorizontal: 38,
            marginTop: 10,
          }}
        />
      )}
      <Text
        style={{
          fontSize: 20,
          color: constants.color.white,
          paddingHorizontal: 40,
          fontFamily: 'JakartaBold',
          textAlign: 'center',
        }}>
        {title}
      </Text>
      <Text
        style={{
          fontSize: 15,
          color: constants.color.white,
          paddingHorizontal: 40,
          fontFamily: 'Jakarta',
          lineHeight: 24,
          textAlign: 'center',
        }}>
        {description}
      </Text>
    </View>
  );
};

export default EmptyDataCard;

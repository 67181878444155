/* eslint-disable dot-notation */
import {Alert} from 'react-native';

import {navigationRef} from 'src/Nav/navigationHelpers';
import {setUTMInfo} from 'src/Redux/reducers/app_info.reducer';
import {setEventId} from 'src/Redux/reducers/event.reducer';
import {getAuth} from 'src/Utils/db';
import {
  getDataAtOrderBy,
  getDataAtPath,
  logCustomAnalyticsEvent,
  setDataAtPath,
} from 'src/Utils/fireUtils';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {
  firebaseFindUserByContactEmail,
  loginAndCreateUser,
} from 'src/Utils/loginFunctions';
import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {setCurrentHunt} from 'src/Redux/reducers/hunts.reducer';
import {FirebaseDynamicLinksTypes} from '@react-native-firebase/dynamic-links';
import {TNavigationRouteParams} from 'src/types/TNavigationRouteParams';
import {THunt} from 'src/types/THunt';

const extractParams = (formattedURL: string | {url: string}) => {
  // fixing different formatting
  let extractedURL;
  if (typeof formattedURL === 'string') {
    extractedURL = formattedURL;
  } else {
    extractedURL = formattedURL?.url;
  }

  // getting the url parameters
  let match;
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const params: Record<string, string> = {};

  while ((match = regex.exec(extractedURL))) {
    params[match[1]] = match[2];
  }

  console.log({params});
  return params;
};

export async function loginAdminTestUser() {
  console.log('loginAsTestUser received');
  mmkvStorage.set('hasViewedTutorial', 'true');
  console.log('copilot: blocked because loginAdminTestUser');
  const testEmail =
    'test' + Math.round(Math.random() * 10000000) + '@letsroam.com';
  const oldUser = await firebaseFindUserByContactEmail(testEmail);
  console.log({oldUser});
  if (oldUser?.userId) {
    await setDataAtPath('users/' + oldUser?.userId, null);
  }

  navigationRef.navigate('LandingPageMain', null, 'loginAdminTestUser');

  await loginAndCreateUser({
    email: testEmail,
    contactEmail: testEmail,
    contactPhone: null,
    firstName: 'Michael',
    lastName: 'Harding',
  });
}

export async function handleTestingDeepLinks(formattedURL: string) {
  console.log('handleTestingDeepLinks fired', {formattedURL});

  const potentialLoginUrl = formattedURL;
  console.log({potentialLoginUrl});

  const params = extractParams(formattedURL);

  // very generic deep link script
  if (params['action'] == 'test_admin_login') {
    return loginAdminTestUser();
  } else if (getAuth().isSignInWithEmailLink(potentialLoginUrl)) {
    const email = await mmkvStorage.getString('email');
    if (!email) {
      console.error('email not found');
      navigationRef.simpleAlert('Error', 'Email not found, please try again.');
      return;
    }

    const firstName = await mmkvStorage.getString('firstName');
    const lastName = await mmkvStorage.getString('lastName');
    const phone = await mmkvStorage.getString('phone');
    console.log('is login deep link', {
      formattedURL,
      email,
      firstName,
      lastName,
      phone,
    });

    const newUser = await getAuth().signInWithEmailLink(
      email,
      potentialLoginUrl,
    );

    const user = newUser?.user;
    if (!user) {
      Alert.alert('login error', 'No User');
    } else {
      const formattedUser = {
        ...user,
        firstName,
        lastName,
        contactEmail: user.email || email,
        contactPhoneNonLogin: phone,
      };
      console.log('isSignInWithEmailLink', {formattedUser});
      Alert.alert('Success', `You have been logged in as ${email}`);
      loginAndCreateUser(formattedUser);
    }

    console.log({newUser, user});
  }
}

export async function handleDeepLinkNavigation(formattedURL: string) {
  console.log('handleDeepLinkNavigation fired', {formattedURL});

  // getting the url parameters
  const params = extractParams(formattedURL);

  // very generic deep link script
  if (params['alert_title'] && params['alert_message']) {
    setTimeout(() => {
      let navigationParams = {};
      try {
        if (params['nav_params']) {
          const parsedParams = params['nav_params']
            .replace(/\%22/gi, '"')
            .replace(/\%7B/gi, '{')
            .replace(/\%7D/gi, '}');
          // Alert.alert(parsedParams);
          console.log({parsedParams});
          navigationParams = JSON.parse(parsedParams);
        }
      } catch (error: unknown) {
        console.log({error});
        // Alert.alert('', error instanceof Error ? error.message : String(error),);
      }

      navigationRef.navigate(
        params['navigate'] as keyof TNavigationRouteParams,
        navigationParams,
        'Utils/deepLinkHelper.ts',
      );
      Alert.alert(
        params['alert_title'].replace(/\+/gi, ' ').replace(/\_/gi, ' '),
        params['alert_message'].replace(/\+/gi, ' ').replace(/\_/gi, ' '),
      );
    }, 1000);
  } else if (params['type'] == 'letsroam_local') {
    setTimeout(() => {
      navigationRef.navigate('LocalTab', null, 'Utils/deepLinkHelper.ts');
    }, 500);
  } else if (params['action'] == 'voucher_redemption') {
    setTimeout(() => {
      navigationRef.navigate(
        'GrouponRedemption',
        {},
        'Utils/deepLinkHelper.ts',
      );
    }, 500);
  } else if (params['action'] == 'go_to_hunt') {
    setTimeout(() => {
      const huntsList = getReduxState((state) => state?.hunts?.huntsList);
      console.log({huntsList});
      const location = huntsList?.find(
        (hunt) => hunt.hunt_id == params?.huntId,
      );
      if (!location) {
        console.error('location not found');
        return;
      }
      dispatchAction(setCurrentHunt(location));
      navigationRef.navigate(
        'LandingPageScavengerHuntInfoModal',
        null,
        'deepLinkHelper 1',
      );
      logCustomAnalyticsEvent('Scavenger_hunt_view', location);
    }, 500);
  } else if (params['join_code']) {
    console.log('join code detected');
    mmkvStorage.set('join_code', params['join_code']);
    mmkvStorage.set('lastEnteredCode', params['join_code']);

    try {
      setTimeout(() => {
        navigationRef.navigate(
          'JoinHuntModal',
          {
            voucherCode: params['join_code'],
          },
          'deepLinkHelper',
        );
      }, 2000);

      setTimeout(async () => {
        try {
          const initialDeepLinkEventData = await getDataAtPath(
            `customEvents/${params['join_code']}`,
          );
          if (initialDeepLinkEventData) {
            dispatchAction(setEventId(params['join_code']));
          }

          const initialDeepLinkEventData2 = await getDataAtOrderBy({
            path: 'customEvents',
            orderBy: 'joinCode',
            equalTo: params['join_code'],
            type: 'custom_event_code',
          });
          if (initialDeepLinkEventData2) {
            dispatchAction(setEventId(params['join_code']));
          }
          console.log({initialDeepLinkEventData2, initialDeepLinkEventData});
        } catch (initialEventIdError) {
          console.log({initialEventIdError});
        }
      }, 500);
    } catch (error: unknown) {
      if (__DEV__) {
        Alert.alert(error instanceof Error ? error.message : String(error));
      }
    }
  } else if (params['hunt_id']) {
    console.log('join code detected');
    mmkvStorage.set('hunt_id', params['hunt_id']);
    try {
      setTimeout(() => {
        const hunts = getReduxState((state) => state?.hunts?.huntsList);
        const newCurrentHunt: THunt | null =
          hunts?.find((hunt) => hunt.hunt_id == params['hunt_id']) || null;

        if (!newCurrentHunt) {
          console.error('newCurrentHunt not found', {
            newCurrentHunt,
            hunts,
            params,
          });
        }

        dispatchAction(setCurrentHunt(newCurrentHunt));

        navigationRef.navigate(
          'LandingPageScavengerHuntInfoModal',
          null,
          'deepLinkHelper 2',
        );
      }, 2000);
    } catch (error: unknown) {
      if (__DEV__) {
        Alert.alert(error instanceof Error ? error.message : String(error));
      }
    }
  }
}

const parseQueryString = (str: string) => {
  try {
    console.log('parseQueryString', {str});
    const params = str
      .split('&')
      .map((pair) => pair.split('='))
      .reduce((acc: Record<string, string>, [key, value]) => {
        acc[decodeURIComponent(key)] = decodeURIComponent(value);
        return acc;
      }, {});

    console.log({params});
    return params;
  } catch (parseQueryStringError) {
    console.log({parseQueryStringError});
    return {};
  }
};

export function handleDeepLinkTracking(
  deepLink: FirebaseDynamicLinksTypes.DynamicLink,
) {
  console.log('handleDeepLinkTracking', {deepLink});
  try {
    if (deepLink) {
      const formattedDeepLink = deepLink;
      console.log({
        formattedDeepLink,
        deepLinkLink: deepLink?.url,
        deepLinkType: typeof formattedDeepLink,
      });
      const queryString = formattedDeepLink?.url?.split?.('?')?.[1];
      const params = parseQueryString(queryString) as Record<string, string>;
      console.log({params});
      const utm_source =
        params?.utm_source ?? deepLink?.utmParameters?.utm_source;
      const utm_medium =
        params?.utm_medium ?? deepLink?.utmParameters?.utm_medium;
      const utm_campaign =
        params?.utm_campaign ?? deepLink?.utmParameters?.utm_campaign;
      const utm_term = params?.utm_term ?? deepLink?.utmParameters?.utm_term;

      console.log('handleDeepLinkTracking utm_source', {utm_source});

      if (utm_medium) {
        mmkvStorage.set('utm_medium', utm_medium);
      }

      if (utm_campaign) {
        mmkvStorage.set('utm_campaign', utm_campaign);
      }
      if (utm_source) {
        mmkvStorage.set('utm_source', utm_source);
      }

      if (utm_term) {
        mmkvStorage.set('utm_term', utm_term);
      }
      console.log('UTM Info:', {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_term,
      });
      dispatchAction(
        setUTMInfo({utm_source, utm_medium, utm_campaign, utm_term}),
      );
    }
  } catch (utmTrackingError) {
    console.error('utm tracking error', {utmTrackingError});
  }
}

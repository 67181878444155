import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {View, Image, TouchableHighlight, Text, ViewStyle} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import Icon from 'react-native-vector-icons/SimpleLineIcons';
import ChatBox from 'src/Modules/GuardianAngel/ChatBox';
import EventLeaderboardCompact from 'src/CustomEvents/EventLeaderboardCompact';
import {setHostMaximized} from 'src/Redux/reducers/event.reducer';

import {TouchableOpacity} from 'react-native-gesture-handler';
import {navigationRef} from 'src/Nav/navigationHelpers';
// import {RtcSurfaceView} from 'src/Modules/Native/Agora';
import {THostMessage} from 'src/types/THostMessage';
import {dispatchAction} from 'src/Utils/helpers';

// const channel = 'test';

// https://ottverse.com/free-hls-m3u8-test-urls/

interface HostFullScreenProps {
  peerIds?: number[]; // Assuming peerIds are an array of strings. Adjust if needed.
  messageData?: {[key: string]: THostMessage}; // Assuming it's an object with string keys. Adjust the value type if needed.
}

const HostFullScreen: React.FC<HostFullScreenProps> = ({
  peerIds = [],
  messageData = {},
}) => {
  const {width, height} = useWindowDimensionsSafe();
  const handleBack = () => {
    console.log('go back pressed');
    dispatchAction(setHostMaximized(false));
  };

  console.log('HostFullScreen', {peerIds, messageData});

  const appCurrentScreenOverride = useTypedSelector(
    (state) => state.event?.info?.appCurrentScreenOverride,
  );

  // navigationRef.devAlert(messageData);

  // const hostOnline = !!peerIds?.[0];
  const hostOnline = !!peerIds?.[0];

  const showLeaderBoard =
    !hostOnline || appCurrentScreenOverride?.includes('leader');

  const showFullScreenHost = !showLeaderBoard && hostOnline;

  const showLeaderboardHost = !!showLeaderBoard && hostOnline;

  const hostStyle: ViewStyle = showLeaderboardHost
    ? {
        position: 'absolute',
        top: 100,
        right: 0,
        width: 120,
        height: 120,
        borderWidth: 2,
        borderColor: 'white',
      }
    : {flex: 1};

  return (
    <View
      style={{
        flex: 1,
        width,
        height,
        position: 'relative',
        backgroundColor: !!showFullScreenHost
          ? 'rgba(83, 166, 196, 1)'
          : 'white',
        paddingBottom: hostOnline ? 40 : 40,
      }}>
      <View style={{flex: 1}}>
        <View
          style={{
            height: 100,
            backgroundColor: constants?.color.orange,
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            flexDirection: 'row',
            paddingBottom: 20,
            paddingHorizontal: 30,
          }}>
          <TouchableOpacity
            onPress={() => handleBack()}
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              marginTop: 15,
            }}>
            <Icon
              name={'arrow-left-circle'}
              size={25}
              color={constants?.color?.white}
            />
            <Text style={{color: 'white'}}>Go Back</Text>
          </TouchableOpacity>
          <TouchableHighlight onPress={() => handleBack()} style={{}}>
            <Image
              style={{
                height: 40,
                width: 90,
                resizeMode: 'contain',
                bottom: -5,
              }}
              source={require('src/Images/logo3.png')}
            />
          </TouchableHighlight>
          <TouchableOpacity
            onPress={() => {
              navigationRef.navigate(
                'LandingPageMain',
                null,
                'Modules/GuardianAngel/HostFullScreen.tsx EventLobby press',
              );
              setTimeout(() => {
                navigationRef.navigate(
                  'EventHome',
                  null,
                  'Modules/GuardianAngel/HostFullScreen.tsx',
                );
              }, 500);

              dispatchAction(setHostMaximized(false));
            }}
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              paddingTop: 10,
            }}>
            <Icon name={'home'} size={20} color={constants?.color?.white} />
            <Text style={{color: 'white'}}>Event Lobby</Text>
          </TouchableOpacity>
        </View>
        {!!showLeaderBoard && <EventLeaderboardCompact />}
        {/* {!!hostOnline && (
          <View style={hostStyle}>
            {peerIds?.map?.((peerId) => {
              console.log('RtcRemoteView', {peerId});
              return (
                <View
                  key={peerId}
                  style={{
                    width: showLeaderBoard ? 116 : width,
                    flex: 1,
                  }}>
                  <RtcSurfaceView
                    canvas={{uid: Number(peerId)}}
                    style={{
                      flex: 1,
                      backgroundColor: constants?.color?.orange,
                      width: showLeaderBoard ? 116 : width,
                      height: showLeaderBoard ? 116 : height,
                    }}
                  />
                </View>
              );
            })}
          </View> */}
        {/* )} */}
      </View>
      <ChatBox
        displayInline={!!showFullScreenHost}
        messageData={messageData}
        hostOnline={!!showFullScreenHost}
      />
    </View>
  );
};

export default HostFullScreen;

import constants from 'src/constants';
import React, {useState} from 'react';

import {Alert, View} from 'react-native';

import {HeaderText} from 'src/Modules/CommonView';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import Photos from 'src/Modules/CommonGame/Photos';

import {ContinueButton} from 'src/Modules/CommonView/Buttons';

import {navigationRef} from 'src/Nav/navigationHelpers';

import useParams from 'src/Hooks/useParams';
import {SafeAreaView} from 'react-native-safe-area-context';
import {SimpleHeaderBar} from 'src/HeaderComponent/HeaderComponents';

const PhotosModal: React.FC = () => {
  const {
    photoData,
    title = 'Your Hunt Photos',
    selectable = false,
    handleSelectionDone,
    initialSelection = [],
  } = useParams<'PhotosModal'>();

  const {width} = useWindowDimensionsSafe();
  const [selection, setSelection] = useState<number[]>(initialSelection);

  const displayedPhotoData = photoData;

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: 'transparent',
        alignItems: 'center',
      }}>
      <SimpleHeaderBar showBack />
      <View
        style={{
          flex: 1,
          width,
          backgroundColor: constants?.color?.white,
        }}>
        <HeaderText text={title} center fullWidth={width} />
        <Photos
          photoData={displayedPhotoData}
          selection={selection}
          handleBack={() => navigationRef.goBack('PhotosModal')}
          selectable={selectable}
          handleSelection={(indexSelectedPhoto) => {
            const newSelection = [...(selection || [])];
            const alreadySelected =
              newSelection?.includes?.(indexSelectedPhoto);
            // remove item if already in their
            if (alreadySelected) {
              const removeIndex = newSelection.indexOf(indexSelectedPhoto);
              console.log({removeIndex});
              if (removeIndex > -1) {
                newSelection.splice(removeIndex, 1);
              }
            } else {
              newSelection?.length > 2 && newSelection?.pop?.();
              newSelection.push(indexSelectedPhoto);
            }
            console.log({newSelection, alreadySelected});
            setSelection(newSelection);
          }}
          hideClose
        />
        {!!selectable && (
          <ContinueButton
            containerStyle={{
              width: width - 60,
              marginTop: 10,
              marginBottom: 20,
              marginHorizontal: 30,
            }}
            text={`Confirm Photos`}
            handlePress={() => {
              if (selection?.length < 1) {
                return Alert.alert('Please select at least 1 photo.');
              }
              handleSelectionDone?.(selection);
              navigationRef?.goBack?.('PhotoModal');
            }}
          />
        )}
      </View>
    </SafeAreaView>
  );
};

export default PhotosModal;

import {registerRootComponent} from 'expo';
import React from 'react';
import {LogBox, Platform, View} from 'react-native';
import {useFonts} from 'expo-font';
import CircularStdBook from 'src/Fonts/CircularStd-Book.otf';
import ReduxStore from 'src/Redux/ReduxStore';
import * as SplashScreen from 'expo-splash-screen';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {StatusBar} from 'expo-status-bar';
import {GestureHandlerRootView} from 'react-native-gesture-handler';
import App from 'src/App';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import constants from 'src/constants';
import {crashlytics} from 'src/Utils/db';
import {
  addToErrorHistory,
  addToLogHistory,
} from 'src/Redux/reducers/logs.reducer';
import {Text} from 'react-native';

// @ts-ignore
Text.defaultProps = Text.defaultProps || {};
// @ts-ignore
Text.defaultProps.allowFontScaling = false;

console.log('app compiling');

if (LogBox && LogBox.ignoreAllLogs) {
  LogBox.ignoreAllLogs();
}

LogBox.ignoreLogs([
  "[react-native-gesture-handler] Seems like you're using an old API with gesture components, check out new Gestures system!",
  'Non-serializable values were found in the navigation state. Check:',
]);

let log;
if (!log) {
  log = console.log;
  console.log = function (a, b) {
    try {
      const aString =
        typeof a == 'object' ? JSON.stringify(a)?.substring(0, 200) : String(a);
      crashlytics?.().log?.(aString || '');
      if (
        __DEV__ ||
        (Platform.OS == 'web' && window?.location?.href?.includes('show'))
      ) {
        b ? log?.(a, b) : log?.(a);
      }
    } catch (e) {
      log && log({a, b, e});
    }
  };

  let error;
  if (!error) {
    error = console.error;

    console.error = function (a, b) {
      try {
        // const aString =
        //   typeof a == 'object' ? JSON.stringify(a)?.substring(0, 200) : a;
        // crashlytics?.().log?.(aString);
        if (typeof error == 'function') {
          const aText =
            (typeof a == 'object' ? JSON.stringify(a) : String(a)) +
            (typeof b == 'object' ? JSON.stringify(b) : String(b));
          if (
            aText?.includes?.('Cannot update a component') || // not really an error
            aText?.includes?.('preventSym') || // not really an error
            aText?.includes?.('Invariant Violation') ||
            aText?.includes?.('The user canceled ') ||
            aText?.includes?.('google sign in error') ||
            aText?.includes?.('Warning: Failed ') ||
            aText?.includes?.(
              'VirtualizedLists should never be nested inside',
            ) ||
            aText?.includes?.('MapViewDirections') ||
            aText?.includes?.('Cannot update a component') ||
            aText?.includes?.('Warning: Failed prop type')
          ) {
            // not showing errors that are not real
            return;
          }

          dispatchAction(addToErrorHistory({a, b}));
          error(a, b?.substring?.(0, 1000));
        }
      } catch (e) {
        log && log({a, b, e});
      }
    };
  }

  let warn;
  if (!warn) {
    warn = console.warn;

    console.warn = function (a, b) {
      try {
        // const aString =
        //   typeof a == 'object' ? JSON.stringify(a)?.substring(0, 200) : a;
        // crashlytics?.().log?.(aString);
        if (typeof warn == 'function') {
          const aText =
            (typeof a == 'object' ? JSON.stringify(a) : String(a)) +
            (typeof b == 'object' ? JSON.stringify(b) : String(b));
          if (
            aText?.includes?.(
              'Non-serializable values were found in the navigation state.',
            )
          ) {
            // not showing errors that are not real
            return;
          }

          warn(a, b);
        }
      } catch (e) {
        log && log({a, b, e});
      }
    };
  }

  console.log_on_production = function (a, b) {
    try {
      const aString =
        typeof a == 'object'
          ? JSON.stringify(a)?.substring(0, 1000)
          : String(a);
      const bString =
        typeof b == 'object'
          ? JSON.stringify(b)?.substring(0, 1000)
          : String(b);
      crashlytics?.().log?.(aString + (bString || ''));
      dispatchAction(addToLogHistory({a, b}));
      b ? log?.(a, b) : log?.(a);
    } catch (e) {
      log && log({a, b});
    }
  };

  if (!__DEV__) {
    console.warn('logs suppressed because production');
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000, // 24 hours
    },
  },
});

if (SplashScreen && SplashScreen.preventAutoHideAsync) {
  SplashScreen.preventAutoHideAsync();
}

const Index = () => {
  console.log('Index.js is rendering');

  const [fontsLoaded] = useFonts({
    'CircularStd-Book': require('src/Fonts/CircularStd-Book.otf'),
    'CircularStd-Bold': require('src/Fonts/CircularStd-Bold.otf'),
    'Alternate Gothic No3 D': require('src/Fonts/AlternateGothic.otf'),
    Jakarta: require('src/Fonts/PlusJakartaSans.ttf'),
    JakartaBold: require('src/Fonts/PlusJakartaSansBold.ttf'),
    Inter: require('src/Fonts/Inter.ttf'),
  });

  if (!fontsLoaded && Platform.OS !== 'web') {
    return <View />;
  }

  const GestureWrapper = Platform.OS !== 'web' ? GestureHandlerRootView : View;

  return (
    <GestureWrapper style={{flex: 1, backgroundColor: constants.color.orange}}>
      <QueryClientProvider client={queryClient}>
        <ReduxStore>
          <App />
        </ReduxStore>
      </QueryClientProvider>
      <StatusBar style="auto" />
    </GestureWrapper>
  );
};

registerRootComponent(Index);

if (Platform.OS == 'web') {
  if (typeof window !== 'undefined') {
    window.state = () => {
      return getReduxState((state) => state);
    };
  }
}

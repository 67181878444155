import React, {useEffect, useState} from 'react';
import {
  SafeAreaView,
  Text,
  View,
  useWindowDimensions,
  ScrollView,
  ImageBackground,
  FlatList,
  TouchableOpacity,
  Alert,
} from 'react-native';

import {joinHunt} from 'src/Utils/huntFunctions';

import constants from 'src/constants';
import {ContinueButton} from 'src/Modules/CommonView';
// import {PlacedTeam} from 'src/CustomEvents/EventLeaderboardCompact';
import ExpoImage from 'src/Utils/ExpoImage';

import useTypedSelector from 'src/Hooks/useTypedSelector';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {TGroupInfo} from 'src/types/TGroupInfo';
import {TSimpleGroupInfo} from 'src/types/TSimpleGroup';
import {updateDataAtPath} from 'src/Utils/fireUtils';
const signInImage = require('src/Images/signInImage.png');

interface GroupWithRank extends TSimpleGroupInfo {
  rank?: number;
  deleted?: boolean;
}

const EventLeaderboardHybrid = ({
  showDeletedTeams,
}: {
  showDeletedTeams: boolean;
}) => {
  console.log('EventLeaderboardHybrid render');
  const {width} = useWindowDimensions();

  const groups = useTypedSelector((state) => state.event?.groups);

  const userId = useTypedSelector((state) => state.user?.userId);

  const event = useTypedSelector((state) => state.event);

  const [paginatedIndex, setPaginatedIndex] = useState(5);

  const [leaderboard, setLeaderboard] = useState<GroupWithRank[]>([]);

  useEffect(() => {
    if (!!groups && groups.length > 0) {
      let tmpRank = 1;
      setLeaderboard([
        ...groups
          .slice()
          .filter((a) => !!showDeletedTeams || !showDeletedTeams)
          .sort((a, b) => {
            const aScore = a?.score && a?.score > 0 ? 1 * a?.score : 0;
            const bScore = b?.score && b?.score > 0 ? 1 * b?.score : 0;
            if (aScore < bScore) {
              return 1;
            }
            if (bScore < aScore) {
              return -1;
            }
            return 0;
          })
          .map((group, ix, leaderboard) => {
            //add a rank to each leaderboard user
            const formattedGroup: GroupWithRank = {...group, rank: tmpRank};
            if (
              ix === 0 ||
              (formattedGroup?.score || 0) < (leaderboard[ix - 1]?.score || 0)
            ) {
              formattedGroup.rank = ix + 1;
              tmpRank = ix + 1;
            } else {
              const previousGroup = leaderboard[ix - 1] as GroupWithRank;
              formattedGroup.rank = previousGroup.rank || tmpRank;
            }
            return formattedGroup;
          }),
      ]);
    }
  }, [groups, showDeletedTeams]);

  const boxShadow = {
    shadowColor: 'rgba(104, 88, 46, 0.20)',
    elevation: 6,
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 1,
    shadowRadius: 12,
  };

  const onNameChangeSubmit = async (
    newName: string,
    handleClose: () => void,
    selectedGroup: TSimpleGroupInfo,
  ) => {
    if (!newName) {
      return Alert.alert('A team name is required! Think of something fun!');
    }
    console.log('onNameChangeSubmit fired');
    const {groups} = event;
    const allNames =
      groups && groups?.map?.((group) => (group.teamName + '').toLowerCase?.());

    //console.log('all the team names are: ', allNames)

    if (newName?.length > 20) {
      return Alert.alert('The name is too long!');
    } else if (
      allNames &&
      allNames?.includes?.((newName + '').toLowerCase?.())
    ) {
      return Alert.alert('The name is already taken!');
    }
    await updateDataAtPath(
      `scavengerhunt/groups/${selectedGroup.groupId}/teamName`,
      newName,
    );
    handleClose();
  };

  const showChangeName = (selectedGroup: TSimpleGroupInfo) => {
    const teamName = selectedGroup?.teamName || '';
    console.log({selectedGroup, teamName});
    navigationRef.navigate(
      'ConfirmModal',
      {
        titleText: `What do you want to name your group?`,
        titleBodyText:
          'Pick a fun and exciting name that will help your group stand out from the pack!',
        confirmText: 'Set Team Name',
        showPrompt: true,
        maxLength: 40,
        cancelText: 'Cancel',
        initialPromptText: teamName == "Your Team's Name" ? '' : teamName,
        handleConfirm: (newName, __, handleClose) => {
          newName &&
            handleClose &&
            onNameChangeSubmit(newName, handleClose, selectedGroup);
        },
      },
      'EventLeaderBoard 123451234',
    );
  };

  const formatName = (winner: TGroupInfo) => {
    if (!winner) return 'Group';
    return winner?.teamName ? winner.teamName : 'Group';
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <ScrollView style={{flex: 1}}>
        <View style={{justifyContent: 'center', width: width}}>
          {!leaderboard ||
            (!leaderboard.length && (
              <ImageBackground
                testID="LeaderboardWrapper"
                source={require('src/Images/stylized_bg.png')}
                resizeMode={'stretch'}
                imageStyle={{
                  tintColor: 'black',
                }}
                style={{
                  flex: 1,
                  height: 250,
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  marginBottom: -30,
                  backgroundColor: constants.color.teal,
                }}>
                <Text
                  style={{marginTop: 10, fontFamily: 'Jakarta', fontSize: 20}}>
                  Leaderboard empty
                </Text>
              </ImageBackground>
            ))}
          {!!leaderboard && !!leaderboard.length && (
            <FlatList
              scrollEnabled={false}
              data={leaderboard?.filter((_, ix) => ix < paginatedIndex)}
              style={{
                paddingTop: 20,
                paddingBottom: paginatedIndex < leaderboard?.length ? 20 : 50,
              }}
              renderItem={({item}) => (
                <TouchableOpacity
                  onPress={() => {
                    Alert.alert('', 'Additional Options', [
                      {
                        text: 'Join Team',
                        onPress: async () => {
                          joinHunt(item?.groupId, userId, 'Joining Group');
                        },
                      },
                      {
                        text: 'Change Name',
                        onPress: () => showChangeName(item),
                      },
                      {
                        text: item?.deleted ? 'Un-delete Team' : 'Delete Team',
                        onPress: async () => {
                          await updateDataAtPath(
                            `scavengerhunt/groups/${
                              item?.groupId || 'error'
                            }/deleted`,
                            !item?.deleted,
                          );
                        },
                      },
                      {text: 'Cancel', onPress: () => {}},
                    ]);
                  }}>
                  <View
                    style={{
                      backgroundColor: !!item?.deleted
                        ? constants.color.red
                        : constants.color.white,
                      marginHorizontal: 40,
                      paddingHorizontal: 15,
                      marginBottom: 10,
                      flex: 1,
                      flexDirection: 'row',
                      minHeight: 72,
                      borderRadius: 10,
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      ...boxShadow,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        flex: 4,
                        gap: 15,
                        alignItems: 'flex-start',
                      }}>
                      <ExpoImage
                        source={item?.groupPhoto || signInImage}
                        style={{
                          height: 35,
                          width: 35,
                          borderRadius: 35,
                        }}></ExpoImage>
                      <View style={{gap: 5}}>
                        <Text
                          style={{
                            fontSize: 16,
                            fontFamily: 'Jakarta',
                            marginTop: -2,
                          }}>
                          {`${formatName(item)}${
                            !!item?.deleted ? ' (DELETED)' : ''
                          }`}
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            fontFamily: 'Jakarta',
                            color: !!item?.deleted
                              ? constants.color.white
                              : constants?.color?.gray2,
                          }}>
                          {item?.score?.toLocaleString() || 0} points
                        </Text>
                      </View>
                    </View>
                    <View style={{flex: 1, alignItems: 'center'}}>
                      <Text
                        style={{
                          fontFamily: 'JakartaBold',
                          color: !!item?.deleted
                            ? constants.color.white1
                            : constants?.color?.gray3,
                          fontSize: 24,
                        }}>
                        {item.rank}
                      </Text>
                    </View>
                  </View>
                </TouchableOpacity>
              )}
              keyExtractor={(_, index) => index.toString()}
            />
          )}
          {leaderboard?.length > 2 && paginatedIndex < leaderboard?.length && (
            <ContinueButton
              textStyle={{paddingHorizontal: 24}}
              handlePress={() => setPaginatedIndex((prev) => prev + 5)}
              buttonStyle={{minHeight: 50, marginHorizontal: 100}}
              containerStyle={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 40,
              }}
              text="Show more"
            />
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default EventLeaderboardHybrid;

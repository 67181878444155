import constants from 'src/constants';
import React from 'react';
import {ImageSourcePropType, View, ViewStyle} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import ExpoImage from 'src/Utils/ExpoImage';
import isIphoneX from 'src/Utils/IphoneXHelper';
import {BodyText} from 'src/Modules/CommonView/Text';
import {ImageButton} from 'src/Modules/CommonView/Buttons';

export const FooterOverlay: React.FC<{
  children?: React.ReactNode;
  footerStyle?: ViewStyle;
}> = ({children, footerStyle = {}}) => {
  return (
    <View
      style={{
        alignItems: 'center',
        backgroundColor: constants?.color?.white_50,
        bottom: isIphoneX() ? 32 : 0,
        flexDirection: 'row',
        justifyContent: 'center',
        left: 0,
        minHeight: 70,
        paddingHorizontal: 10,
        paddingVertical: 10,
        position: 'absolute',
        right: 0,
        ...footerStyle,
      }}>
      {children}
    </View>
  );
};

export const BarChallengeHeader: React.FC<{
  height?: number;
  challengeType?: string;
  source: ImageSourcePropType;
  title?: string;
  handleCancel?: () => void;
  containerStyle?: ViewStyle;
}> = ({
  height = 100,
  challengeType = 'dare',
  source,
  title = '',
  handleCancel = () => null,
  containerStyle = {},
}) => {
  const challengeIcon =
    challengeType == 'dare'
      ? require('src/Images/dareIcon.png')
      : challengeType == 'tournament'
      ? require('src/Images/tournamentIcon.png')
      : challengeType == 'video'
      ? require('src/Images/videoIcon.png')
      : challengeType == 'photo'
      ? require('src/Images/photoIcon.png')
      : require('src/Images/dareIcon.png');
  const {width} = useWindowDimensionsSafe();
  return (
    <View
      style={{
        alignItems: 'center',
        backgroundColor: constants?.color?.blue,
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: width,
        paddingHorizontal: 20,
        height: height,
        ...containerStyle,
      }}>
      <ImageButton
        source={require('src/Images/whiteBackArrow.png')}
        handlePress={() => handleCancel()}
        imageStyle={{width: 35, height: 35}}
        buttonStyle={{marginTop: 10, alignItems: 'center'}}
      />
      <BodyText
        text={title.toUpperCase?.()}
        textStyle={{
          color: 'white',
          fontFamily: constants?.font.gothic,
          fontSize: 25,
          flex: 1,
          textAlign: 'center',
          paddingBottom: 8,
        }}
      />
      <ExpoImage
        source={source || challengeIcon}
        style={{width: 40, height: 40, marginBottom: 2}}
      />
    </View>
  );
};

export const HeaderShadow: React.FC = () => {
  const {width} = useWindowDimensionsSafe();
  return (
    <View
      style={{
        ...constants?.style.navigationHeader,
        top: -6,
        height: 6,
        width,
        backgroundColor: constants?.color?.white,
      }}
    />
  );
};

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect, useState} from 'react';
import {View} from 'react-native';

import {apiUpdateUserBadges} from 'src/Utils/apiCalls';

/////////////////////////////////////////////
///////  components

import {TopTab, TopTabButton} from 'src/Modules/TabViews';
import Badges from 'src/Badges/Badges';
import useParams from 'src/Hooks/useParams';

import {useUserId} from 'src/Hooks/reduxHooks';

const AllBadgesModal = () => {
  const userId = useUserId();
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  useEffect(() => {
    if (!!userId) {
      apiUpdateUserBadges();
    }
  }, [userId]);

  const {view: initialView = 'badges_earned'} = useParams<'AllBadgesModal'>();
  const [view, setView] = useState<string>(initialView);

  return (
    <View style={{flex: 1, backgroundColor: constants?.color?.white}}>
      <TopTab>
        {!playTestState && (
          <TopTabButton
            index={0}
            text={'In Progress'}
            active={view === 'badges_earned'}
            handlePress={() => {
              setView('badges_earned');
            }}
          />
        )}
        <TopTabButton
          index={1}
          text={'Earned'}
          active={view === 'earned_badges'}
          handlePress={() => {
            setView('earned_badges');
          }}
        />
        {}
      </TopTab>

      <View
        style={{
          flex: 1,
          position: 'relative',
          backgroundColor: constants?.color?.white1,
        }}>
        {view === 'badges_earned' && <Badges showEarned={false} />}
        {view === 'earned_badges' && <Badges showEarned={true} />}
      </View>
    </View>
  );
};

export default AllBadgesModal;

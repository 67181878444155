import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import MapView, {
  Marker,
  Callout,
  UserLocationChangeEvent,
  LatLng,
} from 'src/Modules/Native/MapView';
import ExpoImage from 'src/Utils/ExpoImage';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {useRef, useState} from 'react';
import {ContinueButton} from 'src/Modules/CommonView';
import constants from 'src/constants';
import {useChallenge} from 'src/Hooks/gameHooks';
import {markSelectionChallengeComplete} from 'src/Utils/huntFunctions_v2';
import {calculateDistance} from 'src/Utils/distanceHelpers';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {TCompletion} from 'src/types/TChallenge';

interface GuessWhereProps {
  onComplete?: () => void;
  onAllAnswersModal?: boolean;
  correctAnswerOverride?: string;
  completionsOverride?: Record<string, TCompletion> | null;
}

const GuessWhere: React.FC<GuessWhereProps> = ({
  onComplete,
  onAllAnswersModal = false,
  correctAnswerOverride = '',
  completionsOverride = {},
}) => {
  const {width, height} = useWindowDimensionsSafe();
  const mapRef = useRef<MapView | null>(null);
  const challenge = useChallenge();
  const [selectedLocation, setSelectedLocation] = useState<LatLng>();
  const userId = useTypedSelector((state) => state.user?.info?.userId);
  const correctAnswer =
    challenge?.answers?.find((answer) => answer.correct) || {};
  const latlng = correctAnswer.option?.split(',');

  const completions = onAllAnswersModal
    ? completionsOverride || {}
    : challenge?.completions || {};

  const userHasCompleted = userId && completions[userId] ? true : false;

  const userCompletionAnswer = (userId && completions[userId]?.option) || '';

  const handleSubmit = () => {
    const challengeId = challenge?.challengeId || '';

    if (latlng && selectedLocation) {
      const points = Math.max(
        0,
        500 *
          (1 -
            calculateDistance(
              parseFloat(latlng[0]),
              parseFloat(latlng[1]),
              selectedLocation?.latitude,
              selectedLocation?.longitude,
              'N',
            ) /
              500),
      );

      markSelectionChallengeComplete({
        challengeId,
        option: `${selectedLocation?.latitude},${selectedLocation?.longitude}`,
        correct: true,
        singleCompletion: true,
        overridePointsEarned: Math.round(points),
      });

      onComplete && onComplete?.();

      mapRef?.current?.animateToRegion({
        latitude: parseFloat(latlng[0]) || 0,
        longitude: parseFloat(latlng[1]) || 0,
        latitudeDelta: 150,
        longitudeDelta: 150,
      });
    }
  };

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{height: !userHasCompleted ? height / 2 : 'auto'}}
      scrollEnabled={!userHasCompleted}
      contentContainerStyle={{alignItems: 'center'}}>
      {!userHasCompleted && !onAllAnswersModal && (
        <Text
          style={{
            marginVertical: 15,
            fontSize: 15,
            fontFamily: constants.font.Jakarta,
          }}>
          {challenge?.question || ''}
        </Text>
      )}
      {!userHasCompleted && !onAllAnswersModal && (
        <View
          style={{
            padding: 10,
            justifyContent: 'center',
            transform: [{rotate: '2.372deg'}],
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            shadowOffset: {width: 0, height: 4},
            shadowOpacity: 1,
            width: 225,
            shadowRadius: 4,
            elevation: 4,
            alignItems: 'center',
            backgroundColor: 'white',
          }}>
          {challenge?.challengePhoto && (
            <ExpoImage
              style={{height: height < 700 ? 100 : 160, width: 200}}
              resizeMode="contain"
              source={{
                uri:
                  challenge?.challengePhoto?.includes('photos.letsroam.com') ||
                  challenge?.challengePhoto?.includes(
                    'firebasestorage.googleapis.com',
                  )
                    ? challenge?.challengePhoto
                    : challenge?.challengePhoto?.startsWith('_icon')
                    ? 'https://photos.letsroam.com/icons/' +
                      challenge?.challengePhoto
                    : challenge?.challengePhoto?.startsWith('_')
                    ? 'https://photos.letsroam.com/scavenger_hunt_locations/' +
                      challenge?.challengePhoto
                    : '',
              }}
            />
          )}
          <Text
            style={{
              textAlign: 'center',
              fontSize: 15,
              fontFamily: 'Jakarta',
              padding: 10,
            }}>
            Tap this location on the map
          </Text>
        </View>
      )}
      <View
        style={{
          width: width,
          height: height < 700 && userHasCompleted ? 220 : 290,
          overflow: 'hidden',
          marginTop: 30,
          alignItems: 'center',
        }}>
        <MapView
          ref={mapRef}
          pitchEnabled={!onAllAnswersModal}
          rotateEnabled={!onAllAnswersModal}
          scrollEnabled={!onAllAnswersModal}
          zoomEnabled={!onAllAnswersModal}
          style={StyleSheet.absoluteFillObject}
          initialRegion={
            userHasCompleted && !onAllAnswersModal && latlng
              ? {
                  latitude: parseFloat(latlng[0]),
                  longitude: parseFloat(latlng[1]),
                  latitudeDelta: 150,
                  longitudeDelta: 150,
                }
              : onAllAnswersModal && correctAnswerOverride
              ? {
                  latitude: parseFloat(correctAnswerOverride?.split(',')?.[0]),
                  longitude: parseFloat(correctAnswerOverride?.split(',')?.[1]),
                  latitudeDelta: 150,
                  longitudeDelta: 150,
                }
              : {
                  latitude: 0,
                  longitude: 0,
                  latitudeDelta: 150,
                  longitudeDelta: 150,
                }
          }
          showsPointsOfInterest={false}
          customMapStyle={[
            ...constants?.customMapStyle,
            {
              featureType: 'all',
              elementType: 'labels.text',
              stylers: [
                {
                  visibility: 'off',
                },
              ],
            },
          ]}
          showsIndoors={false}
          onPress={(e) => setSelectedLocation({...e.nativeEvent.coordinate})}>
          {selectedLocation && !userHasCompleted && !onAllAnswersModal && (
            <Marker
              pinColor="grey"
              coordinate={
                selectedLocation || {
                  latitude: 0,
                  longitude: 0,
                }
              }
            />
          )}
          {userHasCompleted &&
            userCompletionAnswer &&
            (latlng || correctAnswerOverride) && (
              <Marker
                pinColor="grey"
                coordinate={{
                  latitude:
                    parseFloat(userCompletionAnswer?.split(',')?.[0]) || 0,
                  longitude:
                    parseFloat(userCompletionAnswer?.split(',')?.[1]) || 0,
                }}
              />
            )}
          {userHasCompleted &&
            userCompletionAnswer &&
            (latlng || correctAnswerOverride) && (
              <Marker
                pinColor="grey"
                coordinate={{
                  latitude: parseFloat(userCompletionAnswer?.split(',')?.[0]),
                  longitude: parseFloat(userCompletionAnswer?.split(',')?.[1]),
                }}
              />
            )}
          {!onAllAnswersModal && userHasCompleted && latlng && (
            <Marker
              pinColor="red"
              coordinate={{
                latitude: parseFloat(latlng[0]) || 0,
                longitude: parseFloat(latlng[1]) || 0,
              }}
            />
          )}
          {onAllAnswersModal && correctAnswerOverride && (
            <Marker
              pinColor="red"
              coordinate={{
                latitude:
                  parseFloat(correctAnswerOverride?.split(',')?.[0]) || 0,
                longitude:
                  parseFloat(correctAnswerOverride?.split(',')?.[1]) || 0,
              }}
            />
          )}
        </MapView>
        {selectedLocation && !userHasCompleted && !onAllAnswersModal && (
          <ContinueButton
            text="Guess"
            containerStyle={{
              position: 'absolute',
              bottom: 5,
              width: width - 40,
            }}
            handlePress={handleSubmit}
          />
        )}
      </View>
    </ScrollView>
  );
};

export default GuessWhere;

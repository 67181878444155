import constants from 'src/constants';
import React, {useState} from 'react';
import {View, ScrollView, TouchableOpacity, Image, Text} from 'react-native';

import {BodyText, ContinueButton} from 'src/Modules/CommonView';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {useFirebaseData} from 'src/Utils/fireUtils';
import {updatePAChats, setCurrentChat} from 'src/Redux/reducers/chats.reducer';

import {useUserId} from 'src/Hooks/reduxHooks';
import {TPAChatThread} from 'src/types/TPAChat';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';
import {BulletItem} from 'src/ExplorerPass/AboutExplorerPass';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import getAppType from 'src/Utils/getAppType';
import PARequest from './PARequest';
import {PADataUpdater, useFilteredPaChats} from './PADataUpdater';
import ExpoImage from 'src/Utils/ExpoImage';
const warningIcon = require('src/Images/Icons/warning-error.png');
const helpIcon = require('src/Images/Icons/help.png');
const buoy = require('src/Images/Icons/lifebuoy_2.png');

export default function PACompletedRequests() {
  console.log('PersonalAssistantTab render');
  const [index, setIndex] = useState(1);

  const userRequests = useFilteredPaChats(false, index);

  const explorerPass = useExplorerPass();

  const userId = useUserId();

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  return (
    <ScrollView style={{backgroundColor:'#F9F7F2'}}>
      <View style={{paddingHorizontal: 24}}>
        <BodyText
          text={'Completed requests'}
          textStyle={{
            color: 'black',
            marginTop: 30,
            marginLeft: 0,
            fontSize: 17,
            fontFamily: 'JakartaBold',
          }}
        />
        <View style={{marginTop: 10}}>
          {!!userId && userRequests.length > 0 && (
            <View>
              {userRequests.map((request) => (
                <PARequest key={request.UUID} request={request} />
              ))}
              {index <= userRequests.length && (
                <TouchableOpacity
                  style={{
                    borderWidth: 1,
                    borderColor: '#D9D9D6',
                    height: 50,
                    borderRadius: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 20,
                    marginBottom: 5,
                    width: '100%',
                  }}
                  onPress={() => {
                    setIndex(index + 3);
                  }}>
                  <BodyText
                    text={'Load More'}
                    textStyle={{
                      color: constants?.color?.blue,
                      fontSize: 15,
                      fontWeight: '500',
                      marginBottom: 5,
                      padding: 0,
                      fontFamily: 'Jakarta',
                    }}
                  />
                </TouchableOpacity>
              )}
            </View>
          )}
        </View>
      </View>
      {!userRequests ||
        (userRequests?.length == 0 && (
          <View>
            <View
              style={{
                gap: 20,
                flexDirection: 'column',
                marginHorizontal: 20,
                marginTop: 20,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <ExpoImage
                source={helpIcon}
                style={{height: 40, width: 40}}
                resizeMode="contain"
              />
              <Text
                style={{
                  fontSize: 15,
                  fontFamily: 'Jakarta',
                  textAlign: 'center',
                }}>
                {'No requests found.'}
              </Text>
              <Text
                style={{
                  fontSize: 15,
                  fontFamily: 'Jakarta',
                  textAlign: 'center',
                }}>
                {'Submit your first request\n to get started!'}
              </Text>
            </View>
            <ContinueButton
              text="Ask my personal assistant"
              textStyle={{fontFamily: 'JakartaBold'}}
              testID={'askMyPA'}
              background={'black'}
              containerStyle={{
                alignSelf: 'center',
                marginTop: 16,
                marginBottom: 30,
                borderRadius: 6,
              }}
              width={290}
              height={50}
              handlePress={() => {
                if (!explorerPass || !userId) {
                  return navigationRef.navigate(
                    'FreeTrialScreen',
                    null,
                    'PACompletedRequests.tsx',
                  );
                }
                navigationRef.navigate('PASubmitChat', null, 'PACompletedRequests.tsx');
              }}
            />
          </View>
        ))}
      <View
        style={{
          gap: 20,
          flexDirection: 'row',
          marginTop: 10,
          marginHorizontal: 20,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <ExpoImage
          source={warningIcon}
          style={{height: 50, width: 50}}
          resizeMode="contain"
        />
        <Text
          style={{fontSize: 15, fontFamily: 'Jakarta', textAlign: 'center'}}>
          {'Hit a snag with our app?\nCall our support team instead.'}
        </Text>
      </View>

      {!playTestState && (
        <View style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
          <TouchableOpacity
            style={{
              backgroundColor: 'black',
              borderRadius: 8,
              marginVertical: 20,
              marginHorizontal: 12,
              alignItems: 'center',
              justifyContent: 'center',
              paddingVertical: 40,
              paddingHorizontal: 40,
              shadowColor: '#000',
              shadowOffset: {width: 0, height: 1},
              shadowOpacity: 0.6,
              shadowRadius: 3,
            }}>
            <>
              <Image
                source={buoy}
                style={{width: 36, height: 36, resizeMode: 'contain'}}
              />
              <View style={{display: 'flex', flexDirection: 'row'}}>
                <BodyText
                  text={'Call us at'}
                  textStyle={{
                    color: 'white',
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
                <BodyText
                  text={'1-833-202-7626'}
                  textStyle={{
                    color: '#EACD72',
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                    marginLeft: 5,
                    marginRight: 0,
                  }}
                />
              </View>
              <BodyText
                text={'for live support'}
                textStyle={{
                  color: constants.color.white,
                  fontSize: 17,
                  fontFamily: 'JakartaBold',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 3,
                }}
              />
              <BodyText
                text={'Our friendly agents are standing by.'}
                textStyle={{
                  color: constants.color.white,
                  fontSize: 12,
                  fontFamily: 'Jakarta',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 12,
                }}
              />
              <ContinueButton
                text={'Call support'}
                height={50}
                background={'#EACD72'}
                textStyle={{
                  fontFamily: 'JakartaBold',
                  textAlign: 'center',
                  flex: 1,
                  color: constants?.color?.black,
                }}
                containerStyle={{
                  marginTop: 24,
                  borderRadius: 6,
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  shadowColor: '#000',
                  shadowOffset: {width: 0, height: 1},
                  shadowOpacity: 0.6,
                  shadowRadius: 3,
                }}
                buttonStyle={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                handlePress={() => {
                  navigationRef.navigate(
                    'SupportModal',
                    {},
                    'Nav/DrawerMenu.tsx',
                  );
                }}
              />
            </>
          </TouchableOpacity>
        </View>
      )}
      {!!userId && <PADataUpdater userId={userId} />}
    </ScrollView>
  );
}

import constants from 'src/constants';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {View, Image} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import {BodyText, PrimaryButton} from 'src/Modules/CommonView';
import {navigationRef} from 'src/Nav/navigationHelpers';
import useParams from 'src/Hooks/useParams';

const CommunityViewPhoto: React.FC = () => {
  const {width, height} = useWindowDimensionsSafe();
  const params = useParams<'CommunityViewPhoto'>();
  const photo = params?.photo || {};
  const share_photo = photo?.share_photo || false;
  const description = photo?.description || '';

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: constants?.color?.white,
      }}>
      <View style={{flex: 1, marginTop: 35, justifyContent: 'space-between'}}>
        <BodyText
          text={description}
          textStyle={{textAlign: 'center', marginBottom: 10}}
        />
        {!!share_photo && (
          <Image
            style={{width, maxHeight: height - 230, flex: 1}}
            resizeMode={'cover'}
            source={{uri: share_photo}}
          />
        )}
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: constants?.color?.white,
          }}>
          <PrimaryButton
            text={'Back'}
            background={constants?.color?.gray3}
            handlePress={() =>
              navigationRef?.goBack('LandingPage/CommunityViewPhoto.tsx')
            }
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

export default CommunityViewPhoto;

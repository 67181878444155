import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useMemo} from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import moment from 'moment';

import UserIcon from 'src/HeaderComponent/UserIcon';
import FoxShopIcon from 'src/HeaderComponent/FoxShopIcon';
import {MainIcon} from 'src/HeaderComponent/HeaderComponents';

import {PageTitle} from 'src/HeaderComponent/HeaderComponents';

import LandingPageTabMenu from 'src/Nav/LandingPageTabMenu';
////// Explorer pass
import ExplorerPassLanding from 'src/ExplorerPass/ExplorerPassLanding';
import HomeLandingTab from 'src/LandingPage/Home/HomeLandingTab';
import JoinLanding from 'src/JoinRedeem/JoinLandingPage';
import PersonalAssistantTab from 'src/LandingPage/PATab';
import LocalTab from 'src/Local/LocalTab';

// import {mmkvStorage} from 'src/Utils/mmkvStorage';

import ProfilePage from 'src/LandingPage/ProfilePage';
import LeaderboardPage from 'src/LandingPage/LeaderboardPage';
import {View} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {navigationRef} from './navigationHelpers';
import getAppType from '../Utils/getAppType';
import PAProfile from 'src/LandingPage/PAProfile';
import PASubmitChat from 'src/LandingPage/PASubmitChat';
import PAActiveRequests from 'src/LandingPage/PAActiveRequests';
import PACompletedRequests from 'src/LandingPage/PACompletedRequests';
import JoinAA from 'src/JoinRedeem/JoinAA';
import EventHome from 'src/CustomEvents/EventHome';

const Tab = createBottomTabNavigator();

const LandingPageTabs: React.FC = () => {
  console.log('LandingPageTabs Rerender');

  const {width} = useWindowDimensionsSafe();

  const eventId = useTypedSelector((state) => state.event?.eventId);

  const dateEventCreated = useTypedSelector(
    (state) => state.user?.info?.currentHuntInfo?.dateEventCreated,
  );

  const eventWithin14Days = useMemo(() => {
    // Convert the string to a moment object
    const dateToCheck = moment(dateEventCreated);

    // Get today's date and include the entire day
    const today = moment().endOf('day');

    // Calculate the date 14 days ago from today
    const fourteenDaysAgo = moment().subtract(14, 'days').startOf('day');

    // Check if the event was created after or on the date from 14 days ago and on or before today
    return (
      dateToCheck.isSameOrAfter(fourteenDaysAgo) &&
      dateToCheck.isSameOrBefore(today)
    );
  }, [dateEventCreated]);

  return (
    <Tab.Navigator
      initialRouteName={
        !!eventId && eventWithin14Days ? 'EventHome' : 'LandingPage'
      }
      tabBar={() => <LandingPageTabMenu />}
      screenOptions={() => ({
        unmountOnBlur: true,
        headerStyle: {
          elevation: 0,
          shadowOpacity: 0,
          shadowColor: 'transparent',
        },
        headerLeft: () => (
          <View style={{width: 80, paddingLeft: 5}}>
            <UserIcon />
          </View>
        ),
        headerTitle: () => (
          <View style={{width: width - 200}}>
            <TouchableOpacity
              onPress={() => {
                navigationRef.navigate(
                  getAppType() === 'aa' ? 'PersonalAssistant' : 'LandingPage',
                  null,
                  'LandingPageTabs',
                );
              }}>
              <HeaderTitle />
            </TouchableOpacity>
          </View>
        ),
        headerRight: () => (
          <View style={{width: 80, right: -10, top: 1}}>
            <FoxShopIcon />
          </View>
        ),
      })}>
      {getAppType() == 'lr' && (
        <Tab.Screen name="LandingPage" component={HomeLandingTab} />
      )}
      <Tab.Screen name="EventHome" component={EventHome} />
      <Tab.Screen name="PersonalAssistant" component={PersonalAssistantTab} />
      <Tab.Screen name="LocalTab" component={LocalTab} />
      <Tab.Screen name="ExplorerPass" component={ExplorerPassLanding} />

      {getAppType() == 'aa' && (
        <Tab.Screen name="PAProfile" component={PAProfile} />
      )}
      {getAppType() == 'aa' && (
        <Tab.Screen
          name="PACompletedRequests"
          component={PACompletedRequests}
        />
      )}
      {getAppType() == 'aa' && (
        <Tab.Screen name="PAActiveRequests" component={PAActiveRequests} />
      )}
      {getAppType() == 'aa' && (
        <Tab.Screen name="PASubmitChat" component={PASubmitChat} />
      )}
      {getAppType() == 'lr' && (
        <Tab.Screen name="Leaderboard" component={LeaderboardPage} />
      )}
      {getAppType() == 'lr' && (
        <Tab.Screen name="JoinLanding" component={JoinLanding} />
      )}
      {getAppType() == 'aa' && <Tab.Screen name="JoinAA" component={JoinAA} />}
      <Tab.Screen name="ProfilePage" component={ProfilePage} />
    </Tab.Navigator>
  );
};

const HeaderTitle = () => {
  const headerTitle = useTypedSelector((state) => {
    const currentScreen = state.current_screen?.currentScreen;
    if (currentScreen === 'LandingPage') {
      return 'Landing Page';
    } else if (currentScreen == 'Leaderboard') {
      return 'Prizes';
    } else if (currentScreen == 'PersonalAssistant') {
      return 'Adventure Assistant';
    } else if (currentScreen == 'PAChat') {
      return 'Dining Deals';
    } else if (currentScreen == 'JoinLanding') {
      return 'Join/Redeem';
    } else if (currentScreen?.toLowerCase?.()?.includes('local')) {
      return `Let's Roam Local`;
    } else {
      return 'Landing Page';
    }
  });

  console.log('the app title screen is', {headerTitle});
  if (getAppType() === 'aa') {
    return <PageTitle text="Adventure Assistant" />;
  } else if (headerTitle == 'Landing Page') {
    return <MainIcon />;
  } else {
    return <PageTitle text={headerTitle} />;
  }
};

const LandingPageTabsMemo = () => {
  return useMemo(() => {
    return <LandingPageTabs />;
  }, []);
};

export default LandingPageTabsMemo;

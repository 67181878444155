import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {
  TouchableOpacity,
  View,
  Linking,
  ScrollView,
  Text,
  KeyboardAvoidingView,
  Keyboard,
  TouchableWithoutFeedback,
  Platform,
} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

///////////////////////////
///////////// components
import {
  BodyText,
  CloseIconButton,
  MultiChoiceAnswer,
} from 'src/Modules/CommonView';
import TextMultiChoice from 'src/ScavengerHunt_v2/Challenges/TextMultiChoice';
import LinkChallenge from 'src/ScavengerHunt_v2/Challenges/LinkChallenge';
import PhotoVideo from 'src/ScavengerHunt_v2/Challenges/PhotoVideo';
import NumberGuesser from 'src/ScavengerHunt_v2/Challenges/NumberGuesser';
import LetterScramble from 'src/ScavengerHunt_v2/Challenges/LetterScramble';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';

import {markClassicLocationAllComplete} from 'src/Utils/huntFunctions_classic';

import {
  ClassicIconButton,
  ContinueButton,
} from 'src/Modules/CommonView/Buttons';
import ExpoImage from 'src/Utils/ExpoImage';

import {useChallenge, useImageSource, useLocationId} from 'src/Hooks/gameHooks';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {cloudFlareOptimizeImage, getReduxState} from 'src/Utils/helpers';
import ChallengeFeedbackElement from '../Game/ChallengeFeedbackElement';
import GuessWhere from '../Challenges/GuessWhere';
import TextQuestion from '../Challenges/TextQuestion';
import ScatteredKnowledge from '../Challenges/ScatteredKnowledge';
import CombinationLock from '../Challenges/CombinationLock';
import CoworkerFeud from '../Challenges/CoworkerFeud';
import useParams from 'src/Hooks/useParams';
import {DimensionValue} from 'react-native';
import LightningTimer from 'src/Modules/CommonGame/Challenges/LightningTimer';
import {markSelectionChallengeComplete} from 'src/Utils/huntFunctions_v2';

const ChallengeAttemptAndResultModal: React.FC = () => {
  const {headerTitle} = useParams<'ChallengeAttemptAndResultModal'>();

  const {width} = useWindowDimensionsSafe();

  const locations = useTypedSelector((state) => state.game_v2?.locations) || {};

  const challenge = useChallenge?.();

  const teamName = useTypedSelector((state) => state.group?.info?.teamName);

  const players = useTypedSelector(
    (state) => state.group?.info?.players,
    deepEqualityCheck,
  );

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  const userId = useTypedSelector((state) => state.user?.info?.userId);

  const handleOnComplete = () => {
    console.log('handleOnComplete');
    if (!challenge) {
      console.error('no challenge');
      return;
    }

    const {challengeId, locationId} = challenge;

    if (locationId) {
      //aka location challenges
      const location = locations[locationId];

      const allChallenges =
        getReduxState((state) => state.game_v2?.allChallenges) || {};

      // could be error
      const allChallengesComplete = location.challengeList?.every?.((key) => {
        const locationChallenge = allChallenges[key];

        console.log('testing if all challenges complete', {
          key,
          challengeId,
          locationChallenge,
        });

        return !!locationChallenge?.completeTime || key == challengeId;
      });

      if (allChallengesComplete) {
        markClassicLocationAllComplete?.(locationId);
      }
    }
  };

  const type = challenge?.type || '';
  const question = challenge?.question;
  const hintURL = challenge?.hintURL;
  const challengePhoto = challenge?.challengePhoto;
  const answers = challenge?.answers || [];
  const maxPoints = challenge?.maxPoints || 0;

  const locationId = useLocationId?.();

  const basePhotoUrl = 'photos.letsroam.com';
  const largeDisplayedPhoto =
    challengePhoto && typeof challengePhoto?.substring == 'function'
      ? `https://${basePhotoUrl}${challengePhoto?.substring?.(1)}`
      : null;

  console.log({largeDisplayedPhoto});

  let previousWrongAttempts = 0;
  answers?.map?.((answer) => {
    if (answer?.selectedAt && !answer?.correct) {
      previousWrongAttempts++;
    }
  });

  const hintsDeductions = challenge?.hintsDeductions || 0;

  const maxPointsStillPossible = Math.max(
    Math.round(maxPoints - hintsDeductions),
    0,
  );

  const {height} = useWindowDimensionsSafe();

  const imageSource = useImageSource();

  // challenge complete
  if (
    challenge?.completeTime &&
    !['coworker_feud', 'combination_lock']?.includes(type)
  ) {
    const postContent = challenge?.postContent || '';
    const playerPointsEarned = challenge?.playerPointsEarned || 0;
    const groupPointsEarned = challenge?.groupPointsEarned;

    const totalPointsEarned =
      playerPointsEarned + (groupPointsEarned || 0) || 0;
    const completions = challenge?.completions || {};

    const answeredCorrectly = !!completions
      ? Object.values(completions).filter((completion) => {
          return completion.answeredCorrectly;
        }) || []
      : [];

    return (
      <SafeAreaView
        style={{
          flex: 1,
          justifyContent: 'flex-end',
        }}>
        <View>
          <TouchableOpacity
            style={{flex: 1}}
            onPress={() =>
              navigationRef?.goBack(
                'ScavengerHunt_v2/Classic/ChallengeAttemptAndResultModal.tsx',
              )
            }
          />
          <View style={{maxHeight: height - 60}}>
            <ScrollView>
              <View
                style={{
                  width,
                  backgroundColor: constants?.color?.white,
                  borderRadius: 10,
                  paddingVertical: 5,
                  position: 'relative',
                }}>
                <View style={{alignItems: 'center'}}>
                  {!!answeredCorrectly?.length ? (
                    <ExpoImage
                      style={{
                        width: width - 20,
                        height: 100,
                      }}
                      source={imageSource}
                      resizeMode={'contain'}
                    />
                  ) : (
                    <FontAwesomeIcon size={70} color={'red'} name={'times'} />
                  )}
                </View>

                <View
                  style={{
                    marginTop: 0,
                    marginBottom: 10,
                    alignItems: 'center',
                  }}>
                  <BodyText
                    text={'Result'}
                    textStyle={{
                      textAlign: 'center',
                      fontWeight: '700',
                      fontSize: 18,
                      lineHeight: 20,
                    }}
                  />
                  {challenge?.type === 'emoji_decoder' &&
                  challenge?.challengePhoto ? (
                    <ExpoImage
                      style={{height: 50, width: 200, marginTop: 10}}
                      resizeMode="contain"
                      source={{
                        uri: cloudFlareOptimizeImage(challenge?.challengePhoto),
                      }}
                    />
                  ) : (
                    <BodyText
                      textAlign="center"
                      text={
                        question && question.match(/\\u[\dA-Fa-f]{4}/)
                          ? JSON.parse(question)
                          : question || ''
                      }
                    />
                  )}
                </View>
                {!!answers && (
                  <MultiChoiceAnswer
                    answers={answers}
                    completions={completions}
                    teamName={teamName || 'Team'}
                    players={players}
                    postContent={postContent}
                    isPhoto={type === 'ispy'}
                    pointsEarned={totalPointsEarned > 0 ? totalPointsEarned : 0}
                    showMap={type === 'where_map'}
                    userId={userId || ''}
                  />
                )}
                <ChallengeFeedbackElement isSmiley />

                <CloseIconButton
                  testID={'CloseIconButtonClose'}
                  handlePress={() =>
                    navigationRef.goBack(
                      'CloseIconButtonClose ChallengeAttemptAndResultModal',
                    )
                  }
                  side="right"
                />
              </View>
            </ScrollView>
          </View>
        </View>
      </SafeAreaView>
    );
  }

  return (
    <SafeAreaView style={{flex: 1, justifyContent: 'flex-end'}}>
      <TouchableOpacity
        style={{flex: 1}}
        onPress={() =>
          navigationRef?.goBack?.('ChallengeAttemptAndResultModal GoBack')
        }
      />
      <KeyboardAvoidingViewWrapper width={width}>
        <ContentToWrapWithTouchable width={width} type={type}>
          <View
            style={{
              paddingBottom: 10,
              marginBottom: 10,
              marginHorizontal: 10,
              ...(type === 'where_map' && {height: '100%'}),
            }}>
            <BodyText
              text={headerTitle || 'Challenge'}
              textStyle={{
                textAlign: 'center',
                fontWeight: '700',
                fontSize: 24,
                lineHeight: 29,
                fontFamily: 'JakartaBold',
              }}
            />
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                flexWrap: 'wrap',
                borderBottomWidth: 1,
                borderBottomColor: constants?.color?.white1,
                padding: 10,
                paddingBottom: 20,
                paddingTop: isHybridHunt ? 5 : 20,
                marginLeft: 10,
                width: width - 40,
              }}>
              {!isHybridHunt && type !== 'where_map' && (
                <View style={{marginHorizontal: 5}}>
                  <ClassicIconButton
                    handlePress={() => {
                      navigationRef.goBack?.(
                        'ChallengeAttemptAndResultModal ClassicIconButton',
                      );
                      navigationRef.navigate?.(
                        'NavigationChallengeModal',
                        {
                          locationId,
                        },
                        'ChallengeAttemptAndResultModal',
                      );
                    }}
                    size={24}
                    text="Map"
                    icon="map-o"
                    color="blue"
                    iconColor="white"
                    inline
                  />
                </View>
              )}

              <View style={{marginHorizontal: 5}}>
                {isHybridHunt ? (
                  <Text>{`${maxPointsStillPossible || maxPoints} points`}</Text>
                ) : (
                  <ClassicIconButton
                    handlePress={() => {}}
                    text={`${maxPointsStillPossible} / ${maxPoints} Pts Possible`}
                    size={24}
                    icon="compass"
                    iconColor="white"
                    color="orange"
                    inline
                  />
                )}
              </View>
            </View>

            {!!largeDisplayedPhoto &&
              ![
                'emoji_decoder',
                'spot_the_difference',
                'cypher_decoder',
                'scattered_knowledge',
                'where_map',
              ].includes(type) && (
                <View>
                  <ExpoImage
                    source={{uri: largeDisplayedPhoto}}
                    style={{
                      width: width - 20,
                      height: 150,
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                    resizeMode={'cover'}
                  />
                </View>
              )}
            {![
              'emoji_decoder',
              'spot_the_difference',
              'cypher_decoder',
              'scattered_knowledge',
              'where_map',
              'combination_lock',
              'coworker_feud',
            ].includes(type) && <BodyText text={question || ''} />}
            {type === 'emoji_decoder' && (
              <TextQuestion
                onComplete={handleOnComplete}
                showPhoto
                prompt={'Decipher these emojis.'}
                category={challenge?.category || ''}
              />
            )}
            {type === 'where_map' && (
              <GuessWhere onComplete={handleOnComplete} />
            )}
            {!![
              'spot_the_difference',
              'cypher_decoder',
              'scattered_knowledge',
            ].includes(type) && (
              <ScatteredKnowledge onComplete={handleOnComplete} />
            )}
            {(type === 'true_false' || type === 'multiple_choice') && (
              <TextMultiChoice
                onComplete={handleOnComplete}
                singleCompletion
                allowMultiGuess={type === 'multiple_choice'}
                noTimer
              />
            )}
            {type === 'link' && (
              <LinkChallenge
                onComplete={handleOnComplete}
                singleCompletion
                allowMultiGuess
                noTimer
              />
            )}

            {type === 'combination_lock' && (
              <CombinationLock
                onComplete={handleOnComplete}
                openText="Well done! Lock opened. You're one step closer!"
              />
            )}
            {type === 'coworker_feud' && (
              <CoworkerFeud onComplete={handleOnComplete} />
            )}

            {(type === 'photo' || type === 'video') && (
              <PhotoVideo onComplete={handleOnComplete} />
            )}
            {(type === 'number_guesser' ||
              type === 'numberguesser' ||
              type === 'self_score') && (
              <NumberGuesser onComplete={handleOnComplete} singleCompletion />
            )}
            {type === 'fill_in_the_blank' && (
              <LetterScramble onComplete={handleOnComplete} singleCompletion />
            )}
            {type === 'fill_in_the_blank_traditional' && (
              <TextQuestion onComplete={handleOnComplete} showBlanks={true} />
            )}
            {!!hintURL && (
              <ContinueButton
                text={'Need A Hint: Tap Here!'}
                handlePress={() => {
                  Linking?.openURL?.(hintURL);
                }}
              />
            )}
            {!!challenge?.isPlayerChallenge &&
              !!challenge.lightningTimerSeconds &&
              type != 'photo' &&
              type != 'video' && (
                <LightningTimer
                  questionData={challenge}
                  onTimeUp={() =>
                    markSelectionChallengeComplete({
                      challengeId: challenge.challengeId,
                      option: '',
                      correct: false,
                      key: '',
                      singleCompletion: true,
                      overridePointsEarned: 0,
                      allowMultiGuess: false,
                    })
                  }
                />
              )}
          </View>
        </ContentToWrapWithTouchable>
        <CloseIconButton
          handlePress={() =>
            navigationRef?.goBack?.('ChallengeAttemptAndResultModal')
          }
          side="right"
        />
      </KeyboardAvoidingViewWrapper>
    </SafeAreaView>
  );
};

//Coworker feud or text input based questions, this prevents the keyboard from covering the textinput
//For the web app, we don't use it because web doesn't do well with KeyboardAvoidingView, color becomes
//transparent
const KeyboardAvoidingViewWrapper = ({
  children,
  width,
}: {
  children: React.ReactNode;
  width: DimensionValue | undefined;
}) =>
  Platform.OS !== 'web' ? (
    <KeyboardAvoidingView
      behavior={'position'}
      contentContainerStyle={{
        width,
        backgroundColor: constants?.color?.white,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        paddingVertical: 5,
        position: 'relative',
      }}>
      {children}
    </KeyboardAvoidingView>
  ) : (
    <View
      style={{
        width,
        backgroundColor: constants?.color?.white,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        paddingVertical: 5,
        position: 'relative',
      }}>
      {children}
    </View>
  );

//Dismiss keyboard by clicking it out of focus
const ContentToWrapWithTouchable = ({
  type,
  children,
}: {
  type: string;
  children: React.ReactNode;
  width: DimensionValue | undefined;
}) =>
  Platform.OS !== 'web' &&
  [
    'emoji_decoder',
    'spot_the_difference',
    'cypher_decoder',
    'coworker_feud',
  ]?.includes(type) ? (
    <TouchableWithoutFeedback
      onPress={() => Platform.OS !== 'web' && Keyboard.dismiss()}>
      {children}
    </TouchableWithoutFeedback>
  ) : (
    children
  );

export default ChallengeAttemptAndResultModal;

import React from 'react';
import {TouchableOpacity, View, Text, ImageSourcePropType} from 'react-native';

import ExpoImage from 'src/Utils/ExpoImage';

interface FlagButtonProps {
  height: number;
  width: number;
  orientation: 'left' | 'right';
  label: string;
  iconSource?: ImageSourcePropType;
  touchHandler?: () => void;
  expandedText?: string;
  expandedFontSize?: number;
}

const FlagButton: React.FC<FlagButtonProps> = ({
  height,
  width,
  orientation,
  label,
  iconSource,
  touchHandler,
  expandedText,
  expandedFontSize,
}) => {
  let paddingRight = orientation === 'left' ? height / 2 : 0;
  let paddingLeft = orientation === 'left' ? 0 : height / 2;
  const flexDirection =
    orientation === 'left' && expandedText != null ? 'row-reverse' : 'row';
  if (flexDirection === 'row-reverse') {
    paddingLeft = orientation === 'left' ? height / 2 : 0;
    paddingRight = orientation === 'left' ? 0 : height / 2;
  }

  return (
    <TouchableOpacity
      onPress={touchHandler}
      disabled={!!touchHandler ? false : true}>
      <View
        style={{
          width,
          height,
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
          paddingLeft,
          paddingRight,
          flexDirection,
        }}>
        {!!iconSource && (
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height,
              width: height,
              zIndex: 1000,
            }}>
            <ExpoImage
              source={iconSource}
              style={{
                marginTop: 2,
                height: height - 27,
                width: height - 27,
              }}
              resizeMode="contain"
            />
            <Text
              style={{
                fontFamily: 'Alternate Gothic No3 D',
                textAlign: 'center',
                fontSize: 12,
                color: 'white',
                letterSpacing: -0.1,
                marginTop: 3,
              }}>
              {label.toUpperCase()}
            </Text>
          </View>
        )}
        {!!expandedText && (
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height,
              width: iconSource
                ? width - height - height / 2
                : width - height / 2,
              zIndex: 1000,
            }}>
            <Text
              style={{
                textAlign: 'center',
                marginRight: 20,
                marginLeft: 10,
                color: 'white',
                fontSize: expandedFontSize || 25,
                fontFamily: 'Alternate Gothic No3 D',
              }}>
              {expandedText.toUpperCase()}
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default FlagButton;

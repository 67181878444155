import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect} from 'react';
import {
  setLocalChallenges,
  setLocalCompletions,
  setLocalCompletionsList,
  setLocalFavorites,
  setLocalLocations,
  setLocalLocationsList,
  setLocationTagTypes,
  setWanderlists,
} from 'src/Redux/reducers/local.reducer';

import {apiGetLocalGuide} from 'src/Utils/apiCalls';
import {orderLocationListByDistanceAway} from 'src/Utils/distanceHelpers';
import {useQuery} from '@tanstack/react-query';
import {useUserId} from 'src/Hooks/reduxHooks';
import {TLocalLocation} from 'src/types/TLocalLocation';
import {TLocalCompletion} from 'src/types/TLocalCompletion';
import {THunt} from 'src/types/THunt';

import {TChallenge} from 'src/types/TChallenge';
import {TLocation} from 'src/types/TLocation';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';

const LocalController: React.FC = () => {
  const localLocations = useTypedSelector(
    (state) => state.local.localLocations,
  );

  const userId = useUserId();

  const localFilters = useTypedSelector((state) => state.local.localFilters);

  const roundedRegion = useTypedSelector(
    (state) => state.location.roundedRegion || {},
  );
  const roundedLat = useTypedSelector(
    (state) => state.location.roundedRegion?.latitude,
  );
  const roundedLong = useTypedSelector(
    (state) => state.location.roundedRegion?.longitude,
  );
  const queryKey =
    'allLocalData, ui:' +
    userId +
    ',lat:' +
    (roundedLat && Math.round(roundedLat)) +
    ', long:' +
    (roundedLong && Math.round(roundedLong));

  console.log('LocalController', {queryKey, roundedRegion});

  const {data, refetch} = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      console.log('apiGetLocalGuide fired');
      // /api/v1/get_local_guide_info_all
      const localGuideAPIResponse = await apiGetLocalGuide({
        get_all: 1,
        userId: userId || undefined,
        roundedLat,
        roundedLong,
      });

      console.log('pulling query data', {queryKey});
      if (!localGuideAPIResponse) {
        console.error('apiGetLocalGuide failed');
        throw new Error('no local locations');
      } else {
        console.log('apiGetLocalGuide succeeded');
      }
      return localGuideAPIResponse;
    },
  });

  useEffect(() => {
    constants.localDataRefetch = refetch;
    // console.log({localDataRefetch: window.localDataRefetch});
  }, [refetch]);

  useEffect(() => {
    if (data?.locations) {
      const newLocalLocations: Record<string, TLocalLocation> = {};
      data?.locations?.map?.((location: TLocalLocation) => {
        const newLocation = {...location};
        if (newLocation.tag_ids && typeof newLocation.tag_ids == 'string') {
          // console.log({tag_ids: newLocation.tag_ids});
          try {
            newLocation.tag_ids = JSON.parse(newLocation.tag_ids || '[]');
            newLocation.photo = newLocation.photo?.replace(
              './',
              'https://photos.letsroam.com/',
            );
          } catch (parseError) {
            console.log({parseError});
            newLocation.tag_ids = [];
          }
        }
        newLocalLocations[newLocation.id] = newLocation;
      });
      if (!newLocalLocations) {
        console.error('no newLocalLocations');
        return;
      }

      const localStore = getReduxState((state) => state.local);

      if (!deepEqualityCheck(localStore?.localLocations, newLocalLocations)) {
        dispatchAction(setLocalLocations(newLocalLocations));
      }

      if (
        !deepEqualityCheck(localStore?.locationTagTypes, data?.locationTagTypes)
      ) {
        dispatchAction(setLocationTagTypes(data?.locationTagTypes));
      }

      // Alert.alert('favorites', JSON.stringify(data?.favorites));
      if (!deepEqualityCheck(localStore?.localFavorites, data?.favorites)) {
        dispatchAction(setLocalFavorites(data?.favorites));
      }

      if (data?.localCompletions) {
        // Alert.alert(JSON.stringify(data?.localCompletions));
        const newLocalCompletions: Record<string, TLocalCompletion> = {};
        data?.localCompletions?.info?.map?.(
          (localCompletion: TLocalCompletion) => {
            newLocalCompletions[localCompletion.id] = localCompletion;
          },
        );
        // Alert.alert(data?.localCompletions);
        if (
          !deepEqualityCheck(localStore?.localCompletions, newLocalCompletions)
        ) {
          dispatchAction(setLocalCompletions(newLocalCompletions));
        }
        if (
          !deepEqualityCheck(
            localStore?.localCompletionsList,
            data?.localCompletions,
          )
        ) {
          dispatchAction(setLocalCompletionsList(data?.localCompletions));
        }
      }
      // Alert.alert(JSON.stringify(data?.locationTagTypes));
    }

    if (data?.questions) {
      const newQuestions: Record<string, TChallenge> = {};
      data?.questions?.map?.((questions: TChallenge) => {
        const newQuestion = {...questions};
        if (newQuestion.challenge_id) {
          newQuestions[newQuestion.challenge_id] = newQuestion;
        }
      });
      console.log('setting local challenges', {
        newQuestionsLength: data?.questions?.length,
      });
      const localStore = getReduxState((state) => state.local);

      if (!deepEqualityCheck(localStore?.localChallenges, newQuestions)) {
        dispatchAction(setLocalChallenges(newQuestions));
      }
    }

    if (data?.wanderlist_locations) {
      const wanderlists: {[huntId: string]: THunt} = {};

      data.wanderlist_locations.forEach((location: LocationData) => {
        const huntId = location.hunt_id;

        // mapping through locations (which also have the associated hunt data)
        // when we get to a new hunt_id, we create a new hunt object
        if (!wanderlists[huntId]) {
          wanderlists[huntId] = {
            name: location.name,
            hunt_id: huntId,
            huntMediumPhotoURL: location.photo_medium,
            is_free: true,
            route_id: location.route_id,
            hunt_version: location.hunt_version,
            hunt_type: location.hunt_type,
            city: location.city,
            history_focus: location.history_focus || '',
            culture_focus: location.culture_focus || '',
            difficulty_focus: location.art_focus || '',
            art_focus: location.art_focus,
            state: location.state,
            country: location.country,
            starting_location: location.starting_location,
            description: location.description,
            distance_miles: location.distance_miles,
            lat: Number(location.lat),
            long: Number(location.long),
            huntPhotoURL: location.photo_thumb,
            total_reviews: parseInt(location.total_star_reviews || '0', 10),
            bars: location.bars,
            url: location.url,
            long_description: location.long_description,
            star_rating: parseFloat(location.avg_star_rating || '0'),
            classic_hunt: location.classic_hunt,
            event_start_time: '',
            event_end_time: '',
            key: location.address_city,
            favorite_count: location.favorite_count,
            wander_list_locations: [],
          };
        }

        // Create a new wander_location object
        const wander_location = {
          locationId: location.id,
          lat: Number(location.lat || 0),
          long: Number(location.long || 0),
          name: location.title,
          description: location.description,
          address: location.address,
          photoLarge: location.photo,
        };

        // Add wander_location to the corresponding hunt
        wanderlists[huntId].wander_list_locations?.push?.(wander_location);
      });

      const wanderlistArray = Object.values(wanderlists);
      dispatchAction(setWanderlists(wanderlistArray));
    }
  }, [JSON.stringify(data || {})]);

  useEffect(() => {
    // @ts-ignore
    let newLocalLocationsList = orderLocationListByDistanceAway(
      // @ts-ignore
      Object.values((localLocations || {}) as TLocation),
      roundedLat ?? 0,
      roundedLong ?? 0,
    ) as TLocalLocation[];

    console.log({localFilters});

    if (localFilters?.hasTitleFilter) {
      const titleFilter = localFilters?.title?.toLowerCase();
      newLocalLocationsList = newLocalLocationsList.filter((location) => {
        return (
          titleFilter &&
          (location?.title?.toLowerCase()?.includes(titleFilter) ||
            location?.guide_description?.toLowerCase()?.includes(titleFilter) ||
            location?.address?.toLowerCase()?.includes(titleFilter))
        );
      });
    }

    if (localFilters?.hasStarFilter) {
      newLocalLocationsList = newLocalLocationsList.filter((location) => {
        let match = 0;

        localFilters.star_ratings?.map?.((star_rating) => {
          console.log({star_rating, locationRating: location.rating});
          if (String(location.rating) == String(star_rating)) {
            match = 1;
          }
        });
        return match;
      });
    }

    if (localFilters?.hasTagFilter) {
      newLocalLocationsList = newLocalLocationsList.filter((location) => {
        let match = 1;

        localFilters.tag_ids?.map?.((tag_id) => {
          // console.log({star_rating, locationRating: location.rating});
          if (!location.tag_ids?.includes(tag_id)) {
            match = 0;
          }
        });
        return match;
      });
    }

    console.log('newLocalLocationsList loaded');

    // Alert.alert(JSON.stringify(newLocalLocationsListWithDistance));
    dispatchAction(setLocalLocationsList(newLocalLocationsList));
  }, [
    roundedLat,
    roundedLong,
    JSON.stringify(localLocations),
    JSON.stringify(localFilters),
  ]);

  return <></>;
};

export default LocalController;

interface LocationData {
  hunt_id: string;
  published: string;
  deleted: string;
  city: string;
  state: string;
  country: string;
  hunt_type: string;
  sub_location: string | null;
  date_created: string;
  city_id: string;
  hunt_version: string;
  route_id: string;
  url: string | null;
  tour_url: string | null;
  app_order: string | null;
  name: string;
  seo_title: string | null;
  moz_linking_domains: string | null;
  moz_page_authority: string | null;
  moz_linking_domain_list: string | null;
  zip_code: string | null;
  sub_location_short: string | null;
  sub_brand: string;
  complete_time: string | null;
  starting_location: string;
  completions: string;
  early_completions: string;
  attempts: string;
  last_updated: string;
  objective: string | null;
  description: string;
  sch_description: string | null;
  highlights: string | null;
  lat_long: string;
  change_log: string | null;
  created_by: string | null;
  question_order: string | null;
  distance_miles: string;
  extra_admission_fees: string | null;
  suggested_transport: string;
  access_code: string;
  long_description: string | null;
  LR_long_description: string | null;
  notes: string | null;
  attention: string | null;
  status: string;
  liked_disliked: string | null;
  problem_questions: string | null;
  avg_star_rating: string;
  total_star_reviews: string | null;
  created_from_hunt_id: string | null;
  corporate_lead_id: string | null;
  groupon_url: string | null;
  groupon_address: string | null;
  groupon_distance: string;
  photo_extra_large: string | null;
  photo_large: string | null;
  photo_medium: string | null;
  photo_thumb: string;
  replacement_hunt: string | null;
  hunt_details_id: string;
  reviews: string | null;
  art_focus: string | null;
  history_focus: string | null;
  culture_focus: string | null;
  difficulty_focus: string | null;
  paused: string;
  commission_paid: string;
  bar_hunt_published: string;
  bars: string | null;
  flag_for_edit: string;
  gen_published: string;
  gen_seo_city: string;
  being_written: string;
  review_html_blob: string | null;
  expedia_url: string | null;
  tripadvisor_url: string | null;
  tripadvisor_product_code: string | null;
  tripadvisor_sh_product_code: string | null;
  tripadvisor_locations_added: string;
  expedia_product_code: string | null;
  expedia_step_done: string;
  tripadvisor_locations_reviewed: string;
  bring_fido_submitted: string;
  bring_fido_live: string;
  moz_info_last_updated: string | null;
  is_free: string;
  is_public: string;
  classic_hunt: boolean;
  photo_source: string | null;
  y_hunt_potential_cities_id: string | null;
  lat: string;
  long: string;
  last_updated_by_name: string;
  last_updated_by_id: string;
  date_last_updated: string;
  top_rated_hunt_in_city: string;
  id: string;
  location_id: string;
  app_challenge_id: string | null;
  rank: string;
  recommendation_id: string | null;
  google_place_id: string;
  title: string;
  address: string;
  location_points: string;
  photo: string;
  post_question_facts: string;
  created_for_corporate_UUID: string | null;
  backup: string;
  website: string | null;
  website_da: string | null;
  wiki: string | null;
  hunter_data: string | null;
  contact_name: string | null;
  email: string | null;
  phone: string | null;
  wiki_live: string;
  main_hunt_id: string | null;
  main_route_id: string | null;
  rating: string;
  user_suggested_rating: string;
  main_hunt_city: string | null;
  guide_description: string | null;
  guide_tips: string | null;
  submitted_by_admin_id: string;
  submitted_by_user_id: string | null;
  submitted_by_user_name: string | null;
  submitted_by_user_image: string | null;
  poi_type: string | null;
  poi_published: string;
  archived: string;
  tag_names: string;
  tag_ids: string;
  guide_faq_intro: string | null;
  guide_faqs: string | null;
  guide_slug: string | null;
  address_city: string;
  photo_challenge_count: string | null;
  challenge_count: string | null;
  video_challenge_count: string | null;
  reference_url: string | null;
  recommendation_type: string | null;
  created_by_name: string;
  created_by_id: string;
  hours: string | null;
  favorite_count: number | null;
  elevation_feet: string | null;
  ugc_location_photo: string | null;
  ugc_location_photo_thumb: string | null;
  ugc_location_photo_user_name: string | null;
  ugc_location_photo_team_name: string | null;
  ugc_location_photo_group_id: string | null;
  ugc_team_photo: string | null;
  ugc_team_photo_thumb: string | null;
  ugc_team_user_name: string | null;
  ugc_team_team_name: string | null;
  ugc_team_group_id: string | null;
}

import constants from 'src/constants';
import React from 'react';
import {ScrollView, View} from 'react-native';
import Badges from 'src/Badges/Badges';
import {BodyText} from './CommonView';

export default function Missions({
  hideHeading = false,
  limit = 0,
  removeBottomPadding = false,
}) {
  const ContainerComponent = limit === 0 ? ScrollView : View;

  return (
    <ContainerComponent
      style={{
        paddingHorizontal: 24,
      }}>
      {!hideHeading && (
        <React.Fragment>
          <BodyText
            text={'Missions'}
            textStyle={{
              color: constants.color.black,
              fontFamily: 'JakartaBold',
              fontWeight: 'bold',
              fontSize: 17,
              lineHeight: 16,
              marginLeft: 0,
              marginRight: 0,
            }}
          />
          <BodyText
            text={
              'Explore your world with Let’s Roam to complete missions, earn points, level up, and top the leaderboard!'
            }
            textStyle={{
              color: constants.color.black,
              fontFamily: 'Jakarta',
              fontWeight: 'bold',
              fontSize: 15,
              marginTop: 15,
              marginLeft: 0,
              marginRight: 0,
            }}
          />
        </React.Fragment>
      )}
      <Badges limit={limit} removeBottomPadding={removeBottomPadding} />
    </ContainerComponent>
  );
}

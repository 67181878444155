import React, {useState, useEffect, useCallback} from 'react';
import {
  View,
  Text,
  ImageBackground,
  PanResponderGestureState,
  NativeSyntheticEvent,
  NativeTouchEvent,
  TouchableOpacity,
} from 'react-native';
import Draggable from 'react-native-draggable';
import {useChallenge} from 'src/Hooks/gameHooks';
import {LinearGradient} from 'expo-linear-gradient';
import {markSelectionChallengeComplete} from 'src/Utils/huntFunctions_v2';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {ContinueButton} from 'src/Modules/CommonView';
import debounce from 'lodash.debounce';
import constants from 'src/constants';

interface ColumnProps {
  height: number;
  checkMatch: (number: number | null, id: number) => void;
  isOpen: boolean;
  id: number;
  value: string;
}

const Column: React.FC<ColumnProps> = ({
  height,
  checkMatch,
  isOpen,
  id,
  value,
}) => {
  const [marginTop, setMarginTop] = useState(0);
  const [number, setNumberValue] = useState(0);
  const [canSetNumber, setCanSetNumber] = useState(true);

  useEffect(() => {
    setInitialPos();
    setNumberValue(parseInt(value));
  }, [height, value]);

  const getList = () => {
    const newList = [];
    for (let i = 0; i < 10; i++) {
      newList.push((number + 6 + i) % 10);
    }
    return newList;
  };

  const dragStop = () => {
    setNumber(number);
  };

  const setNumber = (newNumber: number | null = null) => {
    console.log('setting number', newNumber);
    if (newNumber !== null) {
      checkMatch(canSetNumber ? newNumber : null, id);
    }
  };

  const listUpdate = (isUp: boolean, newNumber: number) => {
    setNumberValue(newNumber);
    setCanSetNumber(true);
    setInitialPos();
  };

  const dragAction = (
    e: NativeSyntheticEvent<NativeTouchEvent>,
    gestureState: PanResponderGestureState,
  ) => {
    // If horizontal movement is detected, reset x position
    if (gestureState.dx !== 0) {
      gestureState.dx = 0;
      return; // Return early, as we don't want to handle the vertical movement for this frame
    }

    // const step = height / 4;
    const offset = gestureState.dy;
    const step = height / 4;
    // const isContinue = prevOffset ? offset === prevOffset : true;
    const isUp = offset > 0;

    if (Math.abs(offset) > step) {
      // Calculate the nearest step position based on the current offset
      const nearestStep = Math.round(offset / step) * step;

      // Update the marginTop to the nearest step position
      setMarginTop(nearestStep);

      // Update the number based on the nearest step position
      const newNumber = (number - Math.floor(nearestStep / step) + 10) % 10;
      setNumberValue(newNumber);
      console.log(newNumber);

      // Check if the user has stopped dragging
      if (Math.abs(gestureState.vy) < 0.1) {
        listUpdate(isUp, newNumber);
      }
    } else {
      setInitialPos();
    }
  };

  const setInitialPos = () => {
    setMarginTop(-height * 1.75);
  };

  const listStyle = {
    marginTop: marginTop,
  };

  const list = getList();

  return (
    <View
      style={{
        height: height,
        display: 'flex',
        flexDirection: 'column',
        paddingHorizontal: 20,
        borderWidth: 1,
        borderColor: '#414f6b',
        shadowColor: 'rgba(0, 0, 0, 0.8)',
        shadowOffset: {width: 0, height: 0},
        shadowOpacity: 1,
        shadowRadius: 10,
        overflow: 'hidden',
      }}>
      <Draggable
        disabled={!!isOpen}
        shouldReverse
        onDragRelease={dragStop}
        onDrag={dragAction}
        x={0}
        y={0}
        maxY={300}
        minY={-300}
        minX={0}
        maxX={0}>
        <View style={listStyle}>
          {list.map((num, index) => (
            <LinearGradient
              key={index}
              colors={[
                '#ffffff',
                '#ccd2d6',
                '#b6babd',
                '#ffffff',
                '#ffffff',
                '#ffffff',
                '#9da0a3',
                '#8c9093',
                '#b0bac2',
                '#b0bac2',
              ]}
              start={{x: 0, y: 0}}
              end={{x: 0, y: 1}}
              locations={[0, 0.09, 0.18, 0.5, 0.53, 0.55, 0.82, 0.91, 0.95, 1]}>
              <View
                key={index}
                style={{
                  height: height / 2,
                  width: height / 2,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Text style={{fontSize: 20, fontWeight: '800'}}>{num}</Text>
              </View>
            </LinearGradient>
          ))}
        </View>
      </Draggable>
    </View>
  );
};

interface CombinationLockProps {
  combination?: string;
  height?: number;
  onMatch?: () => void;
  onChange?: () => void;
  onComplete?: () => void;
  singleCompletion?: boolean;
  openText?: string;
  value?: string;
  mainClass?: string;
  finished?: string;
}

const CombinationLock: React.FC<CombinationLockProps> = ({
  combination,
  onMatch,
  openText,
  height,
  mainClass,
  onComplete,
  ...props
}) => {
  const [checker, setChecker] = useState<number[]>([]);
  const [opened, setOpened] = useState(false);
  const [lastAttempt, setLastAttempt] = useState('');
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [challengeAnswer, setChallengeAnswer] = useState<string[]>([]);
  const [codeNumbers, setCodeNumbers] = useState<number[]>([]);
  const userId = useTypedSelector((state) => state.user?.info?.userId);
  const challenge = useChallenge?.();

  // let challengeAnswer: string[] | undefined = [];

  // if (challenge && challenge.answers && challenge.answers.length > 0) {
  //   challengeAnswer = challenge?.answers[0]?.option?.split('');
  // }

  // const codeNumbers = [...(challengeAnswer || '')].map((num) => +num);

  useEffect(() => {
    if (challenge && challenge.answers && challenge.answers.length > 0) {
      setChallengeAnswer(challenge?.answers[0]?.option?.split('') || []);
      setCodeNumbers(
        challenge?.answers[0]?.option?.split('').map((num: string) => +num) ||
          [],
      );
    }
  }, [challenge]);

  useEffect(() => {
    setLastAttempt(
      !!combination
        ? combination
        : !!/\d/.test(challenge?.completions?.[userId || '']?.option || '') &&
          (challenge?.completions?.[userId || '']?.option || '').length ===
            challengeAnswer?.length
        ? challenge?.completions?.[userId || '']?.option ||
          '0'.repeat(challengeAnswer?.length)
        : '0'.repeat(challengeAnswer?.length),
    );
  }, [userId, challengeAnswer]);

  useEffect(() => {
    if (lastAttempt) {
      setChecker(() => [...lastAttempt.split('').map((num) => +num)]);
    }
  }, [lastAttempt]);

  const debouncedHandleType = useCallback(
    debounce((args) => {
      console.log('markSelectionChallengeComplete', args);
      markSelectionChallengeComplete(args);
      setOpened(args.correct);
      setShowErrorMsg(!args.correct);
      if (!!args.correct) {
        onComplete && onComplete?.();
      }
    }, 1000),
    [],
  );

  const checkCode = (number: number, id: number) => {
    console.log('checking numbers', codeNumbers);
    setShowErrorMsg(false);

    let tmpChecker = [...checker];
    tmpChecker[id] = number;

    setChecker([...tmpChecker]);

    if (
      codeNumbers.length === tmpChecker.length &&
      codeNumbers.every((el, i) => el === tmpChecker[i])
    ) {
      debouncedHandleType({
        challengeId: challenge?.challengeId,
        option: challengeAnswer?.join(''),
        correct: true,
        singleCompletion: true,
        overridePointsEarned: 1000,
      });
    } else {
      debouncedHandleType({
        challengeId: challenge?.challengeId,
        option: tmpChecker?.join(''),
        correct: false,
        overridePointsEarned: 0,
      });
    }
  };

  return (
    <ImageBackground
      style={{
        paddingLeft: 30,
        position: 'relative',
        paddingTop: 30,
        paddingRight: 30,
        paddingBottom: 30,
        marginTop: 20,
        borderRadius: 7,
        maxWidth: 800,
        overflow: 'hidden',
      }}
      source={require('src/Images/combination_metal.jpeg')}>
      <LinearGradient
        colors={['#E6E8E8', '#C5C8CA', '#9CA1A5']}
        start={{x: 0, y: 0}}
        end={{x: 0, y: 1}}
        style={{borderRadius: 4}}>
        <View
          style={{
            padding: 20,
            borderRadius: 4,
            margin: 'auto',
            shadowColor: 'rgba(0, 0, 0, 0.6)',
            shadowOffset: {width: 0, height: 1},
            shadowOpacity: 1,
            shadowRadius: 0,
            position: 'relative',
          }}>
          <View
            style={{
              overflow: 'hidden',
              height: 80,
              width: '100%',
              borderRadius: 7,
              borderWidth: 1,
              borderColor: 'rgba(255, 255, 255, 0.6)',
              shadowColor: 'rgba(0, 0, 0, 0.9)',
              shadowOffset: {width: 0, height: 0},
              shadowOpacity: 1,
              shadowRadius: 15,
              backgroundColor:
                'linear-gradient(to bottom, #c4c4c4 0%,#676767 100%)',
              display: 'flex',
              justifyContent: 'space-around',
              flexDirection: 'row',
            }}>
            {!!combination &&
              !!lastAttempt &&
              lastAttempt
                .split('')
                .map((_, i) => (
                  <Column
                    key={i}
                    id={i}
                    value={lastAttempt[i]}
                    checkMatch={() => null}
                    isOpen={true}
                    height={height || 80}
                  />
                ))}
            {!combination &&
              !!Array.isArray(challengeAnswer) &&
              challengeAnswer.map((_, i) => (
                <Column
                  key={i}
                  id={i}
                  value={lastAttempt[i]}
                  checkMatch={(number: number | null, id: number) => {
                    if (number !== null) {
                      checkCode(number, id);
                    }
                  }}
                  isOpen={
                    opened ||
                    !!challenge?.completions?.[userId || '']?.answeredCorrectly
                  }
                  height={height || 80}
                  {...props}
                />
              ))}
          </View>
          {!opened &&
            !challenge?.completions?.[userId || '']?.answeredCorrectly &&
            !combination &&
            showErrorMsg && (
              <Text
                style={{
                  textAlign: 'center',
                  color: constants?.color?.red,
                  fontWeight: '400',
                  marginTop: 10,
                }}>
                {'Wrong Combination: Try Again'}
              </Text>
            )}
          {!!openText &&
            (opened ||
              !!challenge?.completions?.[userId || '']?.answeredCorrectly ||
              !!combination) && (
              <Text
                style={{
                  textAlign: 'center',
                  color: 'black',
                  fontWeight: '400',
                  marginTop: 10,
                }}>
                {openText}
              </Text>
            )}
        </View>
      </LinearGradient>
    </ImageBackground>
  );
};

export default CombinationLock;

import constants from 'src/constants';
import React, {FC} from 'react';
import {View, Text, TouchableOpacity, StyleSheet, Image} from 'react-native';
import {navigationRef} from 'src/Nav/navigationHelpers';
import UserIcon from 'src/HeaderComponent/UserIcon';
import SimpleLineIcon from 'react-native-vector-icons/SimpleLineIcons';
import FoxShopIcon from './FoxShopIcon';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import getAppType from '../Utils/getAppType';

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hamburgerRow: {
    backgroundColor: constants?.color?.orange,
    height: 3,
    borderRadius: 2,
    margin: 3,
  },
});

interface MainIconProps {
  handlePress?: () => void;
}

export const MainIcon: FC<MainIconProps> = ({handlePress}) => {
  return (
    <TouchableOpacity
      onPress={() => {
        if (typeof handlePress == 'function') {
          handlePress();
        }
      }}
      disabled={!handlePress}
      style={styles.container}>
      <Image
        style={{
          height: 40,
          width: (40 * 2083) / 875,
          marginBottom: 10,
          resizeMode: 'contain',
        }}
        source={require('src/Images/logo4.png')}
      />
    </TouchableOpacity>
  );
};

interface HamburgerProps {
  handlePress: () => void;
  collapsed: boolean;
}

export const Hamburger: FC<HamburgerProps> = ({handlePress, collapsed}) => {
  const rowWidth = collapsed ? 4 : 30;
  return (
    <TouchableOpacity
      style={styles.container}
      activeOpacity={0.8}
      onPress={handlePress}>
      <View style={[styles.hamburgerRow, {width: rowWidth}]}>
        <Text />
      </View>
      <View style={[styles.hamburgerRow, {width: rowWidth}]}>
        <Text />
      </View>
      <View style={[styles.hamburgerRow, {width: rowWidth}]}>
        <Text />
      </View>
    </TouchableOpacity>
  );
};

interface PageTitleProps {
  text: string;
}

export const PageTitle: FC<PageTitleProps> = ({text}) => {
  return (
    <View style={styles.container}>
      <Text
        style={{
          color: getAppType()
            ? constants?.color?.yellow
            : constants?.color?.orange,
          fontFamily: constants?.font.circular,
          fontSize: 18,
          fontWeight: '700',
        }}>
        {text}
      </Text>
    </View>
  );
};

interface SimpleHeaderBarProps {
  showScore?: boolean;
  score?: number;
  showBack?: boolean;
}

export const SimpleHeaderBar: FC<SimpleHeaderBarProps> = ({
  showScore = false,
  score = 0,
  showBack = false,
}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <View
      style={{
        flexDirection: 'row',
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'space-between',
        width,
      }}>
      <View>
        {showBack ? (
          <TouchableOpacity style={{paddingLeft: 10, width: 100}}>
            <SimpleLineIcon
              onPress={() => {
                navigationRef.goBack('SimpleStackNavigator');
              }}
              name={'arrow-left-circle'}
              size={24}
              color={constants?.color?.gray3}
            />
          </TouchableOpacity>
        ) : (
          <UserIcon />
        )}
      </View>
      <MainIcon
        handlePress={() => {
          navigationRef.navigate('LandingPageMain', null, 'Header Component');
        }}
      />
      {!!showScore && (
        <View
          style={{
            backgroundColor: constants?.color?.orange,
            marginRight: 5,
            paddingVertical: 5,
            paddingHorizontal: 10,
            height: 30,
          }}>
          <Text
            style={{
              color: constants?.color?.white,
              fontFamily: constants?.font.circular,
            }}>
            {`${score?.toLocaleString?.()} pts`}
          </Text>
        </View>
      )}
      {!showScore && (
        <View style={{width: 100}}>
          <FoxShopIcon />
        </View>
      )}
    </View>
  );
};

import {ImageBackground, ScrollView, Text, View} from 'react-native';
import {useLocation} from 'src/Hooks/gameHooks';
import {useUserId} from 'src/Hooks/reduxHooks';
import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import {BodyText, MultiChoiceAnswer} from 'src/Modules/CommonView';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import ExpoImage from 'src/Utils/ExpoImage';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import constants from 'src/constants';
import {TChallenge} from 'src/types/TChallenge';

const AllAnswersModal: React.FC = () => {
  const location = useLocation();
  const photoLarge = location?.photoLarge || '';

  const basePhotoUrl = 'photos.letsroam.com';
  const largeDisplayedPhoto = photoLarge
    ? photoLarge.includes(basePhotoUrl) ||
      photoLarge.includes('photos.scavengerhunt.com') ||
      photoLarge.includes('firebasestorage.googleapis.com')
      ? photoLarge
      : `https://${basePhotoUrl}${photoLarge && photoLarge.substring(1)}`
    : '';

  const {width} = useWindowDimensionsSafe();
  const userId = useUserId();
  const teamName = useTypedSelector((state) => state.group?.info?.teamName);
  const players = useTypedSelector(
    (state) => state.group?.info?.players,
    deepEqualityCheck,
  );

  const challengeList = useTypedSelector((state) => {
    const allChallenges = state.game_v2?.allChallenges;
    const challenges: TChallenge[] = [];
    location?.challengeList?.length &&
      location?.challengeList.forEach((key: string) => {
        const locationChallenge = allChallenges && allChallenges[key];
        if (locationChallenge) challenges.push(locationChallenge);
      });
    return challenges;
  });

  // const scrollY = useRef(new Animated.Value(0)).current;

  // const [display, setDisplay] = useState<
  //   | Animated.Value
  //   | Animated.AnimatedInterpolation<string | number>
  //   | 'flex'
  //   | 'none'
  //   | undefined
  // >('flex');
  // // Setting up PanResponder to track touch gestures
  // const panResponder = useRef(
  //   PanResponder.create({
  //     onMoveShouldSetPanResponder: (evt, gestureState) => {
  //       // Return true if user is swiping vertically downwards
  //       return gestureState.dy > 0 && gestureState.dy > gestureState.dx;
  //     },
  //     onPanResponderRelease: (evt, gestureState) => {
  //       // Check if the ScrollView has been swiped down more than 50 units
  //       console.log(gestureState);
  //       if (gestureState.dy > 200) {
  //         // hideScrollView();
  //         setDisplay('none');
  //       } else {
  //         // If not, animate ScrollView back to initial position
  //         setDisplay('flex');
  //       }
  //     },
  //   }),
  // ).current;

  // style={{flex: 1, display: !!isCollapsible ? display : 'flex', zIndex: 1}}>

  // <TouchableOpacity
  // onPress={() => setDisplay('flex')}
  // style={{
  //   // position: 'absolute',
  //   zIndex: 20,
  //   top: 20,
  //   // marginTop: 300,
  //   backgroundColor: 'red',
  //   height: 100,
  //   width: 100,
  // }}></TouchableOpacity>

  // <ScrollView
  // {...panResponder.panHandlers}


  return (
    <ImageBackground
      style={{flex: 1}}
      source={{
        uri: largeDisplayedPhoto,
      }}
      resizeMode="cover">
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          flex: 1,
        }}>
        <View
          style={{
            flex: 1,
            backgroundColor: 'white',
            marginTop: '25%',
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            paddingBottom: 30,
          }}>
          <View style={{alignItems: 'center', marginTop: 12}}>
            <View
              style={{
                height: 5,
                width: 40,
                backgroundColor: '#c4c4c4',
                borderRadius: 40,
              }}></View>
          </View>
          <View style={{marginVertical: 5}}>
            <BodyText
              text={'Answers'}
              textStyle={{
                textAlign: 'center',
                fontWeight: '700',
                fontSize: 24,
                lineHeight: 29,
                fontFamily: 'JakartaBold',
              }}
            />
          </View>
          {Object.values(challengeList || {})?.map((challenge, ix) => (
            <View
              key={`answer-${challenge.challenge_id}-${ix}`}
              style={{
                width,
                backgroundColor: constants?.color?.white,
                paddingVertical: 5,
                position: 'relative',
              }}>
              <View
                style={{marginTop: 0, marginBottom: 10, alignItems: 'center'}}>
                <View
                  style={{flexDirection: 'row', gap: 10, marginHorizontal: 10}}>
                  {challenge?.type === 'spot_the_difference' &&
                    !!challenge?.challengePhoto &&
                    challenge?.challengePhoto?.split(',')?.map((photo, ix) => {
                      if (ix > 1) return;
                      let photoURL =
                        photo.includes('photos.letsroam.com') ||
                        photo.includes('photos.scavengerhunt.com') ||
                        photo.includes('firebasestorage.googleapis.com')
                          ? photo
                          : !!photo
                          ? 'https://photos.letsroam.com/' + photo
                          : '';
                      return (
                        <ExpoImage
                          key={'spot_the_diff_answer_img' + ix}
                          style={{height: 150, flex: 1}}
                          resizeMode="contain"
                          source={{
                            uri: cloudFlareOptimizeImage(photoURL),
                          }}
                        />
                      );
                    })}
                </View>
                {challenge?.type === 'emoji_decoder' &&
                challenge?.challengePhoto ? (
                  <ExpoImage
                    style={{height: 50, width: 200}}
                    resizeMode="contain"
                    source={{
                      uri: cloudFlareOptimizeImage(challenge?.challengePhoto),
                    }}
                  />
                ) : (
                  <BodyText
                    textStyle={{
                      textAlign: 'center',
                      fontWeight: '400',
                      marginHorizontal: 60,
                      fontSize: 15,
                      lineHeight: 16,
                      fontFamily: 'Jakarta',
                      fontStyle: 'italic',
                    }}
                    text={challenge?.question && challenge?.question.match(/\\u[\dA-Fa-f]{4}/) ? JSON.parse(challenge?.question) : challenge?.question || ''}
                  />
                )}
              </View>
              {!!challenge?.answers && (
                <MultiChoiceAnswer
                  answers={challenge?.answers}
                  completions={challenge?.completions || {}}
                  teamName={teamName || 'Team'}
                  players={players}
                  postContent={challenge?.postContent}
                  pointsEarned={0}
                  showMap={challenge?.type === 'where_map'}
                  showComboLock={challenge?.type === 'combination_lock'}
                  showTopAnswers={challenge?.type === 'coworker_feud'}
                  userId={userId || ''}
                  showAllAnswers
                />
              )}
            </View>
          ))}
        </View>
      </ScrollView>
    </ImageBackground>
  );
};

export default AllAnswersModal;

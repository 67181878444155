import React, {useEffect, useState} from 'react';
import {View, TouchableOpacity, ViewStyle} from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import constants from 'src/constants';

interface StarsProps {
  initialRating?: number;
  size?: number;
  color?: string;
  containerStyle?: ViewStyle;
  onRatingChange?: (rating: number) => void;
  rating?: number;
}

const StandardStarRating: React.FC<StarsProps> = ({
  initialRating = 5,
  size = 20,
  color = constants?.color?.yellow,
  containerStyle = {},
  onRatingChange,
  rating,
}) => {
  const [currentRating, setCurrentRating] = useState(initialRating);

  useEffect(() => {
    if (rating !== undefined) {
      setCurrentRating(rating);
    }
  }, [rating]);

  useEffect(() => {
    setCurrentRating(initialRating);
  }, [initialRating]);

  const handleStarPress = (newRating: number) => {
    setCurrentRating(newRating);
    if (onRatingChange) {
      onRatingChange(newRating);
    }
  };

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        ...containerStyle,
      }}>
      {Array.from({length: 5}).map((_, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => handleStarPress(index + 1)}
          activeOpacity={0.7}>
          <FontAwesome
            name={index < currentRating ? 'star' : 'star-o'}
            size={size}
            color={color}
            style={{margin: 2}}
          />
        </TouchableOpacity>
      ))}
    </View>
  );
};

export default StandardStarRating;

import React, {useState} from 'react';
import {View, Text, SafeAreaView} from 'react-native';
import {BodyText, TextEntry} from 'src/Modules/CommonView';

import {navigationRef} from 'src/Nav/navigationHelpers';

import constants from 'src/constants';
import useParams from 'src/Hooks/useParams';
import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';
import {SimpleHeaderBar} from 'src/HeaderComponent/HeaderComponents';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {dispatchAction} from 'src/Utils/helpers';
import {addNewWanderListLocation} from 'src/Redux/reducers/local.reducer';

export default function WanderlistAddLocation() {
  const {editIndex} = useParams<'WanderlistAddLocation'>();

  const [locationTitle, setLocationTitle] = useState<string>('');

  const [description, setDescription] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [lat, setLat] = useState<number>(0);
  const [long, setLong] = useState<number>(0);

  const handleSubmit = () => {
    console.log('handleSubmit');
    if (!locationTitle) {
      return navigationRef.simpleAlert(
        'Please Add More Information',
        'Please enter a title for the location',
      );
    } else if (!description) {
      return navigationRef.simpleAlert(
        'Please Add More Information',
        'Please enter a description',
      );
    } else if (!address || !lat || !long) {
      return navigationRef.simpleAlert(
        'Please Add More Information',
        'Please enter a valid address',
      );
    }

    dispatchAction(
      addNewWanderListLocation({
        index: editIndex,
        location: {
          name: locationTitle,
          description,
          address,
          lat,
          long,
        },
      }),
    );
    navigationRef.goBack('WanderlistAddLocation.tsx');
  };

  return (
    <SafeAreaView style={{flex: 1}}>
      <SimpleHeaderBar />
      <View
        style={{backgroundColor: '#F9F7F2', flex: 1, paddingHorizontal: 24}}>
        <BodyText
          text="ADD WANDERLIST LOCATION"
          textStyle={{
            color: constants.color.orange,
            fontFamily: 'JakartaBold',
            fontSize: 11,
            lineHeight: 11,
            marginTop: 10,
            marginLeft: 0,
            letterSpacing: 1.1,
          }}
        />
        <Text
          style={{
            fontFamily: 'JakartaBold',
            fontSize: 15,
            // lineHeight: 21,
            marginTop: 12,
          }}>
          Title
        </Text>
        <TextEntry
          testID={'search locations'}
          value={locationTitle}
          handleChange={(title) => setLocationTitle(title)}
          placeholder="Enter a title for the location"
          containerStyle={{
            backgroundColor: 'white',
            height: 50,
            borderRadius: 8,
            marginTop: 12,
          }}
          inputStyle={{
            backgroundColor: 'white',
            textAlign: 'left',
            paddingHorizontal: 10,
            marginTop: 10,
          }}
        />
        <Text
          style={{
            fontFamily: 'JakartaBold',
            fontSize: 15,
            lineHeight: 21,
            marginTop: 12,
          }}>
          Description
        </Text>
        <TextEntry
          placeholder="What makes this location special? Why have you included in
        your Wanderlist? What do visitors to this location need to
        know before they go?"
          multiline
          value={description}
          handleChange={(description) => setDescription(description)}
          containerStyle={{
            backgroundColor: 'white',
            borderRadius: 8,
            marginTop: 12,
          }}
          inputStyle={{
            backgroundColor: 'white',
            textAlign: 'left',
            paddingHorizontal: 10,
            height: 80,
            width: '100%',
            borderRadius: 8,
          }}
        />
        <Text
          style={{
            fontFamily: 'JakartaBold',
            fontSize: 15,
            // lineHeight: 21,
            marginTop: 12,
          }}>
          Location Address
        </Text>
        <TouchableOpacity
          style={{
            backgroundColor: 'white',
            height: 50,
            borderRadius: 8,
            marginTop: 12,
          }}
          onPress={() => {
            navigationRef.navigate(
              'AutoCompleteAddressModal',
              {
                onComplete: (newAddress, newLat, newLong) => {
                  console.log({newAddress, newLat, newLong});
                  setAddress(newAddress);
                  setLat(newLat);
                  setLong(newLong);
                },
                initialAddress: address,
                locationName: locationTitle || 'Your Location',
              },
              'WanderlistAddLocation.tsx',
            );
          }}>
          <Text
            style={{
              backgroundColor: 'white',
              textAlign: 'left',
              paddingHorizontal: 10,
              marginTop: 10,
              paddingTop: 10,
            }}>
            {address || 'Select An Address'}
          </Text>
        </TouchableOpacity>
      </View>
      <ModalOmniBar
        omniButton={{
          icon: require('src/Images/logo.png'),
          iconLibrary: 'FontAwesome',
          color: constants?.color?.orange,
          text: 'Add Location To Wander List',
          handlePress: handleSubmit,
        }}
        left={{
          icon: 'arrow-left-circle',
          handlePress: () =>
            navigationRef.navigate(
              'WanderlistCreate',
              null,
              'LocalCreateLocationModal.tsx',
            ),
          text: 'Go back',
        }}
        containerStyle={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </SafeAreaView>
  );
}

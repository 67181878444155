import constants from 'src/constants';
import React from 'react';
import {
  ImageBackground,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
  Image,
  ImageSourcePropType,
  ImageStyle,
} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import SimpleLineIcon from 'react-native-vector-icons/SimpleLineIcons';
import isIphoneX from 'src/Utils/IphoneXHelper';
import ExpoImage from 'src/Utils/ExpoImage';
import {BodyText, HtmlBlock} from 'src/Modules/CommonView/Text';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

interface PrimaryButtonProps {
  text: string;
  handlePress: () => void;
  background?: string;
  knockout?: boolean;
  loading?: boolean;
  secondaryText?: string;
  buttonStyle?: ViewStyle;
  textStyle?: TextStyle;
  shouldRender?: boolean;
}

export const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  text,
  handlePress,
  background,
  knockout,
  loading,
  secondaryText,
  buttonStyle = {},
  textStyle = {},
  shouldRender = true,
}) => {
  const styles = knockout
    ? {
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderColor: background || constants?.color?.orange,
      }
    : {
        backgroundColor: background || constants?.color?.orange,
      };

  const textColor = knockout
    ? background || constants?.color?.orange
    : constants?.color?.white;

  if (!shouldRender) {
    return <View />;
  }

  return (
    <TouchableOpacity
      onPress={handlePress}
      activeOpacity={0.9}
      style={{
        alignItems: 'center',
        flex: 3,
        height: 60,
        borderRadius: 10,
        justifyContent: 'center',
        marginHorizontal: 5,
        marginVertical: 5,
        ...styles,
        ...buttonStyle,
      }}
      disabled={!!loading}>
      {!!loading ? (
        <CustomActivityIndicator size={'small'} color={textColor} />
      ) : (
        <Text
          style={{
            color: textColor,
            fontFamily: constants?.font.circular,
            fontWeight: '700',
            fontSize: 20,
            textAlign: 'center',
            ...textStyle,
          }}>
          {text}
        </Text>
      )}
      {!loading && !!secondaryText && (
        <Text
          style={{
            color: textColor,
            fontFamily: constants?.font.circular,
            fontSize: 14,
            textAlign: 'center',
            ...textStyle,
          }}>
          {secondaryText}
        </Text>
      )}
    </TouchableOpacity>
  );
};

interface SecondaryButtonProps {
  text: string;
  handlePress: () => void;
  background?: string;
  icon?: ImageSourcePropType; // Replace 'any' with the actual type of 'icon'
  knockout?: boolean;
  rightMargin?: boolean;
  loading?: boolean;
  secondaryText?: string;
  buttonStyle?: ViewStyle;
  textStyle?: TextStyle;
  secondaryTextStyle?: TextStyle;
  renderElement?: boolean;
}

export const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  text,
  handlePress,
  background,
  icon,
  knockout,
  rightMargin,
  loading,
  secondaryText,
  buttonStyle = {},
  textStyle = {},
  secondaryTextStyle = {},
  renderElement = true,
}) => {
  const styles = knockout
    ? {
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderColor: background || constants?.color?.blue,
      }
    : {
        backgroundColor: background || constants?.color?.blue,
      };

  const textColor = knockout
    ? background || constants?.color?.blue
    : constants?.color?.white;

  if (!renderElement) {
    return <View />;
  }

  return (
    <TouchableOpacity
      onPress={handlePress}
      activeOpacity={0.9}
      style={{
        alignItems: 'center',
        flex: 1,
        height: 60,
        borderRadius: 10,
        justifyContent: 'center',
        marginLeft: 5,
        marginVertical: 5,
        marginRight: rightMargin ? 5 : 0,
        ...styles,
        ...buttonStyle,
      }}>
      {!!loading && (
        <CustomActivityIndicator size={'small'} color={textColor} />
      )}
      {!loading && !!icon && (
        <ExpoImage
          source={icon}
          style={{width: 22, height: 22, marginBottom: 5}}
          resizeMode={'contain'}
        />
      )}
      {!loading && (
        <Text
          style={{
            color: textColor,
            fontFamily: constants?.font.circular,
            fontSize: 16,
            fontWeight: '700',
            ...textStyle,
          }}>
          {text}
        </Text>
      )}
      {!loading && !!secondaryText && (
        <Text
          style={{
            color: textColor,
            fontFamily: constants?.font.circular,
            fontSize: 12,
            textAlign: 'center',
            ...secondaryTextStyle,
          }}>
          {secondaryText}
        </Text>
      )}
    </TouchableOpacity>
  );
};

interface CancelButtonProps {
  handlePress: () => void;
}

export const CancelButton: React.FC<CancelButtonProps> = ({handlePress}) => (
  <TouchableOpacity
    style={{
      position: 'absolute',
      bottom: isIphoneX() ? 32 : 20,
      height: 60,
      left: 0,
      right: 0,
      alignItems: 'center',
    }}
    onPress={handlePress}
    activeOpacity={0.9}>
    <ExpoImage
      source={require('src/Images/dashboardCancel.png')}
      style={{width: 60, height: 60}}
    />
  </TouchableOpacity>
);

interface ImageButtonProps {
  handlePress: () => void;
  source: ImageSourcePropType;
  buttonStyle?: ViewStyle;
  imageStyle?: ImageStyle;
  resizeMode?: 'contain' | 'cover' | 'stretch' | 'center'; // Replace 'string' with the actual type
  textStyle?: TextStyle;
  text?: string;
  renderElement?: boolean;
  filter?: string;
}

export const ImageButton: React.FC<ImageButtonProps> = ({
  handlePress,
  source,
  buttonStyle = {},
  imageStyle = {},
  resizeMode = 'contain',
  textStyle = {},
  text,
  renderElement = true,
  filter,
}) => {
  if (!renderElement) {
    return <View />;
  }

  return (
    <TouchableOpacity
      style={{
        position: 'relative',
        ...buttonStyle,
      }}
      onPress={handlePress}
      activeOpacity={0.9}>
      <ExpoImage
        source={source}
        style={{width: 60, height: 60, ...imageStyle}}
        resizeMode={resizeMode}
      />
      {!filter && (
        <Text style={{fontFamily: constants?.font.circular, ...textStyle}}>
          {text}
        </Text>
      )}
      {!!filter && (
        <View
          style={{
            position: 'absolute',
            backgroundColor: filter,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}>
          <Text style={{fontFamily: constants?.font.circular, ...textStyle}}>
            {text}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  );
};

interface ContinueButtonProps {
  text: string;
  handlePress: () => void;
  background?: string;
  knockout?: boolean;
  loading?: boolean;
  width?: number; // if not width defaults to flex 1
  height?: number;
  textStyle?: TextStyle;
  buttonStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  menu?: boolean;
  iconImage?: ImageSourcePropType | null;
  renderElement?: boolean;
  icon?: string;
  iconImageStyle?: ImageStyle;
  forceHTML?: boolean;
  testID?: string;
  textTestID?: string;
}

export const ContinueButton: React.FC<ContinueButtonProps> = ({
  text,
  handlePress,
  background,
  knockout,
  loading,
  width,
  height = 40,
  textStyle = {},
  buttonStyle = {},
  containerStyle,
  menu = false,
  iconImage = false,
  renderElement = true,
  icon,
  iconImageStyle = {},
  forceHTML = false,
  testID = 'continueButton',
  textTestID = 'continueButtonText',
}) => {
  const styles = knockout
    ? {
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderColor: background || constants?.color?.orange,
        borderRadius: 10,
        ...containerStyle,
        ...buttonStyle,
      }
    : {
        backgroundColor: background || constants?.color?.orange,
        borderRadius: 10,
        ...containerStyle,
        ...buttonStyle,
      };

  if (!renderElement) {
    return <View />;
  }

  const textColor = knockout
    ? background || constants?.color?.orange
    : constants?.color?.white;

  const innerTextElement = forceHTML ? (
    <HtmlBlock
      html={text || ''}
      htmlStyle={{
        color: textColor,
        fontSize: 16,
        fontFamily: constants?.font.circular,
        fontWeight: '700',
        textAlign: 'center',
        marginRight: !!icon ? 4 : 0,
        ...textStyle,
      }}
    />
  ) : (
    <Text
      testID={textTestID}
      allowFontScaling={false}
      style={{
        color: textColor,
        fontSize: 16,
        fontFamily: constants?.font.circular,
        fontWeight: '700',
        textAlign: 'center',
        marginRight: !!icon ? 4 : 0,
        ...textStyle,
      }}>
      {text}
    </Text>
  );

  return (
    <TouchableOpacity
      style={{
        alignItems: 'center',
        flex: 1,
        width: menu ? 180 : width,
        height,
        minHeight: 40,
        maxHeight: height,
        justifyContent: 'center',
        position: 'relative',
        marginHorizontal: 5,
        marginVertical: 5,
        flexDirection: 'row',
        shadowColor: '#000',
        shadowOffset: {width: 0, height: 1},
        shadowOpacity: 0.3,
        shadowRadius: 3,

        ...styles,
      }}
      testID={testID}
      activeOpacity={0.9}
      onPress={handlePress}
      disabled={!!loading || !handlePress}>
      {!!iconImage && typeof iconImage !== 'boolean' && (
        <ImageBackground
          source={iconImage}
          style={{
            height: 15,
            width: 15,
            marginLeft: 15,
            marginRight: 15,
            ...iconImageStyle,
          }}
        />
      )}
      {!!loading ? (
        <CustomActivityIndicator size={'small'} color={textColor} />
      ) : (
        innerTextElement
      )}
      {!!icon && (
        <SimpleLineIcon
          name={icon}
          size={10}
          color={constants?.color?.gray3_50}
          style={{right: 1, top: -5}}
        />
      )}
    </TouchableOpacity>
  );
};

interface CloseIconButtonProps {
  handlePress: () => void;
  containerStyle?: ViewStyle;
  iconColor?: string;
  side?: 'left' | 'right';
  testID?: string;
}

export const CloseIconButton: React.FC<CloseIconButtonProps> = ({
  handlePress,
  containerStyle = {},
  iconColor = constants?.color?.gray3,
  side = 'left',
  testID = 'close',
}) => {
  const style =
    side === 'right'
      ? {right: 8, top: 8}
      : side === 'left'
      ? {top: 8, left: 8}
      : {top: 8, left: 8};
  return (
    <TouchableOpacity
      testID={testID}
      onPress={handlePress}
      style={{
        alignItems: 'flex-end',
        height: 60,
        justifyContent: 'flex-start',
        position: 'absolute',
        width: 60,
        ...style,
        ...containerStyle,
      }}>
      <SimpleLineIcon
        name="close"
        size={25}
        color={iconColor}
        style={{margin: 5}}
      />
    </TouchableOpacity>
  );
};

interface IconButtonProps {
  handlePress: () => void;
  name?: string;
  buttonStyle?: ViewStyle;
  size?: number;
  iconStyle?: Omit<TextStyle, 'aspectRatio'>;
  color?: string;
  left?: boolean;
  right?: boolean;
  render?: boolean;
  testID?: string;
}

export const IconButton: React.FC<IconButtonProps> = ({
  handlePress,
  name = 'close',
  buttonStyle = {},
  size = 25,
  iconStyle = {},
  color = constants?.color?.orange,
  left = false,
  right = false,
  render = true,
  testID = 'closeIconButton',
}) => {
  const formattedName = name
    .replace('close', 'times')
    .replace('cancel', 'times');
  let positioning = {};
  if (!render) {
    return <View />;
  }
  if (left) {
    positioning = {
      left: 10,
      position: 'absolute',
      top: 10,
    };
  }
  if (right) {
    positioning = {
      right: 10,
      position: 'absolute',
      top: 10,
    };
  }
  return (
    <TouchableOpacity
      onPress={handlePress}
      testID={testID}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        ...positioning,
        ...iconStyle,
        ...buttonStyle,
      }}>
      <Icon
        name={formattedName}
        size={size || 14}
        color={color || constants?.color?.orange}
      />
    </TouchableOpacity>
  );
};

interface FeedbackButtonProps {
  handlePress: () => void;
  text?: string;
  color?: string;
}

export const FeedbackButton: React.FC<FeedbackButtonProps> = ({
  handlePress,
  text = '',
  color = constants?.color?.blue,
}) => (
  <TouchableOpacity
    activeOpacity={0.8}
    onPress={handlePress}
    style={{
      ...constants?.style.boxShadow,
      alignItems: 'center',
      backgroundColor: color,
      borderRadius: 30,
      height: 60,
      justifyContent: 'center',
      marginHorizontal: 5,
      marginVertical: 5,
      width: 60,
    }}>
    <Text
      style={{
        color: constants?.color?.white,
        fontFamily: constants?.font.circular,
        fontSize: 18,
        textAlign: 'center',
      }}>
      {text}
    </Text>
  </TouchableOpacity>
);

interface ExpandFullscreenButtonProps {
  handlePress: () => void;
  isFullscreen?: boolean;
  rightDistanceMinimized?: number;
  rightDistanceMaximized?: number;
  minimizedSize?: number;
  dontAddInsets?: boolean;
}

export const ExpandFullscreenButton: React.FC<ExpandFullscreenButtonProps> = ({
  handlePress,
  isFullscreen,
  rightDistanceMinimized = 10,
  dontAddInsets = false,
  minimizedSize = 30,
  rightDistanceMaximized = 20,
}) => {
  const insets = useSafeAreaInsets();
  return (
    <View
      style={{
        position: 'absolute',
        bottom: isFullscreen ? 20 + (dontAddInsets ? 0 : insets.bottom) : 10,
        right: isFullscreen ? rightDistanceMaximized : rightDistanceMinimized,
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.2,
        shadowRadius: 3,
        elevation: 1,
      }}>
      <TouchableOpacity
        style={{
          height: isFullscreen ? 60 : minimizedSize,
          width: isFullscreen ? 60 : minimizedSize,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: isFullscreen ? 15 : 5,
          ...constants?.style.boxShadow,
          backgroundColor: constants?.color?.white_90,
        }}
        activeOpacity={0.9}
        onPress={handlePress}>
        <SimpleLineIcon
          name={isFullscreen ? 'size-actual' : 'size-fullscreen'}
          size={isFullscreen ? 30 : 18}
          color={constants?.color?.gray3}
        />
      </TouchableOpacity>
    </View>
  );
};

interface InLineIconButtonProps {
  text: string;
  icon: string;
  handlePress: () => void;
  color?: string;
  inline?: boolean;
  size?: number;
}

export const InLineIconButton: React.FC<InLineIconButtonProps> = ({
  text,
  icon,
  handlePress,
  color,
  inline,
  size = 34,
}) => {
  return (
    <TouchableOpacity
      disabled={!handlePress}
      onPress={handlePress}
      activeOpacity={0.9}>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: inline ? 'row' : 'column',
          backgroundColor: '#E8772226',
          borderRadius: 6,
          padding: 6,
          width: 94,
        }}>
        <Icon
          name={icon}
          size={size / 2}
          color={color || constants?.color?.blue}
          style={{fontWeight: '700'}}
        />

        <BodyText
          text={text}
          textStyle={{
            textAlign: 'center',
            fontSize: 14,
            lineHeight: 14,
            marginVertical: 0,
            marginBottom: 5,
            marginHorizontal: 5,
            marginTop: 8,
          }}
        />
      </View>
    </TouchableOpacity>
  );
};

interface ClassicIconButtonProps {
  text: string;
  icon: string;
  handlePress: () => void;
  color?: keyof typeof constants.color;
  inline?: boolean;
  size?: number;
  iconColor: string;
  testID?: string;
  colorOverride?: string;
}

export const ClassicIconButton: React.FC<ClassicIconButtonProps> = ({
  text,
  icon,
  handlePress,
  color,
  colorOverride,
  inline,
  size = 34,
  iconColor,
  testID,
}) => {
  return (
    <TouchableOpacity
      testID={testID || 'classicIconButton'}
      disabled={!handlePress}
      onPress={handlePress}
      activeOpacity={0.9}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: inline ? 'row' : 'column',
      }}>
      <View
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
          backgroundColor: colorOverride
            ? colorOverride
            : color
            ? constants?.color?.[`${color}`] || constants?.color?.blue_30
            : constants?.color?.blue_30,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Icon
          name={icon}
          size={size / 2}
          color={iconColor || 'white'}
          style={{fontWeight: '700'}}
        />
      </View>
      <BodyText
        text={text}
        textStyle={{
          textAlign: 'center',
          fontSize: 14,
          lineHeight: 14,
          marginVertical: 0,
          marginBottom: 5,
          marginHorizontal: 5,
          marginTop: 8,
        }}
      />
    </TouchableOpacity>
  );
};

interface ButtonTileProps {
  handlePress: () => void;
  imageSource?: ImageSourcePropType; // Replace 'any' with the actual type of 'imageSource'
  label: string;
  itemWidth?: number;
  testID?: string;
}

export const ButtonTile: React.FC<ButtonTileProps> = ({
  handlePress,
  imageSource,
  label,
  itemWidth,
  testID,
}) => {
  return (
    <TouchableOpacity
      style={{
        backgroundColor: constants?.color?.white,
        borderRadius: 10,
        marginHorizontal: 5,
        marginVertical: 5,
        position: 'relative',
        width: itemWidth || 100,
      }}
      testID={testID}
      activeOpacity={0.9}
      disabled={!handlePress}
      onPress={handlePress}>
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          paddingVertical: 10,
        }}>
        {!!imageSource && (
          <ExpoImage
            source={imageSource}
            style={{width: 48, height: 48, marginBottom: 5}}
            resizeMode={'contain'}
          />
        )}
        <BodyText
          text={label}
          textStyle={{
            textAlign: 'center',
            fontSize: 16,
            lineHeight: 16,
            marginVertical: 0,
            marginBottom: 10,
          }}
        />
      </View>
    </TouchableOpacity>
  );
};

interface SocialButtonProps {
  text: string;
  icon: ImageSourcePropType;
  handlePress: () => void;
  color?: keyof typeof constants.color;
  inline?: boolean;
  size?: number;
}

export const SocialButton: React.FC<SocialButtonProps> = ({
  text,
  icon,
  handlePress,
  color,
  inline,
}) => {
  return (
    <TouchableOpacity
      disabled={!handlePress}
      onPress={handlePress}
      activeOpacity={0.9}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: inline ? 'row' : 'column',
      }}>
      <View
        style={{
          borderRadius: 8,
          backgroundColor: color
            ? constants?.color?.[`${color}`] || constants?.color?.blue_30
            : constants?.color?.blue_30,
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          paddingVertical: 3,
          paddingHorizontal: 5,
          marginRight: 10,
        }}>
        <Image
          source={icon}
          style={{
            width: 13,
            height: 13,
            resizeMode: 'contain',
            marginRight: 0,
            marginLeft: 0,
          }}
        />
        <BodyText
          text={text}
          textStyle={{
            fontFamily: 'Jakarta',
            fontStyle: 'italic',
            textAlign: 'center',
            fontSize: 13,
            lineHeight: 16,
            marginVertical: 0,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginLeft: 5,
            marginRight: 0,
          }}
        />
      </View>
    </TouchableOpacity>
  );
};

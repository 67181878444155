import useTypedSelector from 'src/Hooks/useTypedSelector';
import React from 'react';

import {HeaderAndCarouselView} from 'src/Modules/CommonView/HeaderAndCarouselView';

import {BodyText, CloseIconButton} from 'src/Modules/CommonView';

import {navigationRef} from 'src/Nav/navigationHelpers';

import {changeSelectedHuntPostCreation} from 'src/Utils/huntFunctions';
import {View} from 'react-native';
import {THunt} from 'src/types/THunt';

const ChangeHuntModal: React.FC = () => {
  const huntsList = useTypedSelector((state) => state.hunts.huntsList);

  const groupId = useTypedSelector((state) => state.group?.info?.groupId);

  const updateGroup = async (selectedHunt: THunt) => {
    // return console.log({selectedHunt});
    await changeSelectedHuntPostCreation(groupId, selectedHunt);
    navigationRef?.goBack('Modules/CommonGame/ChangeHuntModal.tsx');
    navigationRef.simpleAlert(
      'Hunt Changed',
      'Your hunt has been changed to ' + selectedHunt.name,
    );
  };

  return (
    <View style={{flex: 1, justifyContent: 'flex-start'}}>
      <BodyText
        text={'Change Selected Hunt'}
        textStyle={{
          marginHorizontal: 30,
          fontWeight: '700',
          marginBottom: 0,
          fontSize: 20,
          lineHeight: 20,
          marginTop: 20,
        }}
        center
      />

      <HeaderAndCarouselView
        type={'hunt_card'}
        cardPress={updateGroup}
        data={huntsList}
        carouselIndex={0}
        minHeight={400}
        title={'All Activites'}
      />

      <CloseIconButton
        handlePress={() =>
          navigationRef?.goBack('Modules/CommonGame/ChangeHuntModal.tsx')
        }
        side="right"
      />
    </View>
  );
};

//eslint-disable-next-line
export default ChangeHuntModal;

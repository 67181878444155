import useTypedSelector from 'src/Hooks/useTypedSelector';

import React from 'react';
import {View} from 'react-native';
import MapView from 'src/Modules/Native/MapView';

////////////// components
import MapViewDirections from 'src/Modules/MapViewDirections';
import MapMarker from 'src/Modules/MapMarker';

const Map: React.FC<{coordinates: string}> = ({coordinates}) => {
  const latitude =
    useTypedSelector((state) => state.location?.region?.latitude) || 0;
  const longitude =
    useTypedSelector((state) => state.location?.region?.longitude) || 0;

  const split = coordinates.split(',') || '0,0';
  const lat = parseFloat(split[0] + '');

  const long = parseFloat(split[1] + '');

  const origin = latitude
    ? {
        latitude: latitude,
        longitude: longitude,
      }
    : {latitude: 0, longitude: 0};
  const destination = {latitude: lat || 0, longitude: long || 0};

  return (
    <View style={{flex: 1, backgroundColor: 'transparent'}}>
      <MapView
        showsUserLocation
        style={{flex: 1}}
        initialRegion={{
          latitude: Number(lat) || origin.latitude || 0,
          longitude: Number(long) || origin.longitude || 0,
          latitudeDelta: 0.04,
          longitudeDelta: 0.01,
        }}>
        {lat != 0 && long != 0 && !!origin && !!origin?.latitude != null && (
          <MapViewDirections
            originLocation={origin}
            destination={destination}
            mode={'walking'}
          />
        )}
        {!!lat && lat != 0 && !!long && long != 0 && (
          <MapMarker
            setSelectedHuntId={() => {}}
            lat={lat || 0}
            long={long || 0}
            showMarker={true}
          />
        )}
      </MapView>
    </View>
  );
};

export default Map;

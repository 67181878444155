import useTypedSelector from 'src/Hooks/useTypedSelector';
import {navigationRef} from 'src/Nav/navigationHelpers';
import React, {useEffect} from 'react';
import {View} from 'react-native';

///////////////////////////
///////////// components
import {NonChallengeView, BodyText} from 'src/Modules/CommonView';

///////////////////////////
///////////// utils

import {logCustomAnalyticsEvent, updateDataAtPath} from 'src/Utils/fireUtils';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import ChallengeFeedbackElement from 'src/ScavengerHunt_v2/Game/ChallengeFeedbackElement';

const defaultTeamPhoto = {
  uri: cloudFlareOptimizeImage(
    'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648578162_medium.jpg',
  ),
};

const HuntIntro: React.FC = () => {
  const corporateBrandingUrl = useTypedSelector(
    (state) => state.game_v2?.corporateBrandingUrl,
  );

  useEffect(() => {
    logCustomAnalyticsEvent('hunt_started');
  }, []);

  const huntIntro = useTypedSelector((state) => state.game_v2?.huntIntro || '');

  console.log('HuntIntro Render');

  const groupId = useTypedSelector((state) => state.group?.info?.groupId);
  const teamName = useTypedSelector((state) => state.group?.info?.teamName);
  const huntMediumPhotoURL =
    useTypedSelector((state) => state.event?.info?.huntMediumPhotoURL || '') ||
    '';
  const huntPhoto = useTypedSelector((state) => {
    if (
      huntMediumPhotoURL.includes('photos.letsroam.com') ||
      huntMediumPhotoURL.includes('photos.scavengerhunt.com') ||
      huntMediumPhotoURL.includes('firebasestorage.googleapis.com')
    ) {
      return state.group?.info?.huntPhoto;
    } else if (!!huntMediumPhotoURL) {
      return (
        'https://photos.letsroam.com/scavenger_hunt_locations/' +
        huntMediumPhotoURL
      );
    } else {
      return '';
    }
  });

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  const classic_hunt = useTypedSelector(
    (state) => state.group?.info?.classic_hunt,
  );

  console.log({huntPhoto});

  // Replace placeholders in huntIntro
  const formattedHuntIntro = huntIntro
    ?.replace('[TEAM_NAME]', teamName || '')
    ?.replace('[TEAM NAME]', teamName || '')
    ?.replace('[team_name]', teamName || '')
    ?.replace('[team name]', teamName || '');

  const markHuntIntroDone = () => {
    console.log('markHuntIntroDone', {groupId});
    updateDataAtPath(`scavengerhunt/groups/${groupId}`, {
      huntIntroDone: true,
    });
  };

  console.log({corporateBrandingUrl});

  return (
    <NonChallengeView
      titleText={`WELCOME ${teamName?.toUpperCase?.() || 'Team!'}`}
      bodyText={
        formattedHuntIntro ||
        `Welcome to your scavenger hunt. Let's get started with today's adventure!`
      }
      buttonText={`Let's Roam`}
      imageHeight={180}
      handleButtonPress={() => {
        if (isHybridHunt) {
          markHuntIntroDone();
        }

        if (classic_hunt) {
          return navigationRef.navigate(
            'ConfirmModal',
            {
              titleText: 'Some Rules, First!',
              showCloseIcon: true,
              confirmText: `Start Challenges Now`,
              handleConfirm: (_, __, handleDone) => {
                handleDone();
                markHuntIntroDone();
              },
              numberedPoints: [
                {
                  text: `Score points and top the leaderboard by visiting sites, taking photos, and completing challenges.`,
                },
                {
                  text: `You might not have time to finish every challenge, so choose wisely! `,
                },
                {
                  text: `Stay together with your group to earn the most points.`,
                },
              ],
            },
            'ScavengerHunt_v2/Game/HuntIntro.tsx',
          );
        }

        if (!isHybridHunt) {
          return navigationRef.navigate(
            'ConfirmModal',
            {
              titleText: '',
              confirmText: `Start Challenges`,
              showCloseIcon: true,
              handleConfirm: (_, __, handleDone) => {
                handleDone();
                markHuntIntroDone();
              },
              children: (
                <View>
                  <BodyText textStyle={{fontWeight: '700'}} text="Objective" />
                  <BodyText text="For your spot on the leaderboard earn as many points as possible by finding clues hidden in locations around town and completing all the challenges in time." />
                  <BodyText
                    textStyle={{fontWeight: '700'}}
                    text="Some Rules For Your Hunt"
                  />
                  <BodyText
                    textStyle={{paddingLeft: 10}}
                    text="1. First find the location and then solve challenges to earn points."
                  />
                  <BodyText
                    textStyle={{paddingLeft: 10}}
                    text="2. Earn points by answering questions at each location as quickly as possible and as accurately as possible."
                  />
                  <BodyText
                    textStyle={{paddingLeft: 10}}
                    text="3. When you are done with your first site move on to the next one. It is not a race between sites so take your time."
                  />
                  <BodyText
                    textStyle={{paddingLeft: 10}}
                    text="4. Feel free to pause your hunt to take a break or explore."
                  />
                </View>
              ),
            },
            'ScavengerHunt_v2/Game/HuntIntro.tsx',
          );
        }
      }}
      imageSource={
        !!huntPhoto
          ? {uri: cloudFlareOptimizeImage(huntPhoto, 1000, 80)}
          : defaultTeamPhoto
      }
      replaceText={teamName || 'Your Team'}
      brandingSource={corporateBrandingUrl}>
      <ChallengeFeedbackElement
        isSmiley
        promptOverride={'How was registering?'}
        ces_modal_type={'r_registration'}
      />
    </NonChallengeView>
  );
};

export default HuntIntro;

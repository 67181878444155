import constants from 'src/constants';
import React, {FC} from 'react';
import {ImageSourcePropType, View} from 'react-native';
import {OmniBar} from 'src/Modules/TabViews/OmniBar';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

const treasureIcon = require('src/Images/Icons/treasure.png');
const shareIcon = require('src/Images/Icons/shareIconDark.png');
const closeIcon = require('src/Images/Icons/closeIconLight.png');

interface OmniBarItem {
  badge?: string;
  text?: string;
  icon?: string | ImageSourcePropType;
  handlePress?: () => void;
  iconLibrary?: string;
}

interface OmniButton {
  showActivity?: boolean;
  text?: string;
  handlePress?: () => void;
  icon?: string | ImageSourcePropType;
  imageIcon?: string | null;
  iconStyle?: object;
  iconLibrary?: string | null;
  color?: string;
  iconTextOverLay?: string;
  loading?: boolean;
}

interface ModalOmniBarProps {
  left?: OmniBarItem;
  right?: OmniBarItem;
  omniButton?: OmniButton;
  active?: boolean;
  containerStyle?: object;
}

const ModalOmniBar: FC<ModalOmniBarProps> = (props) => {
  const {left, right, omniButton, containerStyle = {}} = props;
  const {width} = useWindowDimensionsSafe();

  return (
    <View
      style={{
        height: 65,
        width,
        position: 'relative',
        backgroundColor: 'white',
        ...containerStyle,
      }}>
      <View style={{position: 'absolute', width, bottom: 0}}>
        <OmniBar
          tabOne={
            !!left
              ? {
                  badge: left.badge,
                  text: left.text || '',
                  icon: left.icon || treasureIcon,
                  handlePress: () => {
                    left?.handlePress?.();
                  },
                }
              : undefined
          }
          balanceLeft={!left}
          omniButton={
            omniButton
              ? {
                  showActivity: omniButton.showActivity || false,
                  text: omniButton.text || 'Close',
                  handlePress: () => {
                    omniButton.handlePress?.();
                  },
                  icon: omniButton.icon || closeIcon,
                  imageIcon: omniButton.imageIcon || null,
                  iconStyle: omniButton.iconStyle || {},
                  iconLibrary: omniButton.iconLibrary || null,
                  color: omniButton.color || constants?.color?.gray3,
                  iconTextOverLay: omniButton.iconTextOverLay || null,
                  loading: omniButton.loading || false,
                }
              : undefined
          }
          balanceRight={!right}
          tabThree={
            right
              ? {
                  badge: right.badge,
                  text: right.text || '',
                  icon: right.icon || shareIcon,
                  handlePress: () => {
                    right.handlePress?.();
                  },
                }
              : undefined
          }
        />
      </View>
    </View>
  );
};

export default ModalOmniBar;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {Alert, Platform, View} from 'react-native';
import {calculateDistance} from 'src/Utils/distanceHelpers';
import {TextLine, DealCard} from 'src/Modules/CommonView';

import {askForHuntInvite} from 'src/Utils/huntInviteFunctions';
import {Linking} from 'react-native';
import moment from 'moment';

import {HeaderAndCarouselView} from 'src/Modules/CommonView/HeaderAndCarouselView';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {useUserId} from 'src/Hooks/reduxHooks';
import {cloudFlareOptimizeImage, dispatchAction} from 'src/Utils/helpers';
import {setEventId} from 'src/Redux/reducers/event.reducer';

import {TNearbyEvent} from 'src/types/TNearbyEvent';

let isDetoxSync: () => boolean;
if (Platform.OS !== 'web') {
  const detoxModule = require('react-native-is-detox');
  isDetoxSync = detoxModule.isDetoxSync;
} else {
  isDetoxSync = () => false;
}

interface HomeNearbyGroupFinderProps {
  joinPage?: boolean;
}

const HomeNearbyGroupFinder: React.FC<HomeNearbyGroupFinderProps> = ({
  joinPage = false,
}) => {
  const eventId = useTypedSelector((state) => state.event?.eventId);
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );
  const region = useTypedSelector((state) => state.location?.region);
  const hasGPSLocationData = useTypedSelector(
    (state) => state.location?.hasGPSLocationData,
  );
  const latitude = region?.latitude || 0;
  const longitude = region?.longitude || 0;
  let nearbyEvents = useTypedSelector((state) => state.nearby?.nearbyEvents);

  const userId = useUserId();

  if (playTestState || isDetoxSync()) {
    return <View />;
  }

  if (
    !hasGPSLocationData &&
    !joinPage &&
    Platform.OS != 'web' &&
    !isDetoxSync()
  ) {
    return (
      <View>
        <DealCard
          resizeMode={'contain'}
          imageSource={{
            uri: cloudFlareOptimizeImage(
              'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648579695_medium.jpg',
            ),
          }}
          handlePress={() => Linking?.openSettings?.()}
          title={'Warning: Location services are OFF'}
          description={`Please enable location permissions by clicking here, so we can find nearby hunts for you to do and guide you from location to location. Without location services the app will not work as expected.`}
          allowLongText
        />
      </View>
    );
  }

  let filteredNearbyEvents: TNearbyEvent[] = [];
  // hiding duplicates
  const alreadyDisplayedEventIds: string[] = [];

  if (window?.location?.search) {
    // is demo
    nearbyEvents = [];

    const urlParams = new URLSearchParams(window.location.search);
    const startingLocation = urlParams.get('startingLocation');
    const company = urlParams.get('company');
    const companyURL = urlParams.get('companyURL');
    const logoURL = `https://logo.clearbit.com/${companyURL}?size=800`;
    console.log({logoURL});
    nearbyEvents.push({
      company: company || 'Your Company',
      eventDate: moment().format('MM/DD'),
      isCorporateEvent: true,
      startingLocation: startingLocation,
      type: 'event_card',
      brandingPhoto:
        companyURL && companyURL != 'null'
          ? logoURL
          : cloudFlareOptimizeImage(
              'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648578460_medium.jpg',
            ),
      createdBy: null,
      lastActive: null,
      lat: null,
      long: null,
      groupId: null,
      discoverable: null,
      name: null,
      description: null,
      eventId: null,
      image: {
        uri:
          companyURL && companyURL != 'null'
            ? logoURL
            : cloudFlareOptimizeImage(
                'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648578460_medium.jpg',
              ),
      },

      distanceAway: null,
      handlePress: undefined,
      huntPhoto: null,
      category: null,
      rightText: null,
    });
  }

  if (!nearbyEvents) {
    return <View />;
  }

  console.log({nearbyEvents});

  nearbyEvents?.map?.((nearbyGroup) => {
    // checking if inactive

    if (!nearbyGroup.isCorporateEvent) {
      if (nearbyGroup.createdBy == userId || !nearbyGroup?.createdBy) {
        return;
      }

      if (nearbyGroup.name?.includes('- Internal Test')) {
        return;
      }

      // Convert lastActive to a timestamp (number) and then do the arithmetic
      const lastActiveTimestamp = nearbyGroup?.lastActive
        ? Number(nearbyGroup.lastActive)
        : null;

      if (
        !lastActiveTimestamp // Check if it's null or 0
      ) {
        return false;
      }

      if ((Date.now() - lastActiveTimestamp) / 1000 > 30 * 60) {
        return false;
      }
    }
    const distanceAway = calculateDistance(
      nearbyGroup.lat ?? 0,
      nearbyGroup.long ?? 0,
      latitude,
      longitude,
      'MILES',
    );
    console.log({distanceAway});
    // only showing hunts that want to be discovered within 3 miles
    if (distanceAway < 5 && nearbyGroup.discoverable) {
      filteredNearbyEvents.push({
        ...nearbyGroup,
        type: 'group_card',
        distanceAway: Math.round(distanceAway * 10) / 10,
        handlePress: () => {
          if (!userId) {
            navigationRef.devAlert('Error: No User Id');
            return navigationRef.navigate(
              'LoginModal',
              {
                message: 'You must log in to use this feature',
              },
              'HomeNearbyGroupFinder',
            );
          }
          console.log('group_card pressed', nearbyGroup?.groupId);
          navigationRef.navigate(
            'ConfirmModal',
            {
              titleText: `Are you sure you want to ask to join ${nearbyGroup?.createdBy} group`,
              titleBodyText:
                'Only invite your friends, family, and people you know to play. Spamming invites will get you banned.',
              confirmText: `Ask ${nearbyGroup?.createdBy} To Play`,
              backgroundColor: constants?.color.black_70,

              cancelText: 'Cancel',
              handleConfirm: (_, __, closeModal) => {
                // @ts-ignore above proves
                askForHuntInvite(nearbyGroup, nearbyGroup?.createdBy);
                closeModal();
              },
            },
            'HomeNearbyGroupFinder',
          );
        },
      });
    }

    if (nearbyGroup.isCorporateEvent) {
      // @ts-ignore above proves
      if (alreadyDisplayedEventIds?.includes(nearbyGroup?.eventId)) {
        return console.log('duplicate');
      } else {
        // @ts-ignore above proves
        alreadyDisplayedEventIds?.push(nearbyGroup?.eventId);
      }

      const alreadyJoined = eventId == nearbyGroup?.eventId;
      let image = nearbyGroup?.brandingPhoto || nearbyGroup?.huntPhoto;
      if (
        !nearbyGroup?.brandingPhoto &&
        nearbyGroup?.category?.includes('bach')
      ) {
        image = cloudFlareOptimizeImage(
          'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648578550_large.jpg',
        );
      }
      if (image == 'https://photos.letsroam.com/scavenger_hunt_locations/') {
        image = cloudFlareOptimizeImage(
          'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648577343_medium.jpg',
        );
      }
      console.log({nearbyGroup, image});

      filteredNearbyEvents.push({
        ...nearbyGroup,
        type: 'custom_card',
        name: `${alreadyJoined ? 'Rejoin' : 'Join'} Custom Event For ${
          nearbyGroup?.company
        } `,
        description:
          `Tap here to join the custom event for ${nearbyGroup?.company} on ${nearbyGroup?.eventDate}.` +
          (nearbyGroup?.startingLocation
            ? `\nStarting Location: ${nearbyGroup?.startingLocation}`
            : ''),
        image: image && image != 'null' ? {uri: image} : null,
        rightText: 'Join Event',
        distanceAway: Math.round(distanceAway * 10) / 10,
        handlePress: () => {
          const formatCode = (code = '') => {
            return code
              ?.toString?.()
              ?.toLowerCase?.()
              ?.trim?.()
              ?.replace?.(/\s/g, '');
          };
          const joinEvent = () => {
            dispatchAction(setEventId(nearbyGroup?.eventId));
            navigationRef.navigate(
              'EventHome',
              null,
              'LandingPage/Home/HomeNearbyGroupFinder.tsx',
            );
          };

          function extractFirstSegment(str: string | null) {
            if (str?.includes?.(' ')) {
              return str.split(' ')[0];
            } else {
              return str;
            }
          }

          if (window?.location?.href?.includes('isDemo')) {
            return joinEvent();
          }

          if (alreadyJoined) {
            return joinEvent();
          }
          navigationRef.navigate(
            'ConfirmModal',
            {
              titleText: `${alreadyJoined ? 'Rejoin' : 'Join'} ${
                nearbyGroup?.company
              } Custom Event`,
              titleBodyText:
                `To join this custom event, please enter the exclusive join code provided by your event planner.\n\nHint: If you can't remember your join code, don't worry! As a backup option, you can also enter the first name of the person who booked your extraordinary event on this screen.` +
                (__DEV__
                  ? '\nDev:' +
                    JSON.stringify({
                      joinCode: nearbyGroup?.joinCode,
                      organizerName: nearbyGroup?.organizerName,
                    })
                  : ''),
              placeholder: 'Join Code or Organizer First Name',
              confirmText: 'Join Event',
              cancelText: 'Cancel',
              showPrompt: true,
              handleConfirm: (promptText, __, handleDone) => {
                const formattedPrompt = formatCode(promptText ?? undefined);
                const codes = [
                  formatCode(nearbyGroup?.joinCode || undefined),
                  formatCode(nearbyGroup?.eventId || undefined),
                  formatCode(
                    extractFirstSegment(nearbyGroup?.organizerName || null) ||
                      undefined,
                  ),
                ];
                // navigationRef.devAlert(codes);
                if (codes?.includes(formattedPrompt)) {
                  handleDone();
                  joinEvent();
                } else {
                  Alert.alert('Incorrect Code', 'Your code did not match.');
                }
              },
            },
            'HomeNearbyGroupFinder',
          );
        },
      });
    }
  });

  if (!filteredNearbyEvents?.[0]) {
    return <View />;
  }

  filteredNearbyEvents = filteredNearbyEvents?.sort((a, b) => {
    if (a.lastActive === null || b.lastActive === null) {
      return 0;
    }
    return a.lastActive < b.lastActive ? 1 : -1;
  });

  if (
    alreadyDisplayedEventIds?.length > 0 &&
    !window?.location?.href?.includes?.('isDemo')
  ) {
    filteredNearbyEvents?.push?.({
      name: `Plan A Team Building Event And Get A Special Offer`,
      description:
        'Plan an epic team building event and get $200 off plus a $100 amazon gift card.',
      // team building dude thumbs up
      image: {
        uri: cloudFlareOptimizeImage(
          'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648578460_medium.jpg',
        ),
      },
      handlePress: () => {
        Linking?.openURL(
          'https://www.letsroam.com/team-building/special-promotion?utm_source=lr_app&utm_medium=events_redirect&utm_campaign=nearby_group_finder',
        );
      },
      company: null,
      eventDate: null,
      eventId: null,
      isCorporateEvent: null,
      startingLocation: null,
      type: null,
      createdBy: null,
      lastActive: null,
      lat: null,
      long: null,
      groupId: null,
      discoverable: null,
      distanceAway: null,
      huntPhoto: null,
      category: null,
      rightText: null,
    });
  }

  console.log({filteredNearbyEvents});

  return (
    <View style={{marginBottom: 10}}>
      {!!joinPage && (
        <View style={{paddingTop: 20, paddingBottom: 10}}>
          <TextLine
            text={'OR'}
            containerStyle={{marginTop: 10, minWidth: 100}}
          />
        </View>
      )}
      <HeaderAndCarouselView
        data={filteredNearbyEvents}
        renderWidth={240}
        type={'custom_card'}
        title={'Nearby Private Events and Groups'}
      />
    </View>
  );
};

export default HomeNearbyGroupFinder;

import * as Application from 'expo-application';
import {Platform} from 'react-native';

const getAppType = () => {
  const appName = Application.applicationName;
  let appType: 'lr' | 'aa' = 'lr';
  try {
    if (appName?.toLowerCase?.().includes?.('assistant')) {
      appType = 'aa';
    }
    if (
      Platform.OS == 'web' &&
      typeof window != 'undefined' &&
      window.location.href?.includes('isAdventureAssistant')
    ) {
      appType = 'aa';
    }
  } catch (e) {}

  return appType;
};

export default getAppType;

import {ScrollView, View} from 'react-native';

import CallSupportLine from 'src/HeaderComponent/CallSupportLine';
import JoinHunt from 'src/JoinRedeem/JoinHunt';
import React from 'react';

///////////////////////////
// components /////////////

const JoinLandingPage: React.FC = () => {
  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <CallSupportLine showBottomShadow />
      <View style={{flex: 1}}>
        <ScrollView style={{flex: 1}}>
          <JoinHunt />
        </ScrollView>
      </View>
    </View>
  );
};

export default JoinLandingPage;

import React from 'react';

import {createStackNavigator} from '@react-navigation/stack';

import UserIcon from 'src/HeaderComponent/UserIcon';
import FoxShopIcon from 'src/HeaderComponent/FoxShopIcon';
import {MainIcon} from 'src/HeaderComponent/HeaderComponents';

import {PageTitle} from 'src/HeaderComponent/HeaderComponents';

import {navigationRef} from 'src/Nav/navigationHelpers';

import {TouchableHighlight} from 'react-native';
import SimpleLineIcon from 'react-native-vector-icons/SimpleLineIcons';
import constants from 'src/constants';

const Stack = createStackNavigator();

interface SimpleStackNavigatorProps<TNav = object> {
  headerText?: string;
  component: React.ComponentType<TNav>; // You might want to specify a more specific type for this prop
  name: string;
  route?: Partial<object | undefined>;
  coinsTestID?: string;
  showBack?: boolean;
}

const SimpleStackNavigator: React.FC<SimpleStackNavigatorProps> = ({
  headerText,
  component,
  name,
  route,
  showBack = false,
}) => {
  return (
    <Stack.Navigator
      screenOptions={() => ({
        title: 'Home',
        headerHideShadow: true,
        gestureEnabled: false,
        headerTitleAlign: 'center',
        cardStyle: {
          flex: 1,
          paddingTop: 0,
        },
        headerStyle: {
          elevation: 0,
          shadowOpacity: 0,
          boxShadow: '',
          shadowColor: 'transparent',
        },
        headerLeft: () =>
          showBack ? (
            <TouchableHighlight style={{paddingLeft: 10}}>
              <SimpleLineIcon
                onPress={() => {
                  navigationRef.goBack('SimpleStackNavigator');
                }}
                name={'arrow-left-circle'}
                size={24}
                color={constants?.color?.gray3}
              />
            </TouchableHighlight>
          ) : (
            <UserIcon />
          ),
        headerTitle: () =>
          headerText ? (
            <PageTitle text={headerText} />
          ) : (
            <MainIcon
              handlePress={() => {
                navigationRef.navigate(
                  'LandingPageMain',
                  null,
                  'SimpleStackNavigator',
                );
              }}
            />
          ),
        headerRight: () => <FoxShopIcon />,
      })}>
      <Stack.Screen
        name={name}
        options={{title: 'home!'}}
        component={component}
        initialParams={route || {}}
      />
    </Stack.Navigator>
  );
};

export default SimpleStackNavigator;

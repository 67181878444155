import constants from 'src/constants';
import React, {useEffect, useRef} from 'react';
import {TextInput, View} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import Icon from 'react-native-vector-icons/FontAwesome';

type SearchBarProps = {
  onBlur?: () => void;
  onFocus?: () => void;
  containerStyle?: object;
  inputStyle?: object;
  onChangeText?: (_text: string) => void;
  placeholder?: string;
  value?: string;
  onClear?: () => void;
  hideShowOnClear?: boolean;
  placeholderColor?: string;
};

const SearchBar: React.FC<SearchBarProps> = ({
  onBlur,
  onFocus,
  containerStyle,
  inputStyle,
  onChangeText,
  placeholder,
  value,
  onClear,
  hideShowOnClear = false,
  placeholderColor,
}) => {
  const searchRef = useRef<TextInput | null>(null);

  // useEffect(() => {
  //   // if (!value && searchRef.current) {
  //   //   searchRef.current?.blur();
  //   // }
  //   // if (value?.length === 1) {
  //   //   setTimeout(() => {
  //   //     searchRef.current?.focus();
  //   //   }, 100);
  //   // }
  // }, [value]);

  return (
    <View
      style={{
        borderTopColor: '#e1e1e1',
        borderBottomColor: '#e1e1e1',
        backgroundColor: constants?.color.gray3,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        position: 'relative',

        ...containerStyle,
      }}>
      <TextInput
        onFocus={onFocus}
        onBlur={onBlur}
        onChangeText={onChangeText}
        autoCorrect={false}
        placeholder={placeholder}
        placeholderTextColor={placeholderColor || constants?.color?.gray3}
        value={value || ''}
        ref={searchRef}
        allowFontScaling={false}
        style={{
          color: constants?.color?.gray3,
          backgroundColor: 'white',
          marginLeft: 10,
          marginTop: 10,
          shadowOffset: {width: 0, height: 2},
          shadowOpacity: 0.15,
          shadowRadius: 3,
          ...inputStyle,
        }}
      />
      <View
        style={{
          position: 'absolute',
          left: 20,
          top: 10,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
        }}>
        <TouchableOpacity>
          <Icon name={'search'} size={14} color={constants?.color?.gray3} />
        </TouchableOpacity>
      </View>
      {!!(value && !hideShowOnClear) && (
        <View
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
          }}>
          <TouchableOpacity
            onPress={()=>onClear && onClear()}
            style={{padding: 5}}>
            <Icon name={'times'} size={16} color={constants?.color?.gray3} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default SearchBar;

import {useMemo} from 'react';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {updatePAChats} from 'src/Redux/reducers/chats.reducer';
import {useFirebaseData} from 'src/Utils/fireUtils';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {TPAChatThread} from 'src/types/TPAChat';

export const PADataUpdater = ({userId}: {userId: string}) => {
  useFirebaseData(
    `paChats/`,
    (userChats: Record<string, TPAChatThread> | null) => {
      if (!userChats || !userId) {
        return;
      }

      const sortedChats: TPAChatThread[] = Object.values(userChats).sort(
        (a, b) => b.createdAt - a.createdAt,
      ); // sort by most recent
      const userRequests = getReduxState((state) => state.chats?.paChats);

      if (sortedChats != userRequests) {
        dispatchAction(updatePAChats(sortedChats));
      }
    },
    'userId', // get matching
    userId,
    false, // hide logs
  );
  return <></>;
};

export const useFilteredPaChats = (active: boolean, index: number = 1, showBothStatus: boolean = false) => {
  const allUserRequests = useTypedSelector((state) => state.chats?.paChats);

  const filteredRequests = useMemo(() => {
    if (!Array.isArray(allUserRequests) || allUserRequests.length === 0) {
      return [];
    }

    let filtered = allUserRequests;

    if(showBothStatus) {
      filtered = filtered.filter(
        (_, ix) =>
          ix < index
      );
    } else {
      // Filter based on the 'active' variable
      filtered = filtered.filter(
        (request, ix) =>
          ix < index &&
          ((active && request.status !== 'Completed') ||
            (!active && request.status === 'Completed')),
      );
    }

    return filtered;
  }, [allUserRequests, active, index]); // Memoize based on changes in allUserRequests, active, and index

  return filteredRequests;
};
// export default PADataUpdater;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import moment from 'moment';
import React, {useEffect} from 'react';
import {Platform, View} from 'react-native';

import {
  setEventId,
  setEventPhotos,
  updateEventGroups,
  updateEventInfo,
} from 'src/Redux/reducers/event.reducer';

import {setDataAtPath, useFirebaseData} from 'src/Utils/fireUtils';

import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {firebaseSubscribeMessaging} from 'src/Utils/loginFunctions';

import {TPhoto} from 'src/types/TPhoto';
import {TEventInfo, isTEventInfo} from 'src/types/TEventInfo';
import {TSimpleGroupInfo} from 'src/types/TSimpleGroup';

interface EventControllerProps {
  eventId: string;
}

const EventControllerWrapper: React.FC = () => {
  const eventId = useTypedSelector((state) => state.event?.eventId);

  useEffect(() => {
    if (Platform.OS == 'web') {
      const urlParams = new URLSearchParams(window.location.search);
      const urlEventId = urlParams?.get('eventId');
      if (urlEventId && urlEventId != 'null') {
        dispatchAction(setEventId(urlEventId));
        mmkvStorage.set('lastEnteredCode', urlEventId);
      }
    }
  }, []);

  // checking on mount if event is too old
  useEffect(() => {
    if (eventId) {
      console.log('checking event age');
      const eventLastActive = mmkvStorage.getString('eventLastActive');
      const a = moment();
      const b = eventLastActive ? moment(eventLastActive) : moment();
      const eventLastActiveDiffHours = a?.diff?.(b, 'seconds'); // =1
      console.log({eventLastActiveDiffHours});
      if (eventLastActiveDiffHours < 10 * 60 * 60) {
        // event still active
        const newLastActive = moment().format('YYYY-MM-DDTHH:mm:ss.sssZ');
        mmkvStorage.set('eventLastActive', newLastActive);
        console.log('eventAge: event still active', {
          eventLastActiveDiffHours,
          eventLastActive,
          a,
          b,
          newLastActive,
        });
      } else {
        mmkvStorage.delete('eventLastActive');
        console.log('eventAge: event not active', {
          eventLastActiveDiffHours,
          eventLastActive,
          a,
          b,
        });
        // event done
        dispatchAction(setEventId(null));
        dispatchAction(updateEventInfo(null));
      }
    }
  }, []);

  useEffect(() => {
    if (eventId) {
      console.log('subscribed to event', {eventId});
      firebaseSubscribeMessaging(eventId);
    }
  }, [eventId]);

  const appLoading = useTypedSelector((state) => {
    const currentScreen = state.current_screen?.currentScreen;
    return ['AppLoadingPage', 'AppIntroSwiper'].includes(currentScreen);
  });
  if (appLoading || !eventId) {
    return <View></View>;
  }
  return <EventController eventId={eventId} />;
};

const EventController: React.FC<EventControllerProps> = ({eventId}) => {
  // getting data

  useFirebaseData(`customEvents/${eventId}`, (eventInfoData: TEventInfo) => {
    // just do a type check because too critical to not return data here
    isTEventInfo(eventInfoData, true);

    dispatchAction(updateEventInfo(eventInfoData));
    console.log({eventInfoData});
  });

  const shouldLoadGroupListener = useTypedSelector((state) => {
    const currentScreen = state.current_screen?.currentScreen;
    return (
      [
        'EventHome',
        'EventHomeStack',
        'EventDashboardWrapper',
        'EventDashboard',
      ].includes(currentScreen) || state.event.hostMaximized
    );
  });

  const shouldLoadPhotoListener = useTypedSelector((state) => {
    const currentScreen = state.current_screen?.currentScreen;
    return [
      'EventDashboard',
      'EventDashboardWrapper',
      // 'EventHomeStack',
      'PhotosModal',
    ].includes(currentScreen);
  });

  console.log('EventController Loaded', {
    eventId,
    shouldLoadGroupListener,
    shouldLoadPhotoListener,
  });

  return (
    <View style={{}}>
      {!!shouldLoadGroupListener && <GroupsListener eventId={eventId} />}
      {!!shouldLoadPhotoListener && <PhotosListener eventId={eventId} />}
      <DataUpdater eventId={eventId} />
    </View>
  );
};

interface GroupsListenerProps {
  eventId: string | null;
}

const GroupsListener: React.FC<GroupsListenerProps> = ({eventId}) => {
  console.log('GroupsListener', {eventId});
  // getting data

  const eventGroupPath = `event/${eventId}/simpleGroups`;

  useFirebaseData(
    eventGroupPath || 'error',
    (groups) => {
      console.log('GroupsListener data', {eventGroupPath});
      const filteredGroups: TSimpleGroupInfo[] = [];
      var eventGroupCount = 0;
      if (groups) {
        Object.keys(groups || {}).reduce((acc, key) => {
          const nextAcc = Object.assign({}, acc);

          // isTSimpleGroupInfo(groups[key], true);

          const group = groups[key] as TSimpleGroupInfo;
          if (group?.groupId) {
            filteredGroups.push(group);
            eventGroupCount++;
          }

          return nextAcc;
        }, {});
      }
      console.log({eventGroupCount});
      dispatchAction(updateEventGroups(filteredGroups));
    },
    // null,
    // null,
    // true,
    // false,
    // true,
  );

  return <View style={{}}></View>;
};

interface PhotosListenerProps {
  eventId: string | null;
}

const PhotosListener: React.FC<PhotosListenerProps> = ({eventId}) => {
  // getting data
  // finding all the photos
  // based on eventId
  useFirebaseData(
    `photos`,
    (groupsPhotoData: Record<string, {photos: TPhoto[]}>) => {
      console.log('the photo data is', groupsPhotoData);

      if (groupsPhotoData) {
        dispatchAction(setEventPhotos(groupsPhotoData));
      }
    },
    'eventId',
    eventId,
  );
  return <View style={{}}></View>;
};

const roundToSecond = (startString: string | null, roundTo = 5) => {
  if (!startString) {
    return 0;
  }
  const roundedString =
    Math.round(moment(startString).seconds() / roundTo) * roundTo;
  return roundedString;
};

interface DataUpdaterProps {
  eventId: string | null;
}

const DataUpdater: React.FC<DataUpdaterProps> = ({eventId}) => {
  const currentGroupId = useTypedSelector(
    (state) => state.user?.info?.currentHuntInfo?.groupId,
  );

  const teamName = useTypedSelector(
    (state) => state.group?.info?.teamName || '',
  );

  const currentScreen = useTypedSelector(
    (state) => state.current_screen?.currentScreen,
  );

  const userId = useTypedSelector((state) => state.user?.userId);
  const firstName = useTypedSelector(
    (state) => state.user?.info?.firstName || 'test',
  );

  const userPhoto = useTypedSelector(
    (state) => state.user?.info?.customPhotoUrl || state.user?.info?.photoUrl,
  );

  const requestNewScreenShot = useTypedSelector((state) => {
    const requestTime = state.user?.info?.requestNewScreenShot;
    if (!requestTime) {
      return null;
    }
    const roundedRequestTime = roundToSecond(String(requestTime));
    return roundedRequestTime;
  });

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (eventId) {
      // saving basic info
      if (userId) {
        setDataAtPath(`event/${eventId || 'error'}/users/${userId}`, {
          userId,
          firstName: firstName || null,
          userPhoto: userPhoto || null,
          currentGroupId: currentGroupId || null,
          userGroupName: teamName || null,
        });
      }
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [
    currentGroupId,
    eventId,
    userPhoto,
    currentScreen,
    firstName,
    requestNewScreenShot,
  ]);

  return <View style={{}}></View>;
};

export default EventControllerWrapper;

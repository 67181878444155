import React, {useState} from 'react';
import {
  SafeAreaView,
  Text,
  View,
  useWindowDimensions,
  ScrollView,
  ImageBackground,
  FlatList,
  TouchableHighlight,
  TouchableOpacity,
  GestureResponderEvent,
} from 'react-native';

import constants from 'src/constants';
import {ContinueButton, DailyChallengesSection} from 'src/Modules/CommonView';
import {useUserId} from 'src/Hooks/reduxHooks';
import {useFirebaseData} from 'src/Utils/fireUtils';
import SubscriptionPrompt from 'src/Modules/LetsRoamPlus/SubscriptionPrompt';

import {LinearGradient} from 'expo-linear-gradient';
import {PlacedTeam} from 'src/CustomEvents/EventLeaderboardCompact';
import ExpoImage from 'src/Utils/ExpoImage';
import {TPrizesLeaderboardItem} from 'src/types/TLeaderBoardItem';

import useTypedSelector from 'src/Hooks/useTypedSelector';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {setLeaderboard} from 'src/Redux/reducers/home.reducer';
import Missions from 'src/Modules/Missions';
const signInImage = require('src/Images/signInImage.png');

export const LeaderboardDataUpdater = () => {
  const dateString = `${new Date().getFullYear()}/${String(
    new Date().getMonth() + 1,
  ).padStart(2, '0')}`;
  useFirebaseData(
    `leaderboard/${dateString}/`,
    (leaderboardUsers: Record<string, TPrizesLeaderboardItem>) => {
      const sortedLeaderboard =
        leaderboardUsers &&
        Object.values(leaderboardUsers)
          .sort((a, b) => b.currentScore - a.currentScore)
          .map((user, ix, leaderboard) => {
            //add a rank to each leaderboard user
            const formattedUser = {...user};
            if (
              ix === 0 ||
              formattedUser.currentScore < leaderboard[ix - 1].currentScore
            ) {
              formattedUser.rank = ix + 1;
            } else {
              formattedUser.rank = leaderboard[ix - 1].rank;
            }
            return user;
          });
      console.log('leaderboard data', {
        dateString,
        leaderboardUsersLength: leaderboardUsers?.length,
      });
      const leaderboard = getReduxState((state) => state.home.leaderboard);
      if (
        sortedLeaderboard &&
        JSON.stringify(sortedLeaderboard) !== JSON.stringify(leaderboard)
      ) {
        dispatchAction(setLeaderboard(sortedLeaderboard));
      }
    },
    'currentScore',
    null,
    false,
    false,
    false,
    'desc',
    50,
  );

  return <></>;
};

const LeaderboardPage = () => {
  console.log('LeaderboardPage render');
  const {width} = useWindowDimensions();

  const userPoints = useTypedSelector(
    (state) => state.user?.info?.currentScore,
  );

  const [paginatedIndex, setPaginatedIndex] = useState(0);

  const userId = useUserId();

  const prizeInstructions = [
    'Everything you do in the Let’s Roam app helps you earn points towards the community leaderboard.',
    'Each month, the user with the most points will take home the grand prize!',
  ];

  const leaderboard = useTypedSelector((state) => state.home.leaderboard);

  const pointRules: Record<
    string,
    {points: string; onPress: (_event: GestureResponderEvent) => void}
  > = {
    'Complete an activity': {
      points: '500',
      onPress: () =>
        navigationRef.navigate('LandingPage', null, 'LeaderboardPage'),
    },
    'Get 1st place on a hunt': {
      points: '1000',
      onPress: () =>
        navigationRef.navigate('LandingPage', null, 'LeaderboardPage'),
    },
    'Complete Missions': {
      points: '50+',
      onPress: () =>
        navigationRef.navigate('AllBadgesModal', {}, 'LeaderboardPage'),
    },
    'Check-in to a Local Stop': {
      points: '50',
      onPress: () =>
        navigationRef.navigate('LocalTab', null, 'LeaderboardPage'),
    },
    'Complete a daily challenge': {
      points: '50',
      onPress: () =>
        navigationRef.simpleAlert(
          'Earn Points By Doing Daily Challenges',
          'Daily Challenges can be found at the bottom of this page and the home page. Complete them to earn points!',
        ),
    },
    'Refer a friend': {
      points: '100',
      onPress: () =>
        navigationRef.navigate('ReferralModal', null, 'LeaderboardPage'),
    },
  };

  const pointRuleKeys = Object.keys(pointRules);

  type pointRuleKeys = keyof typeof pointRules;

  const boxShadow = {
    shadowColor: 'rgba(104, 88, 46, 0.20)',
    elevation: 6,
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 1,
    shadowRadius: 12,
  };

  const isLRAdmin = useTypedSelector(
    (state) => __DEV__ || state.user?.info?.email?.includes?.('letsroam'),
  );

  const monthString = new Date().toLocaleString('default', {month: 'long'});

  const formatName = (winner: TPrizesLeaderboardItem) => {
    if (!winner) return 'User';
    return (
      (winner?.firstName
        ? `${winner?.firstName} ${winner?.lastName}`
        : winner?.contactEmail) || 'User'
    );
  };

  const formatScoreComponent = (score: number, multiplier: number) => {
    return `${score * multiplier} pts (${score} x ${multiplier})`;
  };

  const debugLeaderBoard = (winner: TPrizesLeaderboardItem, place: string) => {
    console.log({winner});
    const checkURL =
      'https://www.letsroam.com/api/v1/users/super_data?user_id=' +
      winner?.userId;
    fetch(checkURL);
    console.log({checkURL});
    navigationRef.simpleAlert(
      `${place} Place Info: ` + formatName(winner),
      `Name: ${formatName(winner)} \n\n` +
        `Total Points: ${winner?.currentScore} \n\n` +
        `Hunts Done: ${formatScoreComponent(
          winner?.currentScoreBreakdown?.activityCount || 0,
          500,
        )} \n` +
        `Daily Challenges Completed: ${formatScoreComponent(
          winner?.currentScoreBreakdown?.dailyChallengesCompleted || 0,
          50,
        )} \n` +
        `First Place Wins: ${formatScoreComponent(
          winner?.currentScoreBreakdown?.firstPlaceWins || 0,
          1000,
        )} \n` +
        `Friend Referrals Done: ${formatScoreComponent(
          winner?.currentScoreBreakdown?.friendReferrals || 0,
          100,
        )} \n` +
        `Local Check Ins Done: ${formatScoreComponent(
          winner?.currentScoreBreakdown?.localCheckIns || 0,
          50,
        )} \n` +
        `Missions Done: ${formatScoreComponent(
          winner?.currentScoreBreakdown?.missions?.missions_completed || 0,
          50,
        )} \n` +
        (isLRAdmin
          ? `Admin Only Info - Email: ${winner?.contactEmail} \nAdmin Only Info - User Id: ${winner?.userId} \n`
          : ''),
    );
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <ScrollView>
        <View style={{justifyContent: 'center', width: width}}>
          <ImageBackground
            source={require('src/Images/airplane_wing.png')}
            style={{
              width: width,
              minHeight: 210,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <LinearGradient
              colors={['#F9F7F2', 'rgba(249, 247, 242, 0.00)']}
              start={{x: 0, y: 1}}
              end={{x: 0, y: 0}}
              style={{
                position: 'absolute',
                bottom: 0,
                height: '50%',
                width: '100%',
              }}>
              <Text
                style={{
                  position: 'absolute',
                  bottom: 8,
                  left: 24,
                  fontFamily: 'Jakarta',
                  fontSize: 11,
                  color: constants.color.orange,
                  letterSpacing: 1.1,
                }}>
                CLIMB THE LEADERBOARD
              </Text>
            </LinearGradient>
            <View>
              <Text
                testID="currentMonth"
                style={{
                  fontFamily: 'JakartaBold',
                  color: constants.color.yellow,
                  fontSize: 28,
                }}>
                {monthString} grand prize:
              </Text>
              <Text
                style={{
                  fontFamily: 'JakartaBold',
                  color: constants.color.white,
                  fontSize: 28,
                }}>
                $400 flight voucher
              </Text>
            </View>
          </ImageBackground>
          <View style={{paddingHorizontal: 24, marginVertical: 15}}>
            {prizeInstructions.map((instruction) => (
              <View
                key={'bullet-' + instruction}
                style={{flexDirection: 'row', gap: 3}}>
                <Text
                  style={{fontFamily: 'Jakarta', fontSize: 14, lineHeight: 21}}>
                  {'\u2022'}
                </Text>
                <Text
                  style={{fontFamily: 'Jakarta', fontSize: 14, lineHeight: 21}}>
                  {instruction}
                </Text>
              </View>
            ))}
          </View>
          <Text
            style={{
              paddingHorizontal: 24,
              marginBottom: 20,
              fontFamily: 'JakartaBold',
              fontSize: 17,
              lineHeight: 16,
            }}>
            {!!userId
              ? `You have ${userPoints || 0} points. Earn more by:`
              : `Log in to view your points. Earn more by:`}
          </Text>

          <ScrollView horizontal style={{height: 90, paddingLeft: 20}}>
            <>
              {/* @ts-ignore */}
              {pointRuleKeys?.map((key: pointRuleKeys, index) => {
                const points = pointRules[key];
                return (
                  <TouchableOpacity
                    onPress={points.onPress}
                    key={String(points) + index}>
                    <View
                      key={index}
                      style={{
                        backgroundColor: constants.color.white,
                        flex: 1,
                        width: 80,
                        borderRadius: 8,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 10,
                      }}>
                      <Text
                        style={{
                          textAlign: 'center',
                          fontSize: 12,
                          fontFamily: 'JakartaBold',
                          paddingHorizontal: 3,
                          paddingTop: 5,
                          lineHeight: 16,
                        }}>
                        {points?.points} points
                      </Text>
                      <Text
                        style={{
                          textAlign: 'center',
                          fontSize: 8,
                          fontFamily: 'Jakarta',
                          lineHeight: 16,
                          paddingHorizontal: 10,
                        }}>
                        {key}
                      </Text>
                    </View>
                  </TouchableOpacity>
                );
              })}
            </>
          </ScrollView>
          <View style={{marginBottom: 20}} />

          <ImageBackground
            testID="LeaderboardWrapper"
            source={require('src/Images/stylized_bg.png')}
            resizeMode={'stretch'}
            imageStyle={{
              tintColor: 'black',
            }}
            style={{
              flex: 1,
              height: 280,
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginBottom: -30,
              backgroundColor: constants.color.teal,
            }}>
            <Text
              style={{
                fontFamily: 'JakartaBold',
                fontSize: 32,
                lineHeight: 48,
                marginTop: 15,
                color: constants?.color?.white,
              }}>
              Leaderboard
            </Text>
            {!!leaderboard && !!leaderboard.length ? (
              <View
                style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
                {!!leaderboard?.[1] && (
                  <PlacedTeam
                    rank={2}
                    photoUrl={
                      leaderboard?.[1]?.lastGroupPhoto ||
                      leaderboard?.[1]?.userPhoto
                    }
                    name={formatName(leaderboard?.[1]) || 'User'}
                    score={leaderboard?.[1]?.currentScore}
                    isLocal={false}
                    handlePress={() => {
                      debugLeaderBoard(leaderboard?.[1], '2nd');
                    }}
                  />
                )}
                {!!leaderboard?.[0] && (
                  <PlacedTeam
                    rank={1}
                    photoUrl={
                      leaderboard?.[0]?.lastGroupPhoto ||
                      leaderboard?.[0]?.userPhoto
                    }
                    name={formatName(leaderboard?.[0]) || 'User'}
                    score={leaderboard?.[0]?.currentScore}
                    isLocal={false}
                    handlePress={() => {
                      debugLeaderBoard(leaderboard?.[0], '1st');
                    }}
                  />
                )}
                {!!leaderboard?.[2] && (
                  <PlacedTeam
                    rank={3}
                    photoUrl={
                      leaderboard?.[2]?.lastGroupPhoto ||
                      leaderboard?.[2]?.userPhoto
                    }
                    name={formatName(leaderboard?.[2]) || 'User'}
                    score={leaderboard?.[2]?.currentScore}
                    isLocal={false}
                    handlePress={() => {
                      debugLeaderBoard(leaderboard?.[2], '3rd');
                    }}
                  />
                )}
              </View>
            ) : (
              <Text
                style={{marginTop: 10, fontFamily: 'Jakarta', fontSize: 20}}>
                Leaderboard empty
              </Text>
            )}
          </ImageBackground>
          {leaderboard?.length > 2 && !!paginatedIndex && (
            <FlatList
              scrollEnabled={false}
              data={leaderboard?.filter(
                (_, ix) => ix > 2 && ix < paginatedIndex,
              )}
              renderItem={({item}) => (
                <TouchableHighlight
                  onPress={() => {
                    debugLeaderBoard(item, '4th+');
                  }}>
                  <View
                    style={{
                      backgroundColor: constants.color.white,
                      marginHorizontal: 40,
                      paddingHorizontal: 15,
                      marginBottom: 20,
                      flex: 1,
                      flexDirection: 'row',
                      minHeight: 72,
                      borderRadius: 10,
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      ...boxShadow,
                    }}>
                    <View
                      style={{
                        flexDirection: 'row',
                        flex: 4,
                        gap: 15,
                        alignItems: 'flex-start',
                      }}>
                      <ExpoImage
                        source={
                          item.userPhoto ? {uri: item.userPhoto} : signInImage
                        }
                        style={{
                          height: 35,
                          width: 35,
                          borderRadius: 35,
                        }}></ExpoImage>
                      <View style={{gap: 5}}>
                        <Text
                          style={{
                            fontSize: 16,
                            fontFamily: 'Jakarta',
                            marginTop: -2,
                          }}>
                          {item?.firstName} {item?.lastName}
                        </Text>
                        <Text
                          style={{
                            fontSize: 14,
                            fontFamily: 'Jakarta',
                            color: constants?.color?.gray2,
                          }}>
                          {item?.currentScore?.toLocaleString()} points
                        </Text>
                      </View>
                    </View>
                    <View style={{flex: 1, alignItems: 'center'}}>
                      <Text
                        style={{
                          fontFamily: 'JakartaBold',
                          color: constants?.color?.gray3,
                          fontSize: 24,
                        }}>
                        {item.rank}
                      </Text>
                    </View>
                  </View>
                </TouchableHighlight>
              )}
              keyExtractor={(_, index) => index.toString()}
            />
          )}
          {leaderboard?.length > 2 && paginatedIndex < 50 && (
            <ContinueButton
              textStyle={{paddingHorizontal: 24}}
              handlePress={() => setPaginatedIndex((prev) => prev + 10)}
              buttonStyle={{minHeight: 50, marginHorizontal: 100}}
              containerStyle={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
              }}
              text="Show more"
            />
          )}
          <View
            style={{
              marginTop: 20,
              paddingHorizontal: 24,
            }}>
            <View
              style={{
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Text
                style={{
                  fontSize: 17,
                  fontFamily: 'JakartaBold',
                }}>
                Missions
              </Text>

              <TouchableOpacity
                onPress={() => {
                  navigationRef.navigate('Missions', null, 'ProfilePage.tsx');
                }}>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants.color.orange,
                  }}>
                  See all
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          <Missions hideHeading={true} limit={3} removeBottomPadding={true} />
          <DailyChallengesSection />
          {!!userId && (
            <SubscriptionPrompt
              title="Explore together"
              titleBold="and earn points."
              description="Click here to share your referral link with friends. They’ll get a free trial of LR+ and you’ll get an extra 100 points for each friend that signs up!"
              referralLink={`https://www.letsroam.com/s/${userId}`}
            />
          )}
          <Text
            style={{
              fontFamily: 'Jakarta',
              fontSize: 12,
              padding: 24,
              lineHeight: 21,
            }}>
            <Text style={{fontFamily: 'JakartaBold'}}>
              Terms and conditions:
            </Text>{' '}
            No purchase necessary to win. Prizes are awarded to the user with
            most points at the end of the month, and are not cumulative. Prizes
            are non-transferable. {`Let’s`} Roam reserves the right to change
            the prize at any time. {`Let’s`} Roam employees and their families
            are not eligible to win.
          </Text>
        </View>
      </ScrollView>
      <LeaderboardDataUpdater />
    </SafeAreaView>
  );
};

export default LeaderboardPage;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import {Platform} from 'react-native';
import React, {useMemo} from 'react';

import EventController from 'src/Controllers/EventController';
import ExpoUpdateController from 'src/Controllers/ExpoUpdateController';
import HuntsController from 'src/Controllers/HuntsController';
import LocalController from 'src/Controllers/LocalController';
import NotificationsController from 'src/Controllers/NotificationsController';
import UserController from 'src/Controllers/UserController';
import VoucherController from 'src/Controllers/VoucherAndRequestsController';

//////// navigation && notifications handler component
import LocationController from 'src/Controllers/LocationController';
import getAppType from '../Utils/getAppType';

// only loading the controllers as they are needed
// aka after app loading page
const ControllersWrapper: React.FC = () => {
  const isAppLoading = useTypedSelector((state) =>
    ['AppLoadingPage'].includes(state.current_screen?.currentScreen),
  );

  return useMemo(() => {
    if (isAppLoading) {
      return <></>;
    }
    console.log('Controllers render', {isAppLoading});

    return (
      <>
        {Platform.OS != 'web' && <NotificationsController />}
        <LocationController />
        <LocalController />
        {getAppType() == 'lr' && <HuntsController />}
        <UserController />
        {getAppType() == 'lr' && <EventController />}
        {Platform.OS != 'web' && getAppType() == 'lr' && <VoucherController />}
        <ExpoUpdateController />
      </>
    );
  }, [isAppLoading]);
};

export default ControllersWrapper;

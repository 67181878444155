import AsyncStorage from '@react-native-async-storage/async-storage';

// Function to add a done hunt to AsyncStorage
async function addDoneHunt(huntId: string, expirationDays: number) {
  console.log('addDoneHunt', {huntId, expirationDays});

  // Retrieve the existing list of done hunts from AsyncStorage
  const doneHuntsString = await AsyncStorage.getItem('doneHunts');
  let doneHunts: {huntId: string; expirationDate: Date}[] = [];

  if (doneHuntsString) {
    // Parse the existing list of done hunts
    doneHunts = JSON.parse(doneHuntsString);
  }

  // Check if the huntId already exists in the list
  const isDuplicate = doneHunts.some((doneHunt) => doneHunt.huntId === huntId);

  if (!isDuplicate) {
    // Calculate the expiration date
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    // Create the new done hunt object
    const newDoneHunt = {
      huntId,
      expirationDate,
    };

    // Add the new hunt to the list
    doneHunts.push(newDoneHunt);

    // Convert the updated list back to a string
    const updatedDoneHuntsString = JSON.stringify(doneHunts);

    console.log({updatedDoneHuntsString});

    // Save the updated list to AsyncStorage
    await AsyncStorage.setItem('doneHunts', updatedDoneHuntsString);

    console.log('Hunt added to the list of done hunts:', newDoneHunt);
  } else {
    console.log('Hunt already exists in the list of done hunts:', huntId);
  }
}

// Function to retrieve valid done hunts from AsyncStorage
async function getValidDoneHunts() {
  // Retrieve the existing list of done hunts from AsyncStorage
  const doneHuntsString = await AsyncStorage.getItem('doneHunts');
  let doneHunts: {huntId: string; expirationDate: Date}[] = [];

  if (doneHuntsString) {
    // Parse the existing list of done hunts
    doneHunts = JSON.parse(doneHuntsString);
  } else {
    return [];
  }

  console.log('getValidDoneHunts', {doneHunts});

  // Get the current date
  const currentDate = new Date();

  const doneHuntIds: string[] = [];

  // Filter the done hunts to retrieve only the valid ones
  const validDoneHunts = doneHunts.filter((doneHunt) => {
    if (!doneHunt?.huntId) {
      return false;
    }

    const isNotExpired = currentDate < doneHunt.expirationDate;
    console.log({
      expirationDate: doneHunt.expirationDate,
      currentDate,
      isNotExpired,
    });
    if (!isNotExpired) {
      doneHuntIds.push(doneHunt.huntId);
    }
    return !isNotExpired;
  });

  console.log({validDoneHunts, doneHuntIds});

  // Convert the valid done hunts back to a string
  const validDoneHuntsString = JSON.stringify(validDoneHunts);

  // Save the valid done hunts list to AsyncStorage, overwriting the previous list
  await AsyncStorage.setItem('doneHunts', validDoneHuntsString);

  console.log('Valid done hunts:', validDoneHunts);

  return doneHuntIds;
}

export {getValidDoneHunts, addDoneHunt};

import constants from 'src/constants';
import React from 'react';
import {Platform, TouchableOpacity, View} from 'react-native';

import {BodyText} from 'src/Modules/CommonView';
import CarouselView from 'src/Modules/CommonView/CarouselView';
import {THunt} from 'src/types/THunt';

interface HeaderAndCarouselViewProps {
  buttonPress?: () => void;
  buttonText?: string;
  buttonColor?: string;
  title?: string;
  subTitle?: string;
  data?: TJSONValue[] | THunt[] | null;
  type?: string;
  cardPress?: (_card: TJSONValue) => void;
  hideIfNoData?: boolean;
  onFocus?: boolean;
  showStars?: boolean;
  extraMargin?: boolean;
  carouselIndex?: number;
  initialMaxLength?: number;
  minHeight?: number;
  imgHeight?: number;
  renderWidth?: number;
}

export const HeaderAndCarouselView: React.FC<HeaderAndCarouselViewProps> = ({
  buttonPress = () => {},
  buttonText,
  buttonColor = constants?.color?.blue,
  title = '',
  subTitle,
  data,
  type = '',
  cardPress,
  showStars = false,
  carouselIndex,
  imgHeight = 115,
}) => {
  return (
    <View>
      <View style={{position: 'relative'}}>
        <BodyText
          text={title}
          textStyle={{
            marginTop: title ? 20 : 0,
            fontWeight: '700',
            marginBottom: Platform.OS === 'web' ? 30 : 0,
            color: '#000',
            fontSize: 17,
            lineHeight: 16,
            fontFamily: 'JakartaBold',
          }}
        />

        {!!buttonText && (
          <TouchableOpacity
            style={{
              position: 'absolute',
              bottom: -4,
              right: 0,
              paddingBottom: 3,
              height: 30,
            }}
            onPress={buttonPress}>
            <BodyText
              text={buttonText}
              textStyle={{
                color: buttonColor,
                fontWeight: '700',
                fontSize: 14,
              }}
            />
          </TouchableOpacity>
        )}
      </View>
      {!!subTitle && !!data?.length && (
        <BodyText text={subTitle} textStyle={{marginTop: 5}} />
      )}
      {!!data && (
        <CarouselView
          data={data}
          cardPress={cardPress}
          type={type}
          showStars={showStars}
          carouselIndex={carouselIndex}
          imgHeight={imgHeight}
        />
      )}
    </View>
  );
};

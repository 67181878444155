import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {View, Text} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import {
  clearLocalFilters,
  setLocalFilters,
} from 'src/Redux/reducers/local.reducer';

import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';
import {BodyText} from 'src/Modules/CommonView/Text';
import {SafeAreaView} from 'react-native-safe-area-context';
import TagInput from 'src/Modules/CommonView/TagInput';
import SearchBar from 'src/Modules/CommonView/SearchBar';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {dispatchAction} from 'src/Utils/helpers';

const LocalFilterModal = () => {
  const localView = useTypedSelector((state) => state.local.localView);
  const {width} = useWindowDimensionsSafe();

  const localFilters =
    useTypedSelector((state) => state.local.localFilters) || {};

  console.log({localView});
  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <SafeAreaView>
        <View style={{padding: 10}}>
          <BodyText
            text={'Filter Local Sites'}
            textStyle={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: 20,
              lineHeight: 20,
            }}
          />
          <BodyText
            textStyle={{
              fontWeight: 'bold',
              fontSize: 18,
            }}>
            {'Search By Name / City / State'}
          </BodyText>
          <SearchBar
            containerStyle={{
              width: width - 20,
              height: 50,
              backgroundColor: 'transparent',
              borderWidth: 0,
              borderBottomColor: 'transparent',
              borderTopColor: 'transparent',
              marginBottom: 10,
            }}
            onChangeText={(text: string) => {
              const newLocalFilters = {
                ...localFilters,
                title: text,
              };
              dispatchAction(setLocalFilters(newLocalFilters));
            }}
            value={localFilters?.title || undefined}
            onFocus={() => {}}
            onClear={() => {
              const newLocalFilters = {
                ...localFilters,
                title: null,
              };
              dispatchAction(setLocalFilters(newLocalFilters));
            }}
            inputStyle={{
              height: 20,
              fontSize: 14,
              backgroundColor: constants?.color?.white1,
              borderRadius: 4,
              marginLeft: 10,
              color: constants?.color?.gray3,
              marginRight: 5,
              paddingLeft: 30,
            }}
            placeholder="Search Local Sites"
          />
          <BodyText
            textStyle={{
              fontWeight: 'bold',
              fontSize: 18,
            }}>
            {'Tags'}
          </BodyText>

          <TagInput
            tag_ids={localFilters?.tag_ids || []}
            handlePress={(newTagIds) => {
              const newLocalFilters = {...localFilters, tag_ids: newTagIds};
              dispatchAction(setLocalFilters(newLocalFilters));
            }}
            tagStyle={{minWidth: 50}}
            tagTextStyle={{fontSize: 12}}
          />
          <BodyText
            textStyle={{
              fontWeight: 'bold',
              fontSize: 18,
            }}>
            {'Star Rating'}
            <Text style={{fontSize: 12, fontWeight: 'normal'}}>
              {' '}
              Tap one or multiple
            </Text>
          </BodyText>

          <TagInput
            tag_ids={localFilters?.star_ratings || []}
            locationTagTypesOverride={[
              {name: 'Any', id: '-1', isDefault: true},
              {name: '1 Star', id: '1'},
              {name: '2 Star', id: '2'},
              {name: '3 Star', id: '3'},
            ]}
            handlePress={(newStarRatings) => {
              const newLocalFilters = {
                ...localFilters,
                star_ratings: newStarRatings,
              };
              dispatchAction(setLocalFilters(newLocalFilters));
            }}
            tagStyle={{
              minWidth: 70,
              borderRadius: 0,
              margin: 0,
              minHeight: 60,
            }}
            tagTextStyle={{fontSize: 12}}
          />
        </View>
      </SafeAreaView>
      <ModalOmniBar
        omniButton={{
          icon: 'check',

          // icon: 'check',
          iconLibrary: 'FontAwesome',
          color: constants?.color?.orange,
          handlePress: () => {
            navigationRef?.goBack('Local/LocalFilterModal.tsx');
          },
          text: `Apply ${localFilters?.filter_count} Filters`,
        }}
        left={{
          icon: 'arrow-left-circle',
          handlePress: () => {
            navigationRef?.goBack('Local/LocalFilterModal.tsx');
          },
          text: 'Go Back',
        }}
        right={{
          icon: 'trash',
          handlePress: () => {
            dispatchAction(clearLocalFilters());
          },
          iconLibrary: 'FontAwesome',
          text: 'Clear Filters',
        }}
        containerStyle={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </View>
  );
};

export default LocalFilterModal;

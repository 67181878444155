import React from 'react';
import {View} from 'react-native';

import Geocoder from 'react-native-geocoding';
// import { useSelector } from 'react-redux';

// Initialize the module (needs to be done only once)
Geocoder.init('AIzaSyBl9aZnUxqdmA_6o6Rr6zSDadHdPVL3ClQ'); // use a valid API key

const LocalCreateChallengeTypeModal: React.FC = () => {
  return (
    <View
      style={{flex: 1, backgroundColor: 'white', alignItems: 'center'}}></View>
  );
};

export default LocalCreateChallengeTypeModal;

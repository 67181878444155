import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useCallback, useRef, useState} from 'react';
import {
  Alert,
  KeyboardAvoidingView,
  TextInput,
  Platform,
  View,
  TouchableOpacity,
} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import {getAuth} from 'src/Utils/db';

import {mmkvStorage} from 'src/Utils/mmkvStorage';

import {
  CloseIconButton,
  ContinueButton,
  BodyText,
} from 'src/Modules/CommonView';
import PhoneInput from 'src/Modules/CommonView/PhoneInput';
import {firebaseLoginWithPhone} from 'src/Utils/loginFunctions';
import {logForCrashlytics} from 'src/Utils/fireUtils';
import useParams from 'src/Hooks/useParams';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {ScrollView} from 'react-native-gesture-handler';
import {getLinks} from 'src/Utils/db';
import {FirebaseAuthTypes} from '@react-native-firebase/auth';

const LoginModalTextForm: React.FC = () => {
  const {width} = useWindowDimensionsSafe();
  const {loginUser, facebookPress, googlePress, onAppleClick, loginType} =
    useParams<'LoginModalTextForm'>();

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [phoneCode, setPhoneCode] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const phoneNumber = useRef<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [showTextValidate, setShowTextValidate] = useState<boolean>(false);
  const [confirmResult, setConfirmResult] =
    useState<FirebaseAuthTypes.ConfirmationResult | null>(null);

  const alertAndResetLoading = (
    message: string,
    secondLine: string | undefined,
  ) => {
    setLoading(false);
    secondLine ? Alert.alert(message, secondLine) : Alert.alert(message);
  };

  const submitFirebaseTextLoginBeforeValidation = useCallback(async () => {
    return new Promise(async (resolve, reject) => {
      logForCrashlytics(
        'submitFirebaseTextLoginBeforeValidation function fired on Login Modal',
      );
      console.log({formattedPhoneNumber: phoneNumber.current});
      if (phoneNumber.current?.length > 5) {
        if (loginType == 'email') {
          const auth = getAuth();

          const loginDynamicLink = await getLinks().buildLink({
            link: `https://www.letsroam.com/login?email=${email}`,
            // domainUriPrefix is created in your Firebase console
            domainUriPrefix: 'https://letsroam.page.link',
            // optional setup which updates Firebase analytics campaign
            // "banner". This also needs setting up before hand
            analytics: {
              campaign: 'banner',
            },
            ios: {
              bundleId: 'com.letsroam.com',
              appStoreId: '1338916789',
            },
            android: {
              packageName: 'com.barhuntv2',
              minimumVersion: '12',
            },
          });

          console.log({loginDynamicLink});

          const actionCodeSettings = {
            handleCodeInApp: true,
            // URL must be whitelisted in the Firebase Console.
            url: loginDynamicLink,
            ios: {
              bundleId: 'com.letsroam.com',
              appStoreId: '1338916789',
            },
            android: {
              packageName: 'com.barhuntv2',
              installApp: true,
              minimumVersion: '12',
            },
          };
          mmkvStorage.set('email', email);
          mmkvStorage.set('phone', phoneNumber.current);
          mmkvStorage.set('firstName', firstName);
          mmkvStorage.set('lastName', lastName);
          auth
            .sendSignInLinkToEmail(email, actionCodeSettings)
            .then((emailSentSuccess) => {
              console.log({emailSentSuccess});
              navigationRef.navigate(
                'EmailSignInBlockingPage',
                null,
                'LoginModalTextForm',
              );
              setLoading(false);
              resolve({});
            })
            .catch((emailSentError) => {
              console.error({emailSentError});
              reject(emailSentError);
            });
        } else {
          console.log('trying firebaseLoginWithPhone');

          firebaseLoginWithPhone(phoneNumber.current)
            ?.then((confirmResult) => {
              if (confirmResult) {
                setConfirmResult(confirmResult);
                setShowTextValidate(true);
                resolve(confirmResult);
              }
            })
            .catch((error: unknown) => {
              reject(error);
            });
        }
      } else {
        Alert.alert('Please Enter Your Phone Number To Log In');
      }
    });
  }, [phoneNumber, email, firstName, lastName]);

  const onTextSubmit = async () => {
    setLoading(true);
    console.log('onTextSubmit', {phoneNumber: phoneNumber.current});
    if (!phoneNumber?.current || phoneNumber?.current?.length < 5) {
      alertAndResetLoading('Please enter a valid phone number!', undefined);
    } else if (!firstName || firstName?.length < 2) {
      alertAndResetLoading('Please enter your first name!', undefined);
    } else if (!lastName || lastName?.length < 2) {
      alertAndResetLoading('Please enter your last name!', undefined);
    } else {
      try {
        logForCrashlytics(
          'The phone number was validated and now the submit press button is firing on LoginModalTextForm',
        );
        const success = await submitFirebaseTextLoginBeforeValidation();
        success && setLoading(false);
      } catch (phoneLoginError) {
        console.log('Sign In With Phone Number Error', {phoneLoginError});
        alertAndResetLoading(
          'Sign In With Phone Number Error',
          phoneLoginError instanceof Error
            ? phoneLoginError.message
            : String(phoneLoginError),
        );
      }
    }
  };

  const renderTextValidate = () => {
    return (
      <View
        style={{
          alignItems: 'center',
          minHeight: 300,
          backgroundColor: 'white',
        }}>
        <View style={{alignItems: 'center', marginVertical: 15}}>
          <BodyText
            text={'Validate Login Code'}
            textStyle={{fontWeight: '700'}}
          />
          <BodyText
            text={
              'You have been texted a validation code which will appear shortly. It can take up to 15 seconds to receive your code.'
            }
            textStyle={{
              marginHorizontal: 20,
              fontSize: 14,
              textAlign: 'center',
            }}
          />
        </View>
        <TextInput
          style={{
            backgroundColor: constants?.color?.white1,
            borderRadius: 10,
            marginVertical: 10,
            fontFamily: constants?.font.circular,
            color: constants?.color?.gray3,
            height: 40,
            width: 220,
            marginBottom: 15,
          }}
          allowFontScaling={false}
          testID={'phoneCode'}
          autoCorrect={false}
          blurOnSubmit
          keyboardType={'numeric'}
          onChangeText={(phoneCode) => setPhoneCode(phoneCode)}
          placeholder="4356"
          returnKeyType="done"
          textAlign={'center'}
          underlineColorAndroid={constants?.color?.white}
          value={phoneCode}
        />
        <ContinueButton
          handlePress={() => {
            // eslint-disable-next-line no-use-before-define
            onTextValidate();
          }}
          text={'Enter Code'}
          containerStyle={{width: 220}}
          testID={'textLoginValidateCodeButton'}
        />
      </View>
    );
  };

  const handleOnValidateDone = async () => {
    const trimmedPhone = `${phoneNumber?.current}`.trim();
    if (!trimmedPhone || trimmedPhone.length < 5) {
      console.error('Invalid phone number');
      return navigationRef.simpleAlert(
        'Invalid phone number',
        'Please enter a valid phone number',
      );
    }
    console.log('handleOnValidateDone', {trimmedPhone});
    const phoneDoneNewUser = {
      email: `${trimmedPhone}`.trim(),
      contactEmail: email?.toLowerCase?.(),
      contactPhone: trimmedPhone,
      firstName,
      lastName,
      //isPhone: true,
    };
    console.log({phoneDoneNewUser});
    navigationRef?.goBack('LandingPage/LoginModalTextForm.tsx');
    await loginUser(phoneDoneNewUser);
  };

  const onTextValidate = () => {
    if (!confirmResult) {
      return console.log('no confirm result');
    }
    try {
      // phoneCode is the confirmation code
      confirmResult
        .confirm(phoneCode || '')
        .then(async (textUser) => {
          console.log('logging in user', {textUser});
          await handleOnValidateDone();
        })
        .catch(async (error: unknown) => {
          if (
            (error instanceof Error ? error.message : String(error))?.includes(
              'auth/session',
            )
          ) {
            await handleOnValidateDone();
            return console.log(
              'logging in irregardless 1, since they had an expired yet valid code',
            );
          }
          alertAndResetLoading(
            `There was an error with your code, you still may have been signed in:`,
            error instanceof Error ? error.message : String(error),
          );
        });
    } catch (error: unknown) {
      if (
        (error instanceof Error ? error.message : String(error))?.includes(
          'auth/session',
        )
      ) {
        handleOnValidateDone();
        return console.log(
          'logging in irregardless 2, since they had an expired yet valid code',
        );
      }
      alertAndResetLoading(
        `There was an error with your code, you still may have been signed in`,
        error instanceof Error ? error.message : String(error),
      );
    }
  };

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
      }}>
      <KeyboardAvoidingView
        style={{
          flex: 1,
          justifyContent: 'flex-end',
        }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        enabled>
        <View style={{height: 520}}>
          <ScrollView style={{height: 520}}>
            <View style={{position: 'relative'}}>
              {!!showTextValidate && renderTextValidate()}
              {!showTextValidate && (
                <View
                  style={{
                    alignItems: 'center',
                    minHeight: 520,
                    position: 'relative',
                    backgroundColor: constants?.color?.white1,
                  }}>
                  <View style={{alignItems: 'center', marginVertical: 15}}>
                    <BodyText
                      testID="clostKeyboard"
                      text={
                        loginType == 'email'
                          ? 'Sign In With Email Link'
                          : 'Sign In With Text!'
                      }
                      textStyle={{fontWeight: '700'}}
                    />
                  </View>
                  <View style={{flexDirection: 'row'}}>
                    <View
                      style={{
                        marginRight: 5,
                        marginLeft: 10,
                      }}>
                      <BodyText
                        text={'First Name'}
                        textStyle={{
                          fontSize: 14,
                          marginLeft: 5,
                          marginBottom: 10,
                        }}
                      />
                      <TextInput
                        style={{
                          backgroundColor: constants?.color?.white,
                          borderRadius: 10,
                          color: constants?.color?.gray3,
                          fontFamily: constants?.font.circular,
                          height: 40,
                          width: width / 2 - 15,
                        }}
                        allowFontScaling={false}
                        testID={'firstName'}
                        textAlign={'center'}
                        placeholder="First Name"
                        autoComplete={'name-given'}
                        value={firstName}
                        autoCorrect={false}
                        underlineColorAndroid={constants?.color?.white}
                        onChangeText={(firstName) => setFirstName(firstName)}
                        blurOnSubmit
                      />
                    </View>
                    <View
                      style={{
                        marginLeft: 5,
                        marginRight: 10,
                      }}>
                      <BodyText
                        text={'Last Name'}
                        textStyle={{
                          fontSize: 14,
                          marginLeft: 5,
                          marginBottom: 10,
                        }}
                      />
                      <TextInput
                        style={{
                          backgroundColor: constants?.color?.white,
                          borderRadius: 10,
                          fontFamily: constants?.font.circular,
                          color: constants?.color?.gray3,
                          height: 40,
                          width: width / 2 - 15,
                        }}
                        allowFontScaling={false}
                        textAlign={'center'}
                        testID={'lastName'}
                        placeholder="Last Name"
                        autoComplete={'name-family'}
                        value={lastName}
                        autoCorrect={false}
                        underlineColorAndroid={constants?.color?.white}
                        onChangeText={(lastName) => setLastName(lastName)}
                        blurOnSubmit
                      />
                    </View>
                  </View>
                  <View style={{marginHorizontal: 10}}>
                    <BodyText
                      text={'Email Address'}
                      textStyle={{
                        fontSize: 14,
                        marginLeft: 5,
                        marginBottom: 10,
                      }}
                    />
                    <TextInput
                      style={{
                        backgroundColor: constants?.color?.white,
                        borderRadius: 10,
                        color: constants?.color?.gray3,
                        fontFamily: constants?.font.circular,
                        height: 40,
                        width: width - 20,
                      }}
                      allowFontScaling={false}
                      testID={'email'}
                      textAlign={'center'}
                      placeholder="Email"
                      autoComplete={'email'}
                      value={email}
                      keyboardType="email-address"
                      autoCorrect={false}
                      underlineColorAndroid={constants?.color?.white}
                      onChangeText={(email) => setEmail(email)}
                      blurOnSubmit
                    />
                  </View>
                  <View style={{marginHorizontal: 10}}>
                    <BodyText
                      text={'Phone Number'}
                      textStyle={{
                        fontSize: 14,
                        marginLeft: 5,
                        marginBottom: 10,
                      }}
                    />
                    <PhoneInput
                      onValueChange={(newPhoneNumber) => {
                        phoneNumber.current = newPhoneNumber;
                      }}
                    />
                  </View>
                  <ContinueButton
                    text={
                      loginType == 'email'
                        ? 'Send Email Sign In Link'
                        : 'Validate Number'
                    }
                    handlePress={() => {
                      console.log(
                        'On text Submit fired for Validate Phone Number Button',
                      );
                      onTextSubmit();
                    }}
                    testID={'textLoginValidateButton'}
                    containerStyle={{
                      width: width - 60,
                      marginTop: 20,
                      marginHorizontal: 30,
                    }}
                    loading={loading}
                  />

                  <TouchableOpacity
                    onPress={() => {
                      if (__DEV__) {
                        setEmail('mike@letsroam.com');
                        setFirstName('Mike');
                        setLastName('Harding');
                        phoneNumber.current = '+14078089625';
                      }
                    }}>
                    <BodyText
                      text={'Or sign in with'}
                      textStyle={{
                        fontSize: 14,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    />
                  </TouchableOpacity>
                  <View style={{flexDirection: 'row', marginHorizontal: 30}}>
                    <ContinueButton
                      text={'Google'}
                      handlePress={() => {
                        logForCrashlytics(
                          'Google sign in pressed on LoginModalTextForm',
                        );
                        googlePress();
                      }}
                      background={'#dd4b39'}
                      containerStyle={{
                        width: width / 2 - 35,
                        marginLeft: 0,
                        marginRight: 10,
                      }}
                    />
                    <ContinueButton
                      text={'Facebook'}
                      handlePress={() => {
                        logForCrashlytics(
                          'Facebook sign in pressed on LoginModalTextForm',
                        );
                        facebookPress();
                      }}
                      background={'#3B5998'}
                      containerStyle={{
                        width: width / 2 - 35,
                        marginLeft: 10,
                        marginRight: 0,
                      }}
                    />
                  </View>

                  {Platform.OS === 'ios' && (!!__DEV__ || !!playTestState) && (
                    <ContinueButton
                      handlePress={onAppleClick}
                      background={'#000'}
                      containerStyle={{
                        width: 220,
                        marginTop: 10,
                        justifyContent: 'flex-start',
                      }}
                      iconImageStyle={{height: 20, width: 20}}
                      text={'Sign in with Apple'}
                      iconImage={require('src/Images/Icons/apple.png')}
                    />
                  )}
                </View>
              )}
              <TouchableOpacity
                style={{
                  height: 30,
                  width: 30,
                  marginTop: 10,
                  backgroundColor: __DEV__ ? 'red' : 'transparent',
                  position: 'absolute',
                  right: 0,
                  top: 20,
                }}
                onPress={() => {
                  console.log('mockTextLogin clicked');
                  phoneNumber.current = '+14078089626';
                  handleOnValidateDone();
                }}
                testID={'mockTextLogin'}>
                <View></View>
              </TouchableOpacity>
              <CloseIconButton
                handlePress={() => {
                  logForCrashlytics('Go back pressed on LoginModalTextForm');
                  if (showTextValidate) {
                    setShowTextValidate(false);
                  } else {
                    navigationRef?.goBack('LandingPage/LoginModalTextForm.tsx');
                  }
                }}
              />
            </View>
          </ScrollView>
        </View>
      </KeyboardAvoidingView>
    </View>
  );
};
export default LoginModalTextForm;

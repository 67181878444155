import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TGroupInfo} from 'src/types/TGroupInfo';

interface GroupReducerState {
  info?: TGroupInfo | null; // Assuming info is an object; refine the type if you have a detailed structure for it.
}

const initialState: GroupReducerState = {
  info: null,
};

const groupInfoSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setGroupInfo(
      state: GroupReducerState,
      action: PayloadAction<TGroupInfo | null>,
    ) {
      console.log('setGroupInfo fired');

      if (action.payload) {
        console.log('setGroupInfo', {groupInfo: action.payload});
        state.info = {...action.payload};
      }
    },
    clearGroupInfo(state: GroupReducerState) {
      state.info = null;
    },
  },
});

export const {setGroupInfo, clearGroupInfo} = groupInfoSlice.actions;
export default groupInfoSlice.reducer;

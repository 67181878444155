import {createSlice, PayloadAction, Slice} from '@reduxjs/toolkit';
import {defaultThemes} from 'src/Data/DefaultThemes';
import {TThemesState} from 'src/types/TTheme';

const initialState: TThemesState = defaultThemes;

const themesSlice: Slice<TThemesState> = createSlice({
  name: 'themeInfo',
  initialState,
  reducers: {
    setThemes(state, action: PayloadAction<TThemesState>) {
      // Replace 'any' with the actual payload type.
      console.log('SET_THEMES: new themes set');
      console.log('SET_THEMES: new themes set');

      if (action.payload.themes) {
        state.themes = {...state.themes, ...action.payload.themes};
      }
      if (action.payload.characters) {
        state.characters = {...state.characters, ...action.payload.characters};
      }
    },
  },
});

export const {setThemes} = themesSlice.actions;
export default themesSlice.reducer;

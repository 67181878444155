import {combineReducers, Reducer, AnyAction} from '@reduxjs/toolkit';
import communityPhotosReducer from 'src/Redux/reducers/communityPhotos.reducer';
import dailyChallengesReducer from 'src/Redux/reducers/dailyChallenges.reducer';
import eventReducer from 'src/Redux/reducers/event.reducer';
import firstVisitReducer from 'src/Redux/reducers/firstVisit.reducer';
import playerReducer from 'src/Redux/reducers/playerChallenges.reducer';
import gameV2Reducer from 'src/Redux/reducers/game_v2.reducer';
import groupInfoReducer from 'src/Redux/reducers/groupInfo.reducer';
import homeReducer from 'src/Redux/reducers/home.reducer';
import huntsReducer from 'src/Redux/reducers/hunts.reducer';
import localReducer from 'src/Redux/reducers/local.reducer';
import nearby from 'src/Redux/reducers/nearby.reducer';
import saveAppInfoReducer from 'src/Redux/reducers/app_info.reducer';
import badgesInfoReducer from 'src/Redux/reducers/badges.reducer';
import saveCurrentScreenReducer from 'src/Redux/reducers/current_screen.reducer';
import saveTicketValuesReducer from 'src/Redux/reducers/ticket_vouchers.reducer';
import saveUserInfoReducer from 'src/Redux/reducers/user_info.reducer';
import saveUserLocationReducer from 'src/Redux/reducers/user_location.reducer';
import themesReducer from 'src/Redux/reducers/themes.reducer';
import logsReducer from 'src/Redux/reducers/logs.reducer';
import chatsReducer from 'src/Redux/reducers/chats.reducer';
import userPhotosReducer from 'src/Redux/reducers/user_photo.reducer';

interface AppState {
  app_info: ReturnType<typeof saveAppInfoReducer>;
  badges: ReturnType<typeof badgesInfoReducer>;
  communityPhotos: ReturnType<typeof communityPhotosReducer>;
  logs: ReturnType<typeof logsReducer>;
  current_screen: ReturnType<typeof saveCurrentScreenReducer>;
  dailyChallenges: ReturnType<typeof dailyChallengesReducer>;
  event: ReturnType<typeof eventReducer>;
  firstVisit: ReturnType<typeof firstVisitReducer>;
  playerChallenges: ReturnType<typeof playerReducer>;
  game_v2: ReturnType<typeof gameV2Reducer>;
  group: ReturnType<typeof groupInfoReducer>;
  home: ReturnType<typeof homeReducer>;
  hunts: ReturnType<typeof huntsReducer>;
  local: ReturnType<typeof localReducer>;
  location: ReturnType<typeof saveUserLocationReducer>;
  nearby: ReturnType<typeof nearby>;
  themeInfo: ReturnType<typeof themesReducer>;
  ticket_vouchers: ReturnType<typeof saveTicketValuesReducer>;
  user: ReturnType<typeof saveUserInfoReducer>;
  photos: ReturnType<typeof userPhotosReducer>;
  chats: ReturnType<typeof chatsReducer>;
}

const appReducer: Reducer<AppState, AnyAction> = combineReducers({
  app_info: saveAppInfoReducer,
  badges: badgesInfoReducer,
  communityPhotos: communityPhotosReducer,
  logs: logsReducer,
  current_screen: saveCurrentScreenReducer,
  dailyChallenges: dailyChallengesReducer,
  event: eventReducer,
  firstVisit: firstVisitReducer,
  playerChallenges: playerReducer,
  game_v2: gameV2Reducer,
  group: groupInfoReducer,
  home: homeReducer,
  hunts: huntsReducer,
  local: localReducer,
  location: saveUserLocationReducer,
  nearby,
  themeInfo: themesReducer,
  ticket_vouchers: saveTicketValuesReducer,
  user: saveUserInfoReducer,
  chats: chatsReducer,
  photos: userPhotosReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer<AppState, AnyAction> = (
  state: AppState | undefined,
  action: AnyAction,
) => {
  if (action.type === 'HARD_RESET') {
    console.log('RESETTING ALL REDUCERS');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;

import React from 'react';
import {Text, View, ScrollView, Image, ImageBackground} from 'react-native';
import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import {ContinueButton} from 'src/Modules/CommonView';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import {markHasReviewedHybridCharacter} from 'src/Utils/huntFunctions';
import constants from 'src/constants';

const HybridCharacterReveal = () => {
  const userId = useTypedSelector((state) => state.user?.userId) || '';
  const groupInfo = useTypedSelector((state) => state.group?.info);

  const players =
    useTypedSelector(
      (state) => state.group?.info?.players,
      deepEqualityCheck,
    ) || {};

  // sort players by when they joined the game
  const sortedPlayersList = Object.values(players).sort((a, b) => {
    const aJoin = a?.timeAdded || 0;
    const bJoin = b?.timeAdded || 0;
    return aJoin > bJoin ? 1 : -1;
  });

  // get the index of the user in the sorted list
  const myGroupUserIndex = sortedPlayersList.findIndex((player) => {
    return player.userId == userId;
  });

  // assign the character based on the index
  // there are 6 characters so we our index will be 0 - 5
  // if we have more than 6 players in the group, we will loop back to the beginning
  const myCharacter = Object.values(groupInfo?.customCharacters ?? {})[
    myGroupUserIndex % 6
  ];

  const characterPhoto = myCharacter?.image;

  return (
    <ImageBackground
      style={{flex: 1}}
      source={{
        uri: cloudFlareOptimizeImage(
          `https://photos.letsroam.com/scavenger_hunt_locations/${groupInfo?.huntPhoto}` ||
            '',
        ),
      }}>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          justifyContent: 'flex-end',
        }}>
        <View
          style={{
            alignItems: 'center',
          }}>
          <Image
            source={{
              uri: characterPhoto?.includes('https://firebasestorage.googleapis.com') ? characterPhoto : `https://photos.letsroam.com/${characterPhoto}`,
            }}
            style={{
              width: 288,
              height: 288,
              marginHorizontal: 'auto',
              resizeMode: 'contain',
            }}
          />
        </View>

        <View
          style={{
            paddingHorizontal: 10,
            paddingBottom: 20,
            backgroundColor: constants.color.white,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            marginTop: -20,
          }}>
          <Text
            style={{
              fontSize: 30,
              letterSpacing: -0.5,
              fontFamily: 'JakartaBold',
              marginTop: 30,
            }}>
            {myCharacter?.name}
          </Text>
          <Text
            style={{
              fontSize: 25,
              letterSpacing: -0.5,
              fontFamily: 'Jakarta',
              fontStyle: 'italic',
            }}>
            {myCharacter?.title}
          </Text>
          <Text
            style={{
              fontSize: 15,
              letterSpacing: -0.5,
              fontFamily: 'Jakarta',
              fontStyle: 'italic',
              marginTop: 10,
            }}>
            {myCharacter?.description}
          </Text>
          <CharacterInfoBox
            title="Read & Share"
            description={myCharacter?.intro || ''}
          />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 15,
            }}>
            <View style={{width: 33, height: 40}}>
              <Image
                source={require('src/Images/lock.png')}
                style={{
                  width: '100%',
                  height: '100%',
                  resizeMode: 'cover',
                }}
              />
            </View>
            <Text
              style={{
                fontSize: 15,
                letterSpacing: -0.5,
                fontFamily: 'Jakarta',
                fontStyle: 'italic',
                maxWidth: 160,
                textAlign: 'center',
                marginLeft: 20,
              }}>
              Don’t share your secret with the other players.
            </Text>
          </View>
          <CharacterInfoBox
            title="Your Secret"
            description={myCharacter?.secret || 'Secret'}
          />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}>
            <ContinueButton
              text={'Go to challenges'}
              textStyle={{fontFamily: 'JakartaBold'}}
              background={constants.color.orange}
              containerStyle={{
                marginTop: 30,
                borderRadius: 6,
                marginHorizontal: 'auto',
              }}
              width={200}
              height={50}
              handlePress={() => {
                if (groupInfo?.groupId) {
                  markHasReviewedHybridCharacter(userId, groupInfo?.groupId);
                }
              }}
            />
          </View>
        </View>
      </ScrollView>
    </ImageBackground>
  );
};

type CharacterInfoBoxProps = {
  title: string;
  description: string;
};

const CharacterInfoBox: React.FC<CharacterInfoBoxProps> = ({
  title,
  description,
}) => {
  return (
    <View style={{marginTop: 15}}>
      <View
        style={{
          backgroundColor: constants.color.orange,
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        }}>
        <Text
          style={{
            color: constants.color.white,
            fontSize: 20,
            letterSpacing: -0.5,
            fontFamily: 'JakartaBold',
            paddingLeft: 20,
            paddingTop: 10,
            paddingBottom: 12,
          }}>
          {title}
        </Text>
      </View>
      <View
        style={{
          backgroundColor: '#D9D9D6',
          borderBottomLeftRadius: 12,
          borderBottomRightRadius: 12,
          paddingHorizontal: 20,
          paddingTop: 11,
          paddingBottom: 17,
          shadowColor: 'black',
          shadowOffset: {width: 0, height: 14},
          shadowOpacity: 0.18,
          shadowRadius: 44,
          elevation: 20,
        }}>
        <Text
          style={{
            fontSize: 15,
            letterSpacing: -0.5,
            fontFamily: 'Jakarta',
            fontStyle: 'italic',
          }}>
          {description}
        </Text>
      </View>
    </View>
  );
};

export default HybridCharacterReveal;

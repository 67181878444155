import React from 'react';

const {Image: ExpoImageNative} = require('expo-image');

import {
  ImageResizeMode,
  ImageSourcePropType,
  ImageStyle,
  Image,
  Platform,
} from 'react-native';

export type TFastImageSource = ImageSourcePropType | undefined;

interface FastImageNativeProps {
  source?: TFastImageSource;
  resizeMode?: ImageResizeMode;
  style?: ImageStyle;
}

const ExpoImage = (props: FastImageNativeProps = {}): JSX.Element => {
  console.log('ExpoImageSrc', props.source);
  // Catching empty prompt error
  if (!props?.source) {
    console.error('FastImageNative: no source provided');
    return <></>;
  }

  const validResizeModes: string[] = ['cover', 'contain', 'stretch', 'center'];

  const getValidResizeMode = (mode: string = 'cover'): ImageResizeMode => {
    if (validResizeModes.includes(mode)) {
      return mode as 'cover' | 'contain' | 'stretch' | 'center';
    }
    console.error(
      `FastImageNative: Invalid resizeMode value: ${mode}. Defaulting to 'cover'`,
    );
    return 'cover'; // Default to 'cover' if an invalid value is provided
  };

  // removing empty uris like ''
  if (
    props?.source &&
    props?.source instanceof Object &&
    'uri' in props?.source &&
    (!props.source.uri ||
      props.source.uri == '' ||
      typeof props.source.uri !== 'string')
  ) {
    console.error('FastImageNative: non-url source provided with uri 1', {
      bad: props?.source,
    });
    return <></>;
  }

  // removing non file uris
  if (
    props?.source &&
    props?.source instanceof Object &&
    'uri' in props?.source &&
    props.source.uri &&
    !props.source.uri?.includes?.('http') &&
    !props.source.uri?.includes?.('file:/')
  ) {
    console.error('FastImageNative: non-url source provided with uri 2', {
      bad: props?.source,
    });
    return <></>;
  }

  const newProps = {...props, resizeMode: getValidResizeMode(props.resizeMode)};

  // console.log({ fastImageSource: props?.source, style: props?.style });

  if (Platform.OS == 'web') {
    return <Image source={props.source} {...newProps} />;
  }

  return <ExpoImageNative {...newProps} />;
};

// console.log({ deviceFullName });

export default ExpoImage;

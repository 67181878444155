import React from 'react';

import {navigationRef} from 'src/Nav/navigationHelpers';

export const addLocationToGuide = () => {
  // has already seen guide

  navigationRef.navigate(
    'ConfirmModal',
    {
      handleConfirm: async (_, __, goBack) => {
        if (typeof goBack == 'function') {
          goBack();
        }
        navigationRef.navigate(
          'LocalCreateLocationModal',
          null,
          'Local/localFunctions.ts',
        );
      },
      numberedPoints: [
        {
          title: 'Be Specific',
          text: 'Try not to list a broad area and instead list a specific part of that area. E.g. Don’t say see the British Museum, but say a specific part of the museum—  see the Rosetta Stone!',
        },
        {
          title: 'Use The Would You Recomend Test',
          text: 'Imagine your best friend is visiting town, would you recommend the site to your best friend? ',
        },
        {
          title: 'Be Unique and Fun',
          text: 'Add sites that are unique. Try to surprise and delight readers with unique history or areas that locals would recommend.',
        },
      ],
      confirmText: 'Create Local Guide',
      cancelText: 'Maybe Later',
      titleText: 'Tips To Create A Great Location',
      titleBodyText: `Sites should be specific areas around town that are odd, surprising, interesting, or unique. After each experience people should feel like they gained something of cultural significance or uniqueness to the city. It should feel like a local had told them about this “secret” spot.
      `,
    },
    'Local/localFunctions.ts',
  );
};

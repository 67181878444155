import constants from 'src/constants';
import React, {useEffect, useState} from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  Text,
  View,
  TouchableHighlight,
  Alert,
  useWindowDimensions,
  ImageSourcePropType,
  TouchableOpacity,
} from 'react-native';
import {BodyText, CloseIconButton} from 'src/Modules/CommonView';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import {navigationRef} from 'src/Nav/navigationHelpers';
import {ContinueButton} from 'src/Modules/CommonView/Buttons';
import ExpoImage from 'src/Utils/ExpoImage';
import {MaskedTextInput} from 'react-native-mask-text';
import {BulletItem} from 'src/ExplorerPass/AboutExplorerPass';

import {apiChargeCard} from 'src/Utils/apiCalls';
import {useUserId} from 'src/Hooks/reduxHooks';
import useParams from 'src/Hooks/useParams';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';
import DropDown from 'src/Modules/DropDown';
import {logCustomAnalyticsEvent, logSaleData} from 'src/Utils/fireUtils';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import getAppType from 'src/Utils/getAppType';
import CheckBox from 'src/Modules/Native/CheckBox';

const PurchaseModal: React.FC = () => {
  const [creditCard, setCreditCard] = React.useState<string>('');
  const [saveWithMembership, setSaveWithMembership] =
    React.useState<boolean>(false);

  const {
    handleDone,
    successMessage,
    forcedTheme,
    showMembershipOnly = getAppType() == 'aa',
  } = useParams<'PurchaseModal'>();

  console.log({handleDone});

  const hasExplorerPass = !!useExplorerPass();

  // checking if have explorer pass on load
  // if it does doesn't do it
  useEffect(() => {
    if (hasExplorerPass) {
      Alert.alert(
        'You already have an explorer pass. You can start a hunt by going to the hunt.',
      );
      navigationRef?.goBack('FoxShop/PurchaseModal.tsx');
    } else {
      logCustomAnalyticsEvent('view_item', {
        item_category: 'tickets_and_membership',
      });
    }
  }, []);

  const [cvc, setCvc] = React.useState<string>('');

  const [expiryMonth, setExpiryMonth] = React.useState<string>('');
  const [expiryYear, setExpiryYear] = React.useState<string>('');
  const insets = useSafeAreaInsets();
  const [stage, setStage] = useState<number>(showMembershipOnly ? 2 : 1);

  function isBetween(str: string, lower: number, upper: number) {
    const num = Number(str);
    return !isNaN(num) && num >= lower && num <= upper;
  }

  const [isMembership, setIsMembership] = useState<boolean>(
    showMembershipOnly || false,
  );

  const isLRAdmin = useTypedSelector(
    (state) => __DEV__ || state.user?.info?.email?.includes?.('letsroam'),
  );

  function checkIfIsPotentiallyValidCard(cardNumber = '') {
    // Remove all non digit characters
    const digits = cardNumber?.replace(/\D/g, '') || '';

    // A valid credit card number should be at least 13 digits and at most 19 digits
    if (digits.length < 16) {
      console.log('still typing', {cardNumber});
      return true;
    } else if (digits.length > 19) {
      console.log('too long', {cardNumber});
      return false;
    }

    let sum = 0;
    let shouldDouble = false;
    // Start from the rightmost digit and work our way left
    for (let i = digits.length - 1; i >= 0; i--) {
      let digit = parseInt(digits.charAt(i));

      if (shouldDouble) {
        if ((digit *= 2) > 9) digit -= 9;
      }

      sum += digit;
      shouldDouble = !shouldDouble;
    }

    // The card number is valid if the sum modulo 10 is 0
    const checkSumPasses = sum % 10 == 0 ? true : false;

    console.log({checkSumPasses});
    return checkSumPasses;
  }

  const isPotentiallyValidCard = checkIfIsPotentiallyValidCard(creditCard);

  const data = Array.from({length: 10}, (value, key) => {
    const ticketCount = key + 1;
    return {
      value: ticketCount,
      key,
      label: `${ticketCount} ${ticketCount === 1 ? 'Ticket' : 'Tickets'}`,
    };
  });

  let themeOptions = [
    {value: 'Standard', key: 1, label: 'Standard Tickets ($11.99 / Players)'},
    {
      value: 'Date Night',
      key: 1,
      label: 'Date Themed Tickets ($19.99 / Person)',
    },
  ];

  if (forcedTheme == 'Standard') {
    themeOptions = [
      {value: 'Standard', key: 1, label: 'Standard Tickets ($11.99 / Players)'},
    ];
  } else if (forcedTheme == 'Date Night') {
    themeOptions = [
      {
        value: 'Date Night',
        key: 1,
        label: 'Date Themed Tickets ($19.99 / Person)',
      },
    ];
  }

  const membershipPrice = getAppType() == 'lr' ? '19.99' : '49.99';

  const {width} = useWindowDimensions();

  const [ticketsQuantity, setTicketsQuantity] = useState<number>(2);

  const [theme, setTheme] = useState<string>(forcedTheme || 'Standard');

  const total = (theme == 'Date Night' ? 19.99 : 11.99) * ticketsQuantity;

  const [loading, setLoading] = useState<boolean>(false);

  const userId = useUserId();

  const email = useTypedSelector((state) => state?.user?.info?.email);
  const firstName = useTypedSelector((state) => state?.user?.info?.firstName);

  const last4Digits = useTypedSelector(
    (state) => state?.user?.info?.last4Digits,
  );
  const stripeCustomerToken = useTypedSelector(
    (state) => state?.user?.info?.stripeCustomerToken,
  );

  const [stripeCustomerTokenToUse, setStripeCustomerTokenToUse] = useState<
    string | null
  >(stripeCustomerToken || null);

  const hasCardOnFile = !!last4Digits && !!stripeCustomerToken;

  const cardOptions = [
    {value: null as string | null, key: 1, label: 'Use New Card'},
  ];

  if (hasCardOnFile) {
    cardOptions.unshift({
      value: stripeCustomerToken,
      key: 2,
      label: `Use Saved Card Ending In ${last4Digits}`,
    });
  }

  const savings = Number((total - 19.99).toFixed(2));
  const savingsMessage =
    savings > 0
      ? `Save $${savings} by getting an annual pass for only $19.99 / month. Cancel any time.`
      : 'Save by getting an annual pass for only $19.99 / month. Cancel any time.';

  console.log({hasCardOnFile, last4Digits, stripeCustomerToken});

  useEffect(() => {
    if (!userId) {
      navigationRef.navigate('LoginModal', {}, 'FoxShop/PurchaseModal.tsx');
    }
  }, []);

  const isValidCVC = isBetween(cvc, 1, 10000);
  const isValidExpiryMonth = isBetween(expiryMonth, 1, 12);
  const isValidExpiryYear = isBetween(
    expiryYear,
    new Date().getFullYear(),
    new Date().getFullYear() + 100,
  );

  const doApiCall = async (makeFree = false) => {
    setTimeout(() => {
      setLoading(false);
    }, 10000);
    setLoading(true);

    if (!userId) {
      setLoading(false);
      return Alert.alert('Please sign in');
    }

    if (!stripeCustomerTokenToUse) {
      if (!creditCard || `${creditCard}`.length < 13) {
        setLoading(false);
        return Alert.alert('Please enter a credit card number');
      }
      if (!cvc || `${cvc}`.length < 3) {
        setLoading(false);
        return Alert.alert('Please enter a cvc code');
      } else if (!expiryMonth) {
        setLoading(false);
        return Alert.alert('Please enter an expiration month');
      } else if (!expiryYear || `${expiryYear}`.length < 3) {
        setLoading(false);
        return Alert.alert(
          'Please enter an expiration year in the format YYYY',
        );
      }
    }

    const trialPeriodDays = isMembership ? 7 : 0;

    try {
      const apiChargeCardAttemptResult = await apiChargeCard({
        user_id: userId ?? 0,
        email: email?.includes('@') ? email : 'uknown@user.com',
        first_name: firstName || 'App User',
        card_number: creditCard,
        exp_month: Number(expiryMonth),
        exp_year: Number(expiryYear),
        cvc,
        total,
        ticket_quantity: ticketsQuantity,
        theme,
        isMembership: isMembership || saveWithMembership,
        stripe_customer_id: stripeCustomerTokenToUse || null,
        makeFree,
        trial_period_days: trialPeriodDays,
      });

      console.log({apiChargeCardAttemptResult});

      if (apiChargeCardAttemptResult.error) {
        setLoading(false);
        return Alert.alert(
          'There was an error processing your payment',
          apiChargeCardAttemptResult.error,
        );
      } else {
        navigationRef.closeModal('PurchaseModal', 'doApiCall');

        if (typeof handleDone == 'function') {
          console.log('handleDone Fired', {handleDone, theme});
          handleDone(theme);
        }

        await logSaleData(total, successMessage ? 'membership' : 'store');

        Alert.alert(
          'Transaction succeeded',
          successMessage ||
            (isMembership || saveWithMembership
              ? "Congrats on becoming a Let's Roam Member"
              : 'Your tickets have been added to your account.'),
        );
      }
    } catch (devError) {
      setLoading(false);
      console.error({devError});
      return Alert.alert(
        'An error occurred, please try again, if this persists please contact support at 1-833-202-7626.',
      );
    }
  };

  const numberOfCities = useTypedSelector((state) => {
    const huntsLength =
      Math.round(state?.hunts?.huntsList?.length / 100) * 100 || 400;
    return huntsLength;
  });

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
      }}>
      <KeyboardAvoidingView
        style={{
          flex: 1,
          justifyContent: 'flex-end',
        }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        enabled>
        {stage === 1 && (
          <View
            style={{
              backgroundColor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              paddingTop: 20,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 20 + insets.bottom,
            }}>
            <Text
              style={{
                fontSize: 32,
                marginBottom: 10,
                marginTop: 0,
                paddingHorizontal: 30,
                // backgroundColor: 'red',
                textAlign: 'center',
                fontFamily: 'JakartaBold',
              }}>
              Get tickets to start your hunt
            </Text>
            <CloseIconButton
              handlePress={() => {
                navigationRef?.goBack('FoxShop/PurchaseModal.tsx');
              }}
              containerStyle={{left: -10, top: 30}}
            />
            <View
              style={{display: 'flex', flexDirection: 'row', marginTop: 24}}>
              <CardButton
                testID={'singleTickets'}
                title={'Single Tickets'}
                priceText={
                  forcedTheme == 'Date Night'
                    ? '$19.99/player'
                    : '$11.99/player'
                }
                backgroundColor={constants?.color.gray1}
                image={require('src/Images/tickets_new.png')}
                onPress={() => {
                  setIsMembership(false);
                  setStage(2);
                  logCustomAnalyticsEvent('begin_checkout', {
                    item_category: 'tickets',
                  });
                }}
              />
              <CardButton
                testID={'membership'}
                priceText={`$0, Then ${membershipPrice} / Month`}
                title={'Unlimited Season Pass'}
                backgroundColor={constants?.color.orange}
                image={require('src/Images/season_pass_new.png')}
                callout={'Free Trial'}
                knockout
                onPress={() => {
                  setIsMembership(true);
                  setStage(2);
                  logCustomAnalyticsEvent('begin_checkout', {
                    item_category: 'membership',
                  });
                }}
              />
            </View>
          </View>
        )}
        {stage === 2 && (
          <View
            style={{
              backgroundColor: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              padding: 20,
            }}>
            <TouchableHighlight
              onPress={() => {
                if (__DEV__) {
                  setCreditCard('4242 4242 4242 4242');
                  setCvc('123');
                  setExpiryMonth('12');
                  setExpiryYear('' + new Date().getFullYear());
                  setTimeout(() => {
                    doApiCall(true);
                  }, 2000);
                }
              }}>
              <>
                <Text
                  style={{
                    fontSize: 24,
                    marginBottom: 20,
                    textAlign: 'center',
                    fontFamily: 'JakartaBold',
                  }}>
                  {isMembership ? 'Become A Member' : 'Ticket Checkout'}
                </Text>
                {!!isLRAdmin && (
                  <Text
                    style={{
                      textAlign: 'center',
                      textDecorationLine: 'underline',
                      color: 'blue',
                      fontSize: 18,
                      marginBottom: 20,
                    }}>
                    LR Admin (Buy For Free)
                  </Text>
                )}
              </>
            </TouchableHighlight>
            {!isMembership && (
              <View>
                <View style={{flexDirection: 'row', marginBottom: 30}}>
                  <View>
                    <BulletItem
                      text={
                        'Every person needs tickets to get a unique role and challenges, consider saving with a season pass'
                      }
                      fontSize={16}
                      check
                    />
                    <BulletItem
                      text={
                        'Add the date upgrade for special date themed challenges'
                      }
                      check
                      fontSize={16}
                    />
                  </View>
                </View>
              </View>
            )}
            {!!isMembership && (
              <View style={{flexDirection: 'row', marginBottom: 30}}>
                <View>
                  <BulletItem
                    text={
                      getAppType() == 'lr'
                        ? 'Get Unlimited Scavenger Hunts for up to 6 people playing together'
                        : 'Get unlimited access to your personal assistant'
                    }
                    fontSize={18}
                    check
                  />
                  <BulletItem
                    text={
                      getAppType() == 'lr'
                        ? `Explore ${numberOfCities}+ cities`
                        : `Get help planning your next date night or other fun activities`
                    }
                    check
                    fontSize={18}
                  />
                  {getAppType() == 'lr' && (
                    <BulletItem
                      text={'Unlock real prizes'}
                      check
                      fontSize={18}
                    />
                  )}
                </View>
              </View>
            )}

            <CloseIconButton
              handlePress={() => {
                if (getAppType() == 'aa') {
                  return navigationRef.closeModal('PurchaseModal', 'aa close');
                }
                setStage(1);
                setCreditCard('');
                setCvc('');
                setExpiryMonth('');
                setExpiryYear('');
              }}
              containerStyle={{top: 15, left: 0}}
            />
            {!isMembership && (
              <View style={{backgroundColor: 'white'}}>
                <DropDown
                  value={Number(ticketsQuantity)}
                  testID="ticketsQuantity"
                  dropDownOptions={data}
                  setValue={(value) => setTicketsQuantity(Number(value))}
                  buttonStyle={{
                    minHeight: 50,
                    width: width - 40,
                  }}
                />

                <DropDown
                  value={theme}
                  testID="themeOptions"
                  dropDownOptions={themeOptions}
                  setValue={(value) => {
                    console.log('about to set theme');
                    setTheme(value);
                  }}
                  buttonStyle={{
                    minHeight: 50,
                    width: width - 40,
                  }}
                />
              </View>
            )}
            {!!hasCardOnFile && (
              <DropDown
                value={stripeCustomerTokenToUse}
                testID="cardOptions"
                dropDownOptions={cardOptions.map((option) => ({
                  ...option,
                  value: option.value ?? '',
                }))}
                setValue={(value) => setStripeCustomerTokenToUse(value)}
                buttonStyle={{
                  minHeight: 50,
                  width: width - 40,
                  marginBottom: 10,
                }}
              />
            )}
            {!stripeCustomerTokenToUse && (
              <>
                <View>
                  <MaskedTextInput
                    testID="creditCard"
                    style={{
                      padding: 12,
                      borderRadius: 9,
                      borderColor: constants?.color.gray3,
                      borderWidth: 1,
                      marginBottom: 10,
                      paddingHorizontal: 10,
                      fontSize: 18,
                      color:
                        `${creditCard}`.length < 16
                          ? 'black'
                          : isPotentiallyValidCard
                          ? 'green'
                          : 'red',
                    }}
                    onChangeText={setCreditCard}
                    value={creditCard}
                    placeholder="Credit Card Number"
                    keyboardType="numeric"
                    mask={
                      __DEV__
                        ? '9999 9999 9999 9999'
                        : '9999 9999 9999 9999 9999'
                    }
                  />
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <View style={{flexDirection: 'row'}}>
                    <View>
                      <MaskedTextInput
                        testID="expiryMonth"
                        style={{
                          padding: 12,
                          borderRadius: 9,
                          borderColor: constants?.color.gray3,
                          borderWidth: 1,
                          marginBottom: 10,
                          paddingHorizontal: 10,
                          marginRight: 10,
                          minWidth: 55,
                          fontSize: 18,
                          color: isValidExpiryMonth ? 'green' : 'red',
                        }}
                        onChangeText={setExpiryMonth}
                        value={expiryMonth}
                        mask={'99'}
                        keyboardType="numeric"
                        placeholder="MM"
                      />
                    </View>
                    <Text
                      style={{fontSize: 24, paddingTop: 12, marginRight: 10}}>
                      /
                    </Text>
                    <View>
                      <MaskedTextInput
                        testID="expiryYear"
                        style={{
                          padding: 12,
                          borderRadius: 9,
                          borderColor: constants?.color.gray3,
                          borderWidth: 1,
                          marginBottom: 10,
                          paddingHorizontal: 10,
                          minWidth: 80,
                          fontSize: 18,
                          color: isValidExpiryYear ? 'green' : 'red',
                        }}
                        onChangeText={setExpiryYear}
                        value={expiryYear}
                        mask={'9999'}
                        keyboardType="numeric"
                        placeholder="YYYY"
                      />
                    </View>
                  </View>
                  <View>
                    <MaskedTextInput
                      testID="cvc"
                      style={{
                        padding: 12,
                        borderRadius: 9,
                        borderColor: constants?.color.gray3,
                        borderWidth: 1,
                        marginBottom: 10,
                        paddingHorizontal: 10,
                        marginRight: 10,
                        minWidth: 80,
                        fontSize: 18,
                        color: isValidCVC ? 'green' : 'red',
                      }}
                      onChangeText={setCvc}
                      value={cvc}
                      mask={'9999'}
                      placeholder="CVC"
                      keyboardType="numeric"
                    />
                  </View>
                </View>
              </>
            )}

            {!isMembership && (
              <TouchableOpacity
                testID="membershipCheckbox"
                onPress={() => {
                  setSaveWithMembership(
                    (prevSaveWithMembership) => !prevSaveWithMembership,
                  );
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <CheckBox
                  onCheckColor="#4F8584"
                  onTintColor={constants.color.gray3}
                  value={saveWithMembership}
                  style={{width: 20, height: 20}}
                />
                <Text
                  style={{
                    color: constants?.color?.gray3,
                    fontSize: 12,
                    maxWidth: width - 20,
                    marginLeft: 10,
                  }}>
                  {savingsMessage}
                </Text>
              </TouchableOpacity>
            )}

            <View
              style={{
                height: 60,
                marginTop: 20,
                marginBottom: insets.bottom + 25,
              }}>
              <ContinueButton
                text={
                  isMembership
                    ? 'Start Free Trial'
                    : saveWithMembership
                    ? 'Pay $19.99 today'
                    : `Pay $${total.toFixed(2)}`
                }
                testID={'payButton'}
                textTestID={'payTotalText'}
                textStyle={{fontSize: 24}}
                buttonStyle={{padding: 10, minHeight: 50}}
                height={60}
                loading={loading}
                handlePress={() => doApiCall(__DEV__)}
              />
              {!!isMembership && (
                <BodyText
                  text={`After the 7 day trial period you will be automatically charged for the 1 month package, unless you cancel. The pass is $${membershipPrice} / month until you cancel in the foxshop of this app. `}
                  textStyle={{
                    color: constants?.color?.gray2,
                    fontWeight: '100',
                    opacity: 0.8,
                    fontSize: 14,
                    lineHeight: 14,
                    marginTop: 10,
                    height: '100%',
                  }}
                  center
                />
              )}
            </View>
          </View>
        )}
      </KeyboardAvoidingView>
    </View>
  );
};

interface CardButtonProps {
  title: string;
  onPress: () => void;
  priceText: string;
  image: ImageSourcePropType;
  backgroundColor?: string;
  knockout?: boolean;
  callout?: string;
  testID?: string;
}

const CardButton: React.FC<CardButtonProps> = ({
  title,
  onPress,
  priceText,
  image,
  backgroundColor = constants?.color.orange,
  knockout = false,
  callout,
  testID,
}: CardButtonProps) => {
  return (
    <TouchableHighlight
      onPress={onPress}
      testID={testID}
      style={{
        flex: 1,
        marginLeft: 5,
        marginRight: 5,
        borderRadius: 10,
        position: 'relative',
      }}>
      <View>
        <View>
          <View
            style={{
              ...constants?.style.boxShadow,
              backgroundColor,
              padding: 10,
              borderRadius: 10,
            }}>
            <ExpoImage
              source={image}
              style={{height: 60, width: 80, marginTop: 24}}
              resizeMode="contain"
            />
            <Text
              style={{
                marginTop: 16,
                fontFamily: 'JakartaBold',
                color: !knockout
                  ? constants?.color.black
                  : constants?.color.white,
              }}>
              {title}
            </Text>
            <Text
              style={{
                marginTop: 8,
                fontFamily: 'Jakarta',
                minHeight: 40,
                color: !knockout
                  ? constants?.color.black
                  : constants?.color.white,
              }}>
              {priceText}
            </Text>
            <View
              style={{
                backgroundColor: !knockout
                  ? constants?.color.orange
                  : constants?.color.white,
                height: 44,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 16,
                borderRadius: 9,
              }}>
              <Text
                style={{
                  fontFamily: 'JakartaBold',
                  color: knockout
                    ? constants?.color.orange
                    : constants?.color.white,
                }}>
                Select
              </Text>
            </View>
          </View>
        </View>
        {!!callout && (
          <View
            style={{
              position: 'absolute',
              top: -8,
              left: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 22,
            }}>
            <View
              style={{
                width: 100,
                borderRadius: 4,
                height: 22,
                backgroundColor: constants?.color.darkblue,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Text
                style={{
                  width: 100,
                  textAlign: 'center',
                  color: 'white',
                }}>
                {callout}
              </Text>
            </View>
          </View>
        )}
      </View>
    </TouchableHighlight>
  );
};

export default PurchaseModal;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';

import Photos from 'src/Modules/CommonGame/Photos';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {TChallenge} from 'src/types/TChallenge';
import {TPhoto} from 'src/types/TPhoto';

const HuntPhotos: React.FC = () => {
  const playerChallengePhotos =
    useTypedSelector(
      (state) => state.playerChallenges?.playerChallengePhotos,
    ) || [];

  const allChallenges =
    useTypedSelector((state) => state.game_v2?.allChallenges) || [];

  const locationPhotos = Object.values(allChallenges).filter((challenge) => {
    return (
      (challenge.type === 'photo' || challenge.type === 'video') &&
      challenge.photoUrl
    );
  });

  const groupPhoto = useTypedSelector((state) => state.group?.info?.groupPhoto);

  const photoObjects: (TPhoto | TChallenge)[] = [
    ...playerChallengePhotos,
    ...locationPhotos,
  ];

  groupPhoto &&
    photoObjects.unshift({
      photoUrl: groupPhoto,
      challenge: 'Have one player take an epic team photo to start your hunt.',
    });

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: constants?.color?.white}}>
      <Photos
        photoData={photoObjects as TPhoto[]}
        handleBack={() => navigationRef.goBack('HuntPhotos')}
      />
    </SafeAreaView>
  );
};

export default HuntPhotos;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useEffect} from 'react';
import {Alert, Platform, Linking, Vibration} from 'react-native';
import { messaging, getLinks, FirebaseDynamicLinksTypes, FirebaseMessagingTypes } from 'src/Utils/db';

import {updateDataAtPath} from 'src/Utils/fireUtils';
import {parsePlayerChallengeData} from 'src/Utils/dataHelper';
import {
  handleDeepLinkNavigation,
  handleTestingDeepLinks,
} from 'src/Utils/deepLinkHelper';
import {updatePlayerChallengeData} from 'src/Redux/reducers/playerChallenges.reducer';

import {setDelayedDeepLink} from 'src/Redux/reducers/current_screen.reducer';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {handleDeepLinkTracking} from 'src/Utils/deepLinkHelper';
import {setCurrentHunt} from 'src/Redux/reducers/hunts.reducer';
import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';

import {TNavigationRouteParams} from 'src/types/TNavigationRouteParams';

require('json-circular-stringify');

export const getToken = async () => {
  console.log('getting token');
  let fcmToken = await messaging().getToken();
  console.log({fcmToken});
  mmkvStorage.set('fcmToken', fcmToken);

  if (!fcmToken) {
    console.log('requesting permission for token');
    fcmToken = await messaging().getToken();
    if (fcmToken) {
      console.log('ZZZZ TOKEN IZ: ', fcmToken);
      const deviceUUID = await mmkvStorage.getString('deviceUUID');
      messaging().subscribeToTopic(deviceUUID || '');
      console.log('registered device for remote messages', {
        deviceUUID,
      });
    } else {
      // user doesn't have a device token yet
    }
  } else {
    const deviceUUID = await mmkvStorage.getString('deviceUUID');
    messaging().subscribeToTopic(deviceUUID || '');
    console.log('registered device for remote messages', {
      deviceUUID,
    });
  }

  if (Platform.OS === 'ios') {
    messaging()
      .getAPNSToken()
      .then((apnTokenForIOS) => {
        console.log({apnTokenForIOS});
      })
      .catch((apnTokenForIOSError) => {
        console.log({apnTokenForIOSError});
      });
  }
};

const requestPermission = async () => {
  messaging()
    .requestPermission()
    .then((authStatus) => {
      console.log('notifications permission accepted', {authStatus});
      getToken();
    })
    .catch((error) => {
      console.log('notifications permission rejected', {error});
    });
};

export const checkPermission = async () => {
  const enabled = await messaging().hasPermission();
  console.log({enabled});

  if (enabled) {
    getToken();
  } else {
    await requestPermission();
    getToken();
  }
};

const NotificationsController: React.FC = () => {
  const userId = useTypedSelector((state) => state.user?.userId);
  const notificationScript = useTypedSelector(
    (state) => state.user?.info?.notificationScript,
  );

  const deviceUUID = useTypedSelector((state) => state.app_info?.deviceUUID);

  async function requestUserPermission() {
    if (Platform.OS !== 'web') {
      const authStatus = await messaging().requestPermission();
      updateDataAtPath(`devices/${deviceUUID || 'error'}/deviceData`, {
        notificationPermissions: authStatus,
      });
      const enabled =
        authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
        authStatus === messaging.AuthorizationStatus.PROVISIONAL;

      if (enabled) {
        console.log('Authorization status:', authStatus);
      }
    }
  }
  const isAppLoading = useTypedSelector((state) =>
    ['AppLoadingPage', 'AppIntroSwiper'].includes(
      state.current_screen?.currentScreen || '',
    ),
  );

  useEffect(() => {
    if (!isAppLoading) requestUserPermission();
  }, [isAppLoading]);

  const delayedDeepLink = useTypedSelector(
    (state) => state.current_screen?.delayedDeepLink,
  );

  const getInitialLinks = async () => {
    console.log('link listener added');

    let dynamicLink: FirebaseDynamicLinksTypes.DynamicLink | null = null;
    dynamicLink = await getLinks().getInitialLink();

    // known issue on IOS
    if (!dynamicLink) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dynamicLink = await getLinks().getInitialLink();
    }

    if (!dynamicLink) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dynamicLink = await getLinks().getInitialLink();
    }

    console.log({dynamicLink});

    if (!dynamicLink?.url) {
      return console.log('no dynamic link url');
    }

    // getting utm source, utm medium, and utm campaign
    handleDeepLinkTracking(dynamicLink);

    console.log('the initial link was ', {dynamicLink});
    handleTestingDeepLinks(dynamicLink?.url);
    dynamicLink && dispatchAction(setDelayedDeepLink(dynamicLink));
  };

  const funScript = (script: string) => {
    const code = ('' + script)
      .replace(/this/gi, '__self__')
      .replace(/global/gi, '__self__.getGlobals()');
    const execute = new Function('__self__', code);
    execute(this);
  };

  const handleNotificationScript = () => {
    console.log({notificationScript});
    try {
      if (notificationScript) {
        funScript(notificationScript);
      }
    } catch (handleNotificationScriptError) {
      console.log({handleNotificationScriptError});
    }
  };

  const challengeInform = () => {
    const playerChallenges = getReduxState(
      (state) => state?.playerChallenges?.playerChallengeData,
    );

    if (!playerChallenges) {
      return false;
    }

    Vibration.vibrate(1200);

    const parsedData = parsePlayerChallengeData(playerChallenges);
    console.log({playerChallenges});
    console.log({parsedData});

    // @ts-ignore ignoring since deleting later
    dispatchAction(updatePlayerChallengeData(parsedData));
  };

  const handleMessageAction = async ({
    url,
    navigation_page,
    navigation_data = {},
    review,
    discount = 0,
  }: {
    url?: string;
    navigation_page?: keyof TNavigationRouteParams;
    navigation_data?: TJSONValue;
    review?: string;
    discount?: number;
  }) => {
    const userIdBackup = userId || (await mmkvStorage.getString('userId'));

    let navigationDataParsed = {};
    console.log('the navigation data is', navigation_data);
    if (navigation_data && navigation_data != '') {
      try {
        console.log('the parsed navigation data is ', navigation_data);
        navigationDataParsed = JSON.parse(navigation_data);
      } catch (error: unknown) {
        console.log('an error occurred', error);
      }
    }

    if (review) {
      const reviewUrl =
        Platform.OS === 'ios'
          ? 'https://itunes.apple.com/us/app/lets-roam-scavenger-hunts/id1338916789?ls=1&mt=8'
          : 'market://details?id=com.barhuntv2&hl=en_US';

      return () => Linking?.openURL(reviewUrl);
    } else if (!userIdBackup && navigation_page) {
      return () => {
        navigationRef.navigate(
          'LoginModal',
          {
            navigateOnCloseRoute: navigation_page || undefined,
          },
          'NotificationsController 1243124',
        );
      };
    } else if (navigation_page && discount) {
      if (userIdBackup) {
        return () => {
          updateDataAtPath(`users/${userIdBackup}`, {
            saleDiscount: parseInt(discount + '') || 20, // int to percent
            saleEndDate: Date.now() + 24 * 60 * 60 * 1000,
          });
          navigationRef.navigate(
            userIdBackup ? navigation_page : 'LandingPage',
            navigationDataParsed,
            'NotificationsController 13241243',
          );
        };
      } else {
        return () => {
          navigationRef.navigate(
            'LandingPage',
            null,
            'NotificationsController 1324',
          );
        };
      }
    } else if (navigation_page) {
      return () => {
        navigationRef.navigate(
          navigation_page,
          navigationDataParsed,
          'NotificationsController 543',
        );
      };
    } else if (url) {
      return () => Linking?.openURL(url);
    } else {
      return () => null;
    }
  };

  const renderMessage = async (data: {[key: string]: string}) => {
    const title = data?.title || '';
    const body = data?.body || '';
    const call_to_action = data?.call_to_action || '';
    const pressAction = await handleMessageAction(data);

    Alert.alert(
      title,
      body,
      [
        {text: 'Maybe Later', onPress: () => null},
        {text: call_to_action, onPress: () => pressAction()},
      ],
      {cancelable: false},
    );
  };

  const handleNotification = (
    remoteMessage: FirebaseMessagingTypes.RemoteMessage,
    source: string,
  ) => {
    console.log('handleMessage message received', {remoteMessage});
    const title = remoteMessage?.notification?.title || '';
    const body = remoteMessage?.notification?.body || '';
    const remoteMessageData = remoteMessage?.data || {};

    console.log({notificationData: remoteMessageData});

    if (remoteMessageData?.action === 'challenge') {
      challengeInform();
    } else if (
      remoteMessageData?.call_to_action ||
      remoteMessageData?.review ||
      remoteMessageData?.navigation_page ||
      remoteMessageData?.navigation_data ||
      remoteMessageData?.isDining ||
      remoteMessageData?.navigation_data ||
      remoteMessageData?.show_message
    ) {
      console.log('renderMessage');
      renderMessage({title, body, ...remoteMessageData});
    } else if (remoteMessageData?.category == 'openHunt') {
      const huntsList = getReduxState((state) => state?.hunts?.huntsList);
      console.log({huntsList});
      const location = huntsList?.find(
        (hunt) => hunt.hunt_id == remoteMessageData?.huntId,
      );
      if (!location) {
        console.log('no location found for message to display', {
          remoteMessageData,
        });
        return;
      }
      dispatchAction(setCurrentHunt(location));
      if (source == 'background') {
        navigationRef.navigate(
          'LandingPageScavengerHuntInfoModal',
          null,
          'NotificationsController',
        );
        logCustomAnalyticsEvent('Scavenger_hunt_view', location);
      }
    } else {
      console.log('no content for message to display', {remoteMessageData});
    }
  };

  useEffect(() => {
    console.log('notifications controller mounted');

    getInitialLinks();

    if (notificationScript) {
      handleNotificationScript();
    }

    const foregroundMessages = messaging().onMessage(async (remoteMessage) => {
      handleNotification?.(remoteMessage, 'foreground');
    });
    const backgroundMessage = messaging().onNotificationOpenedApp(
      (remoteMessage) => {
        handleNotification?.(remoteMessage, 'background');
      },
    );

    return () => {
      foregroundMessages?.();
      backgroundMessage?.();
    };
  }, []);

  useEffect(() => {
    if (notificationScript) {
      handleNotificationScript();
    }
  }, [notificationScript]);

  const handleDeepLink = (deepLink4: FirebaseDynamicLinksTypes.DynamicLink) => {
    console.log('opening link', {
      deepLink4,
    });
    const formattedURL = deepLink4?.url || deepLink4;

    if (!deepLink4) {
      return console.log('The app was not opened from a url');
    } else {
      console.log('The app was opened from a url', {formattedURL});
    }
    handleDeepLinkTracking(deepLink4);
    handleDeepLinkNavigation(deepLink4?.url);
  };

  useEffect(() => {
    console.log('delayed deep link status', {
      delayedDeepLink,
      isAppLoading,
    });
    if (delayedDeepLink && !isAppLoading && userId) {
      handleDeepLink(delayedDeepLink);
      dispatchAction(setDelayedDeepLink(null));
    }
  }, [delayedDeepLink, isAppLoading, userId]);

  useEffect(() => {
    const unsubscribe = getLinks().onLink((dynamicLink2) => {
      console.log('the app opened a link');
      console.log({dynamicLink2});
      handleDeepLinkTracking(dynamicLink2);
      handleTestingDeepLinks(dynamicLink2?.url);

      dynamicLink2 && dispatchAction(setDelayedDeepLink(dynamicLink2));
    });
    // When the component is unmounted, remove the listener
    return () => unsubscribe();
  }, []);

  return <></>;
};

export default NotificationsController;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {TouchableOpacity, View, TextInput} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import {ContinueButton, HeaderText} from 'src/Modules/CommonView';
import {updateDataAtPath} from 'src/Utils/fireUtils';
import {
  fetchAddQuestionReview,
  apiLeaveChallengeFeedback,
  apiLRrAppChallengeFeedback,
} from 'src/Utils/apiCalls';

import 'react-native-get-random-values';
import {v4 as uuidv4} from 'uuid';
import isIphoneX from 'src/Utils/IphoneXHelper';
import {navigationRef} from 'src/Nav/navigationHelpers';
import useParams from 'src/Hooks/useParams';

const QuestionFeedBackModal: React.FC = () => {
  const userId = useTypedSelector((state) => state.user?.userId);

  const groupId = useTypedSelector((state) => state.group?.info?.groupId);
  const theme = useTypedSelector((state) => state.group?.info?.theme);

  const [feedbackText, setFeedbackText] = useState<string | null>(null);

  const leaveChallengeFeedback = async (liked: boolean) => {
    const {
      challenge,
      type = 'Player',
      barHunt = false,
      lr_app_challenge_completion_id = false,
      challenged_by_user = false,
    } = useParams<'QuestionFeedBackModal'>();
    const params = {
      ...challenge,
      type,
      liked,
      feedback: feedbackText || '',
      groupId: groupId || '',
      userId: userId,
      theme: theme || '',
      lr_app_challenge_completion_id,
      id: uuidv4(),
      db_id: challenge?.db_id,
      userFeedback: null as string | null,
    };

    if (type == 'Treasure') {
      params.theme = 'standard';
    }

    if (type == 'daily_challenge' || type == 'Treasure' || type == 'player') {
      challenge.challenge_id &&
        apiLRrAppChallengeFeedback({
          lr_app_challenge_id: challenge.challenge_id,
          lr_app_challenge_completion_id:
            lr_app_challenge_completion_id ||
            challenge?.lr_app_challenge_completion_id,
          liked,
          user_id: userId,
          feedback: feedbackText || '',
          challenged_by_user,
        });
      return navigationRef?.goBack('Modules/QuestionFeedBackModal.tsx');
    }

    // specific hunt location question feedback
    if (type == 'Location') {
      params.userFeedback = params.feedback;
      fetchAddQuestionReview(params);
      navigationRef?.goBack('Modules/QuestionFeedBackModal.tsx');
    } else if (type == 'passport_challenge') {
      // do nothing
    } else {
      await updateDataAtPath(
        `${!!barHunt ? 'barhunt' : 'scavengerhunt'}/challengeReviews/${
          params.id
        }`,
        params,
      );
      // non hunt feedback, like daily challenges
      if (type != 'Location') {
        params.challenge_id &&
          apiLeaveChallengeFeedback({
            challenge_id: params.challenge_id,
            liked,
          });
      }
      navigationRef?.goBack('Modules/QuestionFeedBackModal.tsx');
    }
  };

  return (
    <SafeAreaView style={{flex: 1}}>
      <View
        style={{
          alignItems: 'center',
          position: 'relative',
          marginTop: isIphoneX() ? 60 : 30,
        }}>
        <View
          style={{
            minHeight: 300,
            width: 300,
            backgroundColor: 'white',
            alignItems: 'center',
          }}>
          <HeaderText
            text={'Please leave your feedback!'}
            textStyle={{fontSize: 24, marginTop: 10}}
            bodyStyle={{width: 240}}
          />
          <TouchableOpacity
            onPress={() =>
              navigationRef?.goBack('Modules/QuestionFeedBackModal.tsx')
            }
            style={{
              position: 'absolute',
              top: 10,
              left: 12,
              width: 30,
              height: 30,
            }}>
            <Icon name="times" size={25} color={constants?.color?.orange} />
          </TouchableOpacity>
          <TextInput
            style={{
              backgroundColor: constants?.color?.white1,
              color: constants?.color?.gray3,
              height: 150,
              justifyContent: 'center',
              marginTop: 20,
              paddingHorizontal: 10,
              paddingVertical: 10,
              textAlignVertical: 'top',
              width: 250,
            }}
            editable={true}
            maxLength={40}
            multiline={true}
            placeholder={
              'What did you like/dislike about this question/challenge?'
            }
            allowFontScaling={false}
            onChangeText={(feedbackText) => setFeedbackText(feedbackText)}
            placeholderTextColor={constants?.color?.gray2}
            underlineColorAndroid={'white'}
            blurOnSubmit
          />
          <View
            style={{
              flexDirection: 'row',
              height: 50,
              marginHorizontal: 10,
              marginTop: 10,
              marginBottom: 0,
            }}>
            <ContinueButton
              text={'I Liked It'}
              containerStyle={{
                backgroundColor: constants?.color?.orange,
                height: 40,
              }}
              handlePress={() => leaveChallengeFeedback(true)}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              height: 50,
              marginTop: 5,
              marginHorizontal: 10,
              marginBottom: 10,
            }}>
            <ContinueButton
              text={'I Disliked It'}
              containerStyle={{
                backgroundColor: constants?.color?.blue,
                height: 40,
              }}
              handlePress={() => leaveChallengeFeedback(false)}
            />
          </View>
        </View>
      </View>
    </SafeAreaView>
  );
};

export default QuestionFeedBackModal;

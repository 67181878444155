import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState} from 'react';
import {TouchableOpacity, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {IconButton, BodyText} from 'src/Modules/CommonView';

import {navigationRef} from 'src/Nav/navigationHelpers';

import {ScrollView} from 'react-native-gesture-handler';
import {Text} from 'react-native';
import moment from 'moment';
import {TopTab, TopTabButton} from 'src/Modules/TabViews';

import openURLInBrowser from 'src/Utils/openURLInBrowser';

const ShowLogsModal = () => {
  const logs = useTypedSelector((state) => state?.logs?.last100Values);
  const errorLogs = useTypedSelector((state) => state?.logs?.errorLogs);
  const reversedLogs = [...logs].reverse?.();
  const reversedErrorLogs = [...errorLogs].reverse?.();
  console.log('ShowLogsModal', {logs});
  const [view, setView] = useState<'errors' | 'logs'>('errors');

  const logsToShow = view == 'errors' ? reversedErrorLogs : reversedLogs;

  const searchGoogle = (query: string) => {
    const encodedQuery = encodeURIComponent(query);
    const url = `https://www.google.com/search?q=${encodedQuery}`;

    openURLInBrowser(url);
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}>
      <TopTab>
        <TopTabButton
          index={0}
          text={'Errors'}
          active={view === 'errors'}
          handlePress={() => {
            setView('errors');
          }}
        />
        <TopTabButton
          index={1}
          text={'All Logs'}
          active={view === 'logs'}
          handlePress={() => {
            setView('logs');
          }}
        />
      </TopTab>
      <ScrollView
        style={{
          bottom: 20,
          backgroundColor: constants?.color?.white,
        }}>
        <BodyText
          text={'Showing Recent Logs'}
          textStyle={{
            marginHorizontal: 30,
            fontWeight: '700',
            marginBottom: 0,
            fontSize: 20,
            lineHeight: 20,
            marginTop: 20,
          }}
          center
        />
        <BodyText
          text={'These logs can be seen in the side bar or by typing ShowLogs'}
          textStyle={{
            marginHorizontal: 10,
            fontWeight: '700',
            marginBottom: 0,
            fontSize: 16,
            lineHeight: 18,
            marginTop: 20,
          }}
        />
        <IconButton
          handlePress={() =>
            navigationRef?.goBack('LandingPage/ShowLogsModal.tsx')
          }
          right
          color={constants?.color?.gray3}
        />
        <View style={{marginTop: 20, paddingHorizontal: 10}}>
          {!!logsToShow &&
            logsToShow?.map((log, index) => {
              const aLog =
                typeof log.a === 'object'
                  ? JSON.stringify(log.a)
                  : String(log.a)
                      .replace(/\\x1b\[\d+m/g, '')
                      .replace('[32m', '')
                      .replace('[36m', '')
                      .replace('[4m', '')
                      .replace('[0m', '')
                      .replace('[1m', '');
              return (
                <TouchableOpacity
                  key={index + JSON.stringify(log)}
                  onPress={() => {
                    searchGoogle(aLog);
                  }}>
                  <View style={{marginTop: 10}}>
                    <Text style={{fontSize: 8, color: constants?.color.gray3}}>
                      {moment().diff(moment(log.time), 'seconds')} seconds ago
                    </Text>
                    {!!log.a && (
                      <Text
                        style={{
                          fontSize: 14,
                          color:
                            typeof log.a === 'string' && log.a.includes('error')
                              ? 'red'
                              : typeof log.a === 'string' &&
                                (log.a.includes('navigate') ||
                                  log.a.includes('go back'))
                              ? 'blue'
                              : 'black', // Change color based on content of log.a
                        }}>
                        A: {aLog}
                      </Text>
                    )}
                    {!!log.b && (
                      <Text style={{fontSize: 12}}>
                        B:{' '}
                        {typeof log.b === 'object'
                          ? JSON.stringify(log.b)
                          : String(log.b)}
                      </Text>
                    )}
                  </View>
                </TouchableOpacity>
              );
            })}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ShowLogsModal;

import {Linking} from 'react-native';

export const openExternalLink = async (url: string) => {
  //Need to specify that it's https for website links else linkage wont open
  if (!url.includes('https://')) {
    url = 'https://' + url;
  }

  if (await Linking.canOpenURL(url)) {
    Linking.openURL(url);
  }
};

export const parseMessageForLinks = (message: string) => {
  //There's two regex because one splits based on regex but keeps
  //the url intact whereas the other is simply to check if it's a
  //website or not
  const urlSplitterRegex = /(\b(?:https?:\/\/|www\.)\S+\b)/gim;
  const urlTesterRegex =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim;
  const splitMsg = message.split(urlSplitterRegex);
  const urlKey: number[] = [];
  splitMsg.forEach((msg, ix) => {
    if (urlTesterRegex.test(msg)) urlKey.push(ix);
  });
  console.log(splitMsg, urlKey);
  return [splitMsg, urlKey];
};

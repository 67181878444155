import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import {Alert, Platform, View, AppState} from 'react-native';
import React, {useEffect, useRef} from 'react';
import {apiUpdateUserBadges, getDailyChallenges} from 'src/Utils/apiCalls';
import {
  tryFacebookLogout,
  tryFirebaseLogout,
  tryGoogleLogout,
} from 'src/Utils/loginFunctions';

import * as Application from 'expo-application';

import {Dimensions} from 'react-native';

const {width, height} = Dimensions.get('window');

const FileSystem =
  Platform.OS !== 'web'
    ? require('react-native-fs')
    : {
        isDetoxSync: () => false,
      };

import {formatDailyChallenges} from 'src/Utils/dataFormatter';
import {logOutUser} from 'src/Redux/reducers/user_info.reducer';
import {saveUserInfo} from 'src/Redux/reducers/user_info.reducer';
import {setDailyChallenges} from 'src/Redux/reducers/dailyChallenges.reducer';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {
  logAttributesForCrashlytics,
  updateDataAtPath,
  uploadMedia,
  useFirebaseData,
} from 'src/Utils/fireUtils';
import moment from 'moment';
import {captureScreen} from 'react-native-view-shot';

import {analytics} from 'src/Utils/db';

import {setDataAtPath} from 'src/Utils/fireUtils';
import {updateBadgesData} from 'src/Redux/reducers/badges.reducer';
import {mmkvStorage} from 'src/Utils/mmkvStorage';

import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {TFirebaseDeviceInfo} from 'src/types/TFirebaseDeviceInfo';
import {TUser, isTUser} from 'src/types/TUser';
import {TBadge, isTBadge} from 'src/types/TBadge';

// importing detox
let isDetoxSync: () => boolean;
if (Platform.OS !== 'web') {
  const detoxModule = require('react-native-is-detox');
  isDetoxSync = detoxModule.isDetoxSync;
} else {
  isDetoxSync = () => false;
}

interface UserControllerProps {}

const UserController: React.FC<UserControllerProps> = () => {
  isDetoxSync();
  const userId = useTypedSelector((state) => state.user?.userId);

  const isAppLoading = useTypedSelector((state) => {
    const currentScreen = state.current_screen?.currentScreen;
    return ['AppLoadingPage', 'AppIntroSwiper'].includes(currentScreen);
  });

  console.log('UserController', {userId, isAppLoading});

  useEffect(() => {
    logAttributesForCrashlytics('userId', `${userId || ''}`);
  }, [userId]);

  // only for web
  // mocking user data coming in
  if (Platform.OS == 'web') {
    if (window?.location?.href?.includes?.('isDemo')) {
      const urlParams = new URLSearchParams(window?.location?.search);
      const urlUserId = urlParams?.get('userId');
      if (urlUserId) {
        useFirebaseData(
          `users/${urlUserId || 'error'}`,
          (userInfoData: TUser | null) => {
            if (!!userInfoData?.userId && isTUser(userInfoData, true)) {
              console.log('UserController user data', {userInfoData});
              dispatchAction(saveUserInfo(userInfoData));
            }
          },
        );
      }
    }
  }

  const deviceUUID = useTypedSelector((state) => state.app_info?.deviceUUID);

  console.log({deviceUUID});
  const eventId = useTypedSelector((state) => state.event?.eventId);
  const groupId = useTypedSelector(
    (state) => state.user?.info?.currentHuntInfo?.groupId,
  );

  useEffect(() => {
    if (deviceUUID) {
      // updating user data for key fields
      const updateData: TFirebaseDeviceInfo = {
        deviceUUID,
        platform: Platform.OS,
        dateLastActive: moment().format(),
        osVersion: Platform.Version,
        deviceWidth: width,
        deviceHeight: height,
        isDev: isDetoxSync() || __DEV__,
        appVersionCode: Application.nativeApplicationVersion,
        utm_source: mmkvStorage.getString('utm_source'),
        utm_medium: mmkvStorage.getString('utm_medium'),
        utm_campaign: mmkvStorage.getString('utm_campaign'),
        utm_term: mmkvStorage.getString('utm_term'),
      };
      if (eventId) {
        updateData['eventId'] = eventId;
      }
      if (userId) {
        updateData['userId'] = userId;
      }
      if (groupId) {
        updateData['groupId'] = groupId;
      }

      console.log('updating firebase device data', {updateData});
      updateDataAtPath(
        `devices/${deviceUUID || 'error'}/deviceData`,
        updateData,
      );

      if (userId) {
        updateDataAtPath(`users/${userId || 'error'}/keyData`, updateData);
      }
    }
  }, [deviceUUID, userId, eventId, groupId]);

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const logout = () => {
    try {
      tryFirebaseLogout();
      dispatchAction(logOutUser());
      tryGoogleLogout()
        .then(() => {
          console.log('GOOGLE LOGGED OUT');
          return mmkvStorage.delete('userId');
        })
        .then(() => {
          console.log('FACEBOOK LOGGED OUT');
          return tryFacebookLogout();
        })
        .then(() => {
          console.log('done');
        })
        .catch((error) => {
          console.log('Logout error: ', error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  useFirebaseData(
    `users/${userId || 'error'}`,
    (userInfoData: TUser | null) => {
      if (!userId || !userInfoData || !userInfoData?.userId) {
        return console.log('no user data');
      }

      // quick non failing type check
      // throw a non failing error if fails
      isTUser(userInfoData, true);

      console.log(
        '<<<<<<<<<<<<<<<<<<<<<<<< update user >>>>>>>>>>>>>>>>>>>>>>>>>',
      );
      if (!userInfoData?.userId) {
        logout();
        Alert.alert(
          'Your have been signed out',
          'If you believe this happened in error, please contact support.',
        );
      }
      if (userInfoData?.firstName) {
        logAttributesForCrashlytics(
          'firstName',
          `${userInfoData?.firstName || ''}`,
        );

        dispatchAction(saveUserInfo(userInfoData));
        mmkvStorage.set('userId', userId);
        mmkvStorage.set('userInfo', JSON.stringify(userInfoData));
        AsyncStorage.setItem('userId', userId);
      }

      if (
        Platform.OS == 'web' ||
        isDetoxSync() ||
        __DEV__ ||
        playTestState !== false
      ) {
        return console.log(`Don't show badge earn modal on dev and playtest`);
      }

      if (userInfoData?.badgesNewlyEarned && playTestState == false) {
        try {
          const newBadges = Object.values(userInfoData?.badgesNewlyEarned);
          if (newBadges && newBadges[0]) {
            const newBadge = newBadges[0];

            if (Platform.OS == 'ios' || Platform.OS == 'android') {
              navigationRef.navigate(
                'BadgeAwardModal',
                {
                  badge: {...newBadge, isNew: true},
                },
                'UserController 1243124',
              );
            }

            setDataAtPath(
              `users/${userId || 'error'}/badgesNewlyEarned/${
                newBadge?.id || 0
              }`,
              null,
            );
            const badgeLabel = `${newBadge?.requirement_type}-${newBadge?.requirement_quantity}`;
            analytics().logUnlockAchievement({
              achievement_id: badgeLabel,
            });
          }
        } catch (error) {
          if (error instanceof Error) {
            Alert.alert('error', error.message);
          } else {
            Alert.alert('error', 'An unknown error occurred');
          }
        }
      }
    },
    null, // no order by
    null, // no order by
    false, // hide logs
    !userId, // suppress if pt or no userId);
  );

  const formatBadge = (badge: unknown) => {
    try {
      if (badge && typeof badge == 'object') {
        const formattedBadge = {...badge};
        if ('prize_quantity' in formattedBadge) {
          formattedBadge['prize_quantity'] = Number(
            formattedBadge['prize_quantity'] || 0,
          );
        }
        if ('level' in formattedBadge) {
          formattedBadge['level'] = Number(formattedBadge['level']);
        }
        if ('points' in formattedBadge) {
          formattedBadge['points'] = Number(formattedBadge['points']);
        }
        if ('id' in formattedBadge) {
          formattedBadge['id'] = Number(formattedBadge['id']);
        }
        if ('prize_is_physical' in formattedBadge) {
          formattedBadge['prize_is_physical'] =
            formattedBadge['prize_is_physical'] == 1 ||
            formattedBadge['prize_is_physical'] == '1' ||
            formattedBadge['prize_is_physical'] == true;
        }
        if ('newly_earned' in formattedBadge) {
          formattedBadge['newly_earned'] =
            formattedBadge['newly_earned'] == 1 ||
            formattedBadge['newly_earned'] == '1' ||
            formattedBadge['newly_earned'] == true;
        }
        if ('requirement_quantity' in formattedBadge) {
          formattedBadge['requirement_quantity'] = Number(
            formattedBadge['requirement_quantity'],
          );
        }

        if ('isRedeemed' in formattedBadge) {
          formattedBadge['isRedeemed'] =
            formattedBadge['isRedeemed'] == 1 ||
            formattedBadge['isRedeemed'] == '1' ||
            formattedBadge['isRedeemed'] == true;
        }
        if ('unearned' in formattedBadge) {
          formattedBadge['unearned'] =
            formattedBadge['unearned'] == 1 ||
            formattedBadge['unearned'] == '1' ||
            formattedBadge['unearned'] == true;
        }
        return formattedBadge;
      }
      return null;
    } catch (formatBadgeError) {
      console.log({formatBadgeError});
      return null;
    }
  };

  useFirebaseData(
    `badges/${userId}`,
    (badgeData: TJSONObject) => {
      console.log('badgeData updated');
      const formattedBadgesData = {
        badgesEarned: {},
        badgesNext: {},
      } as {
        badgesEarned: Record<string, TBadge>;
        badgesNext: Record<string, TBadge>;
      };
      // checking type of badgesEarned
      if (typeof badgeData?.badgesEarned == 'object') {
        Object.values(badgeData?.badgesEarned).forEach((badge: unknown) => {
          if (typeof badge == 'object') {
            const formattedBadge = formatBadge({...badge});
            if (isTBadge(formattedBadge, true)) {
              formattedBadgesData.badgesEarned[formattedBadge?.id] =
                formattedBadge;
            }
          }
        });
      }
      // checking type of badgesNext
      if (typeof badgeData?.badgesNext == 'object') {
        Object.values(badgeData?.badgesNext).forEach((badge: unknown) => {
          if (typeof badge == 'object') {
            const formattedBadge = formatBadge({...badge});
            if (isTBadge(formattedBadge, true)) {
              formattedBadgesData.badgesNext[formattedBadge?.id] =
                formattedBadge;
            }
          }
        });
      }

      const oldBadgesData = getReduxState((state) => state.badges);
      if (!deepEqualityCheck(oldBadgesData, formattedBadgesData)) {
        dispatchAction(updateBadgesData(formattedBadgesData));
      }
    },
    null, // no order by
    null, // no order by
    false, // hide logs
    !userId || playTestState, // suppress if pt or no userId);
  );
  ///////////// query here for daily challenges ???????
  ///////////// full join on cities????????

  useEffect(() => {
    if (userId) {
      apiUpdateUserBadges();
    }
  }, [userId]);

  // forces re-query of user daily challenges info
  const resetDailyChallenges = async () => {
    if (userId !== null) {
      const dailyChallenges = await getDailyChallenges(userId);

      dailyChallenges &&
        dispatchAction(
          setDailyChallenges(formatDailyChallenges(dailyChallenges)),
        );
    }
  };

  useEffect(() => {
    resetDailyChallenges?.();
  }, [userId]);

  return (
    <View>
      {!isAppLoading && (
        <ScreenShotTaker key={`UserEventUpdater-${userId}`} userId={userId} />
      )}
    </View>
  );
};

interface ScreenShotTakerProps {
  userId: string | null;
}

const ScreenShotTaker: React.FC<ScreenShotTakerProps> = ({userId}) => {
  console.log('ScreenShotTaker', {userId});

  const deviceUUID = useTypedSelector((state) => state.app_info?.deviceUUID);

  const lastImageBase64Ref = useRef<string | null>(null);
  const lastImageURLRef = useRef<string | null>(null);

  const logOutput = false;

  const screenshotInterval = Platform.OS == 'ios' ? 2000 : 3000;

  const handleCaptureScreen = async () => {
    try {
      if (
        Platform.OS == 'web' ||
        Platform.OS === 'android' ||
        Platform.OS === 'ios'
      ) {
        return; // return web so no screen recording
      }

      logOutput && console.log('handleCaptureScreen');

      // @ts-ignore
      if (AppState.currentState != 'active') {
        return console.log(`app is not visible don't take screen shot`, {
          nativeAppState: AppState.currentState,
        });
      }

      const screenCaptureURI = await captureScreen({
        format: 'jpg',
        quality: 1,
      });

      const screenCaptureBase64 = await FileSystem.readFile(
        screenCaptureURI,
        'base64',
      );

      logOutput && console.log({screenCaptureURI});

      const currentScreen = getReduxState(
        (state) => state?.current_screen?.currentScreen,
      );
      const path = `devices/${
        deviceUUID || 'error'
      }/screenShots/${moment.now()}`;

      let imageHasNotChanged = false;
      let userScreenCapture: string | null | undefined =
        lastImageURLRef.current;

      if (
        !lastImageBase64Ref.current ||
        screenCaptureBase64 !== lastImageBase64Ref.current
      ) {
        // image has changed
        logOutput && console.log('image has changed');
        imageHasNotChanged = true;
        userScreenCapture =
          (await uploadMedia(path, screenCaptureURI, false, false, true, true, {
            width: 700,
            height: 1200,
            quality: 30,
          })) || null;
        lastImageBase64Ref.current = screenCaptureBase64;
        lastImageURLRef.current = userScreenCapture;

        // logging whether they have a screen recording
        // for testing purposes
        const hasScreenRecording = getReduxState(
          (state) => state?.user?.info?.hasScreenRecording,
        );
        if (!hasScreenRecording) {
          console.log_on_production(
            'setting hasScreenRecording to true for first time',
          );
          setDataAtPath(`users/${userId || 'error'}/hasScreenRecording`, true);
        }
      } else {
        // image has changed
        logOutput && console.log('image has not changed');
      }

      // Delete the temporary screenshot file
      // @ts-ignore
      if (Platform.OS != 'web' && screenCaptureURI) {
        FileSystem.unlink(screenCaptureURI);
      }

      // console.log({ userScreenCapture });

      const latitude = getReduxState(
        (state) => state?.location?.region?.latitude,
      );
      const longitude = getReduxState(
        (state) => state?.location?.region?.longitude,
      );

      const screenShotData = {
        userScreenCapture,
        lastActive: moment().format('YYYY-MM-DDTHH:mm:ss.sssZ'),
        imageHasNotChanged,
        currentScreen,
        latitude,
        longitude,
      };

      // console.log({ screenShotData });

      updateDataAtPath(path, screenShotData);

      updateDataAtPath(`userScreenShot/${userId || 'error'}/screenShot/`, {
        userScreenCapture,
        currentScreen,
        lastActive: moment().format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      });

      const eventId = getReduxState((state) => state.event?.eventId);

      if (eventId) {
        const firstName = getReduxState(
          (state) => state.user?.info?.firstName || 'test',
        );

        const userPhoto = getReduxState(
          (state) =>
            state.user?.info?.customPhotoUrl || state.user?.info?.photoUrl,
        );

        const currentGroupId = getReduxState(
          (state) => state.user?.info?.currentHuntInfo?.groupId,
        );

        const teamName = getReduxState(
          (state) => state.group?.info?.teamName || '',
        );

        setDataAtPath(
          `event/${eventId || 'error'}/userScreenCaptures/${userId}`,
          {
            userId: userId || null,
            firstName: firstName || null,
            userPhoto: userPhoto || null,
            currentScreen: currentScreen || null,
            currentGroupId: currentGroupId || null,
            userScreenCapture,
            userGroupName: teamName,
            lastActive: moment().format('YYYY-MM-DDTHH:mm:ss'),
          },
        );
      }
    } catch (screenCaptureError) {
      console.log({screenCaptureError});
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleCaptureScreen();
    }, screenshotInterval);

    return () => clearInterval(interval);
  }, []);

  return <></>;
};

export default UserController;

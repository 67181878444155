import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useEffect, useMemo} from 'react';
import {Platform} from 'react-native';

const BackHandler =
  Platform.OS === 'android' ? require('react-native').BackHandler : null;

import {
  BottomTabButton,
  BottomTab,
} from 'src/Modules/TabViews/FooterComponents';

import {navigationRef} from 'src/Nav/navigationHelpers';
import {updateHomeState} from 'src/Redux/reducers/home.reducer';
import {updateLocalView} from 'src/Redux/reducers/local.reducer';
import {dispatchAction} from 'src/Utils/helpers';
import {setFirstVisitItems} from 'src/Redux/reducers/firstVisit.reducer';
import getAppType from '../Utils/getAppType';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';
import {useUserId} from 'src/Hooks/reduxHooks';

const DrawerTab: React.FC = () => {
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const hasPressedPrizes = useTypedSelector(
    (state) => state.firstVisit.hasPressedPrizes,
  );

  const eventId = useTypedSelector((state) => state.event?.eventId);

  const buttonStyle = {marginTop: 5, maxWidth: '25%'};

  const explorerPass = useExplorerPass();

  const userId = useUserId();

  useEffect(() => {
    let backHandler:
      | ReturnType<typeof BackHandler.addEventListener>
      | undefined;
    if (BackHandler && Platform.OS == 'android') {
      backHandler = BackHandler?.addEventListener?.('hardwareBackPress', () => {
        navigationRef.navigate('LandingPage', null, 'Nav/DrawerTab.tsx');
        return true;
      });
    }
    return () => {
      if (typeof backHandler?.remove == 'function') {
        backHandler?.remove?.();
      }
    };
  }, []);

  return useMemo(() => {
    console.log('drawer tab rerender');
    return (
      <BottomTab height={75} tabStyle={{alignItems: 'flex-start'}}>
        {!!eventId && (
          <BottomTabButton
            text={'Your Event'}
            testID={'yourEvent'}
            iconName={'star'}
            textStyle={{maxWidth: 52}}
            handlePress={() => {
              navigationRef.navigate('EventHome', null, 'Nav/DrawerTab.tsx');
            }}
            buttonStyle={buttonStyle}
            activeScreenName={'EventHome'}
          />
        )}
        {getAppType() == 'lr' && (
          <BottomTabButton
            text={!!eventId ? 'Other Activities' : 'Activities'}
            iconName={'directions'}
            textStyle={{maxWidth: 50}}
            handlePress={() => {
              navigationRef.navigate('LandingPage', null, 'Nav/DrawerTab.tsx');

              dispatchAction(
                updateHomeState({
                  homeView: 'list',
                  homeActiveSearch: false,
                  homeSearchText: false,
                }),
              );
            }}
            buttonStyle={buttonStyle}
            activeScreenName={'LandingPage'}
          />
        )}
        {/* {playTestState == false &&
          getAppType() == 'lr' &&
          Platform.OS != 'web' &&
          !window?.location?.href?.includes?.('isDemo') && (
            <BottomTabButton
              text={!!eventId ? 'App-Wide Leaderboard' : 'Prizes & Leaderboard'}
              testID={'prizes'}
              iconName={'badge'}
              textStyle={{maxWidth: 52}}
              handlePress={() => {
                dispatchAction(
                  setFirstVisitItems({items: {hasPressedPrizes: true}}),
                );
                navigationRef.navigate(
                  'Leaderboard',
                  null,
                  'Nav/DrawerTab.tsx',
                );
              }}
              buttonStyle={buttonStyle}
              activeScreenName={'Leaderboard'}
              badge={!hasPressedPrizes ? 1 : undefined}
            />
          )} */}

        {getAppType() == 'aa' && playTestState == false && (
          <BottomTabButton
            text={'Home'}
            iconName={'home'}
            textStyle={{maxWidth: 50}}
            handlePress={() => {
              navigationRef.navigate(
                'PersonalAssistant',
                null,
                'Nav/DrawerTab.tsx',
              );
            }}
            buttonStyle={buttonStyle}
            activeScreenName={'PersonalAssistant'}
          />
        )}
        {getAppType() == 'aa' && playTestState == false && (
          <BottomTabButton
            text={'Completed Requests'}
            iconName={'check'}
            textStyle={{maxWidth: 50}}
            handlePress={() => {
              navigationRef.navigate(
                'PACompletedRequests',
                null,
                'Nav/DrawerTab.tsx',
              );
            }}
            buttonStyle={buttonStyle}
            activeScreenName={'PACompletedRequests'}
          />
        )}
        {getAppType() == 'aa' && playTestState == false && (
          <BottomTabButton
            text={'New Request'}
            iconName={'plus'}
            textStyle={{maxWidth: 50}}
            handlePress={() => {
              if (!explorerPass || !userId) {
                return navigationRef.navigate(
                  'FreeTrialScreen',
                  null,
                  'LandingPageTabMenu.tsx',
                );
              }
              navigationRef.navigate(
                'PASubmitChat',
                null,
                'LandingPageTabMenu.tsx',
              );
            }}
            buttonStyle={buttonStyle}
            activeScreenName={'PASubmitChat'}
          />
        )}
        {getAppType() == 'aa' && playTestState == false && (
          <BottomTabButton
            text={'Active Requests'}
            iconName={'bell'}
            textStyle={{maxWidth: 50}}
            handlePress={() => {
              navigationRef.navigate(
                'PAActiveRequests',
                null,
                'Nav/DrawerTab.tsx',
              );
            }}
            buttonStyle={buttonStyle}
            activeScreenName={'PAActiveRequests'}
          />
        )}
        {!window?.location?.href?.includes?.('isDemo') &&
          getAppType() === 'lr' &&
          playTestState == false && (
            <BottomTabButton
              text={'Local Guide'}
              iconName={'map'}
              textStyle={{maxWidth: 50}}
              handlePress={() => {
                dispatchAction(updateLocalView('list'));
                navigationRef.navigate('LocalTab', null, 'Nav/DrawerTab.tsx');
              }}
              buttonStyle={buttonStyle}
              activeScreenName={'Local'}
            />
          )}

        {!window?.location?.href?.includes?.('isDemo') &&
          getAppType() == 'lr' &&
          playTestState == false && (
            <BottomTabButton
              text={'Profile'}
              textStyle={{maxWidth: 50}}
              iconName={'user'}
              handlePress={() => {
                navigationRef.navigate('ProfilePage', null, 'CallerString');
              }}
              buttonStyle={buttonStyle}
              activeScreenName={'ProfilePage'}
            />
          )}
        {getAppType() == 'aa' && (
          <BottomTabButton
            text={'Your Profile'}
            iconName={'user'}
            textStyle={{maxWidth: 50}}
            handlePress={() => {
              console.log('JoinLanding Pressed');
              navigationRef.navigate('PAProfile', null, 'Nav/DrawerTab.tsx');
            }}
            testID={'profile'}
            buttonStyle={buttonStyle}
            activeScreenName={'PAProfile'}
          />
        )}
        {getAppType() == 'lr' && (
          <BottomTabButton
            text={!!eventId ? 'Join Other Activities' : 'Join'}
            iconName={'action-redo'}
            textStyle={{maxWidth: 50}}
            handlePress={() => {
              console.log('JoinLanding Pressed');
              navigationRef.navigate('JoinLanding', {}, 'Nav/DrawerTab.tsx');
            }}
            testID={'joinCodeTabButton'}
            buttonStyle={buttonStyle}
            activeScreenName={'JoinLanding'}
          />
        )}
      </BottomTab>
    );
  }, [playTestState, eventId]);
};

export default DrawerTab;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState, useEffect} from 'react';
import {
  Platform,
  ScrollView,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Text,
  View,
} from 'react-native';

import {LinearGradient} from 'expo-linear-gradient';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import ExpoImage from 'src/Utils/ExpoImage';

///////////////////////////
///////////// utils

import {calculateDistance} from 'src/Utils/distanceHelpers';

import {
  setNavigationStartTime,
  setNavigationCompleteTime,
  handleBackupLocationClosed,
} from 'src/Utils/huntFunctions_v2';
import {apiUpdateUserBadges} from 'src/Utils/apiCalls';

///////////////////////////
///////////// components
import {
  ContinueButton,
  HtmlBlock,
  ExpandFullscreenButton,
} from 'src/Modules/CommonView';
import ChallengeMap from 'src/ScavengerHunt_v2/Challenges/ChallengeMap';

import {navigationRef} from 'src/Nav/navigationHelpers';

import {useLocation, useLocationId} from 'src/Hooks/gameHooks';
import {saveGroupPosition} from 'src/Utils/huntFunctions';
import {useSafeAreaInsets} from 'react-native-safe-area-context';

import {TLocation} from 'src/types/TLocation';

const basePhotoUrl = 'photos.letsroam.com';

const NavigationChallenge: React.FC = () => {
  const {width, height} = useWindowDimensionsSafe();

  const groupPhoto = useTypedSelector((state) => state.group?.info?.groupPhoto);
  const teamName = useTypedSelector((state) => state.group?.info?.teamName);
  const isBarHunt = useTypedSelector((state) => state.group?.info?.isBarHunt);

  let locationId = useLocationId();
  const location = useLocation();
  console.log('NavigationChallenge render', {location, locationId});

  const latitude =
    useTypedSelector((state) => state.location?.region?.latitude) || 0;
  const longitude =
    useTypedSelector((state) => state.location?.region?.longitude) || 0;

  // used for backup bars
  const locations = useTypedSelector((state) => state.game_v2?.locations);

  const locationLat = Number(location?.lat);
  const locationLong = Number(location?.long);

  const userLat = latitude.toString();
  const userLong = longitude.toString();

  const distanceAway = calculateDistance(
    locationLat,
    locationLong,
    Number(userLat),
    Number(userLong),
    'FEET',
  );

  console.log({distanceAway, locationLat, locationLong, userLat, userLong});

  const backupLocationList = useTypedSelector(
    (state) => state.game_v2?.backupLocationList,
  );
  const lastUsedBackUpBar = useTypedSelector(
    (state) => state.game_v2?.lastUsedBackUpBar,
  );

  const isNavigationChallenge = useTypedSelector(
    (state) => state.current_screen?.currentScreen == 'NavigationChallenge',
  );

  const [view, setView] = useState<string>('map'); // [ photo, map ]

  let huntLocation: TLocation | null | undefined = location;

  // only used for Bar Hunt
  if (huntLocation?.backupLocationForLocation) {
    const originalLocation = huntLocation.locationId;
    locationId = huntLocation.backupLocationForLocation;

    huntLocation =
      locations && locations[locationId] ? locations[locationId] : null;
    if (originalLocation && huntLocation) {
      huntLocation['originalLocation'] = originalLocation;
    }
  }

  const description = huntLocation?.description || '';
  const startTime = huntLocation?.startTime;
  const photoLarge = huntLocation?.photoLarge;
  const completeTime = huntLocation?.completeTime;

  const setStartTime = () => {
    locationId && setNavigationStartTime(locationId);
  };

  //setting the hunt start time
  useEffect(() => {
    //getting the current hunt location

    //synchronizing the start timer
    //sets a start time if not set yet
    if (locationId && !startTime) {
      setStartTime();
    }
  }, [isNavigationChallenge]);

  const handleBarClosed = () => {
    if (!location) return console.error('no location');

    if (!huntLocation?.backupLocationForLocation)
      return console.error('no backup location for location');

    return navigationRef.navigate(
      'ConfirmModal',
      {
        handleConfirm: (_, __, closeModal) => {
          if (
            !locationId ||
            !backupLocationList ||
            !huntLocation?.backupLocationForLocation
          ) {
            console.error('no data');
            navigationRef.simpleAlert(
              'No Data',
              'No data for backup bar, please contact support',
            );
            return;
          }
          handleBackupLocationClosed({
            locationId,
            backupLocationList,
            lastUsedBackUpBar: lastUsedBackUpBar || undefined,
            backupLocationForLocation: huntLocation?.backupLocationForLocation,
          });
          if (typeof closeModal == 'function') {
            closeModal();
          }
        },
        titleText: 'Is the local watering hole closed? ',
        titleBodyText: `We are sorry about that! 
      Make sure to report it in the the task bar as closed, so our writers can fix it!
      Do you want us to switch you out to another bar?`,
        confirmText: `Switch Bar`,
        cancelText: `Never mind`,
      },
      'ScavengerHunt_v2/Game/NavigationChallenge.tsx',
    );
    // need to fix start time/end time for back up bar, use for points calc

    // setBackUpBar
  };

  const handleHerePress = async () => {
    apiUpdateUserBadges();

    console.log('checkNavigationCompletion 1');
    //add correct nav for if this fires & they're already checked in?

    saveGroupPosition({latitude, longitude}, 'NavigationChallenge');

    const locationOffset = 3500;

    //// need flag for overriding geo-fencing, currently bypassed for dev
    const devOverride = false;

    const isClose =
      distanceAway < locationOffset ||
      devOverride ||
      Platform.OS == 'web' ||
      isHybridHunt;

    if (isClose && locationId && huntLocation) {
      await setNavigationCompleteTime(
        locationId,
        huntLocation || null,
        distanceAway,
      );
    }

    //console.log(checkIn)

    if (!isHybridHunt) {
      navigationRef.navigate(
        'ConfirmModal',
        {
          handleConfirm: async () => {
            navigationRef?.goBack(
              'ScavengerHunt_v2/Game/NavigationChallenge.tsx',
            );

            if (!locationId) {
              return console.error('no location id');
            }

            if (!huntLocation) {
              return console.error('no location');
            }

            await setNavigationCompleteTime(
              locationId,
              huntLocation,
              distanceAway,
            );
          },
          titleText: 'Are you at the location?',
          titleBodyText:
            'Maxmimize Your Points! Earn the most points by getting as close as possible to the check in point.',
          confirmText: `Check In Now`,
          cancelText: 'Keep Hunting',
          confirmColor: constants?.color?.blue,
        },
        'ScavengerHunt_v2/Game/NavigationChallenge.tsx',
      );
    }
  };

  console.log('NavigationCHallenge Render');

  console.log('navigation challenge render');

  const largeDisplayedPhoto = photoLarge
    ? `https://${basePhotoUrl}${photoLarge && photoLarge.substring(1)}`
    : groupPhoto;
  //console.log({largeDisplayedPhoto})

  const insets = useSafeAreaInsets();

  const topDimensions = {
    width,
    minWidth: width,
    // height: 90,
    height: height - 500,
    minHeight: 250,
  };

  const littleButtonDimensions = {
    width: 66,
    minWidth: 66,

    // height: 90,
    minHeight: 66,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    ...constants?.style.boxShadow,
  };

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  useEffect(() => {
    if (!!isHybridHunt) setView('photo');
  }, [isHybridHunt]);

  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <View style={{position: 'relative', ...topDimensions}}>
        {view == 'map' &&
          typeof huntLocation != 'undefined' &&
          !!huntLocation &&
          !isHybridHunt &&
          Platform.OS !== 'web' && (
            <ChallengeMap
              huntLocation={huntLocation}
              style={{
                ...topDimensions,
              }}
            />
          )}
        {view == 'photo' && !!largeDisplayedPhoto && (
          <ExpoImage
            style={{
              ...topDimensions,
              backgroundColor: 'black',
            }}
            source={{uri: largeDisplayedPhoto}}
            resizeMode={'cover'}
          />
        )}
        <TouchableOpacity
          activeOpacity={0.8}
          disabled={!!isHybridHunt}
          onPress={() => setView(view == 'map' ? 'photo' : 'map')}
          style={{position: 'absolute', bottom: 10, left: 10}}>
          <View
            style={{
              flexDirection: 'row',
              padding: 0,
              borderRadius: 12,
              ...constants?.style.boxShadow,
            }}>
            {view == 'map' && (
              <View testID={'MapButton'} style={{...littleButtonDimensions}}>
                {!!largeDisplayedPhoto && (
                  <ExpoImage
                    style={{...littleButtonDimensions}}
                    source={{uri: largeDisplayedPhoto}}
                    resizeMode={'cover'}
                  />
                )}
              </View>
            )}
            {view == 'photo' && !!huntLocation && !isHybridHunt && (
              <View testID={'PhotoButton'} style={{...littleButtonDimensions}}>
                <ChallengeMap
                  huntLocation={huntLocation as TLocation}
                  style={{
                    ...littleButtonDimensions,
                  }}
                />
              </View>
            )}
            <View style={{paddingRight: 10}}>
              {!isHybridHunt && (
                <Text
                  style={{
                    fontFamily: 'JakartaBold',
                    width: 150,
                    fontSize: 14,
                    lineHeight: 18,
                    marginLeft: 16,
                    marginTop: 10,
                  }}
                  ellipsizeMode="tail">
                  {location?.name}
                </Text>
              )}
              {!isHybridHunt && (
                <Text
                  style={{
                    fontFamily: 'Jakarta',
                    width: 150,
                    marginLeft: 16,
                    fontSize: 12,
                    opacity: 0.5,
                    marginTop: 4,
                  }}>
                  View Photo
                </Text>
              )}
            </View>
          </View>
        </TouchableOpacity>
        {!isHybridHunt && (
          <ExpandFullscreenButton
            isFullscreen={false}
            handlePress={() =>
              navigationRef.navigate(
                'FullScreenNavigationHelpModal',
                null,
                'ScavengerHunt_v2/Game/NavigationChallenge.tsx',
              )
            }
          />
        )}
      </View>
      <View style={{flex: 1}}>
        <View testID={'navigationChallengeHeader'} style={{flex: 1}}>
          {
            /////////////////////////////////////
            //////////////   challenge/photo view
          }

          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              position: 'relative',
            }}>
            <HtmlBlock
              html={description || ''}
              htmlStyle={{
                fontSize: 16,
                lineHeight: 21,
                fontFamily: 'Jakarta',
                padding: 10,
                marginBottom: 150,
              }}
              replaceText={teamName}
            />
          </ScrollView>
        </View>
        <LinearGradient
          colors={['rgba(255, 255,255, 0)', 'rgba(255, 255, 255, 1)']}
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 50,
            height: 100,
          }}
        />
        <View style={{position: 'absolute', bottom: insets.bottom, left: 20}}>
          <ContinueButton
            text={isHybridHunt ? `Continue` : `We're Here`}
            height={70}
            textStyle={{fontSize: 20}}
            containerStyle={{flex: 1, width: width - 40, height: 60}}
            handlePress={() => {
              // calling handle next
              if (!!completeTime) {
                constants?.handleNext?.();
              } else {
                handleHerePress();
              }
            }}
          />
          {isBarHunt === true && (
            <Text
              style={{
                textAlign: 'center',
                fontFamily: constants?.font.circular,
                marginTop: 20,
                paddingHorizontal: 5,
                width: width - 40,
              }}>
              If the bar is closed,{' '}
              <TouchableWithoutFeedback onPress={() => handleBarClosed()}>
                <Text
                  style={{
                    // color: constants?.color?.teal,
                    textDecorationLine: 'underline',
                  }}
                  testID={'barIsClosed'}>
                  tap here for an alternative bar to do your challenges at
                </Text>
              </TouchableWithoutFeedback>
              .
            </Text>
          )}
        </View>
      </View>
    </View>
  );
};

export default NavigationChallenge;

import constants from 'src/constants';
import React from 'react';
import {
  Image,
  ImageSourcePropType,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import ExpoImage from 'src/Utils/ExpoImage';

import {
  ContinueButton,
  ExpandFullscreenButton,
  ImageButton,
} from 'src/Modules/CommonView/Buttons';
import {BodyText, HeaderText, HtmlBlock} from 'src/Modules/CommonView/Text';
import Timer from 'src/ScavengerHunt_v2/Game/Timer';

import FeatherIcon from 'react-native-vector-icons/Feather';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import Ionicon from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import LineIcon from 'react-native-vector-icons/SimpleLineIcons';

import {navigationRef} from 'src/Nav/navigationHelpers';
import {achievementsData} from 'src/Data/Achievements';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {AnimatedPointsEarned} from 'src/Modules/CommonView/Text';
import {useChallenge} from 'src/Hooks/gameHooks';
import {TGroupPlayer} from 'src/types/TGroupInfo';
import {TCompletion} from 'src/types/TChallenge';
import GuessWhere from 'src/ScavengerHunt_v2/Challenges/GuessWhere';
import {calculateDistance} from 'src/Utils/distanceHelpers';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import CombinationLock from 'src/ScavengerHunt_v2/Challenges/CombinationLock';
import {LinearGradient} from 'expo-linear-gradient';

const backButtonDarkIcon = require('src/Images/BackButtonDark.png');
const huntMapIcon = require('src/Images/Icons/huntMap.png');

export const PointsDisplay: React.FC<{
  previousGuesses?: number;
  pointsPossible?: number;
}> = ({previousGuesses = 0, pointsPossible = 100}) => {
  return (
    <AnimatedPointsEarned
      pointsEarned={pointsPossible - 20 * previousGuesses || 0}
    />
  );
};

export const ChallengeHeader: React.FC<{
  handleBackPress: () => void;
  text: string;
  hideBack?: boolean;
  previousGuesses?: number;
  pointsPossible?: number;
  handleMapPress?: () => void;
  containerStyle?: ViewStyle;
}> = ({
  handleBackPress,
  text,
  hideBack,
  previousGuesses = 0,
  pointsPossible = 100,
  handleMapPress,
  containerStyle = {},
}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <View
      style={{
        alignItems: 'center',
        position: 'relative',
        width,
        marginTop: 10,
        ...containerStyle,
      }}>
      {!hideBack && (
        <ImageButton
          handlePress={handleBackPress}
          source={backButtonDarkIcon}
          imageStyle={{
            width: width > 350 ? 20 : 12,
            height: width > 350 ? 20 : 12,
          }}
          buttonStyle={{
            position: 'absolute',
            left: 10,
            top: 14,
            width: 40,
            height: 40,
          }}
        />
      )}
      <HeaderText bodyStyle={{width: 180}} text={text} />
      {!handleMapPress && (
        <PointsDisplay
          previousGuesses={previousGuesses}
          pointsPossible={pointsPossible}
        />
      )}
      {!!handleMapPress && (
        <ImageButton
          handlePress={handleMapPress}
          source={huntMapIcon}
          imageStyle={{
            width: 90,
            height: 40,
            marginVertical: 0,
            marginHorizontal: 0,
            ...constants?.style.boxShadow,
          }}
          buttonStyle={{
            position: 'absolute',
            top: 0,
            right: 6,
          }}
        />
      )}
    </View>
  );
};

export const TimerHeader: React.FC<{
  text: JSX.Element | string;
  seconds?: number;
  countUp?: boolean;
  startTime?: number;
  completeTime?: number;
  onComplete?: () => void;
  handlePress?: () => void;
  showBadge?: boolean;
  isTimed?: boolean;
}> = ({
  text,
  seconds,
  countUp = false,
  startTime,
  completeTime,
  onComplete,
  handlePress,
  showBadge = false,
  isTimed = false,
}) => {
  const {width} = useWindowDimensionsSafe();
  console.log({text, isTimed});
  console.log('TimerHeader render', {startTime, completeTime});
  return (
    <TouchableOpacity
      style={{
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 5,
        width,
      }}
      disabled={!handlePress}
      activeOpacity={0.8}
      onPress={handlePress}>
      {!!text && (
        <BodyText
          text={text}
          textStyle={{
            fontFamily: constants?.font.gothic,
            fontSize: 20,
            lineHeight: 24,
            marginTop: 0,
            width: width - 140,
          }}
        />
      )}
      {!!startTime && !completeTime && !!isTimed && (
        <Timer
          seconds={seconds || 90}
          startTime={startTime}
          countUp={countUp}
          onComplete={onComplete ? onComplete : () => {}}
          badge={showBadge ? '1' : '0'}
        />
      )}
      {/* show completed time, forces re mount of Timer component. Built this way
      for timer reset between slides, otherwise timer is active && running in
      background for navigation challenges even after completion */}
      {!!startTime && !!completeTime && (
        <Timer
          seconds={seconds || 90}
          startTime={startTime}
          countUp={countUp}
          onComplete={onComplete ? onComplete : () => {}}
          badge={showBadge ? '1' : '0'}
        />
      )}
    </TouchableOpacity>
  );
};

export const UserThumbnail: React.FC<{
  name?: string;
  photoUrl: string | undefined;
  photoColor?: string;
  size?: number;
}> = ({
  name = ' ',
  photoUrl,
  photoColor = constants?.color?.teal,
  size = 50,
}) => {
  return (
    <View
      style={{
        borderRadius: size / 2,
        height: size,
        position: 'relative',
        width: size,
      }}>
      <View
        style={{
          alignItems: 'center',
          backgroundColor: photoColor,
          borderRadius: size / 2,
          height: size,
          justifyContent: 'center',
          position: 'absolute',
          width: size,
        }}>
        <Text
          style={{
            color: constants?.color?.white,
            fontFamily: constants?.font.circular,
          }}>
          {name[0]}
        </Text>
      </View>
      {!!photoUrl && (
        <ExpoImage
          source={{uri: photoUrl}}
          style={{
            width: size,
            height: size,
            borderRadius: size / 2,
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      )}
    </View>
  );
};

export const PhotoChoiceTile: React.FC<{
  photoUrl: string;
  handlePress?: () => void;
}> = ({photoUrl, handlePress}) => {
  const {width} = useWindowDimensionsSafe();
  const tileWidth = (width - 60) / 3;
  return (
    <TouchableOpacity
      style={{marginHorizontal: 5}}
      onPress={handlePress}
      disabled={!handlePress}
      activeOpacity={0.8}>
      <ExpoImage
        source={{uri: photoUrl}}
        style={{width: tileWidth, height: tileWidth}}
        resizeMode={'cover'}
      />
    </TouchableOpacity>
  );
};

export const ChallengeBlock: React.FC<{
  title: JSX.Element | string;
  question?: string;
  directive?: string;
}> = ({title, question, directive}) => {
  return (
    <View style={{marginTop: 10, marginBottom: 20}}>
      <BodyText
        text={title}
        textStyle={{
          textAlign: 'center',
          fontSize: 14,
          lineHeight: 16,
          fontWeight: '700',
        }}
      />
      <HtmlBlock
        html={question || ''}
        htmlStyle={{
          fontStyle: 'italic',
          minHeight: 80,
          color: constants?.color?.gray3,
          fontFamily: constants?.font.circular,
          fontSize: 16,
          lineHeight: 20,
          marginTop: 10,
          marginHorizontal: 15,
        }}
      />
      {!!directive && <WideLine text={directive} />}
    </View>
  );
};

export const WideLine: React.FC<{text: string}> = ({text}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        width: width - 20,
        marginHorizontal: 10,
      }}>
      <View
        style={{
          flex: 1,
          height: 2,
          backgroundColor: constants?.color?.gray3,
        }}
      />
      <BodyText
        text={text}
        textStyle={{
          fontWeight: '700',
          marginTop: 0,
          fontSize: 12,
          lineHeight: 12,
        }}
      />
      <View
        style={{
          flex: 1,
          height: 2,
          backgroundColor: constants?.color?.gray3,
        }}
      />
    </View>
  );
};

export const NonChallengeView: React.FC<{
  titleText: string;
  bodyText: string;
  buttonText: string;
  handleButtonPress: () => void;
  imageSource: ImageSourcePropType;
  replaceText: string;
  brandingSource?: string;
  handleFullScreenPress?: () => void;
  children?: React.ReactNode;
  imageHeight?: number;
}> = ({
  titleText,
  bodyText,
  buttonText,
  handleButtonPress,
  imageSource,
  replaceText,
  brandingSource,
  handleFullScreenPress,
  children,
  imageHeight,
}) => {
  const {width, height} = useWindowDimensionsSafe();
  return (
    <ScrollView style={{flex: 1, backgroundColor: 'white'}}>
      <View
        style={{
          flex: 1,
          paddingBottom: 60,
          backgroundColor: 'white',
        }}>
        <TimerHeader text={titleText} />
        {!!imageSource && (
          <View
            style={{
              alignItems: 'center',
              backgroundColor: constants?.color?.white1,
              marginTop: 5,
              height: imageHeight || (height < 700 ? 220 : 290),
              justifyContent: 'center',
              position: 'relative',
              width,
            }}>
            <CustomActivityIndicator
              size={'small'}
              color={constants?.color?.gray3}
              style={{position: 'absolute'}}
            />
            <ExpoImage
              style={{
                width,
                height: imageHeight || (height < 700 ? 220 : 290),
              }}
              source={imageSource}
              resizeMode={'cover'}
            />
            {!!brandingSource && (
              <View
                style={{
                  position: 'absolute',
                  top: 5,
                  right: 5,
                  paddingHorizontal: 5,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-end',
                }}>
                <Image
                  style={{
                    width: 88,
                    height: 50,
                  }}
                  source={{uri: brandingSource}}
                  resizeMode={'contain'}
                />
              </View>
            )}
            {!!handleFullScreenPress && (
              <ExpandFullscreenButton handlePress={handleFullScreenPress} />
            )}
          </View>
        )}
        {!!bodyText && (
          <HtmlBlock
            html={bodyText || ''}
            htmlStyle={{fontSize: 16, lineHeight: 20}}
            replaceText={replaceText}
          />
        )}
        <View style={{alignItems: 'center'}}>
          {!!buttonText && (
            <ContinueButton
              text={buttonText}
              containerStyle={{width: 220}}
              handlePress={handleButtonPress}
            />
          )}
        </View>
        {!!children && children}
      </View>
    </ScrollView>
  );
};

export const AchievementIcon: React.FC<{
  size?: number;
  iconSet: string;
  icon: string;
  color?: string;
}> = ({size = 30, iconSet, icon, color = constants?.color?.gray3}) => {
  const circleSize = 50;
  return (
    <View
      style={{
        alignItems: 'center',
        backgroundColor: constants?.color?.white1,
        borderRadius: circleSize / 2,
        height: circleSize,
        justifyContent: 'center',
        width: circleSize,
      }}>
      {iconSet === 'FontAwesome' && (
        <FontAwesomeIcon name={icon} color={color} size={size} />
      )}
      {iconSet === 'MaterialCommunityIcons' && (
        <MaterialCommunityIcon name={icon} color={color} size={size} />
      )}
      {iconSet === 'Feather' && (
        <FeatherIcon name={icon} color={color} size={size} />
      )}
      {iconSet === 'Ionicons' && (
        <Ionicon name={icon} color={color} size={size} />
      )}
      {iconSet === 'SimpleLineIcons' && (
        <LineIcon name={icon} color={color} size={size} />
      )}
    </View>
  );
};

export const PlayerScoreCard: React.FC<{
  player: TGroupPlayer;
  index: number;
  color?: string;
  handlePress: () => void;
  cardWidth: number;
  containerStyle?: ViewStyle;
  characters: Record<string, {source: string}>;
}> = ({
  player,
  index,
  color,
  handlePress,
  cardWidth,
  containerStyle = {},
  characters = {},
}) => {
  const {
    character = 'Explorer',
    firstName = '',
    points = 0,
    achievements = [],
  } = player;

  const colors = [
    constants?.color?.blue,
    constants?.color?.teal,
    constants?.color?.gray2,
    constants?.color?.gray3,
  ];
  const characterSource = (
    characters[character || 'Explorer'] || characters.Explorer
  ).source;

  const backgroundColor = color || colors[index % 4];

  let formattedPoints;
  try {
    formattedPoints = Math.round(points || 500);
  } catch (formatError) {
    console.log(formatError);
    formattedPoints = points;
  }

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      style={{
        width: cardWidth,
        marginHorizontal: 10,
        marginVertical: 5,
        borderRadius: 10,
        ...containerStyle,
      }}
      onPress={handlePress}
      disabled={!handlePress}>
      <View
        style={{
          backgroundColor,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          width: cardWidth,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <BodyText
          text={firstName}
          textStyle={{marginVertical: 5, color: constants?.color?.white}}
        />
        <BodyText
          text={`${formattedPoints} pts`}
          textStyle={{marginVertical: 5, color: constants?.color?.white}}
        />
      </View>

      <View
        style={{
          backgroundColor: constants?.color?.white,
          minHeight: 90,
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}>
          <ExpoImage
            source={{uri: characterSource}}
            resizeMode={'contain'}
            style={{width: 80, height: 80, marginVertical: 15, flex: 1}}
          />
          {!!achievements?.length &&
            achievements.slice(0, 2)?.map?.((key) => {
              const {
                name = '',
                level = '',
                iconSet,
                icon,
                color,
              } = achievementsData[key] || {};
              return (
                <View
                  key={`${key}_ach`}
                  style={{
                    alignItems: 'center',
                    minWidth: 120,
                    marginVertical: 10,
                  }}>
                  <AchievementIcon
                    iconSet={iconSet}
                    color={color}
                    icon={icon}
                  />
                  <BodyText
                    text={`${name}`}
                    textStyle={{
                      fontSize: 10,
                      marginTop: 5,
                      fontWeight: '700',
                    }}
                  />
                  {!!level && (
                    <BodyText
                      text={`(${level})`}
                      textStyle={{fontSize: 10, marginTop: 0}}
                    />
                  )}
                </View>
              );
            })}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export const MultiChoiceAnswer: React.FC<{
  completions: Record<string, TCompletion> | null;
  teamName: string;
  isPhoto?: boolean;
  answers?: Array<{
    option?: string | null;
    correct?: boolean | null;
    selectedAt?: string | null;
    answer?: string | null;
    points?: string | null;
  }>;
  players?: Record<string, TGroupPlayer>;
  postContent?: string | null;
  pointsEarned: number;
  showMap?: boolean;
  showComboLock?: boolean;
  userId?: string;
  showAllAnswers?: boolean;
  showTopAnswers?: boolean;
}> = ({
  completions,
  teamName,
  answers = [],
  players,
  postContent = '',
  pointsEarned,
  showMap = false,
  showComboLock = false,
  userId = '',
  showAllAnswers = false,
  showTopAnswers = false,
}) => {
  console.log('MultiChoiceAnswer render');

  const correctAnswer = answers.find((answer) => answer.correct) || {};
  const answeredCorrectly = !!completions
    ? Object.values(completions).filter((completion) => {
        return completion.answeredCorrectly;
      }) || []
    : [];
  const answered = !!completions
    ? (Object.values(completions) || []).sort((a, b) => {
        return a === b ? 0 : a ? -1 : 1;
      })
    : [];

  const {points, challengeId, type} = useChallenge() || {};

  const formattedPostContent =
    postContent &&
    postContent
      .replace(/<p>/gi, '')
      .replace(/<\/p>/gi, '')
      .replace(/\[TEAM_NAME\]/gi, teamName);

  let previousWrongAttempts = 0;
  answers?.map?.((answer) => {
    if (answer?.selectedAt && !answer?.correct) {
      previousWrongAttempts++;
    }
  });

  const maxPoints = Math.round(
    (points || 500) *
      ((answers?.length - previousWrongAttempts) / (answers?.length || 1)),
  );

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  return showAllAnswers ? (
    <View key={`multi-choice-answers-${challengeId}`}>
      <View style={{alignItems: 'center', marginHorizontal: 20}}>
        <View style={{width: '100%', gap: 15}}>
          {answers?.map((answer, ix) =>
            showComboLock && answer?.option ? (
              <CombinationLock combination={answer.option} />
            ) : showTopAnswers ? (
              <LinearGradient
                key={`multi-choice-answers-${challengeId}-ans-${ix}`}
                colors={[
                  'rgb(125, 185, 232)',
                  'rgb(32, 124, 202)',
                  'rgb(41, 137, 216)',
                  'rgb(30, 87, 153)',
                ]}
                locations={[0, 0.49, 0.5, 1]}
                style={{borderRadius: 10}}>
                <View
                  style={{
                    height: 30,
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    paddingHorizontal: '30%',
                  }}>
                  <Text
                    style={{
                      textAlign: 'left',
                      fontSize: 14,
                      color: constants?.color?.white,
                      fontWeight: '700',
                    }}>
                    {answer.answer}
                  </Text>
                  <LinearGradient
                    colors={[
                      'rgb(206, 219, 233)',
                      'rgb(170, 197, 222)',
                      'rgb(97, 153, 199)',
                      'rgb(58, 132, 195)',
                      'rgb(65, 154, 214)',
                      'rgb(75, 184, 240)',
                      'rgb(58, 139, 194)',
                      'rgb(38, 85, 139)',
                    ]}
                    locations={[0, 0.17, 0.5, 0.51, 0.59, 0.71, 0.84, 1]}>
                    <Text
                      style={{
                        textAlign: 'center',
                        fontSize: 14,
                        color: constants?.color?.white,
                        fontWeight: '700',
                        padding: 6,
                        minWidth: 30,
                      }}>
                      {answer.points || '0'}
                    </Text>
                  </LinearGradient>
                </View>
              </LinearGradient>
            ) : (
              <View
                key={`multi-choice-answers-${challengeId}-ans-${ix}`}
                style={{
                  backgroundColor: answer?.correct
                    ? constants?.color?.teal
                    : answered &&
                      answer?.option === answered[answered.length - 1]?.option
                    ? constants?.color?.red
                    : constants?.color?.orange,
                  height: 30,
                  width: '100%',
                  borderRadius: 10,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <Text
                  style={{
                    textAlign: 'center',
                    fontSize: 14,
                    color: constants?.color?.white,
                    fontWeight: answer?.correct ? '700' : '400',
                  }}>
                  {answer.option}
                </Text>
              </View>
            ),
          )}
        </View>
        {!!postContent && (
          <BodyText textStyle={{fontSize: 16, lineHeight: 18}}>
            {formattedPostContent}
          </BodyText>
        )}
      </View>
      {showMap && (
        <View style={{alignItems: 'center', marginVertical: 5}}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginHorizontal: 10,
              marginTop: 10,
              flexWrap: 'wrap',
            }}>
            {answered?.map?.((completion, ix) => {
              const completionUser = completion?.userId
                ? players?.[completion?.userId] || null
                : null;
              const firstName = completionUser?.firstName || '';
              const photoUrl = completionUser?.photoUrl || '';
              const photoColor = completionUser?.photoColor || '';
              const latlng = showMap ? completion?.option?.split(',') : '';
              const correctLatLng = showMap
                ? correctAnswer?.option?.split(',')
                : '';

              return (
                <View
                  key={`multi-choice-answers-${challengeId}-mapmarker-${ix}`}
                  style={{alignItems: 'center'}}>
                  {showMap && latlng && correctLatLng && (
                    <BodyText
                      text={`${
                        completion?.userId === userId
                          ? 'Your'
                          : firstName + "'s"
                      } guess was ${
                        Math.round(
                          calculateDistance(
                            parseFloat(latlng[0]),
                            parseFloat(latlng[1]),
                            parseFloat(correctLatLng[0]),
                            parseFloat(correctLatLng[1]),
                            'MILES',
                          ),
                        ) || 0
                      } miles off`}
                      textStyle={{fontWeight: '700', textAlign: 'center'}}
                    />
                  )}
                </View>
              );
            })}
          </View>
          <GuessWhere
            onAllAnswersModal={true}
            correctAnswerOverride={correctAnswer.option || ''}
            completionsOverride={completions}
          />
        </View>
      )}
    </View>
  ) : (
    <View key={`multi-choice-${challengeId}`}>
      {type !== 'where_map' && (
        <>
          <BodyText
            text={'The Correct Answer Was:'}
            textStyle={{
              marginVertical: 10,
              textAlign: 'center',
              fontWeight: '700',
            }}
          />

          <View style={{alignItems: 'center'}}>
            <ContinueButton
              text={correctAnswer.option || 'Correct Answer'}
              textStyle={{color: constants?.color?.gray3}}
              containerStyle={{minWidth: 220}}
              background={constants?.color?.white1}
              icon={'flag'}
              handlePress={() => {
                navigationRef.navigate(
                  'ReportModal',
                  null,
                  'Modules/CommonView/GameElements.tsx',
                );
              }}
            />
            {!!postContent && (
              <BodyText textStyle={{fontSize: 16, lineHeight: 18}}>
                {formattedPostContent}
              </BodyText>
            )}
          </View>
        </>
      )}
      <View style={{alignItems: 'center', marginVertical: 5}}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 10,
            marginTop: 10,
            flexWrap: 'wrap',
          }}>
          {answered?.map?.((completion, ix) => {
            const completionUser = completion?.userId
              ? players?.[completion?.userId] || null
              : null;
            const firstName = completionUser?.firstName || '';
            const photoUrl = completionUser?.photoUrl || '';
            const photoColor = completionUser?.photoColor || '';
            const latlng = showMap ? completion?.option?.split(',') : '';
            const correctLatLng = showMap
              ? correctAnswer?.option?.split(',')
              : '';

            return (
              <View key={'completion' + ix} style={{alignItems: 'center'}}>
                {showMap && latlng && correctLatLng && (
                  <BodyText
                    text={`${
                      completion?.userId === userId ? 'Your' : firstName + "'s"
                    } guess was ${
                      Math.round(
                        calculateDistance(
                          parseFloat(latlng[0]),
                          parseFloat(latlng[1]),
                          parseFloat(correctLatLng[0]),
                          parseFloat(correctLatLng[1]),
                          'MILES',
                        ),
                      ) || 0
                    } miles away`}
                    textStyle={{fontWeight: '700', textAlign: 'center'}}
                  />
                )}
                {!isHybridHunt && (
                  <View
                    style={{
                      marginTop: showMap ? 10 : 0,
                      marginHorizontal: 5,
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 40,
                      height: 40,
                    }}
                    key={`${completion.userId}_answ`}>
                    <UserThumbnail
                      name={firstName}
                      size={30}
                      photoUrl={photoUrl || ''}
                      photoColor={photoColor || ''}
                    />
                    <View
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }}>
                      <FontAwesomeIcon
                        size={22}
                        color={completion.answeredCorrectly ? 'green' : 'red'}
                        name={completion.answeredCorrectly ? 'check' : 'times'}
                      />
                    </View>
                  </View>
                )}
              </View>
            );
          })}
        </View>
        {!showMap && !!answeredCorrectly?.length && (
          <BodyText
            text={`Correct: +${Math.round(
              pointsEarned ??
                (maxPoints || (answeredCorrectly?.length || 0) * 100),
            )} pts`}
          />
        )}
        {showMap && <GuessWhere />}
      </View>
    </View>
  );
};

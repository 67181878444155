import {useEffect, useState} from 'react';
import {mmkvStorage} from 'src/Utils/mmkvStorage';

const BOOLEAN_TRUE_STRING = 'boolean-true';
const BOOLEAN_FALSE_STRING = 'boolean-false';

const useLocalState = (key: string, defaultValue: string | boolean) => {
  const [localState, setLocalState] = useState<string | boolean>(defaultValue);

  const getSavedValue = async () => {
    const savedValue = await mmkvStorage.getString(key);
    switch (savedValue) {
      case BOOLEAN_TRUE_STRING:
        setLocalState(true);
        break;
      case BOOLEAN_FALSE_STRING:
        setLocalState(false);
        break;
      default:
        if (savedValue !== null && savedValue !== undefined) {
          setLocalState(savedValue);
        }
        break;
    }
  };

  useEffect(() => {
    getSavedValue();
  }, []);

  useEffect(() => {
    console.log('updating local state', {key, localState});
    if (typeof localState === 'boolean') {
      const booleanValueAsString = localState
        ? BOOLEAN_TRUE_STRING
        : BOOLEAN_FALSE_STRING;
      mmkvStorage.set(key, booleanValueAsString);
    } else {
      mmkvStorage.set(key, localState);
    }
  }, [localState]);

  return [localState, setLocalState] as const;
};

export default useLocalState;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect} from 'react';

import {Text, View, ViewStyle} from 'react-native';

import {BodyText} from 'src/Modules/CommonView';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import {useLocation} from 'src/Hooks/gameHooks';
import {calculateDistance} from 'src/Utils/distanceHelpers';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {setIsChallengeCloseInformation} from 'src/Redux/reducers/game_v2.reducer';
export const DistanceAway: React.FC<{
  style?: ViewStyle;
  textOnly?: boolean;
  isCloseCutoff?: number;
  lat?: number;
  long?: number;
}> = ({style = {}, textOnly, isCloseCutoff = 500, lat, long}) => {
  const location = useLocation();

  const locationId = location?.locationId;

  const locationLat = location?.lat || lat;
  const locationLong = location?.long || long;

  const userLat =
    useTypedSelector((state) => state.location?.region?.latitude) || 0;
  const userLong =
    useTypedSelector((state) => state.location?.region?.longitude) || 0;

  const distanceAway = calculateDistance(
    locationLat || 0,
    locationLong || 0,
    Number(userLat.toString()),
    Number(userLong.toString()),
    'FEET',
  );

  // checking if not in error
  // and checking that distance away is beneath the cutoff
  const isClose = distanceAway !== -1 && distanceAway < isCloseCutoff;

  useEffect(() => {
    console.log('isClose Change', {
      isClose,
      locationId,
      distanceAway,
      locationLat,
      locationLong,
    });

    if (!locationId) {
      return console.log('no location');
    }

    const oldIsChallengeCloseInformation = getReduxState(
      (state) => state?.game_v2?.isChallengeCloseInformation,
    );

    if (
      oldIsChallengeCloseInformation?.locationId != locationId ||
      oldIsChallengeCloseInformation?.isClose != isClose
    ) {
      // getReduxState('')
      console.log(
        'check if should fire setIsChallengeCloseInformation: change',
        {oldIsChallengeCloseInformation, locationId, isClose},
      );

      dispatchAction(
        setIsChallengeCloseInformation({
          locationId,
          isClose,
        }),
      );
    } else {
      console.log(
        'check if should fire setIsChallengeCloseInformation:  change',
        {oldIsChallengeCloseInformation, locationId, isClose},
      );
    }
  }, [isClose, locationId]);
  // if(__D)

  console.log('DistanceAway', {
    distanceAway,
    locationLat,
    locationLong,
    userLat,
    userLong,
  });

  if (!locationLat) {
    return <View></View>;
  }

  const formattedDistanceAway =
    distanceAway <= -0.0001
      ? `Error`
      : distanceAway > 5280
      ? `${Math.round((100 * distanceAway) / 5280) / 100} mi away`
      : `${Math.round(distanceAway)} ft away`;

  if (textOnly) {
    return (
      <Text style={{color: 'white', fontFamily: 'JakartaBold', fontSize: 14}}>
        {formattedDistanceAway}
      </Text>
    );
  }

  return (
    <View
      style={{
        backgroundColor: constants?.color?.gray3,
        paddingHorizontal: 5,
        paddingVertical: 5,
        position: 'absolute',
        top: 10,
        left: 10,
        ...style,
      }}>
      <BodyText
        textStyle={{
          color: constants?.color?.white,
          fontWeight: '700',
          fontSize: 14,
          marginTop: 0,
          textAlign: 'center',
        }}>
        <FontAwesomeIcon name={'search'} size={12} color={'white'} />
        {formattedDistanceAway}
      </BodyText>
    </View>
  );
};

import useTypedSelector from 'src/Hooks/useTypedSelector';
import * as Updates from 'expo-updates';

import {Image, Platform, Text, TouchableOpacity, View} from 'react-native';

import React from 'react';
import {setExpoUpdateAvailable} from 'src/Redux/reducers/app_info.reducer';

import AppLink from 'react-native-app-link';
import {dispatchAction} from 'src/Utils/helpers';

import {openFirebaseLink} from 'src/Nav/DrawerMenu';

const upgradeImage = {
  uri: 'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648577952_medium.png',
};

// Props passed in from use copilot
const UpgradeView: React.FC = () => {
  const expoUpdateAvailable = useTypedSelector((state) => {
    // if (typeof window !== 'undefined') {
    //   (window as any).state = state;
    // }
    return state.app_info?.expoUpdateAvailable;
  });
  const isLRAdmin = useTypedSelector(
    (state) => __DEV__ || state.user?.info?.email?.includes?.('letsroam'),
  );

  return (
    <View
      testID="appInitialLoadDone"
      style={{
        flex: 1,
        backgroundColor: 'rgba(80, 87, 89, 1)',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <View style={{height: 360, width: 300, backgroundColor: 'white'}}>
        <Image
          style={{
            height: 300,
            width: 300,
            backgroundColor: 'white',
            resizeMode: 'contain',
          }}
          source={upgradeImage}
        />
        <View
          style={{
            height: 10,
            width: 300,
            backgroundColor: 'rgba(80, 87, 89, 1)',
          }}
        />
        <TouchableOpacity
          style={{
            height: 50,
            width: 300,
            backgroundColor: 'rgba(232, 119, 34, 1)',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
          }}
          onPress={() => {
            expoUpdateAvailable
              ? Updates?.reloadAsync?.()
                  .then(() => {})
                  .catch(() => {
                    dispatchAction(setExpoUpdateAvailable(0));
                  })
              : AppLink?.openInStore({
                  appStoreId: 1338916789,
                  playStoreId: 'com.barhuntv2',
                  appName: `Let's Roam`,
                  appStoreLocale: 'us',
                });
          }}>
          <Text
            style={{
              fontFamily: 'Alternate Gothic No3 D',
              fontSize: 30,
              color: 'white',
              textAlign: 'center',
            }}>
            {'UPGRADE YOUR APP'}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            marginLeft: 10,
            marginTop: 2,
          }}
          onPress={() => {
            openFirebaseLink(
              `https://console.firebase.google.com/u/0/project/barhuntv2/database/barhuntv2/data/versionInfo/${
                Platform.OS
              }/${Updates.runtimeVersion?.replace?.(/\./gi, '_')}`,
              isLRAdmin,
            );
          }}>
          <Text
            style={{
              fontSize: 10,
              color: 'white',
              textAlign: 'center',
              marginTop: 10,
            }}>
            {`Current App Version: ${
              Updates.runtimeVersion || 'No Device Id'
            }` + (__DEV__ ? ' (Click Here To Change)' : '')}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default UpgradeView;

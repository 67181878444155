import {persistReducer, persistStore} from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import React, {ReactNode} from 'react';
import {configureStore, EnhancedStore} from '@reduxjs/toolkit';
import rootReducer from 'src/Redux/reducers/rootReducer';
import {Platform} from 'react-native';
import localforage from 'localforage';

interface StoreProps {
  children: ReactNode;
}

const whiteList = [
  'app_info',
  'firstVisit',
  'location',
  'user',
  'event',
  'home',
  'ticket_vouchers',
];

if (Platform.OS != 'android') {
  whiteList.push('hunts');
}

const persistConfig =
  Platform.OS === 'web'
    ? {
        key: 'root',
        storage: localforage,
      }
    : {
        key: 'root',
        version: 1,
        storage: AsyncStorage,
        whitelist: whiteList,
      };

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store: EnhancedStore = configureStore({
  // only persisting in the app
  reducer: persistedReducer,
  devTools: __DEV__,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }) as any,
});

const persistor = persistStore(store);

const Store: React.FC<StoreProps> = ({children}) => {
  console.log('loading store');
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default Store;

import constants from 'src/constants';
import React, {useState, useEffect} from 'react';
import {
  ImageSourcePropType,
  Linking,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import {useUserId} from 'src/Hooks/reduxHooks';
import {BodyText} from 'src/Modules/CommonView';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import ExpoImage from 'src/Utils/ExpoImage';

import Icon from 'react-native-vector-icons/FontAwesome';

import {applyReviewBonusPoints} from 'src/Utils/huntFunctions';

import {navigationRef} from 'src/Nav/navigationHelpers';
import {apiMarkSocialCompleted} from 'src/Utils/apiCalls';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {useFirebaseData} from 'src/Utils/fireUtils';
import useTypedSelector from 'src/Hooks/useTypedSelector';
// import {ImageSourcePropType} from 'react-native';

interface SocialReviewItemProps {
  reviewLink: string;
  reviewTitle?: string;
  reviewMessage?: string;
  reviewType: string;
  reviewIcon?: ImageSourcePropType | undefined;
  reviewLetter?: string;
  reviewText: string;
  reviewColor: string;
  points?: number;
  showReviewItem: boolean;
  embedded?: boolean;
  dollars?: number;
  skipVerify?: boolean;
  groupId?: string;
}

interface BonusCompletion {
  userId?: string;
  bonudId?: string;
  bonusType?: string;
  points?: number;
  dateTime?: string;
  description?: string;
  pointsEarned: number;
}

const SocialReviewItem: React.FC<SocialReviewItemProps> = ({
  reviewLink,
  reviewTitle,
  reviewMessage,
  reviewType,
  reviewIcon,
  reviewLetter,
  reviewText,
  reviewColor,
  points,
  showReviewItem,
  dollars,
  skipVerify = false,
  groupId,
}) => {
  const {width} = useWindowDimensionsSafe();

  const userId = useUserId();
  const eventId =
    useTypedSelector((state) => state.event?.info?.eventId || '') || '';

  const [showVerify, setShowVerify] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [hasReview, setHasReview] = useState<boolean>(false);
  const [showContinue, setShowContinue] = useState<boolean>(false);
  const [reviewAlreadySubmitted, setReviewAlreadySubmitted] =
    useState<boolean>(false);

  // fetch group bonus completions
  const groupBonusCompletions =
    useFirebaseData(
      `scavengerhunt/completions/${groupId}/bonus`,
      null, // no side effect
      null, // no 'order by' key
      null, // no 'order by' value
      false, // hide logs
      !groupId, // suppress if not groupId
    ) || {};

  // format
  const bonusCompletions: BonusCompletion[] = Object.values(
    groupBonusCompletions,
  );

  useEffect(() => {
    // filter for completions by this user
    const userIdBonusCompletions = bonusCompletions.filter(
      (bonusCompletion) => bonusCompletion.userId === userId,
    );

    // get the completion types: "googleReview", "facebookReview", etc.
    const userBonusCompletionTypes = userIdBonusCompletions.map(
      (userBonusCompletion) => userBonusCompletion.bonusType,
    );

    // if "googleReview" included in types array, then user has already submitted and
    // gotten bonus points for this reviewType
    setReviewAlreadySubmitted(userBonusCompletionTypes.includes(reviewType));
  }, [bonusCompletions, userId, reviewType]);

  const handleReviewPress = () => {
    console.log('handle review press');
    // this is never true as we never pass in
    // values for both reviewTitle and reviewMessage
    if (reviewTitle && reviewMessage) {
      navigationRef.navigate(
        'ConfirmModal',
        {
          handleConfirm: async (_, __, handleDone) => {
            handleDone();
            Linking?.openURL(reviewLink);
            if (!hasReview) {
              setShowVerify(true);
              if (skipVerify) {
                setShowContinue(true);
                setHasReview(true);
              }
            }
          },
          confirmText: 'Leave A Review',
          cancelText: 'Cancel',
          confirmColor: constants?.color?.orange,
          titleText: reviewTitle,
          titleBodyText: reviewMessage,
        },
        'ScavengerHunt_v2/Challenges/SocialReviewItem.tsx',
      );
    } else {
      Linking?.openURL(reviewLink);
      if (!hasReview) {
        setShowVerify(true);
        if (skipVerify) {
          setShowContinue(true);
          setHasReview(true);
        }
      }
    }
  };

  const handleReviewVerifyPress = () => {
    userId && applyReviewBonusPoints(reviewType, 300, userId);
    userId && apiMarkSocialCompleted({userId, challengeType: reviewType});
    setHasReview(true);
  };

  if (!showReviewItem) {
    return <View />;
  }

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 0,
      }}>
      <View
        style={{
          height: 1,
          width: 100,
          backgroundColor: 'black',
        }}
      />

      <TouchableOpacity
        style={{
          backgroundColor: reviewColor,
          height: 80,
          marginVertical: 5,
          marginHorizontal: 10,
          width: width - 20,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        disabled={reviewAlreadySubmitted || !!hasReview}
        onPress={() => handleReviewPress()}>
        {!!reviewIcon && (
          <ExpoImage
            style={{
              width: 30,
              height: 25,
              marginLeft: 10,
            }}
            source={reviewIcon}
            resizeMode={'contain'}
          />
        )}
        {!!reviewLetter && (
          <Text
            style={{
              // width: 30,
              // height: 25,
              marginLeft: 10,
              color: 'white',
              fontWeight: '700',
              fontSize: 35,
            }}>
            {reviewLetter}
          </Text>
        )}

        <BodyText
          text={reviewText}
          textStyle={{
            color: constants?.color?.white,
            fontSize: 16,
            width: 200,
            textAlign: 'center',
            marginTop: 5,
          }}
        />
        <View
          style={{
            backgroundColor: constants?.color?.white1,
            width: 90,
            height: 80,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Icon name={'pencil'} size={24} color={constants?.color?.gray3} />
          {!!points && (
            <BodyText
              text={`+ ${points} pts`}
              textStyle={{fontSize: 14, marginTop: 5}}
            />
          )}
          {!!dollars && (
            <BodyText
              text={`+ $${dollars}`}
              textStyle={{fontSize: 14, marginTop: 5}}
            />
          )}
        </View>
      </TouchableOpacity>

      {!!showVerify && !hasReview && !skipVerify && !eventId && (
        <TouchableOpacity
          style={{
            backgroundColor: constants?.color?.orange,
            height: 40,
            marginVertical: 10,
            width: width - 100,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onPress={() => handleReviewVerifyPress()}>
          {!!isValidating ? (
            <View
              style={{
                width: 200,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <CustomActivityIndicator size={'small'} color={'white'} />
            </View>
          ) : (
            <Text
              style={{
                color: 'white',
                fontSize: 20,
                width: 220,
                fontFamily: 'Alternate Gothic No3 D',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {!!showContinue
                ? 'CONTINUE'
                : hasReview
                ? 'Points Awarded'
                : 'VERIFY YOUR ACTION'}
            </Text>
          )}
        </TouchableOpacity>
      )}
      {(!!showContinue ||
        !!showVerify ||
        !!hasReview ||
        !!reviewAlreadySubmitted) &&
        !skipVerify &&
        !eventId && (
          <Text
            style={{
              color: constants?.color?.gray3,
              fontSize: 14,
              width: 280,
              paddingLeft: 0,
              paddingTop: 0,
              fontFamily: constants?.font.circular,
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: 10,
            }}>
            {!!showContinue || reviewAlreadySubmitted
              ? `Thanks for the review! You earned ${points} bonus points`
              : showVerify
              ? hasReview || reviewAlreadySubmitted
                ? `Thanks for the review! You earned ${points} bonus points!`
                : 'It can take up to 1 minute after you post for our system to find the review.'
              : ''}
          </Text>
        )}
    </View>
  );
};

export default SocialReviewItem;

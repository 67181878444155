import React, {useEffect, useRef} from 'react';
import {Text, View} from 'react-native';
import {TouchableHighlight} from 'react-native-gesture-handler';
import {GooglePlacesAutocomplete} from 'react-native-google-places-autocomplete';
import {SafeAreaView} from 'react-native-safe-area-context';
import useParams from 'src/Hooks/useParams';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {navigationRef} from 'src/Nav/navigationHelpers';
import constants from 'src/constants';
import SimpleLineIcon from 'react-native-vector-icons/SimpleLineIcons';
const AutoCompleteAddressModal = () => {
  const {initialAddress, onComplete, locationName} =
    useParams<'AutoCompleteAddressModal'>();
  const {width} = useWindowDimensionsSafe();
  const autoCompleteRef = useRef<typeof GooglePlacesAutocomplete>(null);
  useEffect(() => {
    if (initialAddress) {
      // @ts-ignore
      autoCompleteRef.current?.setAddressText(initialAddress);
    }
  }, []);
  return (
    <SafeAreaView style={{display: 'flex', padding: 20, flex: 1}}>
      <View
        style={{
          display: 'flex',
          backgroundColor: constants.color.white1,
          flex: 1,
          padding: 20,
        }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 20,
          }}>
          <TouchableHighlight
            style={{paddingLeft: 0, paddingTop: 12, marginRight: 10}}>
            <SimpleLineIcon
              onPress={() => {
                navigationRef.goBack('SimpleStackNavigator');
              }}
              name={'arrow-left-circle'}
              size={24}
              color={constants?.color?.gray3}
            />
          </TouchableHighlight>
          <Text
            style={{
              fontFamily: 'JakartaBold',
              fontSize: 18,
              // lineHeight: 21,
              marginTop: 12,
            }}>
            Find An Address For {locationName || 'Your Location'}
          </Text>
        </View>
        <GooglePlacesAutocomplete
          placeholder="Search location address"
          enablePoweredByContainer={false}
          autoCompleteRef={false}
          // @ts-ignore
          ref={autoCompleteRef}
          styles={{
            textInputContainer: {
              width: width - 80,
              marginTop: 20,
            },
            placesautocomplete: {
              flex: 1,
            },
            listView: {
              width: width - 80,
            },
            textInput: {
              height: 38,
              color: '#5d5d5d',
              fontSize: 16,
              backgroundColor: '#fff',
            },
            predefinedPlacesDescription: {
              color: '#1faadb',
            },
          }}
          fetchDetails={true}
          onPress={(data, details) => {
            if (!details) {
              return console.error('no place');
            }
            onComplete(
              details?.formatted_address,
              details?.geometry?.location?.lat,
              details?.geometry?.location?.lng,
            );
            navigationRef.goBack('AutoCompleteAddressModal');
          }}
          onFail={(error) => console.error(error)}
          query={{
            key: 'AIzaSyBl9aZnUxqdmA_6o6Rr6zSDadHdPVL3ClQ',
            language: 'en',
          }}
        />
      </View>
    </SafeAreaView>
  );
};

export default AutoCompleteAddressModal;

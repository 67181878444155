import {fold} from 'fp-ts/lib/Either'; // Ensure you've imported necessary helpers
import {pipe} from 'fp-ts/lib/pipeable'; // Ensure you've imported necessary helpers
import {Type} from 'io-ts';

export const validateResult = <A>(
  input: unknown,
  ioType: Type<A, unknown, unknown>,
  typeName: string,
  haveConsoleError: boolean = true, // Defaulting to true
): boolean => {
  const validationResult = ioType.decode(input);
  return pipe(
    validationResult,
    fold(
      (errors) => {
        const conciseErrors = errors.map((error) => {
          const path = error.context
            .map((c) => c.key)
            .filter((k) => k.length)
            .join('/');
          const expectedType =
            error.context[error.context.length - 1].type.name || 'unknown';
          const errorMessage = `Invalid value ${JSON.stringify(
            error.value,
          )} supplied to ${path}: expected ${expectedType}`;

          if (haveConsoleError) {
            console.error(`Validation Errors for ${typeName}:` + errorMessage);
            console.log({input});
          } else {
            const red = '\x1b[31m';
            const reset = '\x1b[0m';
            console.log(
              `${red}Validation Errors for ${typeName}:${reset}`,
              `${red}${errorMessage}${reset}`,
            );
            console.log('input:', input);
          }

          return errorMessage;
        });

        // If you want to display the array of all errors, you can uncomment the following:
        if (haveConsoleError) {
          console.log(
            `Validation Errors for ${typeName}`,
            conciseErrors.join('\n'),
          );
        }

        return false;
      },
      () => true,
    ),
  );
};

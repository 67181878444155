import moment from 'moment';
import {logForCrashlytics} from 'src/Utils/fireUtils';
import {getReduxState} from 'src/Utils/helpers';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {TPass} from 'src/types/TUser';

/**
 * Custom hook to retrieve the explorer pass from the Redux store.
 * @returns {boolean | Object} The explorer pass object if valid, or false if not.
 */
export const useExplorerPass = () => {
  logForCrashlytics('getExplorerPassHook fired in backpackFunctions');
  const pass = useTypedSelector((state) => state.user?.info?.pass);
  let explorerPass: TPass | null = null;

  if (pass && pass.expiresDate) {
    console.log('the user has a pass that may or may not be valid', {pass});
    const hasValidExplorerPass = moment(pass.expiresDate).isAfter(moment());
    if (hasValidExplorerPass) {
      explorerPass = pass;
    }
  }
  return explorerPass;
};

/**
 * Function to retrieve the explorer pass from the Redux store.
 * @returns {false | TPass} The explorer pass object if valid, or false if not.
 */
export const getExplorerPass = (): TPass | false => {
  logForCrashlytics('getExplorerPassHook fired in backpackFunctions');
  const pass = getReduxState((state) => state?.user?.info?.pass);
  let explorerPass: false | TPass = false;

  if (pass && pass.expiresDate) {
    console.log('the user has a pass that may or may not be valid', {pass});
    const hasValidExplorerPass = moment(pass.expiresDate).isAfter(moment());
    if (hasValidExplorerPass) {
      explorerPass = pass;
    }
  }
  return explorerPass;
};

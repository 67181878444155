import React, {ReactNode, useEffect, useState} from 'react';
import {View, ViewStyle} from 'react-native';

interface DelayedLoadProps {
  children: ReactNode;
  delay?: number | null;
  loadingStyle?: ViewStyle | null;
  skipDelay?: boolean;
}

const DelayedLoad: React.FC<DelayedLoadProps> = ({
  children,
  delay = 1000,
  loadingStyle = {},
  skipDelay = false,
}) => {
  const [hasLoaded, setHasLoaded] = useState<boolean>(skipDelay);

  useEffect(() => {
    setTimeout(() => {
      setHasLoaded(true);
    }, delay || 1000);
  }, []);

  if (!hasLoaded) {
    return <View style={{flex: 1, ...loadingStyle}}></View>;
  }

  return <>{children}</>;
};

export default DelayedLoad;

import * as Device from 'expo-device';
import {ImageStyle} from 'react-native';

const constants = {
  color: {
    orange: 'rgba(232, 119, 34, 1)',
    teal: 'rgba(106, 174, 170, 1)',
    gray1: 'rgba(217, 217, 214, 1)',
    gray2: 'rgba(167, 168, 170, 1)',
    gray3: 'rgba(80, 87, 89, 1)',
    blue: 'rgba(83, 166, 196, 1)',
    blue_30: 'rgba(83, 166, 196, .3)',
    blue_50: 'rgba(83, 166, 196, .5)',
    blue_60: 'rgba(83, 166, 196, .6)',
    blue_80: 'rgba(83, 166, 196, .8)',
    yellow: 'rgba(255, 198, 0, 1)',
    white: 'rgba(255, 255, 255, 1)',
    white1: '#f2f2f2',
    orange_30: 'rgba(232, 119, 34, 0.3)',
    orange_50: 'rgba(232, 119, 34, 0.5)',
    teal_30: 'rgba(106, 174, 170, 0.3)',
    teal_50: 'rgba(106, 174, 170, 0.5)',
    teal_70: 'rgba(106, 174, 170, 0.7)',
    teal_80: 'rgba(106, 174, 170, 0.8)',
    gray1_30: 'rgba(217, 217, 214, 0.3)',
    gray1_50: 'rgba(217, 217, 214, 0.5)',
    gray1_80: 'rgba(217, 217, 214, 0.8)',
    gray2_50: 'rgba(167, 168, 170, 0.5)',
    gray3_30: 'rgba(80, 87, 89, 0.3)',
    gray3_50: 'rgba(80, 87, 89, 0.5)',
    gray3_60: 'rgba(80, 87, 89, 0.6)',
    gray3_80: 'rgba(80, 87, 89, 0.8)',
    yellow_50: 'rgba(255, 198, 0, 0.5)',
    white_30: 'rgba(255, 255, 255, 0.3)',
    white_50: 'rgba(255, 255, 255, 0.5)',
    white_70: 'rgba(255, 255, 255, 0.7)',
    white_80: 'rgba(255, 255, 255, 0.8)',
    white_90: 'rgba(255, 255, 255, 0.9)',
    black_10: 'rgba(0, 0, 0, 0.1)',
    black_20: 'rgba(0, 0, 0, 0.2)',
    black_30: 'rgba(0, 0, 0, 0.3)',
    black_50: 'rgba(0, 0, 0, 0.5)',
    black_60: 'rgba(0, 0, 0, 0.6)',
    black_70: 'rgba(0, 0, 0, 0.7)',
    black_80: 'rgba(0, 0, 0, 0.8)',
    black: 'rgba(0, 0, 0, 1)',
    gold: 'gold',
    silver: 'silver',
    bronze: '#d58c03',
    red: '#e22222',
    pink: '#E24260',
    magenta: '#de2795',
    purple: '#9166fb',
    lightblue: '#E5F2F6',
    darkblue: '#38556F',
    brightgreen: '#2AD55C',
  },
  localWeb: true && __DEV__ && !Device.isDevice,
  font: {
    gothic: 'Alternate Gothic No3 D',
    gothic1: 'Alternate Gothic No1 D', // not in use
    circular: 'CircularStd-Book',
    circularBold: 'CircularStd-Bold',
    Jakarta: 'Jakarta',
    JakartaBold: 'JakartaBold',
  },
  style: {
    navigationHeader: {
      backgroundColor: 'rgba(255, 255, 255, 1)', //white
      borderBottomWidth: 0,
      elevation: 3,
      shadowColor: 'rgba(167, 168, 170, 1)', //gray2
      shadowRadius: 2,
      shadowOpacity: 0.3,
      // shadowOffset: {
      //   height: 3,
      // },
    },
    center: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center',
    },
    noShadowNavigationHeader: {
      backgroundColor: 'rgba(255, 255, 255, 1)', //white
      borderBottomWidth: 0,
      elevation: 0,
      shadowColor: 'transparent',
      shadowRadius: 0,
      shadowOpacity: 0,
      shadowOffset: {
        height: 0,
      },
    },
    boxShadow: {
      backgroundColor: 'rgba(255, 255, 255, 1)', //white
      elevation: 3,
      shadowColor: 'rgba(80, 87, 89, 1)', //gray3
      shadowRadius: 4,
      shadowOpacity: 0.3,
    },
    fillAbsolute: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    } as ImageStyle,
  },
  customMapStyle: [
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
  checkoutTimeLimit: 10,
  handleNext: () => console.log('handleNext'),
  localDataRefetch: () => console.log('localDataRefetch'),
};

export type TConstants = typeof constants;

export default constants;

import constants from 'src/constants';
import React, {useEffect, useRef, useState} from 'react';
import {TouchableOpacity, View, Text} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {MaskedTextInput} from 'react-native-mask-text';
import ModalSelector from 'react-native-modal-selector';

type Country = {
  name: string;
  code: string;
  flag: string;
  dialCode: string;
  mask: string;
};

const countries: Country[] = [
  {
    name: 'United States',
    code: 'US',
    flag: '🇺🇸',
    dialCode: '+1',
    mask: '(999) 999-9999',
  },
  {
    name: 'Canada',
    code: 'CA',
    flag: '🇨🇦',
    dialCode: '+1',
    mask: '(999) 999-9999',
  },
  {
    name: 'Afghanistan',
    code: 'AF',
    flag: '🇦🇫',
    dialCode: '+93',
    mask: '999 999 9999',
  },
  {
    name: 'Åland Islands',
    code: 'AX',
    flag: '🇦🇽',
    dialCode: '+358',
    mask: '999 9999999',
  },
  {
    name: 'Albania',
    code: 'AL',
    flag: '🇦🇱',
    dialCode: '+355',
    mask: '999 999 9999',
  },
  {
    name: 'Algeria',
    code: 'DZ',
    flag: '🇩🇿',
    dialCode: '+213',
    mask: '9999 99 99 99',
  },
  {
    name: 'American Samoa',
    code: 'AS',
    flag: '🇦🇸',
    dialCode: '+1684',
    mask: '(999) 999-9999',
  },
  {
    name: 'Andorra',
    code: 'AD',
    flag: '🇦🇩',
    dialCode: '+376',
    mask: '999 999',
  },
  {
    name: 'Angola',
    code: 'AO',
    flag: '🇦🇴',
    dialCode: '+244',
    mask: '999 999 999',
  },
  {
    name: 'Anguilla',
    code: 'AI',
    flag: '🇦🇮',
    dialCode: '+1264',
    mask: '(999) 999-9999',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    flag: '🇦🇬',
    dialCode: '+1268',
    mask: '(999) 999-9999',
  },
  {
    name: 'Argentina',
    code: 'AR',
    flag: '🇦🇷',
    dialCode: '+54',
    mask: '999 99-9999-9999',
  },
  {
    name: 'Armenia',
    code: 'AM',
    flag: '🇦🇲',
    dialCode: '+374',
    mask: '999 999999',
  },
  {
    name: 'Aruba',
    code: 'AW',
    flag: '🇦🇼',
    dialCode: '+297',
    mask: '999 9999',
  },
  {
    name: 'Australia',
    code: 'AU',
    flag: '🇦🇺',
    dialCode: '+61',
    mask: '9999 999 999',
  },
  {
    name: 'Austria',
    code: 'AT',
    flag: '🇦🇹',
    dialCode: '+43',
    mask: '9999 999999',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    flag: '🇦🇿',
    dialCode: '+994',
    mask: '999 999 99 99',
  },
  {
    name: 'Bahamas',
    code: 'BS',
    flag: '🇧🇸',
    dialCode: '+1242',
    mask: '(999) 999-999',
  },
  {
    name: 'Bahrain',
    code: 'BH',
    flag: '🇧🇭',
    dialCode: '+973',
    mask: '9999 9999',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    flag: '🇧🇩',
    dialCode: '+880',
    mask: '99999-999999',
  },
  {
    name: 'Barbados',
    code: 'BB',
    flag: '🇧🇧',
    dialCode: '+1246',
    mask: '(999) 999-9999',
  },
  {
    name: 'Belarus',
    code: 'BY',
    flag: '🇧🇾',
    dialCode: '+375',
    mask: '9 999 999-99-99',
  },
  {
    name: 'Belgium',
    code: 'BE',
    flag: '🇧🇪',
    dialCode: '+32',
    mask: '9999 99 99 99',
  },
  {
    name: 'Belize',
    code: 'BZ',
    flag: '🇧🇿',
    dialCode: '+501',
    mask: '999-9999',
  },
  {
    name: 'Benin',
    code: 'BJ',
    flag: '🇧🇯',
    dialCode: '+229',
    mask: '99 99 99 99',
  },
  {
    name: 'Bermuda',
    code: 'BM',
    flag: '🇧🇲',
    dialCode: '+1441',
    mask: '(999) 999-9999',
  },
  {
    name: 'Bhutan',
    code: 'BT',
    flag: '🇧🇹',
    dialCode: '+975',
    mask: '99 99 99 99',
  },
  {
    name: 'Bolivia, Plurinational State of bolivia',
    code: 'BO',
    flag: '🇧🇴',
    dialCode: '+591',
    mask: '99999999',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    flag: '🇧🇦',
    dialCode: '+387',
    mask: '999 999 999',
  },
  {
    name: 'Botswana',
    code: 'BW',
    flag: '🇧🇼',
    dialCode: '+267',
    mask: '99 999 999',
  },
  {
    name: 'Bouvet Island',
    code: 'BV',
    flag: '🇧🇻',
    dialCode: '+47',
    mask: '(99) 99999-9999',
  },
  {
    name: 'Brazil',
    code: 'BR',
    flag: '🇧🇷',
    dialCode: '+55',
    mask: '(99) 99999 9999',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    flag: '🇮🇴',
    dialCode: '+246',
    mask: '(999) 999-9999',
  },
  {
    name: 'Brunei Darussalam',
    code: 'BN',
    flag: '🇧🇳',
    dialCode: '+673',
    mask: '999 9999',
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    flag: '🇧🇬',
    dialCode: '+359',
    mask: '999 999 999',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    flag: '🇧🇫',
    dialCode: '+226',
    mask: '99 99 99 99',
  },
  {
    name: 'Burundi',
    code: 'BI',
    flag: '🇧🇮',
    dialCode: '+257',
    mask: '99 99 99 99',
  },
  {
    name: 'Cambodia',
    code: 'KH',
    flag: '🇰🇭',
    dialCode: '+855',
    mask: '999 999 999',
  },
  {
    name: 'Cameroon',
    code: 'CM',
    flag: '🇨🇲',
    dialCode: '+237',
    mask: '9 99 99 99 99',
  },
  {
    name: 'Canada',
    code: 'CA',
    flag: '🇨🇦',
    dialCode: '+1',
    mask: '(999) 999-9999',
  },
  {
    name: 'Cape Verde',
    code: 'CV',
    flag: '🇨🇻',
    dialCode: '+238',
    mask: '999 99 99',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    flag: '🇰🇾',
    dialCode: '+345',
    mask: '999 9999',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    flag: '🇨🇫',
    dialCode: '+236',
    mask: '99 99 99 99',
  },
  {
    name: 'Chad',
    code: 'TD',
    flag: '🇹🇩',
    dialCode: '+235',
    mask: '99 99 99 99',
  },
  {
    name: 'Chile',
    code: 'CL',
    flag: '🇨🇱',
    dialCode: '+56',
    mask: '(9) 9999 9999',
  },
  {
    name: 'China',
    code: 'CN',
    flag: '🇨🇳',
    dialCode: '+86',
    mask: '999 9999 9999',
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    flag: '🇨🇽',
    dialCode: '+61',
    mask: '9999 999 999',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    flag: '🇨🇨',
    dialCode: '+61',
    mask: '9999 999 999',
  },
  {
    name: 'Colombia',
    code: 'CO',
    flag: '🇨🇴',
    dialCode: '+57',
    mask: '999 9999999',
  },
  {
    name: 'Comoros',
    code: 'KM',
    flag: '🇰🇲',
    dialCode: '+269',
    mask: '999 99 99',
  },
  {
    name: 'Congo',
    code: 'CG',
    flag: '🇨🇬',
    dialCode: '+242',
    mask: '9999 999 999',
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    code: 'CD',
    flag: '🇨🇩',
    dialCode: '+243',
    mask: '99 999 9999',
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    flag: '🇨🇰',
    dialCode: '+682',
    mask: '99 999',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    flag: '🇨🇷',
    dialCode: '+506',
    mask: '9999 9999',
  },
  {
    name: "Cote d'Ivoire",
    code: 'CI',
    flag: '🇨🇮',
    dialCode: '+225',
    mask: '99 99 99 99',
  },
  {
    name: 'Croatia',
    code: 'HR',
    flag: '🇭🇷',
    dialCode: '+385',
    mask: '999 999 9999',
  },
  {
    name: 'Cuba',
    code: 'CU',
    flag: '🇨🇺',
    dialCode: '+53',
    mask: '99 9999999',
  },
  {
    name: 'Cyprus',
    code: 'CY',
    flag: '🇨🇾',
    dialCode: '+357',
    mask: '99 999999',
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
    flag: '🇨🇿',
    dialCode: '+420',
    mask: '999 999 999',
  },
  {
    name: 'Denmark',
    code: 'DK',
    flag: '🇩🇰',
    dialCode: '+45',
    mask: '99 99 99 99',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    flag: '🇩🇯',
    dialCode: '+253',
    mask: '99 99 99 99',
  },
  {
    name: 'Dominica',
    code: 'DM',
    flag: '🇩🇲',
    dialCode: '+1767',
    mask: '(999) 999-9999',
  },
  {
    name: 'Ecuador',
    code: 'EC',
    flag: '🇪🇨',
    dialCode: '+593',
    mask: '999 999 9999',
  },
  {
    name: 'Egypt',
    code: 'EG',
    flag: '🇪🇬',
    dialCode: '+20',
    mask: '9999 999 9999',
  },
  {
    name: 'El Salvador',
    code: 'SV',
    flag: '🇸🇻',
    dialCode: '+503',
    mask: '9999 9999',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    flag: '🇬🇶',
    dialCode: '+240',
    mask: '999 999 999',
  },
  {
    name: 'Eritrea',
    code: 'ER',
    flag: '🇪🇷',
    dialCode: '+291',
    mask: '99 999 999',
  },
  {
    name: 'Estonia',
    code: 'EE',
    flag: '🇪🇪',
    dialCode: '+372',
    mask: '9999 9999',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    flag: '🇪🇹',
    dialCode: '+251',
    mask: '999 999 9999',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    code: 'FK',
    flag: '🇫🇰',
    dialCode: '+500',
    mask: '99999',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    flag: '🇫🇴',
    dialCode: '+298',
    mask: '999999',
  },
  {
    name: 'Fiji',
    code: 'FJ',
    flag: '🇫🇯',
    dialCode: '+679',
    mask: '999 9999',
  },
  {
    name: 'Finland',
    code: 'FI',
    flag: '🇫🇮',
    dialCode: '+358',
    mask: '999 9999999',
  },
  {
    name: 'France',
    code: 'FR',
    flag: '🇫🇷',
    dialCode: '+33',
    mask: '99 99 99 99 99',
  },
  {
    name: 'French Guiana',
    code: 'GF',
    flag: '🇬🇫',
    dialCode: '+594',
    mask: '9999 99 99 99',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    flag: '🇵🇫',
    dialCode: '+689',
    mask: '99 99 99 99',
  },
  {
    name: 'Gabon',
    code: 'GA',
    flag: '🇬🇦',
    dialCode: '+241',
    mask: '99 99 99 99',
  },
  {
    name: 'Gambia',
    code: 'GM',
    flag: '🇬🇲',
    dialCode: '+220',
    mask: '999 9999',
  },
  {
    name: 'Georgia',
    code: 'GE',
    flag: '🇬🇪',
    dialCode: '+995',
    mask: '999 99 99 99',
  },
  {
    name: 'Germany',
    code: 'DE',
    flag: '🇩🇪',
    dialCode: '+49',
    mask: '99999 9999999',
  },
  {
    name: 'Ghana',
    code: 'GH',
    flag: '🇬🇭',
    dialCode: '+233',
    mask: '999 999 9999',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    flag: '🇬🇮',
    dialCode: '+350',
    mask: '99999999',
  },
  {
    name: 'Greece',
    code: 'GR',
    flag: '🇬🇷',
    dialCode: '+30',
    mask: '999 999 9999',
  },
  {
    name: 'Greenland',
    code: 'GL',
    flag: '🇬🇱',
    dialCode: '+299',
    mask: '99 99 99',
  },
  {
    name: 'Grenada',
    code: 'GD',
    flag: '🇬🇩',
    dialCode: '+1473',
    mask: '(999) 999-9999',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    flag: '🇬🇵',
    dialCode: '+590',
    mask: '9999 99 99 99',
  },
  {
    name: 'Guam',
    code: 'GU',
    flag: '🇬🇺',
    dialCode: '+1671',
    mask: '(999) 999-9999',
  },
  {
    name: 'Guatemala',
    code: 'GT',
    flag: '🇬🇹',
    dialCode: '+502',
    mask: '9999 9999',
  },
  {
    name: 'Guernsey',
    code: 'GG',
    flag: '🇬🇬',
    dialCode: '+44',
    mask: '99999 999999',
  },
  {
    name: 'Guinea',
    code: 'GN',
    flag: '🇬🇳',
    dialCode: '+224',
    mask: '999 99 99 99',
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    flag: '🇬🇼',
    dialCode: '+245',
    mask: '999 999 999',
  },
  {
    name: 'Guyana',
    code: 'GY',
    flag: '🇬🇾',
    dialCode: '+592',
    mask: '999 9999',
  },
  {
    name: 'Haiti',
    code: 'HT',
    flag: '🇭🇹',
    dialCode: '+509',
    mask: '99 99 9999',
  },
  {
    name: 'Honduras',
    code: 'HN',
    flag: '🇭🇳',
    dialCode: '+504',
    mask: '9999-9999',
  },
  {
    name: 'Hong Kong',
    code: 'HK',
    flag: '🇭🇰',
    dialCode: '+852',
    mask: '9999 9999',
  },
  {
    name: 'Hungary',
    code: 'HU',
    flag: '🇭🇺',
    dialCode: '+36',
    mask: '(99) 999 9999',
  },
  {
    name: 'Iceland',
    code: 'IS',
    flag: '🇮🇸',
    dialCode: '+354',
    mask: '999 9999',
  },
  {
    name: 'India',
    code: 'IN',
    flag: '🇮🇳',
    dialCode: '+91',
    mask: '99999 99999',
  },
  {
    name: 'Indonesia',
    code: 'ID',
    flag: '🇮🇩',
    dialCode: '+62',
    mask: '9999-999-999',
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    code: 'IR',
    flag: '🇮🇷',
    dialCode: '+98',
    mask: '9999 999 9999',
  },
  {
    name: 'Iraq',
    code: 'IQ',
    flag: '🇮🇶',
    dialCode: '+964',
    mask: '9999 999 9999',
  },
  {
    name: 'Ireland',
    code: 'IE',
    flag: '🇮🇪',
    dialCode: '+353',
    mask: '999 999 9999',
  },
  {
    name: 'Isle of Man',
    code: 'IM',
    flag: '🇮🇲',
    dialCode: '+44',
    mask: '99999 999999',
  },
  {
    name: 'Israel',
    code: 'IL',
    flag: '🇮🇱',
    dialCode: '+972',
    mask: '999-999-9999',
  },
  {
    name: 'Italy',
    code: 'IT',
    flag: '🇮🇹',
    dialCode: '+39',
    mask: '999 999 9999',
  },
  {
    name: 'Jamaica',
    code: 'JM',
    flag: '🇯🇲',
    dialCode: '+1876',
    mask: '(999) 999-9999',
  },
  {
    name: 'Japan',
    code: 'JP',
    flag: '🇯🇵',
    dialCode: '+81',
    mask: '999-9999-9999',
  },
  {
    name: 'Jersey',
    code: 'JE',
    flag: '🇯🇪',
    dialCode: '+44',
    mask: '99999 999999',
  },
  {
    name: 'Jordan',
    code: 'JO',
    flag: '🇯🇴',
    dialCode: '+962',
    mask: '99 9999 9999',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    flag: '🇰🇿',
    dialCode: '+7',
    mask: '9 (999) 999 9999',
  },
  {
    name: 'Kenya',
    code: 'KE',
    flag: '🇰🇪',
    dialCode: '+254',
    mask: '999 999999',
  },
  {
    name: 'Kiribati',
    code: 'KI',
    flag: '🇰🇮',
    dialCode: '+686',
    mask: '99999999',
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    code: 'KP',
    flag: '🇰🇵',
    dialCode: '+850',
    mask: '(9) 99999',
  },
  {
    name: 'Korea, Republic of South Korea',
    code: 'KR',
    flag: '🇰🇷',
    dialCode: '+82',
    mask: '999-9999-9999',
  },
  {
    name: 'Kosovo',
    code: 'XK',
    flag: '🇽🇰',
    dialCode: '+383',
    mask: '999 999 999',
  },
  {
    name: 'Kuwait',
    code: 'KW',
    flag: '🇰🇼',
    dialCode: '+965',
    mask: '999 99999',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    flag: '🇰🇬',
    dialCode: '+996',
    mask: '9999 999 999',
  },
  {
    name: 'Laos',
    code: 'LA',
    flag: '🇱🇦',
    dialCode: '+856',
    mask: '999 99 999 999',
  },
  {
    name: 'Latvia',
    code: 'LV',
    flag: '🇱🇻',
    dialCode: '+371',
    mask: '99 999 999',
  },
  {
    name: 'Lebanon',
    code: 'LB',
    flag: '🇱🇧',
    dialCode: '+961',
    mask: '99 999 999',
  },
  {
    name: 'Lesotho',
    code: 'LS',
    flag: '🇱🇸',
    dialCode: '+266',
    mask: '9999 9999',
  },
  {
    name: 'Liberia',
    code: 'LR',
    flag: '🇱🇷',
    dialCode: '+231',
    mask: '999 999 9999',
  },
  {
    name: 'Libyan Arab Jamahiriya',
    code: 'LY',
    flag: '🇱🇾',
    dialCode: '+218',
    mask: '999-9999999',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    flag: '🇱🇮',
    dialCode: '+423',
    mask: '999 999 999',
  },
  {
    name: 'Lithuania',
    code: 'LT',
    flag: '🇱🇹',
    dialCode: '+370',
    mask: '(9-999) 9999',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    flag: '🇱🇺',
    dialCode: '+352',
    mask: '999 999 999',
  },
  {
    name: 'Macao',
    code: 'MO',
    flag: '🇲🇴',
    dialCode: '+853',
    mask: '9999 9999',
  },
  {
    name: 'Macedonia',
    code: 'MK',
    flag: '🇲🇰',
    dialCode: '+389',
    mask: '999 999 999',
  },
  {
    name: 'Madagascar',
    code: 'MG',
    flag: '🇲🇬',
    dialCode: '+261',
    mask: '999 99 999 99',
  },
  {
    name: 'Malawi',
    code: 'MW',
    flag: '🇲🇼',
    dialCode: '+265',
    mask: '9999 99 99 99',
  },
  {
    name: 'Malaysia',
    code: 'MY',
    flag: '🇲🇾',
    dialCode: '+60',
    mask: '999-999 9999',
  },
  {
    name: 'Maldives',
    code: 'MV',
    flag: '🇲🇻',
    dialCode: '+960',
    mask: '999-9999',
  },
  {
    name: 'Mali',
    code: 'ML',
    flag: '🇲🇱',
    dialCode: '+223',
    mask: '99 99 99 99',
  },
  {
    name: 'Malta',
    code: 'MT',
    flag: '🇲🇹',
    dialCode: '+356',
    mask: '9999 9999',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    flag: '🇲🇭',
    dialCode: '+692',
    mask: '999-9999',
  },
  {
    name: 'Martinique',
    code: 'MQ',
    flag: '🇲🇶',
    dialCode: '+596',
    mask: '9999 99 99 99',
  },
  {
    name: 'Mauritania',
    code: 'MR',
    flag: '🇲🇷',
    dialCode: '+222',
    mask: '99 99 99 99',
  },
  {
    name: 'Mauritius',
    code: 'MU',
    flag: '🇲🇺',
    dialCode: '+230',
    mask: '9999 9999',
  },
  {
    name: 'Mayotte',
    code: 'YT',
    flag: '🇾🇹',
    dialCode: '+262',
    mask: '9999 99 99 99',
  },
  {
    name: 'Mexico',
    code: 'MX',
    flag: '🇲🇽',
    dialCode: '+52',
    mask: '999 999 999 9999',
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    code: 'FM',
    flag: '🇫🇲',
    dialCode: '+691',
    mask: '999 9999',
  },
  {
    name: 'Moldova',
    code: 'MD',
    flag: '🇲🇩',
    dialCode: '+373',
    mask: '9999 99 999',
  },
  {
    name: 'Monaco',
    code: 'MC',
    flag: '🇲🇨',
    dialCode: '+377',
    mask: '99 99 99 99 99',
  },
  {
    name: 'Mongolia',
    code: 'MN',
    flag: '🇲🇳',
    dialCode: '+976',
    mask: '9999 9999',
  },
  {
    name: 'Montenegro',
    code: 'ME',
    flag: '🇲🇪',
    dialCode: '+382',
    mask: '999 999 999',
  },
  {
    name: 'Montserrat',
    code: 'MS',
    flag: '🇲🇸',
    dialCode: '+1664',
    mask: '(999) 999-9999',
  },
  {
    name: 'Morocco',
    code: 'MA',
    flag: '🇲🇦',
    dialCode: '+212',
    mask: '9999-999999',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    flag: '🇲🇿',
    dialCode: '+258',
    mask: '99 999 9999',
  },
  {
    name: 'Myanmar',
    code: 'MM',
    flag: '🇲🇲',
    dialCode: '+95',
    mask: '99 999 9999',
  },
  {
    name: 'Namibia',
    code: 'NA',
    flag: '🇳🇦',
    dialCode: '+264',
    mask: '999 999 99999',
  },
  {
    name: 'Nauru',
    code: 'NR',
    flag: '🇳🇷',
    dialCode: '+674',
    mask: '999 9999',
  },
  {
    name: 'Nepal',
    code: 'NP',
    flag: '🇳🇵',
    dialCode: '+977',
    mask: '999-9999999',
  },
  {
    name: 'Netherlands',
    code: 'NL',
    flag: '🇳🇱',
    dialCode: '+31',
    mask: '9999999999',
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    flag: '🇳🇨',
    dialCode: '+687',
    mask: '99.99.99',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    flag: '🇳🇿',
    dialCode: '+64',
    mask: '999 999 9999',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    flag: '🇳🇮',
    dialCode: '+505',
    mask: '9999 9999',
  },
  {
    name: 'Niger',
    code: 'NE',
    flag: '🇳🇪',
    dialCode: '+227',
    mask: '99 99 99 99',
  },
  {
    name: 'Nigeria',
    code: 'NG',
    flag: '🇳🇬',
    dialCode: '+234',
    mask: '9999 999 9999',
  },
  {
    name: 'Niue',
    code: 'NU',
    flag: '🇳🇺',
    dialCode: '+683',
    mask: '999 9999',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    flag: '🇳🇫',
    dialCode: '+672',
    mask: '9 99999',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    flag: '🇲🇵',
    dialCode: '+1670',
    mask: '(999) 999-9999',
  },
  {
    name: 'Norway',
    code: 'NO',
    flag: '🇳🇴',
    dialCode: '+47',
    mask: '999 99 999',
  },
  {
    name: 'Oman',
    code: 'OM',
    flag: '🇴🇲',
    dialCode: '+968',
    mask: '9999 9999',
  },
  {
    name: 'Pakistan',
    code: 'PK',
    flag: '🇵🇰',
    dialCode: '+92',
    mask: '9999 9999999',
  },
  {
    name: 'Palau',
    code: 'PW',
    flag: '🇵🇼',
    dialCode: '+680',
    mask: '999 9999',
  },
  {
    name: 'Palestinian Territory, Occupied',
    code: 'PS',
    flag: '🇵🇸',
    dialCode: '+970',
    mask: '9999 999 999',
  },
  {
    name: 'Panama',
    code: 'PA',
    flag: '🇵🇦',
    dialCode: '+507',
    mask: '9999-9999',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    flag: '🇵🇬',
    dialCode: '+675',
    mask: '9999 9999',
  },
  {
    name: 'Paraguay',
    code: 'PY',
    flag: '🇵🇾',
    dialCode: '+595',
    mask: '9999 999999',
  },
  {
    name: 'Peru',
    code: 'PE',
    flag: '🇵🇪',
    dialCode: '+51',
    mask: '999 999 999',
  },
  {
    name: 'Philippines',
    code: 'PH',
    flag: '🇵🇭',
    dialCode: '+63',
    mask: '9999 999 9999',
  },
  {
    name: 'Poland',
    code: 'PL',
    flag: '🇵🇱',
    dialCode: '+48',
    mask: '999 999 999',
  },
  {
    name: 'Portugal',
    code: 'PT',
    flag: '🇵🇹',
    dialCode: '+351',
    mask: '999 999 999',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    flag: '🇵🇷',
    dialCode: '+1939',
    mask: '(999) 999-9999',
  },
  {
    name: 'Qatar',
    code: 'QA',
    flag: '🇶🇦',
    dialCode: '+974',
    mask: '9999 9999',
  },
  {
    name: 'Romania',
    code: 'RO',
    flag: '🇷🇴',
    dialCode: '+40',
    mask: '9999 999 999',
  },
  {
    name: 'Russia',
    code: 'RU',
    flag: '🇷🇺',
    dialCode: '+7',
    mask: '9 (999) 999-99-99',
  },
  {
    name: 'Rwanda',
    code: 'RW',
    flag: '🇷🇼',
    dialCode: '+250',
    mask: '9999 999 999',
  },
  {
    name: 'Saint Barthelemy',
    code: 'BL',
    flag: '🇧🇱',
    dialCode: '+590',
    mask: '9999 99 99 99',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    code: 'SH',
    flag: '🇸🇭',
    dialCode: '+290',
    mask: '99999',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    flag: '🇰🇳',
    dialCode: '+1869',
    mask: '(999) 999-9999',
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    flag: '🇱🇨',
    dialCode: '+1758',
    mask: '(999) 999-9999',
  },
  {
    name: 'Saint Martin',
    code: 'MF',
    flag: '🇲🇫',
    dialCode: '+590',
    mask: '9999 99 99 99',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    flag: '🇵🇲',
    dialCode: '+508',
    mask: '999 99 99',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    flag: '🇻🇨',
    dialCode: '+1784',
    mask: '(999) 999-9999',
  },
  {
    name: 'Samoa',
    code: 'WS',
    flag: '🇼🇸',
    dialCode: '+685',
    mask: '99 99999',
  },
  {
    name: 'San Marino',
    code: 'SM',
    flag: '🇸🇲',
    dialCode: '+378',
    mask: '99 99 99 99',
  },
  {
    name: 'Sao Tome and Principe',
    code: 'ST',
    flag: '🇸🇹',
    dialCode: '+239',
    mask: '999 9999',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    flag: '🇸🇦',
    dialCode: '+966',
    mask: '999 999 9999',
  },
  {
    name: 'Senegal',
    code: 'SN',
    flag: '🇸🇳',
    dialCode: '+221',
    mask: '99 999 99 99',
  },
  {
    name: 'Serbia',
    code: 'RS',
    flag: '🇷🇸',
    dialCode: '+381',
    mask: '999 9999999',
  },
  {
    name: 'Seychelles',
    code: 'SC',
    flag: '🇸🇨',
    dialCode: '+248',
    mask: '9 999 999',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    flag: '🇸🇱',
    dialCode: '+232',
    mask: '(999) 999999',
  },
  {
    name: 'Singapore',
    code: 'SG',
    flag: '🇸🇬',
    dialCode: '+65',
    mask: '9999 9999',
  },
  {
    name: 'Slovakia',
    code: 'SK',
    flag: '🇸🇰',
    dialCode: '+421',
    mask: '9999 999 999',
  },
  {
    name: 'Slovenia',
    code: 'SI',
    flag: '🇸🇮',
    dialCode: '+386',
    mask: '999 999 999',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    flag: '🇸🇧',
    dialCode: '+677',
    mask: '99 99999',
  },
  {
    name: 'Somalia',
    code: 'SO',
    flag: '🇸🇴',
    dialCode: '+252',
    mask: '9 9999999',
  },
  {
    name: 'South Africa',
    code: 'ZA',
    flag: '🇿🇦',
    dialCode: '+27',
    mask: '999 999 9999',
  },
  {
    name: 'South Sudan',
    code: 'SS',
    flag: '🇸🇸',
    dialCode: '+211',
    mask: '9999 999 999',
  },
  {
    name: 'Spain',
    code: 'ES',
    flag: '🇪🇸',
    dialCode: '+34',
    mask: '999 99 99 99',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    flag: '🇱🇰',
    dialCode: '+94',
    mask: '999 999 9999',
  },
  {
    name: 'Sudan',
    code: 'SD',
    flag: '🇸🇩',
    dialCode: '+249',
    mask: '999 999 9999',
  },
  {
    name: 'Suriname',
    code: 'SR',
    flag: '🇸🇷',
    dialCode: '+597',
    mask: '999-9999',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
    flag: '🇸🇯',
    dialCode: '+47',
    mask: '999 99 999',
  },
  {
    name: 'Swaziland',
    code: 'SZ',
    flag: '🇸🇿',
    dialCode: '+268',
    mask: '9999 9999',
  },
  {
    name: 'Sweden',
    code: 'SE',
    flag: '🇸🇪',
    dialCode: '+46',
    mask: '999-999 99 99',
  },
  {
    name: 'Switzerland',
    code: 'CH',
    flag: '🇨🇭',
    dialCode: '+41',
    mask: '999 999 99 99',
  },
  {
    name: 'Syrian Arab Republic',
    code: 'SY',
    flag: '🇸🇾',
    dialCode: '+963',
    mask: '9999 999 999',
  },
  {
    name: 'Taiwan',
    code: 'TW',
    flag: '🇹🇼',
    dialCode: '+886',
    mask: '9999 999 999',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    flag: '🇹🇯',
    dialCode: '+992',
    mask: '999 99 9999',
  },
  {
    name: 'Tanzania',
    code: 'TZ',
    flag: '🇹🇿',
    dialCode: '+255',
    mask: '9999 999 999',
  },
  {
    name: 'Thailand',
    code: 'TH',
    flag: '🇹🇭',
    dialCode: '+66',
    mask: '999 999 9999',
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
    flag: '🇹🇱',
    dialCode: '+670',
    mask: '9999 9999',
  },
  {
    name: 'Togo',
    code: 'TG',
    flag: '🇹🇬',
    dialCode: '+228',
    mask: '99 99 99 99',
  },
  {
    name: 'Tokelau',
    code: 'TK',
    flag: '🇹🇰',
    dialCode: '+690',
    mask: '9999',
  },
  {
    name: 'Tonga',
    code: 'TO',
    flag: '🇹🇴',
    dialCode: '+676',
    mask: '999 9999',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    flag: '🇹🇹',
    dialCode: '+1868',
    mask: '(999) 999-9999',
  },
  {
    name: 'Tunisia',
    code: 'TN',
    flag: '🇹🇳',
    dialCode: '+216',
    mask: '99 999 999',
  },
  {
    name: 'Turkey',
    code: 'TR',
    flag: '🇹🇷',
    dialCode: '+90',
    mask: '(999) 999 9999',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    flag: '🇹🇲',
    dialCode: '+993',
    mask: '9 99 999999',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    flag: '🇹🇨',
    dialCode: '+1649',
    mask: '(999) 999-9999',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    flag: '🇹🇻',
    dialCode: '+688',
    mask: '999999',
  },
  {
    name: 'Uganda',
    code: 'UG',
    flag: '🇺🇬',
    dialCode: '+256',
    mask: '9999 999999',
  },
  {
    name: 'Ukraine',
    code: 'UA',
    flag: '🇺🇦',
    dialCode: '+380',
    mask: '999 999 9999',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    flag: '🇦🇪',
    dialCode: '+971',
    mask: '999 999 9999',
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    flag: '🇬🇧',
    dialCode: '+44',
    mask: '99999 999999',
  },
  {
    name: 'United States',
    code: 'US',
    flag: '🇺🇸',
    dialCode: '+1',
    mask: '(999) 999-9999',
  },
  {
    name: 'Uruguay',
    code: 'UY',
    flag: '🇺🇾',
    dialCode: '+598',
    mask: '999 999 999',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    flag: '🇺🇿',
    dialCode: '+998',
    mask: '9 99 999 99 99',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    flag: '🇻🇺',
    dialCode: '+678',
    mask: '999 9999',
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    code: 'VE',
    flag: '🇻🇪',
    dialCode: '+58',
    mask: '9999-9999999',
  },
  {
    name: 'Vietnam',
    code: 'VN',
    flag: '🇻🇳',
    dialCode: '+84',
    mask: '999 999 99 99',
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
    flag: '🇼🇫',
    dialCode: '+681',
    mask: '99 99 99',
  },
  {
    name: 'Yemen',
    code: 'YE',
    flag: '🇾🇪',
    dialCode: '+967',
    mask: '999 999 999',
  },
  {
    name: 'Zambia',
    code: 'ZM',
    flag: '🇿🇲',
    dialCode: '+260',
    mask: '999 9999999',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    flag: '🇿🇼',
    dialCode: '+263',
    mask: '999 999 9999',
  },
];
const PhoneInput: React.FC<{onValueChange?: (_value: string) => void}> = ({
  onValueChange = () => null,
}) => {
  const {width} = useWindowDimensionsSafe();
  const [country, setCountry] = useState<string>('US');
  const modalRef = useRef<ModalSelector>();
  const data: {key: string; label: string}[] = [];

  countries.forEach((country) => {
    data.push({
      key: country.code,
      label: `${country.flag} ${country.name} (${country.dialCode})`,
    });
  });

  const [value, setValue] = useState<string>('');

  const selectedCounty =
    countries.find((item) => item.code === country) || countries[0];
  // console.log({selectedCounty, country});

  const mask: (RegExp | string)[] = [];
  selectedCounty?.mask?.split?.('').forEach((letter) => {
    mask.push(letter === '9' ? /\d/ : letter);
  });

  useEffect(() => {
    const formattedPhoneNumber = selectedCounty.dialCode + value;
    // console.log({formattedPhoneNumber});
    onValueChange(formattedPhoneNumber);
  }, [country, value]);

  // console.log({value});

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: constants.color.white,
        borderRadius: 10,
        paddingHorizontal: 10,
        width: width - 20,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {width: 0, height: 1},
        shadowOpacity: 0.2,
        shadowRadius: 3,
      }}>
      <TouchableOpacity
        style={{width: 70}}
        onPress={() => {
          modalRef.current?.open();
        }}>
        <Text>{`${selectedCounty.flag} (${selectedCounty.dialCode})`}</Text>
      </TouchableOpacity>
      <MaskedTextInput
        value={value}
        onChangeText={(masked, unmasked) => {
          // setPhone(masked); // you can use the unmasked value as well

          // assuming you typed "9" all the way:
          // console.log({masked}); // (99) 99999-9999
          // console.log({unmasked}); // 99999999999
          setValue(unmasked);
        }}
        testID={'phone'}
        style={{
          color: constants.color.gray3,
          fontFamily: constants.font.circular,
          height: 40,
          paddingHorizontal: 10,
          paddingVertical: 10,
          width: width - 100,
        }}
        placeholder={selectedCounty.mask}
        mask={selectedCounty.mask}
      />
      <ModalSelector
        // @ts-ignore
        ref={modalRef}
        data={data}
        onChange={({key}) => {
          console.log({key});
          setCountry(String(key));
        }}
        customSelector={<View />}
        cancelText={'Cancel'}
        overlayStyle={{
          justifyContent: 'flex-end',
          zIndex: 1000000,
          backgroundColor: 'white',
          paddingTop: 100,
        }}
      />
    </View>
  );
};

export default PhoneInput;

import React, {useState} from 'react';
import {
  TouchableOpacity,
  View,
  Image,
  ImageBackground,
  Text,
} from 'react-native';
import {BodyText, ContinueButton} from 'src/Modules/CommonView';
import SearchBar from 'src/Modules/CommonView/SearchBar';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {ScrollView} from 'react-native-gesture-handler';
import {navigationRef} from 'src/Nav/navigationHelpers';
import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';
import {dispatchAction} from 'src/Utils/helpers';
import {clearNewWanderList} from 'src/Redux/reducers/local.reducer';
import {orderHuntsByProximity} from 'src/Utils/distanceHelpers';
import {THunt} from 'src/types/THunt';

const paPhotoUrl = require('src/Images/user_test_image.png');
const like = require('src/Images/like_heart.png');
const plus_icon = require('src/Images/Icons/add_rectangle.png');

export default function Wanderlists() {
  const {width} = useWindowDimensionsSafe();
  const wanderlists = useTypedSelector((state) => state.local.wanderlists);

  const roundedLat = useTypedSelector(
    (state) => state.location.roundedRegion?.latitude,
  );
  const roundedLong = useTypedSelector(
    (state) => state.location.roundedRegion?.longitude,
  );

  let sortedWanderLists: THunt[] | null = [];

  if (wanderlists) {
    sortedWanderLists = orderHuntsByProximity(wanderlists, {
      latitude: roundedLat ?? 0,
      longitude: roundedLong ?? 0,
      latitudeDelta: 0.05,
      longitudeDelta: 0.05,
    });
  }

  const [searchText, setSearchText] = useState('');

  console.log({searchText});

  return (
    <View
      style={{
        flex: 1,
        marginTop: 22,
      }}>
      <BodyText
        text="WANDERLISTS NEAR YOU"
        textStyle={{
          color: constants.color.orange,
          fontFamily: 'JakartaBold',
          fontSize: 11,
          lineHeight: 11,
          marginTop: 0,
        }}
      />
      <BodyText
        text="Playlists for your life"
        textStyle={{
          color: constants.color.black,
          fontFamily: 'JakartaBold',
          fontSize: 28,
          lineHeight: 28,
          fontWeight: '600',
          marginTop: 18,
        }}
      />
      <BodyText
        text="Ever find yourself asking “What should we do today?” Wanderlists are the perfect answer. Discover member-curated itineraries in a city near you."
        textStyle={{
          color: constants.color.black,
          fontFamily: 'Jakarta',
          fontSize: 15,
          lineHeight: 21,
          marginTop: 12,
        }}
      />

      <ContinueButton
        text={'Submit your own Wanderlist'}
        height={60}
        iconImage={plus_icon}
        iconImageStyle={{height: 20, width: 20, marginRight: 8}}
        textStyle={{
          fontFamily: 'JakartaBold',
          fontSize: 15,
        }}
        containerStyle={{
          borderRadius: 6,
          marginTop: 12,
          marginBottom: 0,
          marginLeft: 10,
          marginRight: 10,
        }}
        handlePress={() => {
          dispatchAction(clearNewWanderList());
          navigationRef.navigate('WanderlistCreate', null, 'Wanderlists.tsx');
        }}
      />

      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <SearchBar
          value={searchText}
          containerStyle={{
            width: width - 80,
            backgroundColor: 'transparent',
            borderWidth: 0,
            borderBottomColor: 'transparent',
            borderTopColor: 'transparent',
            flex: 1,
            marginTop: 5,
          }}
          onChangeText={(_text: string) => {
            // set search
            console.log({_text});
            setSearchText(_text);
          }}
          onClear={() => {
            // clear search
            setSearchText('');
          }}
          placeholderColor="#898282"
          inputStyle={{
            height: 50,
            fontSize: 14,
            fontFamily: 'Jakarta',
            fontWeight: '800',
            backgroundColor: '#D9D9D9',
            borderRadius: 6,
            marginLeft: 10,
            color: '#898282',
            marginRight: 5,
            paddingLeft: 30,
          }}
          placeholder="Search Wanderlists near you"
        />
      </View>
      <ScrollView style={{paddingBottom: 50}}>
        {sortedWanderLists
          ?.filter?.(
            (a) =>
              !a.name?.toLowerCase?.()?.includes?.('vibes') &&
              !a.name?.toLowerCase?.()?.includes?.('test') &&
              !!a.lat,
          )
          ?.filter?.((a) => {
            const itemToSearch =
              (a.name?.toLowerCase?.() || '') +
              (a.description?.toLowerCase?.() || '') +
              (a.city?.toLowerCase?.() || '') +
              (a.state?.toLowerCase?.() || '') +
              (a.long_description?.toLowerCase?.() || '');
            return itemToSearch?.includes?.(searchText?.toLowerCase?.());
          })
          ?.map((wanderlist) => {
            const defaultPhotoURL =
              'https://photos.letsroam.com/scavenger_hunt_locations/';

            const wanderlistPhoto = wanderlist?.huntMediumPhotoURL?.includes(
              'firebase',
            )
              ? wanderlist.huntMediumPhotoURL
              : defaultPhotoURL + wanderlist?.huntMediumPhotoURL;

            console.log('wanderlistPhoto', wanderlistPhoto);

            if (!wanderlist.name || !wanderlist.description) {
              return <></>;
            }

            return (
              <WanderlistCard
                key={wanderlist.hunt_id}
                wrap={false}
                imgSource={wanderlistPhoto}
                title={wanderlist.name}
                description={wanderlist.description}
                favoriteCount={wanderlist.favorite_count}
                handlePress={() => {
                  navigationRef.navigate(
                    'WanderlistDetail',
                    {wanderlistId: wanderlist.hunt_id},
                    'Wanderlists.tsx',
                  );
                }}
              />
            );
          })}
        {/* hacky ScrollView fix */}
        <View style={{width: 200, height: 300}} />
      </ScrollView>
      <ModalOmniBar
        left={{
          icon: 'arrow-left-circle',
          handlePress: () =>
            navigationRef.navigate(
              'LandingPageMain',
              null,
              'LocalCreateLocationModal.tsx',
            ),
          text: 'Go back',
        }}
        containerStyle={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </View>
  );
}

interface WanderlistCardParams {
  imgSource: string;
  title: string;
  testID?: string;
  description: string;
  handlePress: Function;
  cardWidth?: number;
  imgHeight?: number;
  wrap: boolean;
  favoriteCount?: number | null;
}

const WanderlistCard = ({
  imgSource,
  title = '',
  testID,
  description = '',
  handlePress,
  cardWidth,
  imgHeight = 115,
  wrap = false,
  favoriteCount = 0,
}: WanderlistCardParams) => {
  const {width} = useWindowDimensionsSafe();
  console.log('WanderlistCard,' + title);

  return (
    <TouchableOpacity
      style={{
        backgroundColor: constants?.color?.white,
        width: wrap ? width - 20 : cardWidth,
        // minHeight: wrap ? undefined : cardHeight,
        marginVertical: 16,
        marginHorizontal: 10,
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: {width: 0, height: 1},
        shadowOpacity: 0.3,
        shadowRadius: 3,
      }}
      activeOpacity={0.8}
      onPress={() => handlePress()}
      testID={testID}
      disabled={!handlePress}>
      <Image
        source={{uri: imgSource}}
        style={{
          backgroundColor: constants?.color?.gray1,
          width: '100%',
          height: imgHeight,
          resizeMode: 'cover',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      />

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingHorizontal: 25,
          paddingTop: 20,
          paddingBottom: 20,
        }}>
        <View>
          {!!title && (
            <BodyText
              text={title}
              textStyle={{
                marginBottom: 0,
                marginTop: 0,
                color: '#000',
                fontSize: 14,
                fontFamily: 'JakartaBold',
                fontWeight: '700',
                marginHorizontal: 0,
              }}
            />
          )}

          {!!description && (
            <BodyText
              text={
                description?.length > 90
                  ? `${description.substring(0, 90)}...`
                  : description
              }
              textStyle={{
                marginTop: 8,
                fontSize: 12,
                fontFamily: 'Jakarta',
                color: '#000',
                marginBottom: 0,
                marginHorizontal: 0,
                maxWidth: 275,
              }}
            />
          )}
        </View>
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <ImageBackground
            source={like}
            resizeMode={'contain'}
            style={{
              width: 30,
              height: 30,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 0,
            }}>
            <Text
              style={{
                color: constants.color.black_30,
                fontFamily: 'JakartaBold',
                fontSize: 10,
              }}>
              {favoriteCount}
            </Text>
          </ImageBackground>
          <Image
            source={paPhotoUrl}
            style={{
              width: 30,
              height: 30,
              resizeMode: 'contain',
              marginTop: 8,
            }}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import 'react-native-get-random-values';

import {
  Alert,
  Text,
  TouchableWithoutFeedback,
  View,
  Platform,
  Image,
  TouchableOpacity,
  ImageBackground,
  TextInput,
} from 'react-native';

///////////////////////////
// components /////////////
import {BodyText, ContinueButton} from 'src/Modules/CommonView';
import React, {ReactNode, useEffect, useState} from 'react';
import {
  apiCheckNamedVoucher,
  apiTrackUserTransaction,
  apiUpdateUserBadges,
} from 'src/Utils/apiCalls';
import {joinHunt} from 'src/Utils/huntFunctions';
///////////////////////////
// utils //////////////////

import HomeNearbyGroupFinder from 'src/LandingPage/Home/HomeNearbyGroupFinder';

import {addVoucherShows} from 'src/Redux/reducers/ticket_vouchers.reducer';

import moment from 'moment';
import {setEventId, updateEventInfo} from 'src/Redux/reducers/event.reducer';
import {navigationRef} from 'src/Nav/navigationHelpers';
import useParams from 'src/Hooks/useParams';
import {getDataAtOrderBy, logCustomAnalyticsEvent} from 'src/Utils/fireUtils';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import {v4 as uuidv4} from 'uuid';
import {
  getDataAtPath,
  updateDataAtPath,
  getDataAtJoinRef,
} from 'src/Utils/fireUtils';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';
import {useUserTicketCount} from 'src/Hooks/reduxHooks';
import {useUserId} from 'src/Hooks/reduxHooks';
import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {createHunt} from 'src/Utils/huntFunctions';
import {firebaseLoginAnonymously} from 'src/Utils/loginFunctions';
import {THunt} from 'src/types/THunt';
import {TVoucher} from 'src/types/TVoucher';
import {TUser} from 'src/types/TUser';
import {TGroupInfo} from 'src/types/TGroupInfo';
import {TEventInfo} from 'src/types/TEventInfo';
import {TNavigationRouteParams} from 'src/types/TNavigationRouteParams';
import ExpoImage from 'src/Utils/ExpoImage';

import FoundVouchers from 'src/JoinRedeem/FoundVouchers';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import getAppType from 'src/Utils/getAppType';
import {LinearGradient} from 'expo-linear-gradient';

const buoy = require('src/Images/Icons/lifebuoy.png');

const qrCode = require('src/Images/qrCode.png');

const adminCoinCodes = ['rosebud', 'coins'];

const screenIcons = [
  require('src/Images/Icons/closeIconLight.png'),
  require('src/Images/Icons/diamond.png'),
  require('src/Images/Icons/globe.png'),
  require('src/Images/Icons/user.png'),
  require('src/Images/Icons/lr_plus_compass.png'),
  require('src/Images/Icons/chatting.png'),
  require('src/Images/Icons/listView.png'),
  require('src/Images/Icons/3users.png'),
  require('src/Images/Icons/love.png'),
  require('src/Images/Icons/clock_circle2.png'),
  require('src/Images/Icons/badge_percentage.png'),
  require('src/Images/Icons/warning-circular.png'),
];

const JoinHunt: React.FC<{
  isModal?: boolean;
}> = ({isModal = false}) => {
  console.log('JoinHunt render');
  const {width} = useWindowDimensionsSafe();
  const tickets = useUserTicketCount();

  const {
    voucherCode: initialVoucherCode,
    autoSubmit = false,
    autoFound = false,
  } = useParams<'JoinHunt'>();

  const userId = useUserId();
  const iHavePass = !!useExplorerPass();

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const [loading, setLoading] = useState<boolean>();
  const [lastEnteredCode, setLastEnteredCode] = useState<string | undefined>();
  const [voucherCode, setVoucherCode] = useState<string | null>(
    initialVoucherCode || null,
  );

  const eventId = useTypedSelector((state) => state.event?.eventId);
  const groupId = useTypedSelector((state) => state?.group?.info?.groupId);

  useEffect(() => {
    if (!voucherCode && window?.location?.search) {
      const urlParams = new URLSearchParams(window.location.search);
      const joinCode = urlParams.get('joinCode');
      setVoucherCode(joinCode);
    }
  }, []);

  const email = useTypedSelector((state) => state?.user?.info?.email);

  const checkNamedVoucher = (formattedVoucherCode: string) => {
    return new Promise(async (resolve) => {
      const namedVoucher = await apiCheckNamedVoucher(
        userId || 'error',
        formattedVoucherCode,
      );

      if (namedVoucher && namedVoucher.response === 'Success.') {
        resolve(namedVoucher.voucher);
      } else {
        resolve(false);
      }
    });
  };

  const checkFirebaseVoucher = async (formattedCode: string) => {
    return new Promise(async (resolve) => {
      const fullVoucherPath = `transactions/purchases/${
        formattedCode || 'error'
      }`;
      const matchingRedemption = (await getDataAtPath(
        fullVoucherPath,
      )) as TVoucher;
      console.log({matchingRedemption});

      //////////////////  passport redeem
      /// add case for Tickets/Coins vs Passport,

      if (matchingRedemption) {
        const {
          purchase = {},
          redeemed = 0,
          subscriptionLength,
          subscriptionId,
          trialPeriodDays,
          proccessedRedemptions,
        } = matchingRedemption;

        const theme = purchase?.theme || 'Standard';
        const upgrade = purchase?.upgrade || {};
        const tickets = purchase?.tickets || 0;
        const pass = purchase?.pass || {};

        let redeemingUserId = null;
        console.log({proccessedRedemptions});
        if (proccessedRedemptions && Object.values(proccessedRedemptions)) {
          redeemingUserId = Object.values(proccessedRedemptions)?.[0]?.userId;
        }
        if (theme === 'passport') {
          //  handle subscription formatting here
          const formatted = {
            voucher_code: matchingRedemption.voucherCode,
            source: 'firebase_voucher',
            type: 'passport',
            previously_redeemed: tickets <= redeemed ? true : false,
            num_redeemed: redeemed,
            quantity: tickets,
            redeemingUserId,
            subscription_length: subscriptionLength,
            subscription_id: subscriptionId,
            trial_period_days: trialPeriodDays,
          };

          resolve(formatted);
        } else if (pass.passType) {
          const formatted = {
            voucher_code: matchingRedemption.voucherCode,
            source: 'firebase_voucher',
            previously_redeemed: !!redeemed ? true : false,
            num_redeemed: redeemed,
            redeemingUserId,
            type: 'pass',
            theme,
            passPlayers: pass.passPlayers,
            passType: pass.passType,
          };

          resolve(formatted);
        } else {
          const formatted = {
            voucher_code: matchingRedemption.voucherCode,
            source: 'firebase_voucher',
            previously_redeemed: tickets <= redeemed ? true : false,
            num_redeemed: redeemed,
            redeemingUserId,
            type: 'ticket',
            theme,
            tickets,
            coins: upgrade?.coins || 0,
          };

          resolve(formatted);
        }
      } else {
        console.log('no voucher found at: ' + fullVoucherPath);
        resolve(false);
      }

      setLoading(false);
    });
  };

  interface FoundItem {
    type: string;
    previously_redeemed: boolean;
    redeemingUserId: string;
  }

  const checkCode = async (formattedCode: string): Promise<FoundItem> => {
    // query all potential codes types for match

    const userRef = {
      path: 'users',
      equalTo: formattedCode,
      type: 'join_code',
    };
    const unpaidRef = {
      path: 'scavengerhunt/groups',
      equalTo: formattedCode,
      type: 'invite_code',
    };
    const customEventIdRef = {
      path: 'customEvents',
      orderBy: 'eventId',
      equalTo: formattedCode,
      type: 'custom_event_code',
    };
    const customEventRef = {
      path: 'customEvents',
      orderBy: 'joinCode',
      equalTo: formattedCode,
      type: 'custom_event_code',
    };

    const result = await Promise.all([
      // join cases

      getDataAtJoinRef(userRef), // join_code (old user written)
      getDataAtJoinRef(unpaidRef), // invite_code
      getDataAtOrderBy(customEventRef), // custom event by uuid
      getDataAtJoinRef(customEventIdRef), // custom event by id

      // voucher cases
      checkNamedVoucher(formattedCode), // named promo vouchers
      checkFirebaseVoucher(formattedCode), // website purchases
    ]);

    console.log({result});

    const found = (result.find((item) => item) || {}) as FoundItem; // invalid results resolve to false, looking for vald result
    console.log('everything found', found);
    return found;
  };

  const resetAndAlert = (
    text: string,
    cancelButton: string | null = null,
    handleCancel: (() => void) | null = () => {},
    title = 'Could Not Redeem or Join',
    numberedPoints = null,
    confirmText = 'Go Back / Try Again',
    reset = true,
  ) => {
    apiUpdateUserBadges();
    setLoading(false);
    setVoucherCode(reset ? voucherCode : '');
    navigationRef.navigate(
      'ConfirmModal',
      {
        titleText: title,
        titleBodyText: text,
        confirmText: confirmText,
        cancelText: cancelButton,
        handleCancel: handleCancel || undefined,
        cancelColor: constants?.color?.teal,
        numberedPoints,
        //     titleBodyText:
      },
      'Join Hunt 12341234',
    );
  };

  const resetAndConfirm = (
    text: string,
    buttonText: string,
    nextRoute: keyof TNavigationRouteParams,
    children: ReactNode,
  ) => {
    apiUpdateUserBadges();
    setLoading(false);
    setVoucherCode('');
    navigationRef.navigate(
      'ConfirmModal',
      {
        titleText: text,
        confirmText: buttonText,
        cancelText: 'Close',
        handleConfirm: () => {
          !!isModal && navigationRef?.goBack('JoinRedeem/JoinHunt.tsx');
          nextRoute && navigationRef.navigate(nextRoute, null, 'JoinHunt1234');
        },
        children,
      },
      'joinhunt1245',
    );
  };

  const userCoins =
    Number(useTypedSelector((state) => state?.user?.info?.coins)) || 0;

  const applyCoins = (redemptionObject: {
    coins: number;
    voucher_code: string;
  }) => {
    const {coins, voucher_code} = redemptionObject;

    const nextCoins = userCoins + Number(coins || 0);

    updateDataAtPath(`users/${userId}/coins/`, nextCoins);

    // track coin redemption
    !!userId &&
      apiTrackUserTransaction({
        type: 'redeem_voucher',
        sch_voucher_code: voucher_code,
        user_id: userId,
        usage_user_id: userId,
        coins: coins,
      });

    navigationRef?.goBack('JoinRedeem/JoinHunt.tsx');

    resetAndAlert(
      'The coins were successfully added to your account!',
      null,
      null,
      'Success!',
      null,
      'Okay',
    );
  };

  const alertNoData = () => {
    resetAndAlert(
      'Please check the code you entered, no matching group or voucher was found. For 3rd party vouchers, tap redeem 3rd party voucher below.',
      'Redeem 3rd Party Voucher',
      () => {
        navigationRef.navigate(
          'GrouponRedemption',
          {},
          'JoinRedeem/JoinHunt.tsx',
        );
      },
    );
  };

  const isPass = !!useExplorerPass();
  const currentHuntInfo = useTypedSelector(
    (state) => state?.user?.info?.currentHuntInfo,
  );

  const handleJoinHunt = async (groupId: string, payingUserId = groupId) => {
    joinHunt(groupId, payingUserId, 'Joining Group');
  };

  const isLoggedIn = useTypedSelector((state) => !!state?.user?.userId);

  const validateCode = (directCode?: string) => {
    // validate code before querying
    //check for valid length and give feedback

    const code = (voucherCode || directCode || '')?.toLowerCase?.().trim();
    console.log('validateCode', {directCode, voucherCode, code});
    const missingCodeError = 'Please enter a valid join or voucher code.';
    const shortCodeError =
      'Join codes are at least 4 characters long. This is found in the hunt lobby after one of your friends have registered a hunt.';

    if (code?.includes?.('showlog')) {
      return navigationRef.navigate(
        'ShowLogsModal',
        null,
        'JoinRedeem/JoinHunt.tsx',
      );
    }

    if (code?.length === 0 || !code) {
      console.error({shortCodeError, code});
      Alert.alert(missingCodeError);
    } else if (code?.length < 3) {
      console.error({shortCodeError, code});
      Alert.alert(shortCodeError);
    } else {
      //DEFAULT ROUTE:
      setLoading(true);

      setTimeout(() => {
        setLoading?.(false);
      }, 10000);

      // eslint-disable-next-line
      handleCode(code);
    }
  };

  const alertUnableToRedeem = (groupId: string) => {
    setLoading(false);
    navigationRef.navigate(
      'ConfirmModal',
      {
        handleConfirm: async (_, __, handleDone) => {
          setVoucherCode(groupId);
          validateCode(groupId);
          if (typeof handleDone == 'function') {
            handleDone();
          }
        },
        titleText: `Game Creator Doesn't Have Tickets`,
        bodyText: `Unable to redeem Prepaid Join Code. Please check with the code's owner to verify that they have tickets to apply towards this hunt. To join using your own tickets use the code: ${groupId} after you get tickets.`,
        confirmText: 'Use My Own Tickets',
        cancelText: 'Okay',
        confirmColor: constants?.color?.orange,
        cancelColor: constants?.color?.blue,
        titleImage: require('src/Images/standardTicketIcon.png'),
      },
      'JoinHunt1235',
    );
  };

  const applyPass = ({
    voucher_code,
    passPlayers,
    passType,
  }: {
    voucher_code: string;
    passPlayers: number;
    passType: string;
  }) => {
    const expiresDate = moment().add(1, 'years').format('YYYY-MM-DD');

    const params = {
      passCode: voucher_code,
      passPlayers,
      passType,
      redeemedAt: Date.now(),
      redeemedDate: moment().format('YYYY-MM-DD'),
      expiresDate: expiresDate >= '2020-12-31' ? expiresDate : '2020-12-31',
    };

    updateDataAtPath(`users/${userId}/pass/`, params);

    updateDataAtPath(`transactions/purchases/${voucher_code}/redeemed/`, 1);

    // track pass redemption
    userId &&
      apiTrackUserTransaction({
        type: 'redeem_voucher',
        sch_voucher_code: voucher_code,
        user_id: userId,
        usage_user_id: userId,
      });

    resetAndConfirm(
      `Your ${passType} was successfully added to your account!`,
      'Select A Hunt',
      'LandingPage',
      <View>
        <BodyText text="When You're Ready To Start" />
        <BodyText textStyle={{paddingLeft: 10}} text="1. Pick a hunt" />
        <BodyText
          textStyle={{paddingLeft: 10}}
          text="2. Select 'Start Registration'"
        />
        <BodyText textStyle={{paddingLeft: 10}} text="3. Choose your theme" />
        <BodyText textStyle={{paddingLeft: 10}} text="4. Press 'Create Hunt'" />
        <BodyText
          textStyle={{paddingLeft: 10}}
          text={`5. Add up to ${passPlayers - 1} other player(s) to your hunt!`}
        />
      </View>,
    );
  };

  const handleUserData = async (userData: TUser) => {
    const groupId = userData?.currentHuntInfo?.groupId;
    const currentGroupId = currentHuntInfo?.groupId;
    const alreadyInGroup = groupId == currentGroupId;

    // checking if I have a pass or if they have a pass

    const groupData = ((await getDataAtPath(
      `/scavengerhunt/groups/${groupId}/`,
    )) || {}) as TGroupInfo;
    const {theme = 'standard', isFree, passId} = groupData;
    const hasTickets = userData?.tickets?.[theme] > 0;

    if (!groupId) {
      alertNoData();
      return;
    }

    if (groupId && isPass && userData?.userId) {
      handleJoinHunt(groupId, userData?.userId);
      return;
    }

    if (groupId && userData?.userId && (isFree || passId)) {
      handleJoinHunt(groupId, userData?.userId);
      return;
    }

    if (groupId && !alreadyInGroup && hasTickets) {
      const newTicketQuantity = Number(userData?.tickets?.[theme]) - 1;

      updateDataAtPath(
        `users/${userData?.userId}/tickets/${theme}`,
        newTicketQuantity,
      );

      userData?.userId && handleJoinHunt(groupId, userData?.userId);

      // track join code ticket use
      userData?.userId &&
        userId &&
        apiTrackUserTransaction({
          type: 'ticket_use',
          user_id: userData?.userId,
          usage_user_id: userId,
          group_id: groupId,
          tickets: -1,
          theme,
        });

      return;
    }

    if (groupId && !alreadyInGroup && !hasTickets) {
      alertUnableToRedeem(groupId);
      return;
    }

    if (groupId && alreadyInGroup && userData?.userId) {
      handleJoinHunt(groupId, userData?.userId);
      return;
    }

    //DEFAULT CASE:
    alertUnableToRedeem(groupId);
  };

  const resetSelf = () => {
    setVoucherCode('');
    setLoading(false);
  };

  const alertNeedTickets = (theme: string) => {
    Alert.alert(
      'You need tickets!',
      `You need at least one ${theme} Ticket from the Foxshop to join this hunt`,
      [
        {
          text: 'Cancel',
          onPress: () => resetSelf(),
        },
        {
          text: 'Ok',
          onPress: () => {
            setLoading(false);
            navigationRef.navigate('PurchaseModal', {}, 'JoinHunt1234');
          },
        },
      ],
    );
  };

  const alertJoinHunt = (theme: string, groupId: string) => {
    Alert.alert(
      'Join This Hunt?',
      `This will use 1 ${theme} Ticket. You can add more players on the next screen.`,
      [
        {
          text: 'Cancel',
          onPress: () => {
            setLoading(false);
            resetSelf();
          },
        },
        {
          text: 'Confirm',
          onPress: () => {
            const currentTicketQuantity = tickets;
            //subtract 1 and ensure newTicketQuantity is always greater than 0
            const newTicketQuantity =
              Number(currentTicketQuantity) - 1 > 0
                ? Number(currentTicketQuantity) - 1
                : 0;
            updateDataAtPath(
              `users/${userId}/tickets/${theme}`,
              newTicketQuantity,
            );

            // track invite code ticket use
            userId &&
              apiTrackUserTransaction({
                type: 'ticket_use',
                user_id: userId,
                usage_user_id: userId,
                group_id: groupId,
                tickets: -1,
                theme,
              });

            handleJoinHunt(groupId);
          },
        },
      ],
    );
  };

  const handleGroupData = (groupData: TGroupInfo) => {
    const hasEventId = groupData?.eventId;

    const groupOwnerHasPass = groupData?.passId;

    const {groupId, theme} = groupData;
    const alreadyInGroup = currentHuntInfo?.groupId == groupId;

    const hasTickets = tickets;

    const {isFree} = groupData;

    if (hasEventId) {
      //user is alredy part of an event

      handleJoinHunt(groupId);
      return;
    }

    if (playTestState || isFree) {
      //skip process in test state
      handleJoinHunt(groupId);
      return;
    }

    if (iHavePass || groupOwnerHasPass) {
      handleJoinHunt(groupId);
      return;
    }

    if (hasTickets && !alreadyInGroup) {
      //show popup with option to join
      alertJoinHunt(theme, groupId);
      return;
    }

    if (alreadyInGroup) {
      handleJoinHunt(groupId);
      return;
    }

    //DEFAULT CASE:
    alertNeedTickets(theme);
  };

  const handleEventData = (eventData: TEventInfo) => {
    console.log('handling event data');
    const eventId = eventData?.eventId;
    console.log({eventId, eventData});

    dispatchAction(updateEventInfo(eventData));
    console.log('setEventId JoinHunt');

    logCustomAnalyticsEvent('join_custom_event', {
      eventId: eventData?.eventId,
    });

    dispatchAction(setEventId(eventData?.eventId));
    navigationRef.navigate('EventHome', null, 'JoinRedeem/JoinHunt.tsx');
    setLoading(false);
  };

  const handleBarCodeRead = (readText: string) => {
    const barCode = String(readText);
    const formattedBarCode = barCode?.includes('join_code=')
      ? barCode.split('join_code=')[1]
      : barCode;

    console.log('handleBarCodeRead', {barCode, formattedBarCode});

    setVoucherCode(String(formattedBarCode));
    setLoading(true);
    const joinCode = String(formattedBarCode);

    navigationRef?.goBack('JoinRedeem/JoinHunt.tsx');
    // eslint-disable-next-line
    handleCode(formattedBarCode);

    setTimeout(() => {
      if (voucherCode) {
        setVoucherCode(null);
        setLoading(false);
      }
    }, 4000);
  };

  const applyTicketsCoins = ({
    coins,
    tickets,
    theme,
    redeemed,
    voucher_code,
    source,
  }: {
    coins: number;
    tickets: Record<string, number>;
    theme: string;
    redeemed: boolean;
    voucher_code: string;
    source: string;
  }) => {
    const themesList = getReduxState((state) =>
      Object.keys(state.themeInfo?.themes || {}),
    );

    const ticketTypes = themesList || [
      'Standard',
      'Date Night',
      'Birthday',
      'Bachelor',
      'Bachelorette',
    ];

    let ticketsPurchased = 0;
    let ticketTheme = 'Standard';
    const ticketsUsed = Number(redeemed || 0) || 0;
    let bonusCoinsPerPerson = 0;

    if (tickets) {
      ticketsPurchased = Number(tickets || 0) || 0;

      if (ticketTypes?.includes?.(theme)) {
        ticketTheme = theme || 'Standard';
      }

      if (coins) {
        bonusCoinsPerPerson = coins || 0;
      }
    }

    const userCurrentTickets = tickets?.[ticketTheme] || 0 || 0;
    const userCurrentCoins = coins || 0;

    if (ticketsPurchased - ticketsUsed > 0) {
      updateDataAtPath(
        `users/${userId}/tickets/${ticketTheme}`,
        userCurrentTickets + ticketsPurchased - ticketsUsed,
      );
      updateDataAtPath(
        `users/${userId}/coins/`,
        userCurrentCoins +
          bonusCoinsPerPerson * (ticketsPurchased - ticketsUsed),
      );
      updateDataAtPath(
        `transactions/purchases/${voucher_code}/redeemed/`,
        ticketsPurchased,
      );

      const redemptionId = uuidv4();
      const redemptionObject = {
        date: Date.now(),
        dateTime: moment().format('YYYY-MM-DDTHH:mm:ss.sssZ'),
        redemptionId,
        tickets: ticketsPurchased - ticketsUsed,
        bonusCoins: bonusCoinsPerPerson * (ticketsPurchased - ticketsUsed),
        transactionId: voucher_code,
        userId: userId,
      };

      if (source === 'firebase_voucher') {
        updateDataAtPath(
          `transactions/purchases/${voucher_code}/redemptions/${redemptionId}`,
          redemptionObject,
        );
      }
      updateDataAtPath(
        `users/${userId}/redemptions/${redemptionId}`,
        redemptionObject,
      );

      // track tickets voucher redemption
      userId &&
        apiTrackUserTransaction({
          type: 'redeem_voucher',
          sch_voucher_code: voucher_code,
          user_id: userId,
          usage_user_id: userId,
          tickets: ticketsPurchased - ticketsUsed,
          theme,
        });

      setTimeout(() => {
        navigationRef.navigate(
          'CESModal',
          {
            title: 'Thank You For Your Redeeming ',
            subTitle: 'Let us know how we can improve the redemption process',
            type: 'r_voucher_redemption',
          },
          'JoinHunt1236',
        );
      }, 2000);

      !!isModal && navigationRef?.goBack('JoinRedeem/JoinHunt.tsx');

      resetAndConfirm(
        `You've Redeemed ${ticketsPurchased} Ticket${
          ticketsPurchased > 1 ? 's' : ''
        }`,
        'Select An Activity',
        'LandingPage',
        <View>
          <BodyText text="You are the team leader! To get started:" />
          <BodyText
            textStyle={{paddingLeft: 10}}
            text={`1. Tap a activity and select "Start Pre-Registration".`}
          />
          <BodyText
            textStyle={{paddingLeft: 10}}
            text="2. Choose a theme and create a group."
          />
          <BodyText
            textStyle={{paddingLeft: 10}}
            text="3. Share your join code with other players."
          />
        </View>,
      );
    } else {
      resetAndAlert('This voucher has already been applied.');
    }
  };

  const handleCode = async (code: string) => {
    console.log('handling a code ', code);

    const newFormattedCode = code
      ?.toString?.()
      ?.toLowerCase?.()
      ?.trim?.()
      ?.replace?.(/\s/g, '');

    /// check for matching code, take action based on code type

    // admin coins code
    // testListHunt
    // testAudioHunt
    if (
      newFormattedCode?.includes('test') &&
      newFormattedCode?.includes('hunt')
    ) {
      let hunt_id = '452';
      let route_id = '578';
      let classic_hunt = true;
      let hunt_type = 'scavaHunt';
      let huntStarted = true;
      if (newFormattedCode?.includes('list')) {
        hunt_id = '2368';
        route_id = '10';
        classic_hunt = true;
      } else if (newFormattedCode?.includes('bar')) {
        hunt_id = '1233';
        route_id = '934';
        hunt_type = 'barHunt';
        classic_hunt = true;
      } else if (newFormattedCode?.includes('audio')) {
        hunt_id = '6587';
        route_id = '14560';
        hunt_type = 'audioTour';
      }

      const groupObject = await createHunt(
        {
          name: 'Test Hunt' as string | null,
          huntStarted,
          hunt_id: hunt_id as string,
          route_id: route_id as string,
          classic_hunt: classic_hunt as boolean,
          isTestHunt: true, // Adjust the type here
          hunt_type: hunt_type as string,
          huntPhotoURL:
            'https://photos.letsroam.com/scavenger_hunt_locations/Zanzibar_Zatop_Olso_team_building_1509998322_thumb.jpg' as
              | string
              | null,
        } as unknown as THunt, // Use unknown as a "type wildcard"
        'Standard',
        true,
        'testHunt',
      );
      if (!!groupObject?.groupId)
        return await joinHunt(groupObject.groupId, userId, 'Creating Hunt');
    } else if (
      adminCoinCodes?.includes?.(newFormattedCode) &&
      email?.includes?.('@letsroam.com')
    ) {
      return applyCoins({
        voucher_code: 'coins',
        coins: 100000,
      });
    }

    console.log('about to checkCode');

    // check firebase & lr api for match
    const found = await checkCode(newFormattedCode);

    console.log('finished to checkCode');

    console.log('data found', {found});

    mmkvStorage.delete('customAdView');

    console.log('voucher code potentially found', {found});

    const handleNoUserRegular = (message: string | null | undefined) => {
      return navigationRef.navigate(
        'LoginModal',
        {
          message:
            message ||
            'You must log in to join a regular group. For corporate events you can also enter in the event join code on the previous screen.',
        },
        'JoinHunt1237',
      );
    };

    // join cases
    if (found?.type === 'custom_event_code') {
      const joinEvent = () => {
        mmkvStorage.set('lastEnteredCode', newFormattedCode);
        // custom event
        console.log('custom_event_code found');

        // @ts-ignore we know type from type above
        handleEventData(found as TEventInfo);
      };
      if (!userId) {
        return firebaseLoginAnonymously(() => {
          joinEvent();
        });
      }
      return joinEvent();
    }

    // needs to be logged in
    // unless for event with anonymous login
    if (!isLoggedIn) {
      return navigationRef.navigate(
        'LoginModal',
        {},
        'JoinRedeem/JoinHunt.tsx',
      );
    }

    if (found?.type === 'join_code') {
      if (!userId) {
        return handleNoUserRegular('You must be loggin in to join hunts');
      }

      mmkvStorage.set('lastEnteredCode', newFormattedCode);
      // prepaid join code

      // @ts-ignore we know type from type above
      handleUserData(found);
      return;
    }

    if (found?.type === 'invite_code') {
      if (!userId) {
        return handleNoUserRegular('You must be loggin in to join hunts');
      }
      mmkvStorage.set('lastEnteredCode', newFormattedCode);
      // invite code

      // @ts-ignore we know type from type above
      handleGroupData(found);
      return;
    }

    // redeem cases
    if (found?.previously_redeemed) {
      if (!userId) {
        return handleNoUserRegular('You must be loggin in to redeem vouchers');
      }
      console.log({found});
      const {redeemingUserId} = found;

      if (redeemingUserId && redeemingUserId != userId) {
        const redemingUserInfo = (await getDataAtPath(
          `/users/${redeemingUserId}`,
        )) as TUser;
        if (redemingUserInfo) {
          console.log({redemingUserInfo});
          return handleUserData(redemingUserInfo);
        }
      }
      // previously redeemed voucher
      return resetAndAlert(
        `Join the team leader's group instead. To join their team: `,
        'See FAQs',
        () =>
          navigationRef.navigate('SupportModal', {}, 'JoinRedeem/JoinHunt.tsx'),
        'This voucher has already been applied.',
      );
    }

    if (found?.type === 'ticket') {
      if (!userId) {
        return handleNoUserRegular('You must be loggin in to redeem vouchers');
      }

      // @ts-ignore we know type from type above
      return applyTicketsCoins(found);
    }

    if (found?.type === 'pass') {
      if (!userId) {
        handleNoUserRegular('You must be loggin in to redeem vouchers');
      }

      // @ts-ignore we know type from type above
      return applyPass(found);
    }

    if (found?.type === 'coins') {
      if (!userId) {
        return handleNoUserRegular('You must be loggin in to redeem vouchers');
      }

      // @ts-ignore we know type from type above
      return applyCoins(found);
    }

    console.log('no data!!');

    alertNoData();
  };

  const getLastEnteredCode = async () => {
    const lastEnteredCode =
      mmkvStorage.getString('lastEnteredCode') || eventId || groupId;

    if (lastEnteredCode) {
      setLastEnteredCode(lastEnteredCode);
    }
  };

  useEffect(() => {
    if (!!autoSubmit) {
      setTimeout(() => {
        console.log('autosubmitting');
        validateCode();
      });
    }
    getLastEnteredCode();
  }, []);

  ////////////////////////////
  /////////  redeem functions

  ////////////////////////////
  /////////  join functions

  /////////////////////
  /// alerts && ui reset
  const stylesOverride =
    getAppType() === 'aa'
      ? {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          backgroundColor: '#EACD72',
        }
      : {};

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: isModal ? 'transparent' : '#F9F7F2',
        borderRadius: 100,
      }}>
      <View style={{alignItems: 'center', paddingBottom: 0}}>
        <ImageBackground
          style={{
            width,
            borderTopLeftRadius: isModal ? 10 : 0,
            borderTopRightRadius: isModal ? 10 : 0,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: constants?.color?.teal,
            paddingBottom: 20,
            ...stylesOverride,
          }}
          source={require('src/Images/stylized_bg.png')}>
          {getAppType() === 'aa' && (
            <LinearGradient
              colors={['#F9F7F2', 'rgba(249, 247, 242, 0.00)']}
              start={{x: 0, y: 1}}
              end={{x: 0, y: 0}}
              style={{
                position: 'absolute',
                bottom: 0,
                height: '50%',
                width: '100%',
              }}></LinearGradient>
          )}
          <BodyText
            color={
              getAppType() == 'lr'
                ? constants?.color?.white
                : constants?.color?.black
            }
            testID={'joinHeader'}
            text={
              getAppType() == 'lr'
                ? 'Join a hunt or redeem a voucher'
                : 'Purchased online?\nActivate your membership'
            }
            textStyle={{
              paddingTop: 10,
              textAlign: 'center',
              fontSize: 20,
              fontFamily: 'JakartaBold',
              lineHeight: 26,
            }}
          />
          <BodyText
            color={
              getAppType() == 'lr'
                ? constants?.color?.white
                : constants?.color?.black
            }
            textStyle={{
              paddingBottom: 10,
              marginBottom: 10,
              textAlign: 'center',
              width: 280,
              fontSize: 14,
              fontFamily: 'Jakarta',
              lineHeight: 20,
            }}
            text={
              getAppType() == 'lr' ? (
                <Text>
                  Enter your code in the field below to join a friend’s existing
                  group, a custom event, {!playTestState && 'or '}
                  {!playTestState && (
                    <TouchableOpacity
                      testID="grouponButton"
                      onPress={() =>
                        navigationRef.navigate(
                          'GrouponRedemption',
                          {},
                          'JoinRedeem/JoinHunt.tsx',
                        )
                      }>
                      <Text
                        style={{
                          textDecorationLine: 'underline',
                          color: constants.color.white,
                        }}>
                        redeem a third-party voucher.
                      </Text>
                    </TouchableOpacity>
                  )}
                  <Text>
                    {!!lastEnteredCode && (
                      <Text>
                        {'\n\n'}The last code you entered was{' '}
                        <TouchableWithoutFeedback
                          onPress={() => {
                            setVoucherCode(lastEnteredCode);
                            validateCode(lastEnteredCode);
                          }}>
                          <Text
                            style={{
                              textDecorationLine: 'underline',
                            }}>
                            {lastEnteredCode}
                          </Text>
                        </TouchableWithoutFeedback>
                      </Text>
                    )}
                  </Text>
                </Text>
              ) : (
                'Attach purchases made through myadventureassistant.com'
              )
            }
          />
          <View style={{alignItems: 'center', gap: 10}}>
            <TextInput
              placeholder="Q2134D2"
              testID={'voucherCode'}
              value={voucherCode || ''}
              autoCorrect={false}
              underlineColorAndroid={'transparent'}
              allowFontScaling={false}
              onChangeText={(voucherCode) => setVoucherCode(voucherCode)}
              blurOnSubmit
              style={{
                width: 300,
                padding: 12,
                backgroundColor: constants?.color?.white,
                borderRadius: 8,
                textAlign: 'center',
                ...(Platform.OS == 'web' ? {padding: 10} : {}),
              }}
            />
            <ContinueButton
              text="Submit"
              loading={loading}
              width={300}
              buttonStyle={{
                borderRadius: 6,
                backgroundColor:
                  getAppType() == 'lr' ? '#4F8584' : constants?.color?.black,
              }}
              testID={'voucherCodeSubmit'}
              handlePress={() => {
                console.log('voucherCodeSubmit', autoFound);
                if (autoFound) {
                  dispatchAction(addVoucherShows(0.5));
                }

                if (loading) {
                  console.log('the search is already happening');
                } else {
                  console.log('searching for voucher');
                  validateCode();
                }
              }}
            />
            {!!isModal && getAppType() !== 'aa' && (
              <ContinueButton
                text={'Cancel - Redeem Later'}
                width={300}
                buttonStyle={{
                  borderRadius: 6,
                  backgroundColor: constants?.color?.gray3,
                }}
                background={constants?.color?.gray3}
                handlePress={() => {
                  if (autoFound) {
                    dispatchAction(addVoucherShows(1));
                  }
                  navigationRef?.goBack('JoinRedeem/JoinHuntModal.tsx');
                }}
              />
            )}
          </View>
        </ImageBackground>
        {/* <BodyText
          text={
            playTestState ? (
              'Enter your code to join a hunt.'
            ) : autoFound ? (
              'A voucher code has been automatically found with your account! Would you like to auto-redeem it now, so you can use it towards a future scavenger hunt?'
            ) : (
              <Text>
                {!!lastEnteredCode && (
                  <Text>
                    The last code you entered was{' '}
                    <TouchableWithoutFeedback
                      onPress={() => {
                        setVoucherCode(lastEnteredCode);
                        validateCode(lastEnteredCode);
                      }}>
                      <Text
                        style={{
                          // color: constants?.color?.teal,
                          textDecorationLine: 'underline',
                        }}>
                        {lastEnteredCode}
                      </Text>
                    </TouchableWithoutFeedback>
                  </Text>
                )}
              </Text>
            )
          }
          textStyle={{
            marginBottom: 20,
            textAlign: 'center',
            marginHorizontal: 20,
          }}
        /> */}
        {
          // hideVoucherSearch != true && !playTestState &&
          // <JoinVoucherSearch />
          //
        }

        {getAppType() === 'aa' && (
          <View
            style={{
              paddingHorizontal: 20,
              marginTop: 20,
              width: '100%',
            }}>
            <ExpoImage
              source={screenIcons[1]}
              style={{
                width: 29,
                height: 26,
                marginBottom: 20,
                tintColor: 'black',
              }}
            />
            <Text
              style={{
                flex: 1,
                textAlign: 'left',
                fontFamily: 'JakartaBold',
                fontSize: 23,
                lineHeight: 28,
                color: constants?.color?.black,
              }}>
              Start your membership today
            </Text>
            <View style={{gap: 20, marginVertical: 20}}>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                  <View
                    style={{
                      borderColor: constants?.color?.black,
                      borderRadius: 30,
                      height: 30,
                      width: 30,
                      borderWidth: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <ExpoImage
                      style={{height: 15, width: 15, tintColor: 'black'}}
                      resizeMode="contain"
                      source={screenIcons[3]}></ExpoImage>
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: 'JakartaBold',
                      color: constants?.color?.black,
                      textAlign: 'center',
                      width: 120,
                    }}>
                    Dedicated {'\n'} personal assistant
                  </Text>
                </View>
                <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                  <View
                    style={{
                      borderColor: constants?.color?.black,
                      borderRadius: 30,
                      height: 30,
                      width: 30,
                      borderWidth: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <ExpoImage
                      style={{height: 15, width: 15, tintColor: 'black'}}
                      resizeMode="contain"
                      source={screenIcons[8]}></ExpoImage>
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: 'JakartaBold',
                      color: constants?.color?.black,
                      textAlign: 'center',
                      width: 120,
                    }}>
                    Personalized recommendations
                  </Text>
                </View>
                <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                  <View
                    style={{
                      borderColor: constants?.color?.black,
                      borderRadius: 30,
                      height: 30,
                      width: 30,
                      borderWidth: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <ExpoImage
                      style={{height: 15, width: 15, tintColor: 'black'}}
                      resizeMode="contain"
                      source={screenIcons[9]}></ExpoImage>
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: 'JakartaBold',
                      color: constants?.color?.black,
                      textAlign: 'center',
                      width: 100,
                    }}>
                    Spend less {'\n'} time planning
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                  <View
                    style={{
                      borderColor: constants?.color?.black,
                      borderRadius: 30,
                      height: 30,
                      width: 30,
                      borderWidth: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <ExpoImage
                      style={{height: 15, width: 15, tintColor: 'black'}}
                      resizeMode="contain"
                      source={screenIcons[10]}></ExpoImage>
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: 'JakartaBold',
                      color: constants?.color?.black,
                      textAlign: 'center',
                      width: 120,
                    }}>
                    Save money with {'\n'} price comparisons
                  </Text>
                </View>
                <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                  <View
                    style={{
                      borderColor: constants?.color?.black,
                      borderRadius: 30,
                      height: 30,
                      width: 30,
                      borderWidth: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <ExpoImage
                      style={{height: 15, width: 15, tintColor: 'black'}}
                      resizeMode="contain"
                      source={screenIcons[6]}></ExpoImage>
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: 'JakartaBold',
                      color: constants?.color?.black,
                      textAlign: 'center',
                      width: 100,
                    }}>
                    Bespoke itineraries
                  </Text>
                </View>
                <View style={{flex: 1, alignItems: 'center', gap: 5}}>
                  <View
                    style={{
                      borderColor: constants?.color?.black,
                      borderRadius: 30,
                      height: 30,
                      width: 30,
                      borderWidth: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <ExpoImage
                      style={{height: 15, width: 15, tintColor: 'black'}}
                      resizeMode="contain"
                      source={screenIcons[11]}></ExpoImage>
                  </View>
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: 'JakartaBold',
                      color: constants?.color?.black,
                      textAlign: 'center',
                      width: 100,
                    }}>
                    Near 24/7 support
                  </Text>
                </View>
              </View>
            </View>
            <Text
              style={{
                flex: 1,
                textAlign: 'center',
                fontFamily: 'Jakarta',
                fontSize: 15,
                color: constants?.color?.black,
                lineHeight: 22,
                marginBottom: 20,
              }}>
              Auto-renew.{'\n'}
              Cancel anytime.
            </Text>
            <View style={{flex: 1}}>
              <TouchableOpacity
                onPress={() =>
                  navigationRef.navigate(
                    'PurchaseModal',
                    {showMembershipOnly: true},
                    'FreeTrialScreen.tsx',
                  )
                }
                style={{
                  flex: 1,
                  flexDirection: 'column',
                  backgroundColor: '#648A87',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 5,
                  padding: 8,
                  borderRadius: 5,
                  marginTop: 24,
                  paddingVertical: 20,
                  marginBottom: 30,
                }}>
                <Text
                  style={{
                    color: constants.color.white,
                    fontSize: 15,
                    fontFamily: 'JakartaBold',
                    textAlign: 'center',
                  }}>
                  Become a member
                </Text>
              </TouchableOpacity>
            </View>
            <View style={{width: '100%'}}>
              <TouchableOpacity
                style={{
                  backgroundColor: 'black',
                  borderRadius: 8,
                  marginVertical: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingVertical: 40,
                  paddingHorizontal: 40,
                  shadowColor: '#000',
                  shadowOffset: {width: 0, height: 1},
                  shadowOpacity: 0.6,
                  shadowRadius: 3,
                }}>
                <>
                  <Image
                    source={buoy}
                    style={{width: 36, height: 36, resizeMode: 'contain'}}
                  />
                  <View style={{display: 'flex', flexDirection: 'row'}}>
                    <BodyText
                      text={'Call us at'}
                      textStyle={{
                        color: 'white',
                        fontSize: 17,
                        fontFamily: 'JakartaBold',
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    />
                    <BodyText
                      text={'1-833-202-7626'}
                      textStyle={{
                        color: '#EACD72',
                        fontSize: 17,
                        fontFamily: 'JakartaBold',
                        marginLeft: 5,
                        marginRight: 0,
                      }}
                    />
                  </View>
                  <BodyText
                    text={'for live support'}
                    textStyle={{
                      color: constants.color.white,
                      fontSize: 17,
                      fontFamily: 'JakartaBold',
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 3,
                    }}
                  />
                  <BodyText
                    text={'Our friendly agents are standing by.'}
                    textStyle={{
                      color: constants.color.white,
                      fontSize: 12,
                      fontFamily: 'Jakarta',
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 12,
                    }}
                  />
                  <ContinueButton
                    text={'Call support'}
                    height={50}
                    background={'#EACD72'}
                    textStyle={{
                      fontFamily: 'JakartaBold',
                      textAlign: 'center',
                      flex: 1,
                      color: constants?.color?.black,
                    }}
                    containerStyle={{
                      marginTop: 24,
                      borderRadius: 6,
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      shadowColor: '#000',
                      shadowOffset: {width: 0, height: 1},
                      shadowOpacity: 0.6,
                      shadowRadius: 3,
                    }}
                    buttonStyle={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    handlePress={() => {
                      navigationRef.navigate(
                        'SupportModal',
                        {},
                        'Nav/DrawerMenu.tsx',
                      );
                    }}
                  />
                </>
              </TouchableOpacity>
            </View>
          </View>
        )}

        {!autoFound && playTestState == false && !isModal && (
          <View style={{flex: 1, marginTop: 20}}>
            <View style={{width, paddingHorizontal: 20}}>
              <TouchableOpacity
                style={{
                  flex: 1,
                  backgroundColor: constants?.color?.white,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingVertical: 20,
                  gap: 20,
                  paddingHorizontal: 20,
                  borderRadius: 8,
                }}
                onPress={() => {
                  if (!userId) {
                    return navigationRef.navigate(
                      'LoginModal',
                      {},
                      'JoinRedeem/JoinHunt.tsx',
                    );
                  }
                  navigationRef.navigate(
                    'QrModal',
                    {
                      handleBarCodeRead: handleBarCodeRead,
                      text: 'Scan QR code to join hunt.',
                    },
                    'JoinHunt1238',
                  );
                }}>
                <View
                  style={{flexDirection: 'column', flex: 1, marginLeft: 20}}>
                  <Text style={{fontSize: 20, fontFamily: 'JakartaBold'}}>
                    Scan QR Code
                  </Text>
                  <Text style={{fontSize: 14, fontFamily: 'Jakarta'}}>
                    You can also scan your friend’s QR code to join their event.
                  </Text>
                </View>
                <ExpoImage
                  style={{height: 64, width: 64}}
                  resizeMode="contain"
                  source={qrCode}></ExpoImage>
              </TouchableOpacity>
            </View>
          </View>
        )}
        {playTestState == false && !isModal && (
          <>
            <FoundVouchers />

            <View style={{marginTop: 10}}>
              <HomeNearbyGroupFinder />
            </View>
            <View
              style={{
                paddingHorizontal: 24,
              }}>
              <TouchableOpacity
                style={{
                  width: '100%',
                  backgroundColor: 'black',
                  borderRadius: 8,
                  marginVertical: 20,
                  marginHorizontal: 12,
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingVertical: 40,
                  paddingHorizontal: 40,
                  shadowColor: '#000',
                  shadowOffset: {width: 0, height: 1},
                  shadowOpacity: 0.6,
                  shadowRadius: 3,
                }}>
                <>
                  <Image
                    source={buoy}
                    style={{width: 36, height: 36, resizeMode: 'contain'}}
                  />
                  <View style={{display: 'flex', flexDirection: 'row'}}>
                    <BodyText
                      text={'Call us at'}
                      textStyle={{
                        color: 'white',
                        fontSize: 17,
                        fontFamily: 'JakartaBold',
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    />
                    <BodyText
                      text={'1-833-202-7626'}
                      textStyle={{
                        color: constants.color.orange,
                        fontSize: 17,
                        fontFamily: 'JakartaBold',
                        marginLeft: 5,
                        marginRight: 0,
                      }}
                    />
                  </View>
                  <BodyText
                    text={'for live support'}
                    textStyle={{
                      color: constants.color.white,
                      fontSize: 17,
                      fontFamily: 'JakartaBold',
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 3,
                    }}
                  />
                  <BodyText
                    text={'Our friendly agents are standing by.'}
                    textStyle={{
                      color: constants.color.white,
                      fontSize: 12,
                      fontFamily: 'Jakarta',
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 12,
                    }}
                  />
                  <ContinueButton
                    text={'Call support'}
                    height={50}
                    textStyle={{
                      fontFamily: 'JakartaBold',
                      textAlign: 'center',
                      flex: 1,
                    }}
                    containerStyle={{
                      marginTop: 24,
                      borderRadius: 6,
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      shadowColor: '#000',
                      shadowOffset: {width: 0, height: 1},
                      shadowOpacity: 0.6,
                      shadowRadius: 3,
                    }}
                    buttonStyle={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    handlePress={() => {
                      navigationRef.navigate(
                        'SupportModal',
                        {},
                        'Nav/DrawerMenu.tsx',
                      );
                    }}
                  />
                </>
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

//eslint-disable-next-line
export default JoinHunt;

import constants from 'src/constants';
import React, {ReactNode} from 'react';

import {ScrollView, TouchableOpacity, View, Image} from 'react-native';
import {BodyText} from 'src/Modules/CommonView';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import Icon from 'react-native-vector-icons/FontAwesome';

import moment from 'moment';

import Badges from 'src/Badges/Badges';
import {navigationRef} from 'src/Nav/navigationHelpers';
import DelayedLoad from 'src/Nav/DelayedLoad';
import {getExplorerPass} from 'src/Utils/explorerPassHelper';
import useTypedSelector from 'src/Hooks/useTypedSelector';

const YourExplorerPassView: React.FC = () => {
  const {width} = useWindowDimensionsSafe();
  const pass = getExplorerPass();
  if (!pass) {
    console.error('no explorer pass on gated page');
    return <></>;
  }

  let passExpirationText = '';
  if (pass && !pass.isSubscription && pass.expiresDate) {
    passExpirationText = ` Until ${moment(pass.expiresDate).format(
      'MMM-DD-YYYY',
    )}`;
  }

  const numberOfCities = useTypedSelector((state) => {
    const huntsLength =
      Math.round(state?.hunts?.huntsList?.length / 100) * 100 || 400;
    return huntsLength;
  });

  return (
    <View style={{flex: 1, backgroundColor: constants?.color?.white1}}>
      <ScrollView>
        <View style={{paddingBottom: 100}}>
          {/* Top Card showing number of recharges */}
          <View
            style={{
              backgroundColor: 'white',
              minHeight: 100,
              width,
              alignItems: 'center',
            }}>
            <Image
              source={require('src/Images/badgeStar250.png')}
              style={{width: 50, height: 50, marginTop: 20}}
            />
            <BodyText
              text={`You Have An Explorer Pass` + passExpirationText}
              textStyle={{
                textAlign: 'center',
                marginTop: 20,
                marginBottom: 20,
              }}
            />
          </View>
          <View style={{position: 'relative'}}>
            <BodyText
              text={'Your Key Perks'}
              textStyle={{marginTop: 20, fontWeight: '700'}}
            />
            <TouchableOpacity
              style={{
                position: 'absolute',
                top: 8,
                right: 0,
                paddingBottom: 3,
                height: 30,
              }}
              onPress={() => {
                navigationRef.navigate(
                  'PurchaseModal',
                  {},
                  'ExplorerPass/YourExplorerPassView.tsx',
                );
              }}>
              {/* A Button to see everything */}
              <BodyText
                text={'See Membership Details >'}
                textStyle={{
                  color: constants?.color?.blue,
                  fontWeight: '700',
                  fontSize: 14,
                }}
              />
            </TouchableOpacity>
            <RowWrap>
              <RowIcon icon={'star'} color={constants?.color?.yellow} />
              <View style={{width: width - 100}}>
                <BodyText
                  text={`Adventure in ${numberOfCities}+ cities`}
                  textStyle={{marginLeft: 0, fontWeight: '700'}}
                />
                <View style={{flexDirection: 'row'}}>
                  <View>
                    <BulletItem
                      text={
                        'Get unlimited Scavenger Hunts, Bar Hunts, and Ghost Tours'
                      }
                    />
                    <BulletItem text={'Invite up to 5 friends every hunt'} />
                    <BulletItem text={'Get Premium Rewards'} />
                    <BulletItem text={'Member Support at 1-833-202-7626'} />
                    <BulletItem text={'Earn Badges to unlock prizes'} />
                  </View>
                </View>
              </View>
            </RowWrap>
          </View>
          {/* showing the badges in progress */}
          <DelayedLoad delay={100}>
            <View style={{position: 'relative'}}>
              <BodyText
                text={'Your Badges in Progress'}
                textStyle={{marginTop: 20, fontWeight: '700'}}
              />
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  bottom: -4,
                  right: 0,
                  paddingBottom: 3,
                  height: 30,
                }}
                onPress={() => {
                  navigationRef.navigate(
                    'AllBadgesModal',
                    {},
                    'ExplorerPass/YourExplorerPassView.tsx',
                  );
                }}>
                {/* A Button to see everything */}
                <BodyText
                  text={'View All >'}
                  textStyle={{
                    color: constants?.color?.blue,
                    fontWeight: '700',
                    fontSize: 14,
                  }}
                />
              </TouchableOpacity>
            </View>
          </DelayedLoad>
          <Badges />
        </View>
      </ScrollView>
    </View>
  );
};

const RowWrap = ({children}: {children: ReactNode}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <View
      style={{
        alignItems: 'center',
        backgroundColor: constants?.color?.white,
        borderRadius: 10,
        marginVertical: 5,
        marginHorizontal: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
        paddingHorizontal: 5,
        paddingVertical: 5,
        width: width - 20,
      }}>
      {children}
    </View>
  );
};

const RowIcon = ({icon, color}: {icon: string; color: string}) => {
  return (
    <View
      style={{
        width: 70,
        height: 70,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Icon name={icon} size={40} color={color} />
    </View>
  );
};

const BulletItem = ({text}: {text: string}) => {
  return (
    <View style={{flexDirection: 'row'}}>
      <BodyText
        text={'\u2022'}
        textStyle={{
          fontWeight: '700',
          marginHorizontal: 0,
        }}
      />
      <BodyText
        text={text}
        textStyle={{
          marginLeft: 5,
          fontSize: 14,
        }}
      />
    </View>
  );
};

export default YourExplorerPassView;

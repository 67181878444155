import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useMemo} from 'react';

import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';

////////////////////////
///////// components

//////////// header bar

import ScavengerHuntCharacterSelection from 'src/ScavengerHunt_v2/Lobby/CharacterSelection';
import ScavengerHuntGameLobby from 'src/ScavengerHunt_v2/Lobby/GameLobby';

import HuntPaused from 'src/ScavengerHunt_v2/HuntPaused';
import HuntSocialBonuses from 'src/ScavengerHunt_v2/HuntSocialBonuses';

import DelayedLoad from 'src/Nav/DelayedLoad';
///////////////////////////////
///////////////////////////////
///////////////// v2

import HuntLoading from 'src/ScavengerHunt_v2/HuntLoading';
import TeamPhoto from 'src/ScavengerHunt_v2/TeamPhoto';
import MainGameDataUpdater from 'src/ScavengerHunt_v2/MainGameDataUpdater';
import HuntIntro from 'src/ScavengerHunt_v2/Game/HuntIntro';
import HuntOutro from 'src/ScavengerHunt_v2/Game/HuntOutro';
import NavigationChallenge from 'src/ScavengerHunt_v2/Game/NavigationChallenge';
import NavigationScore from 'src/ScavengerHunt_v2/Game/NavigationScore';
import LocationChallengeScore from 'src/ScavengerHunt_v2/Game/LocationChallengeScore';
import LocationOutro from 'src/ScavengerHunt_v2/Game/LocationOutro';
import MainGameScreenController from 'src/ScavengerHunt_v2/MainGameScreenController';

import LocationComplete from 'src/ScavengerHunt_v2/Game/LocationComplete';

import PlayerDash from 'src/ScavengerHunt_v2/Game/PlayerDash';

import HuntFinished from 'src/ScavengerHunt_v2/HuntFinished';
import HuntFinishedPlayer from 'src/ScavengerHunt_v2/HuntFinishedPlayer';

import HuntPhotos from 'src/ScavengerHunt_v2/HuntPhotos';

///////////////////////////////
///////////////////////////////
///////////////// classic
import ClassicChallenges from 'src/ScavengerHunt_v2/Classic/ClassicChallenges';
// import {useSafeAreaInsets} from 'react-native-safe-area-context';
import FocusedWrapper from 'src/Nav/FocusedWrapper';

import FullScreenNavigationHelpModal from 'src/ScavengerHunt_v2/Game/FullScreenNavgiationHelpModal';

import GameLobbyPlayers from 'src/ScavengerHunt_v2/Lobby/GameLobbyPlayers';

import GameHeader from 'src/ScavengerHunt_v2/List/GameHeader';
import LocationChallenges from 'src/ScavengerHunt_v2/Game/LocationChallenges';
import AudioTourMain from 'src/ScavengerHunt_v2/AudioTour/AudioTourMain';

import HuntReviewScreen from 'src/Modules/CommonGame/HuntReviewScreen';
import ChangeHuntModal from 'src/Modules/CommonGame/ChangeHuntModal';
import HybridCharacterList from 'src/ScavengerHunt_v2/Game/HybridCharacterList';
import HybridCharacterReveal from 'src/ScavengerHunt_v2/Game/HybridCharacterReveal';
import StoryRoundPage from 'src/ScavengerHunt_v2/Game/StoryRoundPage';

const Tab = createBottomTabNavigator();

const NavigationChallengeWrapper = () => {
  return (
    <FocusedWrapper>
      <NavigationChallenge />
    </FocusedWrapper>
  );
};

const LocationChallengeWrapper = () => {
  return (
    <FocusedWrapper>
      <LocationChallenges />
    </FocusedWrapper>
  );
};

const HuntFinishedPlayerWrapper = () => {
  return (
    <FocusedWrapper>
      <HuntFinishedPlayer />
    </FocusedWrapper>
  );
};

const HuntPhotosWrapper = () => {
  return (
    <FocusedWrapper>
      <HuntPhotos />
    </FocusedWrapper>
  );
};

const PlayerDashWrapper = () => {
  return (
    <FocusedWrapper>
      <PlayerDash />
    </FocusedWrapper>
  );
};

const TeamPhotoWrapper = () => {
  return (
    <FocusedWrapper>
      <TeamPhoto />
    </FocusedWrapper>
  );
};

const HuntOutroWrapper = () => {
  return (
    <FocusedWrapper>
      <HuntOutro />
    </FocusedWrapper>
  );
};

const LocationCompleteWrapper = () => {
  return (
    <FocusedWrapper>
      <LocationComplete />
    </FocusedWrapper>
  );
};

const ClassicChallengesWrapper = () => {
  return (
    <FocusedWrapper>
      <ClassicChallenges />
    </FocusedWrapper>
  );
};

const LocationOutroWrapper = () => {
  return (
    <FocusedWrapper>
      <LocationOutro />
    </FocusedWrapper>
  );
};

const LocationChallengeScoreWrapper = () => {
  return (
    <FocusedWrapper>
      <DelayedLoad delay={100}>
        <LocationChallengeScore />
      </DelayedLoad>
    </FocusedWrapper>
  );
};

const NavigationScoreWrapper = () => {
  return (
    <FocusedWrapper>
      <NavigationScore />
    </FocusedWrapper>
  );
};

const HuntIntroWrapper = () => {
  return (
    <FocusedWrapper>
      <HuntIntro />
    </FocusedWrapper>
  );
};

// only load component once
const AudioTourMainWrapper = () => {
  const isAudioTour = useTypedSelector(
    (state) => state.group?.info?.huntType == 'audioTour',
  );
  const isHuntLoadingOrLobby = useTypedSelector((state) => {
    return (
      state.current_screen?.currentScreen == 'HuntLoading' ||
      state.current_screen?.currentScreen == 'ScavengerHuntGameLobby' ||
      state.current_screen?.previousScreen == 'ScavengerHuntGameLobby'
    );
  });
  if (!isAudioTour || isHuntLoadingOrLobby) {
    return <></>;
  }
  return <AudioTourMain />;
};

const HuntLoadingWrapper = () => {
  return (
    <FocusedWrapper>
      <HuntLoading />
    </FocusedWrapper>
  );
};

const ScavengerHuntGameLobbyWrapper = () => {
  return (
    <FocusedWrapper>
      <ScavengerHuntGameLobby />
    </FocusedWrapper>
  );
};

const ScavengerHuntCharacterSelectionWrapper = () => {
  return (
    <FocusedWrapper>
      <ScavengerHuntCharacterSelection />
    </FocusedWrapper>
  );
};
const ScavengerHunHybridCharacterListWrapper = () => {
  return (
    <FocusedWrapper>
      <HybridCharacterList />
    </FocusedWrapper>
  );
};
const ScavengerHunHybridCharacterRevealWrapper = () => {
  return (
    <FocusedWrapper>
      <HybridCharacterReveal />
    </FocusedWrapper>
  );
};

const FullScreenNavigationHelpModalWrapper = () => {
  return (
    <FocusedWrapper>
      <FullScreenNavigationHelpModal />
    </FocusedWrapper>
  );
};

const GameLobbyPlayerModal = () => <GameLobbyPlayers isModal />;

export default function MainGameStack() {
  // const insets = useSafeAreaInsets();
  return useMemo(() => {
    console.log('MainGameStack render');
    return (
      <>
        <Tab.Navigator
          tabBar={() => <MainGameDataUpdater />}
          screenOptions={() => ({
            title: 'Home',
            headerTitleAlign: 'center',
            headerStyle: {
              elevation: 0, // remove shadow on Android
              shadowOpacity: 0, // remove shadow on iOS,
              headerTransparent: true,
            },
            header: () => <HeaderWrapper />,
          })}>
          <Tab.Screen
            name={'HuntLoading'}
            options={{title: 'home!'}}
            component={HuntLoadingWrapper}
          />
          <Tab.Screen
            name="ScavengerHuntGameLobby"
            component={ScavengerHuntGameLobbyWrapper}
            options={{unmountOnBlur: true}}
          />

          <Tab.Screen
            name="ScavengerHuntCharacterSelection"
            component={ScavengerHuntCharacterSelectionWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="HybridCharacterList"
            component={ScavengerHunHybridCharacterListWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="HybridCharacterReveal"
            component={ScavengerHunHybridCharacterRevealWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="HuntIntro"
            component={HuntIntroWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="AudioTourMain"
            component={AudioTourMainWrapper}
            options={{unmountOnBlur: false}}
          />

          <Tab.Screen
            name="NavigationChallenge"
            component={NavigationChallengeWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="NavigationScore"
            component={NavigationScoreWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="LocationOutro"
            component={LocationOutroWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="ClassicChallenges"
            component={ClassicChallengesWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="ClassicIntro"
            component={HuntIntroWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="LocationComplete"
            component={LocationCompleteWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="LocationChallenges"
            component={LocationChallengeWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="StoryRound"
            component={StoryRoundPage}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="LocationChallengeScore"
            component={LocationChallengeScoreWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name="HuntOutro"
            component={HuntOutroWrapper}
            options={{unmountOnBlur: true}}
          />
          <Tab.Screen
            name={'TeamPhoto'}
            options={{title: 'home!', unmountOnBlur: true}}
            component={TeamPhotoWrapper}
          />
          <Tab.Screen
            name={'HuntFinished'}
            options={{title: 'home!', unmountOnBlur: true}}
            component={HuntFinished}
          />
          <Tab.Screen
            name={'HuntFinishedPlayer'}
            options={{title: 'home!', unmountOnBlur: true}}
            component={HuntFinishedPlayerWrapper}
          />
          <Tab.Screen
            name={'HuntPhotos'}
            options={{title: 'home!', unmountOnBlur: true}}
            component={HuntPhotosWrapper}
          />

          <Tab.Screen
            name={'HuntReviewScreen'}
            options={{title: 'home!', unmountOnBlur: true}}
            component={HuntReviewScreen}
          />

          <Tab.Screen
            name="HuntSocialBonuses"
            options={{title: 'home!', unmountOnBlur: true}}
            component={HuntSocialBonuses}
          />

          <Tab.Group
            // @ts-ignore
            screenOptions={{presentation: 'modal'}}>
            <Tab.Screen
              name={'PlayerDashboard_v2'}
              options={{title: 'home!', unmountOnBlur: true}}
              component={PlayerDashWrapper}
            />
            <Tab.Screen
              name={'FullScreenNavigationHelpModal'}
              options={{title: 'home!', unmountOnBlur: true}}
              component={FullScreenNavigationHelpModalWrapper}
            />
            <Tab.Screen
              name={'ChangeHuntModal'}
              options={{title: 'home!', unmountOnBlur: true}}
              component={ChangeHuntModal}
            />

            <Tab.Screen
              name="GameLobbyPlayersModal"
              component={GameLobbyPlayerModal}
            />

            <Tab.Screen name="HuntPaused" component={HuntPaused} />
          </Tab.Group>
        </Tab.Navigator>
        <MainGameScreenController />
      </>
    );
  }, []);
}

const HeaderWrapper = () => {
  const useOldHeader = useTypedSelector(
    (state) =>
      state.current_screen?.currentScreen === 'ScavengerHuntGameLobby' ||
      (state.current_screen?.previousScreen === 'ScavengerHuntGameLobby' &&
        state.current_screen?.currentScreen === 'ConfirmModal'),
  );
  if (useOldHeader) {
    return <></>;
  }
  return <GameHeader />;
};

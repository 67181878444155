import constants from 'src/constants';
import React, {useRef} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {View, TouchableOpacity, Image, Linking, Platform} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import ModalSelector from 'react-native-modal-selector';
import email from 'react-native-email';

import {logPhoneCallAttempt} from 'src/Utils/apiCalls';

//////////////////////////
///////  components
import {
  ContinueButton,
  BodyText,
  CloseIconButton,
} from 'src/Modules/CommonView';
import useParams from 'src/Hooks/useParams';
import {navigationRef} from 'src/Nav/navigationHelpers';

import useTypedSelector from 'src/Hooks/useTypedSelector';

import * as Application from 'expo-application';

const SupportModal: React.FC = () => {
  const {
    bodyText,
    confirmColor,
    thirdOption,
    titleImage,
    thirdOptionColor,
    numberedPoints,
  } = useParams<'SupportModal'>();
  const {width} = useWindowDimensionsSafe();

  const modalRef = useRef<ModalSelector | null>(null);

  const phoneNumber = useTypedSelector(
    (state) => state?.user?.info?.contactPhone,
  );

  return (
    <SafeAreaView style={{flex: 1, justifyContent: 'flex-end'}}>
      <TouchableOpacity
        style={{flex: 1}}
        onPress={() => navigationRef?.goBack('LandingPage/SupportModal.tsx')}
      />
      <View
        style={{
          width,
          backgroundColor: constants?.color?.white,
          paddingVertical: 10,
          paddingHorizontal: 10,
          paddingBottom: 20,
          borderRadius: 10,
          position: 'relative',
        }}>
        {!!titleImage && (
          <Image
            source={titleImage}
            style={{
              height: 140,
              width: width - 30,
              resizeMode: 'contain',
              marginTop: 10,
              marginBottom: 20,
            }}
          />
        )}

        <BodyText
          text={'Contact Support'}
          textStyle={{textAlign: 'center', fontWeight: '700'}}
        />

        <BodyText
          text={`Be sure to check out our FAQs for instant help. Our support team is ready to help you. We typically reply to emails within the 24 hours and calls immediately during business hours.`}
          textStyle={{
            fontSize: numberedPoints ? 16 : 14,
            paddingBottom: numberedPoints ? 0 : 15,
          }}
        />

        <View style={{flexDirection: 'row', marginVertical: 10}}>
          <ContinueButton
            text={'Call Support'}
            handlePress={() => {
              modalRef.current?.open?.();
            }}
            background={confirmColor || constants?.color?.orange}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            marginVertical: !!thirdOption ? 0 : 10,
            marginBottom: !!thirdOption ? 10 : 0,
          }}>
          <ContinueButton
            text={'Email Support'}
            handlePress={() => {
              const versionNumber = Application.nativeApplicationVersion;
              email(['support@letsroam.com'], {
                subject: `Important help needed/feedback for LR v${
                  versionNumber + Platform.OS
                }`,
                body: '',
              }).catch((error) => console.log('email error: ', error));
            }}
            background={constants?.color?.blue}
          />

          <ContinueButton
            text={'Go To FAQs'}
            handlePress={() => {
              navigationRef?.goBack('LandingPage/SupportModal.tsx');
              navigationRef.navigate(
                'FAQModal',
                null,
                'LandingPage/SupportModal.tsx',
              );
            }}
            background={thirdOptionColor || constants?.color?.gray3}
          />
        </View>
        {!!bodyText && (
          <BodyText
            text={bodyText}
            textStyle={{fontStyle: 'italic', fontSize: 14}}
          />
        )}
        <ModalSelector
          data={[
            {
              label: 'Call 🇺🇸 1-833-202-7626',
              value: 'tel://+18332027626',
              key: 1,
            },
            {
              label: 'Call 🇨🇦 (720) 340-3264',
              value: 'tel://+17203403264',
              key: 2,
            },
            {
              label: 'Call 🇦🇺 +61 2 8417 1042',
              value: 'tel://+61284171042',
              key: 3,
            },
            {
              label: 'Call 🌎 1-833-202-7626',
              value: 'tel://+18332027626',
              key: 4,
            },
          ]}
          ref={modalRef}
          onChange={({value}) => {
            console.log({value});
            logPhoneCallAttempt(
              'lets_roam_app',
              phoneNumber || 'Unknown',
              value,
            );
            Linking?.openURL(value);
          }}
          cancelText={'Cancel'}
          customSelector={<View />}
          overlayStyle={{justifyContent: 'flex-end'}}
        />
        <CloseIconButton
          handlePress={() =>
            navigationRef?.goBack('LandingPage/SupportModal.tsx')
          }
          side="right"
        />
      </View>
    </SafeAreaView>
  );
};

export default SupportModal;

import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {ScrollView, View} from 'react-native';
import ExpoImage from 'src/Utils/ExpoImage';
import * as Device from 'expo-device';

///////////////////////////
///////////// components
import {
  ContinueButton,
  BodyText,
  UserThumbnail,
  ExpandFullscreenButton,
  MultiChoiceAnswer,
} from 'src/Modules/CommonView';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
///////////////////////////
///////////// utils

import ChallengeFeedbackElement from 'src/ScavengerHunt_v2/Game/ChallengeFeedbackElement';
import CallSupportLine from 'src/HeaderComponent/CallSupportLine';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {useChallenge, useChallengeId} from 'src/Hooks/gameHooks';

import Video from 'src/Modules/Native/Video';
import {useUserId} from 'src/Hooks/reduxHooks';
import {markChallengeScoreReviewedTime} from 'src/Utils/huntFunctions_v2';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {TGroupPlayer} from 'src/types/TGroupInfo';

//

const LocationChallengeScore: React.FC = () => {
  const {width} = useWindowDimensionsSafe();

  const challengeId = useChallengeId();

  const userId = useUserId();
  const players =
    useTypedSelector(
      (state) => state.group?.info?.players,
      deepEqualityCheck,
    ) || {};
  const isTimed =
    useTypedSelector((state) => state.group?.info?.isTimed) || false;
  const teamName = useTypedSelector((state) => state.group?.info?.teamName);

  const challenge = useChallenge();

  if (!challenge) {
    return <></>;
  }

  let {type} = challenge;

  const {
    question = '',
    answers = [],
    completions = {},
    postContent = '',
    thumbnail,
    photoUrl,
    playerPointsEarned = 0,
    groupPointsEarned = 0,
  } = challenge;

  console.log({challenge, type});

  if (__DEV__ && !Device.isDevice && type == 'video') {
    type = 'photo';
  }

  const totalPointsEarned =
    (playerPointsEarned || 0) + (groupPointsEarned || 0);

  let feedbackText = 'Results';

  if (type === 'bar_challenges') {
    feedbackText = 'Results';
  } else {
    const userCompletion = completions[userId || ''];
    feedbackText = 'Great Job';
    if (!userCompletion) {
      if (isTimed) {
        if (!!playerPointsEarned) {
          feedbackText = `Times Up (Other Teammates Answered But You Didn't)`;
        } else {
          feedbackText = `Times Up! Be Faster Next Time!`;
        }
      }
    } else {
      if (userCompletion.answeredCorrectly) {
        feedbackText = `Great Job!`;
      } else {
        if (!!playerPointsEarned) {
          feedbackText = `You Didn't Get The Answer Right, But Your Team earned points`;
        } else {
          feedbackText = `Incorrect. Better luck next time!`;
        }
      }
    }
    console.log({userCompletion, completions});
    // Alert.alert()
  }

  return (
    <ScrollView style={{flex: 1, backgroundColor: 'white'}}>
      <CallSupportLine />
      <View
        style={{
          flex: 1,
          width,
          paddingBottom: 60,
          backgroundColor: 'white',
        }}>
        <BodyText text={feedbackText} textStyle={{fontWeight: '700'}} header />

        <BodyText
          text={
            type !== 'bar_challenges'
              ? question || 'Location Challenge'
              : 'You crushed this round of bar challenges!'
          }
          textStyle={{
            fontStyle: 'italic',
            minHeight: 50,
            fontSize: type !== 'bar_challenges' ? 12 : 18,
            lineHeight: type !== 'bar_challenges' ? 12 : 18,
          }}
        />

        {!!(
          type == 'true_false' ||
          type == 'multiple_choice' ||
          type == 'ispy' ||
          type == 'number_guesser' ||
          type == 'numberguesser' ||
          type == 'fill_in_the_blank' ||
          type == 'fill_in_the_blank_traditional'
        ) && (
          <MultiChoiceAnswer
            answers={answers}
            completions={completions}
            teamName={teamName || ''}
            players={players}
            postContent={postContent}
            isPhoto={type === 'ispy'}
            pointsEarned={totalPointsEarned}
          />
        )}
        {!!(type === 'photo' || type === 'video') && !!photoUrl && (
          <PhotoAnswer
            challengePhotoUrl={thumbnail || photoUrl}
            completions={completions}
            teamName={teamName}
            type={type}
            players={players}
            postContent={postContent}
            pointsEarned={totalPointsEarned}
            showCamera={() => {
              navigationRef.navigate(
                'PhotoVideoCamera',
                {
                  text: question || 'Take a Photo',
                  photoUrl: photoUrl,
                  onReport: () => {
                    navigationRef.navigate(
                      'QuestionFeedBackModal',
                      {
                        challenge: challenge,
                        type: challenge?.type,
                      },
                      'ScavengerHunt_v2/Game/LocationChallengeScore.tsx',
                    );
                  },
                  shareUUID: challenge?.shareUUID,
                  isVideo:
                    challenge?.type === 'video' || challenge?.type === 'Video',
                },
                'ScavengerHunt_v2/Game/LocationChallengeScore.tsx',
              );
            }}
          />
        )}
        <View style={{alignItems: 'center'}}>
          <ContinueButton
            text={'Next Challenge'}
            containerStyle={{width: 220}}
            handlePress={() => {
              challengeId && markChallengeScoreReviewedTime(challengeId);
            }}
          />
        </View>

        <ChallengeFeedbackElement />
      </View>
    </ScrollView>
  );
};

type PhotoAnswerPropsType = {
  challengePhotoUrl: string;
  completions: object;
  players: Record<string, TGroupPlayer>;
  postContent?: string | null;
  pointsEarned: number;
  teamName?: string;
  showCamera: () => void;
  type: string;
};

const PhotoAnswer = ({
  challengePhotoUrl,
  completions,
  players,
  postContent,
  pointsEarned,
  teamName,
  showCamera,
  type,
}: PhotoAnswerPropsType) => {
  const userId = Object.keys(completions || {})[0];
  const firstName = players?.[userId]?.firstName || '';
  const photoUrl = players?.[userId]?.photoUrl || '';
  const photoColor = players?.[userId]?.photoColor || '';

  const formattedPostContent =
    postContent &&
    postContent
      .replace(/<p>/gi, '')
      .replace(/<\/p>/gi, '')
      .replace(/\[TEAM_NAME\]/gi, teamName ?? 'TEAM_NAME');
  console.log({challengePhotoUrl});
  const {width, height} = useWindowDimensionsSafe();
  return (
    <View>
      <View style={{alignItems: 'center'}}>
        <View
          style={{
            alignItems: 'center',
            backgroundColor: constants?.color?.white1,
            height: height < 700 ? 220 : 290,
            justifyContent: 'center',
            position: 'relative',
            width,
          }}>
          <CustomActivityIndicator
            size={'small'}
            color={constants?.color?.gray3}
            style={{position: 'absolute'}}
          />
          {!!challengePhotoUrl && type == 'photo' && (
            <ExpoImage
              style={{
                width,
                height: height < 700 ? 220 : 290,
              }}
              resizeMode={'cover'}
              source={{uri: challengePhotoUrl}}
            />
          )}
          {!!challengePhotoUrl && type == 'video' && (
            <Video
              source={{uri: challengePhotoUrl}}
              style={{width, height: height < 700 ? 220 : 290}}
              useNativeControls
            />
          )}
          <ExpandFullscreenButton
            isFullscreen={false}
            handlePress={showCamera}
          />
        </View>
        {!!postContent && (
          <BodyText textStyle={{fontSize: 18, lineHeight: 18}}>
            {formattedPostContent}
          </BodyText>
        )}
      </View>
      <View
        style={{
          marginHorizontal: 5,
          marginVertical: 20,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <UserThumbnail
          name={firstName}
          size={30}
          photoUrl={photoUrl}
          photoColor={photoColor}
        />
        <BodyText
          text={`Completed: +${pointsEarned || '...'} pts`}
          textStyle={{fontWeight: '700'}}
        />
      </View>
    </View>
  );
};

export default LocationChallengeScore;

import {logForCrashlytics} from 'src/Utils/fireUtils';

export const formatNumberDisplayAmount = (
  num: number | null | undefined,
): string => {
  logForCrashlytics('formatNumberDisplayAmount fired in formatHelper');

  // Check if num is null or undefined and set it to 0
  if (num === null || num === undefined) {
    num = 0;
  } else {
    num = Math.round(num);
  }

  // Process the rounded number for display
  if (num === 0) {
    return '0';
  } else if (num > 1000 * 1000 * 1000 * 1000) {
    return (
      (Math.round((num / (1000 * 1000 * 1000 * 1000)) * 10) / 10).toString() +
      'Q'
    );
  } else if (num > 1000 * 1000 * 1000) {
    return (
      (Math.round((num / (1000 * 1000 * 1000)) * 10) / 10).toString() + 'T'
    );
  } else if (num > 1000 * 1000) {
    return (Math.round((num / (1000 * 1000)) * 10) / 10).toString() + 'M';
  } else if (num > 9 * 1000) {
    return (Math.round(num / 100) / 10).toString() + 'K';
  } else {
    return num.toLocaleString();
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import moment from 'moment';

export interface TLog {
  a: unknown;
  b: unknown;
  time?: string;
}

interface LogsState {
  last100Values: TLog[]; // Change 'any' to the actual type of 'newLog' if needed
  errorLogs: TLog[];
}

const initialState: LogsState = {
  last100Values: [],
  errorLogs: [],
};

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  reducers: {
    addToLogHistory: (state, action: PayloadAction<TLog>) => {
      try {
        const newLogWithTime = {...action.payload, time: moment().format()};

        const newLogs: TLog[] = [
          ...(state.last100Values || []),
          newLogWithTime,
        ];
        if (newLogs.length > 100) {
          newLogs.shift(); // Remove the oldest value if there are more than 100
        }
        state.last100Values = newLogs;
      } catch (logError) {
        console.log({logError});
      }
    },
    addToErrorHistory: (state, action: PayloadAction<TLog>) => {
      try {
        const newLogWithTime = {...action.payload, time: moment().format()};

        const newLogs: TLog[] = [...state.errorLogs, newLogWithTime];
        // console.log('addToErrorHistory', {newLogs});

        state.errorLogs = newLogs;
      } catch (logError) {
        console.log({logError});
      }
    },
    clearErrors: (state) => {
      state.errorLogs = [];
    },
  },
});

export const {addToLogHistory, addToErrorHistory, clearErrors} =
  logsSlice.actions;

export default logsSlice.reducer;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, { useCallback, useState } from 'react';
import {Platform, TouchableOpacity, View} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import Icon from 'react-native-vector-icons/FontAwesome';
import {dispatchAction, parseHtmlEntities} from 'src/Utils/helpers';

import {setCurrentHunt} from 'src/Redux/reducers/hunts.reducer.ts';

import {BodyText} from 'src/Modules/CommonView';

import {ActivityCard} from 'src/Modules/CommonView/CarouselView';
import {
  updateHomeActiveSearch,
  updateHomeSearchText,
  updateHomeState,
} from 'src/Redux/reducers/home.reducer';
import {
  calculateFormatWalkingTime,
  cloudFlareOptimizeImage,
} from 'src/Utils/helpers';
import {navigationRef} from 'src/Nav/navigationHelpers';
import SearchBar from 'src/Modules/CommonView/SearchBar';
import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';
import debounce from 'lodash.debounce';

// dinner plate empty white
const fallbackImg = {
  uri: cloudFlareOptimizeImage(
    'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648578804_medium.jpg',
  ),
};

const popularSuggestions = [
  {
    string: 'Outdoor Scavenger Hunts',
    string_formatted: 'Outdoor Scavenger Hunts',
  },
  {
    string: 'Indoor Scavenger Hunts',
    string_formatted: 'Indoor Scavenger Hunts',
  },
  {string: 'Bar Hunts', string_formatted: 'Bar Hunts'},
  {string: 'Art Walks', string_formatted: 'Art Walks'},
  {string: 'Zoo Hunts', string_formatted: 'Zoo Hunts'},
  {string: 'Ghost Hunts', string_formatted: 'Ghost Hunts'},
  {string: 'Audio Tour', string_formatted: 'Audio Tour'},
];

const HuntSearchView: React.FC = () => {
  const {width} = useWindowDimensionsSafe();
  const [inputText,setInputText] = useState('');

  const debouncedHandleType = useCallback(
    debounce((searchText) => {
      dispatchAction(
        updateHomeState({
          homeActiveSearch: !!searchText,
          homeSearchText: searchText,
        })
      );
    }, 275),
    []
  );
  
  const handleInputChange = (searchText: string) => {
    setInputText(searchText);
    debouncedHandleType(searchText);
  };  

  const huntsList = useTypedSelector((state) => state.hunts.huntsList);
  const homeActiveSearch = useTypedSelector(
    (state) => state.home?.homeActiveSearch,
  );
  const homeSearchText = useTypedSelector(
    (state) => state.home?.homeSearchText,
  );
  const homeView = useTypedSelector((state) => state.home?.homeView);

  console.log({homeActiveSearch});

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <SearchBar
          containerStyle={{
            width: width - 80,
            backgroundColor: 'transparent',
            borderWidth: 0,
            borderBottomColor: 'transparent',
            borderTopColor: 'transparent',
            marginBottom: 10,
            flex: 1,
            paddingRight: 5,
          }}
          onChangeText={(text: string) => {
            handleInputChange(text);
          }}
          value={inputText || ''}
          onFocus={() => {
            dispatchAction(updateHomeActiveSearch(true));
          }}
          key={'Home Search View'}
          onClear={() => {
            setInputText('');
            dispatchAction(
              updateHomeState({
                homeView: 'list',
                homeActiveSearch: false,
                homeSearchText: false,
              }),
            );
          }}
          placeholderColor="#898282"
          hideShowOnClear={!inputText}
          inputStyle={{
            height: 50,
            fontSize: 14,
            fontFamily: 'Jakarta',
            fontWeight: '800',
            backgroundColor: '#D9D9D9',
            borderRadius: 6,
            marginLeft: 10,
            color: '#898282',
            marginRight: 5,
            paddingLeft: 30,

            paddingBottom: Platform.OS == 'android' ? 11 : 0,
          }}
          placeholder="Search activities in 3,500+ locations"
        />
      </View>

      {
        // suggestions
        !!homeActiveSearch &&
          !homeSearchText && !inputText &&
          popularSuggestions?.map?.((suggestion) => {
            const {string, string_formatted} = suggestion;
            return (
              <TouchableOpacity
                key={`sug_${string}`}
                style={{
                  borderBottomColor: constants?.color?.gray1,
                  borderBottomWidth: 1,
                  height: 40,
                  justifyContent: 'center',
                }}
                onPress={() => {
                  console.log({string});
                  dispatchAction(updateHomeSearchText(string));
                }}
                activeOpacity={0.8}>
                <BodyText textStyle={{marginTop: 3, fontSize: 14}}>
                  <Icon
                    name={'search'}
                    color={constants?.color?.gray3}
                    size={12}
                  />
                  {`  ${string_formatted}`}
                </BodyText>
              </TouchableOpacity>
            );
          })
      }

      {!!homeSearchText &&
        !!popularSuggestions.some((item) =>
          item.string.includes(homeSearchText),
        ) && (
          <BodyText
            text={`${homeSearchText} near you`}
            textStyle={{
              fontSize: 17,
              lineHeight: 16,
              fontFamily: 'JakartaBold',
              color: '#000',
              marginTop: 13,
              marginBottom: 0,
            }}
          />
        )}

      {!!(homeActiveSearch || homeSearchText) &&
        homeView !== 'map' &&
        huntsList.slice(0, 10)?.map?.((location, index) => {
          const cardHeight = 130;
          // console.log({location});
          const location_photo = !!location.huntMediumPhotoURL
            ? {uri: location.huntMediumPhotoURL}
            : fallbackImg;

          const displayedReviews = Math.max(
            location.total_reviews,
            (location.total_reviews % 14) +
              12 +
              (Number(location.hunt_id) % 14),
          );

          const description = parseHtmlEntities(
            location.description || 'About this hunt',
          );
          return (
            <View
              testID={'huntCard' + index}
              key={'huntCard' + index + location.hunt_id}
              style={{}}>
              <ActivityCard
                imgSource={location_photo}
                title={location.name || 'Scavenger Hunt'}
                description={description}
                leftText={
                  location.distanceAway
                    ? calculateFormatWalkingTime(location.distanceAway)
                    : undefined
                }
                handlePress={() => {
                  dispatchAction(setCurrentHunt(location));
                  navigationRef.navigate(
                    'LandingPageScavengerHuntInfoModal',
                    null,
                    'HuntSearchView',
                  );
                  logCustomAnalyticsEvent('Scavenger_hunt_view', location);
                }}
                cardWidth={width}
                cardHeight={cardHeight}
                total_reviews={displayedReviews}
                star_rating={location.star_rating}
                showReviews={true}
                wrap={true}
              />
            </View>
          );
        })}
      {!!(homeActiveSearch || homeSearchText) && homeView !== 'map' && (
        <View style={{height: 120}} />
      )}
    </>
  );
};

export default HuntSearchView;

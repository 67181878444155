import {navigationRef} from 'src/Nav/navigationHelpers';

import {getHighestCompletionParams} from 'src/Utils/huntFunctions_v2';
import useParams from 'src/Hooks/useParams';

import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import {logAttributesForCrashlytics} from 'src/Utils/fireUtils';
import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import {TLocation} from 'src/types/TLocation';
import {TChallenge} from 'src/types/TChallenge';

import {useEffect, useState} from 'react';
import {ImageSourcePropType} from 'react-native';

export const useLocationId = (): string | null => {
  const locationId: string | null = null;

  const {locationId: navigationLocationId} =
    useParams<'ModalChallengeGeneric'>();

  const currentLocationId = useTypedSelector((state) => {
    if (state.group?.info?.huntType === 'audioTour') {
      const {currentAudioTourIndex = 0} = state.group?.info || {};
      const {audioTourOrder = []} = state?.game_v2;
      const currentAudioTourItem =
        currentAudioTourIndex !== null
          ? audioTourOrder?.[currentAudioTourIndex]
          : undefined;

      return currentAudioTourItem?.locationId || navigationLocationId || null;
    } else {
      return state.game_v2?.currentLocationId || navigationLocationId || null;
    }
  });

  logAttributesForCrashlytics('locationId', `${locationId || 'none'}`);

  return currentLocationId;
};

export const usePercentageComplete = (): number => {
  return useTypedSelector((state) => {
    if (state.group?.info?.huntFinished) {
      return 100;
    }

    if (state.group?.info?.huntType === 'audioTour') {
      const {currentAudioTourIndex = 0} = state.group?.info;
      const {audioTourOrder = []} = state?.game_v2;
      // Check if currentAudioTourIndex is null, if so, set percentageComplete to 0
      const percentageComplete =
        currentAudioTourIndex !== null
          ? Math.floor(
              (currentAudioTourIndex / (audioTourOrder?.length || 100)) * 100,
            )
          : 0;

      console.log('usePercentageComplete 1', {
        percentageComplete,
        currentAudioTourIndex,
      });
      return percentageComplete;
    }

    const huntType = state.group?.info?.huntType;
    const locationList = state?.game_v2?.locationList || [];
    const locationsLength = locationList?.length;
    const locations = state?.game_v2?.locations || {};
    const locationsClosed = Object.values(locations).filter(
      (location) => location?.locationClosed,
    );
    const closedLocationIds = locationsClosed.map(
      (location) => location.locationId,
    );
    const closedChallengeIds = locationsClosed.flatMap(
      (location) => location.challengeList || [],
    );

    //BarHunt considers location as a challenge itself, so multiply locationsCompleted by two to count the correct number of completed challenges, so that the percentage works correctly
    const locationsComplete =
      locationList.filter((key) => locations[key]?.completeTime).length *
      (huntType === 'barHunt' ? 2 : 1);

    // Filter out challenges for closed locations and their associated challenges
    let challengesList = Object.values(
      state?.game_v2?.allChallenges || {},
    ).filter(
      (challenge) =>
        !closedLocationIds.includes(challenge?.locationId ?? null) &&
        !closedChallengeIds.includes(challenge?.challengeId ?? null),
    );

    // filter out bar_challenges (these aren't realy challenges)
    // and backup challenges that aren't currently shown
    challengesList = challengesList.filter(
      (challenge) =>
        challenge.type !== 'bar_challenges' &&
        (!challenge.isBackupChallenge || challenge.show),
    );

    const challengesLength = challengesList?.length;
    const challengesComplete = challengesList.filter(
      (challenge) => challenge?.completeTime,
    ).length;
    const percentageComplete = Math?.floor(
      ((locationsComplete + challengesComplete) /
        (locationsLength + challengesLength)) *
        100,
    );

    if (!locationsLength && !challengesLength) {
      console.log('usePercentageComplete 0 - no locations or challenges', {
        percentageComplete,
      });
      return 0;
    }

    const huntFinished = state?.group?.info?.huntFinished;

    if (huntFinished) {
      console.log('usePercentageComplete 3', {percentageComplete: 100});
      return 100;
    }

    if (percentageComplete > 95) {
      console.log('usePercentageComplete 5', {percentageComplete: 100});
      return 100;
    }

    console.log('usePercentageComplete 4', {percentageComplete});

    if (percentageComplete === 0) {
      return 0;
    }

    return Math?.round(percentageComplete);
  });
};

export const useLocation = (): TLocation | null => {
  const {locationOverride} = useParams<'ModalChallengeGeneric'>();

  const locationId = useLocationId();

  console.log('useLocation', {locationId});

  const locations = useTypedSelector((state) => state.game_v2?.locations);

  const location = locationOverride || (locationId && locations?.[locationId]);

  logAttributesForCrashlytics?.(
    'locationTitle',
    location ? `${location?.name || ''}` : 'No Location',
  );

  console.log('useLocation 2', {location});

  if (!location) {
    return null;
  }

  return location;
};

export const useLocationIndex = <T>(
  // eslint-disable-next-line
  selector: (appState: TLocation | null) => T,
): T | undefined => {
  // get instant point of time state
  const location = useLocation();

  const [selectedValue, setSelectedValue] = useState<T | undefined>(() =>
    selector(location),
  );

  useEffect(() => {
    const newValue = selector(location);
    if (newValue !== selectedValue) {
      setSelectedValue(newValue);
    }
  }, [location]);

  console.log({selectedValue});

  return selectedValue;
};

export const useChallengeId = (
  listChallengeIdOverride: string | null = null,
): string | null => {
  const {challengeId: navigationChallengeId} =
    useParams<'ModalChallengeGeneric'>();

  const currentChallengeId = useTypedSelector((state) => {
    if (state.group?.info?.huntType === 'audioTour') {
      const {currentAudioTourIndex = 0} = state.group?.info || {};
      const {audioTourOrder = []} = state?.game_v2;
      const currentAudioTourItem = audioTourOrder?.[currentAudioTourIndex || 0];
      return currentAudioTourItem?.challengeId;
    } else {
      return state.game_v2?.currentChallengeId;
    }
  });
  console.log('useChallengeId', {
    listChallengeIdOverride,
    navigationChallengeId,
    currentChallengeId,
  });

  const challengeId = navigationChallengeId || currentChallengeId || null;

  logAttributesForCrashlytics?.('challengeId', `${challengeId || ''}`);

  return challengeId;
};

export const useChallenge = (
  listChallengeIdOverride: string | null = null,
): TChallenge | null => {
  console.log('getChallenge: fired');
  const challengeId = useChallengeId(listChallengeIdOverride);

  const {challengeOverride} = useParams<'ModalChallengeGeneric'>();

  console.log('useChallenge data', {
    challengeId,
    challengeOverride,
  });

  const challengeFromAllChallenges = useTypedSelector(
    (state) =>
      challengeId ? state.game_v2?.allChallenges?.[challengeId] : null,
    deepEqualityCheck,
  );

  const challengeFromPlayerChallenges = useTypedSelector(
    (state) =>
      challengeId
        ? state?.playerChallenges?.playerChallengeData?.[challengeId]
        : null,
    deepEqualityCheck,
  );

  const challenge =
    challengeFromAllChallenges ||
    challengeOverride ||
    challengeFromPlayerChallenges ||
    null;

  logAttributesForCrashlytics?.(
    'challengeTask',
    challenge ? `${challenge?.task || ''}` : '',
  );

  return challenge;
};

export const useGameDebugInfo = (): string => {
  return __DEV__ ? ` ${useLocationId()}-${useChallengeId()}` : '';
};

export const useGoToNextChallenge = (): (() => void) => {
  const locationId = useLocationId();
  const huntFinished = useTypedSelector(
    (state) => state.group?.info?.huntFinished,
  );

  const goToNextChallenge = (): void => {
    if (huntFinished) {
      console.log('HuntFinished navigate1');
      navigationRef.navigate('HuntFinished', null, 'Hooks/gameHooks.ts');
      return;
    }

    const completionsParams = getHighestCompletionParams();

    if (!completionsParams) {
      return console.error('completionsParams not found');
    }

    const {route, params, highestUnFinishedLocationId} = completionsParams;
    console.log('checkIfRightLocation', {
      route,
      params,
      highestUnFinishedLocationId,
      locationId,
    });
    if (highestUnFinishedLocationId != locationId) {
      console.log('checkIfRightLocation: Wrong Location!!', route);
      route && navigationRef.navigate(route, params, 'useGoToNextChallenge');
      return;
    }
  };

  return goToNextChallenge;
};

export const useImageSource = (): ImageSourcePropType => {
  const theme = useTypedSelector((state) => state.group?.info?.theme);
  const huntType = useTypedSelector((state) => state.group?.info?.huntType);

  const ghostBanner = {
    uri: cloudFlareOptimizeImage(
      'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648582432_original.png',
    ),
  };
  const trophyBanner = {
    uri: cloudFlareOptimizeImage(
      'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648582538_original.png',
    ),
  };
  const drinkBanner = {
    uri: cloudFlareOptimizeImage(
      'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648582527_original.png',
    ),
  };
  const flowersBanner = {
    uri: cloudFlareOptimizeImage(
      'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648582514_original.png',
    ),
  };
  const bowAndArrowBanner = {
    uri: cloudFlareOptimizeImage(
      'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648582497_original.png',
    ),
  };
  const presentBanner = {
    uri: cloudFlareOptimizeImage(
      'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648582553_original.png',
    ),
  };
  const snowManBanner = {
    uri: cloudFlareOptimizeImage(
      'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648582564_original.png',
    ),
  };

  if (huntType === 'ghostHunt') {
    return ghostBanner;
  }
  if (huntType === 'barHunt') {
    return drinkBanner;
  }
  if (theme === 'Bachelorette') {
    return flowersBanner;
  }
  if (theme === 'Date Night') {
    return bowAndArrowBanner;
  }
  if (theme === 'Birthday') {
    return presentBanner;
  }
  if (theme === 'Holiday') {
    return snowManBanner;
  }
  return trophyBanner;
};

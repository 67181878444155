import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState} from 'react';
import {
  Linking,
  Text,
  TouchableOpacity,
  View,
  Image,
  Alert,
  Platform,
} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {BodyText, ContinueButton} from 'src/Modules/CommonView';

import ExpoImage from 'src/Utils/ExpoImage';

import {ActivityCard} from 'src/Modules/CommonView/CarouselView';

import {parseHTML} from 'src/Utils/dataHelper';
import DelayedLoad from 'src/Nav/DelayedLoad';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import MapMarker from 'src/Modules/MapMarker';
import MapView from 'src/Modules/Native/MapView';
import {ExpandFullscreenButton} from 'src/Modules/CommonView/Buttons';
import {Region} from 'react-native-maps';
import SimpleStarRating from 'src/Modules/CommonView/SimpleStarRating';

// teambuilding dude thumbs up
const teambuilding_group = {
  uri: cloudFlareOptimizeImage(
    'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1695386749_medium.jpeg',
  ),
};
const bachelorette_group = {
  uri: cloudFlareOptimizeImage(
    'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1695386858_medium.jpeg',
  ),
};
const birthday_sign = {
  uri: cloudFlareOptimizeImage(
    'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1695386943_medium.jpeg',
  ),
};

interface ScavengerHuntInfoAboutProps {
  handleStartPress: () => void;
  handleJoinPress: () => void;
}

const ScavengerHuntInfoAbout: React.FC<ScavengerHuntInfoAboutProps> = ({
  handleStartPress,
  handleJoinPress,
}) => {
  const {width} = useWindowDimensionsSafe();
  const [showMore, setShowMore] = useState<boolean>(false);

  const currentHunt = useTypedSelector((state) => state.hunts.currentHunt);
  const barHunt = currentHunt?.hunt_type?.includes('bar');
  const {
    lat,
    long,
    long_description,
    is_free,
    description,
    starting_location,
    hunt_type,
    featured_locations: huntFeaturedLocations,
  } = currentHunt || {};

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  console.log('ScavengerHuntInfoAbout current hunt', {currentHunt});

  console.log(
    'ScavengerHuntInfoAbout featured locations',
    huntFeaturedLocations,
  );

  function parseFeaturedLocations(
    locationsString = '' as string,
  ): {title: string; image?: string}[] {
    let parsedLocations = [];
    try {
      const locationParts = locationsString.split(',');

      parsedLocations = locationParts.map((part) => {
        console.log({part});
        const [titlePart, imagePart] = part.split('-p');
        const title = titlePart.substring(2); // Remove 't:' prefix
        const image = imagePart?.replace('./', 'https://photos.letsroam.com/');

        return {
          title,
          image,
        };
      });
    } catch (e) {
      return [];
      console.error('error parsing featured locations', e);
    }
    if (!locationsString) {
      return [];
    }

    return parsedLocations;
  }

  const parsedLocations = parseFeaturedLocations(huntFeaturedLocations || '');

  console.log('parsed locations', parsedLocations);

  const longDescription = long_description || description;

  let parsedDescription = parseHTML(longDescription || '');

  console.log('hunt is_free', is_free);
  // Alert.alert(is_free);
  if (is_free && is_free != '0') {
    parsedDescription = 'This Hunt is free to play! ' + parsedDescription;
  }
  const trimmedShortDescription = parsedDescription.substring(0, 200);
  const shortDescription =
    trimmedShortDescription.substring(
      0,
      Math?.min(
        trimmedShortDescription?.length || 0,
        trimmedShortDescription?.lastIndexOf(' ') || 0,
      ),
    ) + '...';

  let activity_description = 'Scavenger Hunt';
  if (barHunt) {
    activity_description = 'Bar Hunt and Crawl';
  } else if (hunt_type == 'artWalk') {
    activity_description = 'Art Walk Scavenger Hunt';
  } else if (hunt_type == 'audioTour') {
    activity_description = 'Audio Tour';
  }

  const distance_miles = currentHunt?.distance_miles || '';
  const distanceFixed = parseFloat(distance_miles);
  let distance = distanceFixed < 2.5 ? distanceFixed : 2.5;

  if (isNaN(distance)) {
    distance = 2.5;
  }

  // const featuredLocations = [
  //   'https://photos.letsroam.com/scavenger_hunt_location_stops/scavenger_hunt_location_1564444329_large.png',
  //   'https://photos.letsroam.com/scavenger_hunt_location_stops/scavenger_hunt_location_1564444329_large.png',
  //   'https://photos.letsroam.com/scavenger_hunt_location_stops/scavenger_hunt_location_1564444329_large.png',
  // ];

  const featuredLocations = parsedLocations;

  const specialEvents = [
    {
      image: teambuilding_group,
      title: 'Plan a team building event',
      description:
        'Enjoy this scavenger hunt as a team building event for you and your coworkers!',
      handlePress: () => {
        Linking.openURL(
          'https://www.letsroam.com/team-building/special-promotion?utm_source=lr_app&utm_medium=events_redirect&utm_campaign=scavenger_hunt_info_about',
        );
      },
    },
    {
      image: bachelorette_group,
      title: 'Book a bachelorette party',
      description:
        'Grab your bridal crew and get ready for the most adventurous bachelorette party ever!',
      handlePress: () => {
        Linking.openURL(
          'https://www.letsroam.com/bachelorette_scavenger_hunt?utm_source=lr_app&utm_medium=events_redirect',
        );
      },
    },
    {
      image: birthday_sign,
      title: 'Throw a birthday party',
      description:
        'Put on an incredible custom birthday party by exploring your area together!',
      handlePress: () => {
        Linking.openURL(
          'https://www.letsroam.com/activities/birthday_scavenger_hunt?utm_source=lr_app&utm_medium=events_redirect',
        );
      },
    },
  ];

  const latFloat = parseFloat(String(lat || '39.7392'));
  const lngFloat = parseFloat(String(long || '-104.9903'));

  const initialRegion: Region = {
    latitude: latFloat,
    longitude: lngFloat,
    latitudeDelta: 0.025,
    longitudeDelta: 0.025,
  };

  const handlePress = () => {
    Alert.alert(
      'Warning',
      'Apple and Google Maps can sometimes give inaccurate directions. Double check that the starting location given matches the one shown here in the app.',
      [
        {
          text: 'View Directions',
          onPress: () => {
            if (Platform.OS == 'android') {
              Linking?.openURL('geo:0,0?q=' + starting_location);
            } else {
              Linking?.openURL('http://maps.apple.com/?q=' + starting_location);
            }
          },
        },
        {
          text: 'Cancel',
        },
      ],
    );
  };

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  return (
    <View
      style={{
        backgroundColor: '#F9F7F2',
        width,
      }}>
      <View style={{backgroundColor: 'black', width, height: 120, top: -5}}>
        <ExpoImage
          resizeMode={'cover'}
          style={{
            width: width,
            height: 120,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
          }}
          source={{
            uri: cloudFlareOptimizeImage(currentHunt?.huntMediumPhotoURL || ''),
          }}
        />
      </View>
      <DelayedLoad delay={250}>
        <View
          style={{
            justifyContent: 'flex-start',
            padding: 5,
            backgroundColor: '#F9F7F2',
            position: 'relative',
          }}>
          {/* on modal after clicking activity on home page */}
          <BodyText
            text={`About This ${activity_description}`}
            textStyle={{
              textTransform: 'uppercase',
              fontSize: 11,
              lineHeight: 24,
              letterSpacing: 1.1,
              color: constants?.color?.orange,
              fontWeight: '600',
              fontFamily: 'JakartaBold',
              marginTop: 0,
              marginBottom: 0,
            }}
          />
          <BodyText
            text={`${currentHunt?.name}`}
            textStyle={{
              fontSize: 28,
              lineHeight: 28,
              color: '#000',
              fontWeight: '600',
              fontFamily: 'JakartaBold',
              marginTop: 8,
            }}
          />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: 8,
            }}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 14,
                marginTop: 0,
                marginBottom: 0,
              }}>
              <SimpleStarRating
                rating={Math?.max(currentHunt?.star_rating || 0, 3.8)}
                color={constants?.color?.orange}
                size={16}
              />
              <Text
                style={{
                  fontSize: 13,
                  lineHeight: 13,
                  color: '#000',
                  fontFamily: 'Jakarta',
                  marginLeft: 5,
                  marginTop: 3,
                }}>
                ({currentHunt?.total_reviews})
              </Text>
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 14,
              }}>
              <Image
                source={require('src/Images/Icons/clock_circle.png')}
                style={{
                  width: 20,
                  height: 20,
                  resizeMode: 'contain',
                  marginTop: 0,
                  marginBottom: 0,
                }}
              />
              <BodyText
                text={`${0.5 + 0.5 * Math?.round(distance)} Hour${
                  0.5 + 0.5 * Math?.round(distance) == 1 ? '' : 's'
                }`}
                textStyle={{
                  color: '#000',
                  fontFamily: 'Jakarta',
                  fontSize: 13,
                  lineHeight: 13,
                  marginTop: 3,
                  marginBottom: 0,
                  paddingBottom: 0,
                  marginRight: 0,
                  marginLeft: 7,
                }}
              />
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 14,
              }}>
              <Image
                source={require('src/Images/Icons/interactive.png')}
                style={{width: 20, height: 20, resizeMode: 'contain'}}
              />
              <BodyText
                text={
                  Math.round(distance * 10) / 10 +
                  ' mi ' +
                  '(' +
                  Math?.round((distance / 0.62137) * 10) / 10 +
                  ' km) long'
                }
                textStyle={{
                  color: '#000',
                  fontFamily: 'Jakarta',
                  fontSize: 13,
                  lineHeight: 13,
                  marginTop: 3,
                  marginBottom: 0,
                  marginLeft: 6,
                }}
              />
            </View>
          </View>

          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'baseline',
            }}>
            <BodyText
              text={!!showMore ? parsedDescription : shortDescription}
              textStyle={{
                color: '#000',
                fontSize: 15,
                fontFamily: 'Jakarta',
                lineHeight: 21,
              }}
            />
          </View>

          <TouchableOpacity
            onPress={() => setShowMore(!showMore)}
            style={{marginTop: 10}}>
            <Text
              style={{
                fontFamily: 'JakartaBold',
                color: constants.color.gray2,
                fontSize: 12,
                lineHeight: 16,
                position: 'absolute',
                right: 5,
              }}>
              {!!showMore ? 'Show Less' : 'Read More'}
            </Text>
          </TouchableOpacity>

          {featuredLocations.length > 0 && (
            <View
              style={{
                marginTop: 24,
                marginBottom: 0,
              }}>
              <BodyText
                text={'Featured on this hunt'}
                textStyle={{
                  color: '#000',
                  fontSize: 17,
                  fontFamily: 'JakartaBold',
                  fontWeight: '600',
                  lineHeight: 16,
                  marginTop: 0,
                }}
              />
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginHorizontal: 12,
                  marginTop: 20,
                  marginBottom: 0,
                  paddingBottom: 0,
                }}>
                {featuredLocations.map((location, index) => {
                  const featuredImage = location.image;
                  if (!featuredImage) {
                    return <></>;
                  }

                  console.log({featuredImage, location});

                  return (
                    <View key={index} style={{width: '31%'}}>
                      <Image
                        source={{
                          uri: featuredImage,
                        }}
                        style={{
                          width: '100%',
                          height: 100,
                          borderRadius: 10,
                          resizeMode: 'cover',
                        }}
                      />
                      <BodyText
                        text={location.title}
                        textStyle={{
                          color: '#000',
                          textAlign: 'center',
                          fontFamily: 'JakartaBold',
                          fontSize: 12,
                          fontWeight: '600',
                          marginLeft: 0,
                          marginRight: 0,
                        }}
                      />
                    </View>
                  );
                })}
              </View>
            </View>
          )}

          {!!barHunt && !!showMore && (
            <View>
              <BodyText
                text={`What is a Bar Hunt`}
                textStyle={{
                  fontSize: 16,
                  lineHeight: 24,
                  color: constants?.color?.gray3,
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              />
              <BodyText
                text={
                  'A Bar Hunt is a gamified bar crawl that you can play whatever night with your friends. You can also play with others on specified nights. You and your friends will compete to finish challenges in order to fight for the top spot.'
                }
                textStyle={{fontSize: 14, lineHeight: 18}}
              />
            </View>
          )}
        </View>

        {/* map */}
        {hunt_type !== 'inHome' && (
          <View
            style={{
              position: 'relative',
              // borderWidth: 2,
              // borderColor: 'red',
              marginTop: 0,
              backgroundColor: '#F9F7F2',
              paddingLeft: 20,
              paddingRight: 20,
            }}>
            <BodyText
              text={'Hunt starting location'}
              textStyle={{
                color: '#000',
                fontSize: 17,
                fontFamily: 'JakartaBold',
                fontWeight: '600',
                lineHeight: 16,
                marginTop: 0,
                marginLeft: 0,
              }}
            />
            {!isExpanded && (
              <TouchableOpacity
                onPress={() => {
                  setIsExpanded(!isExpanded);
                }}>
                <Image
                  style={{
                    marginTop: 15,
                    height: 180,
                    width: '100%',
                    borderRadius: 10,
                  }}
                  source={{
                    uri: `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/url-https%3A%2F%2Fwww.letsroam.com%2Fassets%2Fimg%2Flocation.png(${lngFloat},${latFloat})/${lngFloat},${latFloat},13.75/400x200?access_token=pk.eyJ1IjoibWZoYXJkaW5nIiwiYSI6ImNqbGlicnQ2MzBkeDgzcm9lbnQxb2dhNGoifQ.oWHnl0D4-eSNX-43SHqK8A&`,
                  }}
                />
              </TouchableOpacity>
            )}
            {!!isExpanded && !!initialRegion && !!Object.keys(initialRegion).length && (
              <MapView
                showsUserLocation
                style={{
                  marginTop: 15,
                  height: 300,
                  width: barHunt ? 300 : undefined,
                }}
                initialRegion={
                  initialRegion || {
                    latitude: 0,
                    longitude: 0,
                    latitudeDelta: 1,
                    longitudeDelta: 1,
                  }
                }>
                <MapMarker
                  key={`huntMarker_${lat}${Math?.random() * Date.now()}`}
                  lat={lat || 0}
                  long={long || 0}
                  showMarker={true}
                  setSelectedHuntId={handlePress}
                />
              </MapView>
            )}
            {Platform.OS != 'web' && (
              <ExpandFullscreenButton
                isFullscreen={isExpanded}
                rightDistanceMinimized={30}
                dontAddInsets={true}
                handlePress={() => {
                  setIsExpanded(!isExpanded);
                }}
              />
            )}
          </View>
        )}

        <View style={{paddingLeft: 12, paddingRight: 12}}>
          <ContinueButton
            text={'Start creating group'}
            height={50}
            textStyle={{
              fontFamily: 'JakartaBold',
              fontSize: 15,
            }}
            containerStyle={{
              marginTop: 24,
              borderRadius: 6,
              width: '100%',
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 0,
            }}
            handlePress={() => {
              handleStartPress();
            }}
          />
          <ContinueButton
            text={'Join Friends Group'}
            height={50}
            textStyle={{
              fontFamily: 'JakartaBold',
              fontSize: 15,
              color: constants?.color?.orange,
            }}
            containerStyle={{
              marginTop: 12,
              borderRadius: 6,
              width: '100%',
              marginLeft: 0,
              marginRight: 0,
              backgroundColor: 'transparent',
              borderWidth: 1,
              borderColor: constants?.color?.orange,
            }}
            handlePress={() => {
              handleJoinPress();
            }}
          />
        </View>

        <DelayedLoad>
          <View style={{width: '100%', minHeight: 300}}>
            {playTestState == false &&
              specialEvents.map((event, index) => (
                <ActivityCard
                  key={index}
                  imgSource={event.image}
                  title={event.title}
                  description={event.description}
                  handlePress={event.handlePress}
                  cardWidth={width}
                  showReviews={false}
                  wrap={true}
                />
              ))}
          </View>
        </DelayedLoad>
      </DelayedLoad>
    </View>
  );
};

export default ScavengerHuntInfoAbout;

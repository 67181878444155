import constants from 'src/constants';
import React from 'react';
import {View, ViewStyle} from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';

interface StarsProps {
  rating?: number;
  size?: number;
  color?: string;
  containerStyle?: ViewStyle;
}

const SimpleStarRating: React.FC<StarsProps> = ({
  rating = 1,
  size = 20,
  color = constants?.color?.yellow,
  containerStyle = {},
}) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.3;

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        ...containerStyle,
      }}>
      {Array.from({length: fullStars}).map((_, index) => (
        <FontAwesome
          color={color}
          size={size}
          style={{margin: 2}}
          key={index}
          name={'star'}
        />
      ))}
      {!!hasHalfStar && (
        <FontAwesome
          color={color}
          size={size}
          style={{margin: 2}}
          name={'star-half'}
        />
      )}
    </View>
  );
};

export default SimpleStarRating;

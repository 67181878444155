import React from 'react';
import {
  Image,
  TouchableOpacity,
  View,
  ImageBackground,
  ViewStyle,
  TextStyle,
  ImageSourcePropType,
  ImageStyle,
  Text,
} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import Icon from 'react-native-vector-icons/FontAwesome';
import ExpoImage from 'src/Utils/ExpoImage';

import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {BodyText} from 'src/Modules/CommonView/Text';
import constants from 'src/constants';
import {ImageResizeMode} from 'react-native';
import {DistanceAway} from 'src/ScavengerHunt_v2/Game/DistanceAway';

import useTypedSelector from 'src/Hooks/useTypedSelector';
import {dispatchAction} from 'src/Utils/helpers';
import {markDailyChallengeCompleted} from 'src/Redux/reducers/dailyChallenges.reducer';
import {addChallengeCoins} from 'src/Utils/huntFunctions';
import {
  apiMarkDailyChallengeCompleted,
  apiScheduleNextDayDailyChallengeNotification,
  apiUpdateUserBadges,
} from 'src/Utils/apiCalls';
import {TChallenge} from 'src/types/TChallenge';
import {useUserId} from 'src/Hooks/reduxHooks';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {addLocationToGuide} from 'src/Local/localFunctions';

interface LocationCardProps {
  title?: string;
  description?: string;
  leftText?: string;
  rightText?: string;
  buttonText?: string;
  color?: string;
  handlePress?: () => void;
  containerStyle?: ViewStyle;
  imageSource?: ImageSourcePropType;
  showOverlay?: boolean;
  overlayText?: string;
}

export const LocationCard: React.FC<LocationCardProps> = ({
  title,
  description,
  leftText,
  rightText,
  buttonText,
  color = constants?.color?.orange,
  handlePress,
  containerStyle = {},
  imageSource,
  showOverlay,
  overlayText,
}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <TouchableOpacity
      style={{
        backgroundColor: constants?.color?.white,
        borderRadius: 10,
        marginHorizontal: 10,
        marginVertical: 5,
        minHeight: 200,
        position: 'relative',
        width: width - 20,
        ...containerStyle,
      }}
      activeOpacity={0.8}
      disabled={!handlePress}
      onPress={handlePress}>
      {!!imageSource && (
        <View
          style={{
            position: 'relative',
            width: width - 40,
            marginHorizontal: 10,
            marginTop: 10,
            height: 140,
          }}>
          <ExpoImage
            style={{width: width - 40, height: 140}}
            resizeMode={'cover'}
            source={imageSource}
          />
          {!!showOverlay && (
            <View
              style={{
                ...constants?.style.fillAbsolute,
                backgroundColor: constants?.color?.black_30,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {!!overlayText && (
                <BodyText
                  text={overlayText}
                  textStyle={{color: 'white', marginTop: 5}}
                />
              )}
            </View>
          )}
        </View>
      )}

      <View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}>
          {!!title && (
            <BodyText
              text={title}
              textStyle={{
                marginVertical: 0,
                color: constants?.color?.orange,
                fontSize: 14,
                fontWeight: '700',
              }}
            />
          )}
          {!!buttonText && (
            <View
              style={{
                alignItems: 'center',
                backgroundColor: color,
                borderRadius: 10,
                marginVertical: 5,
                marginRight: 10,
                minWidth: 100,
              }}>
              <BodyText
                text={buttonText}
                textStyle={{
                  borderRadius: 10,
                  color: constants?.color?.white,
                  fontSize: 12,
                  fontWeight: '700',
                  marginBottom: 3,
                  marginTop: 2,
                }}
              />
            </View>
          )}
        </View>
        {!!description && (
          <BodyText
            text={
              description?.length > 60
                ? `${description.substring(0, 160)}...`
                : description
            }
            textStyle={{
              marginTop: 5,
              marginBottom: 10,
              fontSize: 14,
            }}
          />
        )}
      </View>
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        {!!leftText && (
          <BodyText
            text={leftText}
            textStyle={{
              marginVertical: 5,
              fontSize: 14,
              color: constants?.color?.gray2,
            }}
          />
        )}
        {!!rightText && (
          <BodyText
            text={rightText}
            textStyle={{
              marginVertical: 5,
              fontSize: 14,
              color: constants?.color?.gray2,
            }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

interface PassportHeaderProps {
  title: string;
  subTitle: string;
  imageSource: ImageSourcePropType;
  headerHeight?: number;
}

export const PassportHeader: React.FC<PassportHeaderProps> = ({
  title,
  subTitle,
  imageSource,
  headerHeight = 160,
}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <View style={{position: 'relative'}}>
      <ExpoImage
        style={{width, height: headerHeight, position: 'absolute'}}
        source={imageSource}
        resizeMode={'cover'}
      />
      <View
        style={{
          width,
          height: headerHeight,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: constants?.color?.black_30,
        }}>
        <View
          style={{
            borderBottomWidth: 2,
            borderBottomColor: constants?.color?.orange,
            paddingBottom: 5,
            marginHorizontal: 40,
          }}>
          <BodyText
            text={title}
            textStyle={{
              color: constants?.color?.white,
              textAlign: 'center',
              fontSize: 20,
              lineHeight: 22,
              fontWeight: '700',
            }}
          />
        </View>
        <BodyText
          text={subTitle}
          textStyle={{
            color: constants?.color?.white,
            fontSize: 12,
            marginTop: 5,
          }}
        />
      </View>
    </View>
  );
};

interface DealCardProps {
  title?: string;
  titleStyle?: TextStyle;
  topTitle?: string;
  description?: string;
  buttonText?: string;
  color?: string;
  handlePress?: () => void;
  containerStyle?: ViewStyle;
  imageSource?: ImageSourcePropType;
  showOverlay?: boolean;
  overlayText?: string;
  minHeight?: number;
  allowLongText?: boolean;
  resizeMode?: ImageResizeMode;
  overlayTextStyle?: TextStyle;
  overlayTextContainerStyle?: ViewStyle;
  imageContainerStyle?: ViewStyle;
  imageStyle?: ImageStyle;
  testID?: string;
}

export const DealCard: React.FC<DealCardProps> = ({
  title,
  titleStyle = {},
  topTitle,
  description,
  buttonText,
  color,
  handlePress,
  containerStyle = {},
  imageSource,
  showOverlay,
  overlayText,
  minHeight = 200,
  allowLongText = false,
  resizeMode = 'cover',
  overlayTextStyle = {},
  overlayTextContainerStyle = {},
  imageContainerStyle = {},
  imageStyle = {},
  testID = 'dealCard',
}) => {
  const {width} = useWindowDimensionsSafe();

  return (
    <TouchableOpacity
      style={{
        backgroundColor: constants?.color?.white,
        borderRadius: 10,
        marginHorizontal: 10,
        marginVertical: 5,
        minHeight,
        position: 'relative',
        width: width - 20,
        ...containerStyle,
      }}
      testID={testID}
      activeOpacity={0.8}
      disabled={!handlePress}
      onPress={handlePress}>
      {!!topTitle && (
        <BodyText
          text={topTitle}
          textStyle={{
            marginTop: 15,
            marginLeft: 10,
            fontWeight: '700',
          }}
        />
      )}
      {!!imageSource && (
        <View
          style={{
            position: 'relative',
            width: width - 40,
            marginHorizontal: 10,
            marginTop: 10,
            height: 140,
            backgroundColor: constants?.color?.white1,
            ...imageContainerStyle,
          }}>
          <ExpoImage
            style={{width: width - 40, height: 140, ...imageStyle}}
            resizeMode={resizeMode || 'contain'}
            source={imageSource}
          />
          {!!showOverlay && (
            <View
              style={{
                ...constants?.style.fillAbsolute,
                backgroundColor: constants?.color?.black_30,
                justifyContent: 'center',
                alignItems: 'center',
                ...overlayTextContainerStyle,
              }}>
              {!!overlayText && (
                <BodyText
                  text={overlayText}
                  textStyle={{
                    color: 'white',
                    marginTop: 5,
                    ...overlayTextStyle,
                  }}
                />
              )}
            </View>
          )}
        </View>
      )}

      <View>
        {(!!title || !!buttonText) && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            {!!title && (
              <BodyText
                text={title}
                textStyle={{
                  marginVertical: 0,
                  marginLeft: 10,
                  color: constants?.color?.orange,
                  fontSize: 14,
                  fontWeight: '700',
                  marginRight: 5,
                  maxWidth: width - 150,
                  ...titleStyle,
                }}
              />
            )}
            {!!buttonText && (
              <View
                style={{
                  alignItems: 'center',
                  backgroundColor: color,
                  borderRadius: 10,
                  marginVertical: 5,
                  marginRight: 10,
                  minWidth: 100,
                }}>
                <BodyText
                  text={buttonText}
                  textStyle={{
                    borderRadius: 10,
                    color: constants?.color?.white,
                    fontSize: 12,
                    fontWeight: '700',
                    marginBottom: 3,
                    marginTop: 2,
                  }}
                />
              </View>
            )}
          </View>
        )}
        {!!description && (
          <BodyText
            text={
              description?.length > 60 && !allowLongText
                ? `${description.substring(0, 160)}...`
                : description
            }
            textStyle={{
              marginTop: 5,
              marginLeft: 10,
              marginBottom: 10,
              fontSize: 14,
            }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

interface ChallengeCardProps {
  title?: string;
  icon?: ImageSourcePropType;
  description?: string;
  iconText?: string;
  completedText?: string;
  iconStyle?: ImageStyle;
  handlePress?: () => void;
  color?: string;
  completed?: boolean;
  disabled?: boolean;
  testID?: string;
}

export const ChallengeCard: React.FC<ChallengeCardProps> = ({
  title,
  icon,
  description,
  iconText,
  completedText,
  iconStyle = {},
  handlePress,
  color = constants?.color?.blue,
  completed,
  disabled,
  testID,
}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <TouchableOpacity
      style={{
        ...constants?.style.boxShadow,
        backgroundColor: completed ? constants?.color?.teal : color,
        minHeight: 80,
        borderRadius: 10,
        marginHorizontal: 10,
        marginVertical: 5,
        width: width - 20,
        flexDirection: 'row',
      }}
      testID={testID}
      activeOpacity={0.8}
      onPress={handlePress}
      disabled={!!disabled}>
      <View style={{flex: 1, justifyContent: 'space-between'}}>
        <BodyText
          text={!!completed ? `${title} - Completed` : title}
          textStyle={{
            color: constants?.color?.white,
            textAlign: 'left',
            marginTop: 10,
            marginLeft: 10,
            fontWeight: '700',
          }}
        />
        <BodyText
          text={description}
          textStyle={{
            color: constants?.color?.white,
            textAlign: 'left',
            marginBottom: 10,
            marginLeft: 10,
            fontStyle: 'italic',
          }}
        />
      </View>

      <View
        style={{
          alignItems: 'center',
          backgroundColor: constants?.color?.white1,
          justifyContent: 'center',
          width: 80,
        }}>
        {!!icon && (
          <ExpoImage
            resizeMode={'contain'}
            source={icon}
            style={{width: 50, height: 50, ...iconStyle}}
          />
        )}
        {!completed && (
          <BodyText
            text={iconText}
            textStyle={{marginTop: 0, marginHorizontal: 0}}
          />
        )}
        {!!completed && !!completedText && (
          <BodyText
            text={completedText}
            textStyle={{marginTop: 0, marginHorizontal: 0}}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export const DailyChallengesSection: React.FC = () => {
  const dailyChallenges =
    useTypedSelector((state) => state.dailyChallenges?.challenges) || [];

  // checking if any of the daily challenges have been compeleted
  let dailyChallengeComplete = false;

  for (const challenge of dailyChallenges) {
    if (challenge.completed) {
      dailyChallengeComplete = true;
      break;
    }
  }

  const userId = useUserId();

  const handleChallengePress = async (challenge: TChallenge) => {
    console.log('handleChallengePress', {challenge});
    if (!userId) {
      return navigationRef.navigate(
        'LoginModal',
        {
          message: 'You must log in to do daily challenges',
        },
        'HomeActivityList.tsx',
      );
    }

    if (challenge?.challenge_medium == 'local_submit') {
      return addLocationToGuide();
    }

    navigationRef.navigate(
      'PhotoVideoCamera',
      {
        feedbackUnits: 'Coins',
        feedbackPoints: 10,
        text: challenge?.task,
        onScore: async (data) => {
          console.log('scoring daily challenge');
          return await markDailyCompleted(
            challenge,
            data?.downloadURL,
            data?.shareUUID,
          );
        },
        onFailScore: (data) => {
          const photo_grade = data?.photo_grade || {};
          const lr_app_challenge_completion_id =
            data?.lr_app_challenge_completion_id || 0;

          const feedback_title =
            photo_grade?.feedback_title || 'Close but no Cigar!!!!';
          const feedback_body =
            photo_grade?.feedback_body ||
            'We were not able to detect the required elements in the image!';

          navigationRef.navigate(
            'ConfirmModal',
            {
              handleConfirm: async () => {
                navigationRef.goBack('');
                navigationRef.goBack('');
                handleChallengePress(challenge);
              },
              confirmText: 'Try Again',
              cancelText: 'Report Issue',
              handleCancel: () => {
                navigationRef.goBack('');
                navigationRef.goBack('');
                navigationRef.navigate(
                  'QuestionFeedBackModal',
                  {
                    challenge,
                    lr_app_challenge_completion_id: String(
                      lr_app_challenge_completion_id,
                    ),
                    type: 'daily_challenge',
                    userId,
                    challenged_by_user: true,
                  },
                  'HomeActivityList.tsx',
                );
              },
              thirdOption: 'Cancel',
              handleThird: () => {
                navigationRef.goBack('');
              },
              confirmColor: constants?.color?.orange,
              cancelColor: constants?.color?.blue,
              titleText: feedback_title,
              titleBodyText: feedback_body,
              titleImage: require('src/Images/sadTrophy.png'),
            },
            'HomeActivityList.tsx',
          );
        },
        onCapture: (data) => {
          return console.log('Done!', {data});
        },
        onReport: () => {
          navigationRef.navigate(
            'QuestionFeedBackModal',
            {
              challenge,
              type: 'daily_challenge',
            },
            'HomeActivityList.tsx',
          );
        },
        photoUrl: challenge?.photo_url,
        shareUUID: challenge.shareUUID,
        isVideo: challenge?.type === 'video',
      },
      'HomeActivityList.tsx',
    );
  };

  const markDailyCompleted = async (
    challenge: TChallenge,
    photo_url: string,
    shareUUID: string,
  ) => {
    console.log({challenge});

    if (userId && !challenge?.completed) {
      const markedComplete = await apiMarkDailyChallengeCompleted({
        userId,
        scheduled_id: challenge.scheduled_id,
        photo_url,
        shareUUID,
      });

      console.log({markedComplete});

      apiUpdateUserBadges();
      // failing the question
      if (!markedComplete?.photo_grade?.is_correct) {
        return markedComplete;
      } else {
        markedComplete.response === 'success' &&
          dispatchAction(
            markDailyChallengeCompleted({challenge, photo_url, shareUUID}),
          );
        markedComplete.response === 'success' &&
          addChallengeCoins(userId, challenge?.coin_value || 10);

        //getting new earned badges if neccassary

        if (
          dailyChallenges.every((chal) => {
            chal.completed || chal.challenge_id === challenge?.challenge_id;
          })
        ) {
          apiScheduleNextDayDailyChallengeNotification(userId);
        }
        return markedComplete;
      }
    }
  };

  const dailyChallenge = dailyChallenges[0];

  if (!dailyChallenge) {
    return <></>;
  }

  return (
    <DailyChallengeCard
      bgColor={constants?.color?.orange}
      title={
        (dailyChallengeComplete
          ? 'Challenge Completed: '
          : 'Daily Community Challenge: ') + (dailyChallenge?.name ?? '')
      }
      isComplete={dailyChallengeComplete}
      completionPhotoUrl={dailyChallenge?.photo_url || undefined}
      description={dailyChallenge?.task}
      cardImage={require('src/Images/LetsRoamPlus/Ellipse.png')}
      showUsers={true}
      calloutText={`+${dailyChallenge?.coin_value?.toString()}`}
      handlePress={() => handleChallengePress(dailyChallenge)}
    />
  );
};

interface DailyChallengeCardProps {
  bgColor?: string;
  title?: string;
  description?: string;
  cardImage?: ImageSourcePropType;
  calloutImage?: ImageSourcePropType;
  calloutText?: string;
  showUsers?: boolean;
  showBanner?: boolean;
  handlePress?: () => void;
  calloutImageStyle?: ImageStyle;
  isComplete?: boolean;
  completionPhotoUrl?: string;
}

export const DailyChallengeCard: React.FC<DailyChallengeCardProps> = ({
  bgColor,
  title,
  description,
  cardImage,
  showUsers = false,
  calloutImage,
  calloutText,
  handlePress,
  calloutImageStyle = {},
  showBanner = false,
  isComplete = false,
  completionPhotoUrl,
}) => {
  const {width} = useWindowDimensionsSafe();

  const playTest = useTypedSelector((state) => state.app_info.playTestState);

  if (playTest !== false) {
    return <></>;
  }

  return (
    <TouchableOpacity
      style={{
        maxWidth: width,
        paddingHorizontal: 24,
        marginTop: 20,
      }}
      onPress={handlePress}>
      {!!showBanner && (
        <View
          style={{
            backgroundColor: constants?.color?.white,
            borderRadius: 10,
            borderColor: constants?.color?.orange,
            borderWidth: 1,
            maxWidth: 160,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            top: 12,
            left: 20,
            zIndex: 100,
            paddingHorizontal: 12,
            shadowColor: '#000',
            shadowOffset: {width: 0, height: 1},
            shadowOpacity: 0.2,
            shadowRadius: 3,
          }}>
          <Image
            source={require('src/Images/LetsRoamPlus/open_lock.png')}
            style={{width: 16, height: 16, resizeMode: 'contain'}}
          />
          <BodyText
            text={'UNLIMITED ACCESS'}
            textStyle={{
              fontFamily: 'JakartaBold',
              fontSize: 10,
              lineHeight: 23,
              color: constants?.color?.orange,
              marginTop: 0,
              marginBottom: 0,
              paddingVertical: 6,
              marginLeft: 10,
              marginRight: 0,
            }}
          />
        </View>
      )}
      <ImageBackground
        source={require('src/Images/stylized_bg.png')}
        resizeMode="stretch"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
          marginBottom: 0,
          backgroundColor: bgColor,
          borderRadius: 8,
          padding: 24,
          shadowColor: '#000',
          shadowOffset: {width: 0, height: 1},
          shadowOpacity: 0.2,
          shadowRadius: 3,
        }}>
        {!!cardImage && (
          <ImageBackground
            source={cardImage}
            resizeMode="contain"
            style={{
              width: 66,
              height: 66,
              marginLeft: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {!!calloutText && !completionPhotoUrl ? (
              <BodyText
                text={calloutText}
                textStyle={{
                  color: 'white',
                  lineHeight: 26,
                  marginTop: 0,
                  fontSize: 20,
                  fontFamily: 'JakartaBold',
                  textAlign: 'center',
                }}
              />
            ) : (
              (!!calloutImage || !!completionPhotoUrl) && (
                <ExpoImage
                  source={calloutImage || {uri: completionPhotoUrl}}
                  style={{
                    width: completionPhotoUrl ? 66 : 24,
                    height: completionPhotoUrl ? 66 : 24,
                    borderRadius: completionPhotoUrl ? 33 : 0,
                    ...calloutImageStyle,
                  }}
                />
              )
            )}
          </ImageBackground>
        )}
        <View>
          <BodyText
            text={title}
            textStyle={{
              color: 'white',
              lineHeight: 20,
              marginLeft: 0,
              marginRight: 0,
              maxWidth: width - 150,
              marginTop: 0,
              fontSize: isComplete ? 15 : 17,
              fontFamily: 'JakartaBold',
              paddingLeft: 10,
            }}
          />
          <BodyText
            text={description}
            textStyle={{
              color: 'white',
              marginTop: 8,
              marginLeft: 0,
              paddingLeft: 10,
              fontSize: 14,
              fontFamily: 'Jakarta',
              maxWidth: 250,
            }}
          />

          {!!showUsers && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <CircleOverlapImages />
              <BodyText
                text={'300+ Members completed'}
                textStyle={{
                  color: 'white',
                  opacity: 0.56,
                  marginLeft: 10,
                  fontSize: 11,
                  fontFamily: 'JakartaBold',
                }}
              />
            </View>
          )}
        </View>
      </ImageBackground>
    </TouchableOpacity>
  );
};

interface PhotoTileProps {
  index: number;
  source: string;
  handlePress?: () => void;
}

export const PhotoTile: React.FC<PhotoTileProps> = ({
  index,
  source,
  handlePress,
}) => {
  const {width} = useWindowDimensionsSafe();
  const tileWidth = width - 20;
  return (
    <TouchableOpacity
      testID={`cardView-photoTile-${index}`}
      style={{
        backgroundColor: constants?.color?.white1,
        width: tileWidth,
        height: tileWidth,
        marginVertical: 5,
        marginHorizontal: 10,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      activeOpacity={0.8}
      disabled={!handlePress}
      onPress={handlePress}>
      <CustomActivityIndicator
        size={'small'}
        color={constants?.color?.gray3}
        style={{position: 'absolute'}}
      />
      <Image
        source={{uri: source}}
        style={{
          width: tileWidth,
          height: tileWidth,
        }}
      />
    </TouchableOpacity>
  );
};

interface ClassicChallengeCardProps {
  title?: JSX.Element | string;
  titleStyle?: TextStyle;
  text?: JSX.Element | string;
  secondaryText?: JSX.Element | string;
  icon?: string;
  iconImage?: ImageSourcePropType | null;
  label?: string;
  subLabel?: string;
  backgroundColor?: string;
  backgroundUrl?: string;
  handlePress?: () => void;
  completed?: boolean;
  completedLabel?: string;
  completedIcon?: string;
  completedIconColor?: string;
  containerStyle?: ViewStyle;
  testID?: string;
  renderEmojiChallenge?: boolean;
  lat?: number;
  long?: number;
  isPlayerChallenge?: boolean;
}

export const ClassicChallengeCard: React.FC<ClassicChallengeCardProps> = ({
  title = '',
  titleStyle = {},
  text = '',
  secondaryText = '',
  icon,
  iconImage,
  label,
  subLabel,
  backgroundColor,
  backgroundUrl,
  handlePress,
  completed,
  completedLabel,
  completedIcon,
  completedIconColor,
  containerStyle = {},
  testID,
  renderEmojiChallenge = false,
  lat,
  long,
  isPlayerChallenge = false,
}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <TouchableOpacity
      testID={testID}
      activeOpacity={0.9}
      style={{
        ...constants?.style.boxShadow,
        backgroundColor: constants?.color?.white,
        minHeight: 80,
        borderRadius: 13,
        marginHorizontal: 10,
        marginVertical: 5,
        width: width - 20,
        flexDirection: 'row',
        position: 'relative',
        ...containerStyle,
      }}
      onPress={handlePress}
      disabled={!handlePress}>
      {!!backgroundUrl && (
        <ExpoImage
          resizeMode={'cover'}
          source={{uri: backgroundUrl}}
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            width: 80,
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
          }}
        />
      )}
      <View
        style={{
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          alignItems: 'center',
          backgroundColor: backgroundUrl
            ? undefined
            : backgroundColor || constants?.color?.gray3,
          justifyContent: 'center',
          width: 80,
          paddingVertical: 7,
        }}>
        {iconImage ? (
          <Image
            source={iconImage} // Render the image if provided
            style={{width: 38, height: 47}}
            resizeMode="contain"
          />
        ) : (
          !!icon && (
            <Icon name={icon} size={28} color={constants?.color?.white} />
          )
        )}
        {!!label && (
          <BodyText
            textStyle={{
              marginTop: 8,
              marginHorizontal: 0,
              color: constants?.color?.white,
            }}>
            {label}
          </BodyText>
        )}

        {!!lat && !!long && !label && !completed && (
          <BodyText
            textStyle={{
              marginTop: 8,
              marginHorizontal: 0,
              color: constants?.color?.white,
            }}>
            <DistanceAway textOnly={true} lat={lat} long={long} />
          </BodyText>
        )}
        {!!subLabel && (
          <BodyText
            textStyle={{
              marginTop: 2,
              marginHorizontal: 0,
              color: constants?.color?.white,
              fontSize: 12,
              lineHeight: 14,
            }}>
            {subLabel}
          </BodyText>
        )}
      </View>
      <View
        style={{
          flex: 1,
          justifyContent: 'space-between',
          paddingVertical: 10,
          position: 'relative',
        }}>
        {!!completedLabel && (
          <View
            style={{position: 'absolute', top: 10, right: completed ? 30 : 5}}>
            <BodyText text={completedLabel} textStyle={{fontWeight: '700'}} />
          </View>
        )}
        {!!completed && (
          <View style={{position: 'absolute', top: 5, right: 5}}>
            <Icon
              name={completedIcon || 'check'}
              size={33}
              color={completedIconColor || constants?.color?.brightgreen}
            />
          </View>
        )}
        {!!title && (
          <BodyText
            textStyle={{
              textAlign: 'left',
              marginTop: 10,
              marginLeft: 10,
              marginRight: 50,
              fontWeight: '700',
              ...titleStyle,
            }}>
            {title}
          </BodyText>
        )}
        {!!text && !!renderEmojiChallenge && (
          <Text
            style={{
              textAlign: 'left',
              marginBottom: 10,
              marginLeft: 10,
              marginRight: 35,
            }}>
            {text}
          </Text>
        )}
        {!!text && !renderEmojiChallenge && (
          <BodyText
            text={text}
            textStyle={{
              textAlign: 'left',
              marginBottom: 10,
              marginLeft: 10,
              marginRight: 35,
            }}
          />
        )}

        {!!secondaryText && !renderEmojiChallenge && (
          <BodyText
            text={secondaryText}
            textStyle={{
              textAlign: 'left',
              marginTop: 0,
              marginLeft: 10,
              marginRight: 35,
            }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

interface CircleOverlapImagesProps {
  images?: string[];
  containerStyle?: ViewStyle;
  width?: number;
  maxImages?: number;
}

export const CircleOverlapImages: React.FC<CircleOverlapImagesProps> = ({
  containerStyle,
  images,
  width = 30,
  maxImages = 4,
}) => {
  const signInImage = require('src/Images/signInImage.png');

  // Create a new array containing at least four images
  const displayedImages = Array.isArray(images) ? [...images] : [];

  while (displayedImages.length < maxImages) {
    displayedImages.push(signInImage);
  }

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 8,
        ...containerStyle,
      }}>
      {displayedImages.slice(0, 4).map((image, index) => {
        return (
          <Image
            key={index}
            source={typeof image === 'string' ? {uri: image} : image}
            style={{
              width: width,
              height: width,
              borderRadius: 17,
              borderWidth: 1,
              borderColor: constants?.color?.white,
              marginLeft: -8.5,
            }}
          />
        );
      })}
    </View>
  );
};

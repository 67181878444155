import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {mmkvStorage} from 'src/Utils/mmkvStorage';

interface FirstVisitState {
  hasPressedBarHunt: boolean;
  hasPressedScavaHunt: boolean;
  hasViewedPassport: boolean;
  hasFinishedTutorial: boolean;
  hasPressedPrizes: boolean;
}

const initialState: FirstVisitState = {
  hasPressedBarHunt: false,
  hasPressedScavaHunt: false,
  hasViewedPassport: false,
  hasFinishedTutorial: false,
  hasPressedPrizes: false,
};

const firstVisitSlice = createSlice({
  name: 'firstVisit',
  initialState,
  reducers: {
    setFirstVisitItems: (
      state,
      action: PayloadAction<{items: Partial<FirstVisitState>}>,
    ) => {
      const newState = {...state, ...action.payload.items};
      mmkvStorage.set('firstVisit', JSON.stringify(newState));
      return newState;
    },
    markItemVisited: (state, action: PayloadAction<string>) => {
      const newState = {...state, [action.payload]: true};
      console.log('markItemVisited', {newState});
      mmkvStorage.set('firstVisit', JSON.stringify(newState));
      return newState;
    },
  },
});

export const {setFirstVisitItems, markItemVisited} = firstVisitSlice.actions;
export default firstVisitSlice.reducer;

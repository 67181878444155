import {Platform} from 'react-native';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {TAppInfoState} from 'src/types/TAppInfoState';

type UTMInfo = {
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  utm_term?: string | null;
};

interface VersionInfoPayload {
  playTestState?: boolean;
  smallGroupVideoId?: string;
  largeGroupVideoId?: string;
  passportShown?: boolean;
  showOnlyExplorerPass?: boolean;
  status?: string;
  hideFacebook?: boolean;
  hideGoogle?: boolean;
  hideText?: boolean;
  hideEmail?: boolean;
  customAdView?: string;
}

const initialState: TAppInfoState = {
  deviceUUID: mmkvStorage.getString('deviceUUID') ?? null,
  customAdView: '',
  utm_source: null,
  utm_medium: null,
  utm_campaign: null,
  utm_term: null,
  playTestState: Platform.OS === 'ios',
  showOnlyExplorerPass: false,
  expoUpdateAvailable: 0,
  useLocalHost: false,
  hiddenLoginButtons: {},
  hideFacebook: false,
  hideGoogle: false,
  hideText: false,
  hideEmail: false,
};

const appInfoSlice = createSlice({
  name: 'app_info',
  initialState,
  reducers: {
    setDeviceUUID(state, action: PayloadAction<string>) {
      state.deviceUUID = action.payload;
    },
    setVersionInfo(state, action: {payload: VersionInfoPayload}) {
      // specify a type for payload if known
      console.log('setVersionInfo', action.payload);
      return {
        ...state,
        playTestState: action.payload?.playTestState || false,
        passportShown: action.payload?.passportShown || false,
        showOnlyExplorerPass: action.payload?.showOnlyExplorerPass || false,
        status: action.payload?.status || 'normal',
        hideFacebook: action.payload?.hideFacebook || false,
        hideGoogle: action.payload?.hideGoogle || false,
        hideText: action.payload?.hideText || false,
        hideEmail: action.payload?.hideEmail || false,
      };
    },
    setCustomAdView(state, action: PayloadAction<string>) {
      state.customAdView = action.payload;
    },
    setUTMSource(state, action: PayloadAction<string>) {
      state.utm_source = action.payload;
    },
    setUTMMedium(state, action: PayloadAction<string>) {
      state.utm_medium = action.payload;
    },
    setUTMCampaign(state, action: PayloadAction<string>) {
      state.utm_campaign = action.payload;
    },
    setUTMInfo(state, action: PayloadAction<UTMInfo>) {
      // specify a type for payload if known
      console.log('setUTMInfo', action.payload);
      return {
        ...state,
        utm_source: action.payload.utm_source || null,
        utm_medium: action.payload.utm_medium || null,
        utm_campaign: action.payload.utm_campaign || null,
        utm_term: action.payload.utm_term || null,
      };
    },
    setExpoUpdateAvailable(state, action: PayloadAction<number>) {
      state.expoUpdateAvailable = action.payload;
    },
    setUseLocalHost(state, action: PayloadAction<boolean>) {
      console.log('setUseLocalHost ' + action.payload);
      state.useLocalHost = action.payload;
    },
  },
});

export const {
  setDeviceUUID,
  setVersionInfo,
  setCustomAdView,
  setUTMSource,
  setUTMMedium,
  setUTMCampaign,
  setUTMInfo,
  setExpoUpdateAvailable,
  setUseLocalHost,
} = appInfoSlice.actions;

export default appInfoSlice.reducer;

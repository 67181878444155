import {
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  Linking,
  Platform,
  useWindowDimensions,
} from 'react-native';
import React, {useEffect, useState} from 'react';
import {navigationRef} from 'src/Nav/navigationHelpers';
import ExpoImage from 'src/Utils/ExpoImage';
import constants from 'src/constants';
import {mmkvStorage} from 'src/Utils/mmkvStorage';

const EmailSignInBlockingPage = () => {
  const [email, setEmail] = useState<string | null>('');
  const {width} = useWindowDimensions();

  useEffect(() => {
    const email = mmkvStorage.getString('email');
    if (email) {
      setEmail(email);
    }
  }, []);

  const handleOpenEmailApp = async () => {
    const url = 'mailto:';
    const supported = await Linking.canOpenURL(url);
    if (supported) {
      await Linking.openURL(url);
    } else {
      if (email?.includes('@gmail.com')) {
        await Linking.openURL('https://mail.google.com');
      } else {
        console.log("Can't open email client");
      }
    }
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: constants.color.orange,
      }}>
      <View style={{alignItems: 'center', justifyContent: 'center'}}>
        <ExpoImage
          resizeMode={'contain'}
          source={require('src/Images/computer.png')}
          style={{width: 100, height: 100, position: 'absolute', zIndex: 2}}
        />
        <ExpoImage
          resizeMode={'contain'}
          source={require('src/Images/phoneCheckmark.png')}
          style={{width: 250, height: 350}}
        />
      </View>
      <Text
        style={{
          color: constants.color.white,
          fontSize: 28,
          fontFamily: 'JakartaBold',
        }}>
        Check your email
      </Text>
      <Text
        style={{
          color: constants.color.white,
          fontFamily: 'Jakarta',
          marginTop: 10,
          fontSize: 17,
          paddingHorizontal: 60,
          textAlign: 'center',
        }}>
        {`We sent an email to you at: ${email?.toLowerCase()}. It has a magic link that will sign you in.`}
      </Text>
      {Platform.OS === 'ios' && (
        <TouchableOpacity
          onPress={handleOpenEmailApp}
          style={{
            flexDirection: 'row',
            width: width - 60,
            backgroundColor: constants.color.white,
            alignItems: 'center',
            justifyContent: 'center',
            gap: 5,
            padding: 8,
            borderRadius: 5,
            marginTop: 24,
            paddingVertical: 20,
          }}>
          <Text
            style={{
              color: constants.color.orange,
              fontSize: 17,
              fontWeight: '600',
            }}>
            Open email app
          </Text>
        </TouchableOpacity>
      )}
      <TouchableOpacity
        onPress={() =>
          navigationRef.navigate(
            'LoginModal',
            {
              message: 'You must log in to see your tickets',
            },
            '',
          )
        }
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 5,
          marginTop: 24,
        }}>
        <ExpoImage
          resizeMode={'contain'}
          source={require('src/Images/BackButton.png')}
          style={{width: 20, height: 10}}
        />
        <Text
          style={{
            color: constants.color.white,
            fontSize: 15,
          }}>
          Use another login method
        </Text>
      </TouchableOpacity>
    </SafeAreaView>
  );
};

export default EmailSignInBlockingPage;

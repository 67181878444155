import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TBadge} from 'src/types/TBadge';

interface BadgesState {
  badgesEarned: Record<string, TBadge>;
  badgesNext: Record<string, TBadge>;
}

const initialState: BadgesState = {
  badgesEarned: {},
  badgesNext: {},
};

const badgesInfoSlice = createSlice({
  name: 'badges',
  initialState,
  reducers: {
    updateBadgesData: (
      state,
      action: PayloadAction<{
        badgesEarned: Record<string, TBadge>;
        badgesNext: Record<string, TBadge>;
      }>,
    ) => {
      // console.log('updateBadgesData', action.payload);
      return {
        ...state,
        badgesEarned: action.payload.badgesEarned,
        badgesNext: action.payload.badgesNext,
      };
    },
  },
});

export const {updateBadgesData} = badgesInfoSlice.actions;

export default badgesInfoSlice.reducer;

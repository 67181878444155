import {Modal, SafeAreaView} from 'react-native';
import {ContinueButton} from './Buttons';
import ExpoImage from 'src/Utils/ExpoImage';
import {Dispatch, SetStateAction} from 'react';

interface EnlargeImageModalProps {
  isModalVisible: boolean;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  modalImage: string;
}

const EnlargeImageModal: React.FC<EnlargeImageModalProps> = ({
  isModalVisible,
  modalImage,
  setIsModalVisible,
}) => {
  return (
    <Modal visible={isModalVisible}>
      <SafeAreaView style={{flex: 1}}>
        <ExpoImage
          style={{flex: 1}}
          resizeMode="contain"
          source={{uri: modalImage}}></ExpoImage>
        <ContinueButton
          handlePress={() => setIsModalVisible(false)}
          text="Close"></ContinueButton>
      </SafeAreaView>
    </Modal>
  );
};

export default EnlargeImageModal;

import constants from 'src/constants';
import React from 'react';
import {Alert, Image, TextStyle, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {apiFlagPhotos} from 'src/Utils/apiCalls';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

/////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
////////////  NOT USING ExpoImage DUE TO CRASHING MEMORY ISSUES ON IOS
/////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
import {navigationRef} from 'src/Nav/navigationHelpers';
import {BodyText} from 'src/Modules/CommonView';
import Video from 'src/Modules/Native/Video';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import {TPhoto} from 'src/types/TPhoto';
import {ResizeMode} from 'expo-av';

const markerIcon = require('src/Images/Icons/mapMarker2.png');

interface PhotoTileProps {
  photo: TPhoto; // Define a more specific type if known
  handlePress: () => void; // Assuming it's a function without arguments. Adjust if needed.
  index: number;
  showFlag?: boolean;
  onReport?: Function;
  fetchSetTopPhotos: () => void; // Assuming it's a function without arguments. Adjust if needed.
  descriptionOverride?: string; // Assuming it's a string and optional. Adjust if needed.
  descriptionStyle?: TextStyle; // Assuming it's for styling in React and is optional. Adjust if not.
}

export const PhotoTile: React.FC<PhotoTileProps> = ({
  photo,
  handlePress,
  index,
  fetchSetTopPhotos,
  onReport,
  descriptionOverride,
  descriptionStyle = {},
  showFlag = false,
}) => {
  if (!photo) {
    return <View></View>;
  }

  const {width} = useWindowDimensionsSafe();
  const photoUrl =
    photo.thumbnail ||
    photo.share_photo ||
    photo.photoURL ||
    photo.photoUrl ||
    photo.downloadURL ||
    (photo.completion ? photo.completion.photoUrl : null);

  const challengeText =
    photo.challengeText ||
    photo.challenge ||
    photo.question ||
    photo.task ||
    photo.description ||
    ' ';

  const isVideo =
    photo.type === 'video' ||
    photo.type === 'Video' ||
    !!photo.thumbnail ||
    challengeText?.includes('Video') ||
    challengeText?.includes('Capture') ||
    challengeText?.includes('video') ||
    photoUrl?.includes('video');

  let photoDescription = photo.city
    ? `${photo.city}, ${photo.state}`
    : challengeText;
  if (descriptionOverride) {
    photoDescription = descriptionOverride;
  }

  return (
    <TouchableOpacity
      testID={`photoTile-${index}`}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 10,
        marginVertical: 5,
        position: 'relative',
        backgroundColor: constants?.color?.white1,
        width: width - 20,
        height: width - 20,
      }}
      activeOpacity={0.8}
      onPress={handlePress}>
      <CustomActivityIndicator
        size={'small'}
        style={{position: 'absolute'}}
        color={constants?.color?.gray3}
      />

      {!isVideo && !!photoUrl && (
        <Image
          style={{width: width - 20, height: width - 20}}
          resizeMode={'cover'}
          source={{uri: cloudFlareOptimizeImage(photoUrl)}}
        />
      )}

      {!!isVideo && !!photoUrl && (
        <Video
          style={{width: width - 20, height: width - 20}}
          resizeMode={ResizeMode.COVER}
          source={{uri: photoUrl}}
          shouldPlay
          isLooping
        />
      )}

      <View
        style={{
          ...constants?.style.fillAbsolute,
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
        }}>
        <View
          style={{
            flexDirection: 'row',
            marginHorizontal: 15,
            alignItems: 'center',
            marginBottom: 5,
          }}>
          <Image
            source={markerIcon}
            resizeMode={'contain'}
            style={{width: 18, height: 25}}
          />
          <BodyText
            text={photoDescription}
            textStyle={{
              color: constants?.color?.white,
              textAlign: 'left',
              marginBottom: 5,
              marginLeft: 5,
              ...descriptionStyle,
            }}
          />
        </View>
      </View>
      {!!showFlag && (
        <TouchableOpacity
          // @ts-ignore
          onPress={
            onReport ||
            (async () => {
              // pulling the group in indirectly so we do not have to rely on computationally
              // expensive groups

              navigationRef.navigate(
                'ConfirmModal',
                {
                  titleText: `Are you 100% Sure You Want To Flag This Photo`,
                  confirmText: 'Yes, Flag for Removal!',
                  cancelText: 'Cancel',
                  handleConfirm: () => {
                    console.log({photo});
                    if (!photo.id) {
                      console.error('No photo id');
                      return;
                    }
                    apiFlagPhotos({photoId: photo.id});
                    navigationRef?.goBack('PhotoTile.tsx');
                    navigationRef?.goBack('PhotoTile.tsx');
                    fetchSetTopPhotos();
                    Alert.alert(
                      'This Photo Was Flagged For Removal',
                      'We apologize for potentially inappropriate content. Thank you for helping to keep our community clean. The photo will be gone when you fully close and reopen the app.',
                    );
                  },
                },
                '',
              );
            })
          }
          style={{
            ...{
              position: 'absolute',
              top: 0,
              padding: 10,
              right: 5,
              with: 40,
              height: 50,
              zIndex: 100,
              pointerEvents: 'auto',
            },
          }}>
          <Icon
            name={'flag'}
            size={30}
            color={constants?.color?.teal_80}
            // style={{ marginHorizontal: 7 }}
          />
        </TouchableOpacity>
      )}
    </TouchableOpacity>
  );
};

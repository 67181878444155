import constants from 'src/constants';
import React from 'react';
import {View, Vibration, Linking} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

///////////////////////////
///////////// components
import {ContinueButton} from 'src/Modules/CommonView';

///////////////////////////
///////////// utils
import {
  markSelectionChallengeComplete,
  markSelectionChallengeCompleteParams,
} from 'src/Utils/huntFunctions_v2';
import {useChallenge} from 'src/Hooks/gameHooks';
import {useUserId} from 'src/Hooks/reduxHooks';
import {TChallengeAnswers} from 'src/types/TChallenge';
import {updateDataAtPath} from 'src/Utils/fireUtils';
import {getReduxState} from 'src/Utils/helpers';

interface TextMultiChoiceProps {
  noTimer: boolean;
  completed?: boolean;
  singleCompletion?: boolean;
  onComplete: () => void;
  allowMultiGuess: boolean;
}

const TextMultiChoice: React.FC<TextMultiChoiceProps> = ({
  noTimer,
  completed = false,
  singleCompletion = false,
  onComplete,
  allowMultiGuess,
}) => {
  const userId = useUserId();
  const challenge = useChallenge();

  const handleAnswerPress = (answer: TChallengeAnswers) => {
    console.log('TextMultiChoice handleAnswerPress fired: ', {answer});

    if (!challenge?.challengeId) return console.error('no challenge found');

    const {challengeId} = challenge;

    const pressData: markSelectionChallengeCompleteParams = {
      ...answer,
      challengeId,
      singleCompletion,
      allowMultiGuess,
    };

    let previousAttempts = 0;
    const {answers = [], points = 100} = challenge;
    answers?.map?.((answer) => {
      if (answer.selectedAt) {
        previousAttempts++;
      }
    });
    console.log(answers);

    if (!answer.correct) {
      const hintsDeductions =
        ((points || 100) * (previousAttempts + 1)) / (answers?.length || 1);

      const groupId = getReduxState((state) => state.group?.info?.groupId);

      // Alert.alert(hintsDeductions + '', points + '');

      updateDataAtPath(
        `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/hintsDeductions`,
        hintsDeductions,
      );
    }

    // return console.log('a multi choice answer was pressed', { pressData });

    markSelectionChallengeComplete(pressData);

    if (!answer.correct) {
      Vibration.vibrate(200);
    }
    if (!!answer.correct) {
      //Vibration.vibrate([0, 100, 400, 100]);
    }

    if (answer.correct || !allowMultiGuess) {
      onComplete && onComplete?.();
    }
  };

  if (!challenge) return <></>;

  const {answers, completions = {}, challengeId} = challenge;
  let userHasCompleted =
    userId && completions[userId] && !noTimer ? true : false;
  if (completed) {
    userHasCompleted = true;
  }
  return (
    <View
      style={{
        justifyContent: 'center',
      }}>
      {!userHasCompleted && (
        <View style={{marginTop: 20}}>
          {!!answers &&
            answers?.map?.((answer, index) => {
              return (
                <View
                  style={{height: 50, position: 'relative'}}
                  key={`chal_${challengeId}_${index}`}>
                  <ContinueButton
                    text={
                      !answer.selectedAt
                        ? 'Complete Challenge At Link'
                        : 'Challenge Completed'
                    }
                    containerStyle={{flex: 1, marginVertical: 5}}
                    background={
                      answer.selectedAt
                        ? constants?.color?.gray3
                        : constants?.color?.blue
                    }
                    handlePress={() => {
                      answer.option && Linking?.openURL(answer.option);
                      setTimeout(() => {
                        if (answer.selectedAt) {
                          return console.log('already done');
                        } else if (handleAnswerPress) {
                          handleAnswerPress(answer);
                        } else {
                          console.error('no handleAnswerPress');
                        }
                      }, 1000);
                    }}
                  />
                  {!!noTimer && !!answer.selectedAt && !answer.correct && (
                    <View style={{position: 'absolute', top: 10, right: 15}}>
                      <Icon
                        name={'close'}
                        size={30}
                        color={constants?.color?.red}
                      />
                    </View>
                  )}
                </View>
              );
            })}
        </View>
      )}
      {!!userHasCompleted && (
        <View style={{marginTop: 20}}>
          <ContinueButton
            handlePress={() => {
              console.log('user has completed this challenge');
            }}
            text={
              !!userId && !!completions[userId] && !!completions[userId].option
                ? completions[userId].option || 'Challenge Completed'
                : 'Challenge Completed'
            }
            containerStyle={{flex: 1, marginVertical: 5}}
            background={constants?.color?.orange}
          />
        </View>
      )}
    </View>
  );
};

export default TextMultiChoice;

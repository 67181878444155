import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {View, Text, TouchableHighlight, TextStyle} from 'react-native';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import Icon from 'react-native-vector-icons/SimpleLineIcons';
import FeatherIcon from 'react-native-vector-icons/Feather';
import Ionicon from 'react-native-vector-icons/Ionicons';
import EntypoIcon from 'react-native-vector-icons/Entypo';
import isIphoneX from 'src/Utils/IphoneXHelper';

const isX = isIphoneX();

interface BottomTabProps {
  children: React.ReactNode;
  height?: number;
  tabStyle?: object;
}

export const BottomTab: React.FC<BottomTabProps> = ({
  children,
  height = isX ? 75 : 65,
  tabStyle = {},
}) => (
  <View
    style={{
      ...constants?.style.navigationHeader,
      shadowOffset: {
        height: -3,
        width: 0,
      },
      height: height,
      justifyContent: 'space-around',
      flexDirection: 'row',
      paddingTop: 3,
      alignItems: 'flex-start',
      position: 'relative',
      ...tabStyle,
    }}>
    {children}
  </View>
);

interface BottomTabButtonProps {
  text: string;
  iconName: string;
  handlePress?: () => void;
  badge?: number | string;
  active?: boolean;
  buttonStyle?: object;
  iconPack?:
    | 'FontAwesome'
    | 'SimpleLineIcons'
    | 'FeatherIcon'
    | 'Ionicons'
    | 'Entypo';
  iconSize?: number;
  activeScreenName?: string;
  activeScreenNames?: string[];
  testID?: string | undefined;
  textStyle?: TextStyle;
}

export const BottomTabButton: React.FC<BottomTabButtonProps> = ({
  text,
  iconName,
  handlePress,
  badge,
  active = false,
  buttonStyle = {},
  iconPack = 'SimpleLineIcons',
  iconSize = 23,
  activeScreenName,
  activeScreenNames,
  testID = undefined,
  textStyle = {},
}) => {
  let isActive = active;
  if (activeScreenName) {
    isActive = useTypedSelector((state) =>
      state.current_screen?.currentScreen?.includes(activeScreenName),
    );
  }
  if (activeScreenNames) {
    isActive = useTypedSelector((state) =>
      activeScreenNames?.includes(state.current_screen?.currentScreen),
    );
  }

  return (
    <TouchableHighlight
      activeOpacity={0.8}
      testID={testID}
      underlayColor={constants?.color?.white1}
      onPress={!!handlePress ? handlePress : undefined}
      style={{
        alignItems: 'center',
        borderRadius: 32,
        height: isX ? 65 : 50,
        justifyContent: 'flex-start',
        minWidth: 80,
        flex: 1,
        marginTop: 5,
        ...buttonStyle,
      }}>
      <View
        style={{
          alignItems: 'center',
          flex: 1,
          justifyContent: 'flex-start',
        }}>
        {!!badge && (
          <View
            style={{
              alignItems: 'center',
              backgroundColor: 'red',
              borderRadius: 5.5,
              height: 11,
              justifyContent: 'center',
              position: 'absolute',
              top: -4,
              right: -4.5,
              width: 11,
            }}>
            {typeof badge === 'number' && (
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: constants?.font.circular,
                  color: constants?.color?.white,
                  fontWeight: '700',
                }}>
                {badge}
              </Text>
            )}
          </View>
        )}
        <View style={{minHeight: iconSize}}>
          {iconPack === 'FontAwesome' && (
            <FontAwesomeIcon
              name={iconName}
              size={iconSize}
              color={
                isActive ? constants?.color?.orange : constants?.color?.gray3
              }
            />
          )}
          {iconPack === 'SimpleLineIcons' && (
            <View nativeID={iconName}>
              <Icon
                name={iconName}
                size={23}
                color={
                  isActive ? constants?.color?.orange : constants?.color?.gray3
                }
              />
            </View>
          )}
          {iconPack === 'FeatherIcon' && (
            <FeatherIcon
              name={iconName}
              size={23}
              color={
                isActive ? constants?.color?.orange : constants?.color?.gray3
              }
            />
          )}
          {iconPack === 'Ionicons' && (
            <Ionicon
              name={iconName}
              size={23}
              color={
                isActive ? constants?.color?.orange : constants?.color?.gray3
              }
            />
          )}
          {iconPack === 'Entypo' && (
            <EntypoIcon
              name={iconName}
              size={23}
              color={
                isActive ? constants?.color?.orange : constants?.color?.gray3
              }
            />
          )}
        </View>
        <Text
          style={{
            fontSize: 9,
            fontWeight: 'bold',
            fontFamily: constants?.font.circular,
            textAlign: 'center',
            marginTop: 5,
            color: isActive
              ? constants?.color?.orange
              : constants?.color?.gray3,
            ...textStyle,
          }}>
          {text}
        </Text>
      </View>
    </TouchableHighlight>
  );
};

export const TabDivider: React.FC = () => (
  <View
    style={{
      height: 40,
      width: 1,
      backgroundColor: constants?.color?.white1,
    }}
  />
);

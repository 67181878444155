import React from 'react';

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';

import {Text, View} from 'react-native';
///////////////////////////

const FoundVouchers: React.FC = () => {
  const vouchers = useTypedSelector(
    (state) => state?.ticket_vouchers?.allVouchers || [],
  );

  if (!!vouchers && vouchers?.length > 0) {
    return <></>;
  }

  return (
    <View style={{width: '100%'}}>
      <Text
        style={{
          marginHorizontal: 20,
          fontSize: 17,
          lineHeight: 16,
          fontFamily: 'JakartaBold',
          marginVertical: 20,
        }}>
        Your tickets & vouchers
      </Text>
      <Text
        style={{
          marginHorizontal: 20,
          fontSize: 15,
          lineHeight: 21,
          fontFamily: 'Jakarta',
          marginBottom: 30,
        }}>
        Use tickets already found in your account. These are linked by your
        email/phone number.
      </Text>
      <View style={{alignItems: 'center', gap: 15, paddingHorizontal: 16}}>
        {vouchers.map((voucher, ix) => (
          <View
            key={'redeemed-voucher' + ix}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: constants?.color?.white,
              opacity: 0.65,
              width: '100%',
              paddingVertical: 20,
              paddingHorizontal: 16,
              borderRadius: 8,
            }}>
            <View style={{gap: 8}}>
              <Text style={{fontFamily: 'JakartaBold', fontSize: 14}}>
                {voucher?.voucherCode?.toUpperCase() || 'VOUCHER'}
              </Text>
              <Text style={{fontFamily: 'Jakarta', fontSize: 12}}>
                Tickets: {voucher?.purchase?.tickets}
              </Text>
              <Text
                style={{
                  fontFamily: 'Jakarta',
                  fontSize: 12,
                  color: constants?.color?.orange,
                }}>
                {voucher?.roleType || ''}
              </Text>
            </View>
            <View style={{alignItems: 'center', justifyContent: 'center'}}>
              <View
                style={{
                  borderRadius: 6,
                  paddingHorizontal: 12,
                  paddingVertical: 8,
                  backgroundColor: constants?.color?.orange,
                }}>
                <Text style={{color: constants?.color?.white, fontSize: 15}}>
                  Redeemed
                </Text>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

//eslint-disable-next-line
export default FoundVouchers;

import constants from 'src/constants';
import React from 'react';
import {View, Text, ViewStyle, TextStyle} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

interface ChallengesHeaderProps {
  text: string;
  color: string;
  fontSize?: number;
  subTitle?: string;
  containterStyle?: ViewStyle;
  textColor?: string;
  textStyle?: TextStyle;
}

const ChallengesHeader: React.FC<ChallengesHeaderProps> = ({
  text,
  color,
  fontSize,
  subTitle,
  containterStyle = {},
  textColor = 'white',
  textStyle = {},
}) => {
  const {width} = useWindowDimensionsSafe();

  return (
    <View
      style={{
        height: width > 355 ? 55 : 35,
        backgroundColor: color,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 0,
        ...containterStyle,
      }}>
      <Text
        style={{
          fontFamily: 'Alternate Gothic No3 D',
          fontWeight: '400',
          color: textColor,
          fontSize: fontSize || (width > 350 ? 28 : 22),
          ...textStyle,
        }}>
        {text}
      </Text>
      {!!subTitle && (
        <Text
          style={{
            fontFamily: 'Alternate Gothic No3 D',
            fontWeight: '400',
            color: constants?.color?.white,
            fontSize: 14,
            marginTop: 5,
          }}>
          {subTitle}
        </Text>
      )}
    </View>
  );
};

export default ChallengesHeader;

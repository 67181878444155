import React, {useEffect, useCallback, useState} from 'react';
import {Text, View, Image, TextInput} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import constants from 'src/constants';
import {useChallenge} from 'src/Hooks/gameHooks';
import {markSelectionChallengeComplete} from 'src/Utils/huntFunctions_v2';
import {fetchWithParams} from 'src/Utils/apiCalls';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {updateDataAtPath} from 'src/Utils/fireUtils';
import debounce from 'lodash.debounce';
import {ContinueButton} from 'src/Modules/CommonView';
import {navigationRef} from 'src/Nav/navigationHelpers';

interface CoworkerFeudProps {
  onComplete?: () => void;
}

const CoworkerFeud: React.FC<CoworkerFeudProps> = ({onComplete}) => {
  const {width} = useWindowDimensionsSafe();
  const challenge = useChallenge();
  const groupId = useTypedSelector(
    (state) => state.user?.info?.currentHuntInfo?.groupId,
  );
  const [guesses, setGuesses] = useState(['', '', '']);

  // all the possible answers to the feud question
  const challengeAnswers = challenge?.answers || [];
  // once the user types anything, they will have a completion object
  const feudUserCompletions = challenge?.completions || {};

  const initialGuesses = feudUserCompletions?.guesses || [];
  let groupGuesses: string[] = [];
  if (Array.isArray(initialGuesses)) {
    groupGuesses = [...initialGuesses];
  }

  // sync our local state with the firebase state
  useEffect(() => {
    setGuesses([...groupGuesses]);
  }, [groupGuesses.length]);

  const handleType = (index: number, value: string) => {
    // set guesses locally
    setGuesses((prevGuesses) => {
      const updatedGuesses = [...prevGuesses];
      updatedGuesses[index] = value;
      debouncedHandleType(updatedGuesses);
      return updatedGuesses;
    });

    const challengeId = challenge?.challengeId || '';
    const firebaseGuessesPath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/completions/guesses/${index}`;

    const firebaseChallengePath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/completions/${index}`;

    // set loading in firebase
    updateDataAtPath(firebaseChallengePath, {
      pointsEarned: 'loading',
    });

    // set guesses in firebase
    updateDataAtPath(firebaseGuessesPath, value);
  };

  const checkAnswers = async (updatedGuesses: string[]) => {
    const challengeId = challenge?.challengeId || '';
    const firebasePath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/completions`;

    const feudApiUrl = 'https://game.letsroam.com/api/apiCheckCoworkerFeud';

    try {
      const data = await fetchWithParams(feudApiUrl, {
        guesses: updatedGuesses,
        answers: challengeAnswers,
        firebasePath: firebasePath,
      });
      console.log('feud returned data', {data});
      const challengeId = challenge?.challengeId || '';

      markSelectionChallengeComplete({
        challengeId,
        correct: true,
        singleCompletion: true,
        allowMultiGuess: true,
        overridePointsEarned: Number(data?.pointsEarned) || 0,
      });

      onComplete && onComplete?.();
    } catch (error) {
      console.error('coworker feud api error:', error);
    }
  };

  const completeChallenge = () => {
    const challengeId = challenge?.challengeId || '';

    markSelectionChallengeComplete({
      challengeId,
      correct: true,
      singleCompletion: true,
      allowMultiGuess: true,
      overridePointsEarned: Number(feudUserCompletions?.pointsEarned) || 0,
    });

    onComplete && onComplete?.();
    navigationRef.goBack('Coworker Feud');
  };

  const getOrdinal = (index: number) => {
    const ordinals = ['first', 'second', 'third'];
    return ordinals[index];
  };

  const debouncedHandleType = useCallback(debounce(checkAnswers, 1000), []);

  return (
    <View>
      <View
        style={{
          backgroundColor: constants.color.orange,
          borderRadius: 8,
          paddingVertical: 14,
          paddingHorizontal: 15,
        }}>
        <Text
          style={{
            color: constants.color.white,
            fontSize: 20,
            fontFamily: 'JakartaBold',
            letterSpacing: -0.5,
          }}>
          {challenge?.question}
        </Text>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 20,
          gap: 11,
        }}>
        <View
          style={{
            backgroundColor: '#D9D9D6',
            borderRadius: 10,
            paddingVertical: 11,
            paddingHorizontal: 16,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 72,
          }}>
          <Text
            style={{
              fontSize: 30,
              fontFamily: 'Jakarta',
              letterSpacing: -0.5,
              padding: 0,
              marginVertical: 0,
            }}>
            {String(feudUserCompletions?.pointsEarned || 0)}
          </Text>
        </View>
        <Text style={{maxWidth: 240, fontSize: 15, fontStyle: 'italic'}}>
          Out of 100 people surveyed, what were the top three answers?
        </Text>
        <Image
          source={require('src/Images/help.png')}
          style={{width: 42, height: 42, resizeMode: 'contain', flexShrink: 1}}
        />
      </View>

      {[0, 1, 2].map((index: number) => {
        const isWrong =
          feudUserCompletions?.[index]?.pointsEarned != 'loading' &&
          !feudUserCompletions?.[index]?.pointsEarned &&
          groupGuesses?.[index];

        return (
          <View
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
              marginTop: 20,
              width: width - 20,
            }}>
            <TextInput
              style={{
                width: width - 30,
                height: 40,
                backgroundColor: '#D9D9D6',
                borderRadius: 10,
                textAlign: 'center',
                color: '#505759',
                fontFamily: constants.font.Jakarta,
                fontSize: 15,
                elevation: 10,
                shadowColor: 'rgba(0, 0, 0, 0.8)',
                shadowOffset: {width: 10, height: 14},
                shadowOpacity: 0.25,
                shadowRadius: 20,
              }}
              onChangeText={(text) => {
                handleType(index, text);
              }}
              placeholderTextColor="#505759"
              placeholder={`Enter your team's ${getOrdinal(index)} guess`}
              value={guesses?.[index] || ''}
            />
            <View
              style={{
                backgroundColor: 'white',
                width: 60,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingVertical: 12,
                paddingHorizontal: 15,
                borderRadius: 12,
                position: 'absolute',
                right: 5,
              }}>
              {feudUserCompletions?.[index]?.pointsEarned === 'loading' ? (
                <Text style={{fontSize: 20, fontFamily: 'Jakarta'}}>...</Text>
              ) : (
                <Text
                  style={{
                    fontSize: 20,
                    fontFamily: isWrong ? 'JakartaBold' : 'Jakarta',
                    color: isWrong ? 'red' : 'black',
                  }}>
                  {isWrong
                    ? 'X'
                    : feudUserCompletions?.[index]?.pointsEarned || 0}
                </Text>
              )}
            </View>
          </View>
        );
      })}
    </View>
  );
};

export default CoworkerFeud;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TPhoto} from 'src/types/TPhoto';

interface CommunityPhotoState {
  photos: TPhoto[];
}

const initialState: CommunityPhotoState = {
  photos: [],
};

const communityPhotoSlice = createSlice({
  name: 'communityPhotos',
  initialState,
  reducers: {
    setCommunityPhotos(state, action: PayloadAction<TPhoto[]>) {
      state.photos = action.payload;
    },
  },
});

export const {setCommunityPhotos} = communityPhotoSlice.actions;

export default communityPhotoSlice.reducer;

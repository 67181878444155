import {logForCrashlytics} from 'src/Utils/fireUtils';

/**
 * Formats an array of daily challenges into a specific structure.
 * @param {Array} challenges - An array of daily challenge objects.
 * @returns {Array} The formatted array of daily challenges.
 */
// @eslint-disable-next-line
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatDailyChallenges = (challenges: Array<any>): Array<any> => {
  logForCrashlytics('formatDailyChallenges fired in dataFormatter');
  return challenges?.map?.((challenge) => {
    return {
      category: challenge.category,
      challenge_id: challenge.challenge_id,
      completed: Boolean(parseInt(challenge.completed)),
      completed_success: Boolean(parseInt(challenge.completed_success)),
      coin_value: parseInt(challenge.coin_value),
      task: challenge.task,
      challenge_medium: challenge.challenge_medium,
      difficulty: challenge.difficulty,
      scheduled_id: parseInt(challenge.scheduled_id),
      name: challenge.name,
      type: challenge.type,
      lr_app_challenge_completion_id:
        challenge.lr_app_challenge_completion_id || false,
      user_id: challenge.user_id,
      photo_url: challenge.photo_url,
      shareUUID: challenge.share_UUID || null,
    };
  });
};

import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useEffect} from 'react';
import {View} from 'react-native';

///////////////////////////
///////////// components
import {NonChallengeView} from 'src/Modules/CommonView';

///////////////////////////
///////////// utils

import {finishHunt} from 'src/Utils/huntFunctions';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';

// default team photo
// default team photo
const defaultTeamPhoto = {
  uri: cloudFlareOptimizeImage(
    'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648578162_medium.jpg',
  ),
};

const HuntOutro: React.FC = () => {
  console.log('HuntOutro render');

  const corporateBrandingUrl = useTypedSelector(
    (state) => state.game_v2?.corporateBrandingUrl,
  );

  let huntOutro = useTypedSelector((state) => state.game_v2?.huntOutro);

  const teamName = useTypedSelector((state) => state.group?.info?.teamName);
  const groupPhoto = useTypedSelector((state) => state.group?.info?.groupPhoto);

  useEffect(() => {
    logCustomAnalyticsEvent('hunt_completion');
  }, []);

  const handleFinishHunt = async () => {
    try {
      await finishHunt();
    } catch (err) {
      console.log(err);
    }
  };

  console.log('HuntOutro Render');

  huntOutro = huntOutro
    ?.replace('[TEAM_NAME]', teamName || '')
    ?.replace('[TEAM NAME]', teamName || '')
    ?.replace('[team_name]', teamName || '')
    ?.replace('[team name]', teamName || '');

  return (
    <View style={{flex: 1}}>
      <NonChallengeView
        titleText={`GOOD JOB ${teamName?.toUpperCase?.()}`}
        bodyText={
          (huntOutro || '') +
          `\n  \n Finish your hunt to see your score, how you ranked versus other teams, and get a free present. Make sure you have completed all your player and team challenges, so you can get the highest score possible.`
        }
        buttonText={'Finish Hunt & See Rank'}
        replaceText={teamName || 'Team Name'}
        handleButtonPress={() => {
          handleFinishHunt();
        }}
        imageSource={!!groupPhoto ? {uri: groupPhoto} : defaultTeamPhoto}
        brandingSource={corporateBrandingUrl}
      />
    </View>
  );
};

export default HuntOutro;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {
  Alert,
  KeyboardAvoidingView,
  Platform,
  TextInput,
  View,
} from 'react-native';
import ModalSelector from 'react-native-modal-selector';
import UserProfileIcon from 'src/Modules/UserProfileIcon';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import {UserProfileChallenges} from 'src/Data/UserProfileChallenges';

import {
  CloseIconButton,
  ContinueButton,
  BodyText,
} from 'src/Modules/CommonView';

import PhoneInput from 'src/Modules/CommonView/PhoneInput';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {logForCrashlytics, updateDataAtPath} from 'src/Utils/fireUtils';
import DatePicker from 'src/Modules/Native/DatePicker';
import useParams from 'src/Hooks/useParams';

interface LoginAdditionalInformationFormProps {
  isFullScreen?: boolean;
}

const LoginAdditionalInformationForm: React.FC<
  LoginAdditionalInformationFormProps
> = ({isFullScreen = false}) => {
  const {width} = useWindowDimensionsSafe();

  const {showFreeTrialScreen} =
    useParams<'LoginAdditionalInfoFullScreenPage'>();

  const contactPhoneInitial = useTypedSelector(
    (state) => state.user?.info?.contactPhone,
  );

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const userId = useTypedSelector((state) => state.user?.userId);

  const latitude = useTypedSelector(
    (state) => state.location?.region?.latitude,
  );
  const longitude = useTypedSelector(
    (state) => state.location?.region?.longitude,
  );

  const [contactPhone, setContactPhone] = useState<string>(
    contactPhoneInitial || '',
  );

  const [gender, setGender] = useState<
    'female' | 'male' | 'non_binary' | 'other' | null
  >(null);
  const [birthday, setBirthday] = useState<Date>(new Date());
  const [birthdayOpen, setBirthdayOpen] = useState<boolean>(false);
  const [home_zip_code, setHome_zip_code] = useState<string | null>(null);

  const selectorRef = useRef<ModalSelector | null>(null);
  const notDiscoverable = false;

  const updateUserInfo = useCallback(
    async (data: TJSONObject = {}) => {
      console.log({userId, data});
      if (!userId) {
        return;
      }

      data.platform = Platform.OS;

      await updateDataAtPath(`users/${userId}/additionalInformation`, data);

      await updateDataAtPath(`users/${userId}`, data);
    },
    [userId],
  );

  // updating based on user location
  useEffect(() => {
    if (!userId || !latitude || !longitude) {
      return;
    }

    updateUserInfo({latitude, longitude, userId});
  }, [latitude, latitude]);

  useEffect(() => {
    if (!userId) {
      return;
    }
    updateUserInfo({gender, birthday, home_zip_code, userId, contactPhone});
  }, []);

  const onAdditionalInfoSubmit = async () => {
    if (!userId) {
      return Alert.alert('User Not Found');
    }
    updateUserInfo({
      gender,
      birthday,
      home_zip_code,
      userId,
      notDiscoverable,
      contactPhone,
    });

    if (showFreeTrialScreen) {
      navigationRef.navigate(
        playTestState == false ? 'FreeTrialScreen' : 'LandingPageMain',
        null,
        'LoginAdditionalForm2',
      );
    } else {
      navigationRef.navigate('LandingPageMain', null, 'LoginAdditionalForm2');
    }
  };

  const genderOptions = {
    female: {label: 'She / Her / Hers', value: 'female', key: 1},
    male: {label: 'He / Him / His', value: 'male', key: 2},
    non_binary: {label: 'They / Them / Theirs', value: 'non_binary', key: 3},
    other: {label: 'Other', value: 'other', key: 4},
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: isFullScreen ? constants?.color.white1 : '#000000f0',
      }}>
      <KeyboardAvoidingView
        style={{flex: 1, justifyContent: 'flex-end'}}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        enabled>
        <View
          style={{
            width,
            backgroundColor: constants?.color?.white1,
            position: 'relative',
            height: '100%',
          }}>
          <View
            style={{
              alignItems: 'center',
              minHeight: 560,
            }}>
            <View style={{alignItems: 'center', marginVertical: 15}}>
              <BodyText
                text={'Additional Information'}
                textStyle={{fontWeight: '700'}}
              />
            </View>

            <BodyText
              text={'Help us personalize your experience and content.'}
              textStyle={{
                fontWeight: '400',
                textAlign: 'center',
                paddingLeft: 5,
                marginLeft: 0,
                marginTop: 10,
                marginBottom: 20,
              }}
            />
            <View style={{marginTop: 10, marginBottom: 10}}>
              <UserProfileIcon
                showName
                handlePress={() => {
                  const challenge =
                    UserProfileChallenges[
                      Math?.floor(
                        Math?.random() * UserProfileChallenges?.length,
                      )
                    ];
                  navigationRef.navigate(
                    'PhotoVideoCamera',
                    {
                      text: challenge,
                      onCapture: (data) => {
                        const {downloadURL, shareUUID} = data;
                        updateDataAtPath(`/users/${userId || 1342342134}`, {
                          customPhotoUrl: downloadURL,
                          customPhotoShareUUID: shareUUID,
                          photoUrl: downloadURL,
                        });
                      },
                      isVideo: false,
                    },
                    'LoginAdditionalInformationForm',
                  );
                }}
                textColor={constants?.color?.gray3}
                showCameraIcon={true}
              />
            </View>

            <View style={{marginHorizontal: 10}}>
              <BodyText
                text={`What's is your phone number?`}
                textStyle={{fontSize: 14, marginLeft: 5, marginBottom: 10}}
              />
              <PhoneInput
                onValueChange={(contactPhone) => {
                  console.log({contactPhone});
                  setContactPhone(contactPhone);
                }}
              />
            </View>
            <View style={{marginHorizontal: 10}}>
              <BodyText
                text={`What's your home Zip/Postal Code?`}
                textStyle={{fontSize: 14, marginLeft: 5, marginBottom: 10}}
              />
              <TextInput
                style={{
                  backgroundColor: constants?.color?.white,
                  borderRadius: 10,
                  color: constants?.color?.gray3,
                  fontFamily: constants?.font.circular,
                  height: 40,
                  width: width - 20,
                  shadowColor: '#000',
                  shadowOffset: {width: 0, height: 1},
                  shadowOpacity: 0.2,
                  shadowRadius: 3,
                }}
                allowFontScaling={false}
                textAlign={'center'}
                placeholder="Zip/Postal Code"
                value={home_zip_code || ''}
                keyboardType="email-address"
                autoCorrect={false}
                underlineColorAndroid={constants?.color?.white}
                onChangeText={(home_zip_code) =>
                  setHome_zip_code(home_zip_code)
                }
                blurOnSubmit
              />
            </View>

            <View style={{marginHorizontal: 10}}>
              <BodyText
                text={`What's your preferred pronoun?`}
                textStyle={{fontSize: 14, marginLeft: 5, marginBottom: 10}}
              />
              <ModalSelector
                data={Object.values(genderOptions)}
                ref={selectorRef}
                onChange={({value}) => {
                  console.log({value});
                  setGender(value);
                }}
                cancelText={'Cancel'}
                customSelector={<View />}
                overlayStyle={{justifyContent: 'flex-end'}}
              />
              <ContinueButton
                handlePress={() => selectorRef.current?.open?.()}
                text={
                  gender && genderOptions
                    ? genderOptions[gender]?.label
                    : 'Please Select Your Preference'
                }
                containerStyle={{
                  backgroundColor: 'white',
                  height: 40,
                  width: width - 20,
                  shadowOpacity: 0.2,
                }}
                background={constants?.color?.white1}
                textStyle={{
                  color: gender
                    ? constants?.color?.gray3
                    : constants?.color?.gray2,
                  fontWeight: '300',
                }}
              />
            </View>

            <View style={{marginHorizontal: 10}}>
              <BodyText
                text={'When is your birthday?'}
                textStyle={{fontSize: 14, marginLeft: 5, marginBottom: 10}}
              />
              <ContinueButton
                handlePress={() => setBirthdayOpen(true)}
                text={
                  birthday
                    ? birthday?.toDateString?.()
                    : 'Please Select Your Preference'
                }
                containerStyle={{
                  backgroundColor: 'white',
                  height: 40,
                  width: width - 20,
                  shadowOpacity: 0.2,
                }}
                background={constants?.color?.white1}
                textStyle={{
                  color: gender
                    ? constants?.color?.gray3
                    : constants?.color?.gray2,
                  fontWeight: '300',
                }}
              />
              <DatePicker
                modal
                mode="date"
                open={birthdayOpen}
                date={birthday}
                onConfirm={(date) => {
                  setBirthdayOpen(false);
                  setBirthday(date);
                }}
                onCancel={() => {
                  setBirthdayOpen(false);
                }}
              />
              {/* https://github.com/henninghall/react-native-date-picker */}
              {/* <DatePicker
                date={birthday}
                mode="date"
                placeholder="Select Your Birthday"
                format="MMM-DD-YYYY"
                confirmBtnText="Confirm"
                cancelBtnText="Cancel"
                style={{
                  backgroundColor: constants?.color?.white,

                  color: constants?.color?.gray3,
                  fontFamily: constants?.font.circular,
                  height: 40,
                  width: width - 20,
                  borderRadius: 10,
                }}
                customStyles={{
                  dateIcon: {
                    display: 'none',
                  },
                  dateInput: {
                    // marginLeft: 36,
                    borderRadius: 10,
                    backgroundColor: 'white',
                    borderWidth: 0,
                  },
                  // ... You can check the source to find the other keys.
                }}
                onDateChange={(date) => {
                  setBirthday(date);
                }}
              /> */}
            </View>

            <ContinueButton
              text={'Finish Customization'}
              testID={'finishCustomization'}
              handlePress={() => {
                logForCrashlytics(
                  'On additional information Submit fired for Validate Phone Number Button',
                );
                onAdditionalInfoSubmit();
              }}
              containerStyle={{
                width: width - 60,
                marginTop: 20,
                marginHorizontal: 30,
              }}
            />
            {!!isFullScreen && (
              <ContinueButton
                text={'Skip'}
                handlePress={() => {
                  logForCrashlytics(
                    'On additional information Submit fired for Validate Phone Number Button',
                  );
                  onAdditionalInfoSubmit();
                }}
                containerStyle={{
                  width: width - 60,
                  marginTop: 10,
                  marginHorizontal: 30,
                }}
                background={constants?.color.teal}
                // knockout
              />
            )}
          </View>
          <CloseIconButton
            handlePress={() => {
              logForCrashlytics('Go back pressed on AdditionalInformationForm');
              onAdditionalInfoSubmit();
            }}
          />
        </View>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};
export default LoginAdditionalInformationForm;

import { ScrollView } from "react-native";
import JoinHunt from "./JoinHunt";
import useTypedSelector from "src/Hooks/useTypedSelector";
import { useExplorerPass } from "src/Utils/explorerPassHelper";
import { useUserId } from "src/Hooks/reduxHooks";

export default function JoinAA() {
  console.log('PersonalAssistantTab render');

  const userRequests = useTypedSelector((state) => state.chats?.paChats);

  const explorerPass = useExplorerPass();

  const userId = useUserId();

  return (
    <ScrollView style={{backgroundColor: '#F9F7F2'}}>
      <JoinHunt isModal />
    </ScrollView>
  );
}
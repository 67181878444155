import useTypedSelector from 'src/Hooks/useTypedSelector';
import React from 'react';
import {View} from 'react-native';
import {useIsFocused} from '@react-navigation/native';

interface FocusedWrapperProps {
  children: React.ReactNode;
}

const FocusedWrapper: React.FC<FocusedWrapperProps> = ({children}) => {
  const isFocused = useIsFocused();

  const isCesModal = useTypedSelector((state) =>
    [
      'CESModal',
      'ReportModal',
      'ShareModal',
      'ConfirmModal',
      'NavigationChallengeModal',
      'NavigationChallengeScoreModal',
      'LocationChallengeListModal',
      'ChallengeAttemptAndResultModal',
    ]?.includes(state.current_screen.currentScreen),
  );

  console.log({isCesModal});

  if (!isFocused && !isCesModal) {
    return <View style={{flex: 1}}></View>;
  }

  return <>{children}</>;
};

export default FocusedWrapper;

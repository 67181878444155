import constants from 'src/constants';
import React, {useState, useEffect} from 'react';
import {TouchableOpacity, View, Vibration, ViewStyle} from 'react-native';

///////////////////////////
///////////// components
import {ContinueButton, BodyText} from 'src/Modules/CommonView';
import {useUserId} from 'src/Hooks/reduxHooks';
///////////////////////////
///////////// utils
import {markSelectionChallengeComplete} from 'src/Utils/huntFunctions_v2';
import {useChallenge} from 'src/Hooks/gameHooks';
import {updateDataAtPath} from 'src/Utils/fireUtils';
import {getReduxState} from 'src/Utils/helpers';

interface LetterScrambleProps {
  onComplete?: () => void;
  singleCompletion?: boolean;
  containerStyle?: ViewStyle;
}

interface Tile {
  correctLetter?: string;
  selected?: Tile | null;
  active?: boolean;
  tileIndex: number;
  letter?: string;
}

const LetterScramble: React.FC<LetterScrambleProps> = ({
  onComplete,
  singleCompletion,
  containerStyle = {},
}) => {
  const challenge = useChallenge();
  const userId = useUserId();

  const correctAnswer = challenge?.correctAnswer || 0;
  const completions = challenge?.completions || {};
  const challengeId = challenge?.challengeId || '';

  const shuffleArray = (array: TJSONArray) => {
    const shuffled = [...array];
    let currentIndex = array?.length;
    let temporaryValue;
    let randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math?.floor(Math?.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = shuffled[currentIndex];
      shuffled[currentIndex] = shuffled[randomIndex];
      shuffled[randomIndex] = temporaryValue;
    }

    return shuffled;
  };

  const shuffleSplitAnswer = (answer = ''): Tile[] => {
    const split =
      answer
        .toLowerCase?.()
        .trim()
        .split('')
        .filter((letter) => letter !== ' ') || [];

    return (
      (split &&
        shuffleArray(split)?.map?.((letter, tileIndex) => {
          return {
            letter,
            active: false,
            tileIndex,
          };
        })) ||
      []
    );
  };

  const [tiles, setTiles] = useState<Tile[] | null>(
    challenge?.correctAnswer
      ? shuffleSplitAnswer(challenge?.correctAnswer)
      : null,
  );
  const [userAnswer, setUserAnswer] = useState<Tile[] | null>();

  const nextEmptyIndex = userAnswer?.findIndex((answer) => !answer.selected);

  const formatUserAnswer = (answer = ''): Tile[] | null => {
    const split = answer.toLowerCase?.().trim().split('') || [];

    const tiles = split?.map?.((letter, index) => {
      return {
        correctLetter: letter,
        tileIndex: index,
        selected: letter === ' ' ? {letter: ' '} : null,
      } as Tile;
    });

    return tiles;
  };
  useEffect(() => {
    if (challenge?.challengeId && correctAnswer) {
      setTiles(shuffleSplitAnswer(correctAnswer));
      setUserAnswer(formatUserAnswer(correctAnswer));
    }
  }, [challenge?.challengeId]);

  const handlePressTile = (tile: Tile) => {
    const nextUserAnswer = userAnswer ? [...userAnswer] : null;

    if (
      !tile.active &&
      nextUserAnswer &&
      tiles &&
      typeof nextEmptyIndex === 'number' &&
      nextEmptyIndex !== -1
    ) {
      nextUserAnswer[nextEmptyIndex].selected = {...tile};

      if (
        nextEmptyIndex &&
        nextUserAnswer?.[nextEmptyIndex]?.selected?.letter !=
          nextUserAnswer?.[nextEmptyIndex]?.correctLetter
      ) {
        Vibration.vibrate(200);
      }

      const nextTiles = [...tiles];
      // set the clicked tile to active
      tiles[tile.tileIndex].active = true;

      setUserAnswer(nextUserAnswer);

      setTiles(nextTiles);
      const allCorrect = userAnswer?.every((answer) => {
        const {selected, correctLetter} = answer;
        return selected?.letter === correctLetter;
      });

      if (allCorrect) {
        markSelectionChallengeComplete({
          option: String(correctAnswer),
          correct: true,
          challengeId,
          singleCompletion,
          hintsDeductions: challenge?.hintsDeductions || 0,
        });
        console.log({onComplete});
        onComplete && onComplete?.();
      }
    } else {
      // setting from active to not active
      const activeIndex = userAnswer?.findIndex((answerTile) => {
        return (
          answerTile.selected &&
          answerTile?.selected?.tileIndex === tile?.tileIndex &&
          answerTile?.selected?.letter !== answerTile?.correctLetter
        );
      });
      if (
        tiles &&
        activeIndex !== undefined &&
        activeIndex >= 0 &&
        userAnswer
      ) {
        const nextUserAnswer = [...userAnswer];
        nextUserAnswer[activeIndex].selected = null;

        const nextTiles = [...tiles];
        tiles[tile.tileIndex].active = false;

        setUserAnswer(nextUserAnswer);
        setTiles(nextTiles);
      }
    }
  };

  const handleSubmit = (doHint = false) => {
    if (!tiles) {
      return console.error('no tiles');
    }
    const nextTiles = [...tiles];
    let firstWrongIndex: number | null = null;

    // finding the first wrong answer to remove
    const nextUserAnswer = userAnswer?.map?.((answer, index) => {
      const {selected, correctLetter} = answer;
      const isCorrect = selected?.letter === correctLetter;

      if (!isCorrect) {
        if (selected?.tileIndex != null) {
          nextTiles[selected.tileIndex].active = false;
        }
        if (firstWrongIndex === null) {
          firstWrongIndex = index;
        }
        return {...answer, selected: null};
      }
      return answer;
    });

    console.log({nextUserAnswer, firstWrongIndex});

    // removing first wrong answers
    if (doHint && nextUserAnswer != null && firstWrongIndex !== null) {
      const nextCorrectLetter = nextUserAnswer[firstWrongIndex]?.correctLetter;
      console.log({nextCorrectLetter});
      console.log({
        nextCorrectLetter,
        firstWrongIndex,
        nextUserAnswer,
        nextTiles,
      });
      let found = false;
      nextTiles?.map((tile, index) => {
        if (
          !tile.active &&
          tile.letter == nextCorrectLetter &&
          nextUserAnswer &&
          !found
        ) {
          found = true;
          const newTile = {...tile, active: true};
          nextTiles[index].active = true;

          if (firstWrongIndex != null) {
            nextUserAnswer[firstWrongIndex].selected = newTile;
          }
        }
      });
    }

    const groupId = getReduxState((state) => state.group?.info?.groupId);

    const hintsDeductions = challenge?.hintsDeductions || 0;
    const maxPoints = challenge?.maxPoints || 100;

    // don't deduct past the max points and go negative
    if (hintsDeductions + 10 <= maxPoints) {
      updateDataAtPath(
        `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/hintsDeductions`,
        hintsDeductions + 10,
      );
    }

    console.log({nextTiles});

    if (nextUserAnswer?.every((item) => item.selected)) {
      markSelectionChallengeComplete({
        option: String(correctAnswer),
        correct: true,
        challengeId,
        singleCompletion,
        hintsDeductions,
      });
      console.log({onComplete});
      onComplete && onComplete?.();
    } else {
      setTiles(nextTiles);
      setUserAnswer(nextUserAnswer);
    }
  };

  const userHasCompleted = userId && completions?.[userId] ? true : false;

  return (
    <View
      style={{
        justifyContent: 'center',
        ...containerStyle,
      }}>
      {!userHasCompleted && (
        <View style={{alignItems: 'center', ...containerStyle}}>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              marginBottom: 0,
            }}>
            {!!userAnswer &&
              !!userAnswer?.length &&
              userAnswer
                .reduce(
                  (acc: JSX.Element[][], answer, index) => {
                    const {correctLetter, selected} = answer;

                    console.log({correctLetter});

                    const isCorrect = selected?.letter === correctLetter;

                    const element: JSX.Element = (
                      <AnswerTile
                        key={`${correctLetter}${index}`}
                        letter={selected?.letter || '_'}
                        active={isCorrect}
                        selected={index === nextEmptyIndex}
                        handlePress={() => {
                          if (
                            selected?.tileIndex != null &&
                            !isCorrect &&
                            tiles
                          ) {
                            const nextTiles = [...tiles];
                            nextTiles[selected.tileIndex].active = false;
                            const nextUserAnswer = [...userAnswer];
                            nextUserAnswer[index].selected = null;
                            setTiles(nextTiles);
                            setUserAnswer(nextUserAnswer);
                          }
                        }}
                      />
                    );

                    if (correctLetter === ' ') {
                      // create a new line
                      acc.push([]);
                    } else if (!acc.length || correctLetter !== ' ') {
                      // add to the existing line
                      acc[acc.length - 1].push(element);
                    }

                    return acc;
                  },
                  [[]],
                )
                .map((line, index) => (
                  <View
                    key={`line-${index}`}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 240,
                    }}>
                    {line}
                  </View>
                ))}
          </View>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginVertical: 10,
              width: 240,
            }}>
            {!!tiles?.length &&
              tiles?.map?.((tile) => {
                return (
                  <LetterTile
                    key={`${tile.tileIndex}${tile.letter}`}
                    letter={tile.letter}
                    testID={`letter-${tile?.letter}`}
                    active={tile.active}
                    handlePress={() => {
                      tile && handlePressTile(tile);
                    }}
                  />
                );
              })}
          </View>
          <ContinueButton
            text={'Submit'}
            containerStyle={{width: 220, marginVertical: 5}}
            background={constants?.color?.orange}
            handlePress={handleSubmit}
          />
          <ContinueButton
            text={'Get Hint (-10pts)'}
            containerStyle={{width: 220, marginVertical: 5, marginBottom: 75}}
            background={constants?.color?.teal}
            handlePress={() => {
              handleSubmit(true);
            }}
          />
        </View>
      )}
      {!!userHasCompleted && (
        <View style={{alignItems: 'center'}}>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              flexWrap: 'wrap',
              marginBottom: 10,
            }}>
            {!!userAnswer &&
              userAnswer?.map?.((answer, index) => {
                const {correctLetter} = answer;

                console.log({correctLetter});

                return (
                  <AnswerTile
                    key={`${correctLetter}${index}`}
                    letter={correctLetter}
                    active={true}
                    selected={false}
                  />
                );
              })}
          </View>
        </View>
      )}
    </View>
  );
};

const AnswerTile = ({
  letter = '',
  active = false,
  selected = false,
  handlePress = () => {},
}) => {
  const color = active ? constants?.color?.teal : constants?.color?.gray3;
  const styling = selected
    ? {
        borderWidth: 2,
        borderColor: constants?.color?.gray3_80,
        borderRadius: 10,
      }
    : {};

  return (
    <TouchableOpacity
      style={{
        alignItems: 'center',
        height: 40,
        justifyContent: 'center',
        minWidth: 32,
        ...styling,
      }}
      activeOpacity={0.8}
      onPress={handlePress}
      disabled={!handlePress}>
      <BodyText
        text={letter.toUpperCase?.()}
        textStyle={{
          color,
          textAlign: 'center',
          fontSize: 14,
          marginHorizontal: 0,
          fontWeight: '700',
          marginTop: 0,
        }}
      />
      {!!active && letter !== ' ' && (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            width: 26,
            height: 2,
            borderRadius: 1,
            backgroundColor: constants?.color?.teal,
          }}
        />
      )}
    </TouchableOpacity>
  );
};

const LetterTile = ({
  letter = '',
  active = false,
  handlePress = () => {},
  testID = '',
}) => {
  const backgroundColor = active
    ? constants?.color?.blue
    : constants?.color?.white1;
  const color = active ? constants?.color?.white : constants?.color?.gray3;

  return (
    <TouchableOpacity
      style={{
        width: 50,
        height: 50,
        marginHorizontal: 5,
        marginVertical: 5,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor,
        borderRadius: 10,
      }}
      testID={testID}
      activeOpacity={0.8}
      onPress={handlePress}
      disabled={!handlePress}>
      <BodyText
        text={letter.toUpperCase?.()}
        textStyle={{color, marginTop: 5, fontWeight: '700'}}
      />
    </TouchableOpacity>
  );
};

export default LetterScramble;

// used to get store all the ticket vouchers associated with an account
// this is done by firebase query
// https://console.firebase.google.com/u/0/project/barhuntv2/database/barhuntv2/data/~2Ftransactions~2Fpurchases
// This is also used to send messages to the user
// This is mostly used in the ticketsVoucher controller

// used to get and store all the ticket vouchers associated with an account
// this is done by firebase query
// https://console.firebase.google.com/u/0/project/barhuntv2/database/barhuntv2/data/~2Ftransactions~2Fpurchases
// This is also used to send messages to the user
// This is mostly used in the ticketsVoucher controller

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TVoucher} from 'src/types/TVoucher';

interface TicketVouchersState {
  emailVouchers: Record<string, TVoucher>;
  phoneVouchers: Record<string, TVoucher>;
  userIdVouchers: Record<string, TVoucher>;
  allVouchers: TVoucher[];
  voucherShows: number;
}

const initialState: TicketVouchersState = {
  emailVouchers: {},
  phoneVouchers: {},
  userIdVouchers: {},
  allVouchers: [],
  voucherShows: 0,
};

const ticketVouchersSlice = createSlice({
  name: 'ticket_vouchers',
  initialState,
  reducers: {
    updateEmailVouchers(
      state,
      action: PayloadAction<Record<string, TVoucher>>,
    ) {
      console.log('UPDATE_EMAIL_VOUCHERS');
      state.emailVouchers = action.payload;
      state.allVouchers = Object.values({
        ...action.payload,
        ...state.phoneVouchers,
        ...state.userIdVouchers,
      });
    },
    updatePhoneVouchers(
      state,
      action: PayloadAction<Record<string, TVoucher>>,
    ) {
      console.log('UPDATE_PHONE_VOUCHERS');
      state.phoneVouchers = action.payload;
      state.allVouchers = Object.values({
        ...action.payload,
        ...state.emailVouchers,
        ...state.userIdVouchers,
      });
    },
    updateUserIdVouchers(
      state,
      action: PayloadAction<Record<string, TVoucher>>,
    ) {
      console.log('UPDATE_USER_ID_VOUCHERS', action.payload);
      state.userIdVouchers = action.payload;
      state.allVouchers = Object.values({
        ...action.payload,
        ...state.emailVouchers,
        ...state.phoneVouchers,
      });
    },
    addVoucherShows(state, action: PayloadAction<number>) {
      console.log('ADD_VOUCHER_SHOWS');
      state.voucherShows += action.payload;
    },
  },
});

export const {
  updateEmailVouchers,
  updatePhoneVouchers,
  updateUserIdVouchers,
  addVoucherShows,
} = ticketVouchersSlice.actions;

export default ticketVouchersSlice.reducer;

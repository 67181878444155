import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useRef} from 'react';
import {Linking, View, TouchableOpacity} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {
  ContinueButton,
  CloseIconButton,
  BodyText,
} from 'src/Modules/CommonView';

import LottieView from 'lottie-react-native';
import ExpoImage from 'src/Utils/ExpoImage';

import useParams from 'src/Hooks/useParams';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import {apiAdminEarnBadge, apiUpdateUserBadges} from 'src/Utils/apiCalls';
import {useUserId} from 'src/Hooks/reduxHooks';
import {navigationRef} from 'src/Nav/navigationHelpers';

function capitalizeFirstLetter(string: string | undefined | null): string {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const BadgeAwardModal: React.FC = () => {
  const {width, height} = useWindowDimensionsSafe();
  const userId = useUserId();

  const {badge} = useParams<'BadgeAwardModal'>();

  console.log('BadgeAwardModal', {badge});
  const animationRef = useRef<LottieView | null>(null);

  const memberReceiptTid = useTypedSelector(
    (state) => state.user?.info?.memberReceiptTid,
  );

  const {
    current_quantity = 0,
    requirement_quantity = 1,
    date_awarded,
    level = 1,
    prize_type = '',
    photo_thumb,
    isNew,
    prize_is_physical = false,
    isRedeemed = false,
  } = badge;

  const updatedPrizeIsPhysical = !!prize_is_physical;

  const earned = !!date_awarded || current_quantity >= requirement_quantity;

  const subText = earned
    ? `You earned ${badge.prize_quantity} ${prize_type} by doing: ${
        badge?.task || ''
      }`
        .toLowerCase?.()
        .replace(`let's roam`, `Let's Roam`)
    : `${capitalizeFirstLetter((badge?.task || '').toLowerCase?.())} to earn ${
        badge.prize_quantity
      } ${prize_type}!`
        .replace(`let's roam`, `Let's Roam`)
        .replace(`_`, ` `);

  let buttonText = 'GO BACK';
  let buttonPress = () => navigationRef?.goBack('Badges/BadgeAwardModal.tsx');

  if (isNew) {
    if (updatedPrizeIsPhysical) {
      buttonText = 'Redeem Now';
      buttonPress = () =>
        Linking?.openURL(
          'https://www.letsroam.com/store/receipt/' + memberReceiptTid,
        );
    } else {
      buttonText = 'Sweet, Thanks!';
      buttonPress = () => navigationRef?.goBack('Badges/BadgeAwardModal.tsx');
    }
  }

  if (!isRedeemed && updatedPrizeIsPhysical && date_awarded) {
    buttonText = 'Redeem Now';
    buttonPress = () =>
      Linking?.openURL(
        'https://www.letsroam.com/store/receipt/' + memberReceiptTid,
      );
  }

  if (!!isRedeemed && updatedPrizeIsPhysical && updatedPrizeIsPhysical) {
    buttonText = 'See Prize';
    buttonPress = () =>
      Linking?.openURL(
        'https://www.letsroam.com/store/receipt/' + memberReceiptTid,
      );
  }
  const isLRAdmin = useTypedSelector((state) =>
    state.user?.info?.email?.includes?.('letsroam'),
  );

  if (updatedPrizeIsPhysical && !date_awarded) {
    buttonText = 'Become A Member';
    buttonPress = () =>
      navigationRef.navigate(
        'ExplorerPassSubscriptionsListModal',
        null,
        'Badges/BadgeAwardModal.tsx',
      );
  }
  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        justifyContent: 'center',
      }}>
      {/* Background balloon animation */}
      <View style={{position: 'absolute', left: 0, right: 0, top: 0}}>
        <LottieView
          ref={animationRef}
          source={require('src/Lottie/balloons.json')}
          autoPlay
          loop={false}
          style={{flex: 1, width: width - 100, height, paddingRight: 20}}
          speed={1.3}
        />
      </View>
      {/* Modal container */}
      <View
        style={{
          width: width - 50,
          borderRadius: 5,
          minHeight: 420,
          backgroundColor: 'white',
          alignItems: 'center',
          marginBottom: 50,
        }}>
        <BodyText
          text={
            earned
              ? `You Earned a Level ${level} Badge!`
              : `Next Badge Level: ${level}`
          }
          textStyle={{
            fontSize: 18,
            fontWeight: '700',
            marginTop: 25,
            fontFamily: constants?.font.circular,
            lineHeight: 20,
            textAlign: 'center',
            letterSpacing: -0.165,
            color: constants?.color?.gray3,
          }}
        />

        <ExpoImage
          source={{
            uri: cloudFlareOptimizeImage(
              'https://photos.letsroam.com/icons/' + photo_thumb,
              200,
            ),
          }}
          style={{height: 82, width: 82, marginTop: 10}}
        />

        {/* underlined title */}
        <View
          style={{
            borderBottomColor: constants?.color?.gray3,
            borderBottomWidth: 1.5,
            paddingBottom: 5,
          }}>
          <BodyText
            text={badge?.title}
            center
            textStyle={{
              fontSize: 18,
              fontWeight: '700',
              marginTop: 18,
              fontFamily: constants?.font.circular,
              lineHeight: 20,
              textAlign: 'center',
              letterSpacing: -0.165,
              color: constants?.color?.gray3,
            }}
            fullWidth={width - 60}
          />
        </View>

        {/* back button */}
        <CloseIconButton
          handlePress={() =>
            navigationRef?.goBack('Badges/BadgeAwardModal.tsx')
          }
          containerStyle={{top: 5, right: 5}}
        />

        <BodyText
          text={subText}
          textStyle={{
            fontSize: 18,
            fontWeight: '400',
            marginTop: 20,
            fontFamily: constants?.font.circular,
            lineHeight: 20,
            textAlign: 'center',
            letterSpacing: -0.165,
            color: constants?.color?.gray3,
          }}
        />

        <ContinueButton
          text={buttonText}
          width={220}
          containerStyle={{
            marginTop: 30,
            marginBottom: 15,
          }}
          handlePress={buttonPress}
        />

        <ContinueButton
          text={'Close'}
          width={220}
          containerStyle={{
            marginTop: 0,
            marginBottom: 15,
            backgroundColor: constants?.color?.teal,
          }}
          handlePress={() =>
            navigationRef?.goBack('Badges/BadgeAwardModal.tsx')
          }
        />

        {(!!__DEV__ || !!isLRAdmin) && !earned && (
          <ContinueButton
            text={'Admin: Earn Badge'}
            width={220}
            containerStyle={{
              marginTop: 0,
              marginBottom: 15,
              backgroundColor: constants?.color?.teal,
            }}
            handlePress={async () => {
              if (userId && badge?.id) {
                await apiAdminEarnBadge({
                  user_id: userId,
                  badge_id: String(badge.id),
                });
                apiUpdateUserBadges();
              }
            }}
          />
        )}
      </View>
      <View style={{alignItems: 'center', marginBottom: 50}}>
        {prize_type == 'recharge' && (
          <BodyText
            text={
              'Recharged Dining deals will be available the next day. Recharge deals in the Your Rewards Section of Dining. '
            }
            textStyle={{
              color: 'white',
              marginLeft: 0,
              marginRight: 0,
              fontSize: 14,
              paddingLeft: 25,
              paddingRight: 25,
            }}
          />
        )}
        {!!isNew && (
          <TouchableOpacity
            onPress={() => {
              navigationRef.navigate(
                'AllBadgesModal',
                {},
                'Badges/BadgeAwardModal.tsx',
              );
            }}
            activeOpacity={0.8}
            style={{
              borderBottomColor: 'white',
              borderBottomWidth: 2,
              paddingHorizontal: 0,
              width: 130,
              marginTop: 10,
            }}>
            <BodyText
              text={'See All Badges'}
              textStyle={{
                color: 'white',
                marginLeft: 0,
                marginRight: 0,
                textAlign: 'center',
              }}
            />
          </TouchableOpacity>
        )}
      </View>
    </SafeAreaView>
  );
};

export default BadgeAwardModal;

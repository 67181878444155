import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState, useRef} from 'react';

import {SafeAreaView} from 'react-native-safe-area-context';
import {ScrollView, TextInput, View} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';

/////////////////////////////////////////////
/////////////// components
import {BodyText} from 'src/Modules/CommonView';

import {useUserId} from 'src/Hooks/reduxHooks';
import {apiCESReview} from 'src/Utils/apiCalls';
import {leaveHuntReview} from 'src/Utils/huntFunctions';
import StandardStarRating from 'src/Modules/CommonView/StandardStarRating';

////////////// pass in  superlative options, completion action, questions as nav props

const HuntReviewScreen: React.FC = () => {
  const {width} = useWindowDimensionsSafe();

  const userId = useUserId();

  const scrollViewRef = useRef<ScrollView | null>(null);

  const [star_rating, setStar_rating] = useState<number>(5);

  const [additionalComments, setAdditionalComments] = useState<string>('');

  const groupId = useTypedSelector((state) => state?.group?.info?.groupId);
  const huntId = useTypedSelector((state) => state?.group?.info?.huntId);

  const handleReviewSubmit = async () => {
    ////////// handle empty feedback here...
    const review = await apiCESReview({
      groupId,
      userId,
      huntId: huntId || undefined,
      r_hunt: String(star_rating),
      r_app: String(star_rating),
      additionalComments: additionalComments,
      type: 'regular',
    });

    console.log('leaving review', {review});

    userId &&
      groupId &&
      leaveHuntReview(userId, groupId, star_rating, star_rating);
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: constants?.color?.white,
        paddingTop: 0,
      }}>
      <ScrollView
        style={{flex: 1}}
        ref={scrollViewRef}
        showsVerticalScrollIndicator={true}>
        <View
          style={{
            alignItems: 'flex-start',
            paddingTop: 0,
            paddingBottom: 300,
            position: 'relative',
          }}>
          <BodyText
            text={`How Did You Enjoy The Hunt?`}
            textStyle={{
              textAlign: 'center',
              paddingLeft: 5,
              fontSize: 24,
              lineHeight: 28,
              marginLeft: 10,
              marginTop: 0,
              marginBottom: 10,
            }}
          />
          <BodyText
            text={`Let our writers know how you enjoyed the hunt!`}
            textStyle={{
              textAlign: 'center',
              paddingLeft: 5,
              fontSize: 16,
              lineHeight: 20,

              marginTop: 5,
              marginBottom: 20,
            }}
          />

          <View style={{width, alignItems: 'center', marginBottom: 20}}>
            <StandardStarRating
              size={40}
              initialRating={5}
              rating={star_rating}
              onRatingChange={(newRating: number) => setStar_rating(newRating)}
            />
          </View>

          <BodyText
            text={'Leave a review!'}
            textStyle={{
              textAlign: 'left',
              paddingLeft: 5,
              fontSize: 18,
              lineHeight: 20,
              marginLeft: 10,
              marginTop: 10,
              marginBottom: 10,
            }}
          />
          <View style={{width: width - 20, marginHorizontal: 10}}>
            <TextInput
              style={{
                backgroundColor: constants?.color?.white1,
                borderRadius: 10,
                borderWidth: 0,
                color: constants?.color?.gray3,
                flex: 1,
                fontFamily: constants?.font.circular,
                height: 120,
                paddingHorizontal: 15,
                paddingVertical: 15,
              }}
              textAlign={'left'}
              onFocus={() => {
                scrollViewRef.current?.scrollToEnd?.();
              }}
              allowFontScaling={false}
              placeholder={
                'Optional - Leave an internal review for our writers and programmers.'
              }
              value={additionalComments}
              multiline={true}
              autoCorrect={true}
              underlineColorAndroid={constants?.color?.white1}
              onChangeText={(additionalComments) => {
                setAdditionalComments(additionalComments);
              }}
              blurOnSubmit
            />
          </View>
        </View>
      </ScrollView>
      <ModalOmniBar
        right={{
          handlePress: () => {
            handleReviewSubmit();
          },
          icon: 'arrow-right-circle',
          text: 'Skip',
        }}
        omniButton={{
          icon: 'check',
          iconLibrary: 'FontAwesome',
          text: 'Submit',
          color: constants?.color?.orange,
          handlePress: () => handleReviewSubmit(),
          loading: false,
        }}
      />
    </SafeAreaView>
  );
};

export default HuntReviewScreen;

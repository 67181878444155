import constants from 'src/constants';
import 'react-native-gesture-handler';

import React, {useMemo} from 'react';

import AppIntroSwiper from 'src/LandingPage/AppIntroSwiper';
import AppLoadingPage from 'src/LandingPage/AppLoadingPage';
import PASubmitChat from 'src/LandingPage/PASubmitChat';
import PAChat from 'src/LandingPage/PAChat';
import {AppEventsLogger} from 'src/Modules/Native/Facebook';
import DrawerMenu from 'src/Nav/DrawerMenu';

//////// navigation && notifications handler component
import {NavigationContainer} from '@react-navigation/native';

import {SafeAreaProvider} from 'react-native-safe-area-context';

import {Platform} from 'react-native';

import {createDrawerNavigator} from '@react-navigation/drawer';
//////////////////////////
//////// utils && actions
import {setCurrentScreen} from 'src/Redux/reducers/current_screen.reducer';
import {analytics} from 'src/Utils/db';
import LoginModal from 'src/LandingPage/LoginModal';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {
  getActiveRouteName,
  navigationRefSetter,
} from 'src/Nav/navigationHelpers';

import LoginAdditionalInformationForm from 'src/LandingPage/LoginAdditionalInformationForm';
import LoginModalTextForm from 'src/LandingPage/LoginModalTextForm';

import {logAttributesForCrashlytics} from 'src/Utils/fireUtils';
import {mmkvStorage} from 'src/Utils/mmkvStorage';

import LandingPageTabs from 'src/Nav/LandingPageTabs';

import ScavengerHuntMainGameStack from 'src/Nav/MainGameStack';

import {createStackNavigator} from '@react-navigation/stack';

import ReferralModal from 'src/LandingPage/ReferralModal';
import SupportModal from 'src/LandingPage/SupportModal';

import BadgeAwardModal from 'src/Badges/BadgeAwardModal';
import AllBadgesModal from 'src/Badges/AllBadgesModal';

import FAQModal from 'src/LandingPage/FAQModal';
import GrouponRedemption from 'src/LandingPage/GrouponRedemption';

import CommunityViewPhoto from 'src/LandingPage/CommunityViewPhoto';

// pull these out as globally usable, adapt review modal for barhunt as well

import QrModal from 'src/JoinRedeem/QrModal';

import EventDashboard from 'src/CustomEvents/EventDashboard';

////////////////////////////////
//////////////////// backpack v2

import PhotosModal from 'src/Backpack/PhotosModal';

import ChallengeCompletedModal from 'src/ScavengerHunt_v2/Game/ChallengeCompletedModal';

import ReportModal from 'src/ScavengerHunt_v2/Game/ReportModal';

import JoinHuntModal from 'src/JoinRedeem/JoinHuntModal';
import PhotoVideoCamera from 'src/Modules/CommonGame/Camera/PhotoVideoCamera';
///////////////////////////////
///////////////////////////////
///////////////// classic

import ChallengeAttemptAndResultModal from 'src/ScavengerHunt_v2/Classic/ChallengeAttemptAndResultModal';

import LocationChallengeListModal from 'src/ScavengerHunt_v2/Classic/LocationChallengeListModal';
import NavigationChallengeModal from 'src/ScavengerHunt_v2/Classic/NavigationChallengeModal';
import NavigationChallengeScoreModal from 'src/ScavengerHunt_v2/Classic/NavigationChallengeScoreModal';

import ShareModal from 'src/Modules/ShareModal';
import PhotoScoreAndShareModal from 'src/Modules/PhotoScoreAndShareModal';

import QuestionFeedBackModal from 'src/Modules/QuestionFeedbackModal';
import LandingPageScavengerHuntInfoModal from 'src/Registration/LandingPageScavengerHuntInfoModal';

import SimpleStackNavigator from 'src/Nav/SimpleStackNavigator';

import HuntMap from 'src/ScavengerHunt_v2/LegacyHuntMap';

import AddPlayerModal from 'src/Modules/CommonGame/AddPlayerModal';
import ShareEventModal from 'src/Modules/CommonGame/ShareEventModal';

import GameLobbyHuntInfoModal from 'src/ScavengerHunt_v2/GameLobbyHuntInfoModal';

import CESModal from 'src/Modules/CESModal';
import ConfirmModal from 'src/Modules/ConfirmModal';

import LocalCreateChallengeModal from 'src/Local/LocalCreateChallengeModal';
import LocalCreateChallengeTypeModal from 'src/Local/LocalCreateChallengeTypeModal';
import LocalCreateLocationModal from 'src/Local/LocalCreateLocationModal';
import LocalFilterModal from 'src/Local/LocalFilterModal';
import LocalSiteModal from 'src/Local/LocalSiteModal';
import PurchaseModal from 'src/FoxShop/PurchaseModal';

import ShowLogsModal from 'src/LandingPage/ShowLogsModal';
import EmailSignInBlockingPage from 'src/LandingPage/EmailSignInBlockingPage';
import ProfilePage from 'src/LandingPage/ProfilePage';
import WanderlistDetail from 'src/Local/WanderlistDetail';
import {TNavigationRouteParams} from 'src/types/TNavigationRouteParams';
import FreeTrialScreen from 'src/Modules/LetsRoamPlus/FreeTrialScreen';
import WanderlistCreate from 'src/Local/WanderlistCreate';
import WanderlistAddLocation from 'src/Local/WanderlistAddLocation';

import Wanderlists from 'src/Local/Wanderlists';
import HomeLandingMap from 'src/LandingPage/Home/HomeLandingMap';
import LocalMap from 'src/Local/LocalMap';
import PastHuntsModal from 'src/Backpack/PastHuntsModal';
import Missions from 'src/Modules/Missions';
import AutoCompleteAddressModal from 'src/Modules/CommonView/AutoCompleteAddressModal';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import getAppType from '../Utils/getAppType';

function PersonalAssistantStack() {
  return (
    <SimpleStackNavigator
      showBack
      name="PASubmitChatWrapper"
      component={PASubmitChat}
    />
  );
}

function PersonalAssistantChatStack() {
  return (
    <SimpleStackNavigator showBack name="PAChatWrapper" component={PAChat} />
  );
}

function MissionsStack() {
  return <SimpleStackNavigator name="MissionsWrapper" component={Missions} />;
}

function ProfilePageStack() {
  return (
    <SimpleStackNavigator
      showBack={true}
      name="ProfilePageStack"
      component={ProfilePage}
    />
  );
}

function ScavengerHuntInfoStack() {
  return (
    <SimpleStackNavigator
      name="ScavengerHuntInfoStack"
      component={LandingPageScavengerHuntInfoModal}
      showBack={true}
    />
  );
}

function PastHuntsModalStack() {
  return (
    <SimpleStackNavigator
      name="PastHuntsModalStack"
      component={PastHuntsModal}
      showBack={true}
    />
  );
}

function WanderlistStack() {
  return (
    <SimpleStackNavigator
      showBack={true}
      name="WanderlistStack"
      component={Wanderlists}
    />
  );
}

function AllBadgesModalStack() {
  return (
    <SimpleStackNavigator
      name="AllBadgesModalStack"
      component={AllBadgesModal}
      showBack={true}
    />
  );
}

function HomeLandingMapModal() {
  return (
    <SimpleStackNavigator
      name="HomeLandingMapModalWrapper"
      showBack={true}
      component={() => <HomeLandingMap isModal />}
    />
  );
}

function LocalMapModal() {
  return (
    <SimpleStackNavigator
      name="LocalMapModal"
      showBack={true}
      component={() => <LocalMap isModal />}
    />
  );
}

function LocalCreateLocationModalStack() {
  return (
    <SimpleStackNavigator
      name="LocalCreateLocationModalStack"
      showBack={true}
      component={LocalCreateLocationModal}
    />
  );
}
function WanderlistCreateStack() {
  return (
    <SimpleStackNavigator
      name="WanderlistCreateStack"
      showBack={true}
      component={WanderlistCreate}
    />
  );
}

function LocalCreateChallengeModalStack() {
  return (
    <SimpleStackNavigator
      name="LocalCreateChallengeModalStack"
      showBack={true}
      component={LocalCreateChallengeModal}
    />
  );
}

const DrawerWrapper = createDrawerNavigator();

const Stack = createStackNavigator();

const RootNavigationStack = () => {
  console.log('RootNavigationStack render');

  const handleFacebookTracking = (currentScreen: string) => {
    if (Platform.OS != 'web') {
      AppEventsLogger?.logEvent?.('AppContentView', {
        content_name: currentScreen,
      });
    }
  };

  return (
    <SafeAreaProvider style={{position: 'relative'}}>
      <NavigationContainer
        // A ref to the navigation container, allowing access to the navigation object.
        ref={navigationRefSetter}
        // A callback function that is invoked whenever the navigation state changes.
        onStateChange={(currentState) => {
          if (!currentState) {
            return;
          }

          // Retrieve the current screen name from the Redux state.
          const currentScreen = getReduxState(
            (state) => state?.current_screen?.currentScreen,
          );
          // Determine the new current screen name based on the updated navigation state.
          // @ts-ignore this is an error in THEIR code
          const newCurrentScreen = getActiveRouteName(currentState);

          console.log({newCurrentScreen});

          // Check if the new current screen is different from the previous current screen.
          if (!!newCurrentScreen && newCurrentScreen != currentScreen) {
            // Update the Redux state with the new current screen name.
            dispatchAction(
              // @ts-ignore this is true
              setCurrentScreen(newCurrentScreen as TNavigationRouteParams),
            );

            // Log the new current screen name as an attribute for Crashlytics.
            logAttributesForCrashlytics?.(
              'currentScreen',
              `${newCurrentScreen}`,
            );
            // and the previous screen
            logAttributesForCrashlytics?.(
              'previousCurrentScreen',
              `${currentScreen || ''}`,
            );

            //tracking page in facebook
            handleFacebookTracking(newCurrentScreen);

            // saving to local state for quick access
            mmkvStorage.set('currentScreen', newCurrentScreen);

            // // Log a screen view event in Firebase Analytics with the new current screen name.
            analytics()?.logScreenView?.({
              screen_name: 'LR-APP: ' + newCurrentScreen,
              screen_class: 'LR-APP: ' + newCurrentScreen,
            });
          }
        }}>
        <DrawerWrapper.Navigator
          drawerContent={() => <DrawerMenu />}
          screenOptions={() => ({headerShown: false, gestureEnabled: false})}>
          <DrawerWrapper.Screen
            name="main"
            // eslint-disable-next-line no-use-before-define
            component={RootNavigationStackContent}
          />
        </DrawerWrapper.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
  );
};

const Tab = createBottomTabNavigator();

// Put in a tab so they can unmount on blur
// literally can only happen in a tab navigator
const NonModalRootScreens = () => {
  return (
    <Tab.Navigator
      screenOptions={{unmountOnBlur: true, headerShown: false}}
      tabBar={() => <></>}>
      <Tab.Screen name="AppLoadingPage" component={AppLoadingPage} />

      <Tab.Screen name="LandingPageMain" component={LandingPageTabs} />
      {getAppType() == 'lr' && (
        <Tab.Screen
          name="MainGameStack"
          component={ScavengerHuntMainGameStack}
        />
      )}

      {getAppType() == 'lr' && (
        <Tab.Screen name="Missions" component={MissionsStack} />
      )}

      {getAppType() == 'lr' && (
        <Tab.Screen name="ProfilePage" component={ProfilePageStack} />
      )}

      {/* 3 intro pages including swiper
       */}
      <Tab.Screen name="AppIntroSwiper" component={AppIntroSwiper} />

      <Tab.Screen name="LoginPage">
        {() => {
          return <LoginModal isFullScreen />;
        }}
      </Tab.Screen>
      <Tab.Screen name="LoginAdditionalInfoFullScreenPage">
        {() => {
          return <LoginAdditionalInformationForm isFullScreen />;
        }}
      </Tab.Screen>
      <Tab.Screen name="FreeTrialScreen" component={FreeTrialScreen} />
    </Tab.Navigator>
  );
};

const RootNavigationStackContent = () => {
  return useMemo(() => {
    console.log('RootNavigationStackContent render');
    return (
      <Stack.Navigator
        screenOptions={() => ({
          headerShown: false,
          gestureEnabled: false,
          freezeOnBlur: true,
        })}>
        <Stack.Screen
          name="NonModalRootScreens"
          component={NonModalRootScreens}
        />
        {/* modal with no gestures enabled */}
        <Stack.Group
          // https://reactnavigationRef.org/docs/stack-navigator/#presentation
          screenOptions={{
            presentation: 'transparentModal',
            gestureEnabled: false,
            cardStyle: {backgroundColor: constants?.color.black_60},
          }}>
          <Tab.Screen
            name="LandingPageScavengerHuntInfoModal"
            component={ScavengerHuntInfoStack}
          />
          <Stack.Screen name="PhotosModal" component={PhotosModal} />
          <Stack.Screen name="AllBadgesModal" component={AllBadgesModalStack} />
          <Tab.Screen name="Wanderlists" component={WanderlistStack} />
          <Tab.Screen
            name="WanderlistCreate"
            component={WanderlistCreateStack}
          />
          <Tab.Screen
            name="WanderlistAddLocation"
            component={WanderlistAddLocation}
          />
          <Tab.Screen name="PastHuntsModal" component={PastHuntsModalStack} />
          <Tab.Screen name="EventDashboard" component={EventDashboard} />
        </Stack.Group>
        {/* modals with gestures are below */}
        <Stack.Group
          // https://reactnavigationRef.org/docs/stack-navigator/#presentation
          screenOptions={{
            presentation: 'transparentModal',
            gestureEnabled: true,
            cardStyle: {backgroundColor: constants?.color.black_60},
          }}>
          <Stack.Screen name="LoginModal" component={LoginModal} options={{}} />
          <Stack.Screen
            name="ShowLogsModal"
            component={ShowLogsModal}
            options={{}}
          />
          <Stack.Screen
            name="LoginModalTextForm"
            component={LoginModalTextForm}
            options={{}}
          />
          <Stack.Screen
            name="EmailSignInBlockingPage"
            component={EmailSignInBlockingPage}
            options={
              {
                // modalPresentationStyle: 'overCurrentContext',
                // layout: {
                //   backgroundColor: 'transparent',
                // },
              }
            }
          />
          <Stack.Screen
            name="LoginAdditionalInformationForm"
            component={LoginAdditionalInformationForm}
          />
          <Stack.Screen name="ConfirmModal" component={ConfirmModal} />
          <Stack.Screen name="ReferralModal" component={ReferralModal} />
          <Stack.Screen name="BadgeAwardModal" component={BadgeAwardModal} />
          <Stack.Screen name="SupportModal" component={SupportModal} />
          <Stack.Screen name="ShareModal" component={ShareModal} />
          <Stack.Screen
            name="PhotoScoreAndShareModal"
            component={PhotoScoreAndShareModal}
          />
          <Stack.Screen name="JoinHuntModal" component={JoinHuntModal} />

          <Tab.Screen name="PASubmitChat" component={PersonalAssistantStack} />
          <Tab.Screen name="PAChat" component={PersonalAssistantChatStack} />

          <Stack.Screen
            name="CommunityViewPhoto"
            component={CommunityViewPhoto}
          />
          <Stack.Screen
            name="GrouponRedemption"
            component={GrouponRedemption}
          />
          <Stack.Screen
            name="QuestionFeedBackModal"
            component={QuestionFeedBackModal}
          />
          <Stack.Screen name="CESModal" component={CESModal} />
          <Stack.Screen name="FAQ" component={FAQModal} />
          <Stack.Screen name="PhotoVideoCamera" component={PhotoVideoCamera} />
          <Stack.Screen name="QrModal" component={QrModal} />
          <Stack.Screen
            name="ChallengeCompletedModal"
            component={ChallengeCompletedModal}
          />
          <Stack.Screen name="ReportModal" component={ReportModal} />
          <Stack.Screen name="FAQModal" component={FAQModal} />
          <Stack.Screen
            name="NavigationChallengeModal"
            component={NavigationChallengeModal}
          />
          <Stack.Screen
            name="NavigationChallengeScoreModal"
            component={NavigationChallengeScoreModal}
          />
          <Stack.Screen
            name="ChallengeAttemptAndResultModal"
            component={ChallengeAttemptAndResultModal}
          />
          <Stack.Screen
            name="LocationChallengeListModal"
            component={LocationChallengeListModal}
          />

          <Stack.Screen name="AddPlayerModal" component={AddPlayerModal} />
          <Stack.Screen name="ShareEventModal" component={ShareEventModal} />
          <Stack.Screen
            name="GameLobbyHuntInfoModal"
            component={GameLobbyHuntInfoModal}
          />
          <Stack.Screen name="HuntMap" component={HuntMap} />
          <Stack.Screen
            name="LocalCreateChallengeModal"
            component={LocalCreateChallengeModalStack}
          />
          <Stack.Screen
            name={'LocalCreateLocationModal'}
            component={LocalCreateLocationModalStack}
          />
          <Stack.Screen
            name={'AutoCompleteAddressModal'}
            component={AutoCompleteAddressModal}
          />
          <Stack.Screen name={'LocalSiteModal'} component={LocalSiteModal} />
          <Stack.Screen
            name={'LocalFilterModal'}
            component={LocalFilterModal}
          />
          <Tab.Screen name="WanderlistDetail" component={WanderlistDetail} />
          <Stack.Screen
            name={'LocalCreateChallengeTypeModal'}
            component={LocalCreateChallengeTypeModal}
          />
          <Stack.Screen
            name={'HomeLandingMapModal'}
            component={HomeLandingMapModal}
          />
          <Stack.Screen name={'LocalMapModal'} component={LocalMapModal} />
          <Stack.Screen name={'PurchaseModal'} component={PurchaseModal} />
        </Stack.Group>
      </Stack.Navigator>
    );
  }, []);
};

//eslint-disable-next-line
export default RootNavigationStack;

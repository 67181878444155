import React from 'react';
import {
  SafeAreaView,
  Text,
  TouchableOpacity,
  View,
  useWindowDimensions,
  ScrollView,
  ImageBackground,
  Image,
} from 'react-native';

import constants from 'src/constants';

import {BodyText, ContinueButton} from 'src/Modules/CommonView';
import {navigationRef} from 'src/Nav/navigationHelpers';

import {useUserId} from 'src/Hooks/reduxHooks';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';

const signInImage = require('src/Images/signInImage.png');
const warningIcon = require('src/Images/Icons/warning-error.png');
const buoy = require('src/Images/Icons/lifebuoy_2.png');

import ExpoImage from 'src/Utils/ExpoImage';
import {PADataUpdater, useFilteredPaChats} from './PADataUpdater';
import PARequest from './PARequest';

const PAProfile = () => {
  console.log('ProfilePage Render');
  const {width} = useWindowDimensions();

  const firstName =
    useTypedSelector((state) => state?.user?.info?.firstName) || 'Log In';
  const lastName =
    useTypedSelector((state) => state?.user?.info?.lastName) || 'Log In';

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const recentUserRequests = useFilteredPaChats(false, 2, true);
  const completedUserRequests = useFilteredPaChats(false, 2);
  const activeUserRequests = useFilteredPaChats(true, 2);

  const userPhoto = useTypedSelector(
    (state) =>
      state.user?.info?.customPhotoUrl || state.user?.info?.photoUrl || '',
  );

  const userId = useUserId();

  const explorePass = useExplorerPass();

  return (
    <SafeAreaView
      style={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <ScrollView>
        <ImageBackground
          source={require('src/Images/roadtrip_background.png')}
          style={{
            width: width,
            paddingHorizontal: 24,
            paddingTop: 40,
            paddingBottom: 24,
            gap: 16,
          }}>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 16,
            }}>
            {/* Name and DP */}
            <View>
              <ExpoImage
                source={userPhoto ? {uri: userPhoto} : signInImage}
                style={{
                  width: 86,
                  height: 86,
                  borderRadius: 86,
                  backgroundColor: 'red',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}></ExpoImage>
            </View>
            <Text
              style={{
                fontSize: 20,
                fontFamily: 'JakartaBold',
                color: constants.color.white,
              }}>
              {!!userId
                ? !firstName || firstName?.includes('User')
                  ? 'Your Profile'
                  : `${firstName || ''} ${lastName || ''}`
                : 'Please Log In'}
            </Text>
          </View>
          <ContinueButton
            containerStyle={{
              backgroundColor: '#648A87',
              paddingHorizontal: 24,
              paddingVertical: 16,
              alignItems: 'center',
              gap: 8,
              marginHorizontal: 0,
              marginVertical: 0,
            }}
            height={57}
            handlePress={() =>
              navigationRef.navigate(
                !!explorePass ? 'PersonalAssistant' : 'FreeTrialScreen',
                null,
                'PAProfile.tsx',
              )
            }
            iconImage={require('src/Images/Icons/chat_icon.png')}
            iconImageStyle={{
              height: 30,
              width: 30,
              marginLeft: 0,
              marginRight: 0,
            }}
            text={
              !!explorePass
                ? 'Ask your personal assistant'
                : 'Upgrade to unlock personal assistant'
            }
            textStyle={{fontSize: 15}}></ContinueButton>
        </ImageBackground>
        <View>
          <View
            style={{
              marginTop: 20,
              paddingHorizontal: 24,
            }}>
            <View
              style={{
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Text
                style={{
                  fontSize: 17,
                  fontFamily: 'JakartaBold',
                }}>
                Your most recent requests
              </Text>
            </View>
            {!!userId &&
              recentUserRequests.length > 0 &&
              recentUserRequests?.map((request) => (
                <PARequest key={request.UUID} request={request} />
              ))}
          </View>
          <View
            style={{
              marginTop: 20,
              paddingHorizontal: 24,
            }}>
            <View
              style={{
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Text
                style={{
                  fontSize: 17,
                  fontFamily: 'JakartaBold',
                }}>
                Active Requests
              </Text>

              <TouchableOpacity
                onPress={() => {
                  navigationRef.navigate(
                    'PAActiveRequests',
                    null,
                    'PAProfile.tsx',
                  );
                }}>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants?.color?.black,
                  }}>
                  See all
                </Text>
              </TouchableOpacity>
            </View>
            {!!userId &&
              activeUserRequests.length > 0 &&
              activeUserRequests?.map((request) => (
                <PARequest key={request.UUID} request={request} />
              ))}
          </View>
          <View
            style={{
              marginTop: 20,
              paddingHorizontal: 24,
            }}>
            <View
              style={{
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Text
                style={{
                  fontSize: 17,
                  fontFamily: 'JakartaBold',
                }}>
                Completed Requests
              </Text>
              <TouchableOpacity
                onPress={() => {
                  navigationRef.navigate(
                    'PACompletedRequests',
                    null,
                    'PAProfile.tsx',
                  );
                }}>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'JakartaBold',
                    color: constants.color.black,
                  }}>
                  See all
                </Text>
              </TouchableOpacity>
            </View>
            {!!userId &&
              completedUserRequests.length > 0 &&
              completedUserRequests?.map((request) => (
                <PARequest key={request.UUID} request={request} />
              ))}
          </View>
        </View>
        <View
          style={{
            gap: 20,
            flexDirection: 'row',
            marginTop: 10,
            marginHorizontal: 20,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ExpoImage
            source={warningIcon}
            style={{height: 50, width: 50}}
            resizeMode="contain"
          />
          <Text
            style={{fontSize: 15, fontFamily: 'Jakarta', textAlign: 'center'}}>
            {'Hit a snag with our app?\nCall our support team instead.'}
          </Text>
        </View>

        {!playTestState && (
          <View style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
            <TouchableOpacity
              style={{
                backgroundColor: 'black',
                borderRadius: 8,
                marginVertical: 20,
                marginHorizontal: 12,
                alignItems: 'center',
                justifyContent: 'center',
                paddingVertical: 40,
                paddingHorizontal: 40,
                shadowColor: '#000',
                shadowOffset: {width: 0, height: 1},
                shadowOpacity: 0.6,
                shadowRadius: 3,
              }}>
              <>
                <Image
                  source={buoy}
                  style={{width: 36, height: 36, resizeMode: 'contain'}}
                />
                <View style={{display: 'flex', flexDirection: 'row'}}>
                  <BodyText
                    text={'Call us at'}
                    textStyle={{
                      color: 'white',
                      fontSize: 17,
                      fontFamily: 'JakartaBold',
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  />
                  <BodyText
                    text={'1-833-202-7626'}
                    textStyle={{
                      color: '#EACD72',
                      fontSize: 17,
                      fontFamily: 'JakartaBold',
                      marginLeft: 5,
                      marginRight: 0,
                    }}
                  />
                </View>
                <BodyText
                  text={'for live support'}
                  textStyle={{
                    color: constants.color.white,
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 3,
                  }}
                />
                <BodyText
                  text={'Our friendly agents are standing by.'}
                  textStyle={{
                    color: constants.color.white,
                    fontSize: 12,
                    fontFamily: 'Jakarta',
                    marginLeft: 0,
                    marginRight: 0,
                    marginTop: 12,
                  }}
                />
                <ContinueButton
                  text={'Call support'}
                  height={50}
                  background={'#EACD72'}
                  textStyle={{
                    fontFamily: 'JakartaBold',
                    textAlign: 'center',
                    flex: 1,
                    color: constants?.color?.black,
                  }}
                  containerStyle={{
                    marginTop: 24,
                    borderRadius: 6,
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    shadowColor: '#000',
                    shadowOffset: {width: 0, height: 1},
                    shadowOpacity: 0.6,
                    shadowRadius: 3,
                  }}
                  buttonStyle={{
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  handlePress={() => {
                    navigationRef.navigate(
                      'SupportModal',
                      {},
                      'Nav/DrawerMenu.tsx',
                    );
                  }}
                />
              </>
            </TouchableOpacity>
          </View>
        )}
      </ScrollView>
      {!!userId && <PADataUpdater userId={userId} />}
    </SafeAreaView>
  );
};
export default PAProfile;

import React, {useEffect} from 'react';
import {
  View,
  ScrollView,
  FlatList,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import useLocalState from 'src/Hooks/useLocalState';
import {CarouselCard} from 'src/Modules/CommonView/CarouselView';
import {HeaderAndCarouselView} from 'src/Modules/CommonView/HeaderAndCarouselView';
import {BodyText} from 'src/Modules/CommonView/Text';

import {updateSelectedLocalLocationId} from 'src/Redux/reducers/local.reducer';

import {useUserId} from 'src/Hooks/reduxHooks';

import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import {ContinueButton} from 'src/Modules/CommonView/Buttons';

import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {TLocalLocation} from 'src/types/TLocalLocation';
import SiteStatsCard from 'src/Modules/CommonView/SiteStatsCards';

import {addLocationToGuide} from './localFunctions';
import {dispatchAction} from 'src/Utils/helpers';
import SimpleStarRating from 'src/Modules/CommonView/SimpleStarRating';

// importing detox

const LocalList: React.FC<{
  handleTopPress: (_arg0: 'map' | 'list' | 'leaderboard') => void;
}> = () => {
  const {width} = useWindowDimensionsSafe();
  const localLocationsList = useTypedSelector(
    (state) => state.local.localLocationsList,
  );

  const [hasClosedBestieTest, setHasClosedBestieTest] = useLocalState(
    'hasClosedBestieTest',
    false,
  );

  const userId = useUserId();

  const cardPress = (location: TLocalLocation) => {
    dispatchAction(updateSelectedLocalLocationId(String(location.id)));
    logCustomAnalyticsEvent('local_spot_view', {...location, userId});
    navigationRef.navigate('LocalSiteModal', null, 'Local/LocalList.tsx');
  };

  const localFilters = useTypedSelector((state) => state.local.localFilters);

  useEffect(() => {
    if (hasClosedBestieTest) {
      setHasClosedBestieTest(true);
    }
  }, [hasClosedBestieTest]);

  return (
    <ScrollView
      testID={'LocalListScrollView'}
      style={{backgroundColor: constants.color.white1, flex: 1}}>
      {!!localFilters?.filter_count && (
        <FlatList
          data={localLocationsList}
          removeClippedSubviews
          keyExtractor={(item) => String(item.id)}
          initialNumToRender={5}
          ListEmptyComponent={() => (
            <View>
              <BodyText>No Results For The Given Filter</BodyText>
              <BodyText>
                <ContinueButton
                  text={'Clear Filters'}
                  handlePress={() => {
                    <TouchableWithoutFeedback>
                      <BodyText>Clear Filters</BodyText>
                    </TouchableWithoutFeedback>;
                  }}
                />
              </BodyText>
            </View>
          )}
          contentContainerStyle={{paddingBottom: 60}}
          renderItem={({item}) => {
            console.log({item});
            return (
              <CarouselCard
                key={item.id + item.title}
                imgSource={{
                  uri: item.photo,
                }}
                title={item.title}
                description={item.guide_short_description}
                leftText={`${item.distanceAway} miles away`}
                handlePress={() => cardPress(item)}
                cardWidth={width - 20}
                cardHeight={130}
                rightText={<SimpleStarRating rating={item.rating} />}
              />
            );
          }}
        />
      )}

      <View
        testID="LocalListSwipeTarget"
        style={{
          height: 75,
          marginTop: 10,
          paddingHorizontal: 24,
          gap: 16,
        }}>
        <SiteStatsCard showAdditionalInfo />
      </View>
      <HeaderAndCarouselView
        title={'Recommended Locations Near You'}
        subTitle="These stops are worth planning a day to see."
        type={'local_card'}
        cardPress={cardPress}
        initialMaxLength={7}
        data={localLocationsList}
        carouselIndex={0}
        buttonColor={constants?.color?.orange}
      />
      <TouchableOpacity
        style={{
          paddingHorizontal: 12,
          marginTop: 15,
        }}
        onPress={() => {
          navigationRef.navigate('Wanderlists', null, 'LocalList.tsx');
        }}>
        <ImageBackground
          source={require('src/Images/palm_trees.png')}
          style={{
            backgroundColor: 'black',
            borderRadius: 8,
            alignItems: 'center',
            paddingVertical: 40,
            paddingHorizontal: 30,
            shadowColor: '#000',
            shadowOffset: {width: 0, height: 1},
            shadowOpacity: 0.3,
            shadowRadius: 3,
          }}>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <BodyText
              text={'Wanderlists'}
              textStyle={{
                color: constants.color.white,
                fontSize: 20,
                fontFamily: 'JakartaBold',
                marginLeft: 5,
                marginRight: 0,
              }}
            />
          </View>
          <BodyText
            text={
              'Explore curated and user-submitted itineraries to guide your next adventure.'
            }
            textStyle={{
              color: constants.color.white,
              fontSize: 15,
              fontFamily: 'Jakarta',
              marginLeft: 0,
              marginRight: 0,
              marginTop: 12,
              textAlign: 'center',
              maxWidth: 250,
            }}
          />
          <ContinueButton
            text={'Discover'}
            height={50}
            background={constants.color.teal}
            textStyle={{
              fontFamily: 'JakartaBold',
            }}
            containerStyle={{
              marginTop: 24,
              borderRadius: 6,
              width: '100%',
              marginBottom: 40,
            }}
            handlePress={() => {
              navigationRef.navigate('Wanderlists', null, 'LocalList.tsx');
            }}
          />
        </ImageBackground>
      </TouchableOpacity>
      {/* <StylizedBox
        buttonContainerStyles={{marginBottom: 60}}
        containerStyles={{marginTop: 20, paddingHorizontal: 12}}
        backgroundContainerStyles={{
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
        }}
        ctaText="Discuss"
        title="Join local conversation"
        backgroundColor={constants.color.orange}
        description={
          'Share your local expertise with other community members and find new local favorites.'
        }
        onPress={() => {
          // navigate to local conversations
        }}
      /> */}
      {!localFilters?.filter_count && (
        <View testID={'localInto'} style={{paddingLeft: 5, paddingRight: 5}}>
          <ContinueButton
            text={'Submit a new stop'}
            textStyle={{fontFamily: 'JakartaBold'}}
            height={60}
            containerStyle={{
              marginTop: 32,
              marginBottom: 65,
              borderRadius: 6,

              height: 60,
            }}
            handlePress={() => {
              addLocationToGuide();
            }}
          />
          <View style={{width: 200, height: 200}} />
        </View>
      )}
    </ScrollView>
  );
};

export default LocalList;

import {logForCrashlytics} from 'src/Utils/fireUtils';
import {TChallenge} from 'src/types/TChallenge';

/**
 * Parses player challenge data and extracts relevant information.
 * @param {Object} playerChallengeData - The player challenge data object.
 * @returns {Object} An object containing various parsed properties.
 */
export function parsePlayerChallengeData(playerChallengeData: object) {
  logForCrashlytics('parsePlayerChallengeData fired in dataHelper');
  const playerChallengePhotos: Array<{
    challenge: string;
    downloadURL: string;
    userId: string;
    pointsEarned: number | null;
    shareUUID: string;
    type: string;
    isVideo: boolean;
    thumbnail: string | null;
  }> = [];

  const playerChallengeDataActive: TChallenge[] = [];
  const playerChallengeDataCompleted: TChallenge[] = [];

  let playerChallengeDataActiveCount = 0;
  let playerChallengeDataCount = 0;

  const timeNow = Date.now();
  playerChallengeData &&
    Object.values(playerChallengeData).map?.((challenge) => {
      playerChallengeDataCount++;
      if (challenge.pointsEarned > -1) {
        playerChallengeDataCompleted?.push?.(challenge);

        // formatting player challenges to new format
        if (
          challenge &&
          challenge.answers &&
          challenge.answers?.includes('|')
        ) {
          let formattedAnswers: {text: string; correct: boolean}[] = [];
          // let correctAnswer = '';

          if (challenge.answers) {
            formattedAnswers = challenge.answers
              .split('|')
              ?.map?.((answer: string) => {
                const split = answer.split(':');
                if (split[1] == 'true') {
                  // correctAnswer = split[0];
                }
                return {text: split[0], correct: split[1] == 'true'};
              });
          }
          challenge.answers = formattedAnswers;
        }

        if (challenge.photoUrl) {
          playerChallengePhotos.push({
            challenge: challenge.task,
            downloadURL: challenge.photoUrl,
            userId: challenge.userId,
            pointsEarned: challenge.pointsEarned,
            shareUUID: challenge.shareUUID || '',
            type: 'Player',
            isVideo: challenge.isVideo || false,
            thumbnail: challenge.thumbnail || '',
          });
        }
      } else if (challenge.deliveryTime <= timeNow) {
        playerChallengeDataActive.push(challenge);
        playerChallengeDataActiveCount++;
      }
    });

  return {
    playerChallengeData,
    playerChallengePhotos,
    playerChallengeDataActive,
    playerChallengeDataCompleted,
    playerChallengeDataActiveCount,
    playerChallengeDataCount,
  };
}

/**
 * Parses HTML text and removes unwanted elements and tags.
 * @param {string} text - The HTML text to be parsed.
 * @returns {string} The parsed text.
 */
export const parseHTML = (text: string) => {
  return (
    text
      ?.replace?.(/<br>/gi, '')
      .replace(/&nbsp;/gi, '')
      .replace(/<p><\/p>/gi, '')
      .replace(/<p[^>]*>[\s|&nbsp;]*<\/p>/, '')
      .replace(/style="[\w\W][^"]*"/gi, '')
      .replace(/style'[\w\W][^']*'/gi, '')
      .replace(/&nbsp;/gi, '')
      .replace(/<\/p><p>/gi, '\n\n')
      .replace(/<\/p>/gi, '')
      .replace(/<p>/gi, '')
      .replace(/&#700;/g, `'`)
      .replace(/&#8221;/g, '"')
      .replace(/&#8212;/g, ', ')
      .replace(/&#8220;/g, '"')
      .replace(/(<p[^>]+?>|<p>|<\/p>)/i, '')
      .replace(/(<p[^>]+?>|<p>|<\/p>)/gim, '\n\n') || ''
  );
};

import constants from 'src/constants';
import {navigationRef} from 'src/Nav/navigationHelpers';
import React, {useEffect} from 'react';
import {TouchableOpacity, View} from 'react-native';
import LineIcon from 'react-native-vector-icons/SimpleLineIcons';

///////////////////////////
///////////// components
import {BodyText} from 'src/Modules/CommonView';

import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';

interface ChallengeCompletedModalProps {
  onTimeout?: () => void;
  backgroundColor?: string;
  text?: string;
  color?: string;
  timeUp?: boolean;
}

const ChallengeCompletedModal: React.FC<ChallengeCompletedModalProps> = ({
  onTimeout,
  backgroundColor = constants?.color?.blue,
  text = 'Time Is Up',
  color = constants?.color?.white,
  timeUp = false,
}) => {
  useEffect(() => {
    setTimeout(() => {
      if (onTimeout) {
        onTimeout();
      } else {
        navigationRef?.goBack(
          'ScavengerHunt_v2/Game/ChallengeCompletedModal.tsx',
        );
      }
    }, 1200);
  }, []);

  console.log('ChallengeCompleteModal Render');

  return (
    <View
      style={{
        alignItems: 'center',
        backgroundColor,
        flex: 1,
        justifyContent: 'center',
      }}>
      <BodyText
        text={text}
        textStyle={{
          textAlign: 'center',
          color,
          fontSize: 24,
          lineHeight: 26,
        }}
      />
      <BodyText
        text={`Calculating score...`}
        textStyle={{
          textAlign: 'center',
          fontStyle: 'italic',
          color,
        }}
      />
      {!!timeUp && (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginVertical: 20,
          }}>
          <LineIcon name={'clock'} size={16} color={color} />
          <BodyText text={`00:00`} textStyle={{marginTop: 0, color}} />
        </View>
      )}

      <CustomActivityIndicator
        size={'small'}
        color={color}
        style={{marginTop: 10}}
      />
      <TouchableOpacity
        style={{
          position: 'absolute',
          bottom: 80,
          height: 60,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onPress={() =>
          navigationRef.navigate(
            'ReportModal',
            null,
            'ScavengerHunt_v2/Game/ChallengeCompletedModal.tsx',
          )
        }>
        <LineIcon name="flag" color={color} size={18} />
        <BodyText
          text={'Report'}
          textStyle={{marginTop: 5, fontSize: 12, lineHeight: 14, color}}
        />
      </TouchableOpacity>
    </View>
  );
};

export default ChallengeCompletedModal;

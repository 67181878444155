// Define the Hunt interface with optional properties
import * as t from 'io-ts';
import {validateResult} from 'src/types/typesHelpers';

export const TPastGroupIO = t.type({
  hunt_name: t.union([t.string, t.null, t.undefined]),
  huntPhotoURL: t.union([t.string, t.null, t.undefined]),
  group_name: t.union([t.string, t.null, t.undefined]),
  group_photo_count: t.union([t.number, t.null, t.undefined]),
  group_photos: t.union([t.string, t.null, t.undefined]),
  group_id: t.union([t.string, t.null]),
});

// Derive the TypeScript type from the io-ts type
export type TPastGroup = t.TypeOf<typeof TPastGroupIO>;

export const isTPastGroup = (
  input: unknown,
  logErrors: boolean,
): input is TPastGroup => {
  return validateResult(input, TPastGroupIO, 'TPastGroup', logErrors);
};

import constants from 'src/constants';
import React, {useRef, useEffect, useState} from 'react';
import {
  View,
  Platform,
  ImageSourcePropType,
  TouchableOpacity,
  Animated,
  Easing,
  Text,
} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {
  ImageButton,
  ContinueButton,
  FooterOverlay,
  BodyText,
} from 'src/Modules/CommonView';

import {apiUpdateUserBadges} from 'src/Utils/apiCalls';
import {socialShare} from 'src/Utils/shareHelper';

const facebookIcon: ImageSourcePropType = require('src/Images/Share/facebookShareIcon.png');
const instagramIcon: ImageSourcePropType = require('src/Images/Share/instagramShareIcon.png');
const otherIcon: ImageSourcePropType = require('src/Images/Share/otherShareIcon.png');

import {SafeAreaView} from 'react-native-safe-area-context';
import {navigationRef} from 'src/Nav/navigationHelpers';
import useParams from 'src/Hooks/useParams';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import ExpoImage from 'src/Utils/ExpoImage';
import {useImageSource} from 'src/Hooks/gameHooks';
import {updateDataAtPath, useFirebaseData} from 'src/Utils/fireUtils';
import {classicPhotoVideoCompletionEvent} from 'src/Utils/huntFunctions_classic';
import {TChallenge} from 'src/types/TChallenge';

const PhotoScoreAndShareModal: React.FC = () => {
  const {
    shareObject = {},
    shareOrigin,
    huntType,
    shareOverride,
    showCorrectPoints = 0,
    photoCompletionPoints,
    challenge,
  } = useParams<'PhotoScoreAndShareModal'>();

  const {width} = useWindowDimensionsSafe();

  const eventInfoData = useTypedSelector((state) => state?.event?.info);
  const groupId = useTypedSelector((state) => state.group.info?.groupId);
  const imageSource = useImageSource();

  const challengeCompletionData =
    useFirebaseData(
      `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challenge?.challengeId}`,
      null,
      null,
      null,
      false,
      !groupId,
    ) || {};

  const photoScoreData = challengeCompletionData?.bonusScoreData;
  const points = challengeCompletionData?.points;

  const [isLoading, setIsLoading] = useState(true);

  const showFacebookShare = !eventInfoData?.hideFbShare;
  const showInstagramShare =
    shareObject.challengeType !== 'video' &&
    shareObject.challengeType !== 'Video' &&
    Platform.OS !== 'android' &&
    !eventInfoData?.hideInstaShare;

  // Animation setup for skeleton loading effect
  const skeletonOpacity = useRef(new Animated.Value(0.3)).current;

  useEffect(() => {
    // Looping animation to create the loading shimmer effect
    Animated.loop(
      Animated.sequence([
        Animated.timing(skeletonOpacity, {
          toValue: 1,
          duration: 1000,
          easing: Easing.inOut(Easing.ease),
          useNativeDriver: true,
        }),
        Animated.timing(skeletonOpacity, {
          toValue: 0.3,
          duration: 1000,
          easing: Easing.inOut(Easing.ease),
          useNativeDriver: true,
        }),
      ]),
    ).start();

    // Set loading state based on actual data availability
    if (
      challengeCompletionData &&
      'isGradingInProgress' in challengeCompletionData
    ) {
      setIsLoading(challengeCompletionData.isGradingInProgress);
    }
  }, [skeletonOpacity, challengeCompletionData]);

  // Function to render skeleton placeholders with optional overlay text
  const renderSkeleton = (
    width: number,
    height: number,
    marginBottom = 10,
    marginTop = 0,
    overlayText = '',
  ) => (
    <View
      style={{
        position: 'relative',
        alignSelf: 'center',
        marginBottom,
        marginTop,
      }}>
      <Animated.View
        style={{
          width,
          height,
          borderRadius: 4,
          backgroundColor: constants?.color?.gray1,
          opacity: skeletonOpacity,
        }}
      />
      {overlayText ? (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}>
          <Text
            style={{
              color: 'rgba(0, 0, 0, 0.5)',
              fontWeight: '600',
              fontSize: 16,
              textAlign: 'center',
              includeFontPadding: false, // remove extra padding inside the Text
              textAlignVertical: 'center',
            }}>
            {overlayText}
          </Text>
        </View>
      ) : null}
    </View>
  );

  const handleShare = (targetMedia: string) => {
    if (shareOverride) {
      return shareOverride();
    }

    socialShare(shareObject, shareOrigin, huntType, targetMedia);
  };

  const handleSelectChallenge = (challenge: TChallenge) => {
    const {type, challengeId} = challenge;

    if (type === 'photo' || type === 'video') {
      return navigationRef.navigate(
        'PhotoVideoCamera',
        {
          onPhotoFirstUploadedToFirebase: (firebasePhoto) => {
            updateDataAtPath(
              `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}`,
              {
                preliminaryFirebasePhoto: firebasePhoto,
                preliminaryFirebasePhotoAttemptTime: Date.now(),
                isGradingInProgress: true,
              },
            );
          },
          onCapture: (data) => {
            if (type === 'photo') {
              handlePhotoComplete({
                photoUrl: data?.downloadURL,
                shareUUID: data?.shareUUID,
                challenge,
              });
            }
          },
          onReport: () => {
            navigationRef.navigate(
              'ReportModal',
              null,
              'ScavengerHunt_v2/Classic/LocationChallengeListModal.tsx',
            );
          },
          aiScoring: type == 'photo',
          isRetake: true,
          text: challenge.question || 'Take a photo',
          shareUUID: challenge.shareUUID,
          photoUrl: null,
          isVideo: type == 'video',
          challengeId: challenge.challengeId,
        },
        'LocationChallengeListModal',
      );
    }

    return navigationRef.navigate(
      'ChallengeAttemptAndResultModal',
      {
        challengeId: challenge.challengeId,
      },
      'LocationChallengeListModal',
    );
  };

  const handlePhotoComplete = async ({
    photoUrl,
    shareUUID,
    challenge,
  }: {
    photoUrl: string;
    shareUUID: string;
    challenge: TChallenge;
  }) => {
    const {challengeId, question} = challenge;

    challengeId &&
      classicPhotoVideoCompletionEvent({
        photoUrl: photoUrl,
        challengeId,
        shareUUID,
        description: question || 'Challenge',
      });
  };

  return (
    <SafeAreaView style={{flex: 1, justifyContent: 'flex-end'}}>
      <View
        style={{
          width,
          backgroundColor: constants?.color?.white,
          paddingVertical: 10,
          paddingHorizontal: 10,
          paddingBottom: 100,
        }}>
        <View style={{alignItems: 'center'}}>
          <ExpoImage
            style={{width: width - 20, height: 100}}
            source={imageSource}
            resizeMode={'contain'}
          />
        </View>

        <View style={{marginTop: 10, marginBottom: 20}}>
          <BodyText
            text={'🎉 Challenge Complete! 🎉'}
            textStyle={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: 18,
              lineHeight: 20,
              fontFamily: constants?.font.circularBold,
            }}
          />
          <BodyText
            text={'You & Your Team Earned'}
            textStyle={{textAlign: 'center', fontWeight: '700'}}
          />

          {isLoading ? (
            renderSkeleton(150, 26, 0, 10, 'Loading...')
          ) : (
            <BodyText
              text={`${
                photoScoreData?.accuracy_score +
                photoScoreData?.fun_of_photo_creativity +
                points
              } pts`}
              textStyle={{
                textAlign: 'center',
                fontWeight: '700',
                fontSize: 24,
                lineHeight: 26,
                color: constants?.color?.orange,
              }}
            />
          )}
        </View>

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {/* Skeleton or Photo Score Breakdown */}
          {isLoading ? (
            renderSkeleton(200, 20)
          ) : (
            <BodyText
              text={
                photoScoreData && !challengeCompletionData?.isGradingInProgress
                  ? '📸 Photo Score Breakdown'
                  : 'Grading photo...'
              }
              center
              textStyle={{
                marginBottom: 10,
                marginRight: 5,
                marginHorizontal: 0,
                color: 'black',
              }}
            />
          )}
        </View>

        {isLoading ? (
          <View>
            {renderSkeleton(300, 20)}
            {renderSkeleton(300, 20)}
            {renderSkeleton(300, 20)}
          </View>
        ) : (
          !!photoScoreData &&
          !challengeCompletionData?.isGradingInProgress && (
            <View>
              <BodyText
                text={`✅ Completion: ${photoCompletionPoints} / ${photoCompletionPoints}`}
                center
                textStyle={{
                  marginRight: 5,
                  marginHorizontal: 0,
                  color: 'black',
                  lineHeight: 22,
                }}
              />
              <BodyText
                text={`🎯 Accuracy: ${photoScoreData?.accuracy_score} / 100`}
                center
                textStyle={{
                  marginRight: 5,
                  marginHorizontal: 0,
                  color: 'black',
                  lineHeight: 22,
                }}
              />
              <BodyText
                text={`🎨 Creativity: ${photoScoreData?.fun_of_photo_creativity} / 100`}
                center
                textStyle={{
                  marginRight: 5,
                  marginHorizontal: 0,
                  color: 'black',
                  lineHeight: 22,
                }}
              />
            </View>
          )
        )}

        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <BodyText
            text={'🎁 Share for a chance to win prizes!'}
            center
            textStyle={{
              marginRight: 5,
              marginHorizontal: 0,
              color: 'black',
              marginTop: 40,
              lineHeight: 22,
            }}
          />
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            backgroundColor: constants?.color?.white,
            marginTop: 10,
            borderRadius: 5,
          }}>
          {!!showFacebookShare && (
            <ImageButton
              source={facebookIcon}
              buttonStyle={{
                width: 80,
                height: 80,
                alignItems: 'center',
              }}
              imageStyle={{
                width: 40,
                height: 40,
                borderColor: 'black',
                borderWidth: 1,
                borderRadius: 50,
              }}
              textStyle={{
                color: constants?.color?.blue,
                fontSize: 16,
                marginTop: 5,
              }}
              handlePress={() => handleShare('Facebook')}
              text={'facebook'}
            />
          )}
          {!!showInstagramShare && (
            <ImageButton
              source={instagramIcon}
              buttonStyle={{width: 80, height: 80, alignItems: 'center'}}
              imageStyle={{
                width: 40,
                height: 40,
                borderColor: 'black',
                borderWidth: 1,
                borderRadius: 50,
              }}
              textStyle={{
                color: constants?.color?.blue,
                fontSize: 16,
                marginTop: 5,
              }}
              handlePress={() => handleShare('Instagram')}
              text={'instagram'}
            />
          )}
          <ImageButton
            source={otherIcon}
            buttonStyle={{width: 80, height: 80, alignItems: 'center'}}
            imageStyle={{
              width: 40,
              height: 40,
              borderColor: 'black',
              borderWidth: 1,
              borderRadius: 50,
            }}
            textStyle={{
              color: constants?.color?.blue,
              fontSize: 16,
              marginTop: 5,
            }}
            handlePress={() => handleShare('Other')}
            text={'other'}
          />
        </View>

        <FooterOverlay
          footerStyle={{
            backgroundColor: 'transparent',
            paddingBottom: 0,
            bottom: !!showCorrectPoints ? 20 : 10,
            flexDirection: 'column',
          }}>
          <ContinueButton
            handlePress={() => {
              try {
                apiUpdateUserBadges();
              } catch (error) {
                console.log('could not assign badges');
              }
              navigationRef?.goBack('Modules/PhotoScoreAndShareModal.tsx');
            }}
            background={constants?.color?.blue}
            text={'Close'}
            buttonStyle={{width: '100%'}}
          />
          <TouchableOpacity
            onPress={() => challenge && handleSelectChallenge(challenge)}
            style={{
              borderColor: 'black',
              borderWidth: 1,
              width: '100%',
              borderRadius: 10,
              backgroundColor: constants.color.gray1,
              height: 40,
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <BodyText
              text={`📷 Retake Photo`}
              center
              textStyle={{
                marginRight: 5,
                marginHorizontal: 0,
                color: constants.color.black,
                lineHeight: 22,
                marginTop: 0,
                width: '100%',
                borderRadius: 10,
                shadowColor: constants.color.black,
                shadowOffset: {width: 0, height: 1},
                shadowOpacity: 0.3,
                shadowRadius: 3,
              }}
            />
          </TouchableOpacity>
        </FooterOverlay>
      </View>
    </SafeAreaView>
  );
};

export default PhotoScoreAndShareModal;

import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState} from 'react';
import {Alert, Text, TextInput, TouchableOpacity, View} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import Modal from 'react-native-modal';

import {updateDataAtPath} from 'src/Utils/fireUtils';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';
import {navigationRef} from 'src/Nav/navigationHelpers';

interface AddToDeviceModalProps {
  closeModal: () => void;
  addChildUser: (
    _firstName: string,
    _parentUserId: string,
    _parentUserEmail: string,
    _groupId: string,
  ) => void;
  isVisible: boolean;
}

const AddToDeviceModal: React.FC<AddToDeviceModalProps> = ({
  closeModal,
  addChildUser,
  isVisible,
}) => {
  const {width} = useWindowDimensionsSafe();

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const userId = useTypedSelector((state) => state.user?.userId);
  const email = useTypedSelector((state) => state.user?.info?.email);
  const tickets = useTypedSelector(
    (state) => state?.user?.info?.tickets,
    deepEqualityCheck,
  );
  const userHasPass = useTypedSelector(
    (state) => state.user?.info?.pass?.passType,
  );
  const [playerName, setPlayerName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const groupId = useTypedSelector((state) => state?.group?.info?.groupId);
  const passId = useTypedSelector((state) => state?.group?.info?.passId);
  const theme = useTypedSelector((state) => state?.group?.info?.theme);
  const players = useTypedSelector(
    (state) => state?.group?.info?.players,
    deepEqualityCheck,
  );

  const eventId = useTypedSelector((state) => state?.group?.info?.eventId);
  const isFree = useTypedSelector((state) => state?.group?.info?.isFree);
  const passPlayers = useTypedSelector((state) => {
    if (typeof state === 'number') {
      return state;
    } else if (typeof state === 'string') {
      const parsedValue = parseInt(state, 10);
      return isNaN(parsedValue) ? 0 : parsedValue;
    } else {
      return 0;
    }
  });

  const error = '';

  const handleInputChange = (playerName: string) => {
    setPlayerName(playerName);
  };

  const handleAddPlayer = () => {
    setLoading(true);
    const isPass = passId;
    const canJoinWithPass =
      isPass && players && Object.values(players).length < passPlayers;

    const nameIsUnique =
      players &&
      !Object.values(players).some((player) => {
        return player.firstName?.toLowerCase?.() == playerName?.toLowerCase?.();
      });

    const playersOnDevice =
      players &&
      Object.values(players).filter((player) => {
        return player.parentUserId == userId || player.userId == userId;
      });

    const groupNeedsTicketsToJoin =
      !playTestState &&
      !eventId &&
      !isFree &&
      !theme?.includes?.('Home') &&
      !canJoinWithPass &&
      !userHasPass;

    console.log('addTicketModal', {theme, groupNeedsTicketsToJoin});

    logCustomAnalyticsEvent('add_additional_player_to_same_device', {
      userId,
    });

    if (playersOnDevice && playersOnDevice?.length >= 10) {
      setLoading(false);
      Alert.alert('Only 10 Players allowed on a single device.');
    } else if (playerName?.length < 1) {
      setLoading(false);
      Alert.alert('Please use a longer name.');
    } else if (!nameIsUnique) {
      setLoading(false);
      Alert.alert(
        'A player with that name already exists. Please use a nickname instead.',
      );
    } else if (!groupNeedsTicketsToJoin) {
      userId &&
        groupId &&
        email &&
        addChildUser(playerName, userId, email, groupId);
      setPlayerName('');
      setLoading(false);
      closeModal();
    } else if (theme && tickets?.[theme]) {
      const newTicketsNum = Number(tickets?.[theme]) - 1;
      updateDataAtPath(`users/${userId}/tickets/${theme}`, newTicketsNum);

      //console.log('NEW TICKETS', newTicketsNum)
      userId &&
        groupId &&
        email &&
        addChildUser(playerName, userId, email, groupId);
      setPlayerName('');
      setLoading(false);
      closeModal();
    } else {
      Alert.alert(
        'You need tickets!',
        `You need at least one ${theme} Ticket to add another player to your device`,
        [
          {
            text: 'Cancel',
            onPress: () => setLoading(false),
          },
          {
            text: 'Ok',
            onPress: () => {
              navigationRef.navigate(
                'PurchaseModal',
                {},
                'Modules/CommonGame/AddToDeviceModal.tsx',
              );
            },
          },
        ],
        {cancelable: false},
      );
    }
  };

  return (
    <Modal isVisible={isVisible}>
      <View
        style={{
          flex: 1,
          marginTop: 60,
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}>
        <View
          style={{
            height: 350,
            width: width - 100,
            backgroundColor: 'white',
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 5,
            alignItems: 'center',
            borderRadius: 5,
          }}>
          <Text
            style={{
              fontSize: 20,
              textAlign: 'center',
              color: constants?.color?.orange,
              fontFamily: constants?.font.circular,
              fontWeight: '700',
              marginTop: 20,
              marginBottom: 20,
            }}>
            Player Name
          </Text>

          {error != null && (
            <Text
              style={{
                color: 'red',
                fontFamily: 'CircularStd-Book',
                fontSize: 12,
                textAlign: 'center',
                marginTop: 5,
              }}>
              {error}
            </Text>
          )}

          <TextInput
            style={{
              width: 220,
              height: 40,
              borderColor: constants?.color?.orange,
              borderWidth: 1,
              paddingBottom: 5,
              borderRadius: 10,
            }}
            allowFontScaling={false}
            testID={'playerName'}
            textAlign={'center'}
            value={playerName}
            autoCorrect={false}
            underlineColorAndroid={'transparent'}
            onChangeText={handleInputChange}
            maxLength={12}
            blurOnSubmit
          />
          <Text
            style={{
              color: constants?.color?.gray3,
              fontFamily: 'CircularStd-Book',
              fontSize: 12,
              textAlign: 'center',
              marginTop: 5,
            }}>
            {`Add a player to play on your device. For the best experience, we recommend players join on their own device.  ${
              !!playTestState || eventId || isFree
                ? ''
                : 'This will use one of your ' + theme + ' tickets.'
            } You may add up to 4 players on a single device.`}
          </Text>
          <TouchableOpacity
            testID="confirmPlayerName"
            style={{
              backgroundColor: constants?.color?.orange,
              borderRadius: 10,
              height: 40,
              marginTop: 20,
              width: 210,
              alignContent: 'center',
              justifyContent: 'center',
            }}
            onPress={handleAddPlayer}>
            {loading == false && (
              <Text
                style={{
                  color: constants?.color?.white,
                  fontWeight: '700',
                  fontSize: 16,
                  fontFamily: constants?.font.circular,
                  textAlign: 'center',
                }}>
                Add Player
              </Text>
            )}

            {loading == true && (
              <CustomActivityIndicator size={'small'} color={'white'} />
            )}
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              backgroundColor: constants?.color?.gray3,
              borderRadius: 10,
              height: 40,
              marginTop: 20,
              width: 210,
              alignContent: 'center',
              justifyContent: 'center',
            }}
            onPress={() => {
              setLoading(true);
              setPlayerName('');
              closeModal();
            }}>
            <Text
              style={{
                color: constants?.color?.white,
                fontWeight: '700',
                fontSize: 16,
                fontFamily: constants?.font.circular,
                textAlign: 'center',
              }}>
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default AddToDeviceModal;

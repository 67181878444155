// this reducer is used to store the info for new type of location challenges
// as opposed to the game.reducer which stores player / team challenges (generated challenges)

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TAudioTourItem} from 'src/types/TAudioTourItem';
import {TChallenge} from 'src/types/TChallenge';
import {TLocation} from 'src/types/TLocation';

interface IsChallengeCloseInformation {
  locationId: string | null;
  isClose: boolean;
}

interface GameV2State {
  locations?: Record<string, TLocation>;
  locationList?: string[]; // this is correct, this is just a string array of the locationIds
  backupLocationList?: TLocation[] | null;
  challenges?: Record<string, TChallenge>;
  subChallenges?: Record<string, TChallenge>;
  huntIntro?: string;
  huntOutro?: string;
  teamPhotoUrl?: string;
  huntLength?: number;
  corporateBrandingUrl?: string | undefined;
  classicChallengeList?: string[]; // list of the challenge ids
  timerStart?: number | undefined;
  timerFinish?: number | undefined;
  timerLimitMinutes?: number | undefined;
  currentLocationId?: string | null;

  hunt_has_required_checkout_location: boolean | null;
  currentChallengeId?: string | null;
  allChallenges?: Record<string, TChallenge>;
  currentAudioTourIndex?: number;
  gameHeaderMessageOverride: string | null;
  isChallengeCloseInformation?: IsChallengeCloseInformation | null; // Replace 'any' with a more specific type if possible.
  lastUsedBackUpBar: null;
  audioTourOrder: TAudioTourItem[] | null;
}

const initialState: GameV2State = {
  locations: {}, // all the locations on the scavenger hunt
  locationList: [], // the locations as a list
  challenges: {}, // all the challenges on the scavenger hunt
  huntIntro: '', // the hunt intro
  huntOutro: '', // the hunt outro
  hunt_has_required_checkout_location: false, //whether there is a required checkout location
  teamPhotoUrl: '', // the team photo taken at the start of the hunt
  huntLength: 0, // the number of hunt locations
  corporateBrandingUrl: '', // a branding photo added by the corporate event
  classicChallengeList: [], // a list of the classic challenges
  timerStart: undefined, //when the hunt started (classic time limit)
  timerFinish: undefined, // when the hunt finished
  timerLimitMinutes: undefined, // the max time limit
  currentLocationId: null,
  currentChallengeId: null,
  allChallenges: {},
  currentAudioTourIndex: 0,
  gameHeaderMessageOverride: null,
  isChallengeCloseInformation: null,
  lastUsedBackUpBar: null,
  audioTourOrder: null,
  backupLocationList: null,
};

const gameV2Slice = createSlice({
  name: 'game_v2',
  initialState,
  reducers: {
    setLocationChallengeData(state, action: PayloadAction<GameV2State>) {
      if (
        action.payload.locations &&
        JSON.stringify(state.locations) !==
          JSON.stringify(action.payload.locations)
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'locations updated',
        );
        state.locations = action.payload.locations;
      }

      if (
        action.payload.allChallenges &&
        JSON.stringify(state.allChallenges) !==
          JSON.stringify(action.payload.allChallenges)
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'allChallenges updated',
        );
        state.allChallenges = action.payload.allChallenges;
      }

      if (
        action.payload.locationList &&
        JSON.stringify(state.locationList) !==
          JSON.stringify(action.payload.locationList)
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'locationList updated',
        );
        state.locationList = action.payload.locationList;
      }

      if (
        action.payload.classicChallengeList &&
        JSON.stringify(state.classicChallengeList) !==
          JSON.stringify(action.payload.classicChallengeList)
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'classicChallengeList updated',
        );
        state.classicChallengeList = action.payload.classicChallengeList;
      }

      if (
        action.payload.audioTourOrder &&
        JSON.stringify(state.audioTourOrder) !==
          JSON.stringify(action.payload.audioTourOrder)
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'audioTourOrder updated',
        );
        state.audioTourOrder = action.payload.audioTourOrder;
      }

      if (
        action.payload.huntIntro &&
        state.huntIntro !== action.payload.huntIntro
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'huntIntro updated',
        );
        state.huntIntro = action.payload.huntIntro;
      }

      if (
        action.payload.huntOutro &&
        state.huntOutro !== action.payload.huntOutro
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'huntOutro updated',
        );
        state.huntOutro = action.payload.huntOutro;
      }

      if (
        action.payload.hunt_has_required_checkout_location &&
        state.hunt_has_required_checkout_location !==
          action.payload.hunt_has_required_checkout_location
      ) {
        console.log(
          'dispatchAction: hunt_has_required_checkout_location',
          'hunt_has_required_checkout_location updated',
        );
        state.hunt_has_required_checkout_location =
          action.payload.hunt_has_required_checkout_location;
      }

      if (
        action.payload.huntLength &&
        state.huntLength !== action.payload.huntLength
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'huntLength updated',
        );
        state.huntLength = action.payload.huntLength;
      }

      if (
        action.payload.timerStart &&
        state.timerStart !== action.payload.timerStart
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'timerStart updated',
        );
        state.timerStart = action.payload.timerStart;
      }

      if (
        action.payload.timerFinish &&
        state.timerFinish !== action.payload.timerFinish
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'timerFinish updated',
        );
        state.timerFinish = action.payload.timerFinish;
      }

      if (
        action.payload.timerLimitMinutes &&
        state.timerLimitMinutes !== action.payload.timerLimitMinutes
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'timerLimitMinutes updated',
        );
        state.timerLimitMinutes = action.payload.timerLimitMinutes;
      }

      if (
        action.payload.corporateBrandingUrl &&
        state.corporateBrandingUrl !== action.payload.corporateBrandingUrl
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'corporateBrandingUrl updated',
        );
        state.corporateBrandingUrl = action.payload.corporateBrandingUrl;
      }

      if (
        action.payload.subChallenges &&
        JSON.stringify(state.subChallenges) !==
          JSON.stringify(action.payload.subChallenges)
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'subChallenges updated',
        );
        state.subChallenges = action.payload.subChallenges;
      }

      if (
        action.payload.backupLocationList &&
        JSON.stringify(state.backupLocationList) !==
          JSON.stringify(action.payload.backupLocationList)
      ) {
        console.log(
          'dispatchAction: setLocationChallengeData',
          'backupLocationList updated',
        );
        state.backupLocationList = action.payload.backupLocationList;
      }

      if (
        action.payload.lastUsedBackUpBar &&
        state.lastUsedBackUpBar !== action.payload.lastUsedBackUpBar
      ) {
        state.lastUsedBackUpBar = action.payload.lastUsedBackUpBar;
      }
    },
    setCurrentLocationId(state, action: PayloadAction<string | null>) {
      state.currentLocationId = action.payload;
    },
    setCurrentChallengeId(state, action: PayloadAction<string | null>) {
      state.currentChallengeId = action.payload;
    },
    setCurrentAudioTourIndex(state, action: PayloadAction<number>) {
      state.currentAudioTourIndex = action.payload;
    },
    setGameHeaderMessageOverride(state, action: PayloadAction<string | null>) {
      state.gameHeaderMessageOverride = action.payload;
    },
    setIsChallengeCloseInformation(
      state,
      action: PayloadAction<IsChallengeCloseInformation>,
    ) {
      state.isChallengeCloseInformation = action.payload;
    },
    // used when joining a group
    resetChallengeData() {
      return {...initialState};
    },
  },
});

export const {
  setLocationChallengeData,
  setCurrentLocationId,
  setCurrentChallengeId,
  setCurrentAudioTourIndex,
  setGameHeaderMessageOverride,
  setIsChallengeCloseInformation,
  resetChallengeData,
} = gameV2Slice.actions;

export default gameV2Slice.reducer;

import constants from 'src/constants';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {Platform, Text, View} from 'react-native';
import {Camera, CameraView} from 'expo-camera'; // Updated import for Camera
import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';
import {navigationRef} from 'src/Nav/navigationHelpers';
import useParams from 'src/Hooks/useParams';
import {TouchableOpacity} from 'react-native';

const QrModal: React.FC = () => {
  const {handleBarCodeRead, text} = useParams<'QrModal'>();

  return (
    <SafeAreaView
      style={{
        backgroundColor: constants?.color?.white,
        flex: 1,
        position: 'relative',
      }}>
      <View style={{flex: 1}}>
        {!!__DEV__ && (
          <TouchableOpacity
            onPress={() => {
              handleBarCodeRead(
                'https://www.letsroam.com/scavenger_hunt_app?app_redirect=1&join_code=welovekelsey2018',
              );
            }}>
            <View
              style={{
                backgroundColor: 'red',
                width: 20,
                height: 20,
              }}></View>
          </TouchableOpacity>
        )}
        <View
          style={{
            marginTop: Platform.OS === 'android' ? 10 : 40,
            justifyContent: 'center',
            backgroundColor: constants?.color?.white,
            marginBottom: 10,
            paddingBottom: 5,
          }}>
          <Text
            style={{
              fontFamily: constants?.font.circular,
              color: constants?.color?.gray3,
              textAlign: 'center',
              fontSize: 24,
            }}>
            {text}
          </Text>
        </View>
        <CameraView
          style={{flex: 1}}
          onBarcodeScanned={(data) => handleBarCodeRead(data?.data)}
        />
      </View>
      <ModalOmniBar
        omniButton={{
          handlePress: () => navigationRef?.goBack('JoinRedeem/QrModal.tsx'),
          text: 'Cancel',
        }}
      />
    </SafeAreaView>
  );
};

export default QrModal;

import * as t from 'io-ts';
import {validateResult} from 'src/types/typesHelpers';

// io-ts type for Badge
export const TBadgeIO = t.type({
  id: t.number,
  type: t.string,
  current_quantity: t.number,
  requirement_quantity: t.number,
  requirement_type: t.string,
  date_awarded: t.union([t.string, t.undefined, t.null]),
  level: t.number,
  prize_type: t.string,
  photo_thumb: t.string,
  newly_earned: t.union([t.boolean, t.undefined, t.null]),
  unearned: t.union([t.boolean, t.undefined, t.null]),
  prize_is_physical: t.boolean,
  isRedeemed: t.union([t.boolean, t.undefined, t.null]),
  isNew: t.union([t.boolean, t.undefined, t.null]),
  task: t.string,
  title: t.string,
  points: t.union([t.number, t.undefined, t.null]),
  prize_quantity: t.number,
});

// Derive the TypeScript type from the io-ts type
export type TBadge = t.TypeOf<typeof TBadgeIO>;

// Type guard for runtime validation
export const isTBadge = (input: unknown, showErrors: true): input is TBadge => {
  return validateResult(input, TBadgeIO, 'TBadge', showErrors);
};

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {View, TouchableOpacity, Image, ImageSourcePropType} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';

import {
  ContinueButton,
  DailyChallengeCard,
  DailyChallengesSection,
} from 'src/Modules/CommonView';

import HomeNearbyGroupFinder from './HomeNearbyGroupFinder';
import StylizedBox from 'src/Assistant/StylizedBox';

import {navigationRef} from 'src/Nav/navigationHelpers';
import ContinueHuntCard from 'src/Modules/CommonView/ContinueHuntCard';
import {HeaderAndCarouselView} from 'src/Modules/CommonView/HeaderAndCarouselView';
import {updateHomeSearchText} from 'src/Redux/reducers/home.reducer';

import {setCurrentHunt} from 'src/Redux/reducers/hunts.reducer';
import {THunt} from 'src/types/THunt';
import {BodyText} from 'src/Modules/CommonView';
import {ScrollView} from 'react-native-gesture-handler';
import {dispatchAction} from 'src/Utils/helpers';

const search = require('src/Images/Icons/search.png');
const glassCocktail = require('src/Images/Icons/glass-cocktail.png');
const paintBuckets = require('src/Images/Icons/paint-bucket.png');
const ghost = require('src/Images/Icons/ghost.png');
const binocular = require('src/Images/Icons/binocular.png');
const compass = require('src/Images/Icons/compass.png');
const envelope = require('src/Images/Icons/envelope.png');
const dots = require('src/Images/Icons/dots.png');
const buoy = require('src/Images/Icons/lifebuoy.png');

const HomeActivityList: React.FC = () => {
  console.log('HomeActivityList render');
  const {height, width} = useWindowDimensionsSafe();

  const pass = useExplorerPass();

  const huntsList = useTypedSelector((state) => state.hunts.huntsList);

  const hasValidExplorerPass = !!pass;

  // mocking go to hunt because not good
  const mockGoToHunt = (findBarHunt: boolean) => {
    const location = huntsList.find((item: THunt) => {
      const isBarHunt = item.hunt_type === 'barHunt';
      if (findBarHunt) {
        return isBarHunt;
      } else {
        return !isBarHunt;
      }
    });

    if (!!location) {
      dispatchAction(setCurrentHunt(location));
    }

    navigationRef.navigate(
      'LandingPageScavengerHuntInfoModal',
      null,
      'mockGoToHunt',
    );
  };

  console.log(height);

  const playTestState = useTypedSelector(
    (state) => state?.app_info?.playTestState,
  );

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: constants?.color.white1,
      }}>
      <ContinueHuntCard
        width={width}
        containerStyle={{
          width: width - 40,
          left: 20,
          height: 100,
          marginLeft: 0,
          padding: 20,
          marginBottom: 10,
          marginTop: 20,
        }}
      />
      {playTestState == false && (
        <ScrollView horizontal>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 20,
              paddingLeft: 10,
            }}>
            <ActivityType activity="Join Group" icon={dots} />
            <ActivityType
              activity="Scavenger hunts"
              activityString="Outdoor Scavenger Hunts"
              icon={search}
            />
            <ActivityType
              activity="Bar Crawls"
              activityString="Bar Hunts"
              icon={glassCocktail}
            />
            <ActivityType
              activity="Art Walks"
              activityString="Art Walks"
              icon={paintBuckets}
            />
            <ActivityType
              activity="Ghost Tours"
              activityString="Ghost Hunts"
              icon={ghost}
            />
            <ActivityType
              activity="Zoo hunts"
              activityString="Zoo Hunts"
              icon={binocular}
            />
            <ActivityType
              activity="Audio Tours"
              activityString="Audio Tour"
              icon={compass}
            />
            <ActivityType
              activity="In-Home"
              activityString="Indoor Scavenger Hunts"
              icon={envelope}
            />
          </View>
        </ScrollView>
      )}

      <HomeNearbyGroupFinder />

      <HeaderAndCarouselView
        type={'hunt_card'}
        data={huntsList?.filter(
          (hunt) => hunt.hunt_type != 'inHome' && hunt.hunt_type != 'audioTour',
        )}
        carouselIndex={0}
        title={'Activities near you'}
        imgHeight={120}
      />

      {!hasValidExplorerPass && (
        <DailyChallengeCard
          bgColor={constants?.color?.orange}
          title="Upgrade to Let's Roam+"
          description="Unlock unlimited access for you and up to 5 friends, plus other benefits to help you travel smarter."
          cardImage={require('src/Images/LetsRoamPlus/Ellipse.png')}
          showUsers={false}
          showBanner={true}
          calloutImage={require('src/Images/Icons/lr_plus_compass.png')}
          calloutImageStyle={{width: 38, height: 38}}
          handlePress={() => {
            navigationRef.navigate(
              'FreeTrialScreen',
              null,
              'HomeLandingTab.tsx',
            );
          }}
        />
      )}

      {!!hasValidExplorerPass && playTestState == false && (
        <StylizedBox
          showChatIcon={true}
          buttonContainerStyles={{marginBottom: 30}}
          containerStyles={{
            marginTop: 0,
            marginBottom: 20,
            shadowColor: '#000',
            shadowOffset: {width: 0, height: 1},
            shadowOpacity: 0.2,
            shadowRadius: 3,
          }}
          ctaText="Ask my personal assistant"
          title="Get answers and advice fast"
          description={
            'Get help from your own personal assistant, or ask locals.'
          }
          showAltCTA={true}
          onPress={() => {
            navigationRef.navigate('PASubmitChat', null, 'PAChat');
          }}
        />
      )}

      <DailyChallengesSection />

      {!hasValidExplorerPass && (
        <DailyChallengeCard
          bgColor={constants?.color?.teal}
          title="Get tickets"
          description="Purchase individual tickets our activities in over 3,500 locations around the globe."
          cardImage={require('src/Images/LetsRoamPlus/teal_ellipse.png')}
          showUsers={false}
          calloutImage={require('src/Images/Icons/ticket.png')}
          handlePress={() => {
            navigationRef.navigate('PurchaseModal', {}, 'HomeActivityList');
          }}
        />
      )}

      {!playTestState && (
        <View style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
          <TouchableOpacity
            style={{
              backgroundColor: 'black',
              borderRadius: 8,
              marginVertical: 20,
              marginHorizontal: 12,
              alignItems: 'center',
              justifyContent: 'center',
              paddingVertical: 40,
              paddingHorizontal: 40,
              shadowColor: '#000',
              shadowOffset: {width: 0, height: 1},
              shadowOpacity: 0.6,
              shadowRadius: 3,
            }}>
            <>
              <Image
                source={buoy}
                style={{width: 36, height: 36, resizeMode: 'contain'}}
              />
              <View style={{display: 'flex', flexDirection: 'row'}}>
                <BodyText
                  text={'Call us at'}
                  textStyle={{
                    color: 'white',
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
                <BodyText
                  text={'1-833-202-7626'}
                  textStyle={{
                    color: constants.color.orange,
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                    marginLeft: 5,
                    marginRight: 0,
                  }}
                />
              </View>
              <BodyText
                text={'for live support'}
                textStyle={{
                  color: constants.color.white,
                  fontSize: 17,
                  fontFamily: 'JakartaBold',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 3,
                }}
              />
              <BodyText
                text={'Our friendly agents are standing by.'}
                textStyle={{
                  color: constants.color.white,
                  fontSize: 12,
                  fontFamily: 'Jakarta',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 12,
                }}
              />
              <ContinueButton
                text={'Call support'}
                height={50}
                textStyle={{
                  fontFamily: 'JakartaBold',
                  textAlign: 'center',
                  flex: 1,
                }}
                containerStyle={{
                  marginTop: 24,
                  borderRadius: 6,
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  shadowColor: '#000',
                  shadowOffset: {width: 0, height: 1},
                  shadowOpacity: 0.6,
                  shadowRadius: 3,
                }}
                buttonStyle={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                handlePress={() => {
                  navigationRef.navigate(
                    'SupportModal',
                    {},
                    'Nav/DrawerMenu.tsx',
                  );
                }}
              />
            </>
          </TouchableOpacity>
        </View>
      )}

      <View>
        <TouchableOpacity
          style={{
            height: 2,
            width: 2,
            backgroundColor: __DEV__
              ? constants?.color.gray3_30
              : 'transparent',
          }}
          onPress={() => {
            mockGoToHunt(false);
          }}
          testID={'joinScavengerHunt'}></TouchableOpacity>
        <TouchableOpacity
          style={{
            height: 2,
            width: 2,
            backgroundColor: __DEV__
              ? constants?.color.gray3_30
              : 'transparent',
          }}
          onPress={() => {
            mockGoToHunt(true);
          }}
          testID={'joinBarHunt'}></TouchableOpacity>
      </View>
    </View>
  );
};

const ActivityType = ({
  activity = '',
  activityString = '',
  icon = '' as ImageSourcePropType,
}) => {
  const {width} = useWindowDimensionsSafe();

  return (
    <TouchableOpacity
      testID={`activity-${activity}`}
      onPress={() => {
        if (activity == 'Join Group') {
          return navigationRef.navigate('JoinHuntModal', {}, 'Activity Type');
        }
        dispatchAction(updateHomeSearchText(activityString));
      }}
      style={{
        width: (width - 18) / 4,
        marginBottom: 4,
        alignItems: 'center',
        marginRight: 10,
      }}>
      <View
        style={{
          backgroundColor: 'white',
          width: (width - 28) / 4,
          height: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 4,
          shadowColor: '#000',
          shadowOffset: {width: 0, height: 1},
          shadowOpacity: 0.1,
          shadowRadius: 3,
        }}>
        {!!icon && (
          <Image
            source={icon}
            style={{width: 24, height: 24, resizeMode: 'contain'}}
          />
        )}
      </View>
      <BodyText
        text={activity}
        textStyle={{
          color: 'black',
          fontSize: 10,
          fontFamily: 'Jakarta',
          textAlign: 'center',
          lineHeight: 12,
        }}
      />
    </TouchableOpacity>
  );
};

export default HomeActivityList;

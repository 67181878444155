import React from 'react';
import {View, Image, Text} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {deleteNewWanderListLocation} from 'src/Redux/reducers/local.reducer';
import {dispatchAction} from 'src/Utils/helpers';
import constants from 'src/constants';
import {TLocation} from 'src/types/TLocation';

const locationPin = require('src/Images/Icons/location_pin.png');

interface WanderlistStopProps {
  stop: TLocation;
  editIndex?: number;
}

export const WanderlistStop: React.FC<WanderlistStopProps> = ({
  stop,
  editIndex,
}) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        paddingVertical: 24,
        borderBottomWidth: 1,
        borderBottomColor: constants.color.black_10,
      }}>
      <Image
        source={locationPin}
        style={{
          width: 32,
          height: 32,
          resizeMode: 'contain',
        }}
      />
      <View style={{marginLeft: 20}}>
        <Text
          style={{
            color: constants.color.black,
            fontFamily: 'JakartaBold',
            fontSize: 15,
            lineHeight: 21,
          }}>
          {stop.name}
          {editIndex != undefined && (
            <>
              <TouchableOpacity
                onPress={() => {
                  navigationRef.navigate(
                    'WanderlistAddLocation',
                    {editIndex},
                    'WanderlistStop',
                  );
                }}>
                <Text style={{textDecorationLine: 'underline'}}> (edit)</Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  navigationRef.navigate(
                    'ConfirmModal',
                    {
                      titleText: 'Delete Stop',
                      titleBodyText:
                        'Are you sure you want to delete this stop?',
                      confirmText: 'Delete Stop',
                      cancelText: 'Cancel',
                      handleConfirm: () => {
                        dispatchAction(deleteNewWanderListLocation(editIndex));
                      },
                    },
                    'WanderListStop',
                  );
                }}>
                <Text style={{textDecorationLine: 'underline', color: 'red'}}>
                  {' '}
                  (delete)
                </Text>
              </TouchableOpacity>
            </>
          )}
        </Text>
        <Text
          style={{
            color: constants.color.black,
            fontFamily: 'Jakarta',
            fontSize: 12,
            lineHeight: 21,
            marginTop: 7,
          }}>
          {stop.description}
        </Text>
      </View>
    </View>
  );
};

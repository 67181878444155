import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useEffect, useRef, useState} from 'react';
import {Platform, View} from 'react-native';
import DraggableButton from 'src/Modules/GuardianAngel/DragableButton';

// import {
//   ClientRole,
//   ChannelProfile,
//   createAgoraRtcEngine,
//   ClientRoleType,
//   IRtcEngine,
// } from 'src/Modules/Native/Agora';
import {dispatchAction, hashCode} from 'src/Utils/helpers';
import HostChatButton from 'src/Modules/GuardianAngel/HostChatButton';
import HostFullScreen from 'src/Modules/GuardianAngel/HostFullScreen';
import {useFirebaseData} from 'src/Utils/fireUtils';
import {setHostMaximized} from 'src/Redux/reducers/event.reducer';

import {fetchWithParams} from 'src/Utils/apiCalls';

// import { ClientRole } from 'src/Modules/Native/Agora';
// import {ClientRoleType, IRtcEngine} from 'react-native-agora';

const appId = '68124195d89944539c804ebc941fbaa3';
// const APP_CERTIFICATE = '0939e21dad5542bca392ba395bc16340';

const GuardianWrapper: React.FC = () => {
  const eventId = useTypedSelector((state) => state.event?.eventId);
  const theme = useTypedSelector((state) => state.event?.info?.theme);
  const appLoading = useTypedSelector((state) => {
    const currentScreen = state.current_screen?.currentScreen;
    const isAppLoading = ['AppLoadingPage', 'AppIntroSwiper'].includes(
      currentScreen,
    );
    return isAppLoading;
  });

  let formattedEventTheme = theme?.toLowerCase() || '';
  let isBachBday = false;
  if (formattedEventTheme) {
    isBachBday =
      formattedEventTheme.includes('bach') ||
      formattedEventTheme.includes('birth');
  }

  if (appLoading || !eventId || isBachBday) {
    return <View></View>;
  }
  console.log({appLoading});

  return <GuardianContent eventId={eventId} />;
};

function isWithinLastTwoHours(timestamp: number) {
  const now = Math.floor(Date.now() / 1000);
  const hoursAgo = (now - timestamp) / (60 * 60);
  const isInLastTwoHoursValue = hoursAgo <= 2;
  console.log({hoursAgo, timestamp, now, isInLastTwoHoursValue});
  return isInLastTwoHoursValue;
}

const GuardianContent = ({eventId}: {eventId: string}) => {
  console.log('GuardianContent', {eventId});
  const userId = useTypedSelector(
    (state) => state.user?.info?.userId || 'test',
  );

  const hostActiveInLastHour = useTypedSelector((state) => {
    const videoHostOnlineUnix = state.event.info?.videoHostOnlineUnix || 0;
    return isWithinLastTwoHours(videoHostOnlineUnix);
  });

  const deviceUUID = useTypedSelector((state) => state.app_info?.deviceUUID);
  console.log({deviceUUID});
  const userIdHash = Math.round(Math.abs(hashCode(userId)) / 1000);

  // const agoraEngineRef = useRef<typeof IRtcEngine>();

  const [peerIds, setPeerIds] = useState<number[]>([]);

  const [successfullyConnected, setSuccessfullyConnected] =
    useState<boolean>(false);

  const hostMaximized = useTypedSelector((state) => state.event.hostMaximized);
  const hostOnline = useTypedSelector((state) => state.event?.info?.hostOnline);
  console.log({hostOnline, peerIds});

  useEffect(() => {
    if (Platform.OS == 'web') {
      console.log('updating peer ids because web');
      setPeerIds(hostOnline ? [1] : []);
    }
  }, [hostOnline]);

  const appCurrentScreenOverride = useTypedSelector(
    (state) => state.event?.info?.appCurrentScreenOverride,
  );

  useEffect(() => {
    const newHostIsMaximized = !appCurrentScreenOverride;
    console.log('appCurrentScreenOverride changed', {
      appCurrentScreenOverride,
      newHostIsMaximized,
    });
    dispatchAction(setHostMaximized(!newHostIsMaximized));
  }, [appCurrentScreenOverride]);

  // https://docs.agora.io/en/video-calling/get-started/get-started-sdk?platform=react-native
  // user id hash is made from device uuid
  // https://docs.agora.io/en/interactive-live-streaming/get-started/get-started-sdk?platform=react-native
  const joinChannel = async (eventId: string, userIdHash: number) => {
    return console.log('agora blocked');

    // const codeData = await fetchWithParams(
    //   'https://game.letsroam.com/api/apiGetAgoraToken',
    //   {
    //     eventId,
    //     // user id hash is made from device uuid
    //     userIdHash,
    //   },
    // );

    // // user id hash is made from device uuid
    // console.log('codeData data returned', {codeData, userIdHash});

    // let token: string | null = null;

    // if (codeData?.newToken) {
    //   token = codeData?.newToken;
    // } else {
    //   // retry event 2 seconds
    //   setTimeout(() => {
    //     joinChannel(eventId, userIdHash);
    //   }, 2000);
    //   return console.warn('no data returned');
    // }

    // if (!token) {
    //   return console.error('no token');
    // }

    // console.log('joinChannel starting', {
    //   userIdHash,
    //   token,
    //   createAgoraRtcEngine,
    // });

    // try {
    //   // use the helper function to get permissions

    //   const agoraEngine = createAgoraRtcEngine();

    //   agoraEngineRef.current = agoraEngine;
    //   const channelParams = {
    //     appId: appId,
    //     channelProfile: ChannelProfile.ChannelProfileLiveBroadcasting,
    //   };
    //   console.log({channelParams});

    //   agoraEngine.registerEventHandler({
    //     onJoinChannelSuccess: () => {
    //       console.log(
    //         'agoraEngine Successfully joined the channel ' + eventId,
    //         {
    //           // connection,
    //           // elapsed,
    //         },
    //       );
    //       setSuccessfullyConnected(true);
    //     },
    //     onUserJoined: (_connection, Uid) => {
    //       console.log('agoraEngine Remote user joined with uid ' + Uid, {
    //         _connection,
    //         Uid,
    //       });
    //       if (peerIds.indexOf(Uid) === -1) {
    //         setPeerIds([...peerIds, Uid]);
    //       }
    //     },
    //     onUserOffline: (_connection, Uid) => {
    //       console.log('agoraEngine Remote user left the channel. uid: ' + Uid);
    //       setPeerIds(peerIds.filter((id) => id !== Uid));
    //     },
    //     onRemoteVideoStateChanged: (_conncetion, uid, state) => {
    //       console.log('agoraEngine RemoteVideoStateChanged', {uid, state});
    //     },
    //     onError: (err) => {
    //       console.log('agoraEngine joinChannel Error', {
    //         err,
    //         token,
    //         userIdHash,
    //       });
    //       setSuccessfullyConnected(false);
    //     },
    //   });

    //   agoraEngine.initialize(channelParams);

    //   // agoraEngine.enableVideo();

    //   console.log('joinChannel video enabled', {agoraEngine});

    //   agoraEngineRef.current.setChannelProfile(
    //     ChannelProfile.ChannelProfileLiveBroadcasting,
    //   );
    //   agoraEngineRef.current.startPreview();
    //   const clientRoleType = ClientRole.ClientRoleAudience;

    //   agoraEngineRef.current.setClientRole(ClientRoleType.ClientRoleAudience);
    //   agoraEngineRef.current.joinChannel(token, eventId, userIdHash, {
    //     clientRoleType,
    //     autoSubscribeAudio: true,
    //     autoSubscribeVideo: true,
    //   });
    //   console.log('channel joined', {
    //     ClientRole,
    //     clientRoleType,
    //     ChannelProfile,
    //   });
    // } catch (joinChannelError) {
    //   console.log({joinChannelError});
    // }
  };

  useEffect(() => {
    if (Platform.OS == 'web') {
      console.log('on web, dont load');
    } else if (!eventId) {
      console.log('no event to join');
    } else if (!hostActiveInLastHour) {
      console.log('host not online in last 2 hours');
    } else {
      console.log('Joining Channel', {eventId, userId, userIdHash});
      joinChannel(eventId, userIdHash);
    }
    return () => {
      return;
      // if (agoraEngineRef.current) {
      //   agoraEngineRef.current?.leaveChannel?.();
      //   agoraEngineRef.current = undefined;
      //   setPeerIds([]);
      //   setHostMaximized(false);
      //   console.log('left event!');
      // }
    };
  }, [eventId, userIdHash, hostActiveInLastHour]);

  console.log({peerIds});

  // @ashish we want to define a type here
  const messageData = useFirebaseData(
    `event/${eventId}/chats`,
    null, // no data effects
    'sentAt', // order by sent at // is okay
    null, // no order by
    !eventId, // suppress if not eventId
  );

  console.log('GuardianContent', {hostMaximized});

  return (
    <View
      style={{
        flex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 1000000,
      }}
      pointerEvents={'box-none'}>
      {!hostMaximized && (
        <View style={{flex: 1}} pointerEvents={'box-none'}>
          <DraggableButton size={!!peerIds?.[0] ? 160 : 60}>
            <HostChatButton
              onPress={() => {
                dispatchAction(setHostMaximized(!hostMaximized));
              }}
              peerIds={peerIds}
              messageData={messageData}
              successfullyConnected={successfullyConnected}
            />
          </DraggableButton>
        </View>
      )}
      {!!hostMaximized && (
        <HostFullScreen messageData={messageData} peerIds={peerIds} />
      )}
      {!!successfullyConnected && (
        <View testID={'successfullyConnected'}></View>
      )}
    </View>
  );
};

export default GuardianWrapper;

import constants from 'src/constants';
import React, {ReactNode} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  ViewStyle,
} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

interface TopTabProps {
  tabStyle?: ViewStyle;
  children?: ReactNode;
  scrollable?: boolean;
}

export const TopTab: React.FC<TopTabProps> = ({
  tabStyle,
  children,
  scrollable,
}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <View
      style={{
        ...constants?.style.navigationHeader,
        flexDirection: 'row',
        height: 28,
        elevation: 4,
        zIndex: 10,
        width,
        ...tabStyle,
      }}>
      {!!scrollable && (
        <ScrollView style={{paddingLeft: 10, height: 28, flex: 0}} horizontal>
          {children}
        </ScrollView>
      )}
      {!scrollable && children}
    </View>
  );
};

interface Tab {
  text: string;
  value: string | number | boolean | null;
  badge?: number | string;
}

interface TopTabSimpleProps extends TopTabProps {
  tabs: Tab[];
  active: boolean;
  setActive: () => void;
}

export const TopTabSimple: React.FC<TopTabSimpleProps> = ({
  tabStyle,
  scrollable,
  tabs,
  active,
  setActive,
}) => {
  return (
    <TopTab tabStyle={tabStyle} scrollable={scrollable}>
      {tabs?.map?.((tab, index) => {
        const {text, value, badge} = tab;
        return (
          <TopTabButton
            key={index}
            index={index}
            text={text}
            active={value == active}
            badge={badge}
            handlePress={() => setActive()}
          />
        );
      })}
    </TopTab>
  );
};

interface TopTabButtonProps {
  text: string;
  handlePress: () => void;
  active: boolean;
  index: number; // keep required important for other errors
  badge?: number | string;
  scrollable?: boolean;
  testID?: string;
}

export const TopTabButton: React.FC<TopTabButtonProps> = ({
  text,
  handlePress,
  active,
  index,
  badge,
  scrollable,
  testID,
}) => {
  console.log('TopTabButton', {index});
  return (
    <TouchableOpacity
      style={{
        flex: 1,
        alignItems: 'center',
        position: 'relative',
        height: 28,
        marginRight: scrollable ? 15 : 0,
      }}
      testID={testID || `tab${index}`}
      onPress={handlePress}
      disabled={!!active}>
      <View style={{flexDirection: 'row'}}>
        <Text
          style={{
            marginTop: 5,
            fontFamily: constants?.font.circular,
            color: constants?.color?.gray3,
            fontSize: 14,
          }}>
          {text}
        </Text>
        {!!badge && (
          <View
            style={{
              alignItems: 'center',
              backgroundColor: 'red',
              borderRadius: 5.5,
              height: 11,
              justifyContent: 'center',
              left: -2,
              width: 11,
            }}>
            {typeof badge === 'number' && (
              <Text
                style={{
                  fontSize: 8,
                  fontFamily: constants?.font.circular,
                  color: constants?.color?.white,
                  fontWeight: '700',
                }}>
                {badge}
              </Text>
            )}
          </View>
        )}
      </View>
      {!!active && (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            height: 2,
            width: 100,
            backgroundColor: constants?.color?.orange,
          }}
        />
      )}
    </TouchableOpacity>
  );
};

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import ExpoImage from 'src/Utils/ExpoImage';
import Icon from 'react-native-vector-icons/FontAwesome';

import {PrimaryButton} from 'src/Modules/CommonView';
import {navigationRef} from 'src/Nav/navigationHelpers';
// import useParams from 'src/Hooks/useParams';
import {parseHTML} from 'src/Utils/dataHelper';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import {HtmlBlock} from 'src/Modules/CommonView/Text';

const GameLobbyHuntInfoModal: React.FC = () => {
  const groupInfo = useTypedSelector((state) => state.group?.info);

  if (!groupInfo) {
    return <></>;
  }

  const {width} = useWindowDimensionsSafe();

  const {description = ' ', huntName, huntPhoto, city, huntType} = groupInfo;

  const barHunt = huntType == 'barHunt';
  let activity_description = 'SCAVENGER HUNT';
  if (barHunt) {
    activity_description = 'BAR HUNT AND CRAWL';
  } else if (huntType == 'artWalk') {
    activity_description = 'ART WALK SCAVENGER HUNT';
  } else if (huntType == 'audioTour') {
    activity_description = 'AUDIO TOUR';
  }

  const parsedDescription = parseHTML(description || '');
  return (
    // eslint-disable-next-line no-use-before-define
    <SafeAreaView style={styles.container}>
      <ScrollView style={{flex: 1}}>
        <View
          style={{
            width,
            height: 160,
            position: 'relative',
            backgroundColor: 'black',
            alignContent: 'center',
            alignItems: 'center',
          }}>
          {!!huntPhoto && (
            <ExpoImage
              source={{uri: cloudFlareOptimizeImage(huntPhoto)}}
              style={{
                height: 160,
                width,
              }}
              resizeMode={'cover'}
            />
          )}
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: constants?.color?.gray3_50,
            }}>
            <Text
              style={{
                color: constants?.color?.white,
                fontFamily: constants?.font.gothic,
                fontSize: 40,
                textAlign: 'center',
                marginHorizontal: 30,
                marginBottom: 5,
              }}>
              {huntName.toUpperCase?.()}
            </Text>
          </View>

          <TouchableOpacity
            onPress={() =>
              navigationRef?.goBack('ScavengerHunt_v2/HuntInfoModal.tsx')
            }
            style={{
              position: 'absolute',
              top: 10,
              left: 5,
              width: 40,
              height: 40,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Icon name="times" size={25} color={constants?.color?.white} />
          </TouchableOpacity>
        </View>
        <View
          style={{
            justifyContent: 'flex-start',
            marginTop: 10,
            marginHorizontal: 10,
            padding: 10,
            backgroundColor: 'white',
            elevation: 8,
            shadowColor: 'black',
            shadowOffset: {width: 0, height: 0},
            shadowRadius: 6,
            shadowOpacity: 0.35,
          }}>
          <View
            style={{
              borderBottomWidth: 2,
              alignSelf: 'flex-start',
              paddingBottom: 4,
              borderColor: constants?.color?.gray3,
              marginTop: 10,
              marginHorizontal: 10,
            }}>
            <Text
              style={{
                color: '#505759',
                fontFamily: 'Alternate Gothic No3 D',
                fontSize: 21,
              }}>
              {`ABOUT THIS ${(
                (city && city + ' ') ||
                ''
              ).toUpperCase?.()} ${activity_description}`}
            </Text>
          </View>
          <View
            style={{
              marginHorizontal: 10,
              marginVertical: 10,
            }}>
            <HtmlBlock
              htmlStyle={{
                textAlign: 'left',
                fontFamily: 'CircularStd-Book',
                fontSize: 16,
                lineHeight: 20,
                color: '#505759',
              }}
              html={parsedDescription || ''}
            />
          </View>
        </View>

        <View style={{height: 90}} />
      </ScrollView>

      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: constants?.color?.white_50,
          paddingHorizontal: 10,
          paddingVertical: 10,
        }}>
        <View
          style={{
            flexDirection: 'row',
          }}>
          <PrimaryButton
            text={'Return To Lobby'}
            handlePress={() =>
              navigationRef?.goBack('ScavengerHunt_v2/HuntInfoModal.tsx')
            }
            background={constants?.color?.gray3}
          />
        </View>
      </View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: constants?.color?.white,
    flex: 1,
    position: 'relative',
  },
});

export default GameLobbyHuntInfoModal;

import constants from 'src/constants';
import React from 'react';

import {View} from 'react-native';
import ExpoImage from 'src/Utils/ExpoImage';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
// components
import {BodyText, ContinueButton} from 'src/Modules/CommonView';

// utils
import {unPauseHunt} from 'src/Utils/huntFunctions';

const Paused: React.FC = () => {
  const {width} = useWindowDimensionsSafe();

  return (
    <View
      style={{
        flex: 1,
        marginTop: 5,
        backgroundColor: 'white',
        justifyContent: 'center',
      }}>
      <View
        style={{
          alignContent: 'center',
          alignItems: 'center',
          backgroundColor: constants?.color?.blue,
          height: 200,
          justifyContent: 'flex-end',
          marginBottom: 30,
          width,
        }}>
        <ExpoImage
          style={{height: 117 * 1.5, width: 65 * 1.5}}
          source={require('src/Images/huntPaused.png')}
        />
      </View>
      <BodyText
        text={`Your group's hunt is paused. All notifications are stopped. If a device is still receiving push notifications, open up the app on that device to stop it.`}
        textStyle={{textAlign: 'center', marginBottom: 30}}
      />
      <View style={{alignItems: 'center'}}>
        <ContinueButton
          text={'Unpause Hunt'}
          containerStyle={{width: 220}}
          handlePress={() => {
            unPauseHunt();
          }}
        />
      </View>
    </View>
  );
};

export default Paused;

// used to get all user photos

import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  allUserPhotos: [],
};

const userPhotosSlice = createSlice({
  name: 'allUserPhotos',
  initialState,
  reducers: {
    updateAllUserPhotos(state, {payload: photos}) {
      state.allUserPhotos = photos;
    },
  },
});

export const {updateAllUserPhotos} = userPhotosSlice.actions;
export default userPhotosSlice.reducer;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {ScrollView, View} from 'react-native';
import ExpoImage from 'src/Utils/ExpoImage';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

///////////////////////////
///////////// utils

///////////////////////////
///////////// components
import {ContinueButton, BodyText, HtmlBlock} from 'src/Modules/CommonView';

import ChallengeFeedbackElement from 'src/ScavengerHunt_v2/Game/ChallengeFeedbackElement';

import {
  useGameDebugInfo,
  useGoToNextChallenge,
  useImageSource,
  useLocationId,
} from 'src/Hooks/gameHooks';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {AnimatedPointsEarned} from 'src/Modules/CommonView/Text';

const NavigationScore: React.FC = () => {
  const {width} = useWindowDimensionsSafe();

  const locationId = useLocationId();
  const imageSource = useImageSource();
  const locations = useTypedSelector((state) => state.game_v2?.locations);
  console.log('NavigationScore Render');

  const goToNextChallenge = useGoToNextChallenge();

  const handleContinuePress = () => {
    goToNextChallenge();
  };

  const handleBackPress = () => {
    navigationRef.navigate('NavigationChallenge', {locationId}, '');
  };

  if (!locationId || !locations || !locations[locationId]) {
    console.error('NavigationScore: missing locationId or locations');
    return <></>;
  }

  let huntLocation = locations[locationId];
  // only used for Bar Hunt
  if (huntLocation.backupLocationForLocation) {
    const backupLocationId = huntLocation.backupLocationForLocation;
    huntLocation = locations[backupLocationId] || {};
  }
  const name = huntLocation?.name ?? '';
  const groupPointsEarned = huntLocation?.groupPointsEarned ?? 0;

  const gameDebugInfo = useGameDebugInfo();

  return (
    <ScrollView style={{flex: 1, backgroundColor: 'white'}}>
      <View style={{flex: 1, paddingBottom: 60, backgroundColor: 'white'}}>
        <View style={{alignItems: 'center'}}>
          <ExpoImage
            style={{
              width: width - 20,
              height: 240,
            }}
            source={imageSource}
            resizeMode={'contain'}
          />
        </View>
        <BodyText
          text={'Congratulations!'}
          textStyle={{
            fontSize: 20,
            lineHeight: 22,
            fontWeight: '700',
            textAlign: 'center',
          }}
        />
        <BodyText
          text={'You & Your Team Earned'}
          textStyle={{
            fontWeight: '700',
            textAlign: 'center',
          }}
        />

        <AnimatedPointsEarned
          pointsEarned={Math.max(Number(groupPointsEarned) || 1, 100)}
        />

        <View style={{width: width - 40, marginHorizontal: 20}}>
          <HtmlBlock
            html={
              `Your team has made it to the ${name} location. Complete all the challenges at this location to reveal where you need to head next.` +
              gameDebugInfo
            }
            htmlStyle={{fontSize: 16, lineHeight: 18}}
            replaceText={''}
          />
        </View>

        <View style={{alignItems: 'center'}}>
          <ContinueButton
            text={`Let's Roam`}
            containerStyle={{width: 220}}
            handlePress={() => {
              handleContinuePress();
            }}
          />
          <ContinueButton
            text={'Go Back'}
            containerStyle={{width: 220}}
            background={constants?.color?.blue}
            handlePress={() => {
              handleBackPress();
            }}
          />
        </View>
        <ChallengeFeedbackElement />
      </View>
    </ScrollView>
  );
};

export default NavigationScore;

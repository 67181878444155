// this reducer is used to store the user's info
// it is not used to store additional user info
// it is also used to store the group id, which is key

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {TUser} from 'src/types/TUser';

interface UserInfoState {
  info?: TUser | null; // Assuming info is an object; refine the type if you have a detailed structure for it.
  userId: string | null;
  loggedIn: boolean;
  introSliderViewed: boolean;
  currentScavengerHuntGroupId: string | null;
  currentBarHuntGroupId: string | null;
}

const initialState: UserInfoState = {
  info: null, // use false instead of null - null will override default values and cause crashes
  userId: null, // used for creating the listener
  loggedIn: false,
  introSliderViewed: false,
  currentScavengerHuntGroupId: null,
  currentBarHuntGroupId: null,
};

const userInfoSlice = createSlice({
  name: 'user_info',
  initialState,
  reducers: {
    saveUserInfo(state, action: PayloadAction<TUser>) {
      console.log('saveUserInfo');
      if (!deepEqualityCheck(action.payload, state.info)) {
        console.log('dispatchAction: updating state.info');
        state.info = action.payload;
      }
      if (!deepEqualityCheck(action.payload?.userId, state.userId)) {
        state.userId = action.payload.userId;
      }

      if (state.loggedIn != true) {
        state.loggedIn = true;
      }
    },
    logOutUser(state) {
      console.log('Logging out user');
      mmkvStorage.delete('userId');
      mmkvStorage.delete('userInfo');
      state.info = null;
      state.userId = null;
      state.loggedIn = false;
    },
    setCurrentGroupId(state, action: PayloadAction<string | null>) {
      console.log('setCurrentGroupId - ' + action.payload);
      state.currentScavengerHuntGroupId = action.payload;
      // Or: state.currentBarHuntGroupId = action.payload; // Depending on your use case
    },
    setIntroSliderVisited(state, action: PayloadAction<boolean>) {
      state.introSliderViewed = action.payload;
    },
  },
});

export const {
  saveUserInfo,
  logOutUser,
  setCurrentGroupId,
  setIntroSliderVisited,
} = userInfoSlice.actions;

export default userInfoSlice.reducer;

import constants from 'src/constants';
import React from 'react';
import {Image, View, Text, Platform} from 'react-native';
import {Marker, Callout} from 'src/Modules/Native/MapView';

const star = require('src/Images/Rank/star.png');

// optimized check to make sure the markers are not changing
const MarkerIsEqual = (
  prevProps: MapMarkerProps,
  nextProps: MapMarkerProps,
) => {
  if (!prevProps || !nextProps) {
    return false;
  } else if (prevProps.title != nextProps.title) {
    return false;
  } else if (prevProps.lat != nextProps.lat) {
    return false;
  } else if (prevProps.showMarker != nextProps.showMarker) {
    return false;
  } else {
    return true;
  }
};

interface MapMarkerProps {
  lat: number;
  long: number;
  markerPress?: () => void;
  calloutPress?: () => boolean;
  style?: object;
  identifier?: string;
  //may need to apply eslint-disable-next-line
  setSelectedHuntId?(): void;
  title?: string;
  showMarker: boolean;
  isBarHunt?: boolean;
  featured?: boolean;
  markerColor?: string;
}

const MapMarker: React.FC<MapMarkerProps> = (props) => {
  const {
    lat,
    long,
    markerPress,
    calloutPress = () => true,
    identifier,
    setSelectedHuntId,
    title,
    showMarker,
    isBarHunt,
    featured,
    markerColor = constants?.color?.orange,
  } = props;

  if (!lat || !long) {
    return (
      <Marker
        tracksViewChanges={false}
        coordinate={{
          latitude: 0,
          longitude: 0,
        }}
      />
    );
  }

  if (Platform.OS == 'android') {
    return (
      <Marker
        onPress={(ev) => {
          console.log('the selected marker id is: ', ev?.nativeEvent?.id);

          if (!showMarker) {
            return false;
          }
          if (markerPress) {
            markerPress();
          } else if (ev.nativeEvent.id != null && setSelectedHuntId) {
            setSelectedHuntId();
          }
        }}
        image={
          isBarHunt
            ? require('src/Images/Icons/barMarkerAndroid.png')
            : require('src/Images/Icons/mapMarkerAndroid.png')
        }
        identifier={identifier}
        coordinate={{
          latitude: Number(lat) || 0,
          longitude: Number(long) || 0,
        }}
      />
    );
  }

  return (
    <Marker
      tracksViewChanges={false}
      onPress={(ev) => {
        console.log('the selected marker id is: ', ev.nativeEvent.id);

        if (!showMarker) {
          return false;
        }
        if (markerPress) {
          markerPress();
        } else if (ev.nativeEvent.id != null && setSelectedHuntId) {
          setSelectedHuntId();
        }
      }}
      title={title}
      identifier={identifier}
      coordinate={{
        latitude: Number(lat) || 0,
        longitude: Number(long) || 0,
      }}
      opacity={showMarker ? 1 : 0}
      centerOffset={{x: 0, y: -20}}>
      <View
        style={{
          width: 25,
          height: 42,
          position: 'relative',
          alignItems: 'center',
        }}>
        <View
          style={{
            width: 25,
            height: 25,
            borderRadius: 12.5,
            backgroundColor: markerColor,
            position: 'relative',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
          }}>
          <View
            style={{
              width: 16,
              height: 16,
              borderRadius: 8,
              backgroundColor: constants?.color?.white,
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {!!featured && (
              <Image
                source={star}
                style={{
                  height: 11,
                  width: 11,
                  zIndex: 100,
                }}
              />
            )}
          </View>
        </View>
        <View
          style={{
            width: 0,
            height: 0,
            top: -6.5,
            backgroundColor: 'transparent',
            borderStyle: 'solid',
            borderTopWidth: 25,
            borderRightWidth: 11,
            borderBottomWidth: 0,
            borderLeftWidth: 11,
            borderTopColor: markerColor,
            borderRightColor: 'transparent',
            borderBottomColor: 'transparent',
            borderLeftColor: 'transparent',
          }}
        />
      </View>
      {!!title && (
        <Callout onPress={() => calloutPress()}>
          <View style={{minWidth: 120}}>
            <Text
              style={{
                fontFamily: constants?.font.circular,
                color: constants?.color?.gray3,
                textAlign: 'center',
              }}>
              {title}
            </Text>
          </View>
        </Callout>
      )}
    </Marker>
  );
};

export default React.memo(MapMarker, MarkerIsEqual);

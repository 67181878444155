import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {FC} from 'react';
import {Text, TextStyle, TouchableOpacity, View, ViewStyle} from 'react-native';
import {TTagId} from 'src/Redux/reducers/local.reducer';

function removeItemOnce<T>(arr: T[], value: T): T[] {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

interface TagInputProps {
  locationTagTypesOverride?: TTagId[];
  tag_ids: string[];
  handlePress?: (_newTagIds: string[]) => void;
  tagStyle?: ViewStyle;
  tagTextStyle?: TextStyle;
}

const TagInput: FC<TagInputProps> = ({
  locationTagTypesOverride,
  tag_ids,
  handlePress,
  tagStyle = {},
  tagTextStyle = {},
}) => {
  const localTags = useTypedSelector((state) => state.local.locationTagTypes);
  const locationTagTypes = locationTagTypesOverride || localTags;
  console.log({locationTagTypes, tag_ids});
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
      }}>
      {locationTagTypes?.map?.((locationTagType) => {
        const {isDefault} = locationTagType;
        const isSelected =
          tag_ids?.includes(String(locationTagType.id)) ||
          (isDefault && tag_ids?.length === 0);

        if (!isSelected && !handlePress) {
          return null;
        }
        return (
          <TouchableOpacity
            key={`tag ${locationTagType.id}`}
            disabled={!handlePress}
            onPress={() => {
              if (!handlePress) {
                return console.log('no on press event');
              }
              console.log({tag_ids});
              let newTagIds = [...tag_ids];
              if (!isSelected) {
                newTagIds.push(String(locationTagType.id));
              } else {
                newTagIds = removeItemOnce(
                  newTagIds,
                  String(locationTagType.id),
                );
              }

              if (isDefault) {
                newTagIds = [];
              }

              newTagIds?.sort?.((a, b) => (a > b ? 1 : -1)) || [];
              console.log({newTagIds});
              handlePress(newTagIds);
            }}
            style={{
              padding: 8,
              paddingHorizontal: 10,
              minWidth: 70,
              borderRadius: 8,
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              margin: 5,
              marginBottom: 7,
              elevation: 3,
              shadowColor: constants?.color.gray2,
              shadowRadius: 2,
              shadowOpacity: 0.1,
              backgroundColor: !handlePress
                ? 'white'
                : isSelected
                ? constants?.color.blue
                : constants?.color.white1,
              ...tagStyle,
            }}>
            <Text
              style={{
                fontSize: 15,
                fontStyle: 'italic',
                lineHeight: 21,
                ...tagTextStyle,
              }}>
              {locationTagType.name}
            </Text>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

export default TagInput;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TNearbyEvent} from 'src/types/TNearbyEvent';

interface NearbyState {
  nearbyEvents: TNearbyEvent[];
}

const initialState: NearbyState = {
  nearbyEvents: [],
};

const nearbySlice = createSlice({
  name: 'nearby',
  initialState,
  reducers: {
    updateNearbyEvents(state, action: PayloadAction<TNearbyEvent[]>) {
      state.nearbyEvents = action.payload;
    },
  },
});

export const {updateNearbyEvents} = nearbySlice.actions;
export default nearbySlice.reducer;

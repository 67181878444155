import useTypedSelector from 'src/Hooks/useTypedSelector';
import {navigationRef} from 'src/Nav/navigationHelpers';
import React from 'react';

///////////////////////////
///////////// components
import {NonChallengeView} from 'src/Modules/CommonView';

///////////////////////////
///////////// utils
import {useLocation, useLocationId} from 'src/Hooks/gameHooks';

// default team photo
const basePhotoUrl = 'photos.letsroam.com';

const LocationOutro: React.FC = () => {
  const teamName = useTypedSelector(
    (state) => state.group?.info?.teamName || '',
  );

  const locationId = useLocationId();
  const location = useLocation();

  console.log('LocationOutro Render');

  const name = location?.name || '';
  const postContent = location?.postContent || '';
  const photoLarge = location?.photoLarge || '';

  return (
    <NonChallengeView
      titleText={name || ''}
      bodyText={!!postContent ? postContent : ''}
      buttonText={'Continue'}
      replaceText={teamName}
      handleButtonPress={() => {
        navigationRef.navigate(
          'LocationComplete',
          {
            locationId,
          },
          'ScavengerHunt_v2/Game/LocationOutro.tsx',
        );
      }}
      imageSource={{
        uri: `https://${basePhotoUrl}${
          (photoLarge && photoLarge.substring(1)) || ''
        }`,
      }}
    />
  );
};

export default LocationOutro;

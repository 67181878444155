import {
  FlatList,
  Platform,
  ScrollView,
  Text,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import TextQuestion from './TextQuestion';
import ExpoImage from 'src/Utils/ExpoImage';
import TextMultiChoice from './TextMultiChoice';
import constants from 'src/constants';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {useChallenge} from 'src/Hooks/gameHooks';
import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import EnlargeImageModal from 'src/Modules/CommonView/EnlargeImageModal';

const warningIcon = require('src/Images/Icons/warning.png');

interface ScatteredKnowledgeProps {
  onComplete?: () => void;
}

interface WarningComponentProps {
  text?: string;
}

interface EvidenceContainerProps {
  isPhoto?: boolean;
  evidence: string[];
  alternatingNumber?: number;
  userIndex?: number;
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
  setModalImage: Dispatch<SetStateAction<string>>;
}

const WarningComponent: React.FC<WarningComponentProps> = ({text}) => {
  return (
    <View
      style={{
        gap: 20,
        flexDirection: 'row',
        marginHorizontal: 20,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <ExpoImage
        source={warningIcon}
        style={{height: 30, width: 35}}
        resizeMode="contain"
      />
      <Text style={{fontSize: 15, fontFamily: 'Jakarta'}}>
        {text ||
          'Remember: communication is key.\nEach player has different evidence!'}
      </Text>
    </View>
  );
};

const EvidenceContainer: React.FC<EvidenceContainerProps> = ({
  isPhoto,
  evidence,
  alternatingNumber = 0,
  userIndex = 0,
  setIsModalVisible,
  setModalImage,
}) => {
  return (
    <View style={{}}>
      <View>
        <View
          style={{
            backgroundColor: constants.color.orange,
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            paddingLeft: 20,
            paddingVertical: 10,
          }}>
          <Text
            style={{
              color: constants.color.white,
              fontSize: 20,
              fontFamily: constants.font.JakartaBold,
            }}>
            Your Evidence
          </Text>
        </View>
        <View
          style={{
            flexDirection: isPhoto ? 'row' : 'column',
            backgroundColor: '#D9D9D6',
            paddingLeft: isPhoto ? 10 : 20,
            paddingRight: 10,
            paddingVertical: 10,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            gap: 10,
            elevation: 10,
            shadowColor: 'rgba(0, 0, 0, 0.8)',
            shadowOffset: {width: 10, height: 20},
            shadowOpacity: 0.2,
            shadowRadius: 20,
          }}>
          {isPhoto
            ? evidence && (
                <FlatList
                  data={evidence.filter(
                    (_, ix) =>
                      ix > 1 &&
                      ((userIndex >= alternatingNumber ? -1 : 1) *
                        (ix + userIndex)) %
                        alternatingNumber ==
                        0,
                  )}
                  keyExtractor={(_, ix) => 'evidence-photo-' + ix}
                  renderItem={({item, index}) => (
                    <TouchableWithoutFeedback
                      onPress={() => {
                        setModalImage(item);
                        setIsModalVisible(true);
                      }}
                      key={'evidence-photo-' + index}>
                      <ExpoImage
                        source={{uri: item}}
                        style={{width: `30%`, height: 100, margin: 10}}
                        resizeMode="contain"
                      />
                    </TouchableWithoutFeedback>
                  )}
                  numColumns={3}
                />
              )
            : evidence &&
              evidence
                .filter(
                  (_, ix) =>
                    ((userIndex >= alternatingNumber ? -1 : 1) *
                      (ix + userIndex)) %
                      alternatingNumber ==
                    0,
                )
                .map((prompt, ix) => (
                  <Text
                    key={'evidence-prompt-' + ix}
                    style={{fontFamily: 'Jakarta', fontSize: 15}}>
                    {prompt}
                  </Text>
                ))}
        </View>
      </View>
      <View style={{marginTop: 30}}>
        <WarningComponent />
      </View>
    </View>
  );
};

const ScatteredKnowledge: React.FC<ScatteredKnowledgeProps> = ({
  onComplete,
}) => {
  const [userIndex, setUserIndex] = useState(0);
  //is image modal visible or not
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const userId = useTypedSelector((state) => state.user?.info?.userId);

  const players =
    useTypedSelector(
      (state) => state.group?.info?.players,
      deepEqualityCheck,
    ) || {};

  const sortedPlayersList = Object.values(players).sort((a, b) => {
    const aJoin = a?.timeAdded || 0;
    const bJoin = b?.timeAdded || 0;
    return aJoin > bJoin ? 1 : -1;
  });

  const challenge = useChallenge();
  const {width, height} = useWindowDimensionsSafe();

  useEffect(() => {
    if (!sortedPlayersList) return;
    sortedPlayersList.forEach((player, ix) => {
      if (player.userId === userId) {
        setUserIndex(ix);
        return;
      }
    });
  }, [sortedPlayersList]);

  const evidencePhotos = challenge?.challengePhoto
    ? challenge?.challengePhoto
        .split(',')
        .filter((prompt, ix) => ix < 2 || !!prompt)
        .map((url: string) =>
          url.includes('https://firebasestorage.googleapis.com/')
            ? url
            : url.replace('./', 'https://photos.letsroam.com/'),
        )
    : [];

  const evidencePrompts = challenge?.additionalInfo
    ? challenge?.additionalInfo.split('\\').filter((prompt) => !!prompt)
    : [];

  console.log('evidencePhotos', challenge?.challengePhoto);

  return (
    <ScrollView
      style={{height: height / 2}}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{alignItems: 'center'}}>
      <EnlargeImageModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        modalImage={modalImage}
      />
      {challenge?.type === 'cypher_decoder' ? (
        <View>
          <TextQuestion
            onComplete={onComplete}
            hideHint={true}
            prompt="Use your decoders in sync and try to decipher the message. You'll have to work together to answer the question and escape the room!"
          />
          <View
            style={{
              marginVertical: 20,
              borderBottomWidth: 1,
              borderBottomColor: constants?.color?.gray1_30,
            }}></View>
          <EvidenceContainer
            isPhoto
            setIsModalVisible={setIsModalVisible}
            setModalImage={setModalImage}
            evidence={evidencePhotos}
            alternatingNumber={
              sortedPlayersList.length === 1
                ? 1
                : evidencePrompts.length <= 5
                ? 2
                : 3
            }
            userIndex={userIndex}
          />
          {evidencePhotos && evidencePhotos[0] && (
            <TouchableWithoutFeedback
              onPress={() => {
                setModalImage(evidencePhotos[0]);
                setIsModalVisible(true);
              }}>
              <ExpoImage
                style={{width: width - 20, height: width - 20, marginTop: 30}}
                resizeMode="contain"
                source={{
                  uri: evidencePhotos[0],
                }}
              />
            </TouchableWithoutFeedback>
          )}
          {evidencePhotos && evidencePhotos[1] && (
            <TouchableWithoutFeedback
              onPress={() => {
                setModalImage(evidencePhotos[1]);
                setIsModalVisible(true);
              }}>
              <ExpoImage
                style={{width: width - 20, height: width - 20, marginTop: 10}}
                resizeMode="contain"
                source={{
                  uri: evidencePhotos[1],
                }}
              />
            </TouchableWithoutFeedback>
          )}
        </View>
      ) : challenge?.type === 'spot_the_difference' ? (
        <View style={{alignItems: 'center'}}>
          {evidencePhotos && (
            <TouchableWithoutFeedback
              onPress={() => {
                setModalImage(evidencePhotos[userIndex % 2]);
                setIsModalVisible(true);
              }}>
              <ExpoImage
                style={{height: 200, width: 200, marginTop: 10}}
                resizeMode="contain"
                source={{
                  uri: evidencePhotos[userIndex % 2],
                }}
              />
            </TouchableWithoutFeedback>
          )}
          <WarningComponent
            text="Some players have another
version of this image."
          />
          <TextQuestion
            prompt={challenge?.question || ''}
            onComplete={onComplete}
            hideHint={true}
          />
        </View>
      ) : (
        <View style={{alignItems: 'center'}}>
          <EvidenceContainer
            evidence={evidencePrompts}
            setIsModalVisible={setIsModalVisible}
            setModalImage={setModalImage}
            alternatingNumber={
              sortedPlayersList.length === 1
                ? 1
                : evidencePrompts.length <= 3
                ? 2
                : 3
            }
            userIndex={userIndex}
          />
          <Text
            style={{
              marginTop: 20,
              borderTopWidth: 1,
              fontStyle: 'italic',
              paddingHorizontal: Platform.OS === 'web' ? 10 : 0,
              fontSize: 15,
            }}>
            {challenge?.question || ''}
          </Text>
          <TextMultiChoice
            allowMultiGuess
            singleCompletion
            onComplete={onComplete}
            noTimer
          />
        </View>
      )}
    </ScrollView>
  );
};

export default ScatteredKnowledge;

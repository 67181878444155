import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect, useState} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {
  KeyboardAvoidingView,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
  Alert,
} from 'react-native';
import * as Application from 'expo-application';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

///////////////////////////
///////////// components
import {
  ContinueButton,
  BodyText,
  CloseIconButton,
} from 'src/Modules/CommonView';

///////////////////////////
///////////// utils
import {apiLeaveQuestionReview} from 'src/Utils/apiCalls';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {
  useChallengeId,
  useGameDebugInfo,
  useLocation,
  useLocationId,
} from 'src/Hooks/gameHooks';
import {
  setChallengeCompleteTime,
  setLocationCompleteTime,
  setNavigationCompleteTime,
} from 'src/Utils/huntFunctions_v2';
import {useUserId} from 'src/Hooks/reduxHooks';
import {getReduxState} from 'src/Utils/helpers';
import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';
import DropDown from 'src/Modules/DropDown';

const ReportModal: React.FC = () => {
  console.log('ReportModal Render');
  const {width} = useWindowDimensionsSafe();

  const userId = useUserId();
  const groupId = useTypedSelector((state) => state.group?.info?.groupId);
  const huntId = useTypedSelector((state) => state.group?.info?.huntId);
  const routeId = useTypedSelector((state) => state.group?.info?.routeId);

  useEffect(() => {
    logCustomAnalyticsEvent('report_modal_view', {
      userId,
      groupId,
      huntId,
      routeId,
    });
  });

  const isCurrentScrrenNavigationChallenge = useTypedSelector(
    (state) =>
      state.current_screen?.currentScreen == 'NavigationChallenge' ||
      state.current_screen?.previousScreen == 'NavigationChallenge',
  );

  const location = useLocation();

  const currentChallengeId = useChallengeId();
  const currentLocationId = useLocationId();

  const gameDebugInfo = useGameDebugInfo();

  const [feedbackText, setFeedbackText] = useState<string>('');
  const [showThankYou, setShowThankYou] = useState<boolean>(false);
  const [issue, setIssue] = useState<string>('');

  const challengeId = useChallengeId();

  const handleSubmitFeedback = async () => {
    const screen = getReduxState(
      (state) => state?.current_screen?.previousScreen,
    );

    if (!feedbackText && !issue) {
      return Alert.alert(
        'Feedback is Required',
        `Please submit what you didn't like so we can improve.`,
      );
    }

    const userFeedback = issue
      ? feedbackText
        ? issue + ': ' + feedbackText
        : issue
      : feedbackText;

    apiLeaveQuestionReview({
      liked: false,
      questionId: currentChallengeId?.substring?.(2) || '',
      appChallengeId: challengeId || '',
      userId: userId || undefined,
      huntId: huntId || undefined,
      routeId: routeId || undefined,
      groupId,
      screen,
      app_version: `${Platform.OS} ${Application.nativeApplicationVersion}`,
      locationId: (currentLocationId && currentLocationId?.substring(2)) || '',
      userFeedback,
    });

    setShowThankYou(true);
    setTimeout(() => {
      if (screen != 'NavigationChallenge' && screen != 'LocationChallenges') {
        navigationRef.closeModal('ReportModal', ' apiLeaveQuestionReview Done');
      }
    }, 1500);
  };

  const handleNextLocationPress = () => {
    if (!location) {
      return console.error('no location', 'handleNextLocationPress');
    }

    Alert.alert(
      'Are You Sure?',
      'This will advance all players to the next location or challenge.',
      [
        {
          text: 'Cancel',
          onPress: () => null,
        },
        {
          text: 'Yes',
          onPress: async () => {
            if (!currentLocationId) {
              return navigationRef?.goBack(
                'ScavengerHunt_v2/Game/ReportModal.tsx',
              );
            }

            await setNavigationCompleteTime(currentLocationId, location, 0);
            await setLocationCompleteTime(currentLocationId);
            Alert.alert(
              'You have been moved to the next location',
              'We are sorry that there was an issue with our hunt. Our writers have been notified. Just click next.',
            );
            setTimeout(() => {
              navigationRef?.goBack('ScavengerHunt_v2/Game/ReportModal.tsx');
            }, 1000);
          },
        },
      ],
    );
  };

  const handleNextChallengePress = () => {
    Alert.alert(
      'Are You Sure?',
      'This will advance all players to the next location or challenge.',
      [
        {
          text: 'Cancel',
          onPress: () => null,
        },
        {
          text: 'Yes',
          onPress: async () => {
            if (!currentChallengeId) {
              return navigationRef?.goBack(
                'ScavengerHunt_v2/Game/ReportModal.tsx',
              );
            }

            await setChallengeCompleteTime(currentChallengeId);
            Alert.alert(
              'You have been moved to the next question',
              'We are sorry that there was an issue with our hunt. Our writers have been notified.',
            );
            setTimeout(() => {
              navigationRef?.goBack('ScavengerHunt_v2/Game/ReportModal.tsx');
            }, 1000);
          },
        },
      ],
    );
  };

  console.log('ReportModal Render');

  const isCurrentScreenLocationChallenge = useTypedSelector(
    (state) =>
      state.current_screen?.currentScreen == 'LocationChallenges' ||
      state.current_screen?.previousScreen == 'LocationChallenges',
  );

  const potentialChallengeIssues = [
    {value: 'Not Challenging', key: 0, label: 'Not Challenging Enough'},
    {value: 'Wrong Answer', key: 1, label: 'Wrong Answer'},
    {value: 'Technical Issue', key: 2, label: 'Technical Issue'},
    {value: 'Other', key: 3, label: 'Other'},
  ];

  const potentialLocationIssues = [
    {value: "Location doesn't exist", key: 0, label: "Location doesn't exist"},
    {value: 'Bad Part of Town', key: 1, label: 'Bad Part of Town'},
    {value: 'Incorrect Location', key: 3, label: 'Incorrect Location'},
    {value: 'Technical Issue', key: 4, label: 'Technical Issue'},
    {value: 'Other', key: 5, label: 'Other'},
  ];

  return (
    <SafeAreaView style={{flex: 1}}>
      <TouchableOpacity
        onPress={() =>
          navigationRef?.goBack('ScavengerHunt_v2/Game/ReportModal.tsx')
        }
        style={{flex: 1}}
      />
      <KeyboardAvoidingView
        style={{
          justifyContent: 'flex-end',
          backgroundColor: 'white',
          paddingBottom: 20,
        }}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        enabled>
        {!showThankYou && (
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
            }}>
            <BodyText text={'Provide Our Writers Feedback'} header />
            <BodyText
              text={`Let us know what the issue was. You can also skip this task after submitting this form.`}
              center
            />
            {(currentChallengeId || currentLocationId) && (
              <DropDown
                value={issue}
                testID="reportIssue"
                placeholder={
                  currentChallengeId
                    ? 'What was wrong with this challenge'
                    : 'What was wrong with this location'
                }
                dropDownOptions={
                  currentChallengeId
                    ? potentialChallengeIssues
                    : potentialLocationIssues
                }
                setValue={(value) => setIssue(value)}
                buttonStyle={{
                  minHeight: 50,
                  width: width - 40,
                }}
              />
            )}

            <TextInput
              style={{
                backgroundColor: constants?.color?.white1,
                color: constants?.color?.gray3,
                height: 180,
                justifyContent: 'center',
                marginTop: 20,
                paddingHorizontal: 20,
                paddingVertical: 20,
                paddingTop: 20,
                textAlignVertical: 'top',
                borderRadius: 10,
                width: width - 20,
              }}
              allowFontScaling={false}
              editable={true}
              maxLength={900}
              multiline={true}
              placeholder={'What can be improved?' + gameDebugInfo}
              onChangeText={(feedbackText) => setFeedbackText(feedbackText)}
              placeholderTextColor={constants?.color?.gray2}
              underlineColorAndroid={constants?.color?.white}
              blurOnSubmit
            />
            <View style={{alignItems: 'center', marginVertical: 10}}>
              <ContinueButton
                text={`Submit Feedback`}
                containerStyle={{width: 220, marginTop: 10}}
                handlePress={() => {
                  handleSubmitFeedback();
                }}
              />
              <ContinueButton
                text={`Contact Support`}
                containerStyle={{flex: 1, width: 220}}
                handlePress={() => {
                  navigationRef.navigate(
                    'SupportModal',
                    {},
                    'ScavengerHunt_v2/Game/ReportModal.tsx',
                  );
                }}
                background={constants?.color?.blue}
              />
              <ContinueButton
                text={`Cancel`}
                containerStyle={{flex: 1, width: 220}}
                background={constants?.color?.gray3}
                handlePress={() => {
                  navigationRef?.goBack(
                    'ScavengerHunt_v2/Game/ReportModal.tsx',
                  );
                }}
              />
            </View>
          </View>
        )}

        {!!showThankYou && (
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 10,
              minHeight: 80,
            }}>
            <BodyText text={'Thank you for your feedback!'} />
            {!!isCurrentScrrenNavigationChallenge && (
              <View style={{alignItems: 'center', marginVertical: 10}}>
                <BodyText
                  text={
                    'We are sorry that you had this issue. Do you want to skip this current location, and move on to the next one? You will earn full points.'
                  }
                />
                <ContinueButton
                  text={`Skip Location`}
                  containerStyle={{width: 220, marginTop: 10}}
                  handlePress={() => {
                    handleNextLocationPress();
                  }}
                />
                <ContinueButton
                  text={`Close`}
                  containerStyle={{flex: 1, width: 220}}
                  background={constants?.color?.gray3}
                  handlePress={() => {
                    navigationRef?.goBack(
                      'ScavengerHunt_v2/Game/ReportModal.tsx',
                    );
                  }}
                />
              </View>
            )}
            {!!isCurrentScreenLocationChallenge && (
              <View style={{alignItems: 'center', marginVertical: 10}}>
                <BodyText
                  text={
                    'We are sorry that you had this issue. Do you want to skip this current challenge, and move on to the next one? You will earn full points.'
                  }
                />
                <ContinueButton
                  text={`Skip Challenge`}
                  containerStyle={{width: 220, marginTop: 10}}
                  handlePress={() => {
                    handleNextChallengePress();
                  }}
                />
                <ContinueButton
                  text={`Close`}
                  containerStyle={{flex: 1, width: 220}}
                  background={constants?.color?.gray3}
                  handlePress={() => {
                    navigationRef?.goBack(
                      'ScavengerHunt_v2/Game/ReportModal.tsx',
                    );
                  }}
                />
              </View>
            )}
          </View>
        )}

        <CloseIconButton
          handlePress={() =>
            navigationRef?.goBack('ScavengerHunt_v2/Game/ReportModal.tsx')
          }
          side="right"
        />
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default ReportModal;

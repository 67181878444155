import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import * as Updates from 'expo-updates';
import React, {useEffect} from 'react';
import {
  Alert,
  Linking,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import {v4 as uuidv4} from 'uuid';

import LineIcon from 'react-native-vector-icons/SimpleLineIcons';

import UserProfileIcon from 'src/Modules/UserProfileIcon';

import {setCurrentGroupId} from 'src/Redux/reducers/user_info.reducer';
import isIphoneX from 'src/Utils/IphoneXHelper';

import {logOutUser} from 'src/Redux/reducers/user_info.reducer';
import openURLInBrowser from 'src/Utils/openURLInBrowser';

import dJSON from 'dirty-json';

import * as Application from 'expo-application';

///////////////////////
///////////   utils

import {useDrawerStatus} from '@react-navigation/drawer';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {ScrollView} from 'react-native-gesture-handler';
import {setDataAtPath} from 'src/Utils/fireUtils';
import {useUserId} from 'src/Hooks/reduxHooks';
import {
  setIsDrawerOpen,
  setPreviousVisit,
} from 'src/Redux/reducers/current_screen.reducer';
import {setEventId, updateEventInfo} from 'src/Redux/reducers/event.reducer';
import {saveUserInfo} from 'src/Redux/reducers/user_info.reducer';
import {getDataAtPath} from 'src/Utils/fireUtils';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {mmkvStorage} from 'src/Utils/mmkvStorage';

import {api_delete_user_or_group} from 'src/Utils/apiCalls';

import AsyncStorage from '@react-native-async-storage/async-storage';

import {TUser} from 'src/types/TUser';
import {addExtraHuntTime} from 'src/Utils/huntFunctions';
import {TNavigationRouteParams} from 'src/types/TNavigationRouteParams';
import getAppType from 'src/Utils/getAppType';

const isX = isIphoneX();

// efficiency wrapper
// ensure that this expensive component does not update every single time!
const DrawerMenuWrapper: React.FC = () => {
  const isDrawerOpen = useDrawerStatus() === 'open';

  // used to track if drawer is open
  useEffect(() => {
    dispatchAction(setIsDrawerOpen(isDrawerOpen));
  }, [isDrawerOpen]);

  if (!isDrawerOpen) {
    return <></>;
  }

  // eslint-disable-next-line no-use-before-define
  return <DrawerMenu />;
};

export const openFirebaseLink = (link: string, isLRAdmin?: boolean) => {
  if (__DEV__ && Platform.OS != 'web') {
    console.log('opening in browser');
    openURLInBrowser(link);
  } else if (isLRAdmin) {
    console.log('opening url');
    Linking?.openURL(link);
  } else {
    console.log('no match');
  }
};

interface NavButtonParams {
  navigationAction: () => void;
  screenTitle: string;
  icon: string;
}

const NavButton: React.FC<NavButtonParams> = ({
  navigationAction,
  screenTitle,
  icon,
}) => {
  return (
    <TouchableOpacity
      onPress={navigationAction}
      style={{
        width: '100%',
        height: 23,
        marginVertical: 5,
      }}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Text style={{textAlign: 'left', marginHorizontal: 10}}>
          <LineIcon size={18} name={icon} color={constants?.color?.gray3} />
        </Text>
        <Text
          style={{
            color: constants?.color?.gray3,
            fontSize: 16,
            fontFamily: constants?.font.circular,
            textAlign: 'left',
            fontWeight: '700',
          }}>
          {screenTitle}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const DrawerMenu = () => {
  console.log('DrawerMenu rerender');

  const eventId = useTypedSelector((state) => state.event?.eventId);
  const userId = useUserId();

  const loggedIn = useTypedSelector((state) => state.user?.loggedIn);
  const badgesEarnedCount = useTypedSelector(
    (state) => state.user?.info?.badgesEarnedCount,
  );

  const utm_source = useTypedSelector((state) => state.app_info?.utm_source);
  const customAdView = useTypedSelector(
    (state) => state.app_info?.customAdView,
  );
  const shareLink = useTypedSelector((state) => state.user?.info?.shareLink);

  const currentHuntInfo = useTypedSelector(
    (state) => state.user?.info?.currentHuntInfo,
  );

  const currentLocationId = useTypedSelector(
    (state) => state.game_v2?.currentLocationId,
  );
  const currentChallengeId = useTypedSelector(
    (state) => state.game_v2?.currentChallengeId,
  );

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const isLRAdmin = useTypedSelector((state) =>
    state.user?.info?.email?.includes?.('letsroam'),
  );

  const groupId = useTypedSelector((state) => state?.group?.info?.groupId);

  const catchLoginPress = (
    route: keyof TNavigationRouteParams,
    params = {},
  ) => {
    if (loggedIn) {
      navigationRef.navigate(route, params, 'catchLoginPress 1');
    } else {
      navigationRef.navigate(
        'LoginModal',
        {
          navigateOnCloseRoute: route,
          message: 'You must log in to use this feature',
        },
        'catchLoginPress 2',
      );
    }
  };

  const deviceUUID = useTypedSelector((state) => state.app_info?.deviceUUID);

  const handleGoHome = () => {
    dispatchAction(setCurrentGroupId(null));
    const currentScreen = getReduxState(
      (state) => state?.current_screen?.currentScreen,
    );
    if (currentScreen == 'LandingPage') {
      navigationRef?.goBack('Nav/DrawerMenu.tsx');
    }
    navigationRef.navigate('LandingPageMain', null, 'DrawerMenu handleGoHome');
    setTimeout(() => {
      navigationRef.navigate('LandingPage', null, 'Nav/DrawerMenu.tsx');
    }, 500);
  };

  const isAppLoading = useTypedSelector((state) =>
    ['AppLoadingPage', 'AppIntroSwiper', 'LoginPage'].includes(
      state.current_screen?.currentScreen || '',
    ),
  );

  useEffect(() => {
    //This has been put in place because permissions expire after a while
    //Normally permissions would be set up through the intro slider
    //But if the user isn't shown the intro slider, then the landing page
    //would trigger the dispatch and permissions would be prompted, if expired.
  }, [isAppLoading]);

  // note this component has a should component update
  // make sure to add relevant variables

  //console.log('drawer menu is doing a render')

  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        bottom: 0,
      }}>
      <View style={{flex: 1}}>
        <View
          style={{
            height: 130,
            paddingTop: isX ? 40 : 20,
            width: '100%',
            backgroundColor: constants?.color?.orange,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <UserProfileIcon
            showName
            textColor={constants?.color?.white}
            handleSignInPress={() => {
              if (loggedIn) {
                catchLoginPress('ProfilePage', {
                  view: 'profile',
                });
              } else {
                navigationRef.navigate('LoginModal', {}, 'Nav/DrawerMenu.tsx');
              }
            }}
          />
        </View>
        <ScrollView>
          <View style={{flex: 1, marginTop: 10, position: 'relative'}}>
            <NavButton
              screenTitle={'Home'}
              icon={'home'}
              navigationAction={handleGoHome}
            />

            {playTestState == false && !!loggedIn && (
              <NavButton
                screenTitle={`View Badges (${badgesEarnedCount || 0})`}
                icon={'badge'}
                navigationAction={() => {
                  navigationRef.navigate(
                    'AllBadgesModal',
                    {},
                    'Nav/DrawerMenu.tsx',
                  );
                }}
              />
            )}

            {playTestState == false && (
              <NavButton
                screenTitle={'FAQs'}
                icon={'question'}
                navigationAction={() =>
                  navigationRef.navigate('FAQModal', null, 'Nav/DrawerMenu.tsx')
                }
              />
            )}

            {playTestState == false && (
              <NavButton
                screenTitle={'Redeem Code'}
                icon={'key'}
                navigationAction={() => {
                  getAppType() === 'aa'
                    ? catchLoginPress('JoinAA', {
                        view: 'JoinAA',
                      })
                    : catchLoginPress('JoinLanding', {
                        view: 'JoinLanding',
                      });
                }}
              />
            )}

            {playTestState == false && (
              <NavButton
                screenTitle={'Get Tickets'}
                icon={'handbag'}
                navigationAction={() => {
                  if (!loggedIn) {
                    return navigationRef.navigate(
                      'LoginModal',
                      {
                        navigateOnCloseRoute: 'PurchaseModal',
                        message: 'You must log in to get tickets',
                      },
                      'Get Tickets Drawer Menu',
                    );
                  }
                  navigationRef.navigate(
                    'PurchaseModal',
                    {},
                    'Nav/DrawerMenu.tsx',
                  );
                }}
              />
            )}
            {playTestState == false && (
              <NavButton
                screenTitle={'Contact Support'}
                icon={'support'}
                navigationAction={() => {
                  navigationRef.navigate(
                    'SupportModal',
                    {},
                    'Nav/DrawerMenu.tsx',
                  );
                }}
              />
            )}

            {shareLink != null && !!playTestState && (
              <NavButton
                screenTitle={'Refer A Friend'}
                icon={'user-following'}
                navigationAction={() => {
                  navigationRef.navigate(
                    'ReferralModal',
                    null,
                    'Nav/DrawerMenu.tsx',
                  );
                }}
              />
            )}

            {!loggedIn && (
              <NavButton
                screenTitle={'Sign In'}
                icon={'user-follow'}
                navigationAction={() =>
                  navigationRef.navigate('LoginModal', {}, 'Nav/DrawerMenu.tsx')
                }
              />
            )}

            {!!loggedIn && (
              <NavButton
                screenTitle={'Sign Out'}
                icon={'user-unfollow'}
                navigationAction={() => {
                  navigationRef.navigate(
                    'LandingPage',
                    null,
                    'Nav/DrawerMenu.tsx',
                  );
                  setTimeout(() => {
                    setCurrentGroupId(null);
                    dispatchAction(logOutUser());
                  }, 1000);
                }}
              />
            )}
            {!!(isLRAdmin || __DEV__) && (
              <NavButton
                screenTitle={playTestState ? 'Exit Playtest' : 'Enter Playtest'}
                icon={'arrow-right'}
                navigationAction={async () => {
                  let versionNumber =
                    Application.nativeApplicationVersion || 'web';
                  versionNumber = versionNumber?.replace(/\./gi, '_');

                  const featurePath = `versionInfo/${Platform.OS}/${versionNumber}/playTest`;
                  console.log({featurePath});
                  await setDataAtPath(featurePath, !playTestState ? 1 : 0);
                  navigationRef.navigate(
                    'LandingPageMain',
                    null,
                    'Drawer Menu play',
                  );
                }}
              />
            )}
            {!!(isLRAdmin || __DEV__) && (
              <NavButton
                screenTitle={'Force Navigation'}
                icon={'arrow-right'}
                navigationAction={() => {
                  navigationRef.navigate(
                    'ConfirmModal',
                    {
                      titleText: `Where do you want to navigate?`,
                      titleBodyText: `This will reset your app to base state, and log you out of your user account. Common routes are:
                    \nMain: \nLandingPageMain, LoginPage,LoginAdditionalInfoFullScreenPage,PassportStack,PreGameStack,MainGameStack \n
                    \nGame: \nHuntLoading, HuntIntro, NavigationChallenge, NavigationScore, LocationOutro, ClassicChallenges, ClassicIntro, LocationComplete, LocationChallenge, LocationChallengeScore, HuntOutro, TeamPhoto, HuntFinished, HuntFinishedPlayer, HuntPhotos, PlayerDashboard_v2
                    \nFormat:\nRoute|||{JSON}
                      `,
                      showPrompt: true,
                      confirmText: 'Navigate',
                      cancelText: 'Cancel',
                      handleConfirm: (text, __, handleDone) => {
                        if (text) {
                          const [route, jsonString = '{}'] = text.split('||');
                          console.log({route, jsonString});
                          if (typeof handleDone == 'function') {
                            handleDone();
                          }
                          navigationRef.navigate(
                            route as keyof TNavigationRouteParams,
                            dJSON.parse(jsonString),
                            'DrawerMenu ConfirmModal',
                          );
                          // NavigationChallenge||{locationId: l_131}
                        }
                      },
                    },
                    'DrawerMenu',
                  );
                }}
              />
            )}

            {(!!isLRAdmin || !!__DEV__) && (
              <NavButton
                screenTitle={'Debug Listeners'}
                icon={'info'}
                navigationAction={async () => {
                  const firebaseListeners = getReduxState(
                    (state) => state?.current_screen?.firebaseListeners,
                  );
                  console.log({firebaseListeners});

                  navigationRef.devAlert(
                    Object.values(firebaseListeners || []),
                  );
                }}
              />
            )}
            {(!!isLRAdmin || !!__DEV__) && (
              <NavButton
                screenTitle={'Clear Storage'}
                icon={'size-actual'}
                navigationAction={async () => {
                  navigationRef.navigate(
                    'ConfirmModal',
                    {
                      titleText: `Are you Sure You Want To Clear Your Storage?`,
                      titleBodyText:
                        'This will reset your app to base state, and log you out of your user account.',
                      confirmText: 'Delete Device Storage',
                      cancelText: 'Cancel',
                      handleConfirm: async () => {
                        mmkvStorage?.clearAll?.();
                        setCurrentGroupId(null);
                        dispatchAction(logOutUser());
                        await AsyncStorage.clear();
                        Alert.alert('Device Storage Cleared');
                        navigationRef.goBack('Clear Storage', true);
                        navigationRef.goBack('Clear Storage', true);
                        navigationRef.goBack('Clear Storage', true);
                        navigationRef.goBack('Clear Storage', true);
                        navigationRef.goBack('Clear Storage', true);
                        navigationRef.navigate(
                          'AppLoadingPage',
                          null,
                          'Nav/DrawerMenu.tsx',
                        );
                        const deviceUUID = uuidv4()?.substring(0, 8);
                        mmkvStorage.set('deviceUUID', deviceUUID);

                        AsyncStorage.setItem('deviceUUID', deviceUUID);
                        setTimeout(() => {
                          dispatchAction(setCurrentGroupId(null));
                          dispatchAction(setPreviousVisit(0));
                          dispatchAction(logOutUser());
                          mmkvStorage?.clearAll?.();
                          AsyncStorage.clear();
                          dispatchAction({type: 'HARD_RESET'});
                        }, 1000);
                        Updates?.reloadAsync?.();
                      },
                    },
                    'DrawerMenu 10',
                  );
                }}
              />
            )}
            {(!!isLRAdmin || !!__DEV__) && (
              <NavButton
                screenTitle={'Reset Notifications'}
                icon={'size-actual'}
                navigationAction={async () => {
                  await AsyncStorage.removeItem('lastInvocationTime');
                  await AsyncStorage.removeItem('doneHunts');
                  Alert.alert('cleared notifications');
                }}
              />
            )}

            {!!userId && (
              <NavButton
                screenTitle={'Delete User'}
                icon={'size-actual'}
                navigationAction={() => {
                  if (userId?.length > 2) {
                    navigationRef.navigate(
                      'ConfirmModal',
                      {
                        titleText: `Are you Sure You Want To Delete Your User?`,
                        titleBodyText:
                          'This will delete your user, progress, and all your info from our servers. This can not be undone.',
                        confirmText: 'DELETE ALL USER INFO',
                        cancelText: 'Cancel',
                        handleConfirm: async () => {
                          const eventId = getReduxState(
                            (state) => state?.group?.info?.eventId,
                          );
                          await api_delete_user_or_group({
                            id: userId,
                            type: 'user',
                            event_id: eventId,
                          });
                          navigationRef.navigate(
                            'LandingPage',
                            null,
                            'Nav/DrawerMenu.tsx',
                          );
                          setTimeout(() => {
                            logOutUser();
                            navigationRef.goBack('Clear Storage', true);
                            navigationRef.goBack('Clear Storage', true);
                            navigationRef.goBack('Clear Storage', true);
                            navigationRef.goBack('Clear Storage', true);

                            navigationRef.navigate(
                              'LandingPageMain',
                              null,
                              'DrawerMenu delete user',
                            );
                            setCurrentGroupId(null);
                            dispatchAction(logOutUser());
                            mmkvStorage?.clearAll?.();
                            dispatchAction({type: 'HARD_RESET'});

                            Updates?.reloadAsync?.();
                          }, 1000);
                        },
                      },
                      'DrawerMenu 11',
                    );
                  }
                }}
              />
            )}

            {(!!isLRAdmin || !!__DEV__) && !!currentHuntInfo?.groupId && (
              <NavButton
                screenTitle={'Delete Group'}
                icon={'size-actual'}
                navigationAction={async () => {
                  if (!!currentHuntInfo?.groupId) {
                    const eventId = getReduxState(
                      (state) => state?.group?.info?.eventId,
                    );
                    await api_delete_user_or_group({
                      id: currentHuntInfo?.groupId,
                      type: 'group',
                      event_id: eventId,
                    });
                    setDataAtPath(`users/${userId}/currentHuntInfo`, null);
                    navigationRef.navigate(
                      'LandingPage',
                      null,
                      'Nav/DrawerMenu.tsx',
                    );
                  }
                }}
              />
            )}

            {(!!isLRAdmin || !!__DEV__) && !!currentHuntInfo?.groupId && (
              <NavButton
                screenTitle={'Finish Hunt'}
                icon={'size-actual'}
                navigationAction={() => {
                  if (currentHuntInfo?.groupId) {
                    setDataAtPath(
                      `scavengerhunt/groups/${
                        currentHuntInfo?.groupId || 'error'
                      }/huntFinished`,
                      true,
                    );
                    navigationRef.navigate(
                      'LandingPage',
                      null,
                      'Nav/DrawerMenu.tsx',
                    );
                  }
                }}
              />
            )}

            {(!!isLRAdmin || !!__DEV__) && (
              <NavButton
                screenTitle={'Get Redux Size'}
                icon={'size-actual'}
                navigationAction={() => {
                  // @ts-ignore
                  function sizeof(object) {
                    const string = JSON.stringify(object);
                    const sizeInBytes = new Blob([string]).size;
                    return sizeInBytes / (1024 * 1024); // Convert to MB
                  }

                  function getSizeOfEachKey(obj: TJSONValue): string {
                    let result = '';

                    for (const key in obj) {
                      result += `\n${key} - ${sizeof(obj[key])}`;
                    }

                    return result;
                  }

                  const store = getReduxState((state) => state);
                  const storeSize = getSizeOfEachKey(store);
                  navigationRef.simpleAlert('Redux Storage in MB', storeSize);
                }}
              />
            )}

            {(!!isLRAdmin || !!__DEV__) && (
              <NavButton
                screenTitle={'Simulate Error'}
                icon={'size-actual'}
                navigationAction={() => {
                  navigationRef.navigate(
                    'ConfirmModal',
                    {
                      titleText: `What type of error do you want?`,
                      confirmText: 'JS Error',
                      cancelText: 'Native Error',
                      thirdOption: 'Cancel',
                      handleConfirm: async (_, __, handleDone: () => void) => {
                        const data: unknown[] = [];
                        // simulated error
                        // don't freak lol
                        (data as unknown[]).length;
                        if (typeof handleDone == 'function') {
                          handleDone();
                        }
                      },
                      handleCancel: (handleDone: () => void) => {
                        if (typeof handleDone == 'function') {
                          handleDone();
                        }
                      },
                    },
                    'Simulate Error',
                  );
                }}
              />
            )}

            {(!!isLRAdmin || !!__DEV__) && (
              <NavButton
                screenTitle={'Show Logs'}
                icon={'paper-plane'}
                navigationAction={() => {
                  navigationRef.navigate('ShowLogsModal', null, 'SideBar');
                }}
              />
            )}

            {(!!isLRAdmin || !!__DEV__) && (
              <NavButton
                screenTitle={'Sign In As Another User'}
                icon={'user-follow'}
                navigationAction={() => {
                  navigationRef.navigate(
                    'ConfirmModal',
                    {
                      titleText: `What user id?`,
                      titleBodyText: `Pick a user to sign in as. Dev feature.`,
                      showPrompt: true,
                      confirmText: 'Navigate',
                      cancelText: 'Cancel',
                      handleConfirm: async (
                        text,
                        __,
                        handleDone: () => void,
                      ) => {
                        if (text) {
                          const userDataPath = `users/${
                            text?.toLowerCase() || 'error'
                          }`;
                          console.log({userDataPath});
                          const userInfo = (await getDataAtPath(
                            userDataPath,
                          )) as TUser | null;
                          if (!userInfo) {
                            return Alert.alert('No user info found');
                          }

                          if (userInfo.userId) {
                            mmkvStorage.set('userId', userInfo.userId);
                            AsyncStorage.setItem('userId', userInfo.userId);
                          }
                          mmkvStorage.set('userInfo', JSON.stringify(userInfo));
                          dispatchAction(saveUserInfo(userInfo));
                          Alert.alert('You are signed in as that user');
                          handleDone();
                        }
                      },
                    },
                    'Sign In As Another User',
                  );
                }}
              />
            )}

            {(!!isLRAdmin || !!__DEV__) && !!groupId && !!userId && (
              <NavButton
                screenTitle={'Extend Hunt Time'}
                icon={'clock'}
                navigationAction={() => {
                  navigationRef.navigate(
                    'ConfirmModal',
                    {
                      titleText: `Do you want to add extra minutes to your hunt?`,
                      titleBodyText: `Dev feature.`,
                      showPrompt: false,
                      confirmText: 'Enter',
                      cancelText: 'Cancel',
                      handleConfirm: async (text) => {
                        if (text) {
                          await addExtraHuntTime();
                          Alert.alert('Successfully extended the timer');
                          navigationRef.closeModal(
                            'ConfirmModal',
                            'DrawerMenu.tsx',
                          );
                        }
                      },
                    },
                    'Extend the Hunt Time',
                  );
                }}
              />
            )}

            {!!eventId && (
              <NavButton
                screenTitle={'Leave Event'}
                icon={'size-actual'}
                navigationAction={() => {
                  console.log('setEventId DrawerMenu');
                  dispatchAction(setEventId(null));
                  dispatchAction(updateEventInfo(null));
                  navigationRef.navigate(
                    'LandingPage',
                    null,
                    'Nav/DrawerMenu.tsx',
                  );
                }}
              />
            )}

            {(!!isLRAdmin || !!__DEV__) && (!!utm_source || !!customAdView) && (
              <NavButton
                screenTitle={'Show Ad Info'}
                icon={'size-actual'}
                navigationAction={() => {
                  Alert.alert(
                    `The UTM Source is ${utm_source}
                     and the custom ad view is ${customAdView}`,
                  );
                }}
              />
            )}
          </View>

          <View style={{marginBottom: 20}}>
            <TouchableOpacity
              style={{
                marginLeft: 10,
                marginTop: 2,
              }}
              onPress={() => {
                openFirebaseLink(
                  `https://console.firebase.google.com/u/0/project/barhuntv2/database/barhuntv2/data/versionInfo/${
                    Platform.OS
                  }/${(Updates.runtimeVersion as string)?.replace?.(
                    /\./gi,
                    '_',
                  )}`,
                  isLRAdmin,
                );
              }}>
              <Text style={{fontSize: 10}}>
                {`App Version: ${Updates.runtimeVersion || 'No Device Id'}`}
              </Text>
            </TouchableOpacity>
            {playTestState == false && !!loggedIn && (
              <TouchableOpacity
                style={{
                  marginLeft: 10,
                  marginTop: 2,
                }}
                onPress={() => {
                  openFirebaseLink(
                    `https://console.firebase.google.com/u/0/project/barhuntv2/database/barhuntv2/data/users/${
                      userId || ''
                    }`,
                    isLRAdmin,
                  );
                }}>
                <Text style={{fontSize: 10}}>{`User Id: ${userId || ''}`}</Text>
              </TouchableOpacity>
            )}
            {playTestState == false && !!currentHuntInfo?.groupId && (
              <TouchableOpacity
                style={{
                  marginLeft: 10,
                  marginTop: 2,
                }}
                onPress={() => {
                  openFirebaseLink(
                    `https://console.firebase.google.com/u/0/project/barhuntv2/database/barhuntv2/data/scavengerhunt/groups/${
                      currentHuntInfo?.groupId || ''
                    }`,
                    isLRAdmin,
                  );
                }}>
                <Text style={{fontSize: 10}}>
                  {`Group Id: ${currentHuntInfo?.groupId || ''}`}
                </Text>
              </TouchableOpacity>
            )}

            {playTestState == false &&
              !!loggedIn &&
              !!currentHuntInfo?.groupId && (
                <TouchableOpacity
                  style={{
                    marginLeft: 10,
                    marginTop: 2,
                  }}
                  onPress={() => {
                    openFirebaseLink(
                      `https://console.firebase.google.com/u/0/project/barhuntv2/database/barhuntv2/data/scavengerhunt/completions/${
                        currentHuntInfo?.groupId || ''
                      }`,
                      isLRAdmin,
                    );
                  }}>
                  <Text style={{fontSize: 10}}>
                    {`Hunt Id: ${currentHuntInfo?.huntId || 'No Hunt Id'}`}
                  </Text>
                </TouchableOpacity>
              )}

            {playTestState == false && !!deviceUUID && (
              <TouchableOpacity
                style={{
                  marginLeft: 10,
                  marginTop: 2,
                }}
                onPress={() => {
                  openFirebaseLink(
                    `https://hventures2.letsroam.com/admin/users/see_live/${
                      deviceUUID || ''
                    }`,
                    isLRAdmin,
                  );
                }}>
                <Text style={{fontSize: 10}}>
                  {`Device Id: ${deviceUUID || 'No Device Id'}`}
                </Text>
              </TouchableOpacity>
            )}
            {!!currentLocationId && !!(__DEV__ || isLRAdmin) && (
              <TouchableOpacity
                style={{
                  marginLeft: 10,
                  marginTop: 2,
                }}
                onPress={() => {
                  openFirebaseLink(
                    `https://console.firebase.google.com/u/0/project/barhuntv2/database/barhuntv2/data/scavengerhunt/completions/${
                      currentHuntInfo?.groupId || ''
                    }/location_v2/locations/${currentLocationId}`,
                    isLRAdmin,
                  );
                }}>
                <Text style={{fontSize: 10}}>
                  {`Location Id: ${currentLocationId}`}
                </Text>
              </TouchableOpacity>
            )}
            {!!currentChallengeId && !!(__DEV__ || isLRAdmin) && (
              <TouchableOpacity
                style={{
                  marginLeft: 10,
                  marginTop: 2,
                }}
                onPress={() => {
                  openFirebaseLink(
                    `https://console.firebase.google.com/u/0/project/barhuntv2/database/barhuntv2/data/scavengerhunt/completions/${
                      currentHuntInfo?.groupId || ''
                    }/location_v2/allChallenges/${currentChallengeId}`,
                    isLRAdmin,
                  );
                }}>
                <Text style={{fontSize: 10}}>
                  {`Challenge Id: ${currentChallengeId}`}
                </Text>
              </TouchableOpacity>
            )}
            {!!(__DEV__ || isLRAdmin) && (
              <TouchableOpacity
                style={{
                  marginLeft: 10,
                  marginTop: 2,
                  marginBottom: 0,
                }}
                onPress={() => {
                  openFirebaseLink(
                    `https://hventures2.letsroam.com/admin/corporate/lead_details?uuid=${eventId}`,
                    isLRAdmin,
                  );
                }}>
                <Text style={{fontSize: 10}}>
                  {`Event Info: ${eventId || 'No Event Id'}`}
                </Text>
              </TouchableOpacity>
            )}
            {playTestState == false && !!loggedIn && !!eventId && (
              <TouchableOpacity
                style={{
                  marginLeft: 10,
                  marginTop: 2,
                  marginBottom: -10,
                }}
                onPress={() => {
                  openFirebaseLink(
                    `https://console.firebase.google.com/u/0/project/barhuntv2/database/barhuntv2/data/customEvents/${eventId}`,
                  );
                }}>
                <Text style={{fontSize: 10}}>
                  {`Event Id: ${eventId || 'No Event Id'}`}
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </ScrollView>
      </View>
    </View>
  );
};

export default DrawerMenuWrapper;

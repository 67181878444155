import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TPrizesLeaderboardItem} from 'src/types/TLeaderBoardItem';

interface HomeState {
  homeView: 'list' | 'map';
  homeSearchText: string | false;
  homeActiveSearch: boolean;
  leaderboard: TPrizesLeaderboardItem[];
}

const initialState: HomeState = {
  homeView: 'list',
  homeSearchText: false,
  homeActiveSearch: false,
  leaderboard: [],
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    updateHomeView: (state, action: PayloadAction<'list' | 'map'>) => {
      state.homeView = action.payload;
    },
    updateHomeSearchText: (state, action: PayloadAction<string | false>) => {
      state.homeSearchText = action.payload;
    },
    updateHomeActiveSearch: (state, action: PayloadAction<boolean>) => {
      state.homeActiveSearch = action.payload;
    },
    resetHomeView: (state) => {
      state = {...initialState, leaderboard: state.leaderboard};
      return state;
    },
    setLeaderboard: (
      state,
      action: PayloadAction<TPrizesLeaderboardItem[]>,
    ) => {
      state.leaderboard = action.payload;
    },
    updateHomeState: (state, action: PayloadAction<Partial<HomeState>>) => {
      const newReducerState: HomeState = {
        ...state,
        ...(action.payload || {}),
      };
      // console.log({newReducerState});
      return newReducerState;
    },
  },
});

export const {
  updateHomeView,
  updateHomeSearchText,
  updateHomeActiveSearch,
  updateHomeState,
  resetHomeView,
  setLeaderboard,
} = homeSlice.actions;

export default homeSlice.reducer;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TChallenge} from 'src/types/TChallenge';
import {TLocalCompletion} from 'src/types/TLocalCompletion';
import {TLocalLocation} from 'src/types/TLocalLocation';
import {THunt} from 'src/types/THunt';

export interface TTagId {
  id: string;
  isDefault?: boolean;
  name?: string;
}

interface LocalFilters {
  tag_ids?: string[] | null;
  star_ratings?: string[] | null;
  title?: string | null;
  hasStarFilter?: boolean;
  hasTagFilter?: boolean;
  hasTitleFilter?: boolean;
  filter_count: number;
}

type LocalViews = 'list' | 'map' | 'leaderboard';

export interface TNewWanderLocation {
  address?: string;
  lat: number;
  long: number;
  name: string;
  description: string;
}

interface LocalState {
  localView: LocalViews;
  localLocations: Record<string, TLocalLocation>;
  localLocationsList: TLocalLocation[];
  selectedLocalLocationId: string | null;
  locationTagTypes: TTagId[];
  localCompletions: Record<string, TLocalCompletion>;
  localCompletionsList: TLocalCompletion[];
  localChallenges: Record<string, TChallenge>;
  localChallengesList: TChallenge[];
  localFavorites: string[];
  localFilters: LocalFilters;
  showCreateChallengeModal?: boolean;
  wanderlists: THunt[];
  newWanderList: {
    title: string;
    description: string;
    image?: string;
    locations?: TNewWanderLocation[];
  };
}

const newWanderList = {
  title: '',
  description: '',
  image: '',
  locations: [],
};

const initialState: LocalState = {
  localView: 'list',
  localLocations: {},
  localLocationsList: [],
  selectedLocalLocationId: null,
  locationTagTypes: [],
  localCompletions: {},
  localCompletionsList: [],
  localChallenges: {},
  localChallengesList: [],
  localFavorites: [],
  localFilters: {
    tag_ids: [],
    star_ratings: [],
    filter_count: 0,
  },
  wanderlists: [],
  newWanderList,
};

const localSlice = createSlice({
  name: 'local',
  initialState,
  reducers: {
    updateLocalView(state, action: PayloadAction<LocalViews>) {
      state.localView = action.payload;
    },
    updateSelectedLocalLocationId(state, action: PayloadAction<string | null>) {
      state.selectedLocalLocationId = action.payload;
    },
    setLocalLocations(
      state,
      action: PayloadAction<Record<string, TLocalLocation>>,
    ) {
      state.localLocations = action.payload;
    },
    setLocalLocationsList(state, action: PayloadAction<TLocalLocation[]>) {
      state.localLocationsList = action.payload;
    },
    setLocationTagTypes(state, action: PayloadAction<TTagId[]>) {
      state.locationTagTypes = action.payload;
    },
    setLocalCompletions(
      state,
      action: PayloadAction<Record<string, TLocalCompletion>>,
    ) {
      state.localCompletions = action.payload;
    },
    setLocalCompletionsList(state, action: PayloadAction<TLocalCompletion[]>) {
      state.localCompletionsList = action.payload;
    },
    setShowCreateChallengeModal(state, action: PayloadAction<boolean>) {
      state.showCreateChallengeModal = action.payload;
    },
    setWanderlists(state, action: PayloadAction<THunt[]>) {
      if (action.payload) {
        state.wanderlists = action.payload;
      }
    },
    setLocalChallenges(
      state,
      action: PayloadAction<Record<string, TChallenge>>,
    ) {
      state.localChallenges = {...state.localChallenges, ...action.payload};
      state.localChallengesList = Object.values(action.payload);
    },
    setLocalFavorites(state, action: PayloadAction<string[]>) {
      state.localFavorites = action.payload;
    },
    setLocalFilters(state, action: PayloadAction<Partial<LocalFilters>>) {
      const {tag_ids, star_ratings, title} = action.payload;
      const hasTagFilter = !!tag_ids?.length;
      const hasStarFilter = !!star_ratings?.length;
      const hasTitleFilter = !!title;

      state.localFilters = {
        ...action.payload,
        hasStarFilter,
        hasTagFilter,
        hasTitleFilter,
        filter_count:
          (hasStarFilter ? 1 : 0) +
          (hasTagFilter ? 1 : 0) +
          (hasTitleFilter ? 1 : 0),
      };
    },
    clearLocalFilters(state) {
      state.localFilters = {
        hasStarFilter: false,
        hasTagFilter: false,
        hasTitleFilter: false,
        filter_count: 0,
        title: null,
        tag_ids: [],
        star_ratings: [],
      };
    },
    clearNewWanderList(state) {
      state.newWanderList = newWanderList;
    },
    updateNewWanderListTitle(state, action: PayloadAction<string>) {
      state.newWanderList.title = action.payload;
    },
    updateNewWanderListDescription(state, action: PayloadAction<string>) {
      state.newWanderList.description = action.payload;
    },
    updateNewWanderListImage(state, action: PayloadAction<string>) {
      state.newWanderList.image = action.payload;
    },
    addNewWanderListLocation(
      state,
      action: PayloadAction<{index?: number; location: TNewWanderLocation}>,
    ) {
      const {index, location} = action.payload;
      if (!index) {
        state.newWanderList.locations?.push(location);
      } else {
        state.newWanderList.locations?.splice(index, 1, location);
      }
    },
    deleteNewWanderListLocation(state, action: PayloadAction<number>) {
      state.newWanderList.locations?.splice(action.payload, 1);
    },
  },
});

export const {
  updateLocalView,
  updateSelectedLocalLocationId,
  setLocalLocations,
  setLocalLocationsList,
  setLocationTagTypes,
  setLocalCompletions,
  setLocalCompletionsList,
  setLocalChallenges,
  setLocalFavorites,
  setLocalFilters,
  clearLocalFilters,
  setShowCreateChallengeModal,
  updateNewWanderListTitle,
  updateNewWanderListDescription,
  updateNewWanderListImage,
  addNewWanderListLocation,
  setWanderlists,
  clearNewWanderList,
  deleteNewWanderListLocation,
} = localSlice.actions;

export default localSlice.reducer;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useEffect, useRef} from 'react';
import {AppState, Platform} from 'react-native';

import * as TaskManager from 'expo-task-manager';

// importing detox
let isDetoxSync: () => boolean;
if (Platform.OS !== 'web') {
  const detoxModule = require('react-native-is-detox');
  isDetoxSync = detoxModule.isDetoxSync;
} else {
  isDetoxSync = () => false;
}

import {
  updateDataAtPath,
  useFirebaseData,
  logAttributesForCrashlytics,
} from 'src/Utils/fireUtils';

import {saveUserLocation} from 'src/Redux/reducers/user_location.reducer';

import moment from 'moment';

import {updateNearbyEvents} from 'src/Redux/reducers/nearby.reducer';
import {fetchWithParams} from 'src/Utils/apiCalls';

import * as Location from 'expo-location';
import {calculateDistance} from 'src/Utils/distanceHelpers';
import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {saveGroupPosition} from 'src/Utils/huntFunctions';

import AsyncStorage from '@react-native-async-storage/async-storage';
import {addDoneHunt, getValidDoneHunts} from 'src/Utils/huntsDoneHelpers';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {TRegionUpdate} from 'src/types/TRegion';
import {TCondensedHunt} from 'src/types/TCondensedHunt';

const LocationController: React.FC = () => {
  const userId = useTypedSelector((state) => state.user?.userId);
  const groupId = useTypedSelector(
    (state) => state.user?.info?.currentHuntInfo?.groupId,
  );
  const deviceUUID = useTypedSelector((state) => state.app_info?.deviceUUID);

  const appStateVisible = useTypedSelector(
    (state) => state?.current_screen?.appStateVisible,
  );
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const isAppLoading = useTypedSelector(
    (state) =>
      ['AppLoadingPage', 'AppIntroSwiper'].includes(
        state.current_screen?.currentScreen || '',
      ) || !state.current_screen?.currentScreen,
  );

  const hasPermissionOrCurrentScreen = useTypedSelector((state) => {
    state.location?.hasGPSLocationData || state.current_screen?.currentScreen;
  });

  const [foregroundPermission, requestForegroundPermission] =
    Location.useForegroundPermissions?.();

  let backgroundStatus: Location.PermissionResponse | null = null,
    requestBackgroundPermission: () => Promise<Location.PermissionResponse> =
      // @ts-ignore
      () => ({} as Location.PermissionResponse);
  if (Platform.OS === 'ios') {
    [backgroundStatus, requestBackgroundPermission] =
      Location.useBackgroundPermissions?.();
  }

  console.log('LocationController render', {
    userId,
    groupId,
    appStateVisible,
    playTestState,
    foregroundPermission,
    backgroundStatus,
    isAppLoading,
    hasPermissionOrCurrentScreen,
  });

  const startLocationBackgroundLister = async () => {
    console.log('background-location-task registered');

    const settings = {
      accuracy: Location.Accuracy.Lowest,
      deferredUpdatesInterval: __DEV__ ? undefined : 1000 * 60 * 120, // none or 120 minutes
      foregroundService: {
        notificationTitle: 'Location Service',
        notificationBody: 'This is running in background',
      },
    };

    Location.startLocationUpdatesAsync('background-location-task', settings)
      .then((result) =>
        console.log('background-location-task registered success', {result}),
      )
      .catch((error) =>
        console.log('background-location-task registered failed', {error}),
      );
  };

  const trackingOn = useRef<boolean>(false);

  useEffect(() => {
    if (Platform.OS !== 'ios') {
      return console.log('background not possible on android');
    }

    logAttributesForCrashlytics(
      'locationPermissionStatus_background_ios_only',
      `${backgroundStatus?.status || ''}`,
    );

    if (backgroundStatus?.status === 'granted' && !playTestState) {
      trackingOn.current = true;
      console.log('locationPermissionStatus_background_ios_only');
      startLocationBackgroundLister();
    } else if (playTestState) {
      console.log('stopLocationUpdatesAsync because playTest');
      try {
        if (trackingOn.current === true) {
          Location.stopLocationUpdatesAsync('background-location-task');
        }
      } catch (e) {
        // task not registered
      }
    }
  }, [backgroundStatus, playTestState]);

  useEffect(() => {
    logAttributesForCrashlytics(
      'locationPermissionStatus_foreground',
      `${foregroundPermission?.status || ''}`,
    );
  }, [foregroundPermission]);

  const savePosition = (position: TRegionUpdate, type: number) => {
    console.log('savePosition');
    const currentPosition = getReduxState((state) => state?.location?.region);

    if (currentPosition?.latitude && position?.coords?.latitude) {
      const distanceChange = calculateDistance(
        currentPosition?.latitude,
        currentPosition?.longitude,
        position?.coords?.latitude,
        position?.coords?.longitude,
        'FEET',
      );

      if (groupId) {
        saveGroupPosition(
          position?.coords || {},
          'LocationController: distance change:' + distanceChange,
        );
      }

      if (distanceChange < 100) {
        return console.log('location update stopped', {distanceChange});
      } else {
        console.log('location update allowed 1');
      }
    } else {
      console.log('location update allowed 2');
    }

    console.log({currentPosition, position});

    dispatchAction(saveUserLocation(position));

    mmkvStorage.set('position', JSON.stringify(position));
    console.log('the position was set, type' + type, {position});

    if (userId) {
      updateDataAtPath(`users/${userId}/position`, JSON.stringify(position));
      const latitude = position?.coords?.latitude || 0;
      const longitude = position?.coords?.longitude || 0;

      updateDataAtPath(`users/${userId}/additionalInformation`, {
        latitude,
        longitude,
        platform: Platform.OS,
      });
      updateDataAtPath(`devices/${deviceUUID || 'error'}/deviceData`, {
        latitude,
        longitude,
        platform: Platform.OS,
      });
    }
  };

  useEffect(() => {
    if (!isAppLoading && !isDetoxSync()) {
      ('App Done Loading');

      console.log({foregroundPermission});
      updateDataAtPath(`devices/${deviceUUID || 'error'}/deviceData`, {
        foregroundLocationPermissions: foregroundPermission?.status || null,
      });
      if (foregroundPermission?.status === 'granted') {
        console.log('already granted permission');
      } else {
        console.log(
          'useBackgroundPermission, current status',
          foregroundPermission?.status,
        );
        if (Platform.OS === 'ios') {
          console.log('requesting foreground and background permissions');
          requestBackgroundPermission()
            .then((requestResponse) => {
              console.log({requestResponse});
              updateDataAtPath(`devices/${deviceUUID || 'error'}/deviceData`, {
                backgroundLocationPermissions: requestResponse?.status,
              });
            })
            .catch((requestError) => {
              console.error({requestError});
            });
        } else {
          console.log('requesting foreground permissions');
          requestForegroundPermission()
            .then((requestForegroundPermissionResponse) => {
              console.log({requestForegroundPermissionResponse});
            })
            .catch((requestForegroundPermissionError) => {
              console.error({requestForegroundPermissionError});
            });
        }
      }
    }
  }, [
    foregroundPermission?.status,
    appStateVisible,
    isAppLoading,
    hasPermissionOrCurrentScreen,
  ]);

  useEffect(() => {
    let locationSubscription: Location.LocationSubscription | null | null;
    const listenerNumber = 'LocationListener' + Math.random() + ':';
    const startWatching = async () => {
      console.log('location permission status', {
        foregroundPermission,
        locationSubscription,
      });

      if (
        foregroundPermission?.status === 'granted' ||
        backgroundStatus?.status === 'granted'
      ) {
        console.log('watchPositionAsync');

        try {
          locationSubscription = await Location.watchPositionAsync(
            {
              accuracy: Location.Accuracy.High,
              timeInterval: 1000,
              distanceInterval: 10,
            },
            (location: Location.LocationObject) => {
              console.log(listenerNumber + 'new location');

              console.log(listenerNumber + 'location updated 2', {location});
              savePosition(location, 2);
            },
          );
          console.log({locationSubscription});
        } catch (watchLocationErrorIOS) {
          console.error({watchLocationErrorIOS});
        }
      }
      return () => {
        if (locationSubscription) {
          console.log('locationSubscription removed', {locationSubscription});
          locationSubscription?.remove?.();
        }
      };
    };
    startWatching();
  }, [
    foregroundPermission?.status,
    backgroundStatus?.status,
    appStateVisible,
    isAppLoading,
    hasPermissionOrCurrentScreen,
  ]);

  // console.log('LocationController', {userId, groupId, foregroundPermission});

  return (
    <>
      <LocationControllerNearbyGroupListener playTestState={playTestState} />
    </>
  );
};

export default LocationController;

const LocationControllerNearbyGroupListener = ({
  playTestState,
}: {
  playTestState: boolean;
}) => {
  const veryRoundedRegion = useTypedSelector(
    (state) => state.location.veryRoundedRegion,
  );
  const nearbyGroupPath = `nearbyGroups/${moment().format('YYYY-MM-DD')}/${
    veryRoundedRegion?.latitude
  },${veryRoundedRegion?.longitude}/`;
  console.log({nearbyGroupPath, veryRoundedRegion});

  // Record<string, TNearbyEvent>
  useFirebaseData(
    nearbyGroupPath,
    (nearbyGroupFirebaseData) => {
      dispatchAction(
        updateNearbyEvents(
          nearbyGroupFirebaseData ? Object.values(nearbyGroupFirebaseData) : [],
        ),
      );
    },
    null,
    null,
    false,
    playTestState,
  );
  return <></>;
};

if (Platform.OS == 'ios') {
  TaskManager.defineTask(
    'background-location-task',
    async ({data, error}: {data: unknown; error: unknown}) => {
      try {
        const lastInvocationTime = await AsyncStorage.getItem(
          'lastInvocationTime',
        );

        if (
          lastInvocationTime &&
          Date.now() - parseInt(lastInvocationTime, 10) < 5000
        ) {
          return; // Return if the last invocation was within the last 5 seconds
        }

        if (error) {
          // Error occurred - check `error.message` for more details.
          return console.log('error');
        }

        if (
          data &&
          typeof data == 'object' &&
          'locations' in data &&
          !!data?.locations &&
          typeof data?.locations == 'object' &&
          0 in data?.locations &&
          data?.locations?.[0] &&
          typeof data?.locations?.[0] == 'object' &&
          'coords' in data?.locations?.[0] &&
          !!data?.locations?.[0]?.coords &&
          typeof data?.locations?.[0]?.coords == 'object' &&
          'latitude' in data?.locations?.[0]?.coords &&
          'longitude' in data?.locations?.[0]?.coords
        ) {
          const coords = data?.locations?.[0]?.coords;
          const latitude = Number(coords?.latitude || 0);
          const longitude = Number(coords?.longitude || 0);

          const appState = AppState.currentState;
          if (appState !== 'inactive' && appState !== 'background') {
            return;
          }

          await AsyncStorage.setItem(
            'lastInvocationTime',
            Date.now().toString(),
          );

          const date = new Date();
          const hours = date.getHours();

          const isBetween8AMAnd10PM = hours >= 8 && hours <= 22;

          if (!isBetween8AMAnd10PM) {
            return console.log('Current time is outside normal hours.');
          }

          console.log(
            'backgroundLister background-location-task locationData',
            {
              latitude,
              longitude,
            },
          );

          const condensedHuntsString = await AsyncStorage.getItem(
            'condensedHunts',
          );

          if (!condensedHuntsString) {
            console.log('No hunts found in AsyncStorage');
            return;
          }

          const condensedHunts = JSON.parse(condensedHuntsString);

          let closestHunt: TCondensedHunt | null = null;
          let closestDistance: number = Infinity;

          const foundDoneHuntIds = await getValidDoneHunts();

          console.log({foundDoneHuntIds});

          condensedHunts?.map((hunt: TCondensedHunt) => {
            if (foundDoneHuntIds?.includes?.(hunt?.hunt_id + '')) {
              return;
            }

            const huntLat = hunt.lat;
            const huntLong = hunt.long;

            if (!huntLat || !huntLong) {
              return;
            }

            const distance = calculateDistance(
              latitude,
              longitude,
              huntLat,
              huntLong,
              'FEET',
            );

            if (distance < closestDistance) {
              closestHunt = hunt;
              closestDistance = distance;
            }
          });

          if (!closestHunt) {
            return console.log('No closest hunt found');
          }

          closestHunt = closestHunt as TCondensedHunt;

          console.log('Closest Hunt:', {closestHunt, closestDistance});

          const device_uuid = await AsyncStorage.getItem('deviceUUID');
          const user_id = await AsyncStorage.getItem('userId');

          let notification_sent = false;

          if (closestHunt && closestDistance <= 3000) {
            console.log('A Hunt is within 3000 feet');
            notification_sent = true;
          } else {
            console.log('No hunts within 3000 feet');
          }

          const lastJoinHuntTime = await AsyncStorage.getItem(
            'lastJoinedHuntTime',
          );
          let timePassedSinceLastJoinHunt = null;

          if (lastJoinHuntTime) {
            const currentTime = Date.now();
            timePassedSinceLastJoinHunt = Math.floor(
              (currentTime - parseInt(lastJoinHuntTime, 10)) / (1000 * 60 * 60),
            );
          }

          if (timePassedSinceLastJoinHunt && timePassedSinceLastJoinHunt < 4) {
            console.log('Less than 4 hours since last joined hunt');
            notification_sent = false;
          }

          if (notification_sent && closestHunt?.hunt_id) {
            addDoneHunt(closestHunt?.hunt_id, 7);
          }

          const userClosestHuntData = {
            device_uuid,
            user_id,
            notification_sent,
            latitude,
            longitude,
            distance_away_feet: closestDistance,
            closest_hunt_id: closestHunt?.hunt_id,
            time_since_last_hunt_joined: timePassedSinceLastJoinHunt,
            hunt_name: closestHunt?.name,
            hunt_medium_photo: closestHunt?.hunt_medium_photo,
            hunt_type: closestHunt?.hunt_type,
          };

          console.log({userClosestHuntData});

          fetchWithParams(
            'https://us-central1-barhuntv2.cloudfunctions.net/logUserLocationAndSendNotificaion',
            userClosestHuntData,
          );
        } else {
          console.log('No background data');
        }
      } catch (backgroundLocationError) {
        console.error(backgroundLocationError);
      }
    },
  );
}

// @ts-ignore
import firebase from 'firebase8';

let _database: firebase.database.Database | null = null;
let _storage: firebase.storage.Storage | null = null;
let _auth: firebase.auth.Auth | null = null;
let appStarted = false;

export const messaging = () => {
  return {
    messaging: () => {},
    subscribeToTopic: () => {},
  };
};

export const initFirebase = () => {
  console.log('starting firebase');
  if (!appStarted) {
    console.log('firebase init');

    const config = {
      apiKey: 'AIzaSyCMRIDyzb0VRjWTjCu8CZtnzGkLMbjIMt8',
      authDomain: 'barhuntv2.firebaseapp.com',
      databaseURL: 'https://barhuntv2.firebaseio.com',
      projectId: 'barhuntv2',
      storageBucket: 'barhuntv2.appspot.com',
      messagingSenderId: '472107255951',
      appId: 'barhuntv2', // potentially wrong
    };

    // Firebase.database.enableLogging(true);
    if (!firebase.apps?.length) {
      firebase.initializeApp(config);
    }
    appStarted = true; // Change '==' to '=' to assign the value correctly
  }
};

export const getDatabase = () => {
  if (!_database) {
    initFirebase();
    _database = firebase.database();
  }
  return _database;
};

export const getStorage = () => {
  if (!_storage) {
    initFirebase();
    _storage = firebase.storage();
  }
  return _storage;
};

export const getAuth = () => {
  if (!_auth) {
    _auth = firebase.auth();
  }
  return _auth;
};

export const crashlytics = () => {
  return {};
};

export const analytics = () => {
  return {};
};
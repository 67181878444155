import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useRef, useState} from 'react';
import {View, SafeAreaView} from 'react-native';

import MapView from 'src/Modules/Native/MapView';

/////////////////////////////////////////////
///////  components

import {TopTab, TopTabButton} from 'src/Modules/TabViews';

////////////////// utils

import {
  BottomTabButton,
  BottomTab,
} from 'src/Modules/TabViews/FooterComponents';

import MapMarker from 'src/Modules/MapMarker';

import Photos from 'src/Modules/CommonGame/Photos';

import isIphoneX from 'src/Utils/IphoneXHelper';
const isX = isIphoneX();

/////////////////////////////////////////////
/////////////// utils

import useParams from 'src/Hooks/useParams';
import {navigationRef} from 'src/Nav/navigationHelpers';
import EventLeaderboard from 'src/CustomEvents/EventLeaderboard';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {TGroupInfo} from 'src/types/TGroupInfo';
import {TSimpleGroupInfo} from 'src/types/TSimpleGroup';
import EventLeaderboardHybrid from './EventLeaderboardHybrid';
import {SimpleHeaderBar} from 'src/HeaderComponent/HeaderComponents';
import {useFirebaseData} from 'src/Utils/fireUtils';

const EventDashboard: React.FC = () => {
  console.log('EventDashboard re-render');
  const event = useTypedSelector((state) => state.event);

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  const defaultView = !event.info?.hideLeaderboard ? 'Leaderboard' : 'Map';
  const {currentView: initialView} = useParams<'EventDashboard'>();
  const {hideBackButton = false} = useParams<'EventDashboard'>();
  const [currentView, setCurrentView] = useState<string>(
    initialView || defaultView,
  );
  const [showDeletedTeams, setShowDeletedTeams] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<TGroupInfo | null>();

  // updating the event info stored in redux based on firebase state
  // data is stored at customEvents/$eventId

  // searching for groups based on eventId
  // then filtering through the groups
  // this is done so all groups can be shown

  // @ts-ignore
  const hunt = {...event?.info} || {};

  hunt.name = hunt.company ? hunt.company + '' : hunt.groupType + '';

  if (!event || !event.info) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <CustomActivityIndicator />
      </View>
    );
  }

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: constants?.color?.white}}>
      <SimpleHeaderBar />
      <TopTab>
        {!event.info?.hideLeaderboard && (
          <TopTabButton
            index={0}
            text={'Leaderboard'}
            active={currentView === 'Leaderboard'}
            handlePress={() => {
              setCurrentView('Leaderboard');
            }}
          />
        )}
        {!event.info?.locateTeamsOff && !isHybridHunt && (
          <TopTabButton
            index={1}
            text={'Map'}
            active={currentView === 'Map'}
            handlePress={() => {
              setCurrentView('Map');
            }}
          />
        )}
        {!isHybridHunt && !event.info?.hidePhotos && (
          <TopTabButton
            index={2}
            text={'Photos'}
            active={currentView === 'Photos'}
            handlePress={() => {
              setCurrentView('Photos');
            }}
          />
        )}
      </TopTab>
      <View
        style={{
          flex: 1,
          position: 'relative',
          backgroundColor: constants?.color?.white1,
        }}>
        {currentView == 'Map' && (
          <RenderMap
            showDeletedTeams={false}
            selectedGroup={selectedGroup || undefined}
          />
        )}
        {currentView == 'Leaderboard' &&
          (isHybridHunt ? (
            <EventLeaderboardHybrid showDeletedTeams={showDeletedTeams} />
          ) : (
            <EventLeaderboard
              showDeletedTeams={showDeletedTeams}
              setSelectedGroup={setSelectedGroup}
              setCurrentView={setCurrentView}
            />
          ))}
        {currentView === 'Photos' && (
          <GroupPhotos
            showDeletedTeams={false}
            key="groupPhotos"
            selectedGroupId={selectedGroup?.groupId}
            setSelectedGroup={setSelectedGroup}
          />
        )}
      </View>
      <BottomTab
        height={isX ? 75 : 65}
        tabStyle={{
          alignItems: 'flex-start',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}>
        {!hideBackButton && (
          <BottomTabButton
            text={'Go Back'}
            iconName={'arrow-left-circle'}
            handlePress={() =>
              navigationRef?.goBack('CustomEvents/EventDashboard.tsx')
            }
            buttonStyle={{marginTop: 5}}
          />
        )}
        <BottomTabButton
          text={
            showDeletedTeams
              ? 'Hide Deleted / Test Teams'
              : 'Show Deleted / Test Teams'
          }
          iconName={showDeletedTeams ? 'minus' : 'plus'}
          handlePress={() => setShowDeletedTeams(!showDeletedTeams)}
          buttonStyle={{marginTop: 5}}
        />
        <BottomTabButton
          text={'Contact Support'}
          iconName={'phone'}
          handlePress={() =>
            navigationRef.navigate(
              'SupportModal',
              {},
              'CustomEvents/EventDashboard.tsx',
            )
          }
          buttonStyle={{marginTop: 5}}
        />
      </BottomTab>
    </SafeAreaView>
  );
};

export default EventDashboard;

type GroupMarker = TSimpleGroupInfo & {
  lat: number;
  long: number;
};

const RenderMap: React.FC<{
  selectedGroup?: TGroupInfo;
  showDeletedTeams: boolean;
}> = ({selectedGroup, showDeletedTeams}) => {
  const event = useTypedSelector((state) => state.event);
  const groups = useFirebaseData(`groupLocations/${event.eventId || 'error'}/`);
  const {info} = event;
  let initialRegion = {
    latitude:
      typeof info?.lat === 'number'
        ? info?.lat || 39.7392
        : Number(info?.lat || '39.7392'),
    longitude:
      typeof info?.long === 'number'
        ? info?.long || -104.9903
        : Number(info?.long || '-104.9903'),
    latitudeDelta: 0.0722,
    longitudeDelta: 0.0321,
  };
  if (selectedGroup && selectedGroup.position) {
    const position =
      typeof selectedGroup.position != 'object'
        ? JSON.parse(selectedGroup.position)
        : selectedGroup.position;
    initialRegion = {
      latitude: Number(
        position.latitude || position.coords.latitude || '39.7392',
      ),
      longitude: Number(
        position.longitude || position.coords.longitude || '-104.9903',
      ),
      latitudeDelta: 0.01,
      longitudeDelta: 0.005,
    };
  }

  const mapRef = useRef<MapView | null>(null);

  return (
    <View style={{flex: 1, marginBottom: 0}}>
      {!!initialRegion && !!Object.keys(initialRegion).length && (
        <MapView
          key={selectedGroup?.groupId}
          ref={mapRef}
          style={{flex: 1}}
          initialRegion={
            initialRegion || {
              latitude: 39.7392,
              longitude: -104.9903,
              latitudeDelta: 0.01,
              longitudeDelta: 0.005,
            }
          }>
          {!!groups &&
            (Object.values(groups) as GroupMarker[]).map((group) => {
              if (
                group.teamName?.toLowerCase?.().includes('test') &&
                !showDeletedTeams
              ) {
                // return <></>; // return `<></>` instead of `undefined` or `void`
              }

              if (group.lat !== 0 && group.long !== 0) {
                return (
                  <MapMarker
                    lat={group.lat}
                    key={`${group.groupId}_marker`}
                    long={group.long}
                    showMarker={true}
                    title={`Team: ${group.teamName}`}
                    identifier={JSON.stringify(group)}
                    setSelectedHuntId={() => {
                      mapRef?.current?.animateToRegion({
                        latitude: group.lat,
                        longitude: group.long,
                        latitudeDelta: 0.01,
                        longitudeDelta: 0.005,
                      });
                    }}
                  />
                );
              }

              return <></>;
            })}
        </MapView>
      )}
    </View>
  );
};
const GroupPhotos: React.FC<{
  selectedGroupId?: string;
  showDeletedTeams: boolean;
  setSelectedGroup: Function;
}> = ({selectedGroupId, setSelectedGroup}) => {
  // console.log('the event photos are');
  return (
    <View style={{flex: 1, marginBottom: 0}}>
      <Photos
        hideClose
        handleBack={() => {
          navigationRef.goBack('EventDashboard Photos');
        }}
        showFlag
        useEventPhotos
        selectedGroupId={selectedGroupId}
        setSelectedGroup={setSelectedGroup}
      />
    </View>
  );
};

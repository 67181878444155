import React, {useEffect, useRef, useState} from 'react';

import {Text, View} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

const VideoTimeLeft: React.FC<{isRecording: boolean}> = ({isRecording}) => {
  const {width} = useWindowDimensionsSafe();
  const [timeLeft, setTimeLeft] = useState<number>(15);

  const timer = useRef<NodeJS.Timeout | null>(null);

  const setTimer = (newTimeLeft: number) => {
    timer.current = setTimeout(() => {
      if (newTimeLeft < 1) {
        // handleDone();
      } else {
        setTimeLeft(newTimeLeft);
        setTimer(newTimeLeft - 1);
      }
    }, 1000);
  };

  useEffect(() => {
    if (isRecording) {
      setTimeLeft(15);
      setTimer(14);
    }
    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [isRecording]);

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'absolute',
        bottom: 120,
        width,
        height: 30,
      }}>
      <View style={{borderRadius: 10, backgroundColor: 'red'}}>
        <Text
          style={{color: 'white', padding: 5, width: 250, textAlign: 'center'}}>
          {isRecording
            ? `Recording Time Left: ${timeLeft} Seconds`
            : 'Recording Time Limit: 15 seconds'}
        </Text>
      </View>
    </View>
  );
};

export default VideoTimeLeft;

export const localProfileBackups = [
  {
    submitted_by_user_name: 'Irene',
    submitted_by_user_image: require('src/Images/Users/1.jpg'),
  },
  {
    submitted_by_user_name: 'Janette',
    submitted_by_user_image: require('src/Images/Users/2.jpg'),
  },
  {
    submitted_by_user_name: 'Sarah',
    submitted_by_user_image: require('src/Images/Users/3.jpg'),
  },
  {
    submitted_by_user_name: 'Rachel',
    submitted_by_user_image: require('src/Images/Users/4.jpg'),
  },
  {
    submitted_by_user_name: 'Sarah',
    submitted_by_user_image: require('src/Images/Users/5.jpg'),
  },
  {
    submitted_by_user_name: 'Elizabeth',
    submitted_by_user_image: require('src/Images/Users/6.jpg'),
  },
  {
    submitted_by_user_name: 'Hannah',
    submitted_by_user_image: require('src/Images/Users/7.jpg'),
  },
  {
    submitted_by_user_name: 'Amber',
    submitted_by_user_image: require('src/Images/Users/8.jpg'),
  },
  {
    submitted_by_user_name: 'Anna',
    submitted_by_user_image: require('src/Images/Users/9.jpg'),
  },
  {
    submitted_by_user_name: 'Olivia',
    submitted_by_user_image: require('src/Images/Users/10.jpg'),
  },
  {
    submitted_by_user_name: 'Maria',
    submitted_by_user_image: require('src/Images/Users/11.jpg'),
  },
  {
    submitted_by_user_name: 'Mary',
    submitted_by_user_image: require('src/Images/Users/12.jpg'),
  },
  {
    submitted_by_user_name: 'Leah',
    submitted_by_user_image: require('src/Images/Users/13.jpg'),
  },
  {
    submitted_by_user_name: 'Julia',
    submitted_by_user_image: require('src/Images/Users/14.jpg'),
  },
  {
    submitted_by_user_name: 'Maria',
    submitted_by_user_image: require('src/Images/Users/15.jpg'),
  },
  {
    submitted_by_user_name: 'Jessica',
    submitted_by_user_image: require('src/Images/Users/16.jpg'),
  },
  {
    submitted_by_user_name: 'Ashley',
    submitted_by_user_image: require('src/Images/Users/17.jpg'),
  },
  {
    submitted_by_user_name: 'Samantha',
    submitted_by_user_image: require('src/Images/Users/18.jpg'),
  },
  {
    submitted_by_user_name: 'Danielle',
    submitted_by_user_image: require('src/Images/Users/19.jpg'),
  },
  {
    submitted_by_user_name: 'Jennifer',
    submitted_by_user_image: require('src/Images/Users/20.jpg'),
  },
];

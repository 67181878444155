import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState} from 'react';
import {Platform, Text, TextStyle, View, ViewStyle} from 'react-native';
import * as Application from 'expo-application';
///////////////////////////
///////////// components
import {BodyText, FeedbackButton} from 'src/Modules/CommonView';
import {TouchableOpacity} from 'react-native';
import {apiLeaveQuestionReview} from 'src/Utils/apiCalls';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {
  useChallengeId,
  useGameDebugInfo,
  useLocationId,
} from 'src/Hooks/gameHooks';
import {getReduxState} from 'src/Utils/helpers';
import {useUserId} from 'src/Hooks/reduxHooks';

interface ChallengeFeedbackElementProps {
  isSmiley?: boolean;
  textStyle?: TextStyle;
  containerStyle?: ViewStyle;
  typeOverride?: string | false;
  promptOverride?: string | false;
  ces_modal_type?: string;
}

const ChallengeFeedbackElement: React.FC<ChallengeFeedbackElementProps> = ({
  isSmiley = false,
  textStyle = {},
  containerStyle = {},
  typeOverride = false,
  promptOverride = false,
  ces_modal_type,
}) => {
  const userId = useUserId();
  const groupId = useTypedSelector((state) => state.group?.info?.groupId);
  const huntId = useTypedSelector((state) => state.group?.info?.huntId);
  const routeId = useTypedSelector((state) => state.group?.info?.routeId);
  const isTimed = useTypedSelector((state) => state.group?.info?.isTimed);
  const hideChallengeFeedback = useTypedSelector(
    (state) => state.event?.info?.hideChallengeFeedback,
  );

  const locationId = useLocationId();
  const challengeId = useChallengeId();
  const gameDebugInfo = useGameDebugInfo();

  const [rated, setRated] = useState<boolean>(false);

  const handleFeedbackPress = async (liked: boolean) => {
    if (ces_modal_type) {
      setRated(true);
      return navigationRef.navigate(
        'CESModal',
        {
          title: promptOverride || null,
          subTitle: `Please let us know what you ${
            liked ? 'liked' : 'disliked'
          }!`,
          type: 'r_registration',
          initialRating: liked ? 5 : 1,
        },
        'ScavengerHunt_v2/Game/ChallengeFeedbackElement.tsx',
      );
    }
    try {
      setRated(true);
      if (!liked) {
        navigationRef.navigate(
          'ReportModal',
          {
            challengeId,
            locationId,
          },
          'ScavengerHunt_v2/Game/ChallengeFeedbackElement.tsx',
        );
      } else {
        const currentScreen = getReduxState(
          (state) => state?.current_screen?.currentScreen,
        );
        await apiLeaveQuestionReview({
          liked: true,
          questionId: challengeId?.replace?.('c_', ''),
          appChallengeId: challengeId?.replace('c_', ''),
          userId: userId || undefined,
          huntId: huntId || undefined,
          routeId: routeId || undefined,
          groupId,
          screen: currentScreen || '',
          app_version: `${Platform.OS} ${Application.nativeApplicationVersion}`,
          locationId: locationId?.replace?.('l_', ''),
          userFeedback: 'Positive',
        });
      }
    } catch (error: unknown) {
      console.log(error);
    }
  };

  console.log('LocationChallenge Render');

  console.log({isTimed});
  const type = typeOverride || (!!challengeId ? 'challenge' : 'location');

  if (hideChallengeFeedback) {
    return <View style={{marginBottom: 10}}></View>;
  }
  return (
    <View>
      {!rated && (
        <View style={{alignItems: 'center'}}>
          <BodyText
            text={
              !!promptOverride
                ? promptOverride
                : `Do you like this ${type}?` + gameDebugInfo
            }
            textStyle={{textAlign: 'center', marginTop: 20, ...textStyle}}
          />
          {!isSmiley && (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginVertical: 20,
                width: 210,
                ...containerStyle,
              }}>
              <FeedbackButton
                text="NO"
                handlePress={() => handleFeedbackPress(false)}
              />
              <FeedbackButton
                text="YES"
                color={constants?.color?.orange}
                handlePress={() => handleFeedbackPress(true)}
              />
            </View>
          )}
          {!!isSmiley && (
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginVertical: 5,
                marginBottom: 20,
                width: 210,
                ...containerStyle,
              }}>
              <FeedbackIcon
                text="☹️" // there is an emoji here
                handlePress={() => handleFeedbackPress(false)}
              />

              <FeedbackIcon
                text="😊"
                handlePress={() => handleFeedbackPress(true)}
              />
            </View>
          )}
        </View>
      )}
      {!!rated && (
        <View style={{alignItems: 'center'}}>
          <BodyText
            text="Thanks for your feedback!"
            textStyle={{textAlign: 'center', marginTop: 20}}
          />
        </View>
      )}
    </View>
  );
};

interface FeedbackIconProps {
  text: string;
  handlePress: () => void;
}

const FeedbackIcon: React.FC<FeedbackIconProps> = ({text, handlePress}) => {
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      onPress={handlePress}
      style={{
        alignItems: 'center',
        height: 40,
        justifyContent: 'center',
        width: 60,
      }}>
      <Text
        style={{
          color: constants?.color?.white,
          fontFamily: constants?.font.circular,
          fontSize: 28,
          textAlign: 'center',
        }}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};

export default ChallengeFeedbackElement;

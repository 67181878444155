// this reducer store the daily challenges
// and is used for logging their completions
// daily challenges are pulled from the api
// /api/v1/app/community/get_daily_challenges

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TChallenge} from 'src/types/TChallenge';

interface DailyChallengesState {
  challenges: TChallenge[];
}

const initialState: DailyChallengesState = {
  challenges: [],
};

const communityPhotoSlice = createSlice({
  name: 'dailyChallenges',
  initialState,
  reducers: {
    setDailyChallenges(state, action: PayloadAction<TChallenge[]>) {
      console.log('setDailyChallenges', action.payload);
      state.challenges = action.payload;
    },
    markDailyChallengeCompleted(
      state,
      action: PayloadAction<{
        challenge: TChallenge;
        photo_url: string;
        shareUUID: string;
      }>,
    ) {
      console.log(action.payload);
      state.challenges = state.challenges.map((challenge) => {
        if (
          challenge.scheduled_id &&
          challenge.scheduled_id === action.payload.challenge.scheduled_id
        ) {
          return {
            ...challenge,
            completed: true,
            photo_url: action.payload.photo_url,
            shareUUID: action.payload.shareUUID,
          };
        }
        return challenge;
      });
      console.log('markDailyChallengeCompleted', {
        challenges: state.challenges,
      });
    },
  },
});

export const {setDailyChallenges, markDailyChallengeCompleted} =
  communityPhotoSlice.actions;

export default communityPhotoSlice.reducer;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect, useState} from 'react';
import {View, Vibration, ViewStyle} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
///////////////////////////
///////////// components
import {ContinueButton, BodyText} from 'src/Modules/CommonView';

///////////////////////////
///////////// utils
import {markSelectionChallengeComplete} from 'src/Utils/huntFunctions_v2';

import {useChallenge} from 'src/Hooks/gameHooks';
import Slider from '@react-native-community/slider';

function random(seed = 0) {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

interface NumberGuesserProps {
  onComplete?: () => void;
  singleCompletion?: boolean;
  containerStyle?: ViewStyle;
}

interface SubmissionParams {
  challengeId: string;
  option: number;
  correct: boolean;
  singleCompletion?: boolean;
  overridePointsEarned?: number;
}

const NumberGuesser: React.FC<NumberGuesserProps> = ({
  onComplete,
  singleCompletion,
  containerStyle = {},
}) => {
  const userId = useTypedSelector((state) => state.user?.info?.userId);

  const challenge = useChallenge();
  const challengeType = challenge?.type || 'numberguesser';
  const correctAnswer = challenge?.correctAnswer || 0;

  let minGuess = Math.max(Math.round(Number(correctAnswer) / 10) * 10 - 20, 0);

  // self scoring users award points during their hunt
  // min is always 0 and we can set the maxPoints in the hunt dashboard
  if (challengeType === 'self_score') {
    minGuess = 0;
  }

  let maxGuess =
    Math.round(Number(correctAnswer) / 10) * 10 +
    20 +
    Math.round(random(Number(correctAnswer) * 123442) / 3) * 10;

  if (challengeType === 'self_score') {
    maxGuess = challenge?.maxPoints || 100;
  }

  const [guess, setGuess] = useState<number>(
    Math?.floor((maxGuess + minGuess) / 2),
  );

  const roundedGuess = Math.round(guess);

  useEffect(() => {
    setGuess(Math?.floor((maxGuess + minGuess) / 2));
  }, [correctAnswer]);

  const handleSubmit = () => {
    const challengeId = challenge?.challengeId || '';

    // self score challenges the users will set the score
    // whatever they input as their "guess" will be their score
    // otherwise they will get 0 if wrong or full points for correct for "true" number guesses

    let submissionParams: SubmissionParams = {
      challengeId,
      option: roundedGuess,
      correct:
        challengeType === 'self_score'
          ? true
          : roundedGuess === Number(correctAnswer),
      singleCompletion,
    };

    if (challengeType === 'self_score') {
      submissionParams = {
        ...submissionParams,
        overridePointsEarned: roundedGuess,
      };
    } else {
      submissionParams = {
        ...submissionParams,
        overridePointsEarned:
          roundedGuess === Number(correctAnswer)
            ? Number(challenge?.points)
            : 0,
      };
    }

    markSelectionChallengeComplete(submissionParams);

    // vibrate phone if the guess is incorrect
    if (roundedGuess !== Number(correctAnswer)) {
      Vibration.vibrate(400);
    }

    onComplete && onComplete?.();
  };

  const completions = challenge?.completions || {};

  const userHasCompleted = userId && completions[userId] ? true : false;

  const {width} = useWindowDimensionsSafe();

  return (
    <View
      style={{
        justifyContent: 'center',
        ...containerStyle,
      }}>
      {!userHasCompleted && (
        <View style={{alignItems: 'center'}}>
          <BodyText
            textStyle={{
              textAlign: 'center',

              fontSize: 28,
              lineHeight: 28,
            }}
            largeText
            text={String(roundedGuess)}
          />
          <Slider
            testID={'numberGuesser'}
            style={{width: width - 20}}
            value={guess}
            onValueChange={(newValue) => {
              console.log({newValue});
              setGuess(newValue);
            }}
            minimumValue={minGuess}
            maximumValue={maxGuess}
            tapToSeek={true}
          />
          <View>
            <ContinueButton
              text={'Submit'}
              containerStyle={{width: 220, marginVertical: 5, marginTop: 10}}
              background={constants?.color?.orange}
              handlePress={handleSubmit}
            />
          </View>
        </View>
      )}
      {!!userHasCompleted && !!userId && (
        <View style={{marginTop: 20}}>
          {!!guess && (
            <ContinueButton
              handlePress={() => {
                console.log('already done');
              }}
              text={completions[userId].option || 'Finished'}
              containerStyle={{flex: 1, marginVertical: 5}}
              background={constants?.color?.orange}
            />
          )}
        </View>
      )}
    </View>
  );
};

export default NumberGuesser;

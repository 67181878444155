import constants from 'src/constants';
import { TAchievements } from 'src/types/TAchievements';
export const achievementsData: Record<string, TAchievements> = {
  mvp: {
    name: 'MVP',
    level: '',
    icon: 'star',
    iconSet: 'FontAwesome',
    color: constants?.color.yellow,
    'How To Earn': 'Most points. ( only 1 player )',
    description:
      'Congratulations on winning the MVP award. You earned the most points, and all the bragging rights!',
  },
  treasure_hunter: {
    name: 'Treasure Hunter',
    level: '',
    icon: 'treasure-chest',
    iconSet: 'MaterialCommunityIcons',
    color: '#34495e',
    'How To Earn': 'Most treasure challenges completed',
    description:
      'You did it Treasure Hunter! You completed the most bonus challenges out of your group.',
  },
  navigator: {
    name: 'Navigator',
    level: '',
    icon: 'compass',
    iconSet: 'FontAwesome',
    color: '#27ae60',
    'How To Earn': 'logged most nav challenge check ins ( nav challenges )',
    description:
      'You successfully navigated your team to each correct location in a safe and timely manner! ',
  },
  team_leader: {
    name: 'Team Leader',
    level: '',
    icon: 'trophy',
    iconSet: 'FontAwesome',
    //"color": constants?.color.orange,
    color: '#e67e22',
    'How To Earn': 'most correct answers ( location, only 1 player )',
    description:
      "You're an inspiration! You answered the most location challenges correctly out of your whole team.",
  },
  best_effort: {
    name: 'Best Effort',
    level: '',
    icon: 'gift',
    iconSet: 'FontAwesome',
    color: '#8e44ad',
    'How To Earn': 'most wrong answers ( location, only 1 player )',
    description:
      "You may not have answered many questions correctly, but you showed up and that's what counts!",
  },
  absent: {
    name: 'Absent',
    level: '',
    icon: 'power-sleep',
    iconSet: 'MaterialCommunityIcons',
    color: constants?.color.gray3,
    'How To Earn': 'logged least answers ( location, only 1 player )',
    description:
      'Your strategy of never getting questions wrong by not answering them paid off. Congrats! ',
  },
  most_enthusiastic: {
    name: 'Most Enthusiastic',
    level: '',
    icon: 'coffee',
    iconSet: 'FontAwesome',
    color: '#2980b9',
    'How To Earn': 'logged most answers ( location, right and wrong )',
    description:
      'Right or wrong, you never missed an oppurtunity to answer a question.',
  },
  perfectionist: {
    name: 'Perfectionist',
    level: '',
    icon: 'fire',
    iconSet: 'FontAwesome',
    color: '#c0392b',
    'How To Earn':
      'no wrong answers && answered more than 2/3 of location challenges',
    description:
      "If you looked up PERFECTION in the dictionary you'd see your picture. You never answered a single question wrong!",
  },
  completionist: {
    name: "Teacher's Pet",
    level: '',
    icon: 'magic',
    iconSet: 'FontAwesome',
    color: '#9b59b6',
    'How To Earn': 'complete all player challenges',
    description:
      'Were you trying to impress us? Because you did! You completed all of your player challenges.',
  },
  epic_streak_bronze: {
    name: 'Epic Streak',
    level: 'Bronze',
    icon: 'flash',
    iconSet: 'FontAwesome',
    color: constants?.color.bronze,
    'How To Earn': '3 correct in a row ( location challenges )',
    description:
      'C-C-C-Combo! You answered 3 or more questions correctly in a row.',
  },
  epic_streak_silver: {
    name: 'Epic Streak',
    level: 'Silver',
    icon: 'flash',
    iconSet: 'FontAwesome',
    color: constants?.color.silver,
    'How To Earn': '4 correct in a row',
    description:
      'C-C-C-C-Combo! You answered 4 or more questions correctly in a row.',
  },
  epic_streak_gold: {
    name: 'Epic Streak',
    level: 'Gold',
    icon: 'flash',
    iconSet: 'FontAwesome',
    color: constants?.color.gold,
    'How To Earn': '7 correct in a row',
    description:
      'C-C-C-C-C-C-C-Combo! You answered 7 or more questions correctly in a row.',
  },
  trivia_bronze: {
    name: 'Smarty Pants',
    level: 'Bronze',
    icon: 'trophy',
    iconSet: 'FontAwesome',
    color: constants?.color.bronze,
    'How To Earn': '100+ trivia points ( player)',
    description:
      "Who's got 2 thumbs and earned 100 or more points answering trivia questions? You!",
  },
  trivia_silver: {
    name: 'Smarty Pants',
    level: 'Silver',
    icon: 'trophy',
    iconSet: 'FontAwesome',
    color: constants?.color.silver,
    'How To Earn': '200+ trivia points',
    description:
      "Who's got 2 thumbs and earned 200 or more points answering trivia questions? You!",
  },
  trivia_gold: {
    name: 'Smarty Pants',
    level: 'Gold',
    icon: 'trophy',
    iconSet: 'FontAwesome',
    color: constants?.color.gold,
    'How To Earn': '350 + trivia points',
    description:
      "Who's got 2 thumbs and earned 350 or more points answering trivia questions? You!",
  },
  photo_bronze: {
    name: 'Photo Bug',
    level: 'Bronze',
    icon: 'camera',
    iconSet: 'FontAwesome',
    color: constants?.color.bronze,
    'How To Earn': '3+ photos ( player + treasure + location )',
    description:
      'Your Instagram fans are going to love the 3 or more photos you took of yourself and your team on your hunt #Famous',
  },
  photo_silver: {
    name: 'Photo Bug',
    level: 'Silver',
    icon: 'camera',
    iconSet: 'FontAwesome',
    color: constants?.color.silver,
    'How To Earn': '5+ photos',
    description:
      'Your Instagram fans are going to love the 5 or more photos you took of yourself and your team on your hunt #Famous',
  },
  photo_gold: {
    name: 'Photo Bug',
    level: 'Gold',
    icon: 'camera',
    iconSet: 'FontAwesome',
    color: constants?.color.gold,
    'How To Earn': '8+ photos',
    description:
      'Your Instagram fans are going to love the 8 or more photos you took of yourself and your team on your hunt #Famous',
  },
  team_player_bronze: {
    name: 'Team Player',
    level: 'Bronze',
    icon: 'ios-american-football',
    iconSet: 'Ionicons',
    color: constants?.color.bronze,
    'How To Earn': '800+ player points',
    description:
      "You came, you saw, you scored! You earned 800 or more points towards your team's score.",
  },
  team_player_silver: {
    name: 'Team Player',
    level: 'Silver',
    icon: 'ios-american-football',
    iconSet: 'Ionicons',
    color: constants?.color.silver,
    'How To Earn': '1400+ player points',
    description:
      "You came, you saw, you scored! You earned 1,400 or more points towards your team's score.",
  },
  team_player_gold: {
    name: 'Team Player',
    level: 'Gold',
    icon: 'ios-american-football',
    iconSet: 'Ionicons',
    color: constants?.color.gold,
    'How To Earn': '2000+ player points',
    description:
      "You came, you saw, you scored! You earned 2,000 or more points towards your team's score.",
  },
  trigger_finger_bronze: {
    name: 'Trigger Finger',
    level: 'Bronze',
    icon: 'gauge-full',
    iconSet: 'MaterialCommunityIcons',
    color: constants?.color.bronze,
    'How To Earn': 'First to answer 4 (location challenges)',
    description:
      'Did you actually read the question? You were the first on your team to answer 3 or more location challenges.',
  },
  trigger_finger_silver: {
    name: 'Trigger Finger',
    level: 'Silver',
    icon: 'gauge-full',
    iconSet: 'MaterialCommunityIcons',
    color: constants?.color.silver,
    'How To Earn': 'First to answer 7',
    description:
      'Did you actually read the question? You were the first on your team to answer 5 or more location challenges.',
  },
  trigger_finger_gold: {
    name: 'Trigger Finger',
    level: 'Gold',
    icon: 'gauge-full',
    iconSet: 'MaterialCommunityIcons',
    color: constants?.color.gold,
    'How To Earn': 'First to answer 10',
    description:
      'Did you actually read the question? You were the first on your team to answer 7 or more location challenges.',
  },
  shoe_photographer: {
    name: 'Shoe Photographer',
    level: '',
    icon: 'shoe-prints',
    iconSet: 'FontAwesome5',
    color: constants?.color.gold,
    'How To Earn': 'took multiple photos of their shoes',
    description: '',
  },
  most_selfies: {
    name: '',
    level: '',
    icon: '',
    iconSet: '',
    color: '',
    'How To Earn': 'took most selfies',
    description: '',
  },
  facebook: {
    name: 'Social Review',
    level: 'Facebook',
    icon: 'facebook',
    iconSet: 'FontAwesome5',
    color: 'blue',
    'How To Earn': 'review us on facebook',
    description: '',
  },
  google: {
    name: 'Social Review',
    level: 'Google',
    icon: 'google',
    iconSet: 'FontAwesome5',
    color: 'red',
    'How To Earn': 'review us on google',
    description: '',
  },
};

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect, useState} from 'react';
import {AppState, View, Alert, Platform, AppStateStatus} from 'react-native';
import * as Updates from 'expo-updates';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {setReduxAppStateVisible} from 'src/Redux/reducers/current_screen.reducer';

// Actively checks for changes
// Checks for a change every time the app state changes
// Actively changes every time the home screen is viewed
// Actively checks every 5 page loads
// Also checks app start

// Controls updates
const ExpoUpdateController: React.FC = () => {
  // Getting data
  const {width} = useWindowDimensionsSafe();

  const [expoUpdateFound, setExpoUpdateFound] = useState<number>(0);
  // Also recheck every 5 screens

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  // Intro pages
  // Pages where the app should not update
  const isOnLoadingScreens = useTypedSelector((state) =>
    [
      'AppLoadingPage',
      'AppIntroSwiper',
      'LoginPage',
      'ModalStack',
      'LoginModalTextForm',
      'LoginAdditionalInformationForm',
      'LoginAdditionalInfoFullScreenPage',
    ].includes(state.current_screen?.currentScreen),
  );

  console.log_on_production?.('ExpoUpdateController', {
    isOnLoadingScreens,
    expoUpdateFound,
  });

  const roundedCurrentScreenChanges = useTypedSelector(
    (state) => state.current_screen?.roundedCurrentScreenChanges,
  );

  console.log({currentState: AppState.currentState});

  const [appStateVisible, setAppStateVisible] = useState<AppStateStatus>(
    AppState.currentState || '',
  );

  useEffect(() => {
    dispatchAction(setReduxAppStateVisible(appStateVisible));
  }, [appStateVisible]);

  useEffect(() => {
    const type = Platform?.OS == 'android' ? 'focus' : 'change';
    const listenerNumber = 'AppStateListener' + Math?.random?.() + ':';
    const handler = (nextAppState: AppStateStatus) => {
      console.log('AppStateListenerUpdate', {nextAppState});
      const currentScreen = getReduxState(
        (state) => state?.current_screen?.currentScreen,
      );
      if (currentScreen == 'PhotoVideoCamera') {
        return;
      }
      // if (
      //   appStateRef.current &&
      //   appStateRef.current?.match &&
      //   appStateRef.current?.match?.(/inactive|background/) &&
      //   nextAppState === 'active'
      // ) {
      //   console.log_on_production?.('App has come to the foreground!');
      // }

      console.log_on_production?.({nextAppState, currentScreen});

      setAppStateVisible((prevState) => {
        if (
          prevState?.match?.(/inactive|background/) &&
          nextAppState === 'active'
        ) {
          console.log_on_production?.('App has come to the foreground!');
        }
        return nextAppState;
      });

      console.log_on_production?.('AppState has changed');
    };
    console.log_on_production?.(listenerNumber + ' Added');
    const appStateSubscription = AppState.addEventListener(type, handler);

    return () => {
      try {
        appStateSubscription.remove();
        console.log_on_production?.(listenerNumber + ' Removed');
      } catch (appStateRemoveError) {
        // Clean up error
        console.error({appStateRemoveError});
      }
    };
  }, []);

  useEffect(() => {
    // Don't update app during intro swiper
    //

    const isOnLandingPage = getReduxState((state) =>
      ['LandingPage'].includes(state.current_screen?.currentScreen),
    );

    if (isOnLoadingScreens) {
      console.log_on_production?.(
        'ExpoUpdateCheck Blocked: in isOnLoadingScreens',
        {expoUpdateFound},
      );
    } else if (playTestState) {
      console.log_on_production?.('ExpoUpdateCheck Blocked: in playTestState', {
        expoUpdateFound,
      });
      //
      // If navigated to the landing page just refresh (silent update)
      //
    } else if (expoUpdateFound && isOnLandingPage) {
      try {
        console.log_on_production?.('----------------');
        console.log_on_production?.('----------------');
        console.log_on_production?.('----------------');
        console.log_on_production?.('----------------');
        console.log_on_production?.('reloading app 1 ');
        console.log_on_production?.('----------------');
        console.log_on_production?.('----------------');
        console.log_on_production?.('----------------');
        console.log_on_production?.('----------------');
        console.log_on_production?.('----------------');
        mmkvStorage.set('previousVisit', 'true');
        Updates?.reloadAsync?.()
          .then(() => {
            console.log('update restart done!');
          })
          .catch((updateRestartError) => {
            console.log({updateRestartError});
          });
      } catch (expoUpdateError3) {
        console.log_on_production?.({expoUpdateError3});
      }

      //
      // Else tell them we are refreshing
      //
    } else if (expoUpdateFound) {
      Alert.alert(
        'Instant App Update Found',
        'An instant app update was found. The app needs to refresh to work properly.',
        [
          {
            text: 'Apply Update',
            onPress: () => {
              console.log_on_production?.('reloading app 2');
              try {
                mmkvStorage.set('previousVisit', 'true');
                console.log_on_production?.('reloading app');
                console.log_on_production?.('----------------');
                console.log_on_production?.('----------------');
                console.log_on_production?.('----------------');
                console.log_on_production?.('----------------');
                console.log_on_production?.('reloading app 2 ');
                console.log_on_production?.('----------------');
                console.log_on_production?.('----------------');
                console.log_on_production?.('----------------');
                console.log_on_production?.('----------------');
                console.log_on_production?.('----------------');
                Updates?.reloadAsync?.()
                  .then(() => {
                    console.log('update restart done!');
                  })
                  .catch((updateRestartError) => {
                    console.log({updateRestartError});
                  });
              } catch (expoUpdateError3) {
                console.log_on_production?.({expoUpdateError3});
              }
            },
          },
        ],
      );
    } else {
      console.log_on_production?.('ExpoUpdateCheck: no update', {
        expoUpdateFound,
      }); // No update
    }
  }, [
    expoUpdateFound,
    playTestState,
    isOnLoadingScreens,
    roundedCurrentScreenChanges,
  ]);

  useEffect(() => {
    console.log_on_production?.('Should check for expo update', {
      updateChannel: Updates.channel,
      currentUpdateId: Updates.updateId,
      appStateVisible,
    });

    if (!__DEV__) {
      try {
        console.log_on_production?.('Checking for expo updates');

        Updates.checkForUpdateAsync()
          .then((update) => {
            console.log_on_production?.('checkForUpdateAsync Result', {update});
            if (update.isAvailable) {
              console.log_on_production?.('Update found');
              Updates?.fetchUpdateAsync?.()
                .then((updateData) => {
                  console.log_on_production?.({updateData});

                  setExpoUpdateFound(1);
                })
                .catch((expoUpdateError2) =>
                  console.log_on_production?.({expoUpdateError2}),
                );
            } else {
              console.log_on_production?.('No update found');
            }
          })
          //
          .catch((expoUpdateError) => {
            console.log_on_production?.({expoUpdateError});
          });
      } catch (e) {
        // Handle or log error
      }
    }
  }, [
    appStateVisible,
    playTestState,
    isOnLoadingScreens,
    roundedCurrentScreenChanges, // Recheck every 10 screen changes
  ]);

  if (expoUpdateFound) {
    return (
      <View
        style={{
          height: 10,
          backgroundColor: constants?.color.gray3,
          width,
        }}></View>
    );
  }

  return <></>;
};

export default ExpoUpdateController;

import constants from 'src/constants';
import 'react-native-get-random-values';
import React from 'react';

import {Alert, Platform, Text, TouchableWithoutFeedback} from 'react-native';
import {apiTrackUserTransaction, apiUpdateUserBadges} from 'src/Utils/apiCalls';

import {defaultThemes} from 'src/Data/DefaultThemes';
import {createShareLink} from 'src/Utils/shareHelper';

import moment from 'moment';

import {randomPhotoColor} from 'src/Utils/helpers';

import {store} from 'src/Redux/ReduxStore';
import {parseHTML} from 'src/Utils/dataHelper';

import {navigationRef} from 'src/Nav/navigationHelpers';
import {analytics} from 'src/Utils/db';
import {
  getDataAtPath,
  getFirebaseUserInfoById,
  logForCrashlytics,
  removeDataAtPath,
  setDataAtPath,
  updateDataAtPath,
} from 'src/Utils/fireUtils';
import {setLoadingIndicatorOpen} from 'src/Redux/reducers/current_screen.reducer';
import {
  setEventId,
  setEventPhotos,
  updateEventGroups,
  updateEventInfo,
} from 'src/Redux/reducers/event.reducer';
import {resetChallengeData} from 'src/Redux/reducers/game_v2.reducer';
import {getExplorerPass} from 'src/Utils/explorerPassHelper';
import {
  logAttributesForCrashlytics,
  logCustomAnalyticsEvent,
} from 'src/Utils/fireUtils';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {firebaseSubscribeMessaging} from 'src/Utils/loginFunctions';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {addDoneHunt} from 'src/Utils/huntsDoneHelpers';
import {v4 as uuidv4} from 'uuid';
import {THunt} from 'src/types/THunt';
import {TGroupInfo, TGroupPlayer} from 'src/types/TGroupInfo';
import {TChallenge} from 'src/types/TChallenge';

import {TLeaderBoardItem} from 'src/types/TLeaderBoardItem';
import {convertToSimpleGroup} from 'src/types/TSimpleGroup';

export function capitalizeWords(word: string): string {
  let previousLetterSpace = true;
  return [...word]
    .map((letter) => {
      if (previousLetterSpace) {
        previousLetterSpace = false;
        return letter.toUpperCase();
      } else {
        if (letter === ' ') {
          previousLetterSpace = true;
        }
        return letter.toLowerCase();
      }
    })
    .join('');
}

const defaultTeamBannerUrl =
  'https://www.letsroam.com/assets/images/letsroam/team_building/epic.jpg';

// consuming a scavenger hunt ticket
// returns success or failure
export const consumeTicket = async (selectedThemeName: string) => {
  const userId = getReduxState((state) => state?.user?.userId);
  if (!userId) {
    console.error('Not logged in but made a purchase? consumeTicket');
    return Alert.alert('You must log in to consume a ticket');
  }

  const ticketPath = `users/${userId}/tickets/${selectedThemeName}`;
  const rawData = await getDataAtPath(
    `users/${userId}/tickets/${selectedThemeName}`,
  );
  const currentTickets: number =
    typeof rawData === 'number' ? rawData : Number(rawData) || 0;

  apiTrackUserTransaction({
    type: 'ticket_use',
    user_id: userId,
    usage_user_id: userId,
    tickets: -1,
    theme: selectedThemeName,
  });

  console.log({ticketPath, selectedThemeName, currentTickets});
  const newTicketsNum = Math?.max(currentTickets - 1, 0) || 0;

  updateDataAtPath(
    `users/${userId}/tickets/${selectedThemeName}`,
    newTicketsNum,
  );
};

export const redeemCoinsForTicket = async (
  coin_price: number,
  selectedThemeName: string,
) => {
  const userId = getReduxState((state) => state?.user?.userId);

  if (!userId) {
    console.error('Not logged in but made a purchase? consumeTicket');
    return Alert.alert('You must log in to consume a ticket');
  }

  const currentTickets = await getDataAtPath(
    `users/${userId}/tickets/${selectedThemeName}`,
    'number',
  );

  const currentCoins = await getDataAtPath(`users/${userId}/coins`, 'number');

  apiTrackUserTransaction({
    type: 'coins_use',
    user_id: userId,
    usage_user_id: userId,
    tickets: 1,
    coins: coin_price,
    theme: selectedThemeName,
  });

  console.log({selectedThemeName, currentTickets});
  const newTicketsNum = Math?.max(currentTickets + 1, 0) || 0;

  updateDataAtPath(
    `users/${userId}/tickets/${selectedThemeName}`,
    newTicketsNum,
  );

  const newCoins = Math?.max(currentCoins - coin_price, 0) || 0;

  updateDataAtPath(`users/${userId}/coins`, newCoins);
};

const parseTicketOptionName = (name: string = '') => {
  return `Create ${capitalizeWords(name)} Themed Hunt`;
};

export const askToCreateTeam = async (currentHunt: THunt) => {
  const userId = getReduxState((state) => state?.user?.userId);

  if (!userId) {
    console.error('Not logged in but made a purchase? consumeTicket');
    return Alert.alert('Please log in');
  }

  const user = await getFirebaseUserInfoById(userId);

  const tickets = user?.tickets;
  const pass = user?.pass || null;

  const hasExplorerPass = !!pass;
  let themeOptions = [];
  let defaultTicketOption = 'Standard';

  // if logged in creating group
  if (hasExplorerPass) {
    themeOptions = [
      {value: 'Standard', key: 1, label: parseTicketOptionName('Standard')},
      {
        value: 'Date Night',
        key: 1,
        label: parseTicketOptionName('Date'),
      },
    ];
  } else if (typeof tickets == 'object') {
    console.log('determining themeOptions');

    for (const key in tickets) {
      if (key && tickets && !!tickets[key]) {
        defaultTicketOption = capitalizeWords(key);
        themeOptions.push({
          value: key,
          key,
          label: parseTicketOptionName(key),
        });
      }
    }
  } else {
    console.log(tickets);
    console.log('themeOptions: NO TICKETS');
    return Alert.alert('Please buy tickets');
  }

  const barHunt = currentHunt?.hunt_type?.includes?.('bar');

  const ticketMessage = hasExplorerPass
    ? ''
    : 'Creating a group will use 1 ticket.';

  const themeMessage =
    themeOptions?.length > 1
      ? 'Additionally, please select a theme for your scavenger hunt.'
      : '';

  let warningMessage = `What do you want to name your group? ${themeMessage} ${ticketMessage}`;
  if (barHunt) {
    warningMessage += `\n\nWhile no drinking is required on this barhunt, you will be asked to enter bars, many of which require you be of legal drinking age. \n\nPlease Confirm that you are of legal drinking age below before you play. \n\nConsider playing our regular scavenger hunts instead, and do not drive between locations.`;
  }

  console.log('askToCreateTeam', {
    user,
    themeOptions,
    tickets,
    hasExplorerPass,
  });

  return navigationRef.navigate(
    'ConfirmModal',
    {
      handleConfirm: async (teamName, selectedTheme) => {
        const theme =
          themeOptions?.length > 1 ? selectedTheme : defaultTicketOption;

        console.log('handleConfirm LandingPageScavengerHuntInfoModal', {
          teamName,
          theme,
        });

        const themeToUse = selectedTheme || theme;

        if (!themeToUse) {
          return console.error('no selected theme');
        }

        if (!teamName) {
          return Alert.alert('Please enter a team name');
        }

        if (!hasExplorerPass) {
          await consumeTicket(themeToUse);
        }

        navigationRef.closeModal('ConfirmModal', 'askToCreateTeam');

        // eslint-disable-next-line no-use-before-define
        await createHunt(currentHunt, themeToUse, true, teamName);
      },
      titleText: 'Create your team',
      dropDownOptions: themeOptions?.length > 1 ? themeOptions : null,
      titleBodyText: warningMessage,
      confirmText: `Create Group And Use Ticket`,
      showPrompt: true,
      maxLength: 40,
      placeholder: 'Your Team Name',
      cancelText: `Never mind`,
      initialDropdownValue: 'Standard',
    },
    'huntFunctions 213424',
  );
};

/////////////////////////////////
/////////// create && join hunt
export const createHunt = async (
  selectedHunt: THunt,
  themeName: string,
  joinGroupOnCreate: boolean = false,
  teamName: string,
) => {
  const os = Platform.OS;
  logForCrashlytics('createHunt fired in huntFunctions');
  logForCrashlytics(
    'createHunt fired 2',
    themeName + JSON.stringify(selectedHunt),
  );

  const email = getReduxState((state) => state?.user?.info?.email);
  const customPhotoUrl = getReduxState(
    (state) => state?.user?.info?.customPhotoUrl,
  );
  const photoUrl = getReduxState((state) => state?.user?.info?.photoUrl);
  const photoColor = getReduxState((state) => state?.user?.info?.photoColor);
  const userId = getReduxState((state) => state?.user?.info?.userId);
  const firstName = getReduxState((state) => state?.user?.info?.firstName);
  const lastName = getReduxState((state) => state?.user?.info?.lastName);

  if (!userId) {
    Alert.alert('You must log in to create a hunt');
    return navigationRef.navigate('LoginModal', {}, 'Utils/huntFunctions.tsx');
  }

  const groupId = uuidv4().substring(0, 7).toLowerCase?.();

  const playerObject: Record<string, TGroupPlayer> = {};
  playerObject[userId] = {
    userId: userId,
    parentUserId: userId,
    payingUserId: userId,
    parentEmail: email || null,
    photoUrl: customPhotoUrl || photoUrl || '',
    photoColor: photoColor || '#e87722',
    firstName: firstName || 'User',
    OS: os,
    groupId: groupId,
    timeAdded: Date.now(),
  };

  if (selectedHunt?.isTestHunt) {
    playerObject[userId]['questionType'] = 'mixed';
    playerObject[userId]['character'] = 'explorer';
  }

  const description = (selectedHunt.long_description || '') as string;

  //// flag for new hunt
  // const hunt_v2 = true

  console.log('createHunt about to check for explorer pass');

  const pass = getExplorerPass();

  let createdByName = 'User';
  if (
    firstName &&
    !firstName?.toLowerCase().includes('user') &&
    lastName &&
    !firstName?.toLowerCase().includes('user')
  ) {
    createdByName = `${firstName} ${lastName.substring(0, 1)}`;
  }

  console.log('createHunt about to create group object', {
    selectedHunt,
    themeName,
    teamName,
  });

  const groupObject: TGroupInfo = {
    groupId,
    hunt_v2: true,
    hunt_v3: true,
    hunt_v4: true,
    classic_hunt: selectedHunt?.classic_hunt || null,
    indoor_hunt: selectedHunt?.hunt_type == 'indoorHunt',
    joinCode: groupId || '',
    prePaidJoinCode: null,
    teamName: teamName || selectedHunt?.teamName || `Your Team's Name`,
    isBarHunt: selectedHunt?.hunt_type === 'barHunt',
    huntType: selectedHunt?.hunt_type || 'scavaHunt',
    prePaidPlayers: null,
    eventDate: null,
    isFree: Number(selectedHunt?.is_free) === 1 || null,
    passId: pass ? pass?.passCode || null : null,
    passPlayers: pass ? pass?.passPlayers || null : null,
    huntId: selectedHunt?.hunt_id || null,
    huntStarted: selectedHunt?.huntStarted || false, //used to auto start hunt
    routeId: selectedHunt?.route_id || null, /// update this to handle route ids instead of hunt ids
    routeIdOverride: selectedHunt?.routeIdOverride || null, /// for connected hunts
    theme: capitalizeWords(themeName || 'Standard'),
    city: selectedHunt?.city || null,
    lat: Number(selectedHunt?.lat || 0),
    long: Number(selectedHunt?.long || 0),
    huntPhoto: selectedHunt?.huntMediumPhotoURL || defaultTeamBannerUrl || null, //can be edited for corporate groups
    description: parseHTML(description || 'An awesome scavenger hunt.'),
    completionData: null,
    huntVersion: selectedHunt?.hunt_version || '1.0',
    discoverable: true,
    players: {},
    createdBy: userId || null,
    createdByName,
    huntName: selectedHunt?.branding?.eventName || selectedHunt?.name || '',
    huntCity: selectedHunt?.city || '',
    dateTimeEventCreated: Date.now(),
    dateEventCreated: moment().format('YYYY-MM-DD'),
    startingLocation: selectedHunt?.starting_location || '',
    eventId: selectedHunt?.eventId || null,
    isCoOp: true,
    isTimed: true,
    isSocial: true,
    eventStartTime: selectedHunt?.event_start_time || null,
    eventEndTime: selectedHunt?.event_end_time || null,
    huntLength: selectedHunt?.distance_miles || '',
  };

  logCustomAnalyticsEvent(
    selectedHunt?.eventId
      ? 'hunt_created_corporate'
      : 'hunt_created_non_corporate',
    groupObject as TJSONValue,
  );

  // dispatchAction(setCurrentGroupId(groupObject.groupId));

  console.log('setCurrentGroupId fired in createHunt');

  const updatedUserCurrentHuntInfo = {
    huntId: selectedHunt?.hunt_id || null,
    dateEventCreated: moment().format('YYYY-MM-DD'),
    dateEventLastJoined: moment().format('YYYY-MM-DD'),
    huntName: selectedHunt?.name || null,
    huntCity: selectedHunt?.city || '',
    huntType: selectedHunt?.hunt_type || '',
    groupId: groupId,
    eventId: selectedHunt?.eventId || null,
  };
  console.log({groupObject, updatedUserCurrentHuntInfo});

  updateDataAtPath(
    `users/${userId}/currentHuntInfo`,
    updatedUserCurrentHuntInfo,
  );

  console.log('createHunt updated currentHuntInfo', {
    updatedUserCurrentHuntInfo,
  });

  console.log({path: `scavengerhunt/groups/${groupId}`, groupObject});
  setDataAtPath(`scavengerhunt/groups/${groupId}`, groupObject);
  updateDataAtPath(`users/${userId}/scavengerHuntHistory/${groupId}`, true);

  if (joinGroupOnCreate) {
    // eslint-disable-next-line no-use-before-define
    joinHunt(groupId, userId);
  }

  // this is just for speed
  // but for event we are adding an initial group for syncing
  if (selectedHunt?.eventId) {
    const simpleGroup = convertToSimpleGroup(groupObject);
    setDataAtPath(
      `event/${selectedHunt?.eventId}/simpleGroups/${groupId}`,
      simpleGroup,
    );
  }

  return groupObject;
};

export const joinHunt = async (
  groupId: string,
  payingUserId = getReduxState((state) => state?.user?.info?.userId),
  loadingReason = 'Joining Hunt',
) => {
  console.log('joinHunt fired');

  AsyncStorage.setItem('lastJoinedHuntTime', Date.now().toString());

  dispatchAction(setLoadingIndicatorOpen(loadingReason));
  dispatchAction(resetChallengeData());

  logForCrashlytics('joinHunt fired in huntFunctions');
  const state = store?.getState?.() || {};
  const {themeInfo = defaultThemes} = state;
  const {themes = {}} = themeInfo;
  const userId = getReduxState((state) => state?.user?.info?.userId);
  const email = getReduxState((state) => state?.user?.info?.email);
  const customPhotoUrl = getReduxState(
    (state) => state?.user?.info?.customPhotoUrl,
  );
  const photoUrl = getReduxState((state) => state?.user?.info?.photoUrl);
  const photoColor = getReduxState((state) => state?.user?.info?.photoColor);
  const firstName = getReduxState((state) => state?.user?.info?.firstName);

  if (!userId) {
    return navigationRef.navigate(
      'LoginModal',
      {
        message: 'You must log in to join a hunt',
      },
      'huntFunctions 1234553',
    );
  }

  const currentGroupData = (await getDataAtPath(
    `scavengerhunt/groups/${groupId}`,
  )) as TGroupInfo | null;

  // this is just for speed
  // but for event we are adding an initial group for syncing
  if (currentGroupData?.eventId) {
    const simpleGroup = convertToSimpleGroup(currentGroupData);
    setDataAtPath(
      `event/${currentGroupData?.eventId}/simpleGroups/${groupId}`,
      simpleGroup,
    );
  }

  if (!currentGroupData) {
    console.error('No group data found for group id', groupId);
    return navigationRef.simpleAlert('Could not join group');
  }

  const currentGroupPlayerData = currentGroupData?.players || {};

  if (!themes[currentGroupData?.theme]) {
    console.log({groupId, currentGroupData});
    Alert.alert(
      `Error joining hunt! If this error persists please contact support on this matter. error_id: no_theme`,
      currentGroupData?.theme,
    );
    return false;
  }

  // logging when the hunt was created for the ContinueHunt card
  // last joined logic
  const updatedUserCurrentHuntInfo = {
    huntId: currentGroupData?.huntId || null,
    dateEventCreated: currentGroupData?.dateEventCreated || null,
    dateEventLastJoined: moment().format('YYYY-MM-DD'),
    huntName: currentGroupData?.huntName || null,
    huntCity: currentGroupData?.huntCity || null,
    huntType: currentGroupData?.huntType || null,
    groupId: currentGroupData?.groupId || null,
    eventId: currentGroupData?.eventId || null,
  };

  // no event data leave group
  if (!currentGroupData?.eventId) {
    dispatchAction(setEventId(null));
    dispatchAction(setEventPhotos({}));
    dispatchAction(updateEventGroups([]));
    dispatchAction(updateEventInfo(null));
  }

  if (currentGroupData?.huntId) {
    addDoneHunt(currentGroupData?.huntId, 365);
  }

  logCustomAnalyticsEvent(
    currentGroupData?.eventId
      ? 'hunt_joined_corporate'
      : 'hunt_joined_non_corporate',
    updatedUserCurrentHuntInfo,
  );

  console.log({updatedUserCurrentHuntInfo});
  updateDataAtPath(
    `users/${userId}/currentHuntInfo`,
    updatedUserCurrentHuntInfo,
  );

  dispatchAction(setEventId(currentGroupData?.eventId || null));

  // setting key info
  if (updatedUserCurrentHuntInfo?.groupId && Platform.OS != 'web') {
    try {
      updatedUserCurrentHuntInfo?.groupId &&
        logAttributesForCrashlytics?.(
          'currentGroup',
          `${updatedUserCurrentHuntInfo?.groupId}`,
        );
      updatedUserCurrentHuntInfo?.eventId &&
        logAttributesForCrashlytics?.(
          'currentEvent',
          `${updatedUserCurrentHuntInfo?.eventId}`,
        );
      updatedUserCurrentHuntInfo?.huntId &&
        logAttributesForCrashlytics?.(
          'currentHuntId',
          `${updatedUserCurrentHuntInfo?.huntId}`,
        );
      updatedUserCurrentHuntInfo?.huntName &&
        logAttributesForCrashlytics?.(
          'currentHuntName',
          `${updatedUserCurrentHuntInfo?.huntName}`,
        );
      currentGroupData?.routeId &&
        logAttributesForCrashlytics?.(
          'currentRouteId',
          `${currentGroupData?.routeId || ''}`,
        );
      currentGroupData?.theme &&
        logAttributesForCrashlytics?.(
          'currentTheme',
          `${currentGroupData?.theme}`,
        );

      updatedUserCurrentHuntInfo?.groupId &&
        firebaseSubscribeMessaging(updatedUserCurrentHuntInfo?.groupId);
      updatedUserCurrentHuntInfo?.eventId &&
        firebaseSubscribeMessaging(updatedUserCurrentHuntInfo?.eventId);
    } catch (err) {
      console.log(err);
    }
  }

  if (
    !currentGroupPlayerData ||
    (currentGroupPlayerData && !currentGroupPlayerData[userId]) ||
    !currentGroupPlayerData[userId]
  ) {
    const playerObject: TGroupPlayer = {
      parentUserId: userId,
      payingUserId: payingUserId || userId,
      parentEmail: email || null,
      OS: Platform.OS || null,
      userId: userId,
      photoUrl: customPhotoUrl || photoUrl || null,
      photoColor: photoColor || '#e87722',
      firstName: firstName || 'User',
      groupId: groupId,
      timeAdded: Date.now(),
    };

    updateDataAtPath(
      `scavengerhunt/groups/${groupId}/players/${userId}`,
      playerObject,
    );
    updateDataAtPath(
      `users/${userId}/scavengerHuntHistory/${groupId}`,
      moment().format('YYYY-MM-DD'),
    );

    if (currentGroupData && currentGroupData?.eventId) {
      updateDataAtPath(
        `users/${userId}/eventHistory/${currentGroupData?.eventId}`,
        moment().format('YYYY-MM-DD'),
      );
    }
    // updating last joined
    setDataAtPath(
      `scavengerhunt/groups/${groupId}/dateEventLastJoined`,
      moment().format('YYYY-MM-DD'),
    );
  }

  console.log('about to update updatedUserCurrentHuntInfo');

  console.log('user updated');

  dispatchAction(setLoadingIndicatorOpen(false));
  navigationRef.navigate('MainGameStack', null, 'huntFunctions joinHunt');

  return console.log('join hunt done');
};

export const addExtraHuntTime = async () => {
  const groupId = getReduxState((state) => state?.group?.info?.groupId);

  const timerMinutes = await getDataAtPath(
    `scavengerhunt/completions/${groupId}/location_v2/timerLimitMinutes`,
    'number',
  );

  let newTimerMinutes = 30;

  if (timerMinutes) {
    newTimerMinutes += timerMinutes;
  }

  if (!!groupId) {
    updateDataAtPath(`scavengerhunt/groups/${groupId}/huntFinished`, false);
    updateDataAtPath(
      `scavengerhunt/completions/${groupId}/location_v2/timerLimitMinutes`,
      newTimerMinutes,
    );

    updateDataAtPath(
      `scavengerhunt/groups/${groupId}/huntTimeExtendedCount`,
      1,
    );

    removeDataAtPath(
      `scavengerhunt/completions/${groupId}/location_v2/timerFinish`,
    );
  }
};

export const addChildUser = (
  firstName: string,
  parentUserId: string,
  parentUserEmail: string | null,
  groupId: string,
) => {
  logForCrashlytics('addChildUser fired in huntFunctions');

  apiUpdateUserBadges();
  const userId = uuidv4().substring(0, 5);

  const params: TGroupPlayer = {
    userId,
    firstName,
    photoColor: randomPhotoColor(),
    parentUserId,
    parentEmail: parentUserEmail,
    payingUserId: parentUserId,
    points: 0,
    timeAdded: Date.now(),
    groupId: groupId,
    OS: Platform.OS,
  };

  updateDataAtPath(`scavengerhunt/groups/${groupId}/players/${userId}`, params);
};

export const updateTeamName = (groupId: string, teamName: string) => {
  logForCrashlytics('updateTeamName fired in huntFunctions');

  if (teamName) {
    updateDataAtPath(`scavengerhunt/groups/${groupId}/teamName`, teamName);
  }
};

export const selectCharacter = async (
  characterName: string,
  playerId: string,
  parentUserId: string,
  role_type: string,
) => {
  logForCrashlytics('selectCharacter fired in huntFunctions');
  const theme = getReduxState((state) => state?.group?.info?.theme);
  const groupId = getReduxState((state) => state?.group?.info?.groupId);

  const data = {
    appVersion: 4,
    theme: theme || 'Standard',
    character: characterName,
    roleType: role_type,
    parentUserId,
    OS: Platform.OS,
    testing: 'false',
  };

  await updateDataAtPath(
    `scavengerhunt/groups/${groupId}/players/${playerId}`,
    data,
  );
};

export const startHunt = (groupId: string) => {
  logForCrashlytics('startHunt fired in huntFunctions');

  updateDataAtPath(`scavengerhunt/groups/${groupId}`, {
    huntStarted: true,
    huntStartTime: Date.now(),
    huntStartDate: moment().format('YYYY-MM-DD'),
    huntQuizStarted: true,
    huntQuizStartTime: Date.now(),
  });
};

/////////////////////////////////////
//////////////// game flow

export const pauseHunt = async (groupId: string) => {
  logForCrashlytics('pauseHunt fired in huntFunctions');

  const huntPausedCountData = await getDataAtPath(
    `scavengerhunt/groups/${groupId}/huntPausedCount`,
  );

  let huntPausedCount: number = 0;

  if (typeof huntPausedCountData === 'number') {
    huntPausedCount = huntPausedCountData;
  }

  huntPausedCount += 1;

  await updateDataAtPath(`scavengerhunt/groups/${groupId}`, {
    huntPaused: true,
    huntPausedTime: Date.now(),
    huntPausedCount,
  });
};

export const unPauseHunt = async () => {
  logForCrashlytics('unPauseHunt fired in huntFunctions');

  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const huntPausedTime =
    getReduxState((state) => state?.group?.info?.huntPausedTime) || Date.now();

  // updating hunt finish time
  const timerStart = getReduxState((state) => state?.game_v2?.timerStart);
  const timePaused = Date.now() - huntPausedTime;

  console.log({timerStart});
  if (timerStart) {
    const path = `scavengerhunt/completions/${groupId}/location_v2/timerStart`;
    const newFinishTime = timerStart + timePaused;
    updateDataAtPath(path, newFinishTime);
  }

  updateDataAtPath(`scavengerhunt/groups/${groupId}/huntPaused`, false);
  updateDataAtPath(`scavengerhunt/groups/${groupId}/huntPausedTime`, {});

  const playerChallenges = (await getDataAtPath(
    `scavengerhunt/completions/${groupId}/player`,
  )) as Record<string, TChallenge> | null;

  if (playerChallenges != null) {
    Object.values(playerChallenges)?.map?.((challenge) => {
      // checking if the challenge is properly formatted aka has a completion id
      if (challenge?.completionId) {
        challenge.deliveryTime = challenge?.deliveryTime || Date.now();
        const newDeliverDelay = challenge?.deliveryTime - huntPausedTime;

        if (playerChallenges[challenge?.completionId]) {
          playerChallenges[challenge?.completionId].deliveryTime =
            challenge.deliveryTime + timePaused;
          playerChallenges[challenge.completionId].iosDeliveryTime = moment()
            .add(newDeliverDelay, 'milliseconds')
            .format('YYYY-MM-DDTHH:mm:ss.sssZ');
        }
      }
    });
  }

  updateDataAtPath(
    `scavengerhunt/completions/${groupId}/player`,
    playerChallenges,
  );
};

export const finishHunt = async () => {
  logForCrashlytics('finishHunt fired in huntFunctions');

  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const score = getReduxState((state) => state?.group?.info?.score);
  const teamName = getReduxState((state) => state?.group?.info?.teamName);
  const groupPhoto = getReduxState((state) => state?.group?.info?.groupPhoto);
  const huntId = getReduxState((state) => state?.group?.info?.huntId);
  const finishTime = Date.now();

  const leaderboardObject: TLeaderBoardItem = {
    groupId: groupId || null,
    teamName: teamName || null,
    score: score || null,
    finishTime,
    finishDate: moment().format('YYYY-MM-DD'),
    teamPhoto: groupPhoto || null,
  };

  updateDataAtPath(
    `scavengerhunt/leaderboard/${huntId}/all/${groupId}`,
    leaderboardObject,
  );

  const groupDonePath = `scavengerhunt/groups/${groupId}`;
  console.log({groupDonePath});

  return await updateDataAtPath(groupDonePath, {
    huntFinished: true,
    finishTime,
    finishDate: moment().format('YYYY-MM-DD'),
  });
};

export const playerPhotoChallengeCompletion = (
  selectedChallenge: TChallenge,
) => {
  logForCrashlytics('playerPhotoChallengeCompletion fired in huntFunctions');

  const {userId, shareUUID} = selectedChallenge;
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const eventId = getReduxState((state) => state?.group?.info?.eventId);
  const huntId = getReduxState((state) => state?.group?.info?.huntId);

  const fallbackShareUUID = uuidv4().substring(0, 8);

  selectedChallenge.shareUUID = shareUUID || fallbackShareUUID;

  selectedChallenge.photoUrl = selectedChallenge.downloadURL || null;
  selectedChallenge.pointsEarned = 50;
  selectedChallenge.completionTime = Math?.floor(Date.now() / 1000);

  //eslint-disable-next-line
  const url = `scavengerhunt/completions/${groupId}/player/${selectedChallenge.completionId}`;

  updateDataAtPath(url, selectedChallenge);

  const email = getReduxState((state) => state?.user?.info?.email) || null;
  const teamName =
    getReduxState((state) => state?.group?.info?.teamName) || null;
  const is_test = !!(
    email?.includes?.('@lets') || teamName?.includes?.('test')
  );

  const photoCompletionObject = {
    shareUUID: shareUUID || fallbackShareUUID,
    groupId: groupId || null,
    eventId: eventId || null,
    userId: userId || null,
    teamName,
    completionId: selectedChallenge.completionId || null,
    challenge: selectedChallenge.task || null,
    is_test,
    email,
    type: 'Player Photo Challenge',
    date: Date.now(),
    dateFormatted: moment().format('YYYY-MM-DDTHH:mm:ss.sssZ'),
    pointsEarned: 50,
    hunt_id: huntId || null,
    downloadURL: selectedChallenge.downloadURL || null,
    photoUrl: selectedChallenge.downloadURL || null,
    thumbnail: selectedChallenge.thumbnail || null,
    userEmail: email || '',
  };

  console.log(
    ' the photo completion object in hunt utils is ',
    photoCompletionObject,
  );

  const updatedIds = {
    groupId: groupId || null,
    eventId: eventId || null,
  };

  const photoPath2 = `photos/${groupId}/photos/${shareUUID}`;
  updateDataAtPath(photoPath2, photoCompletionObject);

  updateDataAtPath(`photos/${groupId}`, updatedIds);

  // creating share link for photo
  !shareUUID &&
    selectedChallenge.downloadURL &&
    createShareLink(
      shareUUID || fallbackShareUUID,
      selectedChallenge.downloadURL,
      selectedChallenge.task,
      selectedChallenge.thumbnail || null,
    );
};

// Helper function to convert degrees to radians
function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

// Helper function to calculate distance between two coordinates in meters
function getDistanceFromLatLonInMeters(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) {
  const R = 6371000; // Radius of the Earth in meters
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance;
}

type Position = {
  latitude: number;
  longitude: number;
};

export const saveGroupPosition = async (
  latLongObject: {
    latitude: number;
    longitude: number;
  },
  source: string,
) => {
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const teamName = getReduxState((state) => state?.group?.info?.teamName);
  const eventId = getReduxState((state) => state?.event?.eventId);
  const {latitude = 0, longitude = 0} = latLongObject;

  if (!latitude || !groupId) {
    return console.warn('saveGroupPosition error', {
      latitude,
      longitude,
      groupId,
    });
  }

  // Accuracy of about 100 feet
  const roundedLat = Math.round(latitude * 3000) / 3000;
  const roundedLong = Math.round(longitude * 3000) / 3000;

  // Retrieve the previous position
  const previousPosition = (await getDataAtPath(
    `scavengerhunt/groups/${groupId || 'error'}/position`,
  )) as Position | null;

  let shouldUpdatePosition = true; // Default to true

  if (
    previousPosition &&
    previousPosition.latitude &&
    previousPosition.longitude
  ) {
    // Calculate the distance between the previous and new positions
    const distance = getDistanceFromLatLonInMeters(
      previousPosition.latitude,
      previousPosition.longitude,
      roundedLat,
      roundedLong,
    );

    // If the distance is less than or equal to 91.44 meters (300 feet), do not update
    if (distance <= 91.44) {
      shouldUpdatePosition = false;
    }
  }

  if (shouldUpdatePosition) {
    console.warn('saveGroupPosition saved, source: ' + source, {
      roundedLat,
      roundedLong,
      groupId,
    });
    updateDataAtPath(`scavengerhunt/groups/${groupId || 'error'}/position`, {
      latitude: roundedLat,
      longitude: roundedLong,
    });
    if (eventId) {
      updateDataAtPath(
        `groupLocations/${eventId || 'error'}/${groupId || 'error'}/`,
        {
          lat: roundedLat,
          long: roundedLong,
          teamName: teamName || null,
          groupId: groupId,
        },
      );
    }
  } else {
    console.warn('Position not updated due to insufficient movement', {
      roundedLat,
      roundedLong,
      previousLatitude: previousPosition?.latitude,
      previousLongitude: previousPosition?.longitude,
      groupId,
    });
  }
};

export const triviaCompletionEvent = (
  correct: boolean,
  option: string,
  completionId: string,
  pointsPossible: number,
  groupId: string,
) => {
  logForCrashlytics('triviaCompletionEvent fired in huntFunctions');

  apiUpdateUserBadges();
  const completion = {
    pointsEarned: correct ? pointsPossible || 50 : 0,
    guess: option,
    completionTime: Math?.floor(Date.now() / 1000),
    completionDate: moment().format('YYYY-MM-DD'),
  };

  updateDataAtPath(
    `scavengerhunt/completions/${groupId}/player/${completionId}`,
    completion,
  );
};

interface BarChallengeCompletionEventParams {
  selectedChallengeId: string;
  challengeId: string;
  challenge: string;
  imagePath: string;
  downloadURL: string;
  thumbnail?: string | null;
  shareUUID: string;
  userId: string;
  firstName?: null;
}

export const barChallengeCompletionEvent = ({
  selectedChallengeId,
  challengeId,
  challenge,
  imagePath,
  downloadURL,
  thumbnail = null,
  shareUUID,
  userId,
  firstName = null,
}: BarChallengeCompletionEventParams) => {
  logForCrashlytics('barChallengeCompletionEvent fired in huntFunctions');

  const fallbackShareUUID = uuidv4().substring(0, 8);

  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const eventId = getReduxState((state) => state?.group?.info?.eventId);
  const huntId = getReduxState((state) => state?.group?.info?.huntId);

  const completionObject = {
    completingUserId: userId,
    userId,
    firstName,
    completeTime: Math?.floor(Date.now() / 1000),
    completionDate: moment().format('YYYY-MM-DD'),
    imagePath: imagePath || null,
    photoUrl: downloadURL || null,
    thumbnail: thumbnail || null,
    shareUUID: shareUUID || fallbackShareUUID || null,
  };

  const challengePath = `scavengerhunt/completions/${groupId}/location_v2/subChallenges/${challengeId}/completions/${userId}`;

  console.log({challengePath});

  updateDataAtPath(challengePath, completionObject);

  if (!downloadURL) {
    return console.log('no download url so skip');
  }

  !shareUUID && createShareLink(shareUUID, downloadURL, challenge, thumbnail);

  const photoPath = `photos/${groupId}/photos/${uuidv4()}`;

  const email = getReduxState((state) => state?.user?.info?.email);
  const teamName = getReduxState((state) => state?.group?.info?.teamName);
  const is_test = email?.includes?.('@lets') || teamName?.includes?.('test');

  const retrievePhotoObject = {
    groupId: groupId || null,
    eventId: eventId || null,
    photoUrl: downloadURL,
    userId,
    challenge: challenge,
    is_test: is_test || null,
    teamName: email || null,
    email: email || null,
    type: 'Treasure Photo Challenge',
    dateTime: Date.now(),
    dateFormatted: moment().format('YYYY-MM-DD'),
    pointsEarned: 100,
    hunt_id: huntId || null,
    imagePath,
    path: photoPath,
  };

  const updatedIds = {
    groupId: groupId || null,
    eventId: eventId || null,
  };

  updateDataAtPath(`photos/${groupId}`, updatedIds);
  updateDataAtPath(photoPath, retrievePhotoObject);

  if (selectedChallengeId) {
    updateDataAtPath(`scavengerhunt/groups/${groupId}/groupPhoto`, downloadURL);
  }
};

///////////////////////////////////////////////////////////////////
/////////////// user feedback, reviews, endgame bonuses

export const leaveChallengeFeedback = (
  challenge: TChallenge,
  group: TGroupInfo,
  userId: string,
) => {
  logForCrashlytics('leaveChallengeFeedback fired in huntFunctions');

  const {groupId, theme} = group;

  const params = {
    ...challenge,
    groupId,
    userId,
    theme,
    id: uuidv4(),
  };

  updateDataAtPath(`scavengerhunt/challengeReviews/${params.id}`, params);
};

export const leaveHuntReview = async (
  userId: string,
  groupId: string,
  r_hunt: number,
  r_app: number,
) => {
  logForCrashlytics('leaveHuntReview fired in huntFunctions');

  const review = {
    r_hunt,
    r_app,
    OS: Platform.OS,
  };

  return await updateDataAtPath(
    `scavengerhunt/groups/${groupId}/players/${userId}/reviews`,
    review,
  );
};

export const applyReviewBonusPoints = (
  bonusType: string,
  bonusPoints: number,
  userId: string,
) => {
  const groupId = getReduxState((state) => state?.group?.info?.groupId);

  logForCrashlytics('applyReviewBonusPoints fired in huntFunctions');

  const bonusId = uuidv4();

  const bonusObject = {
    bonusId,
    points: bonusPoints,
    pointsEarned: bonusPoints,
    bonusType,
    userId,
  };

  updateDataAtPath(
    `scavengerhunt/groups/${groupId}/players/${userId}/bonuses/${bonusType}`,
    bonusObject,
  );
  updateDataAtPath(
    `scavengerhunt/completions/${groupId}/bonus/${bonusId}`,
    bonusObject,
  );
};

export const addChallengeCoins = async (userId: string, coins = 0) => {
  logForCrashlytics('addChallengeCoins fired in huntFunctions');

  const currentCoins = await getDataAtPath(`users/${userId}/coins`, 'number');

  const newTotalCoins = currentCoins + coins;

  updateDataAtPath(`users/${userId}/coins`, newTotalCoins);
  apiTrackUserTransaction({
    type: 'bonus_coins_reward',
    user_id: userId,
    usage_user_id: userId,
    coins: coins,
  });

  analytics().logEarnVirtualCurrency({
    value: coins,
    virtual_currency_name: 'coins',
  });
};

export const addChallengeTickets = async (
  userId: string,
  tickets: number,
  theme = 'Standard',
) => {
  logForCrashlytics('addChallengeTickets fired in huntFunctions');

  const currentTickets = await getDataAtPath(
    `users/${userId}/tickets/${theme}`,
    'string',
  );
  const newTotalTickets = currentTickets + tickets;

  updateDataAtPath(`users/${userId}/tickets/${theme}`, newTotalTickets);
};

export const howToStartAHuntTips = (titleText = 'How To Start A Hunt') => {
  navigationRef.navigate(
    'ConfirmModal',
    {
      titleText,
      numberedPoints: [
        {
          title: 'Get Tickets Or Join A Friends Event',
          text: (
            <Text>
              <TouchableWithoutFeedback
                onPress={() =>
                  navigationRef.navigate(
                    'PurchaseModal',
                    {},
                    'Utils/huntFunctions.tsx',
                  )
                }>
                <Text
                  style={{
                    color: constants?.color?.teal,
                    textDecorationLine: 'underline',
                  }}>
                  Buy Tickets
                </Text>
              </TouchableWithoutFeedback>{' '}
              or have one of your friends buy tickets. You can also redeem
              tickets that you already purchased on the{' '}
              <TouchableWithoutFeedback
                onPress={() =>
                  navigationRef.navigate(
                    'JoinLanding',
                    {},
                    'Utils/huntFunctions.tsx',
                  )
                }>
                <Text
                  style={{
                    color: constants?.color?.teal,
                    textDecorationLine: 'underline',
                  }}>
                  Join Page.
                </Text>
              </TouchableWithoutFeedback>{' '}
              If your friend has already created a group then you can join with
              their{' '}
              <TouchableWithoutFeedback
                onPress={() =>
                  navigationRef.navigate(
                    'JoinLanding',
                    {},
                    'Utils/huntFunctions.tsx',
                  )
                }>
                <Text
                  style={{
                    color: constants?.color?.teal,
                    textDecorationLine: 'underline',
                  }}>
                  Join Code.
                </Text>
              </TouchableWithoutFeedback>
            </Text>
          ),
        },
        {
          title: 'Pick Your Activity',
          text: 'Tap a activity on the home screen (scavenger hunts, art walks, bar crawls, etc) & start registering.',
        },
        {
          title: 'Invite Your Friends With Your Join Code',
          text: 'When your group is created you will see a join code. Give this to other players once they download the app. Or add your friends to your own device.',
        },
      ],
      confirmText: 'Continue',
    },
    'howToStartAHuntTips',
  );
};

// changing the selected hunt after the group is created
export const changeSelectedHuntPostCreation = async (
  groupId = 'error',
  selectedHunt: THunt,
) => {
  const description = selectedHunt.long_description || '';
  const groupObject = {
    classic_hunt: selectedHunt?.classic_hunt || null,
    indoor_hunt: selectedHunt?.hunt_type == 'inDoorHunt',
    isBarHunt: selectedHunt?.hunt_type === 'barHunt',
    huntType: selectedHunt?.hunt_type || null,
    isFree: selectedHunt?.is_free,
    routeId: selectedHunt?.route_id || null, /// update this to handle route ids instead of hunt ids
    routeIdOverride: selectedHunt?.routeIdOverride || null, /// for connected hunts
    city: selectedHunt?.city || null,
    lat: selectedHunt?.lat || '',
    long: selectedHunt?.long || '',
    huntPhoto: selectedHunt?.huntMediumPhotoURL || defaultTeamBannerUrl || null, //can be edited for corporate groups
    description: parseHTML(description || 'An awesome scavenger hunt.'),
    huntVersion: selectedHunt?.hunt_version || '1.0',
    huntName: selectedHunt?.name || '',
    huntCity: selectedHunt?.city || '',
    dateTimeEventCreated: Date.now(),
    startingLocation: selectedHunt?.starting_location || '',
    eventId: selectedHunt?.eventId || null,
    eventStartTime: selectedHunt?.event_start_time || null,
    eventEndTime: selectedHunt?.event_end_time || null,
    huntLength: selectedHunt?.distance_miles || '',
  };

  await updateDataAtPath(
    `scavengerhunt/groups/${groupId || 'error'}`,
    groupObject,
  );

  return console.log('updated group', {groupObject});
};

export const markHasSeenHybridCharacterList = async (
  userId: string,
  groupId: string,
) => {
  logForCrashlytics('markHasSeenHybridCharacterList fired in huntFunctions');

  const path = `scavengerhunt/groups/${groupId || 'error'}/players/${
    userId || 'error'
  }/hasSeenHybridCharacterList`;

  updateDataAtPath(path, true);
};

export const markHasReviewedHybridCharacter = async (
  userId: string,
  groupId: string,
) => {
  logForCrashlytics('markHasReviewedHybridCharacter fired in huntFunctions');

  const path = `scavengerhunt/groups/${groupId || 'error'}/players/${
    userId || 'error'
  }/hasReviewedHybridCharacter`;

  updateDataAtPath(path, true);
};

import React from 'react';
import {ImageBackground, Text, TouchableOpacity, View} from 'react-native';
import constants from 'src/constants';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {useUserId} from 'src/Hooks/reduxHooks';
import {navigationRef} from 'src/Nav/navigationHelpers';

const SiteStatsCard = ({
  showAdditionalInfo = false,
}: {
  showAdditionalInfo?: boolean;
}) => {
  const userId = useUserId();

  const localLocationsList = useTypedSelector(
    (state) => state.local.localLocationsList,
  );

  const localCompletionsList = useTypedSelector(
    (state) => state.local?.localCompletionsList,
  );

  let siteNearCount = 0;
  let siteNearCountVisited = 0;
  let visitedCount = 0;
  let contributedLocations = 0;

  localLocationsList?.forEach((location) => {
    const userDidSubmit = userId && location.submitted_by_user_id === userId;
    if (userDidSubmit) {
      contributedLocations++;
    }
  });

  localLocationsList?.forEach((location) => {
    let hasVisitedSpecificLocation = false;
    localCompletionsList.forEach((completion) => {
      if (String(completion.location_id) == String(location.id)) {
        hasVisitedSpecificLocation = true;
      }
    });

    if (hasVisitedSpecificLocation) {
      visitedCount++;
    }
    if (location.distanceAway < 100) {
      siteNearCount++;
      if (hasVisitedSpecificLocation) {
        siteNearCountVisited++;
      }
    }
  });

  const additionalInfo = () =>
    navigationRef.simpleAlert(
      'Visit local sites to get points',
      'The more locations you visit the more points that you get each month towards the grand prize of $1,000 voucher. Also they are really fun spots to visit!',
    );

  return (
    <View style={{flex: 2, flexDirection: 'row', gap: 8}}>
      <TouchableOpacity
        style={{flex: 1}}
        onPress={() =>
          showAdditionalInfo
            ? additionalInfo()
            : navigationRef.navigate('LocalTab', null, 'SiteStats')
        }>
        <ImageBackground
          source={require('src/Images/stylized_bg.png')}
          resizeMode={'stretch'}
          imageStyle={{
            tintColor: 'black',
          }}
          style={{
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 0,
            backgroundColor: constants.color.teal,
            borderRadius: 8,
          }}>
          <Text
            style={{
              fontFamily: 'JakartaBold',
              fontSize: 20,
              color: constants.color.white,
            }}>
            {siteNearCountVisited || 0} of {siteNearCount}
          </Text>
          <Text
            style={{
              fontFamily: 'Jakarta',
              fontSize: 10,
              color: constants.color.white,
              textAlign: 'center',
              paddingHorizontal: 10,
            }}>
            Sites visited within 100 miles
          </Text>
        </ImageBackground>
      </TouchableOpacity>
      <TouchableOpacity
        style={{flex: 1}}
        onPress={() =>
          showAdditionalInfo
            ? additionalInfo()
            : navigationRef.navigate('LocalTab', null, 'SiteStats')
        }>
        <ImageBackground
          source={require('src/Images/stylized_bg.png')}
          resizeMode={'stretch'}
          imageStyle={{
            tintColor: 'black',
          }}
          style={{
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 0,
            backgroundColor: constants.color.teal,
            borderRadius: 8,
          }}>
          <Text
            style={{
              fontFamily: 'JakartaBold',
              fontSize: 20,
              color: constants.color.white,
            }}>
            {visitedCount || 0}
          </Text>
          <Text
            style={{
              fontFamily: 'Jakarta',
              fontSize: 10,
              color: constants.color.white,
              textAlign: 'center',
              paddingHorizontal: 10,
            }}>
            Sites visited in the world
          </Text>
        </ImageBackground>
      </TouchableOpacity>
      <TouchableOpacity
        style={{flex: 1}}
        onPress={() =>
          showAdditionalInfo
            ? additionalInfo()
            : navigationRef.navigate('LocalTab', null, 'SiteStats')
        }>
        <ImageBackground
          source={require('src/Images/stylized_bg.png')}
          resizeMode={'stretch'}
          imageStyle={{
            tintColor: 'black',
          }}
          style={{
            flex: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 0,
            backgroundColor: constants.color.teal,
            borderRadius: 8,
          }}>
          <Text
            style={{
              fontFamily: 'JakartaBold',
              fontSize: 20,
              color: constants.color.white,
            }}>
            {contributedLocations || 0}
          </Text>
          <Text
            style={{
              fontFamily: 'Jakarta',
              fontSize: 10,
              color: constants.color.white,
              textAlign: 'center',
              paddingHorizontal: 24,
            }}>
            Sites submitted
          </Text>
        </ImageBackground>
      </TouchableOpacity>
    </View>
  );
};

export default SiteStatsCard;

import React, {Fragment} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  ViewStyle,
  ImageSourcePropType,
  ImageStyle,
} from 'react-native';
import ExpoImage from 'src/Utils/ExpoImage';

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';

import {useNavigation} from '@react-navigation/native';

const signInImage = require('src/Images/signInImage.png');
const coinImage = require('src/Images/badgeStar50.png');

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 10,
    position: 'relative',
    height: 50,
    width: 50,
  },
  image: {
    height: 34,
    width: 34,
    borderRadius: 17,
  },
});

interface UserIconProps {
  userPhoto?: ImageSourcePropType;
  disableSignIn?: boolean;
  imageStyle?: ImageStyle;
  hideBadges?: boolean;
  containerStyle?: ViewStyle;
}

const UserIcon: React.FC<UserIconProps> = ({
  userPhoto = null,
  disableSignIn = false,
  imageStyle = {},
  hideBadges = false,
  containerStyle = {},
}) => {
  console.log('UserIcon Render');
  const navigation = useNavigation();

  const isDeleted = useTypedSelector((state) => state?.user?.info?.deleted);

  const badgesEarnedCount = useTypedSelector(
    (state) => state?.user?.info?.badgesEarnedCount || 0,
  );

  // test for screen recording
  const hasScreenRecording = useTypedSelector(
    (state) => state?.user?.info?.hasScreenRecording || 0,
  );

  const hasScreenRecordingTestId = hasScreenRecording
    ? 'hasScreenRecording'
    : 'doesNotHaveScreenRecording';

  console.log({hasScreenRecordingTestId});

  const hasImageSource = useTypedSelector(
    (state) =>
      state.user?.info?.customPhotoUrl ||
      state.user?.info?.lastGroupPhoto ||
      state.user?.info?.photoUrl ||
      userPhoto,
  );
  const firstName = useTypedSelector((state) => state.user?.info?.firstName);
  const loggedIn = useTypedSelector((state) => state.user?.loggedIn);
  const userImageSource = useTypedSelector(
    (state) =>
      state.user?.info?.customPhotoUrl ||
      state.user?.info?.lastGroupPhoto ||
      state.user?.info?.photoUrl ||
      userPhoto,
  );
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const backgroundColor = useTypedSelector(
    (state) => state.user?.info?.photoColor || constants?.color?.blue,
  );

  const userStatus = () => {
    if (!!hasImageSource) {
      return 'profile';
    }

    if (loggedIn || disableSignIn) {
      return 'color';
    }

    return 'noUser';
  };

  const userPortrait = () => {
    if (!userPhoto && !userImageSource) {
      return <></>;
    }

    const imageSource =
      userPhoto || (userImageSource ? {uri: userImageSource as string} : null);

    if (!imageSource) {
      return <></>;
    }

    return (
      <ExpoImage
        style={{
          ...imageStyle,
          ...styles.image,
          position: 'absolute',
          overflow: 'hidden',

          ...(Platform.OS == 'web'
            ? {width: 36, height: 36, borderRadius: 18}
            : {}),
        }}
        source={imageSource}
        resizeMode={'cover'}
      />
    );
  };

  const colorPortrait = () => {
    return (
      <View
        style={{
          ...styles.image,
          ...imageStyle,
          ...(Platform.OS == 'web' ? {width: 36, height: 36} : {}),
          backgroundColor,
          position: 'absolute',
          top: 0,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Text style={{color: 'white', fontSize: 20}}>
          {firstName?.charAt?.(0).toUpperCase?.()}
        </Text>
      </View>
    );
  };

  const genericPortrait = () => {
    return (
      <ExpoImage
        style={{
          ...styles.image,
          ...imageStyle,
          position: 'absolute',
          overflow: undefined,
          ...(Platform.OS == 'web' ? {width: 36, height: 36} : {}),
        }}
        source={signInImage}
        resizeMode={'contain'}
      />
    );
  };

  const renderOptions = {
    profile: userPortrait,
    color: colorPortrait,
    noUser: genericPortrait,
  };

  console.log('UserIcon Render', {disableSignIn, userPhoto});

  return (
    <TouchableOpacity
      style={{
        ...styles.container,
        ...(isDeleted ? {backgroundColor: 'red', paddingLeft: 20} : {}),
      }}
      disabled={!!disableSignIn}
      onPress={() => {
        console.log('UserIcon pressed');

        // @ts-ignore
        navigation?.openDrawer?.();

        console.log('UserIcon pressed done');
      }}>
      <View
        testID={hasScreenRecordingTestId}
        style={{
          flexDirection: 'row',
          width: 60,
          alignItems: 'center',
          ...containerStyle,
        }}>
        {/* User Icon */}
        <View
          style={{
            position: 'relative',
            width: 40,
            height: 36,
            borderWidth: 0,
            marginLeft: -2,
            marginTop: 4,
          }}>
          {renderOptions[userStatus()]()}
        </View>
        {/* Badge */}
        {!!badgesEarnedCount && !hideBadges && !playTestState && (
          <Fragment>
            <ExpoImage
              style={{
                height: 22,
                width: 22,
                position: 'absolute',
                top: 0,
                left: 20,
              }}
              source={coinImage}
              resizeMode={'contain'}
            />
            <Text
              style={{
                fontFamily: constants?.font.circular,
                fontSize: 10,

                height: 22,
                width: 22,
                position: 'absolute',
                top: 0.5,
                left: 20,
                textAlign: 'center',
                paddingTop: 5,
                color: constants?.color?.white,
              }}>
              {badgesEarnedCount}
            </Text>
          </Fragment>
        )}
        {!!isDeleted && (
          <Text
            style={{
              fontFamily: constants?.font.circular,
              fontSize: 10,
              height: 22,

              position: 'absolute',
              top: 20,
              left: 0,
              textAlign: 'center',
              paddingTop: 5,
              color: 'purple',
              zIndex: 1000,
            }}>
            DELETED!!!
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default UserIcon;

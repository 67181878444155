import constants from 'src/constants';
import React, {useRef} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  Platform,
} from 'react-native';

// importing detox
let isDetoxSync: () => boolean;
if (Platform.OS !== 'web') {
  const detoxModule = require('react-native-is-detox');
  isDetoxSync = detoxModule.isDetoxSync;
} else {
  isDetoxSync = () => false;
}

import AppIntroSlider from 'react-native-app-intro-slider';
import {SafeAreaView} from 'react-native-safe-area-context';

import ExpoImage from 'src/Utils/ExpoImage';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {loginAdminTestUser} from 'src/Utils/deepLinkHelper';

import * as Location from 'expo-location';

import {setIntroSliderVisited} from 'src/Redux/reducers/user_info.reducer';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {messaging} from 'src/Utils/db';
import {updateDataAtPath} from 'src/Utils/fireUtils';
import {dispatchAction} from 'src/Utils/helpers';
import getAppType from '../Utils/getAppType';
import FreeTrialScreen from 'src/Modules/LetsRoamPlus/FreeTrialScreen';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';

const swiperImages = [
  require('src/Images/AppIntroSwiper/appSwiper1.jpg'),
  require('src/Images/AppIntroSwiper/appSwiper2.jpg'),
  require('src/Images/AppIntroSwiper/appSwiper3.jpg'),
  require('src/Images/AppIntroSwiper/appSwiper4.jpg'),
  require('src/Images/AppIntroSwiper/appSwiperAA1.jpg'),
  require('src/Images/AppIntroSwiper/appSwiperAA2.jpg'),
  require('src/Images/AppIntroSwiper/appSwiperAA3.jpg'),
];

const swiperIcons = [
  require('src/Images/Icons/destination.png'),
  require('src/Images/Icons/interactive-white.png'),
  require('src/Images/Icons/notificationBell.png'),
  require('src/Images/Icons/medal.png'),
];

const AppIntroSwiper = () => {
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const hasExplorerPass = !!useExplorerPass();

  const [foregroundPermission, requestForegroundPermission] =
    Location.useForegroundPermissions?.();

  let backgroundStatus: Location.PermissionResponse | null = null,
    requestBackgroundPermission: () => Promise<Location.PermissionResponse>;
  if (Platform.OS === 'ios') {
    // @eslint-disable-next-line
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    [backgroundStatus, requestBackgroundPermission] =
      Location.useBackgroundPermissions?.();
  }

  console.log('AppIntroSwiper render', {
    foregroundPermission,
    backgroundStatus,
  });

  const hasAskedLocationPermissions = useRef(false);
  const hasAskedNotificationPermissions = useRef(false);

  const handlePress = async (item: Slide) => {
    console.log('handlePress', {item});
    if (item.key === '4' || item.key === 'freeTrialScreen') {
      _onDone();
    } else if (item.key === '3') {
      if (hasAskedNotificationPermissions.current) {
        sliderRef?.current?.goToSlide(parseInt(item.key));
      }
      hasAskedNotificationPermissions.current = true;

      messaging()
        .requestPermission()
        .then(() => {
          if (getAppType() == 'aa' && hasExplorerPass) {
            _onDone();
          } else {
            sliderRef?.current?.goToSlide(parseInt(item.key));
          }
        })
        .catch((permissionsError) => {
          console.warn({permissionsError});
        });
    } else if (item.key === '2') {
      if (
        hasAskedLocationPermissions.current ||
        foregroundPermission?.status === 'granted'
      ) {
        sliderRef?.current?.goToSlide(parseInt(item.key));
      }
      hasAskedLocationPermissions.current = true;
      if (isDetoxSync()) {
        sliderRef?.current?.goToSlide(parseInt(item.key));
      } else if (Platform.OS === 'ios') {
        console.log('requesting foreground and background permissions');
        requestBackgroundPermission()
          .then((requestResponse) => {
            console.log({requestResponse});
            updateDataAtPath(`devices/${deviceUUID || 'error'}/deviceData`, {
              backgroundLocationPermissions: requestResponse?.status,
            });
            sliderRef?.current?.goToSlide(parseInt(item.key));
          })
          .catch((requestError) => {
            console.error({requestError});
            sliderRef?.current?.goToSlide(parseInt(item.key));
          });
      } else {
        console.log('requesting foreground permissions');
        requestForegroundPermission()
          .then((requestForegroundPermissionResponse) => {
            console.log({requestForegroundPermissionResponse});
            sliderRef?.current?.goToSlide(parseInt(item.key));
          })
          .catch((requestForegroundPermissionError) => {
            console.error({requestForegroundPermissionError});
            sliderRef?.current?.goToSlide(parseInt(item.key));
          });
      }
    } else {
      sliderRef?.current?.goToSlide(parseInt(item.key));
    }
  };

  let scavengerSlides = [
    {
      key: '1',
      title: "Welcome to Let's Roam!",
      topImage: require('src/Images/logo4.png'),
      buttonText: 'Continue',
      icon: swiperIcons[0],
      iconStyle: {
        height: 30,
        width: 31,
        // resizeMode: 'cover',
        marginBottom: 15,
      },
      titleStyle: {
        fontFamily: 'JakartaBold',
        color: 'white',
        fontSize: 24,
        lineHeight: 28,
        marginBottom: 15,
      },
      image: swiperImages[0],
      description:
        "As one of the world's top sources for things to do and team building, there’s never a dull moment when you adventure with Let’s Roam.",
      descriptionStyle: {
        fontFamily: 'Jakarta',
        color: 'white',
        fontSize: 16,
        lineHeight: 22,
        marginBottom: 15,
      },
      buttonContainerStyle: {},
    },
    {
      key: '2',
      title: 'Discover local gems',
      buttonText:
        foregroundPermission?.status === 'granted'
          ? 'Continue'
          : 'Enable location access',
      icon: swiperIcons[1],
      iconStyle: {
        height: 30,
        width: 31,
        // resizeMode: 'cover',
        marginBottom: 15,
      },
      titleStyle: {
        fontFamily: 'JakartaBold',
        color: 'white',
        fontSize: 24,
        lineHeight: 28,
        marginBottom: 15,
      },
      image: swiperImages[1],
      description:
        "To guide you to the coolest nearby places and customize your experience, we'll need access to your location.",
      descriptionStyle: {
        fontFamily: 'Jakarta',
        color: 'white',
        fontSize: 16,
        lineHeight: 22,
        marginBottom: 15,
      },
      buttonContainerStyle: {},
    },
    {
      key: '3',
      title: 'Stay updated on the go',
      buttonText: Platform.OS == 'ios' ? 'Enable notifications' : 'Continue',
      icon: swiperIcons[2],
      iconStyle: {
        height: 33,
        width: 30,
        // resizeMode: 'cover',
        marginBottom: 15,
      },
      titleStyle: {
        fontFamily: 'JakartaBold',
        color: 'white',
        fontSize: 24,
        lineHeight: 28,
        marginBottom: 15,
      },
      image: swiperImages[2],
      description:
        'Grant us notification permissions to keep you informed about adventures near you, new features, and more.',
      descriptionStyle: {
        fontFamily: 'Jakarta',
        color: 'white',
        fontSize: 16,
        lineHeight: 22,
        marginBottom: 15,
      },
      buttonContainerStyle: {},
    },
    {
      key: '4',
      title:
        playTestState !== false
          ? 'Explore With Friends'
          : 'Win Thousands in prizes every month!',
      buttonText: 'Get started',
      icon: swiperIcons[3],
      iconStyle: {
        height: 30,
        width: 31,
        // resizeMode: 'cover',
        marginBottom: 15,
      },
      titleStyle: {
        fontFamily: 'JakartaBold',
        color: 'white',
        fontSize: 24,
        lineHeight: 28,
        marginBottom: 15,
      },
      image: swiperImages[3],
      description:
        playTestState !== false
          ? 'Go On Epic Adventures With Friends'
          : "Explore with the Let's Roam app to earn points, top the leaderboard, and win thousands of dollars in monthly prizes by completing hunts, getting first place, checking out local spots, and more.",
      descriptionStyle: {
        fontFamily: 'Jakarta',
        color: 'white',
        fontSize: 16,
        lineHeight: 22,
        marginBottom: 15,
      },
      buttonContainerStyle: {},
    },
  ];

  if (getAppType() == 'aa') {
    scavengerSlides = [
      {
        key: '1',
        title: 'Say hi to your new best friend',
        topImage: require('src/Images/logo4.png'),
        buttonText: 'Continue',
        icon: swiperIcons[0],
        iconStyle: {
          height: 30,
          width: 31,
          // resizeMode: 'cover',
          marginBottom: 15,
        },
        titleStyle: {
          fontFamily: 'JakartaBold',
          color: 'white',
          fontSize: 24,
          lineHeight: 28,
          marginBottom: 15,
        },
        image: swiperImages[4],
        description:
          'Your dedicated personal assistant is waiting to meet you. Whether it’s securing a table at a sought-after restaurant or rearranging travel itineraries, your assistant can handle it all.',
        descriptionStyle: {
          fontFamily: 'Jakarta',
          color: 'white',
          fontSize: 16,
          lineHeight: 22,
          marginBottom: 15,
        },
        buttonContainerStyle: {
          backgroundColor: '#648A87',
        },
      },
      {
        key: '2',
        title: 'Get nearby recommendations',
        buttonText:
          foregroundPermission?.status === 'granted'
            ? 'Continue'
            : 'Enable location access',
        icon: swiperIcons[1],
        iconStyle: {
          height: 30,
          width: 31,
          // resizeMode: 'cover',
          marginBottom: 15,
        },
        titleStyle: {
          fontFamily: 'JakartaBold',
          color: 'white',
          fontSize: 24,
          lineHeight: 28,
          marginBottom: 15,
        },
        image: swiperImages[6],
        description:
          "To guide you to the coolest nearby places and customize your recommendations, we'll need access to your location.",
        descriptionStyle: {
          fontFamily: 'Jakarta',
          color: 'white',
          fontSize: 16,
          lineHeight: 22,
          marginBottom: 15,
        },
        buttonContainerStyle: {
          backgroundColor: '#648A87',
        },
      },
      {
        key: '3',
        title: 'Stay updated on the go',
        buttonText: Platform.OS == 'ios' ? 'Enable notifications' : 'Continue',
        icon: swiperIcons[2],
        iconStyle: {
          height: 33,
          width: 30,
          // resizeMode: 'cover',
          marginBottom: 15,
        },
        titleStyle: {
          fontFamily: 'JakartaBold',
          color: 'white',
          fontSize: 24,
          lineHeight: 28,
          marginBottom: 15,
        },
        image: swiperImages[5],
        description:
          'Grant us notification permissions so your assistant can keep you informed with up to the minute updates and lightning-fast responses.',
        descriptionStyle: {
          fontFamily: 'Jakarta',
          color: 'white',
          fontSize: 16,
          lineHeight: 22,
          marginBottom: 15,
        },
        buttonContainerStyle: {
          backgroundColor: '#648A87',
        },
      },
      {
        key: 'freeTrialScreen',
        title: '',
        buttonText: '',
        icon: '',
        iconStyle: {
          height: 33,
          width: 30,
          // resizeMode: 'cover',
          marginBottom: 15,
        },
        titleStyle: {
          fontFamily: 'JakartaBold',
          color: 'white',
          fontSize: 24,
          lineHeight: 28,
          marginBottom: 15,
        },
        image: '',
        description: '',
        descriptionStyle: {
          fontFamily: 'Jakarta',
          color: 'white',
          fontSize: 16,
          lineHeight: 22,
          marginBottom: 15,
        },
        buttonContainerStyle: {
          backgroundColor: '',
        },
      },
    ];
  }

  type Slide = (typeof scavengerSlides)[0];

  const sliderRef = useRef<AppIntroSlider<Slide>>(null);

  const _onDone = async () => {
    dispatchAction(setIntroSliderVisited(true));
    navigationRef.navigate(
      getAppType() === 'aa' ? 'LandingPageMain' : 'LoginPage',
      {},
      'LandingPage/AppIntroSwiper.tsx',
    );
  };

  const deviceUUID = useTypedSelector((state) => state.app_info?.deviceUUID);

  const _renderItem = ({item}: {item: Slide}) => {
    return item.key === 'freeTrialScreen' ? (
      <FreeTrialScreen onDone={_onDone} />
    ) : (
      <ImageBackground
        testID={'appInitialLoadDone'}
        source={item.image}
        style={{
          flex: 1,
        }}>
        <SafeAreaView style={{flex: 1, alignItems: 'center'}}>
          {!!item?.topImage && (
            <ExpoImage
              resizeMode="contain"
              style={{width: 300, height: 118}}
              source={item?.topImage}></ExpoImage>
          )}
        </SafeAreaView>

        <View
          style={{
            paddingHorizontal: 20,
            marginBottom: 60,
            width: '100%',
          }}>
          <ExpoImage source={item.icon} style={{...item.iconStyle}} />
          <Text style={item.titleStyle}>{item.title}</Text>
          <Text style={item.descriptionStyle}>{item.description}</Text>
          <View style={{width: '100%'}}>
            <TouchableOpacity
              testID={'appIntroSwiperButton' + item.key}
              onPress={async () => {
                handlePress(item);
              }}
              style={{
                width: '100%',
                flexDirection: 'row',
                backgroundColor: constants.color.orange,
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5,
                padding: 8,
                borderRadius: 5,
                marginTop: 24,
                paddingVertical: 20,
                marginBottom: 30,
                ...item.buttonContainerStyle,
              }}>
              <Text
                style={{
                  color: constants.color.white,
                  fontSize: 17,
                  fontFamily: 'JakartaBold',
                }}>
                {item.buttonText}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ImageBackground>
    );
  };

  return (
    <View style={{flex: 1, position: 'relative'}}>
      <AppIntroSlider
        ref={sliderRef}
        data={scavengerSlides}
        renderItem={_renderItem}
        onDone={_onDone}
        showNextButton={false}
        showDoneButton={false}
        scrollEnabled={false}
        dotClickEnabled={false}
        dotStyle={{
          backgroundColor: constants?.color?.gray3,
        }}
      />
      <TouchableOpacity
        style={{
          height: 20,
          position: 'absolute',
          zIndex: 60,
          top: 60,
          right: 0,
          width: 20,
          marginTop: 10,
          backgroundColor: __DEV__ ? 'red' : 'transparent',
        }}
        onPress={() => {
          console.log('loginAdminTestUser clicked');
          loginAdminTestUser();
        }}
        testID={'loginAdminTestUser'}></TouchableOpacity>
    </View>
  );
};

export default AppIntroSwiper;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {View, ScrollView, Alert, TouchableOpacity, Text} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

////////////////// utils
import helpers from 'src/Utils/helpers';

import {joinHunt} from 'src/Utils/huntFunctions';

import FlagButton from 'src/Modules/FlagButton';

import UserProfileIcon from 'src/Modules/UserProfileIcon';

/////////////////////////////////////////////
/////////////// utils

import {updateDataAtPath} from 'src/Utils/fireUtils';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {TSimpleGroupInfo} from 'src/types/TSimpleGroup';
import {api_delete_user_or_group} from 'src/Utils/apiCalls';

const EventLeaderboard: React.FC<{
  showDeletedTeams: boolean;
  setSelectedGroup: Function;
  setCurrentView: Function;
}> = ({showDeletedTeams, setSelectedGroup, setCurrentView}) => {
  const {width} = useWindowDimensionsSafe();

  const groups = useTypedSelector((state) => state.event?.groups);

  const userId = useTypedSelector((state) => state.user?.userId);
  const event = useTypedSelector((state) => state.event);

  let place = 0;
  let previousHigherScore = 0;

  const onNameChangeSubmit = async (
    newName: string,
    handleClose: () => void,
    selectedGroup: TSimpleGroupInfo,
  ) => {
    if (!newName) {
      return Alert.alert('A team name is required! Think of something fun!');
    }
    console.log('onNameChangeSubmit fired');
    const {groups} = event;
    const allNames =
      groups && groups?.map?.((group) => (group.teamName + '').toLowerCase?.());

    //console.log('all the team names are: ', allNames)

    if (newName?.length > 20) {
      return Alert.alert('The name is too long!');
    } else if (
      allNames &&
      allNames?.includes?.((newName + '').toLowerCase?.())
    ) {
      return Alert.alert('The name is already taken!');
    }
    await updateDataAtPath(
      `scavengerhunt/groups/${selectedGroup.groupId}/teamName`,
      newName,
    );
    handleClose();
  };

  const showChangeName = (selectedGroup: TSimpleGroupInfo) => {
    const teamName = selectedGroup?.teamName || '';
    console.log({selectedGroup, teamName});
    navigationRef.navigate(
      'ConfirmModal',
      {
        titleText: `What do you want to name your group?`,
        titleBodyText:
          'Pick a fun and exciting name that will help your group stand out from the pack!',
        confirmText: 'Set Team Name',
        showPrompt: true,
        maxLength: 40,
        cancelText: 'Cancel',
        initialPromptText: teamName == "Your Team's Name" ? '' : teamName,
        handleConfirm: (newName, __, handleClose) => {
          newName &&
            handleClose &&
            onNameChangeSubmit(newName, handleClose, selectedGroup);
        },
      },
      'EventLeaderBoard 123451234',
    );
  };

  return (
    <View style={{flex: 1, width}}>
      <ScrollView
        style={{
          flex: 1,
          paddingTop: 10,
          width,
          paddingBottom: 0,
          marginBottom: 0,
        }}>
        <View style={{flex: 1}}>
          {!!groups &&
            groups
              .slice()
              .sort((a, b) => {
                const aScore = a?.score && a?.score > 0 ? 1 * a?.score : 0;
                const bScore = b?.score && b?.score > 0 ? 1 * b?.score : 0;
                if (aScore < bScore) {
                  return 1;
                }
                if (bScore < aScore) {
                  return -1;
                }
                return 0;
              })
              ?.map?.((group) => {
                console.log('LeaderboardItem', {group});

                if (
                  group.teamName.toLowerCase().includes('test') &&
                  !showDeletedTeams
                ) {
                  return;
                }

                let photoUrl = group?.groupPhoto;
                if (!!group.score && previousHigherScore != group.score) {
                  previousHigherScore = group.score;
                  place = place + 1;
                }

                let backgroundColor = constants?.color?.orange;
                if (place == 0) {
                  backgroundColor = constants?.color?.yellow;
                } else if (place == 1) {
                  backgroundColor = constants?.color?.gray2;
                } else if (place == 2) {
                  backgroundColor = constants?.color?.teal;
                }

                return (
                  <View
                    key={`${group.groupId}_leaderboard_item`}
                    style={{
                      marginLeft: 5,
                      width: width - 10,
                      marginBottom: 10,
                      height: 85,
                      backgroundColor,
                    }}>
                    <View
                      style={{
                        position: 'absolute',
                        left: 50,
                        paddingTop: 5,
                      }}>
                      <FlagButton
                        width={width - 70}
                        orientation={'left'}
                        height={60}
                        label=""
                      />
                    </View>
                    <View
                      style={{
                        position: 'absolute',
                        left: 100,
                        top: 4,
                        right: 30,
                      }}>
                      <Text
                        style={{
                          fontFamily: 'Alternate Gothic No3 D',
                          fontSize: 20,
                          color: 'white',
                        }}>
                        {helpers.ordinalSuffixOf(place == 0 ? 1 : place) +
                          ' Place'}
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Alternate Gothic No3 D',
                          fontSize: 17,
                          color: 'white',
                        }}>
                        {group.teamName || 'Team Name'}
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Alternate Gothic No3 D',
                          fontSize: 13,
                          color: 'white',
                        }}>
                        {'Score: ' + (group?.score || 0)}
                      </Text>
                    </View>
                    {!event.info?.teamPhotosOff && (
                      <TouchableOpacity
                        style={{
                          position: 'absolute',
                          left: 100,
                          top: 65,
                          right: 30,
                          flexDirection: 'row',
                        }}
                        onPress={() => {
                          setSelectedGroup(group);
                          setCurrentView('Photos');
                        }}>
                        <Icon
                          size={14}
                          name={'picture-o'}
                          color={constants?.color?.gray3}
                        />
                        <Text
                          style={{
                            fontFamily: 'Alternate Gothic No3 D',
                            fontSize: 14,
                            color: constants?.color?.gray3,
                            marginLeft: 3,
                            letterSpacing: -0.2,
                          }}>
                          {'PHOTOS'}
                        </Text>
                      </TouchableOpacity>
                    )}
                    {!event.info?.locateTeamsOff && (
                      <TouchableOpacity
                        style={{
                          position: 'absolute',
                          left: 235,
                          top: 65,
                          right: 30,
                          flexDirection: 'row',
                        }}
                        onPress={() => {
                          if (group?.position) {
                            setCurrentView('Map');
                            setSelectedGroup(group);
                          } else {
                            Alert.alert(
                              'No Location info exists for this group. Make sure they have started the hunt. This data on average updates every 5 minutes.',
                            );
                          }
                        }}>
                        <Icon
                          size={14}
                          name={'globe'}
                          color={constants?.color?.gray3}
                        />
                        <Text
                          style={{
                            fontFamily: 'Alternate Gothic No3 D',
                            fontSize: 14,
                            marginLeft: 3,
                            color: constants?.color?.gray3,
                            letterSpacing: -0.4,
                          }}>
                          {'Locate'}
                        </Text>
                      </TouchableOpacity>
                    )}
                    <TouchableOpacity
                      style={{
                        position: 'absolute',
                        left: 292,
                        top: 65,
                        right: 30,
                        flexDirection: 'row',
                      }}
                      onPress={() => {
                        Alert.alert('', 'Additional Options', [
                          {
                            text: 'Join Team',
                            onPress: async () => {
                              joinHunt(group?.groupId, userId, 'Joining Group');
                            },
                          },
                          {
                            text: 'Change Name',
                            onPress: () => {
                              setSelectedGroup(group);
                              showChangeName(group);
                            },
                          },
                          {
                            text: 'Delete Team',
                            onPress: async () => {
                              Alert.alert(
                                '',
                                "Are you sure you want to delete this team? This action can't be undone",
                                [
                                  {
                                    text: 'Cancel',
                                    onPress: () => {},
                                    style: 'cancel',
                                  },
                                  {
                                    text: 'Yes',
                                    onPress: () => {
                                      const groupId = group?.groupId || 'error';
                                      api_delete_user_or_group({
                                        id: groupId,
                                        type: 'group',
                                      });
                                    },
                                  },
                                ],
                                {cancelable: false},
                              );
                            },
                          },
                          {text: 'Cancel', onPress: () => {}},
                        ]);
                      }}>
                      <Icon
                        size={14}
                        name={'plus'}
                        color={constants?.color?.gray3}
                      />
                      <Text
                        style={{
                          fontFamily: 'Alternate Gothic No3 D',
                          fontSize: 14,
                          marginLeft: 3,
                          color: constants?.color?.gray3,
                          letterSpacing: -0.4,
                        }}>
                        {'More'}
                      </Text>
                    </TouchableOpacity>
                    <View
                      style={{position: 'absolute', left: 0, paddingTop: 5}}>
                      <UserProfileIcon
                        user={{info: {photoUrl: photoUrl || ''}}}
                        showName={false}
                        nameOverride={''}
                        isTeamIcon
                      />
                    </View>
                  </View>
                );
              })}
        </View>
        <View style={{height: 90}} />
      </ScrollView>
    </View>
  );
};

export default EventLeaderboard;

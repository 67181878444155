import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {View, ScrollView} from 'react-native';

////////////////////
//////////// utils

import isIphoneX from 'src/Utils/IphoneXHelper';
const isX = isIphoneX();

//////////////////////
///////// components

import ScavengerHuntInfoAbout from 'src/Registration/ScavengerHuntInfoAbout';

import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';
import CallSupportLine from 'src/HeaderComponent/CallSupportLine';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {useUserId} from 'src/Hooks/reduxHooks';

import {useUserTicketCount} from 'src/Hooks/reduxHooks';
import {askToCreateTeam, createHunt} from 'src/Utils/huntFunctions';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';

const foxIconWhite = require('src/Images/Icons/foxIconWhite.png');

const LandingPageScavengerHuntInfoModal: React.FC = () => {
  const currentHunt = useTypedSelector((state) => state.hunts.currentHunt);

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );
  const userId = useUserId();

  const userTicketCount = useUserTicketCount();
  const hasExplorerPass = !!useExplorerPass();
  console.log({userTicketCount});

  const handleStartPress = () => {
    if (!currentHunt) {
      return console.error('no current hunt');
    }
    // prompt to log in if not logged in
    if (!userId) {
      return navigationRef.navigate(
        'LoginModal',
        {
          message: 'You must log in to start a scavenger hunt',
        },
        'Registration/LandingPageScavengerHuntInfoModal.tsx',
      );
    }

    if (playTestState) {
      console.log('creating hunt in playtest');
      return createHunt(currentHunt, 'Standard', true, 'Test Team Name');
    }

    if (!userTicketCount && !hasExplorerPass) {
      return navigationRef.navigate(
        'PurchaseModal',
        {
          handleDone: () => {
            askToCreateTeam(currentHunt);
          },
          successMessage:
            'Success, your tickets have been added to your account. You can now create a group.',
        },
        'Registration/LandingPageScavengerHuntInfoModal.tsx',
      );
    }

    askToCreateTeam(currentHunt);
  };

  const handleJoinPress = () => {
    return userId
      ? navigationRef.navigate(
          'JoinHuntModal',
          {},
          'Registration/LandingPageScavengerHuntInfoModal.tsx',
        )
      : navigationRef.navigate(
          'LoginModal',
          {
            navigateOnCloseRoute: 'JoinHuntModal',
            message: 'You must log in to start a scavenger hunt',
          },
          'Registration/LandingPageScavengerHuntInfoModal.tsx',
        );
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}>
      <CallSupportLine />
      <ScrollView
        style={{
          flex: 1,
          paddingTop: 5,
          backgroundColor: constants?.color?.white1,
        }}>
        <ScavengerHuntInfoAbout
          handleStartPress={handleStartPress}
          handleJoinPress={handleJoinPress}
        />

        <View style={{height: 120}} />
      </ScrollView>
      <ModalOmniBar
        left={{
          icon: 'arrow-left-circle',
          handlePress: () => {
            navigationRef.navigate(
              'LandingPageMain',
              null,
              'LandingPageScavengerHuntInfoModal.tsx',
            );
          },
          text: 'Go Back',
        }}
        omniButton={{
          icon: foxIconWhite,
          iconLibrary: 'FontAwesome',
          color: constants?.color?.orange,
          handlePress: handleStartPress,
          text: 'Start Creating Group',
        }}
        right={{
          icon: 'people',
          handlePress: handleJoinPress,
          text: 'Join Friends Group',
        }}
        containerStyle={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
          marginBottom: isX ? 20 : 0,
        }}
      />
    </View>
  );
};

export default LandingPageScavengerHuntInfoModal;

import React from 'react';
import {View, ViewStyle} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import ExpoImage from 'src/Utils/ExpoImage';
import * as Device from 'expo-device';
import {apiCheckAnyChallenge} from 'src/Utils/apiCalls';
///////////////////////////
///////////// components
import {ContinueButton} from 'src/Modules/CommonView';

///////////////////////////
///////////// utils

//import photoImage from 'src/Images/photoChallengeImage.png';
import photoImage from 'src/Images/TeamPhoto.png';
import {useChallenge, useChallengeId, useLocationId} from 'src/Hooks/gameHooks';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {classicPhotoVideoCompletionEvent} from 'src/Utils/huntFunctions_classic';

interface PhotoVideoProps {
  onComplete?: () => void;
  hideShare?: boolean;
  hideCameraIcon?: boolean;
  containerStyle?: ViewStyle;
}

const PhotoVideo: React.FC<PhotoVideoProps> = ({
  onComplete,
  hideShare = false,
  hideCameraIcon,
  containerStyle = {},
}) => {
  const {width} = useWindowDimensionsSafe();
  const challenge = useChallenge();
  const challengeIdState = useChallengeId();
  const locationIdState = useLocationId();

  const handlePhotoComplete = async ({
    photoUrl,
    shareUUID,
  }: {
    photoUrl: string;
    shareUUID: string;
  }) => {
    if (!challenge) return console.error('no challenge');

    const challengeId = challenge?.challengeId || '';
    const locationId = challenge?.locationId || '';
    const question = challenge?.question || '';

    await apiCheckAnyChallenge({
      photo_url: photoUrl,
      share_UUID: shareUUID,
      challenge_id: challengeIdState || challengeId,
      location_id: locationIdState || locationId,
    });

    classicPhotoVideoCompletionEvent({
      photoUrl: photoUrl,
      challengeId,
      shareUUID,
      description: question,
    });

    onComplete && onComplete?.();
  };

  const handleVideoComplete = ({
    photoUrl,
    thumbnail,
    shareUUID,
  }: {
    photoUrl: string;
    thumbnail?: string;
    shareUUID: string;
  }) => {
    if (!challenge) return console.error('no challenge');

    const challengeId = challenge?.challengeId || '';
    const question = challenge?.question || '';

    classicPhotoVideoCompletionEvent({
      photoUrl: photoUrl,
      thumbnail: thumbnail,
      challengeId,
      shareUUID,
      description: question,
    });

    onComplete && onComplete?.();
  };

  if (!challenge) {
    console.error('no challenge');
    return <></>;
  }

  const {completions = {}} = challenge;
  let {type} = challenge;
  if (__DEV__ && !Device.isDevice) {
    type = 'photo';
  }
  const completedChallenge = Object.values(completions) || [];

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        ...containerStyle,
      }}>
      {!completedChallenge?.length && (
        <View style={{alignItems: 'center'}}>
          {!hideCameraIcon && (
            <ExpoImage
              style={{width: width - 20, height: 180, marginHorizontal: 10}}
              source={photoImage}
              resizeMode={'contain'}
            />
          )}
          <View style={{alignItems: 'center'}}>
            <ContinueButton
              text={`Take The ${type === 'video' ? 'Video' : 'Photo'}`}
              containerStyle={{width: 220, marginTop: hideCameraIcon ? 20 : 0}}
              handlePress={() => {
                navigationRef.navigate(
                  'PhotoVideoCamera',
                  {
                    onCapture: (data) => {
                      if (type === 'photo') {
                        handlePhotoComplete({
                          photoUrl: data?.downloadURL,
                          shareUUID: data?.shareUUID,
                        });
                      } else if (type === 'video') {
                        handleVideoComplete({
                          thumbnail: data?.thumbnail,
                          photoUrl: data?.downloadURL,
                          shareUUID: data?.shareUUID,
                        });
                      }
                    },
                    onReport: () => {
                      navigationRef.navigate(
                        'ReportModal',
                        null,
                        'ScavengerHunt_v2/Challenges/PhotoVideo.tsx',
                      );
                    },
                    hideShare,
                    text: challenge.question || 'Take a photo',
                    //isVideo: type == 'video' && !__DEV__,
                    isVideo: type == 'video',
                  },
                  'ScavengerHunt_v2/Challenges/PhotoVideo.tsx',
                );
              }}
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default PhotoVideo;

import constants from 'src/constants';
import React from 'react';
import {
  ImageSourcePropType,
  Platform,
  Text,
  TouchableHighlight,
  View,
} from 'react-native';
import ExpoImage from 'src/Utils/ExpoImage';

import FeatherIcon from 'react-native-vector-icons/Feather';
import Icon from 'react-native-vector-icons/FontAwesome';
import LineIcon from 'react-native-vector-icons/SimpleLineIcons';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

/// omniButton uses 'FeatherIcon' icons, rest of tab bars ALL use 'LineIcon' icons

import {BodyText} from 'src/Modules/CommonView/Text';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';

interface TabButtonProps {
  icon: string;
  text: string;
  handlePress?: () => void;
  badge?: number | string;
}

export const OmniBar: React.FC<{
  tabOne?: TabButtonProps;
  tabTwo?: TabButtonProps;
  omniButton?: {
    handlePress: () => void;
    loading?: boolean;
    color?: string;
    showActivity?: boolean;
    icon: string;
    iconLibrary?: string | null | undefined;
    iconStyle?: object;
    iconTextOverLay?: string | null | undefined;
    text: string;
    imageIcon?: string | null;
  };
  tabThree?: TabButtonProps;
  tabFour?: TabButtonProps;
  tabFive?: TabButtonProps;
  balanceLeft?: boolean;
  balanceRight?: boolean;
}> = ({
  tabOne,
  tabTwo,
  omniButton,
  tabThree,
  tabFour,
  tabFive,
  balanceLeft,
  balanceRight,
}) => {
  console.log({omniButton});
  const {width} = useWindowDimensionsSafe();

  return (
    <View
      style={{
        alignItems: 'center',
        height: Platform.OS == 'android' ? 80 : 65,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        position: 'relative',
        paddingTop: Platform.OS == 'android' ? 20 : 0,
      }}>
      <View
        style={{
          ...constants?.style.navigationHeader,
          elevation: 0,
          backgroundColor: constants?.color?.white,
          height: 50,
          width,
          position: 'absolute',
          bottom: 10,
          flexDirection: 'row',
          alignItems: 'flex-end',
          borderTopColor: 'black',
          borderTopWidth: Platform.OS == 'web' ? 1 : 0,
        }}>
        {!!balanceLeft && <View style={{flex: 1}} />}
        {!!tabOne && typeof tabOne.handlePress == 'function' && (
          <OmniBarButton
            text={tabOne.text}
            icon={tabOne.icon}
            handlePress={tabOne.handlePress}
            badge={tabOne.badge}
          />
        )}
        {!!tabTwo && typeof tabTwo.handlePress == 'function' && (
          <OmniBarButton
            text={tabTwo.text}
            icon={tabTwo.icon}
            handlePress={tabTwo.handlePress}
            badge={tabTwo.badge}
          />
        )}
        {!!omniButton && <View style={{flex: 1}} />}
        {!!tabThree && typeof tabThree.handlePress == 'function' && (
          <OmniBarButton
            text={tabThree?.text}
            icon={tabThree?.icon}
            handlePress={tabThree?.handlePress}
            badge={tabThree?.badge}
          />
        )}
        {!!tabFour && typeof tabFour.handlePress == 'function' && (
          <OmniBarButton
            text={tabFour.text}
            icon={tabFour.icon}
            handlePress={tabFour.handlePress}
            badge={tabFour.badge}
          />
        )}
        {!!tabFive && typeof tabFive.handlePress == 'function' && (
          <OmniBarButton
            text={tabFive.text}
            icon={tabFive.icon}
            handlePress={tabFive.handlePress}
            badge={tabFive.badge}
          />
        )}
        {!!balanceRight && <View style={{flex: 1}} />}
      </View>

      {!!omniButton && (
        <TouchableHighlight
          activeOpacity={0.8}
          underlayColor={'transparent'}
          onPress={omniButton.handlePress}
          disabled={!!omniButton.loading}
          testID={'omniButton'}
          style={{
            backgroundColor: 'transparent',
            position: 'absolute',
            left: width / 2 - 60,
            bottom: 10,
            borderRadius: 42,
            width: 120,
            height: 80,
            zIndex: 100,
          }}>
          <View
            style={{
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <TouchableHighlight
              activeOpacity={0.8}
              underlayColor={'transparent'}
              onPress={omniButton.handlePress}
              disabled={!!omniButton.loading}
              style={{}}>
              <View
                style={{
                  ...constants?.style.navigationHeader,
                  width: 64,
                  height: 64,
                  borderRadius: 32,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: omniButton.color || constants?.color?.gray3,
                }}>
                {(!!omniButton.showActivity || !!omniButton.loading) && (
                  <CustomActivityIndicator
                    size={'small'}
                    color={constants?.color?.white}
                  />
                )}
                {!(omniButton.showActivity || omniButton.loading) &&
                  (typeof omniButton.icon == 'string' &&
                  !omniButton.icon?.includes?.('static/media') ? (
                    <View>
                      {!omniButton.iconLibrary && (
                        <FeatherIcon
                          name={omniButton.icon}
                          size={32}
                          color={constants?.color?.white}
                        />
                      )}

                      {omniButton.iconLibrary == 'FontAwesome' && (
                        <Icon
                          name={omniButton.icon}
                          size={32}
                          color={constants?.color?.white}
                        />
                      )}
                    </View>
                  ) : (
                    <ExpoImage
                      style={{
                        width: Platform.OS == 'web' ? 32 : 40,
                        height: 40,
                        ...(omniButton.iconStyle || {}),
                      }}
                      resizeMode={'contain'}
                      source={omniButton.icon as ImageSourcePropType}
                    />
                  ))}
                {!!omniButton.iconTextOverLay && (
                  <BodyText
                    text={omniButton.iconTextOverLay}
                    textStyle={{
                      position: 'absolute',
                      marginTop: 26,
                      top: 16,
                    }}
                  />
                )}
              </View>
            </TouchableHighlight>
            <View style={{flex: 1}}>
              <Text
                style={{
                  color: constants?.color?.gray3,
                  fontFamily: constants?.font.circular,
                  fontWeight: '700',
                  fontSize: 10,
                  textAlign: 'center',
                  marginRight: 0,
                  width: 150,
                  marginTop: 0,
                  top: 2,
                }}>
                {omniButton.text}
              </Text>
            </View>
          </View>
        </TouchableHighlight>
      )}
    </View>
  );
};

interface OmniBarButtonProps {
  icon: string;
  text: string;
  handlePress: () => void;
  badge?: number | string;
}

const OmniBarButton: React.FC<OmniBarButtonProps> = ({
  icon,
  text,
  handlePress,
  badge,
}) => (
  <TouchableHighlight
    style={{
      alignItems: 'center',
      flex: 1,
      paddingTop: 0,
      borderRadius: 40,
      position: 'relative',
    }}
    underlayColor={constants?.color?.orange_30}
    activeOpacity={0.8}
    onPress={handlePress}>
    <View
      style={{
        alignItems: 'center',
        minWidth: 51,
      }}>
      {typeof icon == 'string' ? (
        <LineIcon name={icon} size={25} color={constants?.color?.gray3} />
      ) : (
        <ExpoImage
          style={{width: 30, height: 30}}
          resizeMode={'contain'}
          source={icon}
        />
      )}

      <Text
        style={{
          color: constants?.color?.gray3,
          fontFamily: constants?.font.circular,
          fontWeight: '700',
          fontSize: 10,
          marginTop: 7,
          textAlign: 'center',
        }}>
        {text}
      </Text>
      {!!badge && (
        <View
          style={{
            position: 'absolute',
            top: -5,
            right: -2,
            height: 14,
            width: 14,
            borderRadius: 7,
            backgroundColor: 'red',
          }}>
          <Text
            style={{
              color: 'white',
              fontSize: 10,
              textAlign: 'center',
              fontWeight: '600',
            }}>
            {badge}
          </Text>
        </View>
      )}
    </View>
  </TouchableHighlight>
);

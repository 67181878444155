import constants from 'src/constants';
import React from 'react';
import {View, Platform, Image, ImageSourcePropType} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {
  ImageButton,
  ContinueButton,
  FooterOverlay,
  BodyText,
} from 'src/Modules/CommonView';

import {apiUpdateUserBadges} from 'src/Utils/apiCalls';
import {socialShare} from 'src/Utils/shareHelper';

const facebookIcon: ImageSourcePropType = require('src/Images/Share/facebookShareIcon.png');
const instagramIcon: ImageSourcePropType = require('src/Images/Share/instagramShareIcon.png');
const otherIcon: ImageSourcePropType = require('src/Images/Share/otherShareIcon.png');

import {SafeAreaView} from 'react-native-safe-area-context';
import {navigationRef} from 'src/Nav/navigationHelpers';
import useParams from 'src/Hooks/useParams';
import useTypedSelector from 'src/Hooks/useTypedSelector';

const ShareModal: React.FC = () => {
  const {
    shareObject = {},
    scoreResponse = false,
    shareOrigin,
    huntType,
    shareOverride,
    showCorrectPoints = 0,
    showCorrectUnits = 'Points',
    shareFeedbackTextOverride,
    titleOverride,
  } = useParams<'ShareModal'>();
  const {width} = useWindowDimensionsSafe();

  const eventInfoData = useTypedSelector((state) => state?.event?.info);

  const handleShare = (targetMedia: string) => {
    if (shareOverride) {
      return shareOverride();
    }

    socialShare(shareObject, shareOrigin, huntType, targetMedia);
  };

  const showInstagramShare =
    shareObject.challengeType !== 'video' &&
    shareObject.challengeType !== 'Video' &&
    Platform.OS !== 'android' &&
    !eventInfoData?.hideInstaShare;

  const showFacebookShare = !eventInfoData?.hideFbShare;

  let shareFeedbackText = !!showCorrectPoints
    ? 'Share for a chance to win free scavenger hunts + other prizes!'
    : 'Share for a chance to win free scavenger hunts + other prizes!';

  if (scoreResponse && scoreResponse?.photo_grade) {
    shareFeedbackText =
      scoreResponse?.photo_grade?.feedback_body ||
      'Share for a chance to win free scavenger hunts + other prizes!';
  }

  return (
    <SafeAreaView style={{flex: 1, justifyContent: 'flex-end'}}>
      <View
        style={{
          width,
          backgroundColor: !!showCorrectPoints
            ? constants?.color?.blue
            : constants?.color?.blue,
          paddingVertical: 10,
          paddingHorizontal: 10,
          paddingBottom: 100,
        }}>
        {!!showCorrectPoints && (
          <Image
            source={require('src/Images/happyTrophy.png')}
            style={{
              height: 160,
              width: width - 0,
              resizeMode: 'contain',
              marginTop: 10,
              marginBottom: 20,
            }}
          />
        )}
        {!!showCorrectPoints && (
          <BodyText
            text={
              titleOverride ||
              'Congrats, You Earned ' +
                showCorrectPoints +
                ' ' +
                showCorrectUnits
            }
            center
            textStyle={{
              marginBottom: 5,
              marginRight: 5,
              color: 'white',
              width: width - 40,
              fontSize: 20,
              lineHeight: 20,
              fontWeight: '800',
            }}
          />
        )}
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <BodyText
            text={shareFeedbackTextOverride || shareFeedbackText}
            center
            textStyle={{
              marginBottom: 25,
              marginRight: 5,
              marginHorizontal: 0,
              color: 'white',
            }}
          />
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}>
          {!!showFacebookShare && (
            <ImageButton
              source={facebookIcon}
              buttonStyle={{width: 80, height: 80, alignItems: 'center'}}
              textStyle={{color: 'white', fontSize: 16, marginTop: 5}}
              handlePress={() => handleShare('Facebook')}
              text={'facebook'}
            />
          )}
          {!!showInstagramShare && (
            <ImageButton
              source={instagramIcon}
              buttonStyle={{width: 80, height: 80, alignItems: 'center'}}
              textStyle={{color: 'white', fontSize: 16, marginTop: 5}}
              handlePress={() => handleShare('Instagram')}
              text={'instagram'}
            />
          )}
          <ImageButton
            source={otherIcon}
            buttonStyle={{width: 80, height: 80, alignItems: 'center'}}
            textStyle={{color: 'white', fontSize: 16, marginTop: 5}}
            handlePress={() => handleShare('Other')}
            text={'other'}
          />
        </View>

        <FooterOverlay
          footerStyle={{
            backgroundColor: 'transparent',
            paddingBottom: 0,
            bottom: !!showCorrectPoints ? 20 : 30,
          }}>
          <ContinueButton
            handlePress={() => {
              try {
                apiUpdateUserBadges();
              } catch (error: unknown) {
                console.log('could not assign badges');
              }
              navigationRef?.goBack('Modules/ShareModal.tsx');
            }}
            background={constants?.color?.gray3}
            text={'Close'}
          />
        </FooterOverlay>
      </View>
    </SafeAreaView>
  );
};

export default ShareModal;

import 'react-native-get-random-values';
import {getDataAtPath, updateDataAtPath} from 'src/Utils/fireUtils';

// Redux store for dispatching actions
import {Alert} from 'react-native';
import {schedulePushNotificationForUser} from 'src/Utils/apiCalls';
import {TGroupInfo} from 'src/types/TGroupInfo';
import {getReduxState} from './helpers';
import {TRequestInvite} from 'src/types/TUser';

interface Request {
  received?: boolean;
  accepted?: boolean;
  groupId?: string;
  createdByName?: string;
  createdBy?: string;
  huntName?: string;
  huntPhoto?: string | null;
  path: string;
  joinCode?: string | null;
  type?: string;
  autoAccept?: boolean;
  askedByName?: string;
}

// Player who has a group invites someone to it
// This can also be an auto-response

function convertRequestToJSONValue(data: Request): TJSONValue | undefined {
  return data ? JSON.parse(JSON.stringify(data)) : undefined;
}
export const createHuntInvite = async (
  userId: string,
  groupInfo: TRequestInvite,
  joinCode: string,
  autoAccept: boolean = false,
) => {
  const path = `users/${userId || 'error'}/requests/${
    groupInfo?.groupId || 'error'
  }`;

  const oldRequest = (await getDataAtPath(path)) as Request | null;

  if (!autoAccept && oldRequest && !oldRequest?.accepted) {
    return Alert.alert(
      'You have already sent an invite to this player.',
      `The player will join automatically once they accept. They can also join by typing the invite code: ${joinCode}`,
    );
  }

  const data: Request = {
    groupId: groupInfo?.groupId || undefined,
    createdByName: groupInfo?.createdByName || 'User',
    createdBy: groupInfo?.createdBy || 'User',
    huntName: groupInfo?.huntName || undefined,
    huntPhoto: groupInfo?.huntPhoto || null,
    path,
    accepted: false,
    joinCode: autoAccept ? groupInfo?.createdBy : joinCode,
    type: 'ask_to_join_my_hunt',
    autoAccept,
  };

  if (autoAccept || oldRequest?.accepted) {
    data['received'] = false;
  }

  console.log({path, data});
  updateDataAtPath(path, convertRequestToJSONValue(data));

  if (!autoAccept) {
    schedulePushNotificationForUser({
      title: `${groupInfo?.createdByName} Invited You To Play On Their ${groupInfo?.huntName} Scavenger Hunt`,
      body: `Confirm if you want to join this hunt. Only join the hunts of friends and family, and not people that you don't know! If you do not want to receive requests like this, in the future, go to your profile and turn off discoverable.`,
      topic: userId || 'error',
    });
  }
};

// Player asks a group to join
export const askForHuntInvite = async (
  groupInfo: TGroupInfo,
  joinCode: string,
) => {
  const userId = getReduxState((state) => state?.user?.userId);
  const firstName =
    getReduxState((state) => state?.user?.info?.firstName) || '';
  const lastName = getReduxState((state) => state?.user?.info?.lastName) || '';

  const path = `users/${groupInfo?.createdBy || 'error'}/requests/${
    userId || 'error'
  }`;

  const oldRequest = (await getDataAtPath(path)) as Request | null;

  if (oldRequest && !oldRequest?.accepted) {
    return Alert.alert(
      'You have already sent an invite to this player.',
      `You will join automatically when they accept. Or you can join by scanning their QR code or entering their join code.`,
    );
  }

  let askedByName = 'User';

  if (
    firstName &&
    !firstName?.toLowerCase().includes('user') &&
    lastName &&
    !lastName?.toLowerCase().includes('user')
  ) {
    askedByName = `${firstName} ${lastName.substring(0, 1)}`;
  }

  const data: Request = {
    groupId: groupInfo?.groupId,
    createdByName: groupInfo?.createdBy || 'User',
    createdBy: userId || '',
    huntName: groupInfo?.huntName,
    huntPhoto: groupInfo?.huntPhoto,
    path,
    accepted: false,
    joinCode,
    type: 'ask_for_invite_to_their_hunt',
  };

  if (oldRequest?.accepted) {
    data['received'] = false;
  }

  console.log('askForHuntInvite', {path, data});
  updateDataAtPath(path, convertRequestToJSONValue(data));

  schedulePushNotificationForUser({
    title: `${groupInfo?.createdByName} Asked To Join Your Scavenger Hunt`,
    body: `Confirm if you want ${askedByName} to join your hunt. If you do not want to receive requests like this, in the future, go to the game lobby and turn off discoverable.`,
    topic: groupInfo?.createdBy || 'error',
  });
};

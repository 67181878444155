import {useState, useEffect} from 'react';
// import {useWindowDimensions} from 'react-native';

const useWindowDimensionsSafe = () => {
  const [width, setWidth] = useState<number>(Math.max(window.innerWidth, 350));
  const [height, setHeight] = useState<number>(
    Math.max(window.innerHeight, 750),
  );
  // app wide height & width listener

  const setWidthSafe = (newWidth: number) => {
    setWidth(Math.max(350, newWidth));
  };
  const setHeightSafe = (newHeight: number) => {
    setHeight(Math.max(750, newHeight));
  };

  useEffect(() => {
    setWidthSafe(document.documentElement.clientWidth);
    setHeightSafe(document.documentElement.clientHeight);
    window.addEventListener('resize', () => {
      console.log('resize event happening');
      setWidthSafe(document.documentElement.clientWidth);
      setHeightSafe(document.documentElement.clientHeight);
    });
  }, []);

  // console.log('useWindowDimensionsSafe', {width, height});

  return {width, height};
};

export default useWindowDimensionsSafe;

import constants from 'src/constants';
import React from 'react';
import {View, ScrollView, TouchableOpacity, Image, Text} from 'react-native';

import {BodyText, ContinueButton} from 'src/Modules/CommonView';
import StylizedBox from 'src/Assistant/StylizedBox';
import {navigationRef} from 'src/Nav/navigationHelpers';

import {useUserId} from 'src/Hooks/reduxHooks';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';
import {BulletItem} from 'src/ExplorerPass/AboutExplorerPass';
import getAppType from 'src/Utils/getAppType';
import PARequest from './PARequest';
import {PADataUpdater} from './PADataUpdater';
import ExpoImage from 'src/Utils/ExpoImage';
const warningIcon = require('src/Images/Icons/warning-error.png');

export default function PersonalAssistantTab() {
  console.log('PersonalAssistantTab render');

  const buoy = require('src/Images/Icons/lifebuoy_2.png');

  const userRequests = useTypedSelector((state) => state.chats?.paChats);

  const explorerPass = useExplorerPass();

  const userId = useUserId();

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const isLRApp = getAppType() === 'lr';

  return (
    <ScrollView style={{backgroundColor: isLRApp ? 'transparent' : '#F9F7F2'}}>
      <StylizedBox
        showChatIcon={false}
        containerStyles={{
          paddingHorizontal: 0,
          height: 220,
          backgroundColor: 'red',
          marginTop: 0,
        }}
        backgroundColor={isLRApp ? '#6AAEAA' : '#EACD72'}
        backgroundContainerStyles={{
          marginTop: 0,
          height: '100%',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
        textStyles={{
          color: isLRApp ? 'white' : 'black',
        }}
        ctaText="Ask my personal assistant"
        title="Get answers and advice fast"
        description={
          'From booking dinner to suggesting new things to do, everything you need is one request away.'
        }
        onPress={() => {
          if (!explorerPass || !userId) {
            return navigationRef.navigate(
              'FreeTrialScreen',
              null,
              'ProfilePage.tsx',
            );
          }
          navigationRef.navigate('PASubmitChat', null, 'PAChat');
        }}
      />
      {!!userRequests && userRequests.length > 0 && (
        <View style={{paddingHorizontal: 24}}>
          <BodyText
            text={isLRApp ? 'My requests' : 'Your most recent requests'}
            textStyle={{
              color: 'black',
              marginTop: 30,
              marginLeft: 0,
              fontSize: 17,
              fontFamily: 'JakartaBold',
            }}
          />
          <View style={{marginTop: 10}}>
            {userRequests.length > 0 &&
              userRequests.map((request,ix) => (
                (isLRApp || (!isLRApp && ix < 2)) && 
                <PARequest key={request.UUID} request={request} />
              ))}
          </View>
        </View>
      )}
      {!userRequests ||
        (userRequests?.length == 0 && (
          <>
            <View style={{flexDirection: 'row', paddingHorizontal: 20}}>
              <View>
                <BodyText
                  text={`What can your ${
                    isLRApp ? "Let's Roam+ " : ''
                  }personal assistant do?`}
                  textStyle={{
                    marginLeft: 0,
                    fontWeight: '700',
                    fontFamily: 'JakartaBold',
                    marginBottom: 10,
                  }}
                />
                <BulletItem
                  text={
                    'Give advice on things to do in your city within 1 hour'
                  }
                />
                <BulletItem
                  text={
                    'Help make restaurant reservations and book other activities'
                  }
                />
                <BulletItem text={'Pretty much anything you want!'} />
              </View>
            </View>
            {!explorerPass && isLRApp && (
              <ContinueButton
                containerStyle={{
                  paddingVertical: 16,
                  alignItems: 'center',
                  gap: 8,
                  marginHorizontal: 0,
                  marginVertical: 0,
                  marginTop: 40,
                  marginLeft: 10,
                  marginRight: 10,
                }}
                height={57}
                handlePress={() =>
                  navigationRef.navigate(
                    'FreeTrialScreen',
                    null,
                    'ProfilePage.tsx',
                  )
                }
                iconImage={require('src/Images/Icons/chat_icon.png')}
                iconImageStyle={{
                  height: 30,
                  width: 30,
                  marginLeft: 0,
                  marginRight: 0,
                }}
                text={'Upgrade to unlock personal assistant'}
                textStyle={{fontSize: 15}}></ContinueButton>
            )}
          </>
        ))}
        {!isLRApp && <View
        style={{
          gap: 20,
          flexDirection: 'row',
          marginHorizontal: 20,
          marginTop: 20,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <ExpoImage
          source={warningIcon}
          style={{height: 50, width: 50}}
          resizeMode="contain"
        />
        <Text
          style={{fontSize: 15, fontFamily: 'Jakarta', textAlign: 'center'}}>
          {'Hit a snag with our app?\nCall our support team instead.'}
        </Text>
      </View>}
      {!playTestState && !isLRApp && (
        <View style={{width: '100%', paddingLeft: 10, paddingRight: 10}}>
          <TouchableOpacity
            style={{
              backgroundColor: 'black',
              borderRadius: 8,
              marginVertical: 20,
              marginHorizontal: 12,
              alignItems: 'center',
              justifyContent: 'center',
              paddingVertical: 40,
              paddingHorizontal: 40,
              shadowColor: '#000',
              shadowOffset: {width: 0, height: 1},
              shadowOpacity: 0.6,
              shadowRadius: 3,
            }}>
            <>
              <Image
                source={buoy}
                style={{width: 36, height: 36, resizeMode: 'contain'}}
              />
              <View style={{display: 'flex', flexDirection: 'row'}}>
                <BodyText
                  text={'Call us at'}
                  textStyle={{
                    color: 'white',
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
                <BodyText
                  text={'1-833-202-7626'}
                  textStyle={{
                    color: '#EACD72',
                    fontSize: 17,
                    fontFamily: 'JakartaBold',
                    marginLeft: 5,
                    marginRight: 0,
                  }}
                />
              </View>
              <BodyText
                text={'for live support'}
                textStyle={{
                  color: constants.color.white,
                  fontSize: 17,
                  fontFamily: 'JakartaBold',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 3,
                }}
              />
              <BodyText
                text={'Our friendly agents are standing by.'}
                textStyle={{
                  color: constants.color.white,
                  fontSize: 12,
                  fontFamily: 'Jakarta',
                  marginLeft: 0,
                  marginRight: 0,
                  marginTop: 12,
                }}
              />
              <ContinueButton
                text={'Call support'}
                height={50}
                background={'#EACD72'}
                textStyle={{
                  fontFamily: 'JakartaBold',
                  textAlign: 'center',
                  flex: 1,
                  color: constants?.color?.black,
                }}
                containerStyle={{
                  marginTop: 24,
                  borderRadius: 6,
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  shadowColor: '#000',
                  shadowOffset: {width: 0, height: 1},
                  shadowOpacity: 0.6,
                  shadowRadius: 3,
                }}
                buttonStyle={{
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                handlePress={() => {
                  navigationRef.navigate(
                    'SupportModal',
                    {},
                    'Nav/DrawerMenu.tsx',
                  );
                }}
              />
            </>
          </TouchableOpacity>
        </View>
      )}
      {!!userId && <PADataUpdater userId={userId} />}
    </ScrollView>
  );
};
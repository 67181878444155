import useTypedSelector from 'src/Hooks/useTypedSelector';

import React, {useEffect} from 'react';
import {Platform, View} from 'react-native';

import {setCurrentGroupId} from 'src/Redux/reducers/user_info.reducer';
import {parsePlayerChallengeData} from 'src/Utils/dataHelper';

import {setLocationChallengeData} from 'src/Redux/reducers/game_v2.reducer';
import {updatePlayerChallengeData} from 'src/Redux/reducers/playerChallenges.reducer';
import {setGroupInfo} from 'src/Redux/reducers/groupInfo.reducer';
import {dispatchAction} from 'src/Utils/helpers';
import {useFirebaseData} from 'src/Utils/fireUtils';

import {isTGroupInfo} from 'src/types/TGroupInfo';

const BackHandler =
  Platform.OS === 'android' ? require('react-native').BackHandler : null;

const MainGameDataUpdater: React.FC = () => {
  console.log('MainGameDataUpdater tab rerender');

  const userId = useTypedSelector((state) => state.user?.userId);
  const userGroupId = useTypedSelector(
    (state) => state.user?.info?.currentHuntInfo?.groupId,
  );

  const handleBackButton = () => {
    return true;
  };

  // adding handler for screen effects like hiding the keyboard
  useEffect(() => {
    // back press
    // @ts-ignore its fine
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let backHandler: any;
    if (BackHandler && Platform.OS == 'android') {
      try {
        backHandler = BackHandler?.addEventListener?.(
          'hardwareBackPress',
          handleBackButton,
        );
      } catch (e) {
        console.log('could not add back handler');
      }
    }

    return () => {
      backHandler?.remove?.();
    };
  }, []);

  const locationChallengeData =
    useFirebaseData(
      `scavengerhunt/completions/${userGroupId}/location_v2`,
      null,
      null, // no order by
      null, // no order by
      false, // show logs
      !userGroupId, // hide if no group id
    ) || {};

  useEffect(() => {
    console.log('location challenge data update', {
      locationChallengeDataKeys: Object.keys(locationChallengeData || {}),
    });
    locationChallengeData &&
      dispatchAction(setLocationChallengeData(locationChallengeData));
  }, [locationChallengeData]);

  useFirebaseData(
    `scavengerhunt/groups/${userGroupId}`,
    async (groupData = {}) => {
      console.log('settingGroupPhotos update');

      // soft type
      isTGroupInfo(groupData, true);

      dispatchAction(setGroupInfo(groupData));
      dispatchAction(setCurrentGroupId(groupData?.groupId));
    },
    null, // no order by
    null, // no order by
    false, // show logs
    !userGroupId, // hide if no group id
  );

  useFirebaseData(
    `scavengerhunt/completions/${userGroupId}/player`,
    async (playerChallengesData = {}) => {
      console.log('playerChallengesData update');
      const parsedData = parsePlayerChallengeData(playerChallengesData);
      // @ts-ignore
      dispatchAction(updatePlayerChallengeData(parsedData));
    },
    'parentUserId', // order by parent user id
    userId, // equal to my user id
    false, // show logs
    !userGroupId, // hide if no group id
  );

  console.log('hunt tab navigator deciding to render');

  return <View />;
};

export default MainGameDataUpdater;

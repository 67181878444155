import React from 'react';

import {View, Text, TouchableOpacity, Alert} from 'react-native';
import Timer from 'src/ScavengerHunt_v2/Game/Timer';
import {setHuntTimerFinish} from 'src/Utils/huntFunctions_classic';
import constants from 'src/constants';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {usePercentageComplete} from 'src/Hooks/gameHooks';

interface ClassicHuntHeaderTimerProps {
  handlePress: () => void;
  disabled?: boolean;
  style?: object;
}

const ClassicHuntHeaderTimer: React.FC<ClassicHuntHeaderTimerProps> = ({
  handlePress,
  disabled,
  style = {},
}) => {
  const currentScreen = useTypedSelector(
    (state) => state.current_screen?.currentScreen,
  );

  const timerLimitMinutes = useTypedSelector(
    (state) => state.game_v2?.timerLimitMinutes,
  );
  const timerStart = useTypedSelector((state) => state.game_v2?.timerStart);
  const timerFinish = useTypedSelector((state) => state.game_v2?.timerFinish);
  console.log('InHuntDisplay Timer', {
    timerStart,
    timerFinish,
    timerLimitMinutes,
  });
  const score = useTypedSelector((state) => state.group?.info?.score) || 0;
  const isBarHunt =
    useTypedSelector((state) => state.group?.info?.isBarHunt) || false;
  const huntPaused = useTypedSelector((state) => state.group?.info?.huntPaused);

  const completionPercent = usePercentageComplete();

  if (!timerLimitMinutes) {
    return <></>;
  }

  return (
    <View style={{flexDirection: 'row', alignItems: 'center', ...style}}>
      <TouchableOpacity
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        activeOpacity={0.8}
        onPress={handlePress}
        disabled={!!disabled}>
        {!!timerStart &&
          !timerFinish &&
          !huntPaused &&
          currentScreen !== 'HuntFinished' && (
            <TouchableOpacity
              activeOpacity={0.9}
              onPress={() => {
                completionPercent === 100
                  ? Alert.alert(
                      `Great job! Your group has completed all of the hunt challenges`,
                      `Do you want to end the hunt?`,
                      [
                        {
                          text: 'End Hunt',
                          onPress: () => {
                            Alert.alert(
                              'Are you sure?',
                              '',

                              [
                                {
                                  text: 'Yes',
                                  onPress: () => {
                                    setHuntTimerFinish();
                                  },
                                },
                                {
                                  text: 'Cancel',
                                  onPress: () => console.log('Cancel Pressed'),
                                  style: 'cancel',
                                },
                              ],
                            );
                          },
                        },
                        {
                          text: 'Cancel',
                          onPress: () => console.log('Cancel Pressed'),
                          style: 'cancel',
                        },
                      ],
                    )
                  : Alert.alert(
                      'Do you want to end your hunt early?',
                      'This will end the hunt for all members of your group!',

                      [
                        {
                          text: 'End Hunt',
                          onPress: () => {
                            Alert.alert(
                              'Are you sure?',
                              '',

                              [
                                {
                                  text: 'Yes',
                                  onPress: () => {
                                    setHuntTimerFinish();
                                  },
                                },
                                {
                                  text: 'Cancel',
                                  onPress: () => console.log('Cancel Pressed'),
                                  style: 'cancel',
                                },
                              ],
                            );
                          },
                        },
                        {
                          text: 'Cancel',
                          onPress: () => console.log('Cancel Pressed'),
                          style: 'cancel',
                        },
                      ],
                    );
              }}>
              <Timer
                seconds={timerLimitMinutes * 60}
                startTime={timerStart}
                containerStyle={{
                  backgroundColor:
                    Date?.now?.() >
                    timerStart + (timerLimitMinutes - 15) * 60 * 1000
                      ? constants?.color?.yellow
                      : 'transparent',
                  maxWidth: 100,
                  borderRadius: 10,
                  marginRight: 0,
                }}
                textStyle={{marginLeft: 5, color: constants?.color?.white}}
                countUp={false}
                onComplete={() => {
                  Alert.alert(`Time's Up!`);
                  setHuntTimerFinish();
                }}
                badge={completionPercent == 100 ? '1' : undefined}
                highlightTimeLimit={10 * 60}
              />
            </TouchableOpacity>
          )}
        {!timerStart && (
          <View
            style={{
              backgroundColor: constants?.color?.orange,
              marginRight: 5,
              paddingVertical: 5,
              paddingHorizontal: 0,
            }}>
            <Text
              style={{
                color: constants?.color?.white,
                fontFamily: 'Jakarta',
              }}>
              {isBarHunt ? 'See Rank' : `Score: ${score?.toLocaleString?.()}`}
            </Text>
          </View>
        )}
      </TouchableOpacity>
    </View>
  );
};

export default ClassicHuntHeaderTimer;

import * as t from 'io-ts';
import {validateResult} from 'src/types/typesHelpers';

const TRegionIO = t.type({
  latitude: t.number,
  longitude: t.number,
  latitudeDelta: t.number,
  longitudeDelta: t.number,
});

// io-ts type for Location
export const TLocationIO = t.intersection([
  t.type({
    locationId: t.union([t.string, t.null]),
    lat: t.number,
    long: t.number,
  }),
  t.partial({
    name: t.string,
    locationIndex: t.union([t.number, t.null]),
    address: t.string,
    photoLarge: t.string,
    photoThumb: t.string,
    isTotalComplete: t.boolean,
    isLocal: t.boolean,
    distanceAwayBonusPoints: t.union([t.number, t.null]),
    // used for hybrid events
    location_type: t.union([
      t.literal('hunt_location'), // standard hunt location with challenges
      t.literal('intros'), // intro for characters
      t.literal('cut_scene'), // cut scene location
      t.literal('challenges'), // challenges for scene
    ]),
    groupPointsEarned: t.union([t.number, t.null]),
    originalLocation: t.string,
    challengeList: t.array(t.string),
    postContent: t.union([t.string, t.null]),
    challengeCount: t.union([t.number, t.null]),
    required_checkout_location: t.union([t.boolean, t.null]),
    region: t.union([TRegionIO, t.null]),
    accuracy: t.union([t.number, t.null]),
    description: t.union([t.string, t.null]),
    locationAccuracy: t.union([t.number, t.null]),
    checkInOverride: t.union([t.null, t.Function]),
    totalLocationPoints: t.union([t.number, t.null]),
    points: t.union([t.number, t.null]),
    completeTime: t.union([t.number, t.null]),
    distanceAway: t.union([t.number, t.null]),
    backupLocationForLocation: t.union([t.string, t.null]),
    locationClosed: t.union([t.boolean, t.null]),
    startTime: t.union([t.string, t.null]),
    locationChallengesCompleteTime: t.union([t.string, t.null]),
  }),
]);

// Derive the TypeScript type from the io-ts type
export type TLocation = t.TypeOf<typeof TLocationIO>;

// Type guard for runtime validation
export const isTLocation = (input: unknown): input is TLocation => {
  return validateResult(input, TLocationIO, 'TLocation', false);
};

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState, useRef, useEffect} from 'react';

import {
  View,
  Text,
  TextInput,
  ScrollView,
  KeyboardAvoidingView,
  Keyboard,
  Platform,
} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {TouchableOpacity} from 'react-native-gesture-handler';
import ModalSelector from 'react-native-modal-selector';
import {useUserId} from 'src/Hooks/reduxHooks';

import {setDataAtPath, updateDataAtPath} from 'src/Utils/fireUtils';
import Icon from 'react-native-vector-icons/FontAwesome5';
import SimpleUserIcon from 'src/HeaderComponent/SimpleUserIcon';
import {apiSendEventPushNotification} from 'src/Utils/apiCalls';
import {THostMessage} from 'src/types/THostMessage';

interface ChatBoxProps {
  displayInline?: boolean;
  messageData: {[key: string]: THostMessage};
  hostOnline?: boolean;
}

export default function ChatBox({
  displayInline = true,
  messageData = {},
  hostOnline = false,
}: ChatBoxProps) {
  const userId = useUserId();
  const eventId = useTypedSelector((state) => state.event?.eventId);
  const {width} = useWindowDimensionsSafe();

  const firstName = useTypedSelector(
    (state) => state.user?.info?.firstName || 'test',
  );

  const lastName = useTypedSelector(
    (state) => state.user?.info?.lastName || '',
  );

  const email = useTypedSelector(
    (state) => state.user?.info?.email || state.user?.info?.contactEmail || '',
  );

  const phone = useTypedSelector(
    (state) => state.user?.info?.contactPhone || state.user?.info?.contactPhoneNonLogin || '',
  );

  const userPhoto = useTypedSelector(
    (state) => state.user?.info?.customPhotoUrl || state.user?.info?.photoUrl,
  );

  const teamName = useTypedSelector(
    (state) => state.group?.info?.teamName || '',
  );

  const chatOptions = [
    {key: 0, section: true, label: 'Who Do You Want To Message?'},
    {key: 1, label: 'Everyone', value: 'Everyone'},
    {key: 2, label: 'Your Host', value: 'host'},
  ];

  const [selectedChatOption, setSelectedChatOption] =
    useState<string>('Everyone');
  const [typedText, setTypedText] = useState<string>('');

  const sendMessage = async () => {
    if (!typedText) {
      return console.log('no text');
    }
    const now = Date.now();
    const data = {
      sentAt: now,
      from: userId,
      fromName: firstName,
      fromFullName: firstName + lastName ? ' ' + lastName : '',
      fromEmail: email,
      fromPhone: phone,
      fromImage: userPhoto,
      to: selectedChatOption,
      message: typedText,
      fromUserGroup: teamName,
    };
    const newPath = `event/${eventId || 'error'}/chats/${now || 'error'}`;
    console.log('setting new message', {data, newPath});

    updateDataAtPath(`${newPath || 'error'}`, data);

    if (selectedChatOption == 'Everyone') {
      eventId &&
        apiSendEventPushNotification({
          topic: eventId,
          title: `${firstName} Sent Everyone A Message`,
          body: typedText,
        });
    } else {
      apiSendEventPushNotification({
        topic: selectedChatOption,
        title: `${firstName} Sent You A Message`,
        body: typedText,
      });
    }
    setTypedText('');
  };

  let previousTo: string | null = '';
  let previousFrom: string | null = '';
  let countOfSameSenders = 0;

  const selectorRef = useRef<ModalSelector | null>(null);

  const scrollRef = useRef<ScrollView | null>(null);

  const filteredChats = Object.values(messageData || {})?.filter(msg => msg?.to == 'Everyone' || msg?.to == userId || msg?.from == userId)?.sort((a, b) => {
    return (a.sentAt || 0) > (b.sentAt || 0) ? 1 : -1;
  });


  useEffect(()=>{
    console.log('testttt');
  },[filteredChats.length]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{
        backgroundColor: 'white',
        flex: 1,
        marginLeft: 7,
        marginRight: 7,
        borderRadius: 30,
        zIndex: 10000,
        boxShadow:
          '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        ...(displayInline
          ? {
              position: 'absolute',
              left: -10,
              bottom: 90,
              right: -10,
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }
          : {}),
      }}>
      {!!__DEV__ && (
        <TouchableOpacity
          testID="deleteChats"
          onPress={() => {
            const newPath = `event/${eventId || 'error'}/chats/`;
            setDataAtPath(`${newPath || 'error'}`, null);
          }}>
          <View
            style={{
              width: 20,
              height: 20,
              backgroundColor: 'red',
            }}></View>
        </TouchableOpacity>
      )}
      <View style={{flex: 1, padding: 20, position: 'relative'}}>
        <Text
          style={{
            textAlign: 'left',
            fontSize: 24,
            fontFamily: constants?.font.circularBold,
            fontWeight: 'bold',
            marginBottom: 20,
            color: hostOnline ? 'white' : constants?.color?.gray3,
          }}>
          Live Chat
        </Text>
        <ScrollView
          style={{display: 'flex', flex: 1, maxHeight: 200}}
          ref={scrollRef}
          onContentSizeChange={() => {
            console.log('scrolling to end');
            scrollRef?.current?.scrollToEnd?.({animated: true});
          }}>
          {filteredChats?.map?.((chatItem, index) => {
            const {from, to, fromName = 'User', fromImage, sentAt} = chatItem;
            let {message} = chatItem;
            message = message?.trim?.() || '';
            if (message) {
              message = message?.split?.(`\n`)?.join?.(' ');
            }
            if (!message || !message?.split(` `)?.join(' ')) {
              return;
            }

            let sameAsPrevious = previousTo === to && previousFrom === from;
            if (sameAsPrevious) {
              countOfSameSenders++;
              if (countOfSameSenders > 15) {
                sameAsPrevious = false;
                countOfSameSenders = 0;
              }
            }
            previousFrom = from;
            previousTo = to;

            return (
              <View
                key={String(
                  (chatItem?.message || '') + chatItem?.sentAt + index,
                )}
                style={{
                  marginTop: sameAsPrevious ? -5 : 20,
                  flexDirection: 'column',
                  width,
                }}>
                {!sameAsPrevious && (
                  <View
                    testID={to == 'host' ? 'toHostTag' : 'toEveryone'}
                    style={{
                      width: width - 40,
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                    }}>
                    <SimpleUserIcon
                      userPhoto={fromImage}
                      userName={fromName || 'User'}
                    />
                    <Text
                      style={{
                        color: hostOnline ? 'white' : constants?.color?.gray3,
                        fontSize: 12,
                        lineHeight: 30,
                        marginLeft: 20,
                        fontFamily: constants?.font?.circularBold,
                      }}>
                      {fromName} {to == 'host' && <Text>to Host</Text>}{to == userId && <Text>to You</Text>}
                    </Text>
                  </View>
                )}

                {!!sameAsPrevious && (
                  <View style={{width: 100, height: 10}}></View>
                )}
                {/* ts-ignore */}
                <Text
                  testID={message || 'error'}
                  style={{
                    fontSize: 14,
                    fontFamily: constants?.font?.circularBold,
                    color: hostOnline ? 'white' : constants?.color?.gray3,
                    lineHeight: 18,
                    width: width - 60,
                  }}>
                  {`${message}`}{' '}
                  {from == userId && (
                    <TouchableOpacity
                      onPress={() => {
                        const newPath = `event/${eventId || 'error'}/chats/${
                          sentAt || 'error'
                        }`;
                        setDataAtPath(`${newPath || 'error'}`, null);
                      }}
                      style={{
                        height: 16,
                        borderRadius: 8,

                        width: 16,
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Text
                        testID={message + '-delete'}
                        style={{
                          color: hostOnline ? 'white' : constants?.color?.gray3,
                          lineHeight: 12,
                          fontSize: 10,
                        }}>
                        x
                      </Text>
                    </TouchableOpacity>
                  )}
                </Text>
              </View>
            );
          })}
        </ScrollView>
      </View>
      <View
        style={{
          borderTopColor: constants?.color.gray1,
          borderTopWidth: 1,
          borderStyle: 'solid',
          padding: 10,
        }}>
        <View style={{display: 'flex', flexDirection: 'row'}}>
          <Text
            style={{
              color: constants?.color.gray2,
              fontSize: 20,
              fontFamily: constants?.font.circularBold,
            }}>
            To:{' '}
          </Text>
          <ModalSelector
            id="video-input"
            style={{flex: 1, height: 22}}
            ref={selectorRef}
            onChange={(newSelectedChatOption) => {
              console.log('changing selected chat option', {
                newSelectedChatOption,
              });
              setSelectedChatOption(newSelectedChatOption?.value);
            }}
            customSelector={
              <TouchableOpacity
                testID="chatToSelector"
                onPress={() => {
                  selectorRef.current?.open?.();
                }}>
                <Text
                  style={{
                    textDecorationLine: 'underline',
                    color: hostOnline ? 'white' : constants?.color?.gray3,
                    fontSize: 20,
                    fontFamily: constants?.font.circularBold,
                  }}>
                  {selectedChatOption
                    ?.replace('host', 'Your Host')
                    .replace('everyone', 'Everyone')}
                </Text>
              </TouchableOpacity>
            }
            data={chatOptions}
            overlayStyle={{justifyContent: 'flex-end'}}
          />
        </View>

        <View
          style={{
            backgroundColor: constants?.color.white_30,
            padding: 0,
            overflow: 'hidden',
            borderRadius: 20,
            borderColor: hostOnline ? 'white' : constants?.color?.gray3,
            display: 'flex',
            flexDirection: 'row',
            marginTop: 10,
          }}>
          <TextInput
            style={{
              flex: 1,
              height: 40,
              color: hostOnline ? 'white' : constants?.color?.gray3,
              width: 300,
              marginLeft: 20,
              fontSize: 16,
            }}
            testID="chatInput"
            allowFontScaling={false}
            value={typedText}
            multiline
            onSubmitEditing={() => {
              sendMessage();
              Keyboard.dismiss();
            }}
            onChangeText={(text) => setTypedText(text)}
            placeholder={`Write a Message to ${selectedChatOption
              ?.replace('host', 'your host')
              .replace('everyone', 'everyone')} here`}
            placeholderTextColor={
              hostOnline ? 'white' : constants?.color?.gray3
            }
          />
          <TouchableOpacity
            testID="sendMessage"
            onPress={() => {
              sendMessage();
              Keyboard.dismiss();
            }}>
            <View
              style={{
                width: 40,
                height: 40,
                backgroundColor: constants?.color?.orange,
                borderRadius: 20,
                alignItems: 'center',
                alignContent: 'center',
                justifyContent: 'center',
              }}>
              <Icon name="paper-plane" style={{color: 'white'}} size={20} />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
}

import React, {useEffect, useMemo, useState} from 'react';
import {View, StyleSheet, ViewStyle} from 'react-native';

interface DashedLineProps {
  axis?: 'horizontal' | 'vertical';
  dashGap?: number;
  dashLength?: number;
  dashThickness?: number;
  dashColor?: string;
  widthOveride?: number | null;
  dashStyle?: ViewStyle;
  style?: ViewStyle;
}

const DashedLine: React.FC<DashedLineProps> = ({
  axis = 'horizontal',
  dashGap = 2,
  dashLength = 3,
  dashThickness = 1,
  dashColor = '#000',
  widthOveride = null,
  dashStyle,
  style,
}) => {
  const [lineLength, setLineLength] = useState<number>(widthOveride || 0);

  useEffect(() => {
    if (widthOveride) {
      setLineLength(widthOveride);
    }
  }, [widthOveride]);

  const isRow = axis === 'horizontal';
  let numOfDashes = Math.ceil(lineLength / (dashGap + dashLength)) || 1;

  // correcting for math errors
  if (isNaN(numOfDashes) || numOfDashes < 1) {
    numOfDashes = 1;
  }

  const dashStyles = useMemo(
    () => ({
      width: isRow ? dashLength : dashThickness,
      height: isRow ? dashThickness : dashLength,
      marginRight: isRow ? dashGap : 0,
      marginBottom: isRow ? 0 : dashGap,
      backgroundColor: dashColor,
    }),
    [dashColor, dashGap, dashLength, dashThickness, isRow],
  );

  if (widthOveride && widthOveride < 5) {
    return <></>;
  }

  return (
    <View
      onLayout={(event) => {
        if (!widthOveride && !lineLength) {
          const {width, height} = event.nativeEvent.layout;
          setLineLength(isRow ? width : height);
        }
      }}
      style={[style, isRow ? styles.row : styles.column]}>
      {[...Array(numOfDashes)].map((_, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <View key={i} style={[dashStyles, dashStyle]} />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
  },
  column: {
    flexDirection: 'column',
  },
});

export default DashedLine;

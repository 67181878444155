import React, {useState} from 'react';
import {View, Image, ScrollView, Text} from 'react-native';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
} from 'react-native-google-places-autocomplete';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {ContinueButton, BodyText, TextEntry} from 'src/Modules/CommonView';
import {useUserId} from 'src/Hooks/reduxHooks';
import {navigationRef} from 'src/Nav/navigationHelpers';

const paPhotoUrl = require('src/Images/user_test_image.png');
import {updateDataAtPath} from 'src/Utils/fireUtils';
import {v4 as uuidv4} from 'uuid';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {getReduxState} from 'src/Utils/helpers';
import {fetchWithParams} from 'src/Utils/apiCalls';
import getAppType from 'src/Utils/getAppType';
import constants from 'src/constants';

interface Location {
  address?: string;
  lat?: number;
  lng?: number;
}

export default function PASubmitChat() {
  const {width} = useWindowDimensionsSafe();
  const userId = useUserId();
  const userLocation = useTypedSelector((state) => state.location || {});

  const [request, setRequest] = useState<string>('');
  const [requestLocation, setRequestLocation] = useState<Location>({});

  const firstName = useTypedSelector(
    (state) => state.user?.info?.firstName || 'test',
  );
  const userPhoto = useTypedSelector(
    (state) => state.user?.info?.customPhotoUrl || state.user?.info?.photoUrl,
  );

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const buoy = require('src/Images/Icons/lifebuoy_2.png');

  const isAA = getAppType() === 'aa';

  const genUUID = () => {
    return uuidv4();
  };

  const sendMessage = async (ctaRequestText: string = '') => {
    if (!request) {
      return console.log('no request');
    }
    const now = Date.now();
    const chatUUID = genUUID();

    const message = {
      sentAt: now,
      from: userId,
      fromName: firstName,
      fromImage: userPhoto,
      message: ctaRequestText || request,
    };

    const data = {
      UUID: chatUUID,
      userId: userId,
      createdAt: now,
      title: ctaRequestText || request,
      messages: [message],
      location: requestLocation?.address ? requestLocation : userLocation,
      status: 'Started',
    };

    const newPath = `paChats/${chatUUID || 'error'}`;
    updateDataAtPath(`${newPath || 'error'}`, data);

    setRequest('');

    navigationRef.navigate(
      'PersonalAssistant',
      null,
      'LandingPage/PASubmitChat.tsx',
    );
  };

  const autoCompleteRef = React.useRef<GooglePlacesAutocompleteRef | null>(
    null,
  );

  const isLRApp = getAppType() === 'lr';

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: isLRApp ? '#clF9F7F2' : '#F9F7F2',
        paddingHorizontal: 12,
      }}>
      <ScrollView
        keyboardShouldPersistTaps="always"
        showsVerticalScrollIndicator={false}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 30,
          }}>
          <View>
            <BodyText
              text={'Chat with your'}
              textStyle={{
                color: 'black',
                fontSize: 28,
                fontWeight: '600',
                lineHeight: 28,
                fontFamily: 'JakartaBold',
              }}
            />
            <BodyText
              text={'Personal Assistant'}
              textStyle={{
                color: 'black',
                fontSize: 28,
                fontWeight: '600',
                lineHeight: 28,
                marginTop: 5,
                fontFamily: 'JakartaBold',
              }}
            />
          </View>
          <View
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Image
              source={paPhotoUrl}
              style={{width: 50, height: 50, resizeMode: 'contain'}}
            />
            <BodyText
              text={'Joyce'}
              textStyle={{
                color: 'black',
                fontSize: 11,
                fontWeight: '400',
                marginTop: 5,
                fontFamily: 'Jakarta',
              }}
            />
          </View>
        </View>
        <BodyText
          text={
            <Text>
              Set your location{' '}
              <TouchableOpacity
                onPress={() => {
                  console.log('set location');
                  const latitude = getReduxState(
                    (state) => state.location?.region?.latitude,
                  );
                  const longitude = getReduxState(
                    (state) => state.location?.region?.longitude,
                  );
                  const API_KEY = 'AIzaSyB_UX9PoSmWcLsqGaLg3w8R9ZoC25EDJCE';
                  const LAT = latitude;
                  const LNG = longitude;

                  const API_URL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${LAT},${LNG}&key=${API_KEY}`;

                  fetchWithParams(API_URL)
                    .then((data) => {
                      console.log({data});
                      if (data.status === 'OK') {
                        const address = data.results[0].formatted_address;
                        console.log(data.results[0]);
                        console.log({address});
                        autoCompleteRef?.current?.setAddressText(address);
                        setRequestLocation({
                          address: address,
                          lat: latitude,
                          lng: longitude,
                        });
                        autoCompleteRef?.current?.setAddressText(address);
                      } else {
                        console.log('Error:', data.status);
                        navigationRef.simpleAlert(
                          'Could not load address',
                          'Please Try Again. Or manually enter the address.',
                        );
                      }
                    })
                    .catch((error) => {
                      console.error('Error fetching address:', error);
                      navigationRef.simpleAlert(
                        'Could not load address',
                        'Please Try Again! Or manually enter the address.',
                      );
                    });
                }}>
                <Text
                  style={{
                    textDecorationLine: 'underline',
                    fontSize: 10,
                    color: 'blue',
                  }}>
                  (use my location)
                </Text>
              </TouchableOpacity>
            </Text>
          }
          textStyle={{
            color: 'black',
            fontSize: 15,
            fontWeight: '700',
            lineHeight: 21,
            marginTop: 5,
            fontFamily: 'JakartaBold',
          }}
        />
        <View style={{flex: 1, marginTop: 12}}>
          <GooglePlacesAutocomplete
            ref={autoCompleteRef}
            placeholder="Search locations"
            enablePoweredByContainer={false}
            // currentLocation={true}
            enableHighAccuracyLocation={true}
            listViewDisplayed={false}
            styles={{
              textInputContainer: {
                width: width - 40,
              },
              placesautocomplete: {
                flex: 1,
              },
              listView: {
                width: width - 40,
              },
              textInput: {
                height: 38,
                color: '#5d5d5d',
                fontSize: 16,
                backgroundColor: '#fff',
                textAlign: 'center',
              },
              predefinedPlacesDescription: {
                color: '#1faadb',
              },
            }}
            fetchDetails={true}
            onPress={(data, details = null) => {
              setRequestLocation({
                address: details?.formatted_address,
                lat: details?.geometry.location.lat,
                lng: details?.geometry.location.lng,
              });
            }}
            onFail={(error) => console.error(error)}
            query={{
              key: 'AIzaSyB_UX9PoSmWcLsqGaLg3w8R9ZoC25EDJCE',
              language: 'en',
            }}
          />

          <BodyText
            text={'What do you need help with?'}
            textStyle={{
              color: 'black',
              fontSize: 15,
              fontWeight: '700',
              lineHeight: 21,
              marginTop: 20,
              fontFamily: 'JakartaBold',
            }}
          />
          <BodyText
            text={
              'Your assistant can help with everything related to travel, exploration, or discovering new things except paid booking reservations.'
            }
            textStyle={{
              color: 'black',
              fontSize: 15,
              fontWeight: '400',
              lineHeight: 21,
              marginTop: 8,
              fontFamily: 'Jakarta',
            }}
          />
          <View style={{marginTop: 12}}>
            <TextEntry
              testID={'search locations'}
              value={request}
              multiline
              handleChange={(request) => setRequest(request)}
              containerStyle={{
                backgroundColor: 'transparent',
                height: 150,
              }}
              inputStyle={{
                backgroundColor: 'white',
                width: '100%',
                textAlign: 'left',
                paddingHorizontal: 10,
                paddingTop: 10,
                height: 150,
              }}
            />
          </View>

          {isAA ? (
            <TouchableOpacity
              onPress={()=>sendMessage()}
              style={{
                flex: 1,
                flexDirection: 'column',
                backgroundColor: '#648A87',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 5,
                padding: 8,
                borderRadius: 5,
                marginTop: 24,
                paddingVertical: 20,
                marginBottom: 30,
              }}>
              <Text
                style={{
                  color: constants.color.white,
                  fontSize: 15,
                  fontFamily: 'JakartaBold',
                  textAlign: 'center',
                }}>
                Submit request
              </Text>
            </TouchableOpacity>
          ) : (
            <ContinueButton
              text={'Submit request'}
              testID={'askMyPA'}
              textStyle={{fontFamily: 'JakartaBold'}}
              containerStyle={{
                marginTop: 16,
                marginBottom: 65,
                borderRadius: 6,
                height: 60,
              }}
              handlePress={() => {
                sendMessage();
              }}
            />
          )}
          {isAA && <View style={{width: '100%',alignSelf: 'center'}}>
          <BodyText
            text={'Example Requests'}
            textStyle={{
              color: 'black',
              fontSize: 15,
              fontWeight: '700',
              lineHeight: 21,
              marginTop: 0,
              fontFamily: 'JakartaBold',
            }}
          />
                  <TouchableOpacity
          style={{
            borderWidth: 1,
            borderColor: '#D9D9D6',
            height: 50,
            borderRadius: 10,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 20,
            marginBottom: 5,
            width: '100%',
          }}
          onPress={() => {
            sendMessage('What’s the best deli near me?');
          }}>
          <BodyText
            text={'What’s the best deli near me?'}
            textStyle={{
              color: constants?.color?.blue,
              fontSize: 15,
              fontWeight: '500',
              marginBottom: 5,
              padding: 0,
              fontFamily: 'Jakarta',
            }}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={{
            borderWidth: 1,
            borderColor: '#D9D9D6',
            height: 50,
            borderRadius: 10,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 5,
            width: '100%',
          }}
          onPress={() => {
            sendMessage('What sights should I check out in my city?')
          }}>
          <BodyText
            text={'What sights should I check out in my city?'}
            textStyle={{
              color: constants?.color?.blue,
              fontSize: 15,
              fontWeight: '500',
              marginBottom: 5,
              padding: 0,
              fontFamily: 'Jakarta',
            }}
          />
        </TouchableOpacity>
            </View>}
          {!playTestState && isAA && (
            <View style={{width: '100%'}}>
              <TouchableOpacity
                style={{
                  backgroundColor: 'black',
                  borderRadius: 8,
                  marginVertical: 20,
                  // marginHorizontal: 12,
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingVertical: 40,
                  paddingHorizontal: 40,
                  shadowColor: '#000',
                  shadowOffset: {width: 0, height: 1},
                  shadowOpacity: 0.6,
                  shadowRadius: 3,
                }}>
                <>
                  <Image
                    source={buoy}
                    style={{width: 36, height: 36, resizeMode: 'contain'}}
                  />
                  <View style={{display: 'flex', flexDirection: 'row'}}>
                    <BodyText
                      text={'Call us at'}
                      textStyle={{
                        color: 'white',
                        fontSize: 17,
                        fontFamily: 'JakartaBold',
                        marginLeft: 0,
                        marginRight: 0,
                      }}
                    />
                    <BodyText
                      text={'1-833-202-7626'}
                      textStyle={{
                        color: '#EACD72',
                        fontSize: 17,
                        fontFamily: 'JakartaBold',
                        marginLeft: 5,
                        marginRight: 0,
                      }}
                    />
                  </View>
                  <BodyText
                    text={'for live support'}
                    textStyle={{
                      color: constants.color.white,
                      fontSize: 17,
                      fontFamily: 'JakartaBold',
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 3,
                    }}
                  />
                  <BodyText
                    text={'Our friendly agents are standing by.'}
                    textStyle={{
                      color: constants.color.white,
                      fontSize: 12,
                      fontFamily: 'Jakarta',
                      marginLeft: 0,
                      marginRight: 0,
                      marginTop: 12,
                    }}
                  />
                  <ContinueButton
                    text={'Call support'}
                    height={50}
                    background={'#EACD72'}
                    textStyle={{
                      fontFamily: 'JakartaBold',
                      textAlign: 'center',
                      flex: 1,
                      color: constants?.color?.black,
                    }}
                    containerStyle={{
                      marginTop: 24,
                      borderRadius: 6,
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      shadowColor: '#000',
                      shadowOffset: {width: 0, height: 1},
                      shadowOpacity: 0.6,
                      shadowRadius: 3,
                    }}
                    buttonStyle={{
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    handlePress={() => {
                      navigationRef.navigate(
                        'SupportModal',
                        {},
                        'Nav/DrawerMenu.tsx',
                      );
                    }}
                  />
                </>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  );
}

import * as t from 'io-ts';

import {validateResult} from 'src/types/typesHelpers';

// io-ts type for Ticket which we defined previously
const TTicketsIO = t.record(t.string, t.number);

export type TTickets = t.TypeOf<typeof TTicketsIO>;

export const isTTickets = (input: unknown): input is TTickets => {
  return validateResult(input, TTicketsIO, 'TTicketsIO', false);
};

// io-ts type for currentHuntInfo
const CurrentHuntInfoIO = t.partial({
  dateEventCreated: t.union([t.string, t.null]),
  dateEventLastJoined: t.union([t.string, t.null]),
  groupId: t.union([t.string, t.null]),
  eventId: t.union([t.string, t.null]),
  huntId: t.union([t.string, t.null]),
  huntCity: t.union([t.string, t.null]),
  huntType: t.union([t.string, t.null]),
  huntName: t.union([t.string, t.null]),
});

// io-ts type for pass
const TPassIO = t.partial({
  expiresDate: t.union([t.string, t.null]),
  passCode: t.union([t.string, t.null]),
  passPlayers: t.union([t.number, t.string, t.null]),
  passType: t.union([t.string, t.null]),
  isSubscription: t.union([t.boolean, t.null]),
});

export type TPass = t.TypeOf<typeof TPassIO>;

const TRequestInvite = t.partial({
  received: t.union([t.string, t.null]),
  joinCode: t.union([t.string, t.null]),
  path: t.union([t.string, t.null]),
  createdByName: t.union([t.string, t.null]),
  createdBy: t.union([t.string, t.null]),
  type: t.union([t.string, t.null]),
  autoAccept: t.boolean,
  huntPhoto: t.union([t.string, t.null]),
  askedByName: t.union([t.string, t.null]),
  askedBy: t.union([t.string, t.null]),
  askedByPhoto: t.union([t.string, t.null]),
  groupId: t.union([t.string, t.null]),
  huntName: t.union([t.string, t.null]),
});

export type TRequestInvite = t.TypeOf<typeof TRequestInvite>;

const TAddressIO = t.partial({
  fullName: t.union([t.string, t.null]),
  address1: t.union([t.string, t.null]),
  address2: t.union([t.string, t.null]),
  zip_code: t.union([t.string, t.null]),
  city: t.union([t.string, t.null]),
  state: t.union([t.string, t.null]),
});

const TMissionsCompletedIO = t.type({
  mission_names: t.union([t.string, t.null]),
  mission_points: t.union([t.number, t.null]),
  missions_completed: t.union([t.number, t.null]),
});

const TCurrentScoreBreakdownIO = t.type({
  activityCount: t.union([t.number, t.null]),
  dailyChallengesCompleted: t.union([t.number, t.null]),
  firstPlaceWins: t.union([t.number, t.null]),
  friendReferrals: t.union([t.number, t.null]),
  localCheckIns: t.union([t.number, t.null]),
  missions: t.union([TMissionsCompletedIO, t.null]),
});

const GrouponRedemptionIO = t.partial({
  redemptionTime: t.union([t.string, t.number, t.null]),
  redemptionDate: t.union([t.string, t.number, t.null]),
});

export type TAddress = t.TypeOf<typeof TAddressIO>;
export type TCurrentScoreBreakdown = t.TypeOf<typeof TCurrentScoreBreakdownIO>;
export type TMissionsCompleted = t.TypeOf<typeof TMissionsCompletedIO>;

// io-ts type for User
const TUserIO = t.intersection([
  t.type({
    userId: t.union([t.string, t.null]),
    tickets: TTicketsIO,
    firstName: t.string,
    lastName: t.string,
    email: t.string,
  }),
  t.partial({
    photoColor: t.string,
    lastGroupPhoto: t.union([t.string, t.null]),
    googleAnalyticsPurchaseLogged: t.union([t.boolean, t.null]),
    coins: t.union([t.number, t.null]),
    currentScore: t.union([t.number, t.null]),
    currentScoreBreakdown: t.union([TCurrentScoreBreakdownIO, t.null]),
    photoShares: t.any,
    shareLink: t.union([t.string, t.null]),
    birthday: t.union([t.string, t.null]),
    badgesEarnedCount: t.union([t.number, t.null]),
    home_zip_code: t.union([t.string, t.null]),
    pass: t.union([TPassIO, t.null]),
    gender: t.union([t.string, t.null]),
    contactEmail: t.union([t.string, t.null]),
    contactPhone: t.union([t.string, t.null]),
    contactPhoneNonLogin: t.union([t.string, t.null]),
    notificationScript: t.union([t.string, t.null]),
    requestNewScreenShot: t.union([t.number, t.null]),
    activePassportId: t.union([t.string, t.null]),
    badgesNewlyEarned: t.record(t.string, t.any),
    address: TAddressIO,
    customPhotoUrl: t.union([t.string, t.null]),
    memberReceiptTid: t.union([t.string, t.null]),
    requests: t.record(t.string, TRequestInvite),
    hasScreenRecording: t.boolean,
    currentHuntInfo: t.union([CurrentHuntInfoIO, t.null]),
    photoUrl: t.union([t.string, t.null]),
    fcmToken: t.union([t.string, t.null]),
    referral: t.any,
    deviceUUID: t.string,
    prepaidJoinCode: t.union([t.string, t.null]),
    googleId: t.union([t.string, t.null]),
    socialPhotoUrl: t.union([t.string, t.null]),
    level: t.union([t.number, t.null]),
    loggedIn: t.boolean,
    easyUserLevelJoinCode: t.union([t.string, t.null]),
    notDiscoverable: t.boolean,
    source: t.union([t.string, t.null]),
    countryCode: t.union([t.string, t.null]), // used for CallSupportModal
    last4Digits: t.union([t.string, t.null]), // credit cards
    stripeCustomerToken: t.union([t.string, t.null]), // credit cards
    voucher1: GrouponRedemptionIO, // for groupon vouchers
    voucherCode1: t.union([t.string, t.null]), // for groupon vouchers
    voucherSource1: t.union([t.string, t.null]), // for groupon vouchers
    voucherBonus1: t.union([t.string, t.null]), // for groupon vouchers
    voucherCode2: GrouponRedemptionIO, // for groupon vouchers
    deleted: t.union([t.boolean, t.null]), // when user is deleted this is set to 1
    portraitColor: t.union([t.string, t.null]), // for portrait mode
  }),
]);

// Derive the TypeScript type from the io-ts type for User
export type TUser = t.TypeOf<typeof TUserIO>;

// Type guard for runtime validation for User
export const isTUser = (
  input: unknown,
  showErrors: boolean = false,
): input is TUser => {
  return validateResult(input, TUserIO, 'TUser', showErrors);
};

import constants from 'src/constants';
import React, {Fragment} from 'react';

import {TouchableOpacity, View} from 'react-native';
import {BodyText} from 'src/Modules/CommonView/Text';
import EntypoIcon from 'react-native-vector-icons/Entypo';
import useParams from 'src/Hooks/useParams';
import {navigationRef} from 'src/Nav/navigationHelpers';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {dispatchAction} from 'src/Utils/helpers';
import {setAutoSavePhotos} from 'src/Redux/reducers/current_screen.reducer';

interface CameraControlRowProps {
  toggleCamera: () => void;
  handleCapture: () => void;
  loading?: boolean;
  type: string; // You might want to specify a more specific type if 'type' has specific values
  toggleFlash: () => void;
  reverseState?: boolean;
  flash: boolean;
  isRecording: boolean;
  handleCameraRoll: () => void;
  showSave?: boolean;
  handleRetake: () => void;
  handleSubmitChallenge: () => void;
  handleStopRecording: () => void;
  initialPhotoUrl?: string | null; // You might want to specify a more specific type if 'initialPhotoUrl' has a specific format
  handleShare: () => void;
}

const CameraControlRow: React.FC<CameraControlRowProps> = ({
  toggleCamera,
  handleCapture,
  loading,
  type,
  toggleFlash,
  reverseState,
  flash,
  isRecording,
  handleCameraRoll,
  showSave = false,
  handleRetake,
  handleSubmitChallenge,
  handleStopRecording,
  initialPhotoUrl,
  handleShare,
}) => {
  const {width} = useWindowDimensionsSafe();
  console.log_on_production({flash});

  const {
    isVideo = false,
    showPhotoUpload = false,
    onScore,
    onReport,
  } = useParams<'PhotoVideoCamera'>();

  const autoSavePhotos = useTypedSelector(
    (state) => state.current_screen?.autoSavePhotos,
  );

  const handleDone = () =>
    navigationRef?.goBack('Modules/CommonGame/Camera/CameraControlRow.tsx');
  // isVideo = 1;
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'absolute',
        bottom: 20,
        width,
        zIndex: 100,
        height: 90,
        backgroundColor: constants?.color?.gray3_50,
      }}>
      {!!showSave && !initialPhotoUrl && (
        <Fragment>
          <CameraIcon
            testID={'savePhoto'}
            handlePress={handleSubmitChallenge}
            name={'upload'}
            active={true}
            label={!!onScore ? 'Submit Challenge' : 'Save'}
          />

          <CameraIcon
            handlePress={handleRetake}
            name={'cross'}
            active={true}
            label={'Retake'}
          />
        </Fragment>
      )}
      {!!initialPhotoUrl && (
        <Fragment>
          <CameraIcon
            handlePress={handleShare}
            name={'share'}
            active={true}
            label={'Share Photo'}
          />

          {!!onReport && (
            <CameraIcon
              handlePress={() => {
                onReport(handleDone);
              }}
              name={'flag'}
              active={true}
              label={'Report Photo'}
            />
          )}
          <CameraIcon
            handlePress={() => {
              navigationRef?.goBack(
                'Modules/CommonGame/Camera/CameraControlRow.tsx',
              );
            }}
            name={'cross'}
            active={true}
            label={'Close Photo'}
          />
        </Fragment>
      )}
      {!showSave && (
        <Fragment>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <CameraIcon
              name={'cross'}
              handlePress={() =>
                navigationRef?.goBack(
                  'Modules/CommonGame/Camera/CameraControlRow.tsx',
                )
              }
              active={true}
              label={'Close'}
            />
            {!showPhotoUpload && (
              <CameraIcon
                name={'save'}
                handlePress={() => {
                  dispatchAction(setAutoSavePhotos(!autoSavePhotos));
                }}
                active={autoSavePhotos || false}
                label={autoSavePhotos ? 'Auto-Save On' : 'Auto-Save Off'}
              />
            )}

            {!!showPhotoUpload && (
              <CameraIcon
                name={'upload'}
                handlePress={handleCameraRoll}
                active={true}
                label={`Cameraroll`}
              />
            )}
          </View>

          <TouchableOpacity
            style={{
              backgroundColor: constants?.color?.gray2_50,
              borderRadius: 34,
              width: 68,
              height: 68,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            testID={'takePhoto'}
            activeOpacity={0.8}
            disabled={!!loading}
            onPress={() => {
              if (isRecording) {
                handleStopRecording();
              } else {
                handleCapture();
              }
            }}
            //
            // onPressIn={isRecording ? () => {} : handleCapture}
            // onPressOut={isRecording ? handleStopRecording : () => {}}
          >
            {!isRecording && (
              <View
                style={{
                  ...constants?.style.boxShadow,
                  backgroundColor: isVideo
                    ? constants?.color?.red
                    : constants?.color?.white,
                  borderRadius: 26,
                  height: 52,
                  width: 52,
                }}
              />
            )}

            {!!isRecording && (
              <View
                style={{
                  ...constants?.style.boxShadow,
                  backgroundColor: constants?.color?.red,
                  borderRadius: 6,
                  height: 34,
                  width: 34,
                }}
              />
            )}
          </TouchableOpacity>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <CameraIcon
              name={'cycle'}
              handlePress={toggleCamera}
              active={reverseState || false}
              label={type === 'back' ? 'Selfie-Cam' : 'Back-Cam'}
            />
            <CameraIcon
              name={'flash'}
              handlePress={() => {
                console.log_on_production('pressed', {flash});
                toggleFlash();
              }}
              size={20}
              label={flash ? 'Flash On' : 'Flash Off'}
              active={flash}
            />
          </View>
        </Fragment>
      )}
    </View>
  );
};

const CameraIcon = ({
  active,
  name,
  handlePress,
  label,
  size = 24,
  testID = 'cameraControlRow',
}: {
  active: boolean;
  name: string;
  handlePress: () => void;
  label: string;
  size?: number;
  testID?: string;
}) => {
  const color = active ? constants?.color?.white : constants?.color?.gray1_80;
  return (
    <TouchableOpacity
      style={{
        minWidth: 60,
        height: 40,

        marginHorizontal: 10,
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      testID={testID}
      activeOpacity={0.8}
      onPress={() => {
        console.log_on_production('CameraIcon Pressed', {
          name,
          active,
          label,
          handlePress,
        });
        handlePress();
      }}>
      {!!name && (
        <View style={{height: 30}}>
          <EntypoIcon name={name} size={size} color={color} />
        </View>
      )}
      {!!label && (
        <BodyText
          text={label}
          textStyle={{
            color,
            fontSize: 10,
            marginTop: 2,
          }}
        />
      )}
    </TouchableOpacity>
  );
};

export default CameraControlRow;

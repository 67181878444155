import constants from 'src/constants';
import React from 'react';

import {View, KeyboardAvoidingView, Platform} from 'react-native';
import JoinHunt from 'src/JoinRedeem/JoinHunt';
import {ScrollView} from 'react-native-gesture-handler';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

const JoinHuntModal: React.FC = () => {
  const {width} = useWindowDimensionsSafe();
  return (
    <KeyboardAvoidingView
      style={{flex: 1, backgroundColor: 'transparent'}}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      enabled>
      <ScrollView
        contentContainerStyle={{flexGrow: 1, justifyContent: 'flex-end'}}>
        <View
          style={{
            width,
            backgroundColor: constants?.color?.white,
            borderRadius: 10,
          }}>
          <JoinHunt isModal />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

//eslint-disable-next-line
export default JoinHuntModal;

// This reducer is used to store the user's location
// This is used to sort hunts / local stops and more
// Only active on pages that need sorting

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {calculateDistance, roundLatLong} from 'src/Utils/distanceHelpers';
import {TRegion, TRegionUpdate} from 'src/types/TRegion';

interface DirectionEntry {
  originString: string;
  destinationString: string;
  directions: {latitude: number; longitude: number}[];
}

interface UserLocationState {
  region: TRegion | null;
  backupRegion: TRegion | null;
  permissionGiven: boolean;
  hasGPSLocationData: boolean;
  roundedRegion: TRegion | null;
  veryRoundedRegion: TRegion | null;
  accuracy?: number;
  last10Directions: DirectionEntry[];
}

const initialState: UserLocationState = {
  region: null,
  backupRegion: null,
  permissionGiven: true,
  hasGPSLocationData: false,
  roundedRegion: null,
  veryRoundedRegion: null,
  last10Directions: [],
};

const userLocationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    saveUserLocation(state, {payload: position}: PayloadAction<TRegionUpdate>) {
      console.log('saveUserLocation', {position});
      if (!state.hasGPSLocationData) {
        state.hasGPSLocationData = true;
      }

      if (!position?.coords?.latitude || !position?.coords?.longitude)
        return console.log('saveUserLocation no coords', {position});

      if (
        state.hasGPSLocationData &&
        state?.region?.latitude &&
        state?.region?.longitude &&
        position?.coords?.latitude &&
        position?.coords?.longitude
      ) {
        const currentPosition = state?.region;

        const distanceChangeMinimum =
          position.coords?.distanceChangeMinimum || 10;
        const distanceChange = calculateDistance(
          currentPosition?.latitude,
          currentPosition?.longitude,
          position?.coords?.latitude,
          position?.coords?.longitude,
          'FEET',
        );
        if (distanceChange < distanceChangeMinimum) {
          return console.log(`saveUserLocation don't make a change`, {
            distanceChangeMinimum,
            distanceChange,
          });
        } else {
          console.log('saveUserLocation make a change', {
            distanceChangeMinimum,
            distanceChange,
          });
        }
      }

      state.region = {
        latitude: position.coords?.latitude,
        longitude: position.coords?.longitude,
        latitudeDelta: 40,
        longitudeDelta: 60,
      };
      state.accuracy = position.coords?.accuracy || undefined;

      // Checking if rounded region should be updated
      const roundedLat =
        Math.round((position.coords?.latitude || 0) * 1000) / 1000;
      const roundedLong =
        Math.round((position.coords?.longitude || 0) * 1000) / 1000;
      if (
        (roundedLong && roundedLat !== state?.roundedRegion?.latitude) ||
        roundedLong !== state?.roundedRegion?.longitude
      ) {
        console.log('setting semiRoundedRegion saveUserLocation', {
          roundedLat,
          roundedLong,
        });
        state.roundedRegion = {
          latitude: roundedLat,
          longitude: roundedLong,
          latitudeDelta: 40,
          longitudeDelta: 60,
        };
      } else {
        console.log('semiRoundedRegion already set saveUserLocation', {
          roundedLat,
          roundedLong,
          roundedRegion: state.roundedRegion,
        });
      }

      // Checking if very round region should be updated
      const {lat, long} = roundLatLong(
        position.coords?.latitude,
        position.coords?.longitude,
      );
      if (
        (lat && lat !== state?.veryRoundedRegion?.latitude) ||
        long !== state?.veryRoundedRegion?.longitude
      ) {
        console.log('setting veryRoundedRegion saveUserLocation', {lat, long});
        state.veryRoundedRegion = {
          latitude: lat,
          longitude: long,
          latitudeDelta: 40,
          longitudeDelta: 60,
        };
      } else {
        console.log('veryRoundedRegion already set saveUserLocation', {
          lat,
          long,
          veryRoundedRegion: state.veryRoundedRegion,
        });
      }
    },
    saveBackupRegion(
      state,
      {payload: position}: PayloadAction<{lat: number; long: number}>,
    ) {
      if (!state.region?.latitude) {
        state.region = {
          latitude: position.lat,
          longitude: position.long,
          latitudeDelta: 40,
          longitudeDelta: 60,
        };

        // Checking if rounded region should be updated
        const roundedLat = Math.round((position.lat || 0) * 1000) / 1000;
        const roundedLong = Math.round((position.long || 0) * 1000) / 1000;
        if (
          (roundedLong && roundedLat !== state?.roundedRegion?.latitude) ||
          roundedLong !== state?.roundedRegion?.longitude
        ) {
          console.log('setting semiRoundedRegion saveBackupRegion', {
            roundedLat,
            roundedLong,
          });
          state.roundedRegion = {
            latitude: roundedLat,
            longitude: roundedLong,
            latitudeDelta: 40,
            longitudeDelta: 60,
          };
        } else {
          console.log('semiRoundedRegion saveBackupRegion already set', {
            roundedLat,
            roundedLong,
            roundedRegion: state.roundedRegion,
          });
        }

        // Checking if very round region should be updated
        const {lat, long} = roundLatLong(position.lat, position.long);
        if (
          lat !== state?.veryRoundedRegion?.latitude ||
          long !== state?.veryRoundedRegion?.longitude
        ) {
          console.log('setting veryRoundedRegion saveBackupRegion', {
            lat,
            long,
          });
          state.veryRoundedRegion = {
            latitude: lat,
            longitude: long,
            latitudeDelta: 40,
            longitudeDelta: 60,
          };
        } else {
          console.log('veryRoundedRegion already set saveBackupRegion', {
            lat,
            long,
            veryRoundedRegion: state.veryRoundedRegion,
          });
        }
      }
      state.backupRegion = {
        latitude: position.lat,
        longitude: position.long,
        latitudeDelta: 40,
        longitudeDelta: 60,
      };
    },
    cacheDirections(state, action: PayloadAction<DirectionEntry>) {
      if (!state.last10Directions) {
        state.last10Directions = [];
      }

      // Add the new direction at the start of the array
      state.last10Directions.unshift(action.payload);

      // If there are more than 10 directions, remove the oldest one
      if (state.last10Directions.length > 10) {
        state.last10Directions.pop();
      }
    },
    setPermissionGiven(
      state,
      {payload: permissionGiven}: PayloadAction<boolean>,
    ) {
      state.permissionGiven = permissionGiven;
    },
  },
});

export const {
  saveUserLocation,
  setPermissionGiven,
  saveBackupRegion,
  cacheDirections,
} = userLocationSlice.actions;

export default userLocationSlice.reducer;

export const mmkvStorage = {
  set: (key: string, value: string): void => {
    localStorage.setItem(key, value);
  },
  delete: (key: string): void => {
    localStorage.removeItem(key);
  },
  getString: (key: string): string | null => {
    return localStorage.getItem(key);
  },
};

import React, {useState} from 'react';
import {
  SafeAreaView,
  ScrollView,
  TouchableOpacity,
  View,
  Alert,
  Share,
} from 'react-native';
import CheckBox from 'src/Modules/Native/CheckBox';
import {
  BodyText,
  ContinueButton,
  CloseIconButton,
} from 'src/Modules/CommonView';
import QRCode from 'react-native-qrcode-svg';
import {addChildUser} from 'src/Utils/huntFunctions';
import AddToDeviceModal from 'src/Modules/CommonGame/AddToDeviceModal';
import {socialShare} from 'src/Utils/shareHelper';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {useUserId} from 'src/Hooks/reduxHooks';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

const AddPlayerModal: React.FC = () => {
  const {width} = useWindowDimensionsSafe();

  const userId = useUserId();
  const [showAddPlayers, setShowAddPlayers] = useState<number>(0);
  const [paidTickets, setPaidTickets] = useState<boolean>(false);

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );
  const groupInfo = useTypedSelector((state) => state?.group?.info);

  const joinCode = useTypedSelector((state) => state.event?.info?.joinCode);

  const prepaidJoinCode = useTypedSelector(
    (state) => state?.group?.info?.prePaidJoinCode,
  );
  const eventId = useTypedSelector((state) => state?.group?.info?.eventId);
  const huntStarted = useTypedSelector(
    (state) => state?.group?.info?.huntStarted,
  );

  const joinLink = joinCode;
  const prepaidLink = prepaidJoinCode;

  const groupId = useTypedSelector((state) => state?.group?.info?.groupId);
  const theme = useTypedSelector((state) => state?.group?.info?.theme);
  const easyGroupLevelJoinCode = useTypedSelector(
    (state) => state?.group?.info?.easyGroupLevelJoinCode,
  );
  const huntId = useTypedSelector((state) => state?.group?.info?.huntId);
  const passId = useTypedSelector((state) => state?.group?.info?.passId);
  const indoor_hunt = useTypedSelector(
    (state) => state?.group?.info?.indoor_hunt,
  );
  const players =
    useTypedSelector((state) => state?.group?.info?.players) || {};
  const voucher_code = useTypedSelector(
    (state) => state?.group?.info?.voucher_code,
  );
  const passPlayers = useTypedSelector(
    (state) => state?.group?.info?.passPlayers,
  );

  let tickets = 0;
  const userTickets = useTypedSelector((state) => state?.user?.info?.tickets);
  if (theme && userTickets?.[theme]) {
    tickets = userTickets?.[theme];
  }

  const navigateToTicketsModal = () => {
    navigationRef.navigate(
      'PurchaseModal',
      {
        forcedTheme: theme,
      },
      'Modules/CommonGame/AddPlayerModal.tsx',
    );
  };

  const toggleType = () => {
    if (!tickets || tickets < 1) {
      Alert.alert(
        'You Need Tickets',
        'You need tickets to let people join with your tickets.',
        [
          {
            text: 'Purchase Tickets',
            onPress: () => {
              navigateToTicketsModal();
            },
          },
          {
            text: 'Cancel',
          },
        ],
      );
    } else {
      setPaidTickets(!paidTickets);
    }
  };

  const easyUserLevelJoinCode = useTypedSelector(
    (state) => state.user?.info?.easyUserLevelJoinCode,
  );
  const isFree = huntId == '2266';

  const isPass = passId;

  const canJoinWithPass =
    isPass &&
    passPlayers &&
    Object.values(players).length &&
    Object.values(players).length < Number(passPlayers);

  let groupNeedsTicketsToJoin =
    playTestState == false &&
    !eventId &&
    !isFree &&
    !theme?.includes?.('home') &&
    !canJoinWithPass;

  if (indoor_hunt) {
    groupNeedsTicketsToJoin = false;
  }
  let displayedCode: string | null | undefined = joinCode;
  let displayedLink: string | null | undefined = joinLink || joinCode;
  let checked = false;
  if (groupNeedsTicketsToJoin && tickets > 0 && paidTickets) {
    displayedCode = prepaidJoinCode;
    displayedLink = prepaidLink || prepaidJoinCode;
    checked = true;
  }

  let qrCode = `https://www.letsroam.com/scavenger_hunt_app?app_redirect=1&join_code=${displayedCode}`;

  // console.log('the qr code', qrCode);

  return (
    <SafeAreaView style={{flex: 1, justifyContent: 'flex-end'}}>
      <TouchableOpacity
        style={{flex: 1}}
        onPress={() =>
          navigationRef?.goBack('Modules/CommonGame/AddPlayerModal.tsx')
        }
      />
      <View
        style={{
          minHeight: 400,
          width,
          borderRadius: 10,
          backgroundColor: constants?.color?.white,
          position: 'relative',
        }}>
        <ScrollView>
          <View
            style={{
              alignItems: 'center',
              position: 'relative',
            }}>
            <BodyText
              text={'Invite players to join this event'}
              textStyle={{
                marginTop: 20,
                marginHorizontal: 30,
                fontWeight: '700',
                marginBottom: 0,
                fontSize: 20,
                lineHeight: 20,
              }}
              center
            />

            <BodyText
              text={'Have them scan this QR code.'}
              textStyle={{
                marginHorizontal: 30,
              }}
              center
            />
            <View style={{height: 30, width}} />
            <QRCode
              value={qrCode || displayedLink || ' '}
              logo={require('src/Images/stickerLogo.png')}
              logoBackgroundColor="transparent"
              size={140}
              logoSize={30}
              logoBorderRadius={0}
              ecl={'M'}
              color={constants?.color?.gray3}
            />

            <View style={{height: 20, width}} />
            {/* <ContinueButton
              text={`Share Invite Code: ${displayedCode}`}
              containerStyle={{minWidth: 300, height: 50}}
              handlePress={() => {
                Share.share({
                  // url: qrCode || '',
                  message:
                    `Join my scavenger hunt by entering this code ${displayedCode} in the Let's Roam App link on your phone or follow this link: ` +
                    qrCode,
                });
              }}
              background={constants?.color?.orange}
            /> */}
          </View>
          {!!eventId && !!voucher_code && (
            <BodyText
              text={`Remember, players can create their own group as part of the event by entering the code: ${
                voucher_code || 'No Code Found'
              }`}
              textStyle={{fontWeight: '700'}}
              center
            />
          )}
        </ScrollView>
        <CloseIconButton
          handlePress={() =>
            navigationRef?.goBack('Modules/CommonGame/AddPlayerModal.tsx')
          }
          side="right"
        />
      </View>
      <AddToDeviceModal
        isVisible={!!showAddPlayers}
        closeModal={() => setShowAddPlayers(0)}
        addChildUser={(firstName, parentUserId, parentUserEmail, groupId) => {
          addChildUser(firstName, parentUserId, parentUserEmail, groupId);
          if (huntStarted) {
            navigationRef.navigate(
              'ScavengerHuntCharacterSelection',
              null,
              'Modules/CommonGame/AddPlayerModal.tsx',
            );
          } else {
            navigationRef?.goBack('Modules/CommonGame/AddPlayerModal.tsx');
          }
        }}
      />
    </SafeAreaView>
  );
};

export default AddPlayerModal;

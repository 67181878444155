import * as t from 'io-ts';
import {validateResult} from './typesHelpers';

const TPurchaseIO = t.partial({
  theme: t.string,
  tickets: t.union([t.number, t.string, t.null]),
  passPlayers: t.union([t.number, t.string, t.null]),
  passType: t.string,
  upgrade: t.record(t.string, t.string),
  pass: t.record(t.string, t.union([t.string, t.number])),
});

const TVoucherTicketInfoIO = t.partial({
  tickets: t.union([t.number, t.boolean]),
  theme: t.union([t.string, t.null]),
});

// io-ts type for Voucher
const TVoucherIO = t.intersection([
  t.type({
    voucherCode: t.string,
  }),
  t.partial({
    createdAt: t.union([t.string, t.null]),
    dateTime: t.union([t.string, t.null]),
    firstName: t.union([t.string, t.null]),
    lastName: t.union([t.string, t.null]),
    message: t.union([t.string, t.null]),
    orderNumber: t.union([t.string, t.null]),
    players: t.union([t.string, t.null]),
    productType: t.union([t.string, t.null]),
    purchaseId: t.union([t.string, t.null]),
    roleType: t.union([t.string, t.null]),
    schTid: t.union([t.string, t.null]),
    tid: t.union([t.string, t.null]),
    token: t.union([t.string, t.null]),
    transactionId: t.union([t.string, t.null]),
    theme: t.union([t.string, t.null]),
    purchase: t.union([TPurchaseIO, t.null]),
    voucherVersion: t.union([t.number, t.null]),
    tickets: t.union([TVoucherTicketInfoIO, t.null]),
    redeemed: t.union([t.number, t.boolean]),
    askedToRedeem: t.union([t.number, t.boolean]),
    type: t.union([t.string, t.null]), // used for notification type
    subType: t.union([t.string, t.null]), // used for groupon
    customCode: t.union([t.string, t.null]), // used for groupon
    reviewUrl: t.union([t.string, t.null]), // used for 3rd party review aka groupon
    subscriptionLength: t.union([t.number, t.null]),
    subscriptionId: t.union([t.string, t.null]),
    trialPeriodDays: t.union([t.number, t.null]),
    proccessedRedemptions: t.union([t.number, t.null]),
    autoSubmit: t.union([t.boolean, t.null]), // used to auto submit voucher
  }),
]);

// Derive the TypeScript type from the io-ts type for Voucher
export type TVoucher = t.TypeOf<typeof TVoucherIO>;

// Type guard for runtime validation for Voucher
export const isTVoucher = (
  input: unknown,
  showErrors: boolean,
): input is TVoucher => {
  return validateResult(input, TVoucherIO, 'TVoucher', showErrors);
};

import React from 'react';
import {Text, View, Image, ScrollView, Platform} from 'react-native';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {ContinueButton} from 'src/Modules/CommonView';
import {markHasSeenHybridCharacterList} from 'src/Utils/huntFunctions';
import {TCustomCharacter} from 'src/types/TGroupInfo';

import constants from 'src/constants';
import ExpoImage from 'src/Utils/ExpoImage';

const HybridCharacterList = () => {
  const userId = useTypedSelector((state) => state.user?.userId) || '';
  const groupInfo = useTypedSelector((state) => state.group?.info);

  const customCharacters = Object.values(groupInfo?.customCharacters ?? {});

  return (
    <ScrollView style={{paddingHorizontal: 10}}>
      {customCharacters?.map((character, index) => (
        <Character key={index} character={character} order={index} />
      ))}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
        <ContinueButton
          text={'See Your Character'}
          textStyle={{fontFamily: 'JakartaBold'}}
          background={constants.color.orange}
          containerStyle={{
            marginTop: 30,
            borderRadius: 6,
            marginHorizontal: 'auto',
          }}
          width={200}
          height={50}
          handlePress={() => {
            if (groupInfo?.groupId) {
              markHasSeenHybridCharacterList(userId, groupInfo?.groupId);
            }
          }}
        />
      </View>
      <View style={{height: 50}}></View>
    </ScrollView>
  );
};

type CharacterProps = {
  character: TCustomCharacter;
  order: number;
};

const Character: React.FC<CharacterProps> = ({
  character: {name, title, description, image},
  order,
}) => {
  const isReversed = order % 2 !== 0; // Check if the order is odd

  return (
    <View
      style={{
        marginTop: 15,
        backgroundColor: constants.color.white,
        display: 'flex',
        flexDirection: isReversed ? 'row-reverse' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 8,
      }}>
      <View style={{maxHeight: 300}}>
        {Platform.OS === 'web' ? (
          <img
            style={{
              width: 130,
              position: 'relative',
              flex: 1,
              borderRadius: 8,
              objectFit: 'cover',
            }}
            src={
              image?.includes('https://firebasestorage.googleapis.com')
                ? image
                : `https://photos.letsroam.com/${image}`
            }></img>
        ) : (
          <ExpoImage
            source={{
              uri: image?.includes('https://firebasestorage.googleapis.com')
                ? image
                : `https://photos.letsroam.com/${image}`,
            }}
            style={{
              width: 130,
              position: 'relative',
              flex: 1,
              borderRadius: 8,
            }}
            resizeMode="cover"
          />
        )}
      </View>
      <View
        style={{
          flex: 2,
          flexShrink: 1,
          paddingRight: isReversed ? 0 : 10,
          paddingLeft: isReversed ? 10 : 0,
          maxWidth: 220,
          paddingVertical: 13,
        }}>
        <Text
          style={{
            fontFamily: 'JakartaBold',
            fontSize: 16,
            letterSpacing: -0.5,
            textAlign: isReversed ? 'left' : 'right',
          }}>
          {name}
        </Text>
        <Text
          style={{
            fontFamily: 'Jakarta',
            fontSize: 14,
            fontStyle: 'italic',
            letterSpacing: -0.5,
            marginTop: 5,
            textAlign: isReversed ? 'left' : 'right',
          }}>
          {title}
        </Text>
        <Text
          style={{
            fontFamily: 'Jakarta',
            fontSize: 15,
            lineHeight: 16,
            letterSpacing: -0.5,
            marginTop: 5,
            textAlign: isReversed ? 'left' : 'right',
          }}>
          {description}
        </Text>
      </View>
    </View>
  );
};

export default HybridCharacterList;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {View, TouchableOpacity, Alert, Text} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import ExpoImage from 'src/Utils/ExpoImage';
import {apiFlagPhotos} from 'src/Utils/apiCalls';

//////////////////////
///////// components

import {PrimaryButton, FooterOverlay} from 'src/Modules/CommonView';
import {setDataAtPath} from 'src/Utils/fireUtils';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {FlatList} from 'react-native-gesture-handler';

import {TPhoto} from 'src/types/TPhoto';
import {TChallenge} from 'src/types/TChallenge';
import {PhotoTile} from 'src/Modules/CommonView/PhotoTile';

interface PhotosProps {
  showQuestionFeedback?: boolean;
  hideClose?: boolean;
  handleBack: () => void;
  showFlag?: boolean;
  photoData?: TPhoto[];
  useEventPhotos?: boolean;
  selectedGroupId?: string | null;
  handleSelection?: (_index: number) => void;
  selectable?: boolean;
  selection?: number[];
  setSelectedGroup?: Function;
}

const Photos: React.FC<PhotosProps> = ({
  showQuestionFeedback = false,
  hideClose = false,
  handleBack,
  showFlag = false,
  photoData = [],
  useEventPhotos = false,
  selectedGroupId = null,
  handleSelection,
  selectable,
  selection = [],
  setSelectedGroup,
}) => {
  const {width} = useWindowDimensionsSafe();

  const backupPhotoData: TPhoto[] = [];

  const playerChallengePhotos = useTypedSelector(
    (state) => state.playerChallenges?.playerChallengePhotos || {},
  );
  const eventId = useTypedSelector((state) => state.group?.info?.eventId);

  //insulate from null challengePhotos
  if (playerChallengePhotos) {
    backupPhotoData?.push(...playerChallengePhotos);
  }

  let photoObjects = photoData || backupPhotoData;

  if (useEventPhotos) {
    const photoData = useTypedSelector((state) => state.event?.photos);
    console.log({photoData});

    photoObjects = [];
    if (selectedGroupId) {
      photoObjects =
        selectedGroupId &&
        photoData?.[selectedGroupId] &&
        photoData[selectedGroupId].photos
          ? Object.values(photoData[selectedGroupId].photos)
          : [];
    } else {
      Object.values(photoData)?.map?.((group) => {
        if (group && group.photos) {
          photoObjects = [
            ...photoObjects,

            ...Object.values(group.photos || {}),
          ];
        }
      });
    }
  }

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white', display: 'flex'}}>
      {!!selectedGroupId && eventId && (
        <TouchableOpacity
          onPress={() => {
            if (setSelectedGroup) {
              setSelectedGroup(null);
            }
          }}
          style={{
            alignSelf: 'center',
            borderRadius: 12,
            backgroundColor: constants?.color?.orange,
            padding: 5,
            marginTop: 5,
            marginBottom: 3,
          }}>
          <Text
            style={{
              fontFamily: 'Alternate Gothic No3 D',
              fontSize: 14,
              color: constants?.color?.white,
              letterSpacing: -0.2,
              textAlign: 'center',
            }}>
            Show All Event Photos
          </Text>
        </TouchableOpacity>
      )}

      {!!photoObjects?.length && !selectable && (
        <FlatList
          data={photoObjects}
          removeClippedSubviews
          keyExtractor={(item) => item.UUID || JSON.stringify(item)}
          contentContainerStyle={{paddingBottom: 200}}
          renderItem={({item: photo, index}) => {
            // navigationRef.devAlert(item);
            const challengeText =
              photo.challengeText ||
              photo.challenge ||
              photo.question ||
              photo.task ||
              photo.description ||
              ' ';
            const photoUrl =
              photo.thumbnail ||
              photo.share_photo ||
              photo.photoURL ||
              photo.photoUrl ||
              photo.downloadURL ||
              (photo.completion ? photo.completion.photoUrl : null);

            const isVideo =
              photo.type === 'video' ||
              photo.type === 'Video' ||
              !!photo.thumbnail ||
              challengeText?.includes('Video') ||
              challengeText?.includes('Capture') ||
              challengeText?.includes('video') ||
              photoUrl?.includes('video');

            const onReport = !showFlag
              ? undefined
              : () => {
                  if (!showQuestionFeedback) {
                    navigationRef.navigate(
                      'ConfirmModal',
                      {
                        titleText: `Are you Positive You Want To Flag This Photo`,
                        confirmText: 'Yes, Flag for Removal!!!',
                        cancelText: 'Cancel',
                        handleConfirm: (_, __, handleDone) => {
                          console.log('onReport', {photo});
                          if (photo.id && photoUrl) {
                            apiFlagPhotos({
                              photoId: photo.id,
                              photoUrl,
                            });
                          }
                          if (photo.path) {
                            setDataAtPath(photo.path, null);
                          }
                          if (typeof handleDone == 'function') {
                            handleDone();
                          }
                          Alert.alert(
                            'This Photo Was Flagged For Removal',
                            'We apologize for potentially inappropriate content. Thank you for helping to keep our community clean. The photo will be gone when you fully close and reopen the app.',
                          );
                        },
                      },
                      'Photos 1',
                    );
                  } else {
                    navigationRef.navigate(
                      'QuestionFeedBackModal',
                      {
                        challenge: photo as TChallenge,
                        type: photo.type || 'Photo',
                      },
                      'Photos 3',
                    );
                  }
                };
            return (
              <PhotoTile
                showFlag={showFlag}
                fetchSetTopPhotos={() => {}}
                handlePress={() => {
                  navigationRef.navigate(
                    'PhotoVideoCamera',
                    {
                      text: challengeText,
                      photoUrl,
                      onReport: onReport,
                      shareUUID: photo.shareUUID || undefined,
                      isVideo,
                    },
                    'Photos 1',
                  );
                }}
                onReport={onReport}
                photo={photo}
                index={index}
                descriptionOverride={photo?.challenge || undefined}
                descriptionStyle={{
                  color: constants?.color.white,
                  fontSize: 16,
                  paddingLeft: 5,
                  lineHeight: 22,
                  fontFamily: constants?.font.circularBold,
                  backgroundColor: 'black',
                }}
              />
            );
          }}
        />
      )}
      {!!selectable && (
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: 5,
          }}>
          {photoObjects?.map?.((photo, index) => {
            let selected = false;
            if (selectable && selection?.includes?.(index)) {
              selected = true;
            }
            console.log({selection, index});
            // console.log('the download url is: ', photo.downloadURL);

            const photoUrl =
              photo.photoURL ||
              photo.photoUrl ||
              photo.downloadURL ||
              (photo.completion ? photo.completion.photoUrl : null);

            return (
              <TouchableOpacity
                key={`photos_view_${index}`}
                onPress={() => {
                  if (selectable && typeof handleSelection == 'function') {
                    return handleSelection(index);
                  }
                  console.log({photo});
                  const challengeText =
                    photo.challengeText ||
                    photo.challenge ||
                    photo.question ||
                    photo.task ||
                    photo.description ||
                    ' ';

                  const isVideo =
                    photo.type === 'video' ||
                    photo.type === 'Video' ||
                    !!photo.thumbnail ||
                    challengeText?.includes('Video') ||
                    challengeText?.includes('video') ||
                    photoUrl?.includes('video');
                  navigationRef.navigate(
                    'PhotoVideoCamera',
                    {
                      text: challengeText,
                      photoUrl,
                      onReport: !showFlag
                        ? undefined
                        : () => {
                            if (!showQuestionFeedback) {
                              navigationRef.navigate(
                                'ConfirmModal',
                                {
                                  titleText: `Are you Sure You Want To Flag This Photo?`,
                                  confirmText: 'Yes, Flag for Removal!!',
                                  cancelText: 'Cancel',
                                  handleConfirm: (_, __, handleDone) => {
                                    console.log('onReport', {photo});
                                    photo.id &&
                                      photoUrl &&
                                      apiFlagPhotos({
                                        photoId: photo.id,
                                        photoUrl,
                                      });
                                    setDataAtPath(photo.path || 'error', null);
                                    if (typeof handleDone == 'function') {
                                      handleDone();
                                    }
                                    Alert.alert(
                                      'This Photo Was Flagged For Removal',
                                      'We apologize for potentially inappropriate content. Thank you for helping to keep our community clean. The photo will be gone when you fully close and reopen the app.',
                                    );
                                  },
                                },
                                'Photos 1',
                              );
                            } else {
                              navigationRef.navigate(
                                'QuestionFeedBackModal',

                                {
                                  // @ats-ignore
                                  challenge: photo as TChallenge,
                                  type: photo.type || 'Photo',
                                },
                                'Photos 2',
                              );
                            }
                          },
                      shareUUID: photo.shareUUID || undefined,
                      isVideo,
                    },
                    'Photos 1',
                  );
                }}>
                {!!photoUrl && (
                  <ExpoImage
                    style={{
                      width: (width - 20) / 3,
                      height: (width - 20) / 3,
                      marginLeft: 3.33,
                      marginRight: 3.33,
                      marginBottom: 6.66,
                      backgroundColor: 'black',
                      borderWidth: selected ? 5 : 0,
                      borderColor: 'green',
                    }}
                    resizeMode={'cover'}
                    //resizeMode={"cover"}
                    source={{
                      uri: photoUrl,
                    }}
                  />
                )}
              </TouchableOpacity>
            );
          })}
          <View style={{height: 20, width: width}} />
        </View>
      )}

      {!hideClose && (
        <FooterOverlay>
          <PrimaryButton
            background={constants?.color?.gray3}
            text={'Back'}
            handlePress={handleBack}
          />
        </FooterOverlay>
      )}
    </SafeAreaView>
  );
};

export default Photos;

import MapView from 'react-native-maps';

// @ts-ignore
export const Marker = MapView.Marker;
// @ts-ignore
export const Circle = MapView.Circle;

console.log('MapViewWeb', {Marker, Circle});

export const MapViewCluster = MapView;
export const PROVIDER_GOOGLE = 'google';

export default MapView;

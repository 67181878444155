import useTypedSelector from 'src/Hooks/useTypedSelector';
import React from 'react';
import {View} from 'react-native';

///////////////////////////
///////////// utils
import {calculateDistance} from 'src/Utils/distanceHelpers';

///////////////////////////
///////////// components
import {ExpandFullscreenButton} from 'src/Modules/CommonView';
import ChallengeMap from 'src/ScavengerHunt_v2/Challenges/ChallengeMap';

import {DistanceAway} from 'src/ScavengerHunt_v2/Game/DistanceAway';

import {navigationRef} from 'src/Nav/navigationHelpers';

import {useLocation} from 'src/Hooks/gameHooks';

const FullScreenNavigationHelpModal: React.FC = () => {
  const location = useLocation();

  const {lat = 0, long = 0} = location || {};

  const region = useTypedSelector((state) => state.location?.region);
  const latitude = region?.latitude || 0;
  const longitude = region?.longitude || 0;

  const locationLat = Number(lat);
  const locationLong = Number(long);

  const userLat = Number(latitude);
  const userLong = Number(longitude);

  const distanceAway = calculateDistance(
    locationLat,
    locationLong,
    userLat,
    userLong,
    'FEET',
  );

  const previousScreen = useTypedSelector(
    (state) => state.current_screen?.previousScreen,
  );

  console.log('FullScreenNavigationHelpModal render', {
    userLat,
    userLong,
    locationLat,
    locationLong,
    distanceAway,
  });

  return (
    <View style={{flex: 1, position: 'relative', backgroundColor: 'white'}}>
      <View style={{flex: 1, position: 'relative', backgroundColor: 'white'}}>
        {!!location && (
          <ChallengeMap
            huntLocation={location}
            style={{flex: 1, minHeight: 50, minWidth: 50}}
          />
        )}
        <ExpandFullscreenButton
          handlePress={() => {
            console.log('going to the previous screen', {
              previousScreen,
            });
            navigationRef.navigate(
              previousScreen,
              {},
              'ScavengerHunt_v2/Game/FullScreenNavgiationHelpModal.tsx',
            );
          }}
        />
        <DistanceAway />
      </View>
    </View>
  );
};

export default FullScreenNavigationHelpModal;

import constants from 'src/constants';
import React, {useEffect, useState} from 'react';
import {Text, View, TextStyle, ViewStyle} from 'react-native';

//@ts-ignore there are no types for this library
import AnimateNumber from 'react-native-animate-number-renewed';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

export const HeaderText: React.FC<{
  text: string;
  bodyStyle?: ViewStyle;
  textStyle?: TextStyle;
  color?: string;
  center?: boolean;
  renderElement?: boolean;
  underline?: boolean;
  fullWidth?: number;
}> = ({
  text,
  bodyStyle = {},
  textStyle = {},
  color = constants?.color?.gray3,
  center = false,
  renderElement = true,
  underline = true,
  fullWidth,
}) => {
  const {width} = useWindowDimensionsSafe();
  const standardView = () => (
    <View
      style={{
        borderBottomWidth: underline ? 3 : 0,
        borderBottomColor: color,
        paddingVertical: 5,
        marginVertical: 5,
        width: 250,
        ...bodyStyle,
      }}>
      <Text
        style={{
          color: color,
          fontFamily: constants?.font.gothic,
          fontSize: 30,
          textAlign: 'center',
          ...textStyle,
        }}>
        {text}
      </Text>
    </View>
  );

  if (!renderElement) {
    return <View />;
  } else if (!center) {
    return standardView();
  } else {
    return (
      <View
        style={{
          width: fullWidth || width,
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {standardView()}
      </View>
    );
  }
};

export const AnimatedPointsEarned: React.FC<{pointsEarned: number}> = ({
  pointsEarned,
}) => {
  const [animatedValue, setAnimatedValue] = useState<number>(10);
  useEffect(() => {
    setTimeout(() => {
      if (!isNaN(pointsEarned)) {
        setAnimatedValue(pointsEarned || 1000);
      }
    }, 50);
  }, []);
  return (
    <View
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        height: 60,
      }}>
      <AnimateNumber
        value={animatedValue}
        style={{
          fontSize: 50,
          fontWeight: 'bold',
          fontFamily: constants?.font.circular,
          textAlign: 'center',
          color: constants?.color.orange,
        }}
        formatter={(val: string) => {
          return parseInt(val) + ' pts';
        }}
      />
    </View>
  );
};

export const BodyText: React.FC<{
  children?: React.ReactNode;
  text?: string | JSX.Element | undefined;
  textStyle?: TextStyle;
  color?: string;
  largeText?: boolean;
  center?: boolean;
  textAlign?: 'left' | 'center';
  header?: boolean;
  testID?: string;
  fullWidth?: number;
}> = ({
  children,
  text = '',
  textStyle = {},
  color = constants?.color?.gray3,
  largeText = false,
  center = false,
  textAlign,
  header = false,
  testID = 'bodyText',
}) => (
  <Text
    testID={testID}
    style={{
      color: color,
      fontFamily: constants?.font.circular,
      fontSize: largeText ? 20 : 16,
      lineHeight: largeText ? 20 : 18,
      textAlign: textAlign || (center ? 'center' : 'left'),
      marginTop: 10,
      marginHorizontal: 15,
      ...(header ? {textAlign: 'center', fontSize: 20, lineHeight: 22} : {}),
      ...textStyle,
    }}>
    {!!children ? children : text}
  </Text>
);

export const HtmlBlock: React.FC<{
  html?: string;
  htmlStyle?: ViewStyle | TextStyle;
  replaceText?: string;
}> = ({html = '', htmlStyle = {}, replaceText = ''}) => {
  const {width} = useWindowDimensionsSafe();

  const splitHtml = html?.split?.(/<\/p> *<p>|<br>|<li>/gi);

  return (
    <React.Fragment>
      {splitHtml?.map((htmlFragment, index) => {
        const formattedFragment = htmlFragment
          .replaceAll(/<p>/gi, '')
          .replaceAll(/<\/p>/gi, '')
          .replaceAll(/(<([^>]+)>)/gi, '')
          .replaceAll(/\[TEAM_NAME\]/gi, replaceText);

        return (
          <BodyText
            key={`html_${index}`}
            text={formattedFragment}
            textStyle={{
              fontFamily: constants?.font.circular,
              fontSize: width > 340 ? 18 : 12,
              lineHeight: 22,
              color: constants?.color?.gray3,
              alignItems: 'center',
              textAlign: 'left',
              marginHorizontal: 10,
              marginVertical: 10,
              width: width - 20,
              ...htmlStyle,
            }}
          />
        );
      })}
    </React.Fragment>
  );
};

export const TextLine: React.FC<{
  text?: string;
  containerStyle?: ViewStyle;
}> = ({text = 'OR', containerStyle = {}}) => {
  return (
    <View
      style={{
        flexDirection: 'row',
        marginHorizontal: 10,
        marginVertical: 5,
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
        ...containerStyle,
      }}>
      <View
        style={{
          height: 3,
          flex: 1,
          backgroundColor: constants?.color?.gray3,
        }}
      />
      <Text
        style={{
          fontFamily: constants?.font.gothic,
          color: constants?.color?.gray3,
          marginHorizontal: 10,
        }}>
        {text}
      </Text>
      <View
        style={{
          height: 3,
          flex: 1,
          backgroundColor: constants?.color?.gray3,
        }}
      />
    </View>
  );
};

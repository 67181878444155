import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect, useRef, useState} from 'react';
import {Platform, Image, View, Text, ViewStyle} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import MapView, {
  Marker,
  Callout,
  UserLocationChangeEvent,
} from 'src/Modules/Native/MapView';

import Icon from 'react-native-vector-icons/FontAwesome';

import MapViewDirections from 'src/Modules/MapViewDirections';
import {DistanceAway} from 'src/ScavengerHunt_v2/Game/DistanceAway';
import {useLocation} from 'src/Hooks/gameHooks';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {TLocation} from 'src/types/TLocation';

const basePhotoUrl = 'photos.letsroam.com';

const ChallengeMap = ({
  style,
  huntLocation,
  delayedLocationUpdate,
  mapStyle = {},
  locations,
  classic = false,
}: {
  style?: ViewStyle;
  huntLocation?: TLocation;
  delayedLocationUpdate?: (_e: UserLocationChangeEvent) => void;
  mapStyle?: ViewStyle;
  locations?: TLocation[];
  classic?: boolean;
}) => {
  const {width, height} = useWindowDimensionsSafe();

  const location = useLocation();
  const challengeLat = location?.lat;
  const challengeLong = location?.long;

  const isLocal = huntLocation?.isLocal;

  if (isLocal) {
    classic = false;
  }

  const mapRef = useRef<MapView | null>(null);

  console.log('challenge map render', {
    location,
    challengeLat,
    challengeLong,
  });

  console.log(
    'ChallengeMap.tsx render challenge map render' +
      JSON.stringify({
        challengeLat,
        challengeLong,
        locationId: location?.locationId,
        mapStyle: style,
      }),
  );

  const latitude =
    useTypedSelector((state) => state.location?.region?.latitude) || 0;

  const longitude =
    useTypedSelector((state) => state.location?.region?.longitude) || 0;

  //For list style hunts, keep track of selected location map marker,
  //in order to display the distance orange polyline
  const [listStyleSelectedLocation, setListStyleSelectedLocation] = useState({
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    // challengeLat is from firebase
    // latitude / longitude is from the user location
    // this can update if the whole component updates, but not on original mount
    if (!challengeLat || !challengeLong || !latitude || !longitude) {
      return console.log('not all data');
    }

    if (Platform.OS !== 'ios') {
      // do not call on mount on android
      // https://github.com/react-native-maps/react-native-maps/issues/4946
      return console.log('skipping fit to map not on ios');
    }

    console.log('ChallengeMap: Fit to coordinates');
    console.log(
      `challengeLat: ${challengeLat}, challengeLong: ${challengeLong}, latitude: ${latitude}, longitude: ${longitude}`,
    );
    try {
      // do not call on mount on adroid
      // https://github.com/react-native-maps/react-native-maps/issues/4946
      mapRef?.current?.fitToCoordinates?.(
        [
          {latitude: challengeLat || 0, longitude: challengeLong || 0},
          {latitude: latitude || 0, longitude: longitude || 0},
        ],
        {edgePadding: {top: 100, right: 100, bottom: 100, left: 100}},
      );
    } catch (couldNotFitError) {
      console.log({couldNotFitError});
    }
  }, []);

  // create a region bounding box
  function createRegion(
    challengeLat: number,
    challengeLong: number,
    latitude: number,
    longitude: number,
  ) {
    // these values can come in as strings
    // convert to string and parse in order to avoid ts error
    const parsedChallengeLat = parseFloat(challengeLat.toString());
    const parsedChallengeLong = parseFloat(challengeLong.toString());
    const parsedLatitude = parseFloat(latitude.toString());
    const parsedLongitude = parseFloat(longitude.toString());

    const region = {
      latitude: -0.002 + (parsedChallengeLat || parsedLatitude || 0),
      longitude: parsedChallengeLong || parsedLongitude || 0,
      latitudeDelta: 0.08,
      longitudeDelta: 0.02,
    };

    return region;
  }
  const initialRegion = createRegion(
    challengeLat || latitude || 0,
    challengeLong || longitude || 0,
    latitude,
    longitude,
  );

  return (
    <View
      style={
        style
          ? {...style, overflow: 'hidden'}
          : {
              width,
              height: height < 700 ? 220 : 290,
              overflow: 'hidden',
            }
      }>
      {!!initialRegion &&
        // challengeLat &&
        // challengeLong &&
        !!Object.keys(initialRegion).length && (
          <MapView
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: -50,
              ...mapStyle,
            }}
            ref={mapRef}
            showsPointsOfInterest={false}
            customMapStyle={constants?.customMapStyle}
            showsIndoors={false}
            showsCompass
            key={'MapA' + JSON.stringify(initialRegion)}
            showsUserLocation
            region={
              initialRegion || {
                latitude: 0,
                longitude: 0,
                latitudeDelta: 0.08,
                longitudeDelta: 0.02,
              }
            }
            legalLabelInsets={{top: -10, left: -10, bottom: -10, right: -10}}
            onUserLocationChange={delayedLocationUpdate}>
            {!classic && !!challengeLat && (
              <Marker
                image={
                  Platform.OS === 'android' || true
                    ? require('src/Images/Icons/mapMarkerAndroid.png')
                    : require('src/Images/Icons/mapMarker2.png')
                }
                identifier={'location_map_marker'}
                coordinate={{
                  latitude: Number(challengeLat) || 0,
                  longitude: Number(challengeLong) || 0,
                }}
              />
            )}

            {!!challengeLat && Platform.OS !== 'web' && (
              <MapViewDirections
                destination={{
                  latitude: challengeLat || 0,
                  longitude: challengeLong || 0,
                }}
                mode={'walking'}
              />
            )}

            {!!classic &&
              !!locations &&
              !!locations?.length &&
              !challengeLat &&
              !challengeLong &&
              !!listStyleSelectedLocation?.latitude && (
                <MapViewDirections
                  destination={{
                    latitude: listStyleSelectedLocation?.latitude || 0,
                    longitude: listStyleSelectedLocation?.longitude || 0,
                  }}
                  mode={'walking'}
                />
              )}

            {!!classic && !!challengeLat && !!challengeLong && (
              <Marker
                identifier={'location_map_marker'}
                coordinate={{
                  latitude: Number(challengeLat) || 0,
                  longitude: Number(challengeLong) || 0,
                }}
                onPress={() => {
                  mapRef.current?.animateToRegion({
                    latitude: Number(challengeLat) || 0,
                    longitude: Number(challengeLong) || 0,
                    latitudeDelta: 0.04,
                    longitudeDelta: 0.01,
                  });
                }}>
                <View style={{alignItems: 'center'}}>
                  <Image
                    source={require('src/Images/Icons/activePin.png')}
                    style={{width: 40, height: 50, resizeMode: 'contain'}}
                  />
                </View>

                <Callout style={{borderRadius: 10}} tooltip>
                  <View
                    style={{
                      backgroundColor: constants?.color?.white,
                      minWidth: 140,
                      borderRadius: 10,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: 10,
                    }}>
                    <View
                      style={{
                        marginLeft: Platform.OS === 'ios' ? 0 : 8,
                        marginHorizontal: 8,
                        marginVertical: 4,
                      }}>
                      <Text
                        style={{
                          fontFamily: constants?.font.circular,
                          color: constants?.color?.gray3,
                          fontSize: 16,
                        }}>
                        This location
                      </Text>
                    </View>
                  </View>
                </Callout>
              </Marker>
            )}

            {!!classic &&
              !!locations &&
              !!locations?.length &&
              locations?.map?.((location) => {
                if (location.lat && location.long) {
                  const photoThumb = location?.photoThumb || '';
                  const required_checkout_location =
                    location?.required_checkout_location || false;
                  return (
                    <Marker
                      identifier={'location_map_marker'}
                      key={`location_${location.name}`}
                      coordinate={{
                        latitude: Number(location.lat) || 0,
                        longitude: Number(location.long) || 0,
                      }}
                      onPress={() => {
                        setListStyleSelectedLocation({
                          latitude: Number(location?.lat) || 0,
                          longitude: Number(location?.long) || 0,
                        });
                        mapRef.current?.animateToRegion({
                          latitude: Number(location?.lat) || 0,
                          longitude: Number(location?.long) || 0,
                          latitudeDelta: 0.04,
                          longitudeDelta: 0.01,
                        });
                      }}>
                      <View style={{alignItems: 'center'}}>
                        {!location.isTotalComplete &&
                          !required_checkout_location && (
                            <Text
                              style={{
                                fontFamily: constants?.font.circular,
                                color: constants?.color?.gray3,
                                fontSize: 16,
                                fontWeight: '700',
                                textShadowColor: constants?.color?.white,
                                textShadowRadius: 8,
                              }}>
                              {`+${
                                location.totalLocationPoints ||
                                location.points ||
                                0
                              } pts`}
                            </Text>
                          )}
                        {!!required_checkout_location && (
                          <Text
                            style={{
                              fontFamily: constants?.font.circular,
                              color: constants?.color?.gray3,
                              fontSize: 16,
                              fontWeight: '700',
                              textShadowColor: constants?.color?.white,
                              textShadowRadius: 8,
                            }}>
                            Ending Location
                          </Text>
                        )}
                        {!!location.isTotalComplete &&
                          !required_checkout_location && (
                            <View
                              style={{
                                width: 30,
                                height: 20,
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: 2,
                              }}>
                              <Icon
                                name={'check'}
                                size={20}
                                color={constants?.color?.brightgreen}
                              />
                            </View>
                          )}
                        <Image
                          source={
                            Platform.OS === 'android' || true
                              ? require('src/Images/Icons/mapMarkerAndroid.png')
                              : require('src/Images/Icons/mapMarker2.png')
                          }
                          style={{
                            width: 30,
                            height: 30,
                            resizeMode: 'contain',
                          }}
                        />
                      </View>

                      <Callout
                        style={{borderRadius: 10}}
                        tooltip
                        onPress={() => {
                          navigationRef?.goBack(
                            'ScavengerHunt_v2/Challenges/ChallengeMap.tsx',
                          );
                          if (location?.completeTime) {
                            return navigationRef.navigate(
                              'LocationChallengeListModal',
                              {
                                locationId: location.locationId,
                              },
                              'ScavengerHunt_v2/Challenges/ChallengeMap.tsx',
                            );
                          }
                          return navigationRef.navigate(
                            'NavigationChallengeModal',
                            {
                              locationId: location.locationId,
                            },
                            'ScavengerHunt_v2/Challenges/ChallengeMap.tsx',
                          );
                        }}>
                        <View
                          style={{
                            backgroundColor: constants?.color?.white,
                            minWidth: 180,
                            borderRadius: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent:
                              Platform.OS === 'ios' ? 'flex-start' : 'center',
                          }}>
                          {Platform.OS === 'ios' && (
                            <Image
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: 8,
                                margin: 5,
                                resizeMode: 'cover',
                              }}
                              source={{
                                uri: `https://${basePhotoUrl}${
                                  photoThumb && photoThumb?.substring?.(1)
                                }`,
                              }}
                            />
                          )}
                          <View
                            style={{
                              alignItems: 'center',
                              marginLeft: Platform.OS === 'ios' ? 0 : 8,
                              marginRight: 8,
                              marginVertical: 2,
                            }}>
                            <Text
                              style={{
                                fontFamily: constants?.font.circular,
                                color: constants?.color?.gray3,
                                fontSize: 14,
                              }}>
                              {location.name}
                            </Text>
                            {!location.required_checkout_location && (
                              <Text
                                style={{
                                  fontFamily: constants?.font.circular,
                                  color: constants?.color?.orange,
                                  fontSize: 12,
                                }}>
                                {`${location.challengeList?.length} Challenges`}
                              </Text>
                            )}
                            {!!location.required_checkout_location && (
                              <Text
                                style={{
                                  fontFamily: constants?.font.circular,
                                  color: constants?.color?.orange,
                                  fontSize: 12,
                                }}>
                                Ending Location
                              </Text>
                            )}
                          </View>
                        </View>
                      </Callout>
                    </Marker>
                  );
                }
              })}
          </MapView>
        )}
      {!!isLocal && (
        <DistanceAway
          style={{
            borderRadius: 20,
            paddingTop: 5,
            display: 'flex',
            paddingLeft: 20,
          }}
        />
      )}
    </View>
  );
};

export default ChallengeMap;

import {createNavigationContainerRef} from '@react-navigation/native';
import {CommonActions} from '@react-navigation/native';

import {dispatchAction, getReduxState} from 'src/Utils/helpers';
import {
  setAlertOpen,
  setCurrentScreen,
} from 'src/Redux/reducers/current_screen.reducer';
import {Camera} from 'expo-camera';
import {Alert} from 'react-native';

import {TNavigationRouteParams} from 'src/types/TNavigationRouteParams';

export const navigationRefSetter = createNavigationContainerRef();

// Make sure to explicitly list every route name and its params in RoutesParams
export async function navigate<TName extends keyof TNavigationRouteParams>(
  name: TName, // do not change unless you talk to mike
  params: TNavigationRouteParams[TName], // do not change unless you talk to mike
  caller: string, // do not change unless you talk to mike
): Promise<void> {
  // going to the photo video camera
  try {
    if (name === 'PhotoVideoCamera') {
      console.log_on_production(
        `\x1b[32m\x1b[36m\x1b[4m\x1b[1mNavigation Action - Asking for camera permission for PhotoVideoCamera\x1b[0m`,
      );

      // checking if have camera permission
      const hasCameraPermission = getReduxState(
        (state) => state?.current_screen?.hasCameraPermission,
      );

      // if no camera permission ask for it
      if (!hasCameraPermission) {
        await Camera?.requestCameraPermissionsAsync?.();
        await Camera.requestMicrophonePermissionsAsync();
      }
    }
  } catch (permissionError) {
    console.log_on_production(permissionError);
  }

  console.log_on_production(
    `\x1b[32m\x1b[36m\x1b[4m\x1b[1mNavigation Action - navigate - ${name}\x1b[0m`,
    {
      name,
      params,
      caller,
    },
  );
  if (navigationRefSetter?.isReady?.()) {
    dispatchAction(setCurrentScreen(name));

    // @ts-ignore this is fine
    navigationRefSetter.navigate(name, params);
  }
}

export function reset(
  caller: string, // mandatory for debugging
): void {
  console.log_on_production(
    `\x1b[32m\x1b[36m\x1b[4m\x1b[1mNavigation Action - reset\x1b[0m`,
    {
      caller,
    },
  );
  if (navigationRefSetter?.isReady?.()) {
    CommonActions.reset({
      index: 0, // The active route index in the routes array after the reset
      routes: [
        {name: 'AppLoadingPage'}, // Replace 'InitialScreen' with the name of the screen you want to reset to
      ],
    });
  }
}

// Gets the current screen from navigation state

interface RoutesState {
  index: number;
  routes: {
    index: number;
    key: string;
    name: string;
    state: RoutesState;
  }[];
}

export const getActiveRouteName = (state: RoutesState): string => {
  const route = state.routes[state.index];

  if (route.state) {
    // Dive into nested navigators
    return getActiveRouteName(route.state);
  }

  return route.name;
};

export function getCurrentScreen(): string {
  const currentNavState = navigationRefSetter?.getState?.();

  // @ts-ignore error with their code
  const currentScreen = getActiveRouteName(currentNavState);
  console.log({currentScreen});

  return currentScreen;
}

export function goBack(caller: string, allowGoBackBeyondHome = false): void {
  const currentScreen = getCurrentScreen();
  console.log_on_production(
    `\x1b[32m\x1b[36m\x1b[4m\x1b[1mNavigation Action - goBack\x1b[0m`,
    {
      caller,
      allowGoBackBeyondHome,
      currentScreen,
    },
  );
  if (navigationRefSetter?.isReady?.()) {
    if (!allowGoBackBeyondHome && currentScreen === 'LandingPageMain') {
      return console.log('un-allowed navigation');
    }

    console.log('calling go back');
    navigationRefSetter.goBack();
  }
}

const closeModal = (modalNameToCheck: string, caller: string): void => {
  const currentScreen = getCurrentScreen();
  if (currentScreen === modalNameToCheck) {
    console.log('Navigation Action - closeModal - fired', {
      currentScreen,
      modalNameToCheck,
      caller,
    });
    // eslint-disable-next-line no-use-before-define
    navigationRef?.goBack('Nav/navigationHelpers.ts');
  } else {
    console.log(
      `\x1b[32m\x1b[36m\x1b[4m\x1b[1mNavigation Action - closeModal - already closed\x1b[0m`,
      {
        currentScreen,
        modalNameToCheck,
        caller,
      },
    );
  }
};

const simpleAlert = (
  title: string,
  body?: string,
  confirmText = 'Okay',
  handleConfirm?: () => void,
): void => {
  navigate(
    'ConfirmModal',
    {
      titleText: title,
      titleBodyText: body,
      confirmText: confirmText,
      handleConfirm: handleConfirm,
    },
    'Nav/navigationHelpers.ts simpleAlert',
  );
};

const devAlert = (data: unknown, multiAlert = 0, allowNotOnDev = 0): void => {
  console.log('devAlert fired');
  if (__DEV__ || allowNotOnDev) {
    const alertOpen = getReduxState(
      (state) => state?.current_screen?.alertOpen,
    );
    if (!alertOpen) {
      !multiAlert && dispatchAction(setAlertOpen(true));
      Alert.alert(
        '' + alertOpen,
        typeof data == 'object' ? JSON.stringify(data) : data + '',
        [
          {
            text: 'Close',
            onPress: () => !multiAlert && dispatchAction(setAlertOpen(false)),
          },
        ],
      );
    }
  }
};

export const navigationRef = {
  navigate,
  navigationRef: navigationRefSetter,
  goBack,
  reset,
  closeModal,
  simpleAlert,
  getCurrentScreen,
  devAlert,
};

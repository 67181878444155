import {TouchableOpacity, View} from 'react-native';
import {BodyText} from 'src/Modules/CommonView';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {setCurrentChat} from 'src/Redux/reducers/chats.reducer';
import {ProgressBar} from 'src/ScavengerHunt_v2/Classic/Header';
import { useExplorerPass } from 'src/Utils/explorerPassHelper';
import getAppType from 'src/Utils/getAppType';
import {dispatchAction} from 'src/Utils/helpers';
import constants from 'src/constants';
import {TPAChatThread} from 'src/types/TPAChat';

const PARequest = ({request}: {request: TPAChatThread}) => {
  const isLRApp = getAppType() === 'lr';

  const hasExplorerPass = useExplorerPass();

  return (
    <TouchableOpacity
      key={request.UUID}
      onPress={() => {
        if(!hasExplorerPass){
          return navigationRef.navigate(
            'FreeTrialScreen',
            null,
            'LandingPage/PARequest.tsx',
          );  
        }
        dispatchAction(setCurrentChat(request.UUID));

        navigationRef.navigate(
          'PAChat',
          {chatId: request.UUID},
          'LandingPage/PARequest.tsx',
        );
      }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: 'white',
          justifyContent: 'space-between',
          borderRadius: 8,
          paddingRight: 16,
          paddingTop: 20,
          paddingBottom: 18,
          marginTop: 14,
          opacity: request.status === 'Completed' ? 0.7 : 1,
        }}>
        <View
          style={{
            display: 'flex',
          }}>
          <BodyText
            text={request.title
              .split(' ') // Split the title into an array of words
              .slice(0, 3) // Take the first three words
              .map((word = '' as string, index = 0 as number) =>
                index === 0
                  ? word.charAt(0).toUpperCase() + word.slice(1)
                  : word,
              ) // Capitalize the first word and keep the rest as is
              .join(' ')}
            textStyle={{
              color: 'black',
              marginTop: 0,
              fontSize: 14,
              fontWeight: '600',
              fontFamily: 'JakartaBold',
            }}
          />
          <BodyText
            text={
              request.title.length <= 35
                ? request.title
                : `${request.title.substring(0, 35)}...`
            }
            textStyle={{
              color: 'black',
              marginTop: 8,
              fontSize: 12,
              fontWeight: '400',
              lineHeight: 16,
              fontFamily: 'Jakarta',
            }}
          />
          <BodyText
            text={request.status}
            textStyle={{
              color: isLRApp ? constants.color.orange : '#9A5D70',
              marginTop: 8,
              fontSize: 12,
              fontWeight: '600',
              lineHeight: 16,
              fontFamily: 'JakartaBold',
            }}
          />
        </View>
        <View>
          <ProgressBar
            percentage={
              request.status === 'Started'
                ? 10
                : request.status === 'In Progress'
                ? 50
                : request.status === 'Completed'
                ? 100
                : 0
            }
            size={50}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default PARequest;

import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {ScrollView, View, FlatList} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

///////////////////////////
///////////// components

import {
  TopTab,
  TopTabButton,
  BottomTab,
  BottomTabButton,
} from 'src/Modules/TabViews';

import {
  BodyText,
  AchievementIcon,
  PlayerScoreCard,
  PhotoTile,
} from 'src/Modules/CommonView';

///////////////////////////
///////////// modules
import {achievementsData} from 'src/Data/Achievements';
import {navigationRef} from 'src/Nav/navigationHelpers';
import useParams from 'src/Hooks/useParams';
import {TGroupPlayer} from 'src/types/TGroupInfo';
import {TAchievements} from 'src/types/TAchievements';
import {TPhoto} from 'src/types/TPhoto';
import {TChallenge} from 'src/types/TChallenge';

const HuntFinishedPlayer: React.FC = () => {
  const {width} = useWindowDimensionsSafe();

  const {userId = ''} = useParams<'HuntFinishedPlayer'>();
  const playerChallengePhotos =
    useTypedSelector(
      (state) => state.playerChallenges?.playerChallengePhotos,
    ) || [];

  const allChallenges =
    useTypedSelector((state) => state.game_v2?.allChallenges) || [];
  const players = useTypedSelector(
    (state) => state.group?.info?.players,
    deepEqualityCheck,
  );
  const characters = useTypedSelector(
    (state) => state.themeInfo?.characters,
    deepEqualityCheck,
  );

  const [view, setView] = useState<string>('achievements'); // [ 'achievements', 'photos' ]

  const viewedUserId = userId;

  const player: TGroupPlayer = players
    ? players[viewedUserId]
    : ({} as TGroupPlayer);
  const achievements = player?.achievements || [];

  const locationPhotos = Object.values(allChallenges).filter((challenge) => {
    return (
      (challenge.type === 'photo' || challenge.type === 'video') &&
      challenge.photoUrl
    );
  });

  const photoObjects: (TPhoto | TChallenge)[] = [
    ...playerChallengePhotos,
    ...locationPhotos,
  ];

  const photosToRender =
    (!!photoObjects?.length &&
      photoObjects.filter((item) => {
        return (
          (item.downloadURL || item.photoUrl) &&
          (item.userId === viewedUserId ||
            (item.completions && item.completions[viewedUserId]))
        );
      })) ||
    [];

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: constants?.color?.white}}>
      <PlayerScoreCard
        player={player}
        color={constants?.color?.orange}
        index={0}
        handlePress={() => null}
        cardWidth={width}
        characters={characters}
        containerStyle={{
          marginHorizontal: 0,
          marginVertical: 0,
          elevation: 4,
        }}
      />
      <TopTab>
        <TopTabButton
          index={1}
          text={'Achievements'}
          handlePress={() => setView('achievements')}
          active={view === 'achievements'}
        />
        {!!photosToRender?.length && (
          <TopTabButton
            index={2}
            text={'Photos'}
            handlePress={() => setView('photos')}
            active={view === 'photos'}
          />
        )}
      </TopTab>
      <ScrollView style={{flex: 1}}>
        {!!player && (
          <View style={{flex: 1}}>
            {view === 'achievements' && (
              <View style={{paddingTop: 10}}>
                {achievements?.map?.((key, index) => {
                  const achievement = achievementsData[key];
                  return (
                    <AchievementRow
                      key={`ach_${viewedUserId}_${index}`}
                      achievement={achievement}
                    />
                  );
                })}
              </View>
            )}

            {view === 'photos' && (
              <View style={{paddingTop: 10}}>
                {!!photosToRender?.length ? (
                  <FlatList
                    data={photosToRender}
                    removeClippedSubviews
                    keyExtractor={(item, index) => {
                      return `${item.UUID || item.challenge_id}_${index}`;
                    }}
                    contentContainerStyle={{paddingBottom: 60}}
                    renderItem={({item, index}) => {
                      return (
                        <PhotoTile
                          key={`photo_${index}`}
                          source={
                            item.thumbnail ||
                            item.photoUrl ||
                            item.downloadURL ||
                            ''
                          }
                          index={index}
                          handlePress={() => {
                            navigationRef.navigate(
                              'PhotoVideoCamera',
                              {
                                text:
                                  item?.challengeText ||
                                  item?.challenge ||
                                  item.question ||
                                  item.task ||
                                  item?.description ||
                                  ' ',
                                photoUrl:
                                  item.photoURL ||
                                  item.photoUrl ||
                                  item.downloadURL ||
                                  (item.completion
                                    ? item.completion.photoUrl
                                    : null),
                                onReport: () => {
                                  navigationRef.navigate(
                                    'ReportModal',
                                    null,
                                    'ScavengerHunt_v2/HuntFinishedPlayer.tsx',
                                  );
                                },
                                shareUUID: item.shareUUID || undefined,
                                isVideo:
                                  item.type === 'video' ||
                                  item.type === 'Video',
                              },
                              'ScavengerHunt_v2/HuntFinishedPlayer.tsx',
                            );
                          }}
                        />
                      );
                    }}
                  />
                ) : (
                  <View>
                    <BodyText text={'No photos found..'} />
                  </View>
                )}
              </View>
            )}
          </View>
        )}
      </ScrollView>
      <BottomTab height={55} tabStyle={{alignItems: 'flex-start'}}>
        <BottomTabButton
          text={'Back'}
          iconName={'arrow-left'}
          iconPack={'FontAwesome'}
          handlePress={() => {
            console.log('HuntFinished navigate3');
            navigationRef.navigate(
              'HuntFinished',
              null,
              'ScavengerHunt_v2/HuntFinishedPlayer.tsx',
            );
          }}
          buttonStyle={{marginTop: 5}}
          active={false}
        />
      </BottomTab>
    </SafeAreaView>
  );
};

const AchievementRow = ({achievement}: {achievement: TAchievements}) => {
  const {
    color,
    description,
    icon,
    iconSet,
    level = '',
    name = '',
  } = achievement;

  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderBottomColor: constants?.color?.white1,
        borderBottomWidth: 2,
      }}>
      <View style={{alignItems: 'center', minWidth: 120, marginVertical: 10}}>
        <AchievementIcon iconSet={iconSet} color={color} icon={icon} />
        <BodyText
          text={`${name}`}
          textStyle={{fontSize: 10, marginTop: 5, fontWeight: '700'}}
        />
        {!!level && (
          <BodyText
            text={`(${level})`}
            textStyle={{fontSize: 10, marginTop: 0, marginBottom: 0}}
          />
        )}
      </View>
      <BodyText
        text={description}
        textStyle={{
          maxWidth: 220,
          lineHeight: 20,
          marginTop: 0,
          fontSize: 14,
        }}
      />
    </View>
  );
};

export default HuntFinishedPlayer;

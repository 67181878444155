import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useRef, useState} from 'react';
import {View, Text} from 'react-native';
import ExpoImage from 'src/Utils/ExpoImage';
import MapView, {Marker} from 'src/Modules/Native/MapView';
import {updateSelectedLocalLocationId} from 'src/Redux/reducers/local.reducer';
import SimpleStarRating from 'src/Modules/CommonView/SimpleStarRating';
import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';
import {dispatchAction, getReduxState, isNumeric} from 'src/Utils/helpers';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {TLocalLocation} from 'src/types/TLocalLocation';
import {TLocation} from 'src/types/TLocation';
import {ExpandFullscreenButton} from 'src/Modules/CommonView';
import {TRegion} from 'src/types/TRegion';
import {Clusterer} from 'react-native-clusterer';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

type TPoint = {
  type: 'Feature';
  geometry: {
    type: 'Point';
    coordinates: [number, number];
  };
  properties: TLocation | TLocalLocation;
};

const LocalMapWrapper = ({
  showWanderlistLocations = false,
  wanderlistStops = [],
  isModal = false,
}: {
  showWanderlistLocations?: boolean;
  wanderlistStops?: TLocation[];
  isModal?: boolean;
}) => {
  const localLocationsList = useTypedSelector(
    (state) => state.local.localLocationsList,
  );

  const roundedLat = useTypedSelector(
    (state) => state.location.roundedRegion?.latitude,
  );
  const roundedLong = useTypedSelector(
    (state) => state.location.roundedRegion?.longitude,
  );

  const unClusteredPoints: TPoint[] = [];

  let initialRegion;

  const locations: TLocation[] | TLocalLocation[] =
    (showWanderlistLocations ? wanderlistStops : localLocationsList) || [];

  if (showWanderlistLocations && wanderlistStops) {
    initialRegion = {
      latitude: 1.0 * (wanderlistStops[0]?.lat || 0),
      longitude: 1.0 * (wanderlistStops[0]?.long || 0),
      latitudeDelta: 3,
      longitudeDelta: 3,
    };
  } else {
    initialRegion = {
      latitude: 1.0 * (roundedLat || 0),
      longitude: 1.0 * (roundedLong || 0),
      latitudeDelta: 0.5,
      longitudeDelta: 0.5,
    };
  }
  locations?.map((location) => {
    const {lat, long} = location;

    if (
      isNumeric(lat) &&
      isNumeric(long) &&
      Number(lat) !== 0 &&
      Number(long) !== 0 &&
      lat !== null &&
      long !== null
    ) {
      unClusteredPoints.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [1 * long, 1 * lat],
        },
        properties: {
          ...location,
        },
      });
    }
  });

  return (
    <LocalMap
      unClusteredPoints={unClusteredPoints}
      initialRegion={initialRegion}
      isModal={isModal}
      showWanderlistLocations={showWanderlistLocations}
    />
  );
};

const LocalMap = ({
  unClusteredPoints = [],
  initialRegion,
  isModal = false,
  showWanderlistLocations = false,
}: {
  showWanderlistLocations?: boolean;
  unClusteredPoints?: TPoint[];
  isModal?: boolean;
  initialRegion: TRegion;
}) => {
  const mapRef = useRef<MapView | null>(null);

  const [isZoomed, setIsZoomed] = useState<boolean>(true);

  console.log({isZoomed});

  const [region, setRegion] = useState(initialRegion);

  const {width, height} = useWindowDimensionsSafe();

  const MAP_DIMENSIONS = {width, height: isModal ? height : 200};
  const DEFAULT_OPTIONS = {};

  return (
    <View style={{position: 'relative', flex: 1}}>
      {!!initialRegion && !!Object.keys(initialRegion).length && <MapView
        initialRegion={initialRegion || {
          latitude: 0,
          longitude: 0,
          latitudeDelta: 1,
          longitudeDelta: 1,
        }}
        showsUserLocation
        showsPointsOfInterest={false}
        legalLabelInsets={{bottom: -20, left: -20, right: -20, top: -20}}
        onRegionChangeComplete={(region) => {
          const {latitudeDelta} = region;
          const newIsZoomed = latitudeDelta < 2;
          setRegion(region);

          if (newIsZoomed !== isZoomed) {
            console.log({newIsZoomed, isZoomed, latitudeDelta});
            setIsZoomed(newIsZoomed);
            // Alert.alert('hi' + region.latitudeDelta);
          }
        }}
        customMapStyle={[
          {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'poi',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            featureType: 'transit',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
        ]}
        ref={mapRef}
        style={{flex: 1}}>
        {region && <Clusterer
          data={unClusteredPoints}
          region={region || {
            latitude: 0,
            longitude: 0,
            latitudeDelta: 1,
            longitudeDelta: 1,
          }}
          options={DEFAULT_OPTIONS}
          mapDimensions={MAP_DIMENSIONS}
          renderItem={(item) => {
            const key = `${isZoomed} ${JSON.stringify(item)}`;
            const lat = item.geometry.coordinates[1];
            const long = item.geometry.coordinates[0];

            const MarkerType = showWanderlistLocations
              ? WanderlistMarker
              : LocalMarker;
            // pointsRendered++;
            // console.log({pointsRendered});
            if (item.properties?.cluster_id) {
              const handlePress = async () => {
                const newRegion = item.properties?.getClusterExpansionRegion();
                newRegion.latitudeDelta = newRegion.latitudeDelta * 0.9;
                newRegion.longitudeDelta = newRegion.longitudeDelta * 0.9;
                mapRef.current?.animateToRegion(newRegion);
              };

              return (
                <Marker
                  onPress={handlePress}
                  coordinate={{latitude: (1 * lat) || 0, longitude: (1 * long) || 0}}
                  key={`cluster-${item.properties?.cluster_id}`}>
                  <View
                    style={{
                      minWidth: 30,
                      height: 30,
                      borderRadius: 15,
                      backgroundColor: 'green',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <Text
                      style={{
                        color: 'white',
                        fontWeight: 'bold',
                      }}>
                      {item.properties?.point_count}
                    </Text>
                  </View>
                </Marker>
              );
            }
            return (
              <MarkerType
                key={key}
                coordinate={{latitude: (1 * lat) || 0, longitude: (1 * long) || 0}}
                mapRef={mapRef}
                isZoomed={isZoomed}
                // @ts-ignore
                location={item.properties}
              />
            );
          }}
        />}
      </MapView>}

      <ExpandFullscreenButton
        isFullscreen={isModal}
        minimizedSize={40}
        handlePress={() => {
          isModal
            ? navigationRef.goBack('LocalMapModal')
            : navigationRef.navigate(
                'LocalMapModal',
                null,
                'SimpleStackNavigator',
              );
        }}
      />
    </View>
  );
};

const LocalMarker = ({
  coordinate,
  mapRef,
  isZoomed = false,
  location,
}: {
  coordinate: {latitude: number; longitude: number};
  mapRef: React.MutableRefObject<MapView | null>;
  isZoomed?: boolean;
  location: TLocalLocation;
}) => {
  const latitude = coordinate?.latitude || 0;
  const longitude = coordinate?.longitude || 0;

  return (
    <Marker
      coordinate={coordinate || {
        latitude: 0,
        longitude: 0
      }}
      onPress={() => {
        if (!isZoomed) {
          return mapRef.current?.animateToRegion({
            latitude: latitude || 1,
            longitude: longitude || 1,
            latitudeDelta: 0.2,
            longitudeDelta: 0.2,
          });
        } else {
          // navigate user to location
          // dispatchAction(updateSelectedLocalLocationId(String(location.id)));
          // const userId = getReduxState((state) => state?.user?.userId);
          // if (userId) {
          //   logCustomAnalyticsEvent('local_spot_view', {...location, userId});
          // }
          // navigationRef.navigate('LocalSiteModal', null, 'Local/LocalMap.tsx');
        }
      }}>
      {!!isZoomed && (
        <View
          style={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: 'white',
            padding: 4,
            borderRadius: 10,
            shadowOffset: {width: 0, height: 2},
            shadowOpacity: 0.2,
            shadowRadius: 3,
            elevation: 1,
          }}>
          <ExpoImage
            source={{uri: location.photo}}
            style={{height: 20, width: 20, borderRadius: 10}}></ExpoImage>
          <View>
            <Text style={{marginLeft: 10, fontSize: 12, maxWidth: 100}}>
              {location?.title}{' '}
              <SimpleStarRating size={10} rating={location.rating} />
            </Text>
          </View>
        </View>
      )}
    </Marker>
  );
};

const WanderlistMarker = ({
  coordinate,
  mapRef,
  isZoomed = false,
  location,
}: {
  coordinate: {latitude: number; longitude: number};
  mapRef: React.MutableRefObject<MapView | null>;
  isZoomed?: boolean;
  location: TLocation;
}) => {
  const latitude = coordinate?.latitude || 0;
  const longitude = coordinate?.longitude || 0;

  const locationPhoto = 'https://photos.letsroam.com/' + location?.photoLarge;

  return (
    <Marker
      coordinate={coordinate || {
        latitude: 0,
        longitude: 0
      }}
      onPress={() => {
        if (!isZoomed) {
          return mapRef.current?.animateToRegion({
            latitude: latitude || 1,
            longitude: longitude || 1,
            latitudeDelta: 0.2,
            longitudeDelta: 0.2,
          });
        } else {
          dispatchAction(
            updateSelectedLocalLocationId(String(location.locationId)),
          );
          const userId = getReduxState((state) => state?.user?.userId);
          if (userId) {
            logCustomAnalyticsEvent('local_spot_view', {...location, userId});
          }
          navigationRef.navigate('LocalSiteModal', null, 'Local/LocalMap.tsx');
        }
      }}>
      {!!isZoomed && (
        <View
          style={{
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            flexDirection: 'row',
            backgroundColor: 'white',
            padding: 4,
            borderRadius: 10,
          }}>
          <ExpoImage
            source={{uri: locationPhoto}}
            style={{height: 20, width: 20, borderRadius: 10}}></ExpoImage>
          <View>
            <Text style={{marginLeft: 10, fontSize: 12, maxWidth: 100}}>
              {location.name}
            </Text>
          </View>
        </View>
      )}
    </Marker>
  );
};

export default LocalMapWrapper;

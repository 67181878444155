import {Dimensions} from 'react-native';
import {THunt} from 'src/types/THunt';
import {TLocation} from 'src/types/TLocation';
import {TRegion} from 'src/types/TRegion';

const {width, height} = Dimensions.get('screen');

export function calculateDistance(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
  unit: string,
) {
  if (
    lat1 != null &&
    lon1 != null &&
    lat2 != null &&
    lon2 != null &&
    unit != null
  ) {
    const radLat1 = (Math.PI * lat1) / 180;
    const radLat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radTheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radLat1) * Math.sin(radLat2) +
      Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == 'K') {
      dist = dist * 1.609344;
    }
    if (unit == 'MILES') {
      // dist = dist;
    }
    if (unit == 'N') {
      dist = dist * 0.8684;
    }
    if (unit == 'METERS') {
      dist = dist * 1.609344 * 1000;
    }
    if (unit == 'FEET') {
      dist = dist * 1.609344 * 5280;
    }
    return dist;
  } else {
    return -1;
  }
}

export function orderLocationListByDistanceAway(
  list: TLocation[],
  latitude: number,
  longitude: number,
) {
  const newLocalLocationsList = list;
  if (!latitude) {
    return newLocalLocationsList;
  }
  let newListWithDistance: TLocation[] = [];
  newLocalLocationsList?.map?.((location) => {
    let distanceAway = undefined;
    if (latitude && longitude) {
      distanceAway = calculateDistance(
        location.lat,
        location.long,
        latitude,
        longitude,
        'MILES',
      );
      distanceAway =
        distanceAway > 10
          ? Math.round(distanceAway)
          : Math.round(distanceAway / 10) * 10;
    }
    newListWithDistance.push({
      ...location,
      distanceAway,
    });
  });
  newListWithDistance = newListWithDistance.sort((locA, locB) => {
    return (locA?.distanceAway || 0) - (locB?.distanceAway || 0);
  });
  return newListWithDistance;
}

export function roundLatLong(lat: number, long: number) {
  console.log('roundLatLong', {lat, long});
  const closestPoints = [
    [Math.floor(lat), Math.floor(long)],
    [Math.floor(lat), Math.ceil(long)],
    [Math.ceil(lat), Math.floor(long)],
    [Math.ceil(lat), Math.ceil(long)],
  ];
  let closestPoint = {lat: Math.round(lat), long: Math.round(long)};
  let closestDistance = 1000000000000000000000;
  closestPoints?.map?.((point) => {
    const distance = calculateDistance(point[0], point[1], lat, long, 'M');
    if (distance < closestDistance) {
      closestDistance = distance;
      closestPoint = {lat: point[0], long: point[1]};
    }
  });
  console.log({closestPoint});
  return closestPoint;
}

function calculateRegion(
  lat: number,
  lon: number,
  distanceMeters: number,
  closeUp: boolean,
) {
  const distance = distanceMeters / 2;
  const circumference = 40075;
  const oneDegreeOfLatitudeInMeters = 111.32 * 1000;
  const angularDistance = distance / circumference;

  const aspectRatio = width / (height - 200);

  const latitudeDelta = distance / oneDegreeOfLatitudeInMeters;
  const longitudeDelta =
    Math.abs(
      Math.atan2(
        Math.sin(angularDistance) * Math.cos(lat),
        Math.cos(angularDistance) - Math.sin(lat) * Math.sin(lat),
      ),
    ) / aspectRatio;

  const longitudeDeltaOffset = distanceMeters > 500000 ? 20 : longitudeDelta;
  const result = {
    latitude: closeUp ? lat : lat - 0.01,
    longitude: lon,
    latitudeDelta: latitudeDelta,
    longitudeDelta: longitudeDeltaOffset,
  };

  return result;
}

export function getInitialRegionForCoordinates(
  coordinates: Record<string, {lat: number; long: number}> = {},
  fudgeFactor: number = 1.5,
) {
  const coordinatesArray = Object.values(coordinates);
  // Default region around Denver if no coordinatesArray are provided
  if (
    coordinatesArray.length === 0 ||
    !coordinatesArray ||
    !coordinatesArray?.[0]
  ) {
    return {
      latitude: 39.7392,
      longitude: -104.9903,
      latitudeDelta: 0.0922,
      longitudeDelta: 0.0421,
    };
  }

  let minLatitude = coordinatesArray[0].lat;
  let maxLatitude = coordinatesArray[0].lat;
  let minLongitude = coordinatesArray[0].long;
  let maxLongitude = coordinatesArray[0].long;

  coordinatesArray?.map((location) => {
    minLatitude = Math.min(minLatitude, location.lat);
    maxLatitude = Math.max(maxLatitude, location.lat);
    minLongitude = Math.min(minLongitude, location.long);
    maxLongitude = Math.max(maxLongitude, location.long);
  });

  console.log({minLatitude, maxLatitude, minLongitude, maxLongitude});

  const midLatitude = (minLatitude + maxLatitude) / 2;
  const midLongitude = (minLongitude + maxLongitude) / 2;

  const latitudeDelta = Math.max(maxLatitude - minLatitude, 0.01) * fudgeFactor;
  const longitudeDelta =
    Math.max(maxLongitude - minLongitude, 0.01) * fudgeFactor;

  console.log({minLatitude, maxLatitude, minLongitude, maxLongitude});

  return {
    latitude: midLatitude,
    longitude: midLongitude,
    latitudeDelta,
    longitudeDelta,
  };
}

export function calculateRegionFromPoints(
  lat1: number,
  long1: number,
  lat2: number,
  long2: number,
  closeUp: boolean = false,
) {
  const distance = calculateDistance(lat1, long1, lat2, long2, 'METERS');
  const scaleDistance = closeUp ? distance + 200 : distance + 1000;

  return calculateRegion(lat1, long1, scaleDistance, closeUp);
}

export const orderHuntsByProximity = (
  hunts: THunt[],
  region: TRegion,
): THunt[] => {
  const latitude = region?.latitude || 0;
  const longitude = region?.longitude || 0;

  if (latitude && longitude) {
    return hunts
      ?.map?.((hunt) => {
        const {lat, long} = hunt;
        const distanceAway: number = calculateDistance(
          latitude,
          longitude,
          parseFloat(`${lat || 0}`),
          parseFloat(`${long || 0}`),
          'N',
        );

        return {
          ...hunt,
          distanceAway: distanceAway,
          distanceAwayNumber: distanceAway,
          isBarHunt: hunt.hunt_type == 'barHunt',
        };
      })
      ?.sort?.((a, b) => {
        const aRating = (!!a?.total_reviews ? a?.star_rating : 4) || 4;
        const bRating = (!!b?.total_reviews ? b?.star_rating : 4) || 4;
        const ratingDiff = bRating - aRating;

        let distanceWeighted =
          a?.distanceAwayNumber - b?.distanceAwayNumber + 5 * ratingDiff;
        if (a?.hunt_type == 'barHunt') {
          distanceWeighted += 0.5;
        }
        if (b?.hunt_type == 'barHunt') {
          distanceWeighted -= 0.5;
        }
        return distanceWeighted;
      });
  }
  return hunts;
};

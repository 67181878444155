import moment from 'moment';
import 'react-native-get-random-values';
import {v4 as uuidv4} from 'uuid';
import {Alert} from 'react-native';
import {
  setDataAtPath,
  updateDataAtPath,
  logForCrashlytics,
  updateMultiDataAtPaths,
  getDataAtPath,
} from 'src/Utils/fireUtils';
import {getReduxState} from 'src/Utils/helpers';
import {TLocation} from 'src/types/TLocation';
import {TChallenge} from 'src/types/TChallenge';
import {TNavigationRouteParams} from 'src/types/TNavigationRouteParams';

export const setNavigationStartTime = (locationId: string) => {
  logForCrashlytics('setNavigationStartTime fired in huntFunctions_v2');
  const groupId = getReduxState((state) => state?.group?.info?.groupId);

  if (groupId && locationId) {
    const path = `scavengerhunt/completions/${groupId}/location_v2/locations/${locationId}/startTime`;
    const startTime = Date.now();

    updateDataAtPath(path, startTime);
  }
};

export const setNavigationCompleteTime = async (
  locationId: string,
  location: TLocation,
  distanceAway: number = 9999,
) => {
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const userId = getReduxState((state) => state?.user?.userId);

  logForCrashlytics('setNavigationCompleteTime fired in huntFunctions_v2');
  console.log('setNavigationCompleteTime', {location});
  try {
    // correcting for bar hunt
    // barhunt!!!!
    if (location?.originalLocation) {
      // barhunt!!!!
      const path = `scavengerhunt/completions/${groupId}/location_v2/locations/${location?.originalLocation}`;

      // barhunt!!!!
      const completeTime = Date.now();

      // barhunt!!!!
      const data = {
        completeTime,
        challengeCompletingUserId: userId,
        groupPointsEarned: location?.points || 500,
      };

      await updateDataAtPath(path, data);
    }

    // regular hunt
    // original location
    const path = `scavengerhunt/completions/${groupId}/location_v2/locations/${locationId}`;

    // regular hunt
    const completeTime = Date.now();

    // https://www.wolframalpha.com/input?i=%28450+*+%282+%5E+%28-x+%2F5000%29%29%29+%2B+50+from+0+to+10000
    //  starts at 400 goes to 50 as x approaches infinity
    var distanceAwayBonusPoints = 50;
    if (!isNaN(distanceAway)) {
      const baseDistanceAway = __DEV__ ? 500 * 1000 : 10 * 1000;
      distanceAwayBonusPoints = Math.round(
        390 * Math.pow(2, -distanceAway / baseDistanceAway) + 10,
      );
    }
    const data = {
      completeTime,
      challengeCompletingUserId: userId,
      distanceAway,
      distanceAwayBonusPoints,
    };

    console.log('setting points now', {data});

    // adding bonus points

    return await updateDataAtPath(path, data);
  } catch (error: unknown) {
    console.log(error);
  }
};

export const setLocationCompleteTime = (locationId: string) => {
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const userId = getReduxState((state) => state?.user?.userId);
  logForCrashlytics('setNavigationCompleteTime fired in huntFunctions_v2');

  try {
    const path = `scavengerhunt/completions/${groupId}/location_v2/locations/${locationId}`;

    const locationChallengesCompleteTime = Date.now();

    const data = {
      locationChallengesCompleteTime,
      completingUserId: userId,
    };

    return updateDataAtPath(path, data);
  } catch (error: unknown) {
    console.log(error);
  }
};

export const setChallengeStartTime = async (challengeId: string) => {
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  logForCrashlytics('setChallengeStartTime fired in huntFunctions_v2', {
    challengeId,
    groupId,
  });

  if (groupId && challengeId) {
    const isPlayerChallenge = challengeId.startsWith('cp_');
    let path;
    if (isPlayerChallenge) {
      path = `scavengerhunt/completions/${groupId}/player/${challengeId}/startTime`;
    } else {
      path = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/startTime`;
    }
    const startTime = Date.now();

    await updateDataAtPath(path, startTime);
  }
};

const handleScoreOrChallenge = (
  nextChallengeId: string,
  challenges: Record<string, TChallenge>,
): {
  route: keyof TNavigationRouteParams;
  params: {
    challengeId: string;
  };
} => {
  logForCrashlytics('handleScoreOrChallenge fired in huntFunctions_v2');

  if (nextChallengeId && challenges[nextChallengeId]?.completeTime) {
    return {
      route: 'LocationChallengeScore',
      params: {
        challengeId: nextChallengeId,
      },
    };
  }
  return {
    route: 'LocationChallenges',
    params: {
      challengeId: nextChallengeId,
    },
  };
};

export const setChallengeCompleteTime = (challengeId: string) => {
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  logForCrashlytics('setChallengeCompleteTime fired in huntFunctions_v2', {
    groupId,
    challengeId,
  });

  if (groupId && challengeId) {
    const path = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/completeTime`;
    const completeTime = Date.now();

    updateDataAtPath(path, completeTime);
  }
};

export const handleBackupLocationClosed = async ({
  locationId,
  backupLocationList = [],
  lastUsedBackUpBar,
  backupLocationForLocation,
  useBackupChallenges = false,
}: {
  locationId: string;
  backupLocationList?: TJSONArray;
  lastUsedBackUpBar?: TLocation;
  backupLocationForLocation?: string;
  useBackupChallenges?: boolean;
}) => {
  logForCrashlytics('handleBackupLocationClosed fired in huntFunctions_v2');

  const groupId = getReduxState((state) => state?.group?.info?.groupId);

  console.log('handleBackupLocationClosed', {
    locationId,
    backupLocationList,
    lastUsedBackUpBar,
    backupLocationForLocation,
  });

  // ensure groupId is defined.
  if (!groupId) {
    console.error('Group ID is undefined. Cannot activate backup challenges.');
    return;
  }

  // if b2c non bar hunt grab 2 backup challenges and set show to true which will make them appear in the challenge list
  if (useBackupChallenges) await activateBackupChallenges(groupId);

  // determine the next location.
  const nextLocation = getNextBackupLocation(
    backupLocationList,
    lastUsedBackUpBar,
  );

  // update the closed location and backup location.
  await updateLocationStatus(groupId, locationId, nextLocation);

  // update the classic challenge list.
  await updateClassicChallengeList(groupId, locationId, nextLocation);

  // generate and display a confirmation message.
  let message = 'Thank you! Our team will review this location.';

  // if there's a backup location, add that to the message
  if (nextLocation) {
    message +=
      ' We’ve also added a new stop to your hunt to keep things on track.';
  }

  // add extra challenges message if applicable
  if (useBackupChallenges) {
    message += ' Plus, enjoy some extra challenges to keep the fun going!';
  }

  return Alert.alert('', message);
};

const activateBackupChallenges = async (groupId: string) => {
  try {
    const allChallengesPath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges`;

    // Fetch all challenges
    let allChallenges =
      ((await getDataAtPath(allChallengesPath)) as {
        [key: string]: TChallenge;
      }) || {};
    const hiddenBackupChallengeKeys = Object.keys(allChallenges).filter(
      (key) => allChallenges[key].isBackupChallenge && !allChallenges[key].show,
    );

    if (hiddenBackupChallengeKeys.length === 0) {
      console.log('No more backup challenges to activate.');
      return;
    }

    hiddenBackupChallengeKeys.slice(0, 2).forEach((key) => {
      allChallenges[key].show = true;
    });

    await updateDataAtPath(allChallengesPath, allChallenges);
    console.log('Activated backup challenges:', hiddenBackupChallengeKeys);
  } catch (error) {
    console.error('Error activating backup challenges:', error);
  }
};

const getNextBackupLocation = (
  backupLocationList: TJSONArray,
  lastUsedBackUpBar?: TLocation,
) => {
  if (!backupLocationList.length) return null;
  return !lastUsedBackUpBar
    ? backupLocationList[0]
    : lastUsedBackUpBar === backupLocationList[0]
    ? backupLocationList[1]
    : null;
};

const updateLocationStatus = async (
  groupId: string,
  locationId: string,
  nextLocation: string | null,
) => {
  const updatePath = `scavengerhunt/completions/${groupId}/location_v2/locations/${locationId}`;
  const updates = {
    backupLocationForLocation: nextLocation ?? null,
    locationClosed: true,
  };
  await updateDataAtPath(updatePath, updates);
};

const updateClassicChallengeList = async (
  groupId: string,
  locationId: string,
  nextLocation: string | null,
) => {
  const classicLocationListPath = `scavengerhunt/completions/${groupId}/location_v2/classicChallengeList`;
  const currentClassicLocationList = (await getDataAtPath(
    classicLocationListPath,
  )) as string[];

  const indexToRemove = currentClassicLocationList.indexOf(locationId);
  if (indexToRemove > -1) currentClassicLocationList.splice(indexToRemove, 1);

  if (nextLocation) {
    currentClassicLocationList.push(nextLocation);
    await setDataAtPath(
      `scavengerhunt/completions/${groupId}/location_v2/lastUsedBackUpBar`,
      nextLocation,
    );
  }

  await setDataAtPath(classicLocationListPath, currentClassicLocationList);
};

export const markTeamPhotoComplete = ({photoUrl = '', shareUUID = ''}) => {
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const eventId = getReduxState((state) => state?.group?.info?.eventId);
  const userId = getReduxState((state) => state?.user?.userId);
  logForCrashlytics('markTeamPhotoComplete fired in huntFunctions_v2');

  const completionPhotoUrlPath = `scavengerhunt/completions/${groupId}/location_v2/teamPhotoUrl`;
  updateDataAtPath(completionPhotoUrlPath, photoUrl);

  const groupPath = `scavengerhunt/groups/${groupId}/`;
  updateDataAtPath(groupPath, {groupPhoto: photoUrl, score: 500});

  updateDataAtPath(groupPath + `pointComponents/teamPhoto/${groupId}`, 500);

  const photoShareUUIDPath = `scavengerhunt/groups/${groupId}/groupPhotoShareUUID`;
  updateDataAtPath(photoShareUUIDPath, shareUUID);

  const updatedIds = {
    groupId,
    eventId: eventId || null,
  };

  const photoPath = `photos/${groupId}/photos/${shareUUID}`;

  const email = getReduxState((state) => state?.user?.info?.email);
  const teamName = getReduxState((state) => state?.group?.info?.teamName);
  const is_test = email?.includes?.('@lets') || teamName?.includes?.('test');

  const photoCompletionObject = {
    shareUUID: shareUUID,
    groupId,
    eventId: eventId || null,
    userId,
    teamName,
    email,
    completionId: uuidv4(),
    challenge: 'Take an epic team photo',
    type: 'Team Photo Challenge',
    date: Date.now(),
    dateFormatted: moment().format('YYYY-MM-DDTHH:mm:ss.sssZ'),
    downloadURL: photoUrl,
    is_test,
    photoUrl,
    path: photoPath,
  };

  updateDataAtPath(`photos/${groupId}`, updatedIds);

  updateDataAtPath(photoPath, photoCompletionObject);
};

export interface markSelectionChallengeCompleteParams {
  challengeId?: string;
  option?: string | number | null | undefined;
  correct?: boolean | null | undefined;
  key?: string | null | undefined;
  singleCompletion?: boolean;
  allowMultiGuess?: boolean;
  overridePointsEarned?: number; // only used for new hunt style
  hintsDeductions?: number;
  newMaxPoints?: number;
}

export interface ParamsType {
  option: string | number | null | undefined;
  optionKey: string | null;
  userId: string | null | undefined;
  answeredCorrectly: boolean | null;
  answeredAt: number;
  selectedAt: number;
  points?: number; // Optional property
}

export const markSelectionChallengeComplete = async ({
  challengeId = '',
  option,
  correct = false,
  key = '',
  singleCompletion = false,
  overridePointsEarned,
  allowMultiGuess = false,
}: markSelectionChallengeCompleteParams) => {
  logForCrashlytics('markSelectionChallengeComplete fired in huntFunctions_v2');
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const userId = getReduxState((state) => state?.user?.userId);

  console.log('markSelectionChallengeComplete', {groupId, challengeId});

  const allFirebaseUpdates: Record<string, TJSONValue> = {};

  if (groupId && challengeId) {
    const isPlayerChallenge = challengeId.startsWith('cp_');
    console.log('markSelectionChallengeComplete isPlayerChallenge', {
      isPlayerChallenge,
      challengeId,
    });

    let path = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/completions/${userId}`;
    let challengePointsPath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/points`;
    if (isPlayerChallenge) {
      path = `scavengerhunt/completions/${groupId}/player/${challengeId}/completions/${userId}`;
      challengePointsPath = `scavengerhunt/completions/${groupId}/player/${challengeId}/points`;
    }

    const params: ParamsType = {
      option,
      optionKey: key,
      userId,
      answeredCorrectly: correct,
      answeredAt: Date.now(),
      selectedAt: Date.now(),
    };

    allFirebaseUpdates[path] = params;

    if (overridePointsEarned != null && overridePointsEarned >= 0) {
      allFirebaseUpdates[challengePointsPath] = overridePointsEarned;
    }

    ////////////////// JL NOTE
    // this is for classic challenges, so they only get to answer once per group, since the timer isn't active
    if (singleCompletion) {
      let optionSelectPath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/answers/${
        key || 0
      }`;

      if (isPlayerChallenge) {
        optionSelectPath = `scavengerhunt/completions/${groupId}/player/${challengeId}/answers/${
          key || 0
        }`;
      }

      allFirebaseUpdates[optionSelectPath + '/selectedByUser'] = userId;
      allFirebaseUpdates[optionSelectPath + '/selectedAt'] = Date.now();

      if (correct || !allowMultiGuess) {
        const completeTime = Date.now();
        let completeTimePath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/completeTime`;
        if (isPlayerChallenge) {
          completeTimePath = `scavengerhunt/completions/${groupId}/player/${challengeId}/completeTime`;
        }

        allFirebaseUpdates[completeTimePath] = completeTime;
      }
    }
    updateMultiDataAtPaths(allFirebaseUpdates);
  }
};

export const markChallengeScoreReviewedTime = async (challengeId: string) => {
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  logForCrashlytics('markChallengeScoreReviewedTime fired in huntFunctions_v2');
  const completeTime = Date.now();
  const completeTimePath = `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/scoreReviewTime`;

  updateDataAtPath(completeTimePath, completeTime);
};

type ParamsReturnType = void | {
  route: keyof TNavigationRouteParams;
  params: {
    challengeId?: string;
    locationId?: string;
  };
};

export const getNavigateNextParams = (): ParamsReturnType => {
  logForCrashlytics('getNavigateNextParams fired in huntFunctions_v2');
  const hunt = getReduxState((state) => state?.game_v2);

  const currentScreen = getReduxState(
    (state) => state?.current_screen?.currentScreen,
  );

  if (!hunt) {
    return console.error('no data');
  }

  const {
    locations,
    challenges,
    locationList,
    currentLocationId,
    currentChallengeId,
  } = hunt;

  if (!challenges) {
    return console.error('no data');
  }

  // opening slide, go to first location navigation challenge
  //    add check for if is hunt outro
  if (currentLocationId === null && locationList) {
    return {
      route: 'NavigationChallenge',
      params: {locationId: locationList[0]},
    };
  }

  // location complete, go to next location navigation challenge
  //    OR if final location, go to hunt finished
  if (
    currentLocationId &&
    currentChallengeId === null &&
    currentScreen === 'LocationComplete' &&
    locationList
  ) {
    const nextLocationIndex = locationList.indexOf(currentLocationId) + 1;
    const notFinalLocation =
      nextLocationIndex && nextLocationIndex < locationList?.length;

    if (notFinalLocation) {
      return {
        route: 'NavigationChallenge',
        params: {locationId: locationList[nextLocationIndex]},
      };
    } else {
      console.log('HuntOutro navigate3');
      return {
        route: 'HuntOutro',
        params: {},
      };
    }
  }

  // location challenge, go to next location challenge
  //   if final challenge, go to location complete
  if (currentLocationId && currentChallengeId && locations) {
    const huntLocation = locations[currentLocationId] || {};
    const {challengeList = []} = huntLocation;

    const nextChallengeIndex = challengeList?.indexOf?.(currentChallengeId) + 1;
    const notFinalChallenge =
      nextChallengeIndex && nextChallengeIndex < challengeList?.length;

    const nextChallengeId =
      locations[currentLocationId]?.challengeList?.[nextChallengeIndex];
    if (notFinalChallenge && nextChallengeId) {
      return handleScoreOrChallenge(nextChallengeId, challenges);
    } else {
      return {
        route: 'LocationComplete',
        params: {
          locationId: currentLocationId,
        },
      };
    }
  }

  return {route: 'HuntIntro', params: {}};
};

// 3 potential outcomes
// 1 hunt intro
// 2 navigation challenge
// 3 challenge at location
export const getHighestCompletionParams = (): {
  route: keyof TNavigationRouteParams;
  params: {locationId?: string | null; challengeId?: string | null};
  highestUnFinishedLocationId?: string | null;
} | null => {
  const locations = getReduxState((state) => state?.game_v2?.locations);
  const challenges = getReduxState((state) => state?.game_v2?.challenges);
  const locationList = getReduxState((state) => state?.game_v2?.locationList);
  logForCrashlytics('getHighestCompletionParams fired in huntFunctions_v2');

  // used to see if location challenges started
  let hasStartedLocationChallenges = false;

  const unFinishedLocationIds = locationList?.filter((key: string) => {
    const location = locations?.[key];
    if (location?.startTime) {
      hasStartedLocationChallenges = true;
    }
    return !location?.locationChallengesCompleteTime;
  });

  // no locations challenges started so showing intro
  if (!hasStartedLocationChallenges) {
    return {route: 'HuntIntro', params: {}, highestUnFinishedLocationId: null};
  }

  if (!locationList || !locationList?.length) {
    console.log('no location list loaded yet, cant redirect');
    return {
      route: 'HuntLoading',
      params: {},
      highestUnFinishedLocationId: null,
    };
  }

  const highestUnFinishedLocationId = unFinishedLocationIds?.[0];

  // no unfinished challenges so the hunt must be done
  if (!highestUnFinishedLocationId) {
    console.log('HuntOutro navigate2');
    return {route: 'HuntOutro', params: {}, highestUnFinishedLocationId: null};
  }

  console.log({unFinishedLocationIds, highestUnFinishedLocationId});

  const highestUncompletedLocation = locations?.[highestUnFinishedLocationId];
  const {
    locationId,
    challengeList = [],
    completeTime,
  } = highestUncompletedLocation || {};

  // checking if the navigation challenge was completed
  if (!completeTime) {
    return {
      route: 'NavigationChallenge',
      params: {
        locationId: locationId || null,
      },
      highestUnFinishedLocationId,
    };
  }

  // the navigation challenge was complete, so now finding the index of the highest non-competed challenge
  const highestCompletedChallengeId = challengeList.filter((key) => {
    return !challenges?.[key]?.completeTime;
  })?.[0];

  console.log({highestCompletedChallengeId});

  if (!highestCompletedChallengeId) {
    return {
      route: 'LocationComplete',
      params: {
        locationId,
      },
      highestUnFinishedLocationId,
    };
  }

  if (challenges) {
    const params = handleScoreOrChallenge(
      highestCompletedChallengeId,
      challenges,
    );
    return params;
  }

  return {
    route: 'LocationComplete',
    params: {
      locationId,
    },
    highestUnFinishedLocationId,
  };
};

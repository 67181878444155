import React from 'react';

const Video = (props = {
  source: {uri:''},
  style: {},
  resizeMode: 'cover',
  shouldPlay: false,
  isLooping: false,
  useNativeControls: false
}) => {
  return <video src={props?.source?.uri} style={{...props.style}} controls={!!props?.useNativeControls} loop={!!props?.isLooping} autoPlay={!!props?.shouldPlay} />;
};
export default Video;
import {Share, Linking, Alert, Platform} from 'react-native';
import 'react-native-get-random-values';
import {v4 as uuidv4} from 'uuid';

import {
  getDataAtPath,
  updateDataAtPath,
  logForCrashlytics,
} from 'src/Utils/fireUtils';
import moment from 'moment';
import {encode as btoa} from 'base-64';

import {saveToCameraRoll} from 'src/Utils/cameraHelpers';
import {apiTrackUserTransaction} from 'src/Utils/apiCalls';
import {getReduxState} from 'src/Utils/helpers';
import {TUser} from 'src/types/TUser';
import {onScoreResponse} from 'src/types/TNavigationRouteParams';

/**
 * Creates a share link for a given share object.
 * @param {string} shareUUID - The share UUID.
 * @param {string} sharePhoto - The share photo URL.
 * @param {string} quoteDescription - The description for the share.
 * @param {string} thumbnail - The thumbnail for the share (optional).
 */
export const createShareLink = (
  shareUUID: string,
  sharePhoto: string,
  quoteDescription: string,
  thumbnail: string | null = null,
) => {
  const huntId = getReduxState((state) => state?.group?.info?.groupId);
  const teamName = getReduxState((state) => state?.group?.info?.teamName);
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const groupPhoto = getReduxState((state) => state?.group?.info?.groupPhoto);
  const eventId = getReduxState((state) => state?.group?.info?.eventId);
  const barHunt = getReduxState((state) => state?.group?.info?.barHunt);
  const userId = getReduxState((state) => state?.user?.info?.userId);

  console.log('createShareLink fired');
  logForCrashlytics('createShareLink fired in shareHelper');
  try {
    fetch(`https://www.letsroam.com/api/v1/app_shares`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        uuid: shareUUID,
        sharePhoto: thumbnail ? sharePhoto + '.mp4' : sharePhoto,
        quoteDescription,
        thumbnail,
        hunt_id: huntId,
        group_name: teamName,
        group_id: groupId,
        group_photo: groupPhoto || '',
        bar_hunt: barHunt || false,
        user_id: userId || '',
        event_id: eventId || '',
      }),
    });
  } catch (error: unknown) {
    console.log(error);
  }
};

/**
 * Adds share coins to a user's account.
 * @param {string} userId - The user ID.
 * @param {string} shareUUID - The share UUID.
 */
export const addShareCoins = async (userId: string, shareUUID: string) => {
  logForCrashlytics('addShareCoins fired in huntFunctions');

  const user = (await getDataAtPath(`users/${userId}`)) as TUser | null;
  const currentCoins = user?.coins;
  const photoShares = user?.photoShares || {};
  const userHasPreviouslySharedPhoto = photoShares[shareUUID];

  if (!userHasPreviouslySharedPhoto) {
    const shareCoins = 5;
    const newTotalCoins = Number(currentCoins) + shareCoins;
    const photoShares = Date.now();

    updateDataAtPath(`users/${userId}/photoShares/${shareUUID}`, photoShares);
    updateDataAtPath(`users/${userId}/coins`, newTotalCoins);

    apiTrackUserTransaction({
      type: 'share_coins_reward',
      user_id: userId,
      usage_user_id: userId,
      coins: shareCoins,
    });
  }
};

/**
 * Encodes data to base64 format.
 * @param {any} input - The input data to encode.
 * @returns {string} The encoded data.
 */
function base64_data_encode(input: TJSONValue) {
  logForCrashlytics('base64_data_encode fired in shareHelper');

  return btoa(JSON.stringify(input || ''))
    .replace(/\+/gi, '.')
    .replace(/\//gi, '_')
    .replace(/=/gi, '-');
}

/**
 * Shares a given share object on social media or other platforms.
 * @param {Object} shareObject - The share object containing information to share.
 * @param {string} shareOrigin - The origin of the share.
 * @param {string} huntType - The type of hunt.
 * @param {string} targetMedia - The target social media or platform.
 */

export interface ShareObject {
  description?: string | null;
  question?: string | null;
  correctAnswer?: string | null;
  challenge?: string | null;
  photoURL?: string | null;
  photoUrl?: string | null;
  downloadURL?: string | null;
  url?: string | null;
  userId?: string | null;
  thumbnail?: string | null;
  scoreResponse?: onScoreResponse | null;
  shareUUID?: string | null;
  shareURL?: string | null;
  challengeType?: string | null;
  // Add additional other optional properties that could be in shareObject
}
export const socialShare = async (
  shareObject: ShareObject,
  shareOrigin: string,
  huntType: string = 'scavaHunt',
  targetMedia?: string,
) => {
  logForCrashlytics('shareObject fired in shareHelper');

  const {
    description,
    question,
    correctAnswer,
    challenge,
    photoURL,
    photoUrl,
    downloadURL,
    url = 'https://www.letsroam.com',
    userId,
    thumbnail = '',
    shareUUID,
    shareURL,
  } = shareObject;

  const huntName = huntType == 'scavaHunt' ? 'Scavenger Hunt' : 'Bar Hunt';
  const quoteDescription = description
    ? description
    : question || challenge || `Let's Roam Scavenger Hunt`;
  const quotes = {
    Photos: `I completed an Epic Photo Challenge on a Let's Roam ${huntName}: ${quoteDescription}`,
    Trivia: `Look at this epic trivia question I answered on a Let's Roam Scavenger Hunt: ${
      question +
      (correctAnswer
        ? ' Did you know the correct answer was: ' + correctAnswer
        : '')
    }`,
    RegistrationHuntInfo: `Join me on an epic scavenger hunt! Learn more at: ${url} or download the Let's Roam Scavenger Hunt App to explore other hunts.`,
    'team-building': `Check out this awesome team-building scavenger hunt app: ${url}.`,
    'bachelorette-party': `Check out this awesome bachelorette party scavenger hunt: ${url}.`,
  };

  const sharePhoto = photoURL || photoUrl || downloadURL;
  let ultimateShareUrl = url || undefined;

  if (sharePhoto) {
    try {
      const dataUri = base64_data_encode({
        sharePhoto: thumbnail ? sharePhoto + '.mp4' : sharePhoto,
        quoteDescription,
        thumbnail,
      });
      ultimateShareUrl =
        'https://www.letsroam.com/scavenger_hunt_app/' + dataUri;
    } catch (err) {
      console.log({err});
    }
  }

  if (shareUUID) {
    ultimateShareUrl =
      'https://www.letsroam.com/scavenger_hunt_app/' + shareUUID;
  }

  if (
    shareOrigin == 'team-building' ||
    (shareOrigin == 'bachelorette-party' && shareURL)
  ) {
    ultimateShareUrl = shareURL || undefined;
  }

  let linkInformation = {
    title: 'Share Link',
    message: 'Share Link',
    dialogTitle:
      Platform.OS == 'android'
        ? ultimateShareUrl
        : shareOrigin == 'Photos' ||
          shareOrigin == 'Trivia' ||
          shareOrigin == 'RegistrationHuntInfo'
        ? quotes[shareOrigin]
        : 'Check out my awesome photo from my scavenger hunt',
    tintColor: 'green',
  };

  // Add keys that only exist for Android
  if (Platform.OS == 'android' && ultimateShareUrl) {
    linkInformation = {
      ...linkInformation,
      title: ultimateShareUrl,
      message: ultimateShareUrl,
    };
  }

  // Share paths
  if (targetMedia === 'Instagram' && userId && shareUUID) {
    addShareCoins(userId, shareUUID);

    sharePhoto &&
      saveToCameraRoll(sharePhoto)
        .then((res) => {
          Linking?.openURL(`instagram://library?AssetPath=${res}`).catch(
            (err) => {
              console.log({err});
              Alert.alert('Unable to open Instagram');
            },
          );
        })
        .catch((err) => Alert.alert('save error: ', err.message));
  } else if (targetMedia === 'Facebook') {
    addShareCoins(userId ?? '', shareUUID ?? '');

    Linking?.openURL(
      `https://www.facebook.com/sharer/sharer.php?u=${ultimateShareUrl}`,
    ).catch((err) => {
      console.log({err});
      Alert.alert('Unable to open Facebook');
    });
  } else {
    addShareCoins(userId ?? '', shareUUID ?? '');

    try {
      Share.share(
        {
          message: ultimateShareUrl,
          url: ultimateShareUrl!,
        },
        linkInformation,
      ).then((result) => {
        updateDataAtPath(`shares/${uuidv4()}/`, {
          userId: userId || '',
          ...shareObject,
          shareDate: moment().format('YYYY-MM-DD'),
          shareTime: Date.now(),
          shareInfo: result || null,
          shareOrigin,
        });
      });
    } catch (error: unknown) {
      console.log(error);
    }
  }
};

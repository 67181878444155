import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect, useState} from 'react';
import {Alert, FlatList, ScrollView, Text, View} from 'react-native';

import ExpoImage from 'src/Utils/ExpoImage';

import {getExplorerPass} from 'src/Utils/explorerPassHelper';

///////////////////////////
///////////// components

import {BottomTab, BottomTabButton} from 'src/Modules/TabViews';

import {BodyText} from 'src/Modules/CommonView';

import {navigationRef} from 'src/Nav/navigationHelpers';

import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {LinearGradient} from 'expo-linear-gradient';
import {TSimpleGroupInfo} from 'src/types/TSimpleGroup';
import {PlacedTeam} from 'src/CustomEvents/EventLeaderboardCompact';
import {useFirebaseData} from 'src/Utils/fireUtils';
import {ordinalSuffixOf} from 'src/Utils/helpers';

const signInImage = require('src/Images/signInImage.png');

interface TAward {
  award_title: string;
  key: string;
  groupId: string;
  photo_url?: string;
  userId?: string;
  winner_name: string;
}

interface GroupWithRank extends TSimpleGroupInfo {
  rank?: number;
  deleted?: boolean;
}

const HuntFinished: React.FC = () => {
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  const groups = useTypedSelector((state) => state.event?.groups);
  const eventId = useTypedSelector((state) => state.group?.info?.eventId);
  const huntId = useTypedSelector((state) => state.group?.info?.huntId);

  const [leaderboard, setLeaderboard] = useState<GroupWithRank[]>([]);
  const [awardList, seTAward] = useState<TAward[]>([]);
  const [huntLeaderboard, setHuntLeaderboard] = useState<GroupWithRank[]>([]);

  useFirebaseData(
    `customEvents/${eventId || 'error'}/awards/list`,
    (awardList: TAward[]) => {
      seTAward(awardList);
    },
  );

  const huntLeaderboardPath = `scavengerhunt/leaderboard/${
    huntId || 'error'
  }/top10`;
  useFirebaseData(
    huntLeaderboardPath,
    (newHuntLeaderboard: TSimpleGroupInfo[]) => {
      console.log({newHuntLeaderboard});
      // setHuntLeaderboard(newHuntLeaderboard);
    },
  );

  console.log({huntLeaderboardPath, huntLeaderboard});

  useEffect(() => {
    if (huntLeaderboard?.length > 0) {
      setLeaderboard([...huntLeaderboard]);
    } else if (!!groups && groups.length > 0) {
      let tmpRank = 1;
      setLeaderboard([
        ...groups
          .slice()
          .sort((a, b) => {
            const aScore = a?.score && a?.score > 0 ? 1 * a?.score : 0;
            const bScore = b?.score && b?.score > 0 ? 1 * b?.score : 0;
            if (aScore < bScore) {
              return 1;
            }
            if (bScore < aScore) {
              return -1;
            }
            return 0;
          })
          .map((group, ix, leaderboard) => {
            //add a rank to each leaderboard user
            const formattedGroup: GroupWithRank = {...group, rank: tmpRank};
            if (
              ix === 0 ||
              (formattedGroup?.score || 0) < (leaderboard[ix - 1]?.score || 0)
            ) {
              formattedGroup.rank = ix + 1;
              tmpRank = ix + 1;
            } else {
              const previousGroup = leaderboard[ix - 1] as GroupWithRank;
              formattedGroup.rank = previousGroup.rank || tmpRank;
            }
            return formattedGroup;
          }),
      ]);
    }
  }, [groups, huntLeaderboard]);

  const insets = useSafeAreaInsets();

  const formatName = (winner: TSimpleGroupInfo) => {
    if (!winner) return 'Group';
    return winner?.teamName || 'Group';
  };

  const boxShadow = {
    shadowColor: 'rgba(104, 88, 46, 0.20)',
    elevation: 6,
    shadowOffset: {width: 0, height: 4},
    shadowOpacity: 1,
    shadowRadius: 12,
  };

  return (
    <View style={{flex: 1}}>
      <ScrollView style={{flex: 1}}>
        <GroupResults />
        <View
          style={{
            flex: 1,
            paddingVertical: 10,
            backgroundColor: constants?.color?.white1,
            minHeight: leaderboard?.length > 0 ? 280 : 0,
          }}>
          {!!leaderboard?.length && (
            <BodyText
              text={'Top Teams'}
              textStyle={{
                fontSize: 28,
                lineHeight: 28,
                color: constants?.color?.black,
                fontFamily: constants?.font.JakartaBold,
                textAlign: 'center',
              }}
            />
          )}
          {!!leaderboard && !!leaderboard.length ? (
            <View
              style={{display: 'flex', flexDirection: 'row', marginTop: 30}}>
              {!!leaderboard?.[1] && (
                <PlacedTeam
                  rank={2}
                  photoUrl={
                    leaderboard?.[1]?.groupPhoto ||
                    // @ts-ignore
                    leaderboard?.[0]?.teamPhoto ||
                    signInImage
                  }
                  name={formatName(leaderboard?.[1]) || 'Group'}
                  score={leaderboard?.[1]?.score}
                  isLocal={false}
                  darkText
                />
              )}
              {!!leaderboard?.[0] && (
                <PlacedTeam
                  rank={1}
                  photoUrl={
                    leaderboard?.[0]?.groupPhoto ||
                    // @ts-ignore
                    leaderboard?.[0]?.teamPhoto ||
                    signInImage
                  }
                  name={formatName(leaderboard?.[0]) || 'Group'}
                  score={leaderboard?.[0]?.score}
                  isLocal={false}
                  darkText
                />
              )}
              {!!leaderboard?.[2] && (
                <PlacedTeam
                  rank={3}
                  photoUrl={
                    leaderboard?.[2]?.groupPhoto ||
                    // @ts-ignore
                    leaderboard?.[2]?.teamPhoto ||
                    signInImage
                  }
                  name={formatName(leaderboard?.[2]) || 'Group'}
                  score={leaderboard?.[2]?.score}
                  isLocal={false}
                  darkText
                />
              )}
            </View>
          ) : (
            <Text
              style={{
                marginTop: 10,
                fontFamily: 'Jakarta',
                fontSize: 20,
                textAlign: 'center',
              }}></Text>
          )}
        </View>
        {!!awardList && !!awardList.length && (
          <FlatList
            scrollEnabled={false}
            data={awardList.filter(
              (award) =>
                award?.award_title !== 'Gold' &&
                award?.award_title !== 'Silver' &&
                award?.award_title !== 'Bronze',
            )}
            style={{paddingBottom: 20}}
            renderItem={({item}) => (
              <View
                style={{
                  backgroundColor: constants.color.white,
                  marginHorizontal: 40,
                  paddingHorizontal: 15,
                  marginBottom: 10,
                  flex: 1,
                  flexDirection: 'row',
                  minHeight: 72,
                  borderRadius: 10,
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  ...boxShadow,
                }}>
                <View
                  style={{
                    flexDirection: 'row',
                    flex: 4,
                    gap: 15,
                    alignItems: 'flex-start',
                  }}>
                  <ExpoImage
                    source={item?.photo_url || signInImage}
                    style={{
                      height: 35,
                      width: 35,
                      borderRadius: 35,
                    }}
                  />
                  <View style={{gap: 5}}>
                    <Text
                      style={{
                        fontSize: 16,
                        fontFamily: 'Jakarta',
                        marginTop: -2,
                      }}>
                      {item?.award_title}
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        fontFamily: 'Jakarta',
                        color: constants?.color?.gray2,
                      }}>
                      {item?.winner_name}
                    </Text>
                  </View>
                </View>
              </View>
            )}
            keyExtractor={(item, index) => `${item?.key}${index.toString()}`}
          />
        )}
      </ScrollView>
      <View style={{backgroundColor: 'white'}}>
        <BottomTab
          height={55}
          tabStyle={{
            alignItems: 'flex-start',
            marginBottom: insets.bottom,
          }}>
          <BottomTabButton
            text={'Home'}
            iconName={'award'}
            iconPack={'FeatherIcon'}
            handlePress={() => {
              try {
                const pass = getExplorerPass();
                if (!pass && playTestState == false) {
                  Alert.alert(
                    `You unlocked a free trial for a Let's Roam Membership`,
                    'Get unlimited scavenger hunts for you plus your 5 of your friends!',
                    [
                      {
                        text: 'Check out Perks',
                        onPress: () =>
                          navigationRef.navigate(
                            'PurchaseModal',
                            {
                              showMembershipOnly: true,
                            },
                            'ScavengerHunt_v2/HuntFinished.tsx',
                          ),
                      },
                      {
                        text: 'Maybe Later',
                        onPress: () =>
                          navigationRef.navigate(
                            'LandingPageMain',
                            null,
                            'HuntFinished.tsx',
                          ),
                      },
                    ],
                  );
                } else {
                  navigationRef.navigate(
                    'LandingPageMain',
                    null,
                    'Hunt Finished',
                  );
                }
              } catch (error: unknown) {
                navigationRef.navigate(
                  'LandingPageMain',
                  null,
                  'Hunt Finished Error',
                );
              }
            }}
            buttonStyle={{marginTop: 5}}
            active={false}
          />
          {!!eventId && (
            <BottomTabButton
              text={'Leaderboard'}
              iconName={'bar-chart-2'}
              iconPack={'FeatherIcon'}
              handlePress={() =>
                navigationRef.navigate(
                  'EventDashboard',
                  {},
                  'ScavengerHunt_v2/HuntFinished.tsx',
                )
              }
              buttonStyle={{marginTop: 5}}
              active={false}
            />
          )}
          {!isHybridHunt && (
            <BottomTabButton
              text={'Photos'}
              iconName={'aperture'}
              iconPack={'FeatherIcon'}
              handlePress={() =>
                navigationRef.navigate(
                  'HuntPhotos',
                  null,
                  'ScavengerHunt_v2/HuntFinished.tsx',
                )
              }
              buttonStyle={{marginTop: 5}}
              active={false}
            />
          )}
        </BottomTab>
      </View>
    </View>
  );
};

const GroupResults = () => {
  const score = useTypedSelector((state) => state.group?.info?.score);

  const ordinal_place = useTypedSelector((state) => {
    // use regular rank
    // from cloud function updateScore and lr_app_group_score_log
    if (!state?.event?.eventId) {
      return (
        state.group?.info?.rankAtFinish ||
        state.group?.info?.ordinal_place ||
        200
      );
    } else {
      let groupScores = Object.values(state.event?.groups || {});
      if (!groupScores) {
        return 200;
      }
      groupScores = groupScores.sort((a, b) =>
        (b.score || 0) > (a.score || 0) ? 1 : -1,
      );

      // console.log({groupScores});

      let rank = 200;
      let currentRank = 1;

      groupScores?.map((score) => {
        if (score.groupId === state?.group?.info?.groupId) {
          rank = currentRank;
        }
        currentRank++;
      });

      return rank;
    }

    // is a corporate event use that instead
  });

  return (
    <View
      style={{
        flex: 1,
      }}>
      <View
        style={{
          alignItems: 'center',
          backgroundColor: constants?.color?.teal,
          paddingTop: 50,
          paddingBottom: 30,
        }}>
        <LinearGradient
          colors={['#F9F7F2', 'rgba(249, 247, 242, 0.00)']}
          start={{x: 0, y: 1}}
          end={{x: 0, y: 0}}
          style={{
            position: 'absolute',
            bottom: 0,
            height: '50%',
            width: '100%',
          }}></LinearGradient>
        <BodyText
          text={'Congratulations!'}
          textStyle={{
            fontSize: 35,
            marginTop: 30,
            lineHeight: 35,
            color: constants?.color?.yellow,
            fontFamily: constants?.font.JakartaBold,
            textAlign: 'center',
          }}
        />
        <BodyText
          text={
            ordinal_place > 100
              ? 'Your team placed in the top 50% of teams'
              : `Your Team Finished In ${ordinalSuffixOf(ordinal_place)} Place`
          }
          textStyle={{
            fontSize: 20,
            lineHeight: 20,
            color: constants?.color?.white,
            fontFamily: constants?.font.JakartaBold,
            textAlign: 'center',
          }}
        />
        <BodyText
          text={`${Math.round(score || 0).toLocaleString()} points`}
          textStyle={{
            fontSize: 30,
            lineHeight: 30,
            color: constants?.color?.white,
            fontFamily: constants?.font.JakartaBold,
            textAlign: 'center',
          }}
        />
      </View>
    </View>
  );
};

export default HuntFinished;

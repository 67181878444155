import {useState} from 'react';
import {
  ImageBackground,
  Platform,
  SafeAreaView,
  Text,
  View,
} from 'react-native';
import Svg, {Polygon} from 'svgs';
import {useLocation} from 'src/Hooks/gameHooks';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import ExpoImage from 'src/Utils/ExpoImage';
import {TChallenge} from 'src/types/TChallenge';
import {
  GestureEvent,
  HandlerStateChangeEvent,
  PinchGestureHandler,
  PinchGestureHandlerEventPayload,
  PanGestureHandler,
  State,
  PanGestureHandlerEventPayload,
} from 'react-native-gesture-handler';
import {navigationRef} from 'src/Nav/navigationHelpers';

interface ClickableImageChallengesProps {
  availableChallengeIds: string[];
}

const ClickableImageChallenges: React.FC<ClickableImageChallengesProps> = ({
  availableChallengeIds = [],
}) => {
  const location = useLocation();
  const photoLarge = location?.photoLarge || '';

  const basePhotoUrl = 'photos.letsroam.com';
  const largeDisplayedPhoto = photoLarge
    ? photoLarge.includes(basePhotoUrl) ||
      photoLarge.includes('photos.scavengerhunt.com') ||
      photoLarge.includes('firebasestorage.googleapis.com')
      ? photoLarge
      : `https://${basePhotoUrl}${photoLarge && photoLarge.substring(1)}`
    : '';

  const {width, height} = useWindowDimensionsSafe();

  const challengeList = useTypedSelector((state) => {
    const allChallenges = state.game_v2?.allChallenges;
    const challenges: TChallenge[] = [];
    location?.challengeList?.length &&
      location?.challengeList.forEach((key: string) => {
        const locationChallenge = allChallenges && allChallenges[key];
        if (locationChallenge) challenges.push(locationChallenge);
      });
    return challenges;
  });

  const [baseScale, setBaseScale] = useState(1);
  const [pinchScale, setPinchScale] = useState(1);
  const [offset, setOffset] = useState({x: 0, y: 0});

  const onPinchGestureEvent = (
    event: GestureEvent<PinchGestureHandlerEventPayload>,
  ) => {
    setPinchScale(event.nativeEvent.scale);
  };

  const onPinchHandlerStateChange = (
    event: HandlerStateChangeEvent<PinchGestureHandlerEventPayload>,
  ) => {
    if (event.nativeEvent.state === State.END) {
      const imageWidth = width * baseScale * pinchScale;

      const minX = Math.min(0, width - imageWidth);
      const maxX = Math.max(0, width - imageWidth);

      const constrainedOffsetX = Math.min(Math.max(offset.x, minX), maxX);

      setOffset({
        x: constrainedOffsetX,
        y: 0,
      });
      setBaseScale(Math.max(1, baseScale * pinchScale));
      setPinchScale(1);
    }
  };

  const onPanGestureEvent = (
    event: GestureEvent<PanGestureHandlerEventPayload>,
  ) => {
    setOffset({
      //Throttle Panning a bit, it's too fast
      x: offset.x + event.nativeEvent.translationX * 0.5,
      y: 0,
    });
  };

  const onPanHandlerStateChange = (
    event: HandlerStateChangeEvent<PanGestureHandlerEventPayload>,
  ) => {
    const imageWidth = width * baseScale * pinchScale;

    const minX = Math.min(0, width - imageWidth);
    const maxX = Math.max(0, width - imageWidth);

    const constrainedOffsetX = Math.min(Math.max(offset.x, minX), maxX);

    setOffset({
      x: constrainedOffsetX,
      y: 0,
    });
    setBaseScale(Math.max(1, baseScale * pinchScale));
    setPinchScale(1);
  };

  const handlePolygonClick = (challengeId: string) => {
    navigationRef.navigate(
      'ChallengeAttemptAndResultModal',
      {
        challengeId,
        headerTitle: 'Challenge',
      },
      'ScavengerHunt_v2/Game/ClickableImageChallenges.tsx',
    );
  };

  const newImageWidth = width;
  const newImageHeight = (width * 9) / 16;
  const scaleX = newImageWidth / 960;
  const scaleY = newImageHeight / 540;

  const getPolygonPoints = (
    polygonString: string,
    scaleX: number,
    scaleY: number,
    offsetX: number,
    offsetY: number,
  ) => {
    try {
      const polygonArray: {x: number; y: number}[] = JSON.parse(polygonString);
      const scaledPoints = polygonArray.map((point) => ({
        x: point.x * scaleX + offsetX,
        y: point.y * scaleY + offsetY,
      }));
      return scaledPoints.map((point) => `${point.x},${point.y}`).join(' ');
    } catch (error) {
      console.error('Error parsing selection_polygon:', error);
      return '';
    }
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: 'black',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 20,
      }}>
      <Text
        style={{
          color: 'white',
          position: 'relative',
          zIndex: 10,
          marginHorizontal: 20,
          textAlign: 'center',
        }}>
        {location?.description}
      </Text>
      <Text
        style={{
          color: 'white',
          position: 'relative',
          zIndex: 10,
          width,
          textAlign: 'center',
          fontWeight: '700',
        }}>
        {availableChallengeIds.length} / {challengeList.length} CLUES LEFT TO
        FIND
      </Text>
      <PinchGestureHandler
        onGestureEvent={onPinchGestureEvent}
        onHandlerStateChange={onPinchHandlerStateChange}>
        <PanGestureHandler
          onGestureEvent={onPanGestureEvent}
          onHandlerStateChange={onPanHandlerStateChange}>
          <View
            style={{
              width: width * baseScale,
              height: ((width * 9) / 16) * baseScale,
              backgroundColor: 'black',
            }}>
            {Platform.OS !== 'web' && (
              <ExpoImage
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  transform: [{scale: pinchScale}, {translateX: offset.x}],
                }}
                resizeMode="contain"
                source={{
                  uri: largeDisplayedPhoto,
                }}
              />
            )}
            {Platform.OS === 'web' ? (
              <ImageBackground
                source={{
                  uri: largeDisplayedPhoto,
                }}
                style={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  transform: [{scale: pinchScale}, {translateX: offset.x}],
                }}
                resizeMode="contain">
                <svg height="100%" width="100%">
                  {challengeList.map((challenge, index) =>
                    challenge?.selection_polygon ? (
                      <polygon
                        key={`polygon-${challenge?.challengeId}-${index}`}
                        points={getPolygonPoints(
                          challenge?.selection_polygon,
                          scaleX * baseScale,
                          scaleY * baseScale,
                          offset.x,
                          0,
                        )}
                        fill="transparent"
                        stroke="transparent"
                        strokeWidth={3}
                        onClick={() =>
                          handlePolygonClick(challenge?.challengeId)
                        }
                      />
                    ) : (
                      <></>
                    ),
                  )}
                </svg>
              </ImageBackground>
            ) : (
              <Svg height="100%" width="100%">
                {challengeList.map((challenge, index) =>
                  challenge?.selection_polygon ? (
                    <Polygon
                      key={`polygon-${challenge?.challengeId}-${index}`}
                      points={getPolygonPoints(
                        challenge?.selection_polygon,
                        scaleX * baseScale,
                        scaleY * baseScale,
                        offset.x,
                        0,
                      )}
                      fill="transparent"
                      stroke="transparent"
                      strokeWidth={3}
                      onPress={() => handlePolygonClick(challenge?.challengeId)}
                    />
                  ) : (
                    <></>
                  ),
                )}
              </Svg>
            )}
          </View>
        </PanGestureHandler>
      </PinchGestureHandler>
    </SafeAreaView>
  );
};

export default ClickableImageChallenges;

import constants from 'src/constants';
import React, {useRef} from 'react';
import {View, ViewStyle} from 'react-native';
import ModalSelector from 'react-native-modal-selector';
import SimpleLineIcon from 'react-native-vector-icons/SimpleLineIcons';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {ContinueButton} from 'src/Modules/CommonView/Buttons';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {TDropDownOption} from 'src/types/TDropDownOption';

interface DropDownProps {
  dropDownOptions: TDropDownOption[];
  placeholder?: string;
  value: string | number | null;
  setValue: (_value: string) => void;
  buttonStyle?: ViewStyle; // You can replace this with more specific styling if needed
  containerStyle?: ViewStyle; // You can replace this with more specific styling if needed
  testID?: string;
}

const DropDown: React.FC<DropDownProps> = ({
  dropDownOptions,
  placeholder = 'Your Selection',
  value,
  setValue,
  buttonStyle = {},
  containerStyle = {},
  testID = 'dropdown',
}) => {
  const selectorRef = useRef<ModalSelector | null>(null); // Ref type should match ModalSelector ref type
  const {width} = useWindowDimensionsSafe();

  let dropdownLabel = placeholder;
  if (dropDownOptions) {
    dropdownLabel =
      dropDownOptions.find((element) => element.value === value)?.label ||
      placeholder;
  }

  return (
    <View
      style={{
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        width: buttonStyle?.width || width,
        ...containerStyle,
      }}>
      <ModalSelector
        data={dropDownOptions}
        ref={selectorRef}
        onChange={(item) => {
          const selectedValue = item?.value;
          setValue(selectedValue + '');
        }}
        cancelText={'Cancel'}
        customSelector={<View />}
        overlayStyle={{justifyContent: 'flex-end'}}
      />
      <View style={{position: 'relative', backgroundColor: 'transparent'}}>
        <ContinueButton
          handlePress={() => selectorRef.current?.open?.()}
          text={dropdownLabel}
          containerStyle={{
            alignItems: 'center',
            paddingLeft: 10,
            justifyContent: 'flex-start',
            backgroundColor: 'white',
            borderRadius: 5,
            borderWidth: 1,
            borderColor: constants?.color.gray3,
            ...buttonStyle,
          }}
          testID={testID}
          background={constants?.color?.white1}
          textStyle={{
            color: constants?.color?.gray3,
            fontFamily: constants?.font.circular,
            marginVertical: 5,
            textAlign: 'center',
          }}
        />
        <View style={{right: 20, top: 20, position: 'absolute'}}>
          <TouchableOpacity onPress={() => selectorRef.current?.open?.()}>
            <SimpleLineIcon
              name={'arrow-down'}
              size={20}
              color={constants?.color.gray3}
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default DropDown;

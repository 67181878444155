import constants from 'src/constants';
import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import {
  ContinueButton,
  HeaderText,
  IconButton,
  BodyText,
} from 'src/Modules/CommonView';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import {socialShare} from 'src/Utils/shareHelper';

import LottieView from 'lottie-react-native';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {useUserId} from 'src/Hooks/reduxHooks';

const ReferralModal: React.FC = () => {
  const {width} = useWindowDimensionsSafe();
  const handleSharePress = (shareLink: string) => {
    socialShare({url: shareLink}, 'Referral_Gift', 'ScavengerHunt');
  };

  const userId = useUserId();

  const shareLink = 'https://www.letsroam.com/s/' + userId;

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 20,
          backgroundColor: constants?.color?.gray3_50,
        }}>
        <LottieView
          source={require('src/Lottie/fireworks.json')}
          autoPlay
          loop
          style={{flex: 1, width: width, height: 400}}
          speed={0.7}
        />
      </View>
      <View
        style={{
          width: width - 50,
          borderRadius: 5,
          minHeight: 400,
          backgroundColor: 'white',
          marginBottom: 75,
        }}>
        <HeaderText text={'REFER A FRIEND'} center fullWidth={width - 60} />
        <IconButton
          handlePress={() =>
            navigationRef?.goBack('LandingPage/ReferralModal.tsx')
          }
          right
          color={constants?.color?.gray3}
        />
        <BodyText
          text={`Invite your friends to Let's Roam and they will get a free trial and you will awesome things like free swag!`}
        />
        <TouchableOpacity
          onPress={() => {
            handleSharePress(shareLink);
          }}>
          <BodyText
            text={`Your Link:`}
            textStyle={{marginTop: 20, marginBottom: 0}}
          />
          <BodyText
            text={shareLink}
            textStyle={{
              color: constants?.color?.gray3,
              fontSize: 20,
              marginLeft: 10,
              fontWeight: '800',
              marginTop: 10,
            }}
          />

          <BodyText
            text={`Share your links with friends by clicking below: `}
            textStyle={{marginTop: 20}}
          />
        </TouchableOpacity>

        <ContinueButton
          text={'Invite Friends'}
          height={60}
          containerStyle={{
            height: 50,
            width: '80%',
            marginLeft: '10%',
            marginTop: 15,
          }}
          background={constants?.color?.orange}
          handlePress={() => {
            handleSharePress(shareLink);
          }}
        />
      </View>
    </SafeAreaView>
  );
};

export default ReferralModal;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants'; /* eslint-disable no-use-before-define */
import React, {useState} from 'react';
import {Modal, View} from 'react-native';
import MapView from 'src/Modules/Native/MapView';

////////////// components
import MapViewDirections from 'src/Modules/MapViewDirections';
import MapMarker from 'src/Modules/MapMarker';
import {getInitialRegionForCoordinates} from 'src/Utils/distanceHelpers';

import ExpoImage from 'src/Utils/ExpoImage';
import {useLocation, useLocationId} from 'src/Hooks/gameHooks';
import {TouchableOpacity} from 'react-native';
import {Text} from 'react-native';

import {StyleSheet} from 'react-native';

import {DistanceAway} from 'src/ScavengerHunt_v2/Game/DistanceAway';

const AudioMap: React.FC = () => {
  const locationList = useTypedSelector(
    (state) => state?.game_v2?.locationList || [],
  );
  const locations = useTypedSelector((state) => state?.game_v2?.locations);

  console.log({locationList});
  let previousLocation: {latitude: number; longitude: number} | null = null;

  const initialRegion = getInitialRegionForCoordinates(locations, 1.5);

  const location = useLocation();
  const photoLarge = location?.photoLarge;

  const groupPhoto = useTypedSelector((state) => state.group?.info?.groupPhoto);

  const largeDisplayedPhoto = photoLarge
    ? `https://photos.letsroam.com${photoLarge && photoLarge.substring(1)}`
    : groupPhoto;

  const littleButtonDimensions = {
    width: 66,

    // height: 90,
    minHeight: 66,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    ...constants?.style.boxShadow,
  };

  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const currentLocationId = useLocationId();

  if (!location) {
    return <></>;
  }

  return (
    <View style={{flex: 1}}>
      {!!initialRegion && !!Object.keys(initialRegion).length && <MapView
        showsUserLocation
        key={JSON.stringify(initialRegion)}
        style={{flex: 1}}
        showsCompass
        legalLabelInsets={{top: -10, left: -10, right: 0, bottom: 0}}
        customMapStyle={constants?.customMapStyle}
        initialRegion={initialRegion || {
          latitude: 0,
          longitude: 0,
          latitudeDelta: 1,
          longitudeDelta: 1,
        }}>
        {!!locationList &&
          !!locations &&
          locationList?.map((locationIndex, index) => {
            const location = locations[locationIndex];
            const destination = {
              latitude: location?.lat || 0,
              longitude: location?.long || 0,
            };

            console.log({destination, previousLocation});

            if (location?.locationId != currentLocationId) {
              return;
            }

            const component = (
              <>
                <MapMarker
                  key={index}
                  setSelectedHuntId={() => {
                    console.log('marker pressed');
                  }}
                  lat={location?.lat || 0}
                  long={location?.long || 0}
                  showMarker={true}
                />
                {index !== 0 && (
                  <MapViewDirections
                    originLocation={previousLocation ? previousLocation : null}
                    destination={destination}
                    mode={'walking'}
                  />
                )}
              </>
            );
            previousLocation = destination;

            return component;
          })}
      </MapView>}
      <DistanceAway
        style={{
          borderRadius: 20,
          paddingTop: 5,
          display: 'flex',
          paddingLeft: 20,
        }}
      />
      {!!location?.locationId && (
        <TouchableOpacity
          activeOpacity={0.8}
          onPress={openModal}
          style={{position: 'absolute', bottom: 10, left: 10}}>
          <View
            style={{
              flexDirection: 'row',
              padding: 0,
              borderRadius: 12,
              ...constants?.style.boxShadow,
              backgroundColor: 'white',
            }}>
            <View testID={'MapButton'} style={{...littleButtonDimensions}}>
              {!!largeDisplayedPhoto && (
                <ExpoImage
                  style={{...littleButtonDimensions}}
                  source={{uri: largeDisplayedPhoto}}
                  resizeMode={'cover'}
                />
              )}
            </View>

            <View style={{paddingRight: 10}}>
              <Text
                style={{
                  fontFamily: 'JakartaBold',
                  width: 150,
                  fontSize: 14,
                  lineHeight: 18,
                  marginLeft: 16,
                  marginTop: 10,
                }}
                ellipsizeMode="tail">
                {location?.name}
              </Text>
              <Text
                style={{
                  fontFamily: 'Jakarta',
                  width: 150,
                  marginLeft: 16,
                  fontSize: 12,
                  opacity: 0.5,
                  marginTop: 4,
                }}>
                View Photo
              </Text>
            </View>
          </View>
        </TouchableOpacity>
      )}
      <Modal visible={modalVisible} animationType="fade" transparent={true}>
        <View style={styles.modalContainer}>
          <TouchableOpacity style={styles.closeButton} onPress={closeModal}>
            <Text style={styles.closeButtonText}>Close</Text>
          </TouchableOpacity>
          {!!largeDisplayedPhoto && (
            <ExpoImage
              source={{uri: largeDisplayedPhoto}}
              style={styles.fullImage}
              resizeMode={'contain'}
            />
          )}
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  thumbnail: {},
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  closeButton: {
    position: 'absolute',
    top: 60,
    right: 20,
    zIndex: 1,
  },
  closeButtonText: {
    color: '#fff',
    fontSize: 18,
  },
  fullImage: {
    width: '80%',
    height: '80%',
    resizeMode: 'contain',
  },
});

export default AudioMap;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';

import {
  ScrollView,
  Text,
  View,
  Platform,
  TouchableHighlight,
} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

/////////////////////// utils

/////////////////////// components
import {BodyText, CircleOverlapImages} from 'src/Modules/CommonView';

import {navigationRef} from 'src/Nav/navigationHelpers';

import {analytics} from 'src/Utils/db';
import {TBadge} from 'src/types/TBadge';
import {ProgressBar} from 'src/ScavengerHunt_v2/Classic/Header';

interface BadgesProps {
  showEarned?: boolean | null;
  onlyShowDining?: boolean;
  backgroundColor?: string;
  showOnlyType?: string | null;
  removeBottomPadding?: boolean;
  limit?: number | undefined;
}

const Badges: React.FC<BadgesProps> = ({
  showEarned = null,
  onlyShowDining,
  backgroundColor = '#F2F2F2',
  showOnlyType = null,
  removeBottomPadding = false,
  limit = undefined,
}: BadgesProps): React.ReactElement | null => {
  const {width} = useWindowDimensionsSafe();

  const badgesNext = useTypedSelector(
    (state) => state.badges?.badgesNext || {},
  );
  const badgesEarned = useTypedSelector(
    (state) => state.badges?.badgesEarned || {},
  );

  console.log({badgesNext});

  let formattedBadges = (showEarned ? badgesEarned : badgesNext) || {};

  let sortedBadges: TBadge[] = [];

  if (!!showOnlyType && badgesEarned && badgesNext) {
    formattedBadges = {...badgesEarned, ...badgesNext};
  }

  if (!!formattedBadges) {
    sortedBadges = Object.values(formattedBadges);
    sortedBadges = sortedBadges.sort((a, b) => {
      if (!a || !b || a?.id || b?.id) {
        return 1;
      } else {
        const aLevel = a?.level ?? 1;
        const aCurrent_quantity = a?.current_quantity ?? 0;
        const aRequirement_quantity = a?.requirement_quantity ?? 1;

        const bLevel = b?.level ?? 1;
        const bCurrent_quantity = b?.current_quantity ?? 0;
        const bRequirement_quantity = b?.requirement_quantity ?? 1;

        if (showEarned) {
          return aLevel < bLevel ? 1 : -1;
        } else {
          if (aLevel === bLevel) {
            return (aCurrent_quantity || 0) / (aRequirement_quantity + 1) <
              (bCurrent_quantity || 0) / (bRequirement_quantity + 1)
              ? 1
              : -1;
          } else {
            return aLevel < bLevel ? 1 : -1;
          }
        }
      }
    });
    if (!!showOnlyType) {
      sortedBadges = sortedBadges.filter((badgeItem) => {
        return (
          badgeItem.prize_type == showOnlyType ||
          badgeItem.requirement_type == showOnlyType ||
          badgeItem.type == showOnlyType
        );
      });
    }
  }

  if (limit !== undefined && limit !== 0) {
    // Check if a limit is provided
    sortedBadges = sortedBadges.slice(0, limit); // Limit the number of displayed badges
  }

  if (Platform.OS == 'web') {
    console.log('skipping badges for detox or dev');
    return <></>;
  }

  return (
    <View
      style={{
        flex: 1,
        paddingTop: 5,
        backgroundColor,
        marginBottom: 0,
        paddingBottom: 0,
      }}>
      <ScrollView style={{flex: 1}}>
        {!!sortedBadges &&
          sortedBadges?.map?.((badge, index) => {
            if (onlyShowDining && badge?.type != 'dining_deals') {
              return (
                <View
                  key={`badge list render ${badge?.id || Math?.random()}`}
                />
              );
            }

            return (
              <BadgeNew
                key={`badge list render ${badge?.id || 1}`}
                title={badge?.title}
                handlePress={() => {
                  if (__DEV__) {
                    // testing badge award
                    const badgeLabel = `${badge?.requirement_type}-${badge?.requirement_quantity}`;
                    analytics().logUnlockAchievement({
                      achievement_id: badgeLabel,
                    });
                  }

                  navigationRef.navigate(
                    'BadgeAwardModal',
                    {
                      badge,
                    },
                    'Badges 12341234',
                  );
                }}
                task={badge?.task}
                currentAmount={badge?.current_quantity}
                requiredAmount={badge?.requirement_quantity}
                prizeType={badge?.prize_type?.replace('_', ' ')}
                prizeQuantity={badge?.prize_quantity}
                level={badge?.level}
                backgroundColor={backgroundColor}
                date_awarded={badge?.date_awarded || null}
                photo={
                  !badge?.photo_thumb?.includes('firebase')
                    ? 'https://photos.letsroam.com/icons/' + badge?.photo_thumb
                    : badge?.photo_thumb
                }
                index={index + 1}
              />
            );
          })}
        {!removeBottomPadding && <View style={{height: 100, width}} />}
      </ScrollView>
    </View>
  );
};

interface BadgeNewProps {
  title?: string;
  task?: string;
  currentAmount: number | null;
  backgroundColor: string;
  requiredAmount: number | null;
  prizeType: string;
  prizeQuantity: number;
  level?: number;
  date_awarded?: string | null;
  prize_quantity?: number;
  handlePress: () => void;
  photo: string;
  index: number;
}

const BadgeNew: React.FC<BadgeNewProps> = ({
  title = '',
  task = '',
  currentAmount,
  requiredAmount,
  prizeQuantity,
}) => {
  const percentComplete =
    ((currentAmount || 0) / (requiredAmount || 100)) * 100;

  return (
    <TouchableHighlight onPress={() => {}}>
      <View
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: 'white',
          justifyContent: 'space-between',
          borderRadius: 8,
          paddingVertical: 16,
          paddingHorizontal: 20,
          marginTop: 14,
        }}>
        <View
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <BodyText
            text={title}
            textStyle={{
              color: 'black',
              marginTop: 0,
              fontSize: 14,
              fontWeight: '600',
              fontFamily: 'JakartaBold',
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 0,
            }}
          />
          <Text
            style={{
              color: constants.color.orange,
              fontSize: 14,
              lineHeight: 16,
              fontFamily: 'JakartaBold',
            }}>
            {currentAmount} of {requiredAmount} completed
          </Text>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%',
            marginTop: 5,
          }}>
          <View>
            <BodyText
              text={task}
              textStyle={{
                color: 'black',
                fontSize: 12,
                fontWeight: '400',
                fontFamily: 'Jakarta',
                maxWidth: 250,
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0,
                marginTop: 0,
              }}
            />
            <CircleOverlapImages
              width={26}
              maxImages={3}
              containerStyle={{marginTop: 5}}
            />
          </View>

          <ProgressBar
            percentage={percentComplete}
            size={50}
            innerText={prizeQuantity}
          />
        </View>
      </View>
    </TouchableHighlight>
  );
};

export default Badges;

import React from 'react';
import {SafeAreaView, TouchableOpacity, View, Image, Text} from 'react-native';
import {BodyText, TextEntry} from 'src/Modules/CommonView';
import constants from 'src/constants';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {navigationRef} from 'src/Nav/navigationHelpers';
import ExpoImage from 'src/Utils/ExpoImage';
import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {dispatchAction} from 'src/Utils/helpers';
import {
  updateNewWanderListDescription,
  updateNewWanderListImage,
  updateNewWanderListTitle,
} from 'src/Redux/reducers/local.reducer';
import {ScrollView} from 'react-native-gesture-handler';
import {WanderlistStop} from 'src/Modules/WanderlistStop';
import {apiCreateWanderlist} from 'src/Utils/apiCalls';
import {useUserId} from 'src/Hooks/reduxHooks';

const photoFrame = require('src/Images/Icons/photo_frame.png');
const locationPin = require('src/Images/Icons/location_pin.png');

export default function WanderlistCreate() {
  const {width} = useWindowDimensionsSafe();

  const newWanderListPhoto = useTypedSelector(
    (state) => state.local.newWanderList.image,
  );

  const newWanderListTitle = useTypedSelector(
    (state) => state.local.newWanderList.title,
  );

  const newWanderListDescription = useTypedSelector(
    (state) => state.local.newWanderList.description,
  );

  const newWanderListLocations = useTypedSelector(
    (state) => state.local.newWanderList.locations,
  );

  const userId = useUserId();

  console.log({newWanderListLocations});

  const handleSave = () => {
    console.log('save wanderlist', {
      newWanderListPhoto,
      newWanderListTitle,
      newWanderListDescription,
      newWanderListLocations,
    });
    if (!newWanderListPhoto) {
      return navigationRef.simpleAlert(
        'Please Add More Information',
        'Please add a photo for the location',
      );
    } else if (!newWanderListTitle) {
      return navigationRef.simpleAlert(
        'Please Add More Information',
        'Please add a title for the location',
      );
    } else if (!newWanderListDescription) {
      return navigationRef.simpleAlert(
        'Please Add More Information',
        'Please add a description for the location',
      );
    } else if (!newWanderListLocations?.length) {
      return navigationRef.simpleAlert(
        'Please Add More Information',
        'Please add at least one location for the wanderlist',
      );
    }
    apiCreateWanderlist({
      newWanderListPhoto,
      newWanderListTitle,
      newWanderListLocations,
      newWanderListDescription,
      lat: newWanderListLocations[0]?.lat,
      lng: newWanderListLocations[0]?.long,
      userId: userId || undefined,
    }).then((response) => {
      console.log('create wanderlist response', response);
      navigationRef.goBack('WanderlistCreate.tsx');
      navigationRef.simpleAlert(
        'Wanderlist Created',
        'Your wanderlist has been created. One of our editors will now review it within the next week, and it will be posted if approved.',
      );
    });
  };

  // const handleSave = () => {
  //   console.log('save wanderlist', {photo, title, description});
  // };

  return (
    <SafeAreaView style={{flex: 1}}>
      <ScrollView>
        <View
          style={{
            backgroundColor: '#F9F7F2',
            flex: 1,
            paddingHorizontal: 24,
          }}>
          <BodyText
            text="Create Wanderlist"
            textStyle={{
              color: constants.color.black,
              fontFamily: 'JakartaBold',
              fontSize: 24,
              lineHeight: 24,
              marginTop: 12,
              marginLeft: 0,
            }}
          />
          <BodyText
            text="Select a cover image, title, and description. Then, add your locations below."
            textStyle={{
              color: constants.color.black,
              fontFamily: 'Jakarta',
              fontSize: 15,
              lineHeight: 21,
              marginTop: 12,
              marginLeft: 0,
            }}
          />
          <TouchableOpacity
            style={{
              width: width - 40,
              height: 140,
              borderRadius: 10,
              marginTop: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderWidth: 1,
              borderStyle: 'dashed',
              borderColor: constants?.color?.black_20,
            }}
            onPress={() => {
              navigationRef.navigate(
                'PhotoVideoCamera',
                {
                  text: 'Upload A Photo For This Location',
                  onCapture: (data) => {
                    const {downloadURL} = data;
                    dispatchAction(updateNewWanderListImage(downloadURL));

                    console.log('WanderlistCreate onCapture', data);
                  },
                  hideShare: true,
                  showPhotoUpload: true,
                  isVideo: false,
                },
                'WanderlistCreate',
              );
            }}>
            {!newWanderListPhoto && (
              <>
                <Image
                  source={photoFrame}
                  style={{
                    width: 30,
                    height: 30,
                    resizeMode: 'contain',
                  }}
                />

                <Text
                  style={{
                    fontFamily: 'JakartaBold',
                    fontSize: 15,
                    lineHeight: 21,
                    marginTop: 12,
                  }}>
                  Add cover image
                </Text>
              </>
            )}

            {!!newWanderListPhoto && (
              <ExpoImage
                source={{uri: newWanderListPhoto}}
                resizeMode={'cover'}
                style={{width: width - 40, height: 140, borderRadius: 10}}
              />
            )}
            {/* {!photo && <Icon name="camera" size={40} color={'white'} />} */}
          </TouchableOpacity>

          <Text
            style={{
              fontFamily: 'JakartaBold',
              fontSize: 15,
              lineHeight: 21,
              marginTop: 24,
            }}>
            Title
          </Text>
          <TextEntry
            testID={'search locations'}
            value={newWanderListTitle}
            handleChange={(title) =>
              dispatchAction(updateNewWanderListTitle(title))
            }
            containerStyle={{
              backgroundColor: 'white',
              height: 50,
              borderRadius: 8,
              marginTop: 12,
            }}
            inputStyle={{
              backgroundColor: 'white',
              textAlign: 'left',
              paddingHorizontal: 10,
              marginTop: 10,
            }}
          />
          <Text
            style={{
              fontFamily: 'JakartaBold',
              fontSize: 15,
              lineHeight: 21,
              marginTop: 24,
            }}>
            About this Wanderlist
          </Text>
          <TextEntry
            testID={'search locations'}
            multiline
            value={newWanderListDescription}
            handleChange={(description) =>
              dispatchAction(updateNewWanderListDescription(description))
            }
            containerStyle={{
              backgroundColor: 'white',
              height: 140,
              borderRadius: 8,
              marginTop: 12,
            }}
            inputStyle={{
              backgroundColor: 'white',
              textAlign: 'left',
              paddingHorizontal: 10,
              height: 140,
            }}
          />
          <Text
            style={{
              fontFamily: 'JakartaBold',
              fontSize: 15,
              lineHeight: 21,
              marginTop: 24,
            }}>
            Set locations
          </Text>

          {newWanderListLocations?.map((stop, index) => (
            <WanderlistStop
              key={index}
              editIndex={index}
              // @ts-ignore
              stop={stop}
            />
          ))}

          <View style={{marginTop: 32}}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Image
                source={locationPin}
                style={{
                  width: 32,
                  height: 32,
                  resizeMode: 'contain',
                }}
              />
              <TouchableOpacity
                onPress={() => {
                  navigationRef.navigate(
                    'WanderlistAddLocation',
                    {},
                    'WanderlistCreate.tsx',
                  );
                }}>
                <Text
                  style={{
                    color: constants.color.orange,
                    fontFamily: 'JakartaBold',
                    fontSize: 15,
                    lineHeight: 21,
                    marginLeft: 12,
                  }}>
                  + Add location
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={{height: 600, width: 300}} />
      </ScrollView>
      <ModalOmniBar
        omniButton={{
          icon: require('src/Images/logo.png'),
          iconLibrary: 'FontAwesome',
          color: constants?.color?.orange,
          text: 'Create Wanderlist',
          handlePress: handleSave,
        }}
        left={{
          icon: 'arrow-left-circle',
          handlePress: () =>
            navigationRef.navigate(
              'Wanderlists',
              null,
              'LocalCreateLocationModal.tsx',
            ),
          text: 'Cancel',
        }}
        containerStyle={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </SafeAreaView>
  );
}

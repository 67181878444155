import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {View, ScrollView} from 'react-native';
import ExpoImage from 'src/Utils/ExpoImage';
import {apiCheckAnyChallenge} from 'src/Utils/apiCalls';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

///////////////////////////
///////////// components
import {ContinueButton} from 'src/Modules/CommonView';

///////////////////////////
///////////// utils
import {markTeamPhotoComplete} from 'src/Utils/huntFunctions_v2';

// import photoImage from 'src/Images/photoChallengeImage.png';
import photoImage from 'src/Images/TeamPhoto.png';

import {navigationRef} from 'src/Nav/navigationHelpers';

import {BodyText} from 'src/Modules/CommonView/Text';

const TeamPhoto = () => {
  const {width} = useWindowDimensionsSafe();

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  const eventId = useTypedSelector((state) => state.event?.eventId);
  const theme = useTypedSelector((state) => state.group?.info?.theme);
  const isBarHunt =
    useTypedSelector((state) => state.group?.info?.isBarHunt) || false;

  const handlePhotoComplete = async ({
    photoUrl,
    shareUUID,
  }: {
    photoUrl: string;
    shareUUID: string;
  }) => {
    console.log('handlePhotoComplete');
    // writes to lr_app_challenges_completions
    // this api also "grades" the photo but this is
    // an "Auto Pass" which gives full points
    await apiCheckAnyChallenge({
      photo_url: photoUrl,
      share_UUID: shareUUID,
      points_earned: 500,
    });

    // this writes to firebase
    await markTeamPhotoComplete({
      photoUrl,
      shareUUID,
    });

    navigationRef.navigate(
      'ClassicIntro',
      null,
      'ScavengerHunt_v2/TeamPhoto.tsx',
    );
  };

  const getFirstPhotoTask = () => {
    if (theme === 'Bachelorette') {
      return 'Bride tribe assemble! Get all the gals together for a team photo to kick things off.';
    } else if (theme === 'Bachelor') {
      return 'Scavenger hunts are for the boys! Get all the guys together for a team photo.';
    } else if (theme === 'Birthday') {
      return 'Let’s get this party started! Get a team photo, make sure the birthday star is front and center.';
    } else if (theme === 'Date Night') {
      return 'Squeeze in tight with your date tonight! Take your best selfie as if you’re in a photo booth.';
    } else if (theme === 'Holiday') {
      return '’Tis the season to be merry, gather your group close for a team photo of everyone showing off their holiday cheer!';
    } else if (isBarHunt) {
      return `The competition is about to start but let's not forget, we're all friends here. Take an awesome photo of your team getting ready to party!`;
    }
    return 'First things first, take an epic photo with your team!';
  };

  return (
    <ScrollView
      style={{
        flex: 1,
        minHeight: 160,
        backgroundColor: 'white',
      }}>
      <View style={{marginBottom: 100}}>
        <BodyText
          textStyle={{
            marginTop: !!isHybridHunt ? 50 : 20,
            fontStyle: 'italic',
            minHeight: 80,
            color: constants?.color?.gray3,
            fontFamily: constants?.font.circular,
            fontSize: 16,
            lineHeight: 20,
            marginHorizontal: 15,
          }}
          text={
            getFirstPhotoTask() +
            (!!eventId
              ? '!'
              : `\n \nTake your time to pose for the PERFECT photo. You'll have the option to receive a free epic post card including your team's photo at the end of your hunt!`)
          }
        />
        <View style={{alignItems: 'center'}}>
          <ExpoImage
            style={{width: width - 20, height: 240, marginHorizontal: 10}}
            source={photoImage}
            resizeMode={'contain'}
          />
          <View style={{alignItems: 'center'}}>
            <ContinueButton
              text={`Take The Photo`}
              width={220}
              handlePress={() => {
                navigationRef.navigate(
                  'PhotoVideoCamera',
                  {
                    onCapture: (data) => {
                      handlePhotoComplete({
                        photoUrl: data?.downloadURL,
                        shareUUID: data?.shareUUID,
                      });
                    },
                    onReport: () => {
                      navigationRef.navigate(
                        'ReportModal',
                        null,
                        'ScavengerHunt_v2/TeamPhoto.tsx',
                      );
                    },
                    text: getFirstPhotoTask(),
                  },
                  'TeamPhoto',
                );
              }}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default TeamPhoto;

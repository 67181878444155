import * as t from 'io-ts';
import {validateResult} from 'src/types/typesHelpers';
import {TGroupInfo} from './TGroupInfo';

// io-ts type for GroupPlayer
export const TSimpleGroupPlayerIO = t.partial({
  userId: t.string,
  firstName: t.string,
  photoColor: t.union([t.string, t.null]),
  photoUrl: t.union([t.string, t.null]),
});

// Derive the TypeScript type from the io-ts type for GroupPlayer
export type TSimpleGroupPlayer = t.TypeOf<typeof TSimpleGroupPlayerIO>;

// io-ts type for GroupInfo

// Using intersection to combine mandatory and optional properties for GroupInfo
const TSimpleGroupInfoIO = t.intersection([
  t.type({
    groupId: t.string,
    teamName: t.string,
  }),
  t.partial({
    score: t.union([t.number, t.null]),
    groupPhoto: t.union([t.string, t.null]),
    place: t.union([t.number, t.null]), // only used for corporate groups for list ranks
  }),
]);

// Derive the TypeScript type from the io-ts type for GroupInfo
export type TSimpleGroupInfo = t.TypeOf<typeof TSimpleGroupInfoIO>;

// Type guard for runtime validation for GroupPlayer
export const isTSimpleGroupPlayer = (
  input: unknown,
): input is TSimpleGroupPlayer => {
  return validateResult(
    input,
    TSimpleGroupPlayerIO,
    'TSimpleGroupPlayer',
    false,
  );
};

// Type guard for runtime validation for GroupInfo
export const isTSimpleGroupInfo = (
  input: unknown,
  logErrors: boolean,
): input is TSimpleGroupInfo => {
  return validateResult(
    input,
    TSimpleGroupInfoIO,
    'TSimpleGroupInfo',
    logErrors,
  );
};

// Function to convert non-simple group to simple group safely
export const convertToSimpleGroup = (group: TGroupInfo) => {
  if (!group) {
    return null;
  }

  const simpleGroup: TSimpleGroupInfo = {
    groupId: group.groupId,
    teamName: group.teamName,
    score: group.score || null,
    place: group.place || null,
    groupPhoto: group.groupPhoto || null,
  };

  return simpleGroup;
};

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import {View} from 'react-native';
import React, {useState} from 'react';
//////////////////////////
///////  components
import {TopTab, TopTabButton} from 'src/Modules/TabViews';

import AboutExplorerPass from 'src/ExplorerPass/AboutExplorerPass';
import CallSupportLine from 'src/HeaderComponent/CallSupportLine';
import YourRewardsView from 'src/ExplorerPass/YourExplorerPassView';

import {useExplorerPass} from 'src/Utils/explorerPassHelper';

const ExplorerPassMapLanding: React.FC = () => {
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const [view, setView] = useState<string>('about');

  const pass = useExplorerPass();
  const hasValidExplorerPass = !!pass;

  // Alert.alert(hasValidExplorerPass ? 'Valid Pass' : 'Invalid Pass');

  return (
    <View style={{flex: 1, position: 'relative'}}>
      <CallSupportLine />
      <TopTab>
        {!playTestState && !hasValidExplorerPass && (
          <TopTabButton
            text={'What Is It?'}
            active={view === 'about'}
            handlePress={() => setView('about')}
            index={0}
          />
        )}
        {!playTestState && !!hasValidExplorerPass && (
          <TopTabButton
            text={`Your Pass`}
            active={view === 'about'}
            handlePress={() => setView('about')}
            index={1}
          />
        )}
      </TopTab>
      <View
        style={{
          flex: 1,
          position: 'relative',
          backgroundColor: constants?.color?.white1,
        }}>
        {view === 'about' && !!hasValidExplorerPass && <YourRewardsView />}

        {view === 'about' && !hasValidExplorerPass && <AboutExplorerPass />}
      </View>
    </View>
  );
};

// eslint-disable-next-line
export default ExplorerPassMapLanding;

import constants from 'src/constants';
import React, {useState, useEffect} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  Alert,
} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

//////////////////////////
///////  components
import {
  ContinueButton,
  BodyText,
  CloseIconButton,
} from 'src/Modules/CommonView';
import Icon from 'react-native-vector-icons/FontAwesome';
import useParams from 'src/Hooks/useParams';
import {navigationRef} from 'src/Nav/navigationHelpers';
import DropDown from 'src/Modules/DropDown';

const ConfirmModal: React.FC = () => {
  const {width} = useWindowDimensionsSafe();

  const {
    handleConfirm = () => {
      navigationRef.closeModal('ConfirmModal', 'default confirm');
    },
    confirmText,
    cancelText,
    bodyText,
    handleCancel = () => {
      navigationRef.closeModal('ConfirmModal', 'default cancel');
    },
    backgroundColor = 'transparent',
    confirmColor,
    titleText,
    titleBodyText,
    children,
    thirdOption,
    handleThird,
    titleImage = false,
    titleIcon = false,
    thirdOptionColor,
    cancelColor = constants?.color?.gray3,
    numberedPoints,
    showCloseIcon,
    showPrompt = false,
    multiline = false,
    dropDownOptions = false,
    maxLength,
    placeholder = 'Your Selection',
    placeholderDropDown = 'Your Selection',
    initialPromptText,
    initialDropdownValue = null,
    items = [],
  } = useParams<'ConfirmModal'>();

  const [dropDownValue, setDropDownValue] = useState<string | null>(
    initialDropdownValue,
  );
  const [loading, setLoading] = useState<boolean>();
  const [promptText, setPromptText] = useState<string | undefined>();

  useEffect(() => {
    if (initialPromptText) {
      setPromptText(initialPromptText);
    }
  }, [initialPromptText]);

  console.log('ConfirmModal', {initialDropdownValue, placeholderDropDown});

  return (
    <SafeAreaView
      style={{
        flex: 1,
        justifyContent: 'flex-end',
        backgroundColor: backgroundColor || undefined,
      }}>
      <TouchableOpacity
        style={{flex: 1}}
        onPress={() => {
          if (Platform.OS == 'android' && promptText) {
            return handleConfirm(promptText, null, () =>
              navigationRef.closeModal(
                'ConfirmModal',
                'confirm android fall back',
              ),
            );
          }
          navigationRef.closeModal('ConfirmModal', 'background press');
        }}
      />
      <KeyboardAvoidingView
        style={{flex: 1, justifyContent: 'flex-end'}}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        enabled>
        <View
          style={{
            width,
            backgroundColor: constants?.color?.white,
            paddingVertical: 10,
            paddingHorizontal: 10,
            paddingBottom: 20,
            borderRadius: 10,
            position: 'relative',
          }}>
          {!!titleImage && (
            <Image
              source={titleImage}
              style={{
                height: 140,
                width: width - 30,
                resizeMode: 'contain',
                marginTop: 10,
                marginBottom: 20,
              }}
            />
          )}
          {!!titleIcon && (
            <View
              style={{
                width: width - 30,
                display: 'flex',
                alignItems: 'center',
              }}>
              <Icon
                name={titleIcon}
                size={120}
                color={constants?.color.gray3}
                style={{
                  marginTop: 10,
                  marginBottom: 20,
                }}
              />
            </View>
          )}
          {!!titleText && (
            <BodyText
              text={titleText}
              textStyle={{textAlign: 'center', fontWeight: '700'}}
            />
          )}
          {!!titleBodyText && (
            <BodyText
              text={titleBodyText}
              textStyle={{
                fontSize: numberedPoints ? 16 : 14,
                paddingBottom: numberedPoints ? 0 : 15,
              }}
            />
          )}

          {items?.map((item, index) => (
            <View key={index} style={{marginVertical: 12}}>
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: 16,
                  textAlign: index > 0 ? 'left' : 'center',
                }}>
                {item.heading}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  marginTop: index < 1 ? 10 : 5,
                  textAlign: index > 0 ? 'left' : 'center',
                }}>
                {item.description}
              </Text>
            </View>
          ))}

          {!!dropDownOptions && (
            <DropDown
              dropDownOptions={dropDownOptions}
              value={dropDownValue}
              testID={'confirmModalDropdown'}
              setValue={setDropDownValue}
              placeholder={placeholderDropDown || undefined}
              containerStyle={{justifyContent: 'center', width}}
              buttonStyle={{
                width: 300,
                minHeight: 50,
              }}
            />
          )}
          {!!showPrompt && (
            <View
              style={{
                width,
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <TextInput
                testID="prompt"
                style={{
                  height: multiline ? 150 : 50,
                  fontSize: 18,
                  elevation: 5,
                  marginTop: 20,
                  marginBottom: 30,
                  width: 300,
                  backgroundColor: 'white',
                  borderRadius: 5,
                  borderWidth: 1,
                  overflow: 'hidden',
                  padding: multiline ? 5 : 0,
                  textAlign: multiline ? 'left' : 'center',
                }}
                allowFontScaling={false}
                multiline={!!multiline}
                value={promptText || ''}
                onChangeText={(newValue) => setPromptText(newValue)}
                maxLength={maxLength || undefined}
                placeholder={placeholder || undefined}
                autoFocus={false}
                underlineColorAndroid="white"
                blurOnSubmit
              />
            </View>
          )}
          {!!numberedPoints && (
            <View>
              {numberedPoints?.map?.((item, index) => {
                const {title, text} = item;
                const colors = [
                  constants?.color?.orange,
                  constants?.color?.blue,
                  constants?.color?.teal,
                  constants?.color?.gray2,
                  constants?.color?.yellow,
                ];
                return (
                  <View
                    key={index}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 15,
                    }}>
                    <View
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 25,
                        backgroundColor: colors[index % 5],
                        justifyContent: 'center',
                        marginBottom: 20,
                      }}>
                      <Text
                        style={{
                          textAlign: 'center',
                          color: constants?.color?.white,
                        }}>
                        {index + 1}
                      </Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        marginLeft: 20,
                        marginTop: title ? 0 : 0,
                      }}>
                      {!!title && (
                        <BodyText
                          text={title}
                          textStyle={{
                            fontWeight: '700',
                            marginLeft: 0,
                            fontSize: 15,
                            marginTop: 0,
                          }}
                        />
                      )}
                      <BodyText
                        text={text}
                        textStyle={{
                          fontSize: title ? 14 : 17,
                          lineHeight: title ? 16 : 20,
                          marginLeft: 0,
                          marginTop: 0,
                        }}
                      />
                    </View>
                  </View>
                );
              })}
            </View>
          )}
          {!!children && children}
          <View style={{flexDirection: 'row', marginVertical: 10}}>
            {!!confirmText && (
              <ContinueButton
                text={confirmText}
                loading={loading}
                testID={'confirmButton'}
                handlePress={async () => {
                  console.log(
                    'handle confirm about to be fired: 1',
                    showPrompt,
                  );
                  if (dropDownOptions && showPrompt) {
                    if (!promptText) {
                      return Alert.alert(
                        'Please enter a value for the prompt.',
                      );
                    }

                    console.log(
                      'dropDownOptions handleConfirm about to be fired',
                      promptText,
                    );

                    return handleConfirm(promptText, dropDownValue, () =>
                      navigationRef.closeModal('ConfirmModal', 'confirm done'),
                    );
                  } else if (showPrompt) {
                    console.log('handleConfirm about to be fired', promptText);

                    return handleConfirm(promptText || null, null, () =>
                      navigationRef.closeModal('ConfirmModal', 'confirm done'),
                    );
                  }
                  setLoading(false);
                  await handleConfirm(null, null, () =>
                    navigationRef.closeModal('ConfirmModal', 'confirm 2'),
                  );
                  setLoading(false);
                }}
                background={confirmColor || constants?.color?.orange}
              />
            )}
          </View>
          <View
            style={{
              flexDirection: 'row',
              marginVertical: !!thirdOption ? 0 : 10,
              marginBottom: !!thirdOption ? 10 : 0,
            }}>
            {!!cancelText && (
              <ContinueButton
                text={cancelText}
                loading={false}
                testID={'cancelButton'}
                handlePress={() => {
                  handleCancel
                    ? handleCancel(() =>
                        navigationRef.closeModal('ConfirmModal', 'cancelled'),
                      )
                    : () =>
                        navigationRef.closeModal('ConfirmModal', 'cancelled');
                }}
                background={cancelColor || undefined}
              />
            )}
            {!!thirdOption && (
              <ContinueButton
                text={thirdOption}
                loading={false}
                handlePress={() => {
                  handleThird
                    ? handleThird(() =>
                        navigationRef.closeModal('ConfirmModal', 'third'),
                      )
                    : navigationRef?.goBack('Modules/ConfirmModal.tsx');
                }}
                background={thirdOptionColor || constants?.color?.gray3}
              />
            )}
          </View>
          {!!bodyText && (
            <BodyText
              text={bodyText}
              textStyle={{
                fontStyle: 'italic',
                fontSize: 14,
                marginTop: 10,
                marginBottom: 20,
              }}
            />
          )}
          {!!showCloseIcon && (
            <CloseIconButton
              handlePress={() =>
                navigationRef?.goBack('Modules/ConfirmModal.tsx')
              }
              side="right"
            />
          )}
        </View>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default ConfirmModal;

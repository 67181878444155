import constants from 'src/constants';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';

import {PrimaryButton, FooterOverlay} from 'src/Modules/CommonView';
import Map from 'src/Modules/CommonGame/Map';
import {navigationRef} from 'src/Nav/navigationHelpers';
import useParams from 'src/Hooks/useParams';

const HuntMap: React.FC = () => {
  const {coordinates} = useParams<'HuntMap'>();

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: constants?.color?.white}}>
      <Map coordinates={coordinates} />
      <FooterOverlay>
        <PrimaryButton
          text={'Back'}
          background={constants?.color?.gray3}
          handlePress={() => navigationRef.goBack('Hunt Map')}
        />
      </FooterOverlay>
    </SafeAreaView>
  );
};

export default HuntMap;

import constants from 'src/constants';
import React, {useRef} from 'react';
import {View, Vibration, Alert} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';

///////////////////////////
///////////// components
import {ContinueButton} from 'src/Modules/CommonView';

///////////////////////////
///////////// utils
import {
  markSelectionChallengeComplete,
  markSelectionChallengeCompleteParams,
} from 'src/Utils/huntFunctions_v2';
import {useChallenge} from 'src/Hooks/gameHooks';
import {useUserId} from 'src/Hooks/reduxHooks';
import {TChallengeAnswers} from 'src/types/TChallenge';
import {updateDataAtPath} from 'src/Utils/fireUtils';
import {getReduxState} from 'src/Utils/helpers';

interface TextMultiChoiceProps {
  noTimer?: boolean;
  completed?: boolean;
  singleCompletion?: boolean;
  onComplete?: () => void;
  allowMultiGuess?: boolean;
}

const TextMultiChoice: React.FC<TextMultiChoiceProps> = ({
  noTimer,
  completed = false,
  singleCompletion = false,
  onComplete,
  allowMultiGuess,
}) => {
  const userId = useUserId();
  const challenge = useChallenge();

  const guessTrackingForOverride = useRef<Record<string, number>>({});

  const handleAnswerPress = async (answer: TChallengeAnswers) => {
    console.log('TextMultiChoice handleAnswerPress fired: ', {answer});

    if (!challenge || !challenge?.challengeId)
      return console.error('no challenge found');

    const {challengeId} = challenge;

    console.log({challenge});

    const pressData: markSelectionChallengeCompleteParams = {
      ...answer,
      challengeId,
      singleCompletion,
      allowMultiGuess,
    };

    let previousAttempts = 0;
    const {
      answers = [],
      maxPoints = 100,
      challengeHandleDoneOverride,
    } = challenge;
    answers?.map?.((answer) => {
      if (answer.selectedAt) {
        previousAttempts++;
      }
    });
    console.log({answers});
    // taking off max potential points if wrong
    if (!answer.correct) {
      let hintsDeductions;

      // if we only allow 1 guess and they guess wrong, deduct full points
      if (!allowMultiGuess) {
        hintsDeductions = maxPoints;
      } else {
        hintsDeductions =
          ((maxPoints || 100) * (previousAttempts + 1)) /
          (answers?.length || 1);
      }

      const groupId = getReduxState((state) => state.group?.info?.groupId);

      // Alert.alert(hintsDeductions + '', points + '');

      updateDataAtPath(
        `scavengerhunt/completions/${groupId}/location_v2/allChallenges/${challengeId}/hintsDeductions`,
        hintsDeductions,
      );
    }
    // return console.log('a multi choice answer was pressed', { pressData });

    console.log({pressData});
    await markSelectionChallengeComplete(pressData);

    if (!answer.correct) {
      Vibration.vibrate(200);
    }
    if (!!answer.correct) {
      //Vibration.vibrate([0, 100, 400, 100]);
    }

    if (challengeHandleDoneOverride) {
      // const newGuesses = {
      //   ...guessTrackingForOverride.current,
      // };
      guessTrackingForOverride.current = {
        ...guessTrackingForOverride.current,
      };

      return challengeHandleDoneOverride(answer);
    }

    if (answer.correct || !allowMultiGuess) {
      onComplete && onComplete?.();
    }
  };
  if (!challenge) return <></>;

  const {answers, completions = {}, challengeId} = challenge;
  let userHasCompleted =
    userId && completions[userId] && !noTimer ? true : false;
  if (completed) {
    userHasCompleted = true;
  }

  console.log('TextMultipleChoice', {answers});
  return (
    <View
      style={{
        justifyContent: 'center',
      }}>
      {!userHasCompleted && (
        <View style={{marginTop: 20}}>
          {!!answers &&
            answers?.map?.((answer, index) => {
              return (
                <View
                  style={{height: 50, position: 'relative'}}
                  key={`challenge_${challengeId}_${index}`}>
                  <ContinueButton
                    text={answer.option || 'Option'}
                    forceHTML
                    containerStyle={{
                      flex: 1,
                      marginVertical: 5,
                      borderColor: 'green',
                      borderWidth: __DEV__ && answer.correct ? 1 : 0,
                    }}
                    background={
                      answer.selectedAt
                        ? constants?.color?.gray3
                        : constants?.color?.blue
                    }
                    handlePress={() => {
                      if (answer.selectedAt) {
                        return console.log('already done');
                      } else if (handleAnswerPress) {
                        handleAnswerPress(answer);
                      } else {
                        console.error('no handleAnswerPress');
                      }
                    }}
                  />
                  {!!noTimer &&
                    (!!answer.selectedAt ||
                      !!guessTrackingForOverride.current[index]) &&
                    !answer.correct && (
                      <View style={{position: 'absolute', top: 10, right: 15}}>
                        <Icon
                          name={'close'}
                          size={30}
                          color={constants?.color?.red}
                        />
                      </View>
                    )}
                  {!!noTimer &&
                    (!!answer.selectedAt ||
                      !!guessTrackingForOverride.current[index]) &&
                    !!answer.correct && (
                      <View style={{position: 'absolute', top: 10, right: 15}}>
                        <Icon name={'check'} size={30} color={'green'} />
                      </View>
                    )}
                  {}
                </View>
              );
            })}
        </View>
      )}
      {!!userHasCompleted &&
        !!userId &&
        !!completions[userId] &&
        !!completions[userId].option && (
          <View style={{marginTop: 20}}>
            <ContinueButton
              handlePress={() => {
                'Just showing the answer';
              }}
              text={completions[userId].option || 'Correct Answer'}
              forceHTML
              containerStyle={{flex: 1, marginVertical: 5}}
              background={constants?.color?.orange}
            />
          </View>
        )}
    </View>
  );
};

export default TextMultiChoice;

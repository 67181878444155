import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Platform,
  Linking,
  Alert,
  Image,
  ImageSourcePropType,
} from 'react-native';
import {ExpandFullscreenButton} from 'src/Modules/CommonView/Buttons';
import MapMarker from 'src/Modules/MapMarker';
import MapView from 'src/Modules/Native/MapView';
import {THunt} from 'src/types/THunt';
import {TRegion} from 'src/types/TRegion';

const capitalize = (s: string): string => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

interface SkillLevelItem {
  desc: string;
  icon: ImageSourcePropType;
}

const skillLevel: SkillLevelItem[] = [
  {
    desc: 'Medium',
    icon: require('src/Images/easyIcon.png'),
  },
  {
    desc: 'Challenging',
    icon: require('src/Images/mildIcon.png'),
  },
  {
    desc: 'Very Challenging',
    icon: require('src/Images/hardIcon.png'),
  },
];

interface GetDirectionsCardProps {
  starting_location: string;
  hunt?: THunt;
  barHunt?: boolean;
  lat: number;
  long: number;
  title?: string;
  width?: number;
  style?: object;
}

const GetDirectionsCard: React.FC<GetDirectionsCardProps> = ({
  starting_location,
  hunt,
  barHunt = false,
  lat,
  long,
  title = 'Starting Location:',
  style = {},
  width,
}) => {
  const group = useTypedSelector((state) => state.group?.info);
  hunt = (hunt || group) as THunt;

  const handlePress = () => {
    Alert.alert(
      'Warning',
      'Apple and Google Maps can sometimes give inaccurate directions. Double check that the starting location given matches the one shown here in the app.',
      [
        {
          text: 'View Directions',
          onPress: () => {
            if (Platform.OS == 'android') {
              Linking?.openURL('geo:0,0?q=' + starting_location);
            } else {
              Linking?.openURL('http://maps.apple.com/?q=' + starting_location);
            }
          },
        },
        {
          text: 'Cancel',
        },
      ],
    );
  };

  const renderAdditionalInfo = (hunt: GetDirectionsCardProps['hunt']) => {
    if (!hunt) {
      return <View />;
    }

    const art_focus = hunt?.art_focus || '';
    const culture_focus = hunt?.culture_focus || '';
    const history_focus = hunt?.history_focus || '';
    const difficulty_focus = hunt?.difficulty_focus || '';
    const distance_miles = hunt?.distance_miles || '';
    const hunt_id = hunt?.hunt_id || '';
    const huntLength = hunt?.huntLength || '';

    console.log({distance_miles, hunt});

    const distanceFixed = parseFloat(distance_miles || huntLength);

    let distance = distanceFixed < 2.5 ? distanceFixed : 2.5;

    if (isNaN(distance)) {
      distance = 2.5;
    }

    console.log({distanceFixed, distance, huntLength, distance_miles});

    const playTime = 0.5 + 0.5 * Math?.round(distance);
    const skill = skillLevel[parseInt(difficulty_focus) - 1] || skillLevel[0];
    const allFocus = [
      {name: 'history', score: history_focus},
      {name: 'culture', score: culture_focus},
      {name: 'art', score: art_focus},
    ];
    const focus = allFocus.sort((a, b) => {
      return (Number(b?.score) || 0) - (Number(a?.score) || 0) > 1 ? 1 : -1;
    })[0];

    const focusName = capitalize(focus ? focus.name : 'History!');
    const skillName = capitalize(skill ? skill.desc : 'Easy!');

    return (
      <View>
        <Line
          left={'Course Length:'}
          right={
            Math.round(distance * 10) / 10 +
            ' mi ' +
            '(' +
            Math?.round((distance / 0.62137) * 10) / 10 +
            ' km)'
          }
        />
        {hunt_id != '2266' && (
          <Line left={'Average Play Time'} right={`${playTime} Hour${playTime == 1 ? '' : 's'}`} />
        )}
        <Line left={'Difficulty:'} right={skillName} />
        <Line left={'Primary Focus:'} right={focusName} />
        <Line left={'Travel Method:'} right={'Walking'} isLast />
      </View>
    );
  };

  console.log('the get directions card is rerendering');

  const formatedStartingLocation =
    starting_location?.replace(/\d{5}, USA/gi, '') || '';

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const latFloat = Number(lat || 39.7392);
  const lngFloat = Number(long || -104.9903);

  const initialRegion: TRegion = {
    latitude: latFloat,
    longitude: lngFloat,
    latitudeDelta: 0.025,
    longitudeDelta: 0.025,
  };

  return (
    <View
      style={{
        margin: 10,
        width: width || (barHunt ? 300 : undefined),
        elevation: 8,
        backgroundColor: 'white',
        shadowColor: 'black',
        shadowOffset: {width: 0, height: 0},
        shadowRadius: 6,
        shadowOpacity: 0.35,
        ...style,
      }}>
      <View style={{position: 'relative'}}>
        {!isExpanded && (
          <TouchableOpacity
            onPress={() => {
              setIsExpanded(!isExpanded);
            }}>
            <Image
              style={{marginTop: 0, height: 180, width: '100%'}}
              source={{
                uri: `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/url-https%3A%2F%2Fwww.letsroam.com%2Fassets%2Fimg%2Flocation.png(${lngFloat},${latFloat})/${lngFloat},${latFloat},13.75/400x200?access_token=pk.eyJ1IjoibWZoYXJkaW5nIiwiYSI6ImNqbGlicnQ2MzBkeDgzcm9lbnQxb2dhNGoifQ.oWHnl0D4-eSNX-43SHqK8A&`,
              }}
            />
          </TouchableOpacity>
        )}
        {!!isExpanded && !!initialRegion && !!Object.keys(initialRegion).length && (
          <MapView
            showsUserLocation
            style={{
              marginTop: 0,
              height: 300,
              width: barHunt ? 300 : undefined,
            }}
            initialRegion={initialRegion || {
              latitude: 0,
              longitude: 0,
              latitudeDelta: 1,
              longitudeDelta: 1,
            }}>
            <MapMarker
              key={`huntMarker_${lat}${Math?.random() * Date.now()}`}
              lat={lat || 0}
              long={long || 0}
              showMarker={true}
              setSelectedHuntId={handlePress}
            />
          </MapView>
        )}
        {Platform.OS != 'web' && (
          <ExpandFullscreenButton
            isFullscreen={isExpanded}
            handlePress={() => {
              setIsExpanded(!isExpanded);
            }}
          />
        )}
      </View>
      <View
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: 0,
        }}
      />
      <TouchableOpacity
        activeOpacity={0.85}
        onPress={handlePress}
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: 10,
        }}>
        <Text
          style={{
            color: constants?.color?.orange,
            fontSize: 16,
            fontFamily: constants?.font.circular,
            paddingTop: 3,
          }}>
          {title}
        </Text>
        <Text
          style={{
            fontSize: 14,
            color: 'white',
            fontFamily: constants?.font.circular,
            backgroundColor: constants?.color?.teal,
            width: 110,
            textAlign: 'center',
            paddingTop: 3,
            paddingBottom: 3,
            borderRadius: 10,
          }}>
          Get Directions
        </Text>
      </TouchableOpacity>
      <Line
        left={
          formatedStartingLocation?.length > 40
            ? formatedStartingLocation.substring(0, 40) + '...'
            : formatedStartingLocation
        }
        isLast={!hunt}
      />
      {!!hunt && renderAdditionalInfo(hunt)}
    </View>
  );
};

interface LineProps {
  left?: string;
  right?: string;
  isLast?: boolean;
}

const Line: React.FC<LineProps> = ({left = '', right = '', isLast = false}) => {
  return (
    <View
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        borderBottomColor: constants?.color?.white1,
        borderBottomWidth: isLast ? 0 : 3,
        paddingTop: 5,
        paddingBottom: 3,
        justifyContent: 'space-between',
        flexDirection: 'row',
      }}>
      {!!left && (
        <Text
          style={{
            fontSize: 14,
            color: constants?.color?.gray3,
            fontFamily: constants?.font.circular,
            fontWeight: '500',
          }}>
          {left}
        </Text>
      )}
      {!!right && (
        <Text
          style={{
            fontSize: 14,
            color: constants?.color?.gray3,
            fontFamily: constants?.font.circular,
            fontWeight: '100',
          }}>
          {right}
        </Text>
      )}
    </View>
  );
};

export default GetDirectionsCard;

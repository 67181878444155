import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useMemo, useRef, useState} from 'react';
import {Text, View, Platform} from 'react-native';
import MapView, {Marker} from 'src/Modules/Native/MapView';
import SimpleStarRating from 'src/Modules/CommonView/SimpleStarRating';
import ExpoImage from 'src/Utils/ExpoImage';
import {setCurrentHunt} from 'src/Redux/reducers/hunts.reducer';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';
import {Clusterer} from 'react-native-clusterer';
import {
  cloudFlareOptimizeImage,
  dispatchAction,
  isNumeric,
} from 'src/Utils/helpers';

import {THunt} from 'src/types/THunt';
import constants from 'src/constants';
import {ExpandFullscreenButton} from 'src/Modules/CommonView';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

// dinner plate empty white
const fallbackImg = {
  uri: cloudFlareOptimizeImage(
    'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648578804_medium.jpg',
  ),
};

const calculateInitialRegion = (hunts: THunt[], isModal: boolean) => {
  const latitudes: number[] = [];
  const longitudes: number[] = [];

  hunts.map((hunt) => {
    if (hunt.lat) {
      latitudes.push(hunt.lat);
    }
    if (hunt.long) {
      longitudes.push(hunt.long);
    }
  });

  const minLat = Math.min(...latitudes);
  const maxLat = Math.max(...latitudes);
  const minLong = Math.min(...longitudes);
  const maxLong = Math.max(...longitudes);

  const latitudeDelta = maxLat - minLat;
  const longitudeDelta = maxLong - minLong;

  // bigger values will zoom out more
  const zoomFactor = isModal ? 2 : 1;
  const adjustedLatitudeDelta = latitudeDelta * zoomFactor;
  const adjustedLongitudeDelta = longitudeDelta * zoomFactor;

  return {
    latitude: (minLat + maxLat) / 2,
    longitude: (minLong + maxLong) / 2,
    latitudeDelta: adjustedLatitudeDelta,
    longitudeDelta: adjustedLongitudeDelta,
  };
};

type TPoint = {
  type: 'Feature';
  geometry: {
    type: 'Point';
    coordinates: [number, number];
  };
  properties: THunt;
};

const HomeLandingMapWrapper = ({isModal = false}: {isModal?: boolean}) => {
  const huntsList = useTypedSelector((state) => state.hunts.huntsList || []);

  const unClusteredPoints: TPoint[] = [];

  const initialRegion = calculateInitialRegion(huntsList.slice(0, 4), isModal);

  huntsList?.map((hunt) => {
    const {lat, long} = hunt;
    const {hunt_id} = hunt;
    if (
      isNumeric(lat) &&
      isNumeric(long) &&
      Number(lat) !== 0 &&
      Number(long) !== 0 &&
      lat !== null &&
      long !== null &&
      !!hunt_id
    ) {
      unClusteredPoints.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [1 * long, 1 * lat],
        },
        properties: {
          ...hunt,
        },
      });
    }
  });

  return (
    <HomeLandingMap
      huntsList={huntsList}
      unClusteredPoints={unClusteredPoints}
      initialRegion={initialRegion}
      isModal={isModal}
    />
  );
};

const HomeLandingMap = ({
  isModal = false,
  huntsList = [],
  unClusteredPoints = [],
  initialRegion = {
    latitude: 37.78825,
    longitude: -122.4324,
    latitudeDelta: 1,
    longitudeDelta: 1,
  },
}: {
  isModal?: boolean;
  huntsList?: THunt[];
  unClusteredPoints: TPoint[];
  initialRegion: {
    latitude: number;
    longitude: number;
    latitudeDelta: number;
    longitudeDelta: number;
  };
}) => {
  const [isZoomed, setIsZoomed] = useState<boolean>(true);
  console.log('HomeLandingMap render', {isZoomed});

  // const latitude = useTypedSelector(
  //   (state) => state.location?.region?.latitude,
  // );
  // const longitude = useTypedSelector(
  //   (state) => state.location?.region?.longitude,
  // );

  const {width, height} = useWindowDimensionsSafe();

  const MAP_DIMENSIONS = {width, height: isModal ? height : 200};

  // console.log('region values', {latitude, longitude});

  const [region, setRegion] = useState(initialRegion);

  const mapRef = useRef<MapView | null>(null);

  const DEFAULT_OPTIONS = {};
  let pointsRendered = 0;

  // const huntsListWeb = useTypedSelector((state) =>
  //   Platform.OS == 'web' ? state.hunts.huntsList : {} || {},
  // );

  return (
    <View
      testID="HomeLandingMap"
      style={{
        marginTop: 0,
        height: isModal ? '100%' : 200,
        width: '100%',
        position: 'relative',
      }}>
      {!!initialRegion && !!Object.keys(initialRegion).length && <MapView
        style={{flex: 1}}
        ref={mapRef}
        customMapStyle={constants?.customMapStyle}
        key={1}
        showsUserLocation
        legalLabelInsets={{bottom: -20, left: -20, right: -20, top: -20}}
        onRegionChangeComplete={(region) => {
          console.log('region change');
          setRegion(region);
          const zoomMinimum = isModal ? 0.3 : 0.1;
          const newIsZoomed = region.latitudeDelta < zoomMinimum;

          if (newIsZoomed !== isZoomed) {
            setIsZoomed(newIsZoomed);
          }
        }}
        initialRegion={initialRegion || {
          latitude: 37.78825,
          longitude: -122.4324,
          latitudeDelta: 1,
          longitudeDelta: 1,
        }}>
        {Platform.OS != 'web' && region && (
          <Clusterer
            data={unClusteredPoints}
            region={region || {
              latitude: 0,
              longitude: 0,
              latitudeDelta: 1,
              longitudeDelta: 1,
            }}
            options={DEFAULT_OPTIONS}
            mapDimensions={MAP_DIMENSIONS}
            renderItem={(item) => {
              const key = `${isZoomed} ${JSON.stringify(item)}`;
              const lat = item.geometry.coordinates[1];
              const long = item.geometry.coordinates[0];
              pointsRendered++;
              console.log({pointsRendered});
              if (item.properties?.cluster_id) {
                const handlePress = async () => {
                  const newRegion =
                    item.properties?.getClusterExpansionRegion();
                  newRegion.latitudeDelta = newRegion.latitudeDelta * 0.9;
                  newRegion.longitudeDelta = newRegion.longitudeDelta * 0.9;
                  mapRef.current?.animateToRegion(newRegion);
                };

                return (
                  <Marker
                    onPress={handlePress}
                    coordinate={{latitude: (1 * lat) || 0, longitude: (1 * long) || 0}}
                    key={`cluster-${item.properties?.cluster_id}`}>
                    <View
                      style={{
                        minWidth: 30,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: 'green',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Text
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                        }}>
                        {item.properties?.point_count}
                      </Text>
                    </View>
                  </Marker>
                );
              }
              return (
                <LocalMarker
                  key={key}
                  coordinate={{latitude: (1 * lat) || 0, longitude: (1 * long) || 0}}
                  mapRef={mapRef}
                  isZoomed={isZoomed}
                  hunt={item.properties as THunt}
                />
              );
            }}
          />
        )}
        {Platform.OS == 'web' &&
          // @ts-ignore
          huntsList && huntsList?.map((hunt, index) => {
            const {lat, long, hunt_id} = hunt;
            // console.log('huntMarker', {lat, long});
            return (
              <Marker
                key={(lat ?? 0) + (long ?? 0) + hunt_id + index + ''}
                coordinate={{latitude: 1 * (lat ?? 0), longitude: 1 * (long ?? 0)}}
                onPress={() => {
                  dispatchAction(setCurrentHunt(hunt));
                  navigationRef.navigate(
                    'LandingPageScavengerHuntInfoModal',
                    null,
                    'HomeLandingMap',
                  );
                  logCustomAnalyticsEvent('Scavenger_hunt_view', hunt);
                }}
              />
            );
          })}
      </MapView>}
      <ExpandFullscreenButton
        isFullscreen={isModal}
        minimizedSize={40}
        handlePress={() => {
          isModal
            ? navigationRef.goBack('HomeLandingMapModal')
            : navigationRef.navigate(
                'HomeLandingMapModal',
                null,
                'SimpleStackNavigator',
              );
        }}
      />

      <View style={{position: 'absolute', bottom: 0, left: 10}}></View>
    </View>
  );
};

const LocalMarker: React.FC<{
  coordinate: {latitude: number; longitude: number};
  mapRef: React.MutableRefObject<MapView | null>;
  isZoomed: boolean;
  hunt: THunt;
}> = ({coordinate, mapRef, isZoomed = false, hunt}) => {
  const {latitude, longitude} = coordinate;

  const location_photo = !!hunt?.huntMediumPhotoURL
    ? {
        uri: cloudFlareOptimizeImage(
          hunt?.huntPhotoURL?.replace?.('./', 'https://photos.letsroam.com/'),
        ),
      }
    : fallbackImg;

  if (!isNumeric(coordinate.latitude) || !isNumeric(coordinate.longitude)) {
    return <></>;
  }

  return useMemo(() => {
    return (
      <Marker
        coordinate={coordinate || {
          latitude: 0,
          longitude: 0
        }}
        onPress={() => {
          if (!isZoomed) {
            return mapRef.current?.animateToRegion({
              latitude: Number(latitude || 1),
              longitude: Number(longitude || 1),
              latitudeDelta: 0.1,
              longitudeDelta: 0.1,
            });
          } else {
            dispatchAction(setCurrentHunt(hunt));
            navigationRef.navigate(
              'LandingPageScavengerHuntInfoModal',
              null,
              'HomeLandingMap',
            );
            logCustomAnalyticsEvent('Scavenger_hunt_view', hunt);
          }
        }}>
        {!!isZoomed && (
          <View
            style={{
              display: 'flex',
              position: 'relative',
              alignItems: 'center',
              flexDirection: 'row',
              backgroundColor: 'white',
              padding: 4,
              borderRadius: 10,
              shadowOffset: {width: 0, height: 2},
              shadowOpacity: 0.2,
              shadowRadius: 3,
              elevation: 1,
            }}>
            <ExpoImage
              source={location_photo}
              style={{height: 20, width: 20, borderRadius: 10}}></ExpoImage>
            <View>
              <Text style={{marginLeft: 10, fontSize: 10, maxWidth: 100}}>
                {hunt?.name}
              </Text>
              <Text style={{marginLeft: 10, fontSize: 8, maxWidth: 100}}>
                {hunt?.hunt_type == 'barHunt'
                  ? 'BarHunt'
                  : hunt?.hunt_type == 'artWalk'
                  ? 'Art Walk'
                  : hunt?.hunt_type == 'audioTour'
                  ? 'Audio Tour'
                  : 'Scavenger Hunt'}
              </Text>
              <SimpleStarRating size={10} rating={hunt.star_rating || 4.0} />
            </View>
          </View>
        )}
      </Marker>
    );
  }, [isZoomed, coordinate, hunt]);
};

export default HomeLandingMapWrapper;

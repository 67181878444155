/* eslint-disable @typescript-eslint/no-explicit-any */
import constants from 'src/constants';
import React, {useCallback} from 'react';
import {Image, TouchableOpacity, View, Text, FlatList} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import {BodyText} from 'src/Modules/CommonView';

import {setCurrentHunt} from 'src/Redux/reducers/hunts.reducer';
import {dispatchAction, parseHtmlEntities} from 'src/Utils/helpers';

import UserIcon from 'src/HeaderComponent/UserIcon';
import SimpleStarRating from 'src/Modules/CommonView/SimpleStarRating';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';
import {TGroupInfo} from 'src/types/TGroupInfo';
import {TLocalLocation} from 'src/types/TLocalLocation';
import {TPhoto} from 'src/types/TPhoto';
import {THunt} from 'src/types/THunt';
import ExpoImage from 'src/Utils/ExpoImage';

// dinner plate empty white
const fallbackImg = require('src/Images/AppIntroSwiper/appSwiper1.jpg');

const CarouselView: React.FC<{
  data?: Array<unknown>;
  handleSnapToMap?: ((_activeItem: null) => void) | undefined;
  renderWidth?: number;
  dataSource?: Array<string | undefined>;
  compact?: boolean;
  cardPress?: ((_item: unknown) => void) | undefined;
  showStars?: boolean;
  carouselIndex?: number;
  type?: string;
  minHeight?: number;
  imgHeight?: number;
}> = ({
  data = [],
  handleSnapToMap,
  renderWidth = 240,
  dataSource,
  compact = false,
  cardPress,
  carouselIndex = 0,
  type = 'offer_card',
  minHeight,
  imgHeight = 115,
}) => {
  console.log('CarouselView render', {type});
  const {width} = useWindowDimensionsSafe();

  const calculateFormatWalkingTime = (distanceMiles: number | null) => {
    if (!distanceMiles || isNaN(distanceMiles)) {
      return null;
    }
    if (distanceMiles < 2) {
      // based on avg of 10 minutes to walk 0.6 miles
      const walkingTime = Math?.floor((distanceMiles / 0.6) * 10);
      return `${walkingTime} min walk`;
    } else {
      return `${Math.round(distanceMiles * 10) / 10} miles away`;
    }
  };

  const _renderCustomCard = ({item, index}: {item: any; index: number}) => {
    const customCard = dataSource?.[item] || (item?.name ? item : {}) || {};
    const cardHeight = 300;
    console.log('the item is ', {customCard});

    return (
      <CarouselCard
        imgSource={customCard?.image || fallbackImg}
        title={customCard?.name}
        description={customCard?.description}
        leftText={' '}
        testID={`customCard${index}`}
        rightText={customCard?.rightText || 'Learn More'}
        handlePress={customCard?.handlePress}
        cardWidth={renderWidth}
        cardHeight={cardHeight}
        wrap={false}
      />
    );
  };

  const _renderGroupCard = ({item}: {item: any}) => {
    const cardHeight = 120;

    return (
      <GroupCard
        group={item}
        handlePress={item?.handlePress}
        cardWidth={renderWidth}
        cardHeight={cardHeight}
      />
    );
  };

  const _renderImageCard = ({item, index}: {item: any; index: number}) => {
    const image = {...item} as TPhoto;
    if (!image.share_photo) {
      return <></>;
    }
    return (
      <View
        style={{
          width: width - 80,
          marginRight: 20,
          marginLeft: index ? 0 : 24,
          minHeight: 282,
          // marginVertical: 20,
          marginHorizontal: 10,
          borderRadius: 8,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Image
          source={{uri: image.share_photo}}
          style={{
            backgroundColor: constants?.color?.gray1,
            flex: 1,
            width: width - 80,
            height: 282,
            resizeMode: 'cover',
            position: 'absolute',
            borderRadius: 8,
          }}
        />
        <View
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {!!image.description && (
            <Text
              style={{
                fontSize: 15,
                color: constants.color.white,
                paddingHorizontal: 40,
                fontFamily: 'Jakarta',
                lineHeight: 24,
                textAlign: 'center',
              }}>
              {image.description}
            </Text>
          )}
        </View>
      </View>
    );
  };

  const _renderGuestBook = ({item, index}: {item: any; index: number}) => {
    const location = {...item};
    location.description = location.guide_description;

    return (
      <View
        nativeID={`guestCard${index}`}
        style={{
          backgroundColor: 'white',
          width: width - 140,
          marginRight: 20,
          minHeight: 200,
          marginVertical: 10,
          marginHorizontal: 10,
          borderRadius: 10,
          display: 'flex',
          padding: 10,
          // flexDirection: 'row',

          // padding: 10,
        }}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <UserIcon
            hideBadges
            disableSignIn
            userPhoto={item?.submitted_by_user_image}
            containerStyle={{width: 40}}
          />
          <Text
            style={{
              backgroundColor: 'white',
              color: constants?.color.orange,
              fontSize: 16,
            }}>
            {item?.submitted_by_user_name}
          </Text>
        </View>
        <Text style={{backgroundColor: 'white'}}>{item?.content}</Text>
      </View>
    );
  };

  const _renderLocalCard = ({item, index}: {item: any; index: number}) => {
    const location = {...item} as TLocalLocation;

    const cardHeight = compact ? 130 : 300;
    // console.log({location});
    const location_photo = !!location.photo
      ? {
          uri: cloudFlareOptimizeImage(
            location.photo?.replace?.('./', 'https://photos.letsroam.com/'),
          ),
        }
      : fallbackImg;

    const description = parseHtmlEntities(
      location.guide_description || location.guide_short_description,
    );

    return (
      <CarouselCard
        imgSource={location_photo}
        title={location.title}
        testID={`localCard-${carouselIndex}-${index}`}
        description={description}
        leftText={`${location.distanceAway} miles away`}
        handlePress={() => {
          if (typeof cardPress == 'function') {
            cardPress(item);
          }
        }}
        maxTextLength={100}
        backgroundColor={constants?.color?.white}
        cardWidth={renderWidth}
        cardHeight={cardHeight}
        total_reviews={undefined}
        star_rating={undefined}
        rightText={
          <SimpleStarRating
            rating={location.rating}
            color={constants?.color?.yellow}
            size={20}
          />
        }
      />
    );
  };

  const _renderHuntCard = ({item, index}: {item: any; index: number}) => {
    const location = item as THunt;
    const cardHeight = compact ? 130 : 330;
    // console.log({location});
    const location_photo = !!location.huntMediumPhotoURL
      ? {
          uri: cloudFlareOptimizeImage(location.huntMediumPhotoURL),
        }
      : fallbackImg;

    const displayedReviews = Math.max(
      location.total_reviews,
      (location.total_reviews % 14) + 12 + (Number(location.hunt_id) % 14),
    );

    const description = parseHtmlEntities(
      location.description || location.long_description || '',
    )?.replace(/<\/?[^>]+(>|$)/g, '');

    // navigationRef.devAlert(location.name);

    const isTopRated =
      location?.total_reviews > 1 && location?.star_rating > 4.3;
    return (
      <ActivityCard
        imgSource={location_photo}
        title={location.name || `${location.city} Scavenger Hunt`}
        description={compact ? '' : description}
        testID={`huntCard-${carouselIndex}-${index}`}
        leftText={calculateFormatWalkingTime(location.distanceAway || null)}
        rightText={
          (isTopRated ? 'Top Rated!' : '') +
          (__DEV__ && location.total_reviews > 0
            ? `${Math.round(location.star_rating * 10) / 10}(${
                location.total_reviews
              })`
            : '')
        }
        handlePress={() => {
          if (cardPress) {
            cardPress(location);
          }
          dispatchAction(setCurrentHunt(location));
          navigationRef.navigate(
            'LandingPageScavengerHuntInfoModal',
            null,
            'CarouselView CardPress',
          );
          logCustomAnalyticsEvent('Scavenger_hunt_view', location);
        }}
        cardWidth={240}
        cardHeight={cardHeight}
        imgHeight={imgHeight}
        total_reviews={displayedReviews}
        star_rating={location.star_rating}
        showReviews={true}
      />
    );
  };

  const useRenderFunction: {
    [key: string]: (_props: any) => JSX.Element;
  } = {
    // map_card: _renderMapCarouselCard,
    local_card: _renderLocalCard,
    guest_book: _renderGuestBook,
    // location_card: _renderLocationCard,
    group_card: _renderGroupCard,
    custom_card: _renderCustomCard,
    hunt_card: _renderHuntCard,
    image_card: _renderImageCard,
    // featured_card: _renderFeaturedCard,
    // offer_card: _renderOfferCard,
  };

  const reducedData = [...(data || [])];

  // console.log({data});
  const onSnapTo = useCallback(({viewableItems}: {viewableItems: any[]}) => {
    if (viewableItems?.length > 0) {
      const {item: activeItem, index} = viewableItems[0];
      console.log('I snapped to', index || '0');
      handleSnapToMap && handleSnapToMap(activeItem);
    }
  }, []);

  return (
    <View
      style={{
        width,
        minHeight,
      }}>
      {!!data && (
        <FlatList
          horizontal
          keyExtractor={(item: any) =>
            item?.UUID ||
            item?.hunt_id ||
            item?.id ||
            item?.name ||
            item?.description
          }
          style={{minHeight}}
          extraData={data}
          initialNumToRender={2}
          getItemLayout={(data, index) => ({
            length: 260,
            offset: 260 * index,
            index,
          })}
          snapToAlignment="start"
          snapToStart
          snapToInterval={260}
          // decelerationRate={0.8}
          decelerationRate={0.9}
          windowSize={3}
          data={reducedData}
          renderItem={useRenderFunction[type]}
          removeClippedSubviews={type == 'hunt_card'}
          onViewableItemsChanged={onSnapTo}
        />
      )}
    </View>
  );
};

const GroupCard: React.FC<{
  group: TGroupInfo;
  handlePress: (() => void) | undefined;
  cardWidth?: number;
  cardHeight?: number;
}> = ({group, handlePress, cardWidth = 270, cardHeight = 200}) => {
  return (
    <TouchableOpacity
      style={{
        backgroundColor: constants?.color?.white,
        width: cardWidth,
        minHeight: cardHeight,
        marginVertical: 10,
        marginHorizontal: 10,
        borderRadius: 10,
      }}
      activeOpacity={0.8}
      onPress={handlePress}
      disabled={!handlePress}>
      <Image
        source={group?.huntPhoto ? {uri: group?.huntPhoto} : fallbackImg}
        style={{
          backgroundColor: constants?.color?.gray1,
          width: cardWidth,
          height: cardHeight,
          resizeMode: 'cover',
          position: 'absolute',
          borderRadius: 10,
        }}
      />

      <View
        style={{
          flex: 1,
          borderRadius: 10,
          backgroundColor: constants?.color?.black_70,
          justifyContent: 'flex-end',
          position: 'relative',
        }}>
        {!!group?.createdByName && (
          <BodyText
            text={`Join ${group?.createdByName}'s ${group?.huntCity} Scavenger Hunt`}
            textStyle={{
              marginVertical: 5,
              fontSize: 20,
              color: constants?.color?.gray2,
            }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

interface CustomCardProps {
  imgSource: {uri: string};
  title: string;
  description: string;
  leftText?: string | null;
  testID?: string;
  rightText?: string | JSX.Element | null;
  handlePress: Function;
  cardWidth: number;
  cardHeight?: number;
  imgHeight?: number;
  wrap?: boolean;
  star_rating?: number;
  total_reviews?: number;
  showReviews?: boolean;
  backgroundColor?: string;
  maxTextLength?: number;
}

export const CarouselCard: React.FC<CustomCardProps> = ({
  imgSource,
  title = '',
  testID,
  description = '',
  leftText = '',
  rightText = '',
  handlePress,
  cardWidth,
  cardHeight = 240,
  total_reviews,
  showReviews = false,
  wrap = false,
  backgroundColor = constants?.color?.white,
}) => {
  const {width} = useWindowDimensionsSafe();
  console.log('CarouselCard,' + title + ',' + imgSource?.uri);

  return (
    <TouchableOpacity
      style={{
        backgroundColor,
        width: (wrap ? width - 20 : cardWidth) || width - 20,
        minHeight: wrap ? undefined : cardHeight,
        marginVertical: 10,
        marginHorizontal: 10,
        borderRadius: 10,
      }}
      activeOpacity={0.8}
      // @ts-ignore
      onPress={handlePress}
      testID={testID}
      disabled={!handlePress}>
      <View
        style={{
          flex: 1,
          ...(wrap
            ? {
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                backgroundColor: 'white',
                borderRadius: 10,
              }
            : {}),
        }}>
        <Image
          source={imgSource}
          style={{
            backgroundColor: constants?.color?.gray1,
            width: wrap ? 120 : Number(cardWidth) - 20,
            marginTop: 10,
            marginHorizontal: 10,
            marginBottom: wrap ? 10 : 0,
            height: 140,
            resizeMode: 'cover',
            position: 'relative',
          }}
        />

        <View
          style={{
            position: 'relative',
            width: wrap ? width - 180 : cardWidth,
          }}>
          {!!title && (
            <BodyText
              text={title}
              textStyle={{
                marginVertical: 0,
                color: constants?.color?.orange,
                fontSize: 14,
                fontWeight: '700',
              }}
            />
          )}

          {!!showReviews && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginLeft: 14,
                marginTop: 5,
                marginBottom: -5,
              }}>
              <SimpleStarRating />
              <Text
                style={{
                  fontSize: 10,
                  color: constants?.color?.gray3,
                  fontFamily: constants?.font.gothic,
                  fontWeight: 'bold',
                  marginLeft: 5,
                }}>
                ({total_reviews})
              </Text>
            </View>
          )}
          {!!description && (
            <BodyText
              text={
                description?.length > 160
                  ? `${description.substring(0, 110)}...`
                  : description
              }
              textStyle={{
                marginVertical: 5,
                fontSize: 14,
              }}
            />
          )}
        </View>
      </View>
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        {!!leftText && (
          <BodyText
            text={leftText}
            textStyle={{
              marginVertical: 5,
              fontSize: 14,
              color: constants?.color?.gray2,
            }}
          />
        )}
        {!!rightText && (
          <BodyText
            text={rightText}
            textStyle={{
              marginVertical: 5,
              fontSize: 14,
              color: constants?.color?.gray2,
            }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export const ActivityCard: React.FC<CustomCardProps> = ({
  imgSource,
  title = '',
  testID,
  description = '',
  leftText = '',
  rightText = '',
  handlePress,
  cardWidth,
  cardHeight = 400,
  imgHeight = 115,
  star_rating,
  total_reviews,
  showReviews = false,
  wrap = false,
  maxTextLength = 125,
}) => {
  const {width} = useWindowDimensionsSafe();
  console.log('ActivityCard,' + title + ',' + imgSource?.uri);

  return (
    <TouchableOpacity
      style={{
        backgroundColor: constants?.color?.white,
        width: wrap ? width - 20 : cardWidth,
        minHeight: wrap ? undefined : cardHeight,
        marginVertical: 16,
        marginHorizontal: 10,
        borderRadius: 8,
        shadowColor: '#000',
        shadowOffset: {width: 0, height: 1},
        shadowOpacity: 0.1,
        shadowRadius: 3,
      }}
      activeOpacity={0.8}
      // @ts-ignore
      onPress={handlePress || undefined}
      testID={testID}
      disabled={!handlePress}>
      <ExpoImage
        source={imgSource || fallbackImg}
        style={{
          backgroundColor: constants?.color?.gray1,
          width: '100%',
          height: imgHeight,
          resizeMode: 'cover',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        }}
      />
      <View
        style={{
          flex: 1,
          ...(wrap
            ? {
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                backgroundColor: 'white',
                borderRadius: 8,
              }
            : {}),
        }}>
        <View
          style={
            {
              // position: 'relative',
              // width: wrap ? width - 180 : cardWidth,
            }
          }>
          {!!title && (
            <BodyText
              text={title}
              textStyle={{
                marginTop: 20,
                marginBottom: 0,
                color: '#000',
                fontSize: 14,
                fontFamily: 'JakartaBold',
                fontWeight: '700',
              }}
            />
          )}

          {!!description && (
            <BodyText
              text={
                description?.length > maxTextLength
                  ? `${description.substring(0, maxTextLength - 15)}...`
                  : description
              }
              textStyle={{
                marginTop: 8,
                fontSize: 12,
                fontFamily: 'Jakarta',
                color: '#000',
                marginBottom: 8,
              }}
            />
          )}
          {!!showReviews && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginLeft: 14,
                marginTop: 0,
                marginBottom: -5,
              }}>
              <SimpleStarRating
                rating={Math?.max(Number(star_rating), 3.8)}
                color={constants?.color?.yellow}
                size={20}
              />
              <Text
                style={{
                  fontSize: 14,
                  color: '#000',
                  fontFamily: 'Jakarta',
                  marginLeft: 5,
                }}>
                ({total_reviews})
              </Text>
            </View>
          )}
        </View>
      </View>
      <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
        {!!leftText && (
          <BodyText
            text={leftText}
            textStyle={{
              marginVertical: 5,
              fontSize: 12,
              color: constants?.color?.gray2,
            }}
          />
        )}
        {!!rightText && (
          <BodyText
            text={rightText}
            textStyle={{
              marginVertical: 5,
              fontSize: 14,
              color: constants?.color?.gray2,
            }}
          />
        )}
      </View>
    </TouchableOpacity>
  );
};

export default CarouselView;

import useTypedSelector from 'src/Hooks/useTypedSelector';

// @eslint-disable-next-line
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
import * as Updates from 'expo-updates';

import {Platform} from 'react-native';

import React, {useEffect} from 'react';
import {getDatabase} from 'src/Utils/db';
import {setVersionInfo} from 'src/Redux/reducers/app_info.reducer';

import {SafeAreaProvider} from 'react-native-safe-area-context';

// main navigation stack
import RootNavigationStack from 'src/Nav/RootNavigationStack';
// redux

import LoadingIndicator from 'src/Modules/CommonView/LoadingIndicator';
import GuardianWrapper from 'src/Modules/GuardianAngel/GuardianWrapper';

//////// navigation && notifications handler component
import {dispatchAction} from 'src/Utils/helpers';
import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {
  deleteFirebaseListener,
  saveFirebaseListener,
} from 'src/Redux/reducers/current_screen.reducer';

// import {DevSettings} from 'react-native';

import * as Device from 'expo-device';
import ControllersWrapper from 'src/Controllers/ControllersWrapper';
import UpgradeView from 'src/UpgradeView';
import {checkIfLocalServerAvailable} from 'src/Utils/helpers';

import * as ExpoApp from 'expo-application';

import ErrorHeaderWarning from 'src/HeaderComponent/HeaderErrorWarning';
import getAppType from './Utils/getAppType';

let LaunchArguments: {value: () => Record<string, string | number>};
if (Platform.OS !== 'web') {
  const ReactNativeLaunchArguments = require('react-native-launch-arguments');
  LaunchArguments = ReactNativeLaunchArguments?.LaunchArguments;
} else {
  LaunchArguments = {
    value: () => ({}),
  };
}

// importing detox
let isDetoxSync: () => boolean;
if (Platform.OS !== 'web') {
  const detoxModule = require('react-native-is-detox');
  isDetoxSync = detoxModule.isDetoxSync;
} else {
  isDetoxSync = () => false;
}

// Props passed in from use copilot
const App: React.FC = () => {
  console.log('App.js is rendering');

  const status = useTypedSelector((state) => state.app_info?.status);
  const expoUpdateAvailable = useTypedSelector((state) => {
    // if (typeof window !== 'undefined') {
    //   (window as any).state = state;
    // }
    return state.app_info?.expoUpdateAvailable;
  });

  useEffect(() => {
    // componentDidMount
    if (!__DEV__) {
      return console.log('not dev');
    }
    checkIfLocalServerAvailable();
  }, []);

  // have to use old method
  // dont use other
  useEffect(() => {
    let versionNumber = ExpoApp.nativeApplicationVersion || 'error';

    versionNumber = versionNumber?.replace(/\./gi, '_');
    let featurePath = `versionInfo/${Platform.OS}/${versionNumber}`;

    try {
      const launchArgs = LaunchArguments?.value?.() || {};
      console.log({launchArgs});

      if (getAppType() == 'aa') {
        featurePath += '/detoxPlayTestTest';
      }

      if (launchArgs?.useAlternateVersionInfoPath && isDetoxSync()) {
        featurePath += '/detoxPlayTestTest';
      }
    } catch (pathEror) {
      console.error({pathEror});
    }

    console.log({versionNumber});

    const listenerId = Math.round(Math?.random?.() * 1000000000);

    const dataRef = getDatabase().ref(featurePath);
    const onChange = dataRef.on('value', async (snapshot) => {
      const versionInfo = (await snapshot?.val?.()) || {};

      console.log('useFirebaseData newData', {versionInfo, featurePath});
      const parsedVersionInfo = {
        playTestState:
          (!!versionInfo?.playTest || !versionInfo) && Platform.OS == 'ios',
        versionNumber,
        showOnlyExplorerPass: versionInfo?.showOnlyExplorerPass || false,
        status: versionInfo?.status || false,
        hideFacebook: versionInfo?.hideFacebook || false,
        hideGoogle: versionInfo?.hideGoogle || false,
        hideText: versionInfo?.hideText || false,
        hideEmail: versionInfo?.hideEmail || false,
      };
      // Alert.alert('version info', JSON.stringify(parsedVersionInfo));
      console.log('the new received version info is ', {parsedVersionInfo});
      dispatchAction(setVersionInfo(parsedVersionInfo));
      mmkvStorage.set('versionInfo', JSON.stringify(parsedVersionInfo));
    });
    dispatchAction(saveFirebaseListener({[listenerId]: featurePath}));
    return () => {
      dataRef?.off?.('value', onChange);
      dispatchAction(deleteFirebaseListener(listenerId));
    };
  });

  // return <View />;
  if (status == 'upgrade' || expoUpdateAvailable) {
    return <UpgradeView />;
  } else {
    console.log('not in playtest');
    return (
      <SafeAreaProvider>
        <ErrorHeaderWarning />
        <RootNavigationStack />
        <LoadingIndicator />
        <ControllersWrapper />
        {getAppType() == 'lr' && <GuardianWrapper />}
      </SafeAreaProvider>
    );
  }
};

export default App;

import constants from 'src/constants';
import React, {Fragment, useState} from 'react';
import {Text, TouchableOpacity, View, ViewStyle} from 'react-native';

import Icon from 'react-native-vector-icons/FontAwesome';

interface Button {
  name: string;
  onPress: () => void;
  title?: string;
}

interface ButtonMenuProps {
  buttons?: Button[];
  style?: ViewStyle;
}

const ButtonMenu: React.FC<ButtonMenuProps> = ({buttons = [], style = {}}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <View
        style={{
          position: 'absolute',
          right: 10,
          bottom: 10,
          zIndex: 10000,
          ...style,
        }}>
        {!!isOpen &&
          buttons?.map?.((button, index) => {
            if (!button) {
              return;
            }
            const name = button?.name || '';
            const onPress = button?.onPress || (() => {});
            const title = button?.title || '';

            return (
              <ButtonIcon
                key={index}
                title={title}
                name={name}
                onPress={() => {
                  setIsOpen(!isOpen);
                  onPress();
                }}
                style={{marginBottom: 15}}
              />
            );
          })}
        <ButtonIcon
          name={!isOpen ? 'plus' : 'close'}
          onPress={() => {
            setIsOpen(!isOpen);
          }}
        />
      </View>
      {!!isOpen && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,.7)',
            zIndex: 100,
          }}></View>
      )}
    </Fragment>
  );
};

interface ButtonIconProps {
  onPress: () => void;
  name: string;
  color?: string;
  backgroundColor?: string;
  style?: ViewStyle;
  title?: string;
  buttonTitle?: string;
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({
  onPress,
  name,
  color = constants?.color?.white,
  backgroundColor = constants?.color?.orange,
  style = {},
  title,
  buttonTitle,
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        ...style,
      }}>
      {!!title && (
        <Text style={{marginRight: 10, fontSize: 15, color: 'white'}}>
          {title}
        </Text>
      )}
      <View
        style={{
          backgroundColor: backgroundColor,
          padding: 10,
          paddingHorizontal: 12.5,
          borderRadius: 30,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
        <Icon size={27} name={name} color={color} />
        {!!buttonTitle && (
          <Text
            style={{
              marginRight: 10,
              fontSize: 18,
              paddingLeft: 10,
              color: 'white',
            }}>
            {buttonTitle}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default ButtonMenu;

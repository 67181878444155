import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';

import {Alert, Platform, View} from 'react-native';

import {setCurrentGroupId} from 'src/Redux/reducers/user_info.reducer';
import {apiTrackUserTransaction} from 'src/Utils/apiCalls';

import {setDataAtPath, updateDataAtPath} from 'src/Utils/fireUtils';

import UserProfileIcon from 'src/Modules/UserProfileIcon';

import {getDataAtPath} from 'src/Utils/fireUtils';

import {useExplorerPass} from 'src/Utils/explorerPassHelper';
import {useUserId} from 'src/Hooks/reduxHooks';

import ChallengesHeader from 'src/Modules/CommonGame/ChallengesHeader';
import {ContinueButton} from 'src/Modules/CommonView/Buttons';
import {navigationRef} from 'src/Nav/navigationHelpers';

import {TTickets, isTTickets} from 'src/types/TUser';
import {TGroupPlayer} from 'src/types/TGroupInfo';
import {dispatchAction} from 'src/Utils/helpers';

const GameLobbyPlayers: React.FC<{isModal: boolean}> = ({isModal = false}) => {
  const userId = useUserId();
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const pass = useExplorerPass();

  const theme = useTypedSelector((state) => state.group?.info?.theme);
  const groupId = useTypedSelector((state) => state.group?.info?.groupId);
  const players =
    useTypedSelector(
      (state) => state.group?.info?.players,
      deepEqualityCheck,
    ) || {};
  const createdBy = useTypedSelector((state) => state.group?.info?.createdBy);
  const eventId = useTypedSelector((state) => state.group?.info?.eventId);
  const passPlayers = useTypedSelector((state) => {
    if (typeof state === 'number') {
      return state;
    } else if (typeof state === 'string') {
      const parsedValue = parseInt(state, 10);
      return isNaN(parsedValue) ? 0 : parsedValue;
    } else {
      return 0;
    }
  });

  const handleGiveTicketsBack = async (
    refundedTickets = 1,
    deletedUserId: string,
    payingUserId: string,
  ) => {
    console.log('handleGiveTicketsBack', {
      refundedTickets,
      deletedUserId,
      payingUserId,
    });

    const payingUserTicketsData = await getDataAtPath(
      `users/${payingUserId}/tickets`,
    );
    if (isTTickets(payingUserTicketsData)) {
      const payingUserTickets: TTickets = payingUserTicketsData;
      const themedTickets =
        payingUserTickets && theme
          ? parseInt('' + payingUserTickets?.[theme])
          : 0;
      setDataAtPath(
        `users/${payingUserId}/tickets/${theme}`,
        themedTickets + refundedTickets,
      );

      apiTrackUserTransaction({
        type: 'refund_leave_group',
        user_id: payingUserId,
        usage_user_id: deletedUserId,
        tickets: refundedTickets,
        theme,
        group_id: groupId,
      });
    }
  };

  const handleRemovePlayer = (player: TGroupPlayer) => {
    console.log('handleRemovePlayer', {player});

    const isPassPlayer = !!passPlayers;

    const paid =
      !eventId &&
      !isPassPlayer &&
      !pass?.passType &&
      (Platform.OS == 'android' || playTestState == false);

    if (
      player.parentUserId === userId &&
      player.parentUserId === player.userId
    ) {
      Alert.alert(
        'Do you want to exit?',
        paid
          ? 'You can use these tickets to re-register for the same theme or go to the foxshop to exchange them.'
          : 'You will be exited to the lobby so you can re-register.',
        [
          {
            text: 'Cancel',
            onPress: () => console.log('Cancel Pressed'),
            style: 'cancel',
          },
          {
            text: 'Delete',
            onPress: () => {
              players &&
                Object.values(players)?.map?.((groupPlayer) => {
                  setDataAtPath(`users/${player.userId}/currentHuntInfo`, null);
                  dispatchAction(setCurrentGroupId(null));
                  if (groupPlayer.userId === createdBy) {
                    updateDataAtPath(
                      `scavengerhunt/groups/${groupId}/createdBy`,
                      null,
                    );
                  }
                  if (groupPlayer.parentUserId == player.parentUserId) {
                    setDataAtPath(
                      `scavengerhunt/groups/${groupId}/players/${
                        groupPlayer.userId || 'error'
                      }`,
                      null,
                    );
                  }
                  if (paid) {
                    if (groupPlayer.userId && groupPlayer.payingUserId) {
                      handleGiveTicketsBack(
                        1,
                        groupPlayer.userId,
                        groupPlayer.payingUserId,
                      );
                    } else {
                      Alert.alert(
                        'There was an error processing your request. Please contact support.',
                      );
                    }
                  }
                });
              navigationRef.navigate(
                'LandingPageMain',
                null,
                'GameLobbyPlayers',
              );
              Alert.alert(
                paid
                  ? `You have left the event. You can use these tickets to re-register for the same theme or go to the foxshop to exchange them.`
                  : 'You have left the event and can now re-register.',
              );
            },
          },
        ],
        {cancelable: false},
      );
    } else if (player.parentUserId === userId || createdBy == userId) {
      Alert.alert(
        'Do you want to delete this user?',
        paid
          ? 'You get back one ticket back for this deleted user?.'
          : 'This player will be removed from your device.',
        [
          {
            text: 'Cancel',
            onPress: () => console.log('Cancel Pressed'),
            style: 'cancel',
          },
          {
            text: 'Delete',
            onPress: () => {
              setDataAtPath(`users/${player.userId}/currentHuntInfo/`, null);
              setDataAtPath(
                `scavengerhunt/groups/${groupId}/players/${player.userId}`,
                null,
              );
              if (paid) {
                if (player.userId && player.payingUserId) {
                  handleGiveTicketsBack(1, player.userId, player.payingUserId);
                } else {
                  Alert.alert(
                    'There was an error processing your request. Please contact support.',
                  );
                }
              }
            },
          },
        ],
        {cancelable: false},
      );
    } else {
      console.log('no match!!!', {player, userId});
    }
  };

  return (
    <View>
      {!!isModal && (
        <ChallengesHeader
          text={'Remove Players'}
          subTitle={'Click below to remove players'}
          color={constants?.color.teal}
        />
      )}
      <View
        style={{
          alignItems: 'center',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          marginHorizontal: 20,
          marginTop: 10,
          width: 315,
        }}>
        {!!players &&
          Object.values(players || {})
            ?.reverse?.()
            ?.map?.((player, index) => {
              const iconColor =
                player.parentUserId == userId || player.userId == userId
                  ? constants?.color?.orange
                  : constants?.color?.teal;

              return (
                <View style={{margin: 5}} key={index + '_user_profile'}>
                  <UserProfileIcon
                    nameOverride={player?.firstName}
                    user={{info: player}}
                    key={'gameLobbyPlayerIcon' + player.userId}
                    color={iconColor}
                    deletable={
                      player.parentUserId == userId || createdBy == userId
                    }
                    handleSignInPress={() => {
                      console.log('pressed');
                      handleRemovePlayer(player);
                    }}
                    showName
                  />
                </View>
              );
            })}
      </View>
      {!!isModal && (
        <ContinueButton
          text={'Go Back'}
          handlePress={() => {
            navigationRef?.goBack(
              'ScavengerHunt_v2/Lobby/GameLobbyPlayers.tsx',
            );
          }}
        />
      )}
    </View>
  );
};

export default GameLobbyPlayers;

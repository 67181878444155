import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState, useEffect} from 'react';
import {
  Alert,
  Linking,
  KeyboardAvoidingView,
  TouchableOpacity,
  View,
  Platform,
  Text,
} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import {
  BodyText,
  IconButton,
  ContinueButton,
  TextEntry,
} from 'src/Modules/CommonView';
import ChallengesHeader from 'src/Modules/CommonGame/ChallengesHeader';

//database writing functions
import {updateDataAtPath} from 'src/Utils/fireUtils';
import moment from 'moment';
import {apiTrackUserTransaction} from 'src/Utils/apiCalls';
import useParams from 'src/Hooks/useParams';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {useUserId} from 'src/Hooks/reduxHooks';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';

// importing detox
let isDetoxSync: () => boolean;
if (Platform.OS !== 'web') {
  const detoxModule = require('react-native-is-detox');
  isDetoxSync = detoxModule.isDetoxSync;
} else {
  isDetoxSync = () => false;
}

import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';
import DropDown from 'src/Modules/DropDown';
import {TDropDownOption} from 'src/types/TDropDownOption';

const styles = {
  mainContainer: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 250,
  },

  formButton: {
    width: 220,
    borderRadius: 0,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    marginBottom: 15,
    borderColor: constants?.color?.gray2,
    borderWidth: 0.5,
    backgroundColor: constants?.color?.white1,
    marginHorizontal: 0,
  },
  groupSize: {
    color: constants?.color?.gray3,
    fontFamily: constants?.font.circular,
    marginVertical: 5,
    textAlign: 'center',

    height: 40,
    marginBottom: 5,
    width: 280,
  },
  bodyText: {
    marginHorizontal: 0,
  },
  text: {
    color: constants?.color?.gray3,
    fontFamily: constants?.font.circular,
    marginVertical: 5,
    textAlign: 'center',
    width: 220,
  },
};

const GrouponRedemption: React.FC = () => {
  const {width} = useWindowDimensionsSafe();
  const userId = useUserId();
  const params = useParams<'GrouponRedemption'>();
  const initialVoucherCode = String(params?.voucherCode || '');
  const initialVoucherSource = String(params?.voucherSource || 'groupon');
  const initialTicketsQuantity = params?.ticketsQuantity || 2;
  const autoFound = params?.autoFound || false;

  const location = useTypedSelector((state) => state.location);

  const [loading, setLoading] = useState<boolean>(false);
  const [voucherSource, setVoucherSource] = useState<string | null>(
    initialVoucherSource,
  );
  const [voucherCode, setVoucherCode] = useState<string>(initialVoucherCode);
  const [ticketsQuantity, setTicketsQuantity] = useState<number>(
    initialTicketsQuantity || 0,
  );
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [markRedeemed, setMarkRedeemed] = useState<boolean>(false);
  const [canOpenGrouponApp, setCanOpenGrouponApp] = useState<boolean>(true);

  const checkCanOpenGrouponApp = () => {
    Linking?.canOpenURL('groupon://').then((supported) => {
      if (!supported) {
        setCanOpenGrouponApp(false); //can't open instagram
      } else {
        return console.log('groupon urls can be opened');
      }
    });
  };
  useEffect(() => {
    checkCanOpenGrouponApp();
  }, []);

  const closeModal = () => {
    navigationRef?.goBack('LandingPage/GrouponRedemption.tsx');
    if (success) {
      const additionalData = {
        loading,
        voucherSource,
        voucherCode,
        ticketsQuantity,
        error,
        success,
        markRedeemed,
        canOpenGrouponApp,
      };

      console.log({additionalData});
      setTimeout(() => {
        navigationRef.navigate(
          'CESModal',
          {
            title: 'Redemption Feedback',
            subTitle:
              'Thank you for redeeming your third party voucher, please let us know how it went & how we can improve!',
            type: 'r_groupon',
            additionalData,
          },
          'GrouponRedemption',
        );
      }, 500);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    if (voucherCode?.length < 4 && voucherSource !== 'groupon') {
      setError(
        'Please enter a valid voucher code. Voucher codes are between 6 and 12 characters long.',
      );

      setLoading(false);
      setTimeout(() => {
        setError('');
      }, 5000);
    } else if (
      voucherSource === 'groupon' &&
      (voucherCode?.length < 6 || voucherCode?.length > 12)
    ) {
      setLoading(false);
      navigationRef.navigate(
        'ConfirmModal',
        {
          confirmText: 'Try Again / Cancel',
          cancelText: 'Find Groupon Code',
          handleCancel: () => {
            if (canOpenGrouponApp) {
              Linking?.openURL('groupon://');
            } else {
              Linking?.openURL(
                location?.region &&
                  'latitude' in location.region &&
                  location?.region?.latitude < 0
                  ? 'https://www.groupon.com.au/mystuff'
                  : 'https://www.groupon.com/mystuff',
              );
            }
          },
          thirdOption: 'Contact Support',
          handleThird: () => {
            navigationRef.navigate(
              'SupportModal',
              {},
              'LandingPage/GrouponRedemption.tsx',
            );
          },
          confirmColor: constants?.color?.orange,
          cancelColor: constants?.color?.blue,
          titleText: 'We Could Not Find Your Voucher',
          // groupon voucher code
          titleImage: {
            uri: cloudFlareOptimizeImage(
              'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648578392_medium.png',
            ),
          },
          titleBodyText:
            'Your voucher code was not found. Voucher codes are between 6 and 12 characters long and are all numbers. The code is not your order number, but is the voucher code that appears above the bar code on your voucher, like the above image.',
        },
        'GrouponRedemption',
      );
    } else {
      // eslint-disable-next-line no-use-before-define
      applyVoucher();
    }
  };

  const voucher1 = useTypedSelector(
    (state) => state?.user?.info?.voucher1,
    deepEqualityCheck,
  );
  const tickets =
    useTypedSelector(
      (state) => state?.user?.info?.tickets,
      deepEqualityCheck,
    ) || {};
  const voucherCode1 = useTypedSelector(
    (state) => state?.user?.info?.voucherCode1,
  );
  const voucherSource1 = useTypedSelector(
    (state) => state?.user?.info?.voucherSource1,
  );
  const voucherBonus1 = useTypedSelector(
    (state) => state?.user?.info?.voucherBonus1,
  );
  const voucherCode2 = useTypedSelector(
    (state) => state?.user?.info?.voucherCode2,
  );

  const applyVoucher = () => {
    if (voucherCode === voucherCode1 || voucherCode === voucherCode2) {
      setError(
        'Redemption is only allowed once. The voucher code has already been used for this account!',
      );
      setLoading(false);
    } else if (voucherCode2) {
      setLoading(false);
      setError(
        'Only 2 third party voucher codes are allowed per account. Use another account to redeem.',
      );
    } else {
      const newTickets = {...tickets};
      newTickets['Standard'] =
        Number(newTickets['Standard'] || 0) + ticketsQuantity;

      const newData = {
        tickets: newTickets,
        voucher1: {
          voucherCode: voucherCode1 ? voucherCode1 : voucherCode,
          voucherSource:
            (voucherSource1 ? voucherSource1 : voucherSource) || null,
          voucherBonus: voucherBonus1 || false,
          redemptionDate:
            voucher1?.redemptionDate || 'N/A' || moment().format('L'),
          redemptionTime:
            voucher1?.redemptionTime || 'N/A' || Date.now() / 1000,
          tickets: ticketsQuantity,
        },
        voucher2: voucherCode1
          ? {
              voucherCode: voucherCode1 ? voucherCode : null,
              voucherSource: (voucherCode1 ? voucherSource : null) || null,
              voucherBonus: false,
              redemptionDate: voucherCode1 ? moment().format('L') : null,
              redemptionTime: voucherCode1 ? Date.now() / 1000 : null,
              coins: ticketsQuantity,
            }
          : null,
        voucherCode1: voucherCode1 ? voucherCode1 : voucherCode,
        voucherCode2: voucherCode1 ? voucherCode : null,
      };

      logCustomAnalyticsEvent('groupon_redeemed', {
        ...newData,
        userId,
      } as TJSONValue);

      console.log('GrouponRedemptionModal updating user', {newData});

      userId &&
        updateDataAtPath(`/users/${userId}`, newData)
          .then(() => {
            setLoading(false);
            setSuccess(true);
            setError('');

            apiTrackUserTransaction({
              type: 'groupon_redemption',
              user_id: userId,
              usage_user_id: userId,
              groupon_voucher_code: voucherCode,
              voucher_source: voucherSource,
            });
          })
          .catch((error) => {
            console.log({error});
            setLoading(true);
            setError(
              'An error occurred, please check your internet connection.',
            );
          });
    }
  };

  const handleMarkRedeemed = () => {
    setMarkRedeemed(true);
    setLoading(true);
    // opening is too slow on detox lol
    if (voucherSource === 'groupon' || voucherSource === 'livingsocial') {
      const isDetox = isDetoxSync();
      if (!isDetox) {
        if (canOpenGrouponApp) {
          Linking?.openURL('groupon://');
        } else {
          Linking?.openURL(
            location?.region &&
              'latitude' in location.region &&
              location?.region?.latitude < 0
              ? 'https://www.groupon.com.au/mystuff'
              : 'https://www.groupon.com/mystuff',
          );
        }
      }
    }
    setTimeout(() => {
      setLoading(false);
      navigationRef.closeModal('GrouponRedemption', 'GrouponRedemption');
      Alert.alert(
        'Your tickets have been applied. You can now use them on a scavenger hunt.',
      );
    }, 2000);
  };

  const headerText = success ? 'Almost Done' : 'Voucher Redemption';
  const headerDescription = success
    ? `Your tickets needs to be marked as redeemed on groupon or the other third party to redeem. Please click the button below to open the groupon app.`
    : autoFound
    ? 'A third party voucher has been automatically found for your account!'
    : 'Enter the information below to redeem your 3rd Party Voucher';

  const showMarkRedeemed =
    success &&
    (voucherSource === 'groupon' || voucherSource === 'livingsocial');

  const formattedTicketQuantityOptions: TDropDownOption[] = [];
  [2, 4, 6, 8, 10, 16]?.map?.((value, key) => {
    if (voucherSource == 'certifikid' && [2, 8, 16]?.includes?.(value)) {
      // do nothing for certifikid 2,8,16
    } else if (voucherSource == 'certifikid') {
      formattedTicketQuantityOptions?.push({
        value,
        key,
        label:
          value == 4
            ? '1 In-Home Hunt'
            : value != 10
            ? '3 In-Home Hunts'
            : 'Season Pass',
      });
    } else if (voucherSource == 'klook') {
      if (value == 2) {
        formattedTicketQuantityOptions?.push({
          value,
          key,
          label: `In Home Hunt`,
        });
      }
    } else if (voucherSource == 'getyourguide') {
      // only do once
      if (value == 2) {
        const values = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

        values.forEach((value) => {
          formattedTicketQuantityOptions.push({
            value: value,
            key: value,
            label: value === 1 ? '1 Ticket' : `${value} Tickets`,
          });
        });
      }
    } else {
      console.log(`pushing ${value}`);
      formattedTicketQuantityOptions?.push({
        value,
        key,
        label: `${value || ''} Tickets`,
      });
    }
  });
  console.log({formattedTicketQuantityOptions});

  const formattedSourceOptions: TDropDownOption[] = [
    'Groupon',
    'Living Social',
    'TripAdvisor',
    'GetYourGuide',
    'Expedia',
    'Certifikid',
    'EventBrite',
    'Klook',
    'Blaze Travel',
    'Scoupon/LuxEveryDay',
  ]?.map?.((label, key) => {
    const newOption: TDropDownOption = {
      value: label?.toLowerCase?.().replace(' ', ''),
      label,
      key,
    };
    return newOption;
  });

  return (
    <SafeAreaView style={{flex: 1}}>
      <TouchableOpacity style={{flex: 1}} onPress={() => closeModal()} />
      <KeyboardAvoidingView
        style={{justifyContent: 'flex-end'}}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        enabled>
        <View
          style={{
            backgroundColor: constants?.color?.white,
            borderRadius: 10,
            width,
            position: 'relative',
          }}>
          <ChallengesHeader
            text={headerText}
            color={constants?.color?.gray3}
            textStyle={{
              fontSize: 20,
              fontWeight: '700',
              fontFamily: constants?.font.circular,
            }}
          />
          <IconButton
            left
            handlePress={() => closeModal()}
            buttonStyle={{top: 15}}
            color={constants?.color?.white}
          />
          <View
            style={{
              alignItems: 'center',
              paddingHorizontal: 10,
              paddingBottom: 20,
              minHeight: 380,
            }}>
            <BodyText
              textStyle={{
                color: error ? 'red' : constants?.color?.gray3,
                fontSize: 15,
                marginBottom: 0,
                paddingLeft: 8,
                paddingRight: 8,
              }}
              center
              text={error || headerDescription}
            />
            {!!showMarkRedeemed && (
              <View style={{alignItems: 'center', flex: 1, marginTop: 20}}>
                <ContinueButton
                  text={'Go To Third Party To Mark As Redeemed'}
                  loading={loading}
                  width={320}
                  handlePress={handleMarkRedeemed}
                />
              </View>
            )}
            {!success && (
              <View style={{alignItems: 'center', flex: 1, marginTop: 20}}>
                <TextEntry
                  label={'Voucher Code:'}
                  testID={'grouponVoucherCode'}
                  // style={styles.formButton}
                  placeholder={'A7689'}
                  value={voucherCode || ''}
                  handleChange={(voucherCode) => setVoucherCode(voucherCode)}
                />
                <Text
                  style={{
                    color: constants?.color?.gray3,
                    fontFamily: constants?.font.circular,
                    marginVertical: 5,
                    textAlign: 'left',
                    width: 220,
                  }}>
                  {'Voucher Source:'}
                </Text>

                <DropDown
                  dropDownOptions={formattedSourceOptions}
                  buttonStyle={styles.formButton}
                  value={voucherSource}
                  testID={'voucherSource'}
                  setValue={(value) => {
                    console.log({value});
                    setVoucherSource(value);
                  }}
                />
                <Text
                  style={{
                    color: constants?.color?.gray3,
                    fontFamily: constants?.font.circular,
                    marginVertical: 5,
                    textAlign: 'left',
                    width: 220,
                  }}>
                  {'Voucher Size:'}
                </Text>
                <DropDown
                  dropDownOptions={formattedTicketQuantityOptions}
                  buttonStyle={styles.formButton}
                  value={ticketsQuantity}
                  testID={'voucherSize'}
                  setValue={(value) => {
                    console.log({value});
                    setTicketsQuantity(Number(value));
                  }}
                />

                <ContinueButton
                  text={'Redeem'}
                  loading={loading}
                  width={220}
                  handlePress={handleSubmit}
                />
              </View>
            )}
          </View>
        </View>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
};

export default GrouponRedemption;

import React from 'react';
import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
// import {RootState} from 'src/Redux/reducers/rootReducer';
import {getReduxState} from 'src/Utils/helpers';
// import {TUser} from 'src/types/TUser';

// type UserInfoState = RootState['user']['info'];

// type UserInfoStateKeys = keyof TUser;

// location id can come from nav params for classic hunts
// location id can also come from state for regular
export const useUserId = (): string | null => {
  const userId = useTypedSelector((state) => state?.user?.info?.userId);

  return userId || null;
};

// location id can come from nav params for classic hunts
// location id can also come from state for regular
export const useUserTicketCount = (): number => {
  const tickets = useTypedSelector(
    (state) => state?.user?.info?.tickets,
    deepEqualityCheck,
  );

  if (!tickets) {
    return 0;
  }

  let userTicketCount: number = 0;

  if (typeof tickets === 'object' && tickets !== null) {
    const ticketsObject = Object.values(tickets);
    userTicketCount = ticketsObject.reduce(
      (a: number, b: number) => a + Number(b),
      0,
    );
  }

  return userTicketCount;
};

//Non-hook version of useUserTicketCount
export const getUserTicketCount = (): number => {
  const tickets = getReduxState((state) => state?.user?.info?.tickets);

  if (!tickets) {
    return 0;
  }

  let userTicketCount: number = 0;

  if (typeof tickets === 'object' && tickets !== null) {
    const ticketsObject = Object.values(tickets);
    userTicketCount = ticketsObject.reduce(
      (a: number, b: number) => a + Number(b),
      0,
    );
  }

  return userTicketCount;
};

import {useEffect, useState} from 'react';
import {useRoute} from '@react-navigation/native';
import {TNavigationRouteParams} from 'src/types/TNavigationRouteParams';

function useParams<
  T extends keyof TNavigationRouteParams = 'ErrorRouteNotSet',
>(): TNavigationRouteParams[T] {
  const route = useRoute();
  const [params, setParams] = useState<TNavigationRouteParams[T]>(
    (route?.params || {}) as TNavigationRouteParams[T],
  );

  useEffect(() => {
    if (route?.params) {
      setParams(route.params as TNavigationRouteParams[T]);
    }
  }, [route?.params]);

  return params;
}

export default useParams;

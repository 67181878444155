import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useRef} from 'react';
import {View, Text, Alert} from 'react-native';

import {ClassicChallengeCard} from 'src/Modules/CommonView';
import {apiCheckAnyChallenge} from 'src/Utils/apiCalls';
import {BodyText} from 'src/Modules/CommonView/Text';
import {dispatchAction, ordinalSuffixOf} from 'src/Utils/helpers';
import {setLocalCompletions} from 'src/Redux/reducers/local.reducer';
import {addLocationToGuide} from 'src/Local/localFunctions';
import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {TChallenge} from 'src/types/TChallenge';
import {TLocalCompletion} from 'src/types/TLocalCompletion';

interface LocalChallengesProps {
  stopChallenges: TChallenge[];
  hasSignedGuestBook: boolean;
  signGuestBook: () => void;
  updateQueryData: (_arg1: boolean, _arg2: number) => void;
  refetch: () => void;
  challengesLeft?: number;
  handleAddChallenge: () => void;
  title: string;
}

const LocalChallenges: React.FC<LocalChallengesProps> = ({
  stopChallenges,
  hasSignedGuestBook,
  signGuestBook,
  updateQueryData,
  refetch,
  challengesLeft = 0,
  handleAddChallenge,
  title,
}) => {
  const localCompletionsList = useTypedSelector(
    (state) => state.local?.localCompletionsList,
  );

  const selectedLocation = useTypedSelector((state) => {
    const selectedLocalLocationId = state.local.selectedLocalLocationId;
    if (!selectedLocalLocationId) return null;
    const localLocations = state.local.localLocations;
    const newSelectedLocation = localLocations?.[selectedLocalLocationId];
    return newSelectedLocation;
  });

  if (!selectedLocation) {
    return <></>;
  }

  const {photo, id, post_task_info} = selectedLocation;

  const wrongGuessCount = useRef<number>(0);

  const handleSelectChallenge = async (
    challenge: TChallenge,
    completion: TLocalCompletion,
  ) => {
    let {challenge_medium} = challenge;
    const {points, task} = challenge;
    if (__DEV__ && challenge_medium == 'video') {
      challenge_medium = 'photo';
    }

    if (challenge_medium === 'photo' || challenge_medium === 'video') {
      return navigationRef.navigate(
        'PhotoVideoCamera',
        {
          onCapture: (data) => {
            console.log('photo captured, ready to score!', {data});
          },
          onScore: async (data) => {
            const checkParams: TLocalCompletion = {
              id: challenge.challenge_id || challenge.challengeId,
              challenge_id: challenge.challenge_id || challenge.challengeId,
              location_id: String(selectedLocation?.id) || null,
              photo_url: data?.downloadURL,
              share_UUID: data?.shareUUID,
              points_earned: points || 100,
              challenge_page: 'local',
              challenge_type: 'local_spot_challenge_done',
            };
            console.log({checkParams});
            const scoringResponse = await apiCheckAnyChallenge(checkParams);

            logCustomAnalyticsEvent('local_spot_challenge_done', checkParams);

            if (scoringResponse?.lr_app_challenge_completion_id) {
              const newCompletion = {
                [scoringResponse?.lr_app_challenge_completion_id]: {
                  ...checkParams,
                },
              };
              console.log({newCompletion});
              dispatchAction(setLocalCompletions(newCompletion));
            }
            updateQueryData(true, points || 200);
            refetch();
            console.log({scoringResponse});
            return scoringResponse;
          },
          onFailScore: (data) => {
            const {photo_grade, lr_app_challenge_completion_id = 0} = data;
            const feedback_title =
              photo_grade?.feedback_title || 'Close but no Cigar!!!!';
            const feedback_body =
              photo_grade?.feedback_body ||
              'We were not able to detect the required elements in the image!';

            navigationRef.navigate(
              'ConfirmModal',
              {
                handleConfirm: async (_, __, handleDone) => {
                  if (typeof handleDone == 'function') {
                    handleDone();
                  }
                  handleSelectChallenge(challenge, completion);
                },
                confirmText: 'Try Again',
                cancelText: 'Report Issue',
                handleCancel: () => {
                  navigationRef?.goBack('Local/LocalChallenges.tsx');
                  navigationRef.navigate(
                    'QuestionFeedBackModal',
                    {
                      challenge,
                      lr_app_challenge_completion_id: String(
                        lr_app_challenge_completion_id,
                      ),
                      type: 'daily_challenge',
                    },
                    'Local/LocalChallenges.tsx',
                  );
                },
                thirdOption: 'Cancel',
                handleThird: () => {
                  navigationRef?.goBack('Local/LocalChallenges.tsx');
                },
                confirmColor: constants?.color?.orange,
                cancelColor: constants?.color?.blue,
                titleText: feedback_title,
                titleImage: require('src/Images/sadTrophy.png'),
                titleBodyText: feedback_body,
              },
              'Local/LocalChallenges.tsx',
            );
          },
          onReport: () => {
            navigationRef.navigate(
              'ReportModal',
              null,
              'Local/LocalChallenges.tsx',
            );
          },
          text: task,
          shareUUID: completion?.share_UUID,
          photoUrl: completion?.photo_url,
          isVideo: challenge_medium == 'video',
        },
        'LocalChallenges',
      );
    } else if (
      challenge_medium === 'multiple_choice' ||
      challenge_medium === 'true_false' ||
      challenge_medium === 'link'
    ) {
      if (completion) {
        return Alert.alert('Challenge Already Completed');
      }
      wrongGuessCount.current = 0;
      console.log({challenge});
      const challengeOverride = {...challenge};
      if (challenge.challenge_medium) {
        challengeOverride.type = challenge.challenge_medium;
      }

      challengeOverride.question = challenge.task;
      challengeOverride.answers = [];
      if (challenge.answers) {
        challengeOverride.answers = challenge.answers;
      }

      (challengeOverride.challengeHandleDoneOverride = async (answer: {
        correct: boolean;
        option: string;
      }) => {
        const {correct, option} = answer;
        // got the multiple choice question wrong
        if (!correct) {
          wrongGuessCount.current++;
          navigationRef.navigate(
            'ConfirmModal',
            {},
            'Local/LocalChallenges.tsx',
          );
          return navigationRef.navigate(
            'ConfirmModal',
            {
              confirmText: 'Try Again',
              cancelText: 'Report Issue',
              handleCancel: (handleDone) => {
                handleDone();
                navigationRef.navigate(
                  'QuestionFeedBackModal',
                  {
                    challenge: challengeOverride,
                    type: 'local',
                    challenged_by_user: true,
                  },
                  'Local/LocalChallenges.tsx',
                );
              },
              thirdOption: 'Cancel',
              confirmColor: constants?.color?.orange,
              cancelColor: constants?.color?.blue,
              titleText: `You Guessed ${option || ''}`,
              titleImage: require('src/Images/sadTrophy.png'),
              titleBodyText: `That was the wrong answer try again! This was your ${ordinalSuffixOf(
                wrongGuessCount.current,
              )} guess.`,
            },
            'Local/LocalChallenges.tsx',
          );
        } else {
          updateQueryData(true, points || 200);
          const points_earned = Math.round(
            (1 * (points || 200)) / (wrongGuessCount.current + 1),
          );
          const checkParams = {
            challenge_id:
              challenge.challenge_id || challenge.challengeId || undefined,
            location_id: id || challenge?.locationId,
            photo_url: '',
            shareUUID: challenge.shareUUID,
            share_UUID: '',
            points_earned: points_earned,
            challenge_page: 'local',
          };
          console.log({checkParams});
          const scoringResponse = await apiCheckAnyChallenge(checkParams);
          logCustomAnalyticsEvent('local_spot_challenge_done', checkParams);
          updateQueryData(true, points || 200);
          console.log({scoringResponse});
          navigationRef?.goBack('Local/LocalChallenges.tsx');
          return navigationRef.navigate(
            'ConfirmModal',
            {
              confirmText: 'Awesome',
              cancelText: 'Report Issue',
              handleCancel: (handleDone) => {
                handleDone();
                challengeOverride &&
                  navigationRef.navigate(
                    'QuestionFeedBackModal',
                    {
                      challenge: challengeOverride,
                      type: 'local',
                      challenged_by_user: true,
                    },
                    'Local/LocalChallenges.tsx',
                  );
              },
              confirmColor: constants?.color?.orange,
              cancelColor: constants?.color?.blue,
              titleText: 'You Got It Right',
              titleImage: require('src/Images/happyTrophy.png'),
              titleBodyText:
                `Congrats you earned ${points_earned} out of ${points} points. ` +
                (!!post_task_info ? `Did you know: \n ${post_task_info}` : ''),
            },
            'Local/LocalChallenges.tsx',
          );
        }
      }),
        (challengeOverride.isLocal = true);

      return navigationRef.navigate(
        'ChallengeAttemptAndResultModal',
        {
          challengeId: challenge.challengeId,
          challengeOverride,
        },
        'Local/LocalChallenges.tsx',
      );
    }
  };

  return (
    <View>
      <BodyText
        text={'Site Challenges'}
        center
        textStyle={{fontWeight: 'bold'}}
      />
      {!challengesLeft && (
        <View>
          <BodyText
            text={
              'You have already completed all the challenges here. Earn more points by submitting new locations or adding challenges to this location.'
            }
            center
          />
          <ClassicChallengeCard
            key={`addLocation`}
            containerStyle={{}}
            title={`Add New Let's Roam Local Location`}
            text={'Create a new location for the guide!'}
            icon={'star'}
            backgroundColor={constants?.color?.teal}
            label={`500`}
            subLabel={'pts'}
            handlePress={() => {
              addLocationToGuide();
            }}
          />
          <ClassicChallengeCard
            key={`addChallenge`}
            containerStyle={{}}
            title={`Add New Challenge To ${title}`}
            text={'Create a fun new challenge for others and earn points!'}
            icon={'star'}
            backgroundColor={constants?.color?.teal}
            label={`200`}
            subLabel={'pts'}
            handlePress={() => {
              handleAddChallenge();
            }}
          />
        </View>
      )}
      <BodyText
        text={'Compete these challenges to earn awesome points and prizes!'}
        center
      />
      {stopChallenges?.map?.((challenge, index) => {
        const {challenge_medium, name, points} = challenge;
        const completions = localCompletionsList.filter(
          (completion) =>
            completion.challenge_id == challenge.challenge_id &&
            completion.points_earned > 0,
        );
        console.log('rendering stopChallenges', {challenge});
        const completion = completions?.[0];
        const {points_earned} = completion || {};
        const isComplete = !!completion;

        return (
          <ClassicChallengeCard
            key={`cc_item_${challenge.challengeId}${index}`}
            containerStyle={{}}
            title={
              isComplete ? (
                <Text>
                  {challenge.name || `Challenge`}
                  <Text style={{fontWeight: '400'}}>{` - Complete`}</Text>
                </Text>
              ) : (
                name || `Challenge`
              )
            }
            text={challenge.task}
            icon={
              challenge_medium === 'photo'
                ? 'camera'
                : challenge_medium === 'video'
                ? 'video-camera'
                : challenge_medium === 'true_false'
                ? 'university'
                : challenge_medium === 'multiple_choice'
                ? 'university'
                : ''
            }
            backgroundColor={
              challenge_medium === 'photo'
                ? constants?.color?.orange
                : challenge_medium === 'video'
                ? constants?.color?.blue
                : constants?.color?.teal
            }
            backgroundUrl={photo}
            label={
              points_earned > 0 ? `${points_earned}/${points}` : `${points} pts`
            }
            subLabel={points_earned ? 'pts earned' : ''}
            completed={isComplete}
            completedIcon={points_earned > 0 ? 'check' : 'close'}
            completedIconColor={
              points_earned > 0
                ? constants?.color?.brightgreen
                : constants?.color?.red
            }
            handlePress={() => {
              handleSelectChallenge(challenge, completion);
            }}
          />
        );
      })}
      <ClassicChallengeCard
        key={`guestBook`}
        containerStyle={{}}
        title={
          hasSignedGuestBook ? (
            <Text>
              {'Sign Guest Book'}
              <Text style={{fontWeight: '400'}}>{` - Complete`}</Text>
            </Text>
          ) : (
            `Challenge`
          )
        }
        text={'Sign Guest Book'}
        icon={'book'}
        backgroundColor={constants?.color?.teal}
        label={hasSignedGuestBook ? `100` : `100`}
        subLabel={hasSignedGuestBook ? 'pts earned' : 'pts'}
        completed={hasSignedGuestBook}
        completedIcon={hasSignedGuestBook ? 'check' : 'close'}
        completedIconColor={
          hasSignedGuestBook
            ? constants?.color?.brightgreen
            : constants?.color?.red
        }
        handlePress={() => {
          signGuestBook();
        }}
      />
    </View>
  );
};

export default LocalChallenges;

// this reducer stores the corporate event info
// it also stores the groups
// firebase data is stored here: https://console.firebase.google.com/u/0/project/barhuntv2/database/barhuntv2/data/customEvents

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import moment from 'moment';
import {mmkvStorage} from 'src/Utils/mmkvStorage';

import {TEventInfo} from 'src/types/TEventInfo';
import {TPhoto} from 'src/types/TPhoto';
import {TSimpleGroupInfo} from 'src/types/TSimpleGroup';

interface EventState {
  info: TEventInfo | null;
  eventId: string | null;
  groups: TSimpleGroupInfo[] | null;
  photos: Record<string, {photos: TPhoto[]}>;
  hostMaximized: boolean;
}

const initialState: EventState = {
  info: null,
  eventId: null,
  groups: null,
  photos: {},
  hostMaximized: false,
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    updateEventInfo(state, action: PayloadAction<TEventInfo | null>) {
      state.info = action.payload;
    },
    setEventId(state, action: PayloadAction<string | null>) {
      const newLastActive = moment().format('YYYY-MM-DDTHH:mm:ss.sssZ');
      mmkvStorage.set('eventLastActive', newLastActive);

      state.eventId = action.payload;
    },
    updateEventGroups(state, action: PayloadAction<TSimpleGroupInfo[] | null>) {
      state.groups = action.payload || [];
    },
    setEventPhotos(
      state,
      action: PayloadAction<Record<string, {photos: TPhoto[]}>>,
    ) {
      state.photos = action.payload;
    },
    setHostMaximized(state, action: PayloadAction<boolean>) {
      console.log('setHostMaximized', {hostMaximized: action.payload});
      state.hostMaximized = action.payload;
    },
  },
});

export const {
  updateEventInfo,
  updateEventGroups,
  setEventPhotos,
  setEventId,
  setHostMaximized,
} = eventSlice.actions;

export default eventSlice.reducer;

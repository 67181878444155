import React, {useEffect} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {useWindowDimensions} from 'react-native';
import {
  setLocalFilters,
  updateLocalView,
} from 'src/Redux/reducers/local.reducer';
import LocalList from 'src/Local/LocalList';
import LocalMap from 'src/Local/LocalMap';
import SearchBar from 'src/Modules/CommonView/SearchBar';
import {navigationRef} from 'src/Nav/navigationHelpers';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import DelayedLoad from 'src/Nav/DelayedLoad';
import {ScrollView} from 'react-native-gesture-handler';
import {dispatchAction} from 'src/Utils/helpers';

const LocalTab: React.FC = () => {
  console.log('LocalTab render');
  const {width} = useWindowDimensions();
  const localView = useTypedSelector((state) => state.local.localView);
  const handleTopPress = (side: 'map' | 'list' | 'leaderboard') => {
    dispatchAction(updateLocalView(side));
  };
  const localFilters = useTypedSelector((state) => state.local.localFilters);

  useEffect(() => {
    console.log({localView});
  }, [localView]);

  return (
    <ScrollView>
      <View style={{flex: 1}}>
        <View testID="localTabMap" style={{height: 200}}>
          <DelayedLoad delay={50}>
            <LocalMap />
          </DelayedLoad>
        </View>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: 'white',
            zIndex: 1000,
          }}>
          <SearchBar
            containerStyle={{
              width: width - 70,
              backgroundColor: 'white',
              borderWidth: 0,
              borderBottomColor: 'transparent',
              borderTopColor: 'transparent',
              marginBottom: 10,
            }}
            onChangeText={(text) => {
              const newLocalFilters = {
                ...localFilters,
                title: text,
              };
              dispatchAction(setLocalFilters(newLocalFilters));
            }}
            value={localFilters?.title || ''}
            onClear={() => {
              const newLocalFilters = {
                ...localFilters,
                title: null,
              };
              dispatchAction(setLocalFilters(newLocalFilters));
              //setActiveSearch(false);
            }}
            inputStyle={{
              height: 35,
              fontSize: 14,
              backgroundColor: constants?.color?.white1,
              borderRadius: 12,
              marginLeft: 10,
              color: constants?.color?.gray3,
              marginRight: 5,
              paddingLeft: 30,
            }}
            placeholder="Search Local Sites"
          />
          <TouchableOpacity
            onPress={() => {
              navigationRef.navigate(
                'LocalFilterModal',
                null,
                'Local/LocalTab.tsx',
              );
            }}
            style={{
              height: 55,
              marginTop: 3,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <Text style={{color: constants?.color?.blue, lineHeight: 14}}>
              Filter{localFilters?.filter_count > 0 ? 's ' : ' '}
              <View
                style={{
                  backgroundColor: constants?.color?.blue,
                  top: 2,
                  height: 16,
                  width: 16,

                  borderRadius: 8,
                }}>
                <Text
                  style={{
                    color: 'white',
                    fontSize: 12,

                    textAlign: 'center',
                    fontFamily: constants?.font?.circular,
                  }}>
                  {localFilters?.filter_count || 0}
                </Text>
              </View>
            </Text>
          </TouchableOpacity>
        </View>
        <View style={{flex: 1}}>
          {!!(localView === 'list') && (
            <LocalList handleTopPress={handleTopPress} />
          )}
          {!!(localView === 'map') && (
            <DelayedLoad delay={100}>
              <LocalMap />
            </DelayedLoad>
          )}
        </View>
      </View>
    </ScrollView>
  );
};

export default LocalTab;

import {Text, TextInput, TouchableOpacity, View} from 'react-native';
import constants from 'src/constants';

import React from 'react';
import {navigationRef} from 'src/Nav/navigationHelpers';
import ExpoImage from 'src/Utils/ExpoImage';

type SubscriptionPromptProps = {
  title: string;
  titleBold: string;
  description: string;
  referralLink?: string;
};

const SubscriptionPrompt = ({
  title,
  titleBold,
  description,
  referralLink,
}: SubscriptionPromptProps) => {
  return (
    <TouchableOpacity
      onPress={() =>
        navigationRef.navigate(
          !!referralLink ? 'ReferralModal' : 'FreeTrialScreen',
          null,
          'SubscriptionPrompt.tsx',
        )
      }
      style={{
        backgroundColor: constants.color.orange,
        paddingTop: 24,
        marginTop: !!referralLink ? 20 : 0,
        marginHorizontal: 24,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        borderRadius: 20,
        shadowColor: '#000',
        shadowOffset: {width: 0, height: -1},
        shadowOpacity: 0.2,
        shadowRadius: 5,
        gap: 8,
      }}>
      <Text
        style={{
          fontSize: 32,
          color: constants.color.white,
          paddingHorizontal: 38,
          fontFamily: 'Jakarta',
        }}>
        {title}{' '}
        <Text
          style={{
            fontFamily: 'JakartaBold',
          }}>
          {titleBold}
        </Text>
      </Text>
      <Text
        style={{
          fontSize: 15,
          color: constants.color.white,
          paddingHorizontal: 38,
          fontFamily: 'Jakarta',
          lineHeight: 24,
        }}>
        {description}
      </Text>
      <View>
        <ExpoImage
          resizeMode={'cover'}
          source={require('src/Images/BackButton.png')}
          style={{
            width: 30,
            height: 30,
            transform: [{rotate: '180deg'}],
            marginHorizontal: 38,
            marginTop: 10,
          }}
        />
      </View>
      {!!referralLink && (
        <TextInput
          editable={false}
          placeholder={referralLink}
          style={{
            backgroundColor: constants.color.white,
            padding: 10,
            marginHorizontal: 40,
            marginTop: 12,
            borderRadius: 8,
          }}
        />
      )}
      <ExpoImage
        resizeMode={'cover'}
        style={{
          width: '100%',
          height: 300,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
        }}
        source={require('src/Images/LetsRoamPlus/lr_plus_selfie.png')}
      />
    </TouchableOpacity>
  );
};

export default SubscriptionPrompt;

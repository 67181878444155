import React, {useEffect, useState, useRef} from 'react';
import {ScrollView, Text, TouchableOpacity, View, Image} from 'react-native';
import {Svg, Circle, Line, Path} from 'react-native-svg';

// utils
import {selectCharacter} from 'src/Utils/huntFunctions';

// components
import {BodyText, ContinueButton} from 'src/Modules/CommonView';
import {navigationRef} from 'src/Nav/navigationHelpers';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import constants from 'src/constants';
import {RootState} from 'src/Redux/reducers/rootReducer';
import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import {TCharacter} from 'src/types/TTheme';
import {TGroupPlayer} from 'src/types/TGroupInfo';

interface CharacterButtonProps {
  chooseCharacter: (_characterObj: TCharacter) => void;
  characterObj: TCharacter;
  selectedCharacter: TCharacter | null;
  color: string;
  icon: string;
}
const CharacterButton: React.FC<CharacterButtonProps> = ({
  chooseCharacter,
  characterObj,
  selectedCharacter,
  color,
  icon,
}) => {
  const {source, character, description} = characterObj || {};

  const isSelected = selectedCharacter?.character === character;
  const backgroundColor = isSelected
    ? constants?.color?.gray1_50
    : constants?.color?.white;

  return (
    <TouchableOpacity
      onPress={() => chooseCharacter(characterObj)}
      style={{width: '100%'}}>
      <View
        style={{
          marginHorizontal: 10,
          marginVertical: 7.5,
          backgroundColor,
          borderRadius: 10,
          padding: 10,
          borderWidth: 1,
          borderColor: color,
        }}>
        <View
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}>
          <View style={{flexDirection: 'row', alignItems: 'center'}}>
            <SVGIcon name={icon} color={color} />
            {/* <Image
              style={{width: 51, height: 43}}
              source={require('src/Images/Icons/compass.png')}
            /> */}
            <Text
              style={{
                color,
                fontFamily: constants?.font.JakartaBold,
                fontSize: 20,
                marginVertical: 5,
                marginLeft: 10,
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
              {character}
            </Text>
          </View>
        </View>

        <View
          style={{
            marginTop: 5,
            width: '100%',
            alignSelf: 'stretch',
          }}>
          {/* <Text
            style={{
              color: 'black',
              fontFamily: constants?.font.Jakarta,
              fontSize: 11,
            }}>
            Challenge Types:
          </Text> */}
          <Text
            style={{
              color,
              fontFamily: constants?.font.Jakarta,
              fontSize: 11,
              marginTop: 3,
            }}>
            {description}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

interface SVGIconProps {
  name: string;
  color?: string;
  width?: number;
  height?: number;
}

const SVGIcon: React.FC<SVGIconProps> = ({
  name,
  color = '#000',
  width = 51,
  height = 43,
}) => {
  const icons: {[key: string]: JSX.Element} = {
    camera: (
      <Svg width={width} height={height} viewBox="0 0 51 43" fill="none">
        <Path
          d="M22.9688 14.9062C18.7471 14.9062 15.3125 18.3408 15.3125 22.5625C15.3125 26.7842 18.7471 30.2188 22.9688 30.2188C27.1904 30.2188 30.625 26.7842 30.625 22.5625C30.625 18.3408 27.1904 14.9062 22.9688 14.9062ZM27.5625 24.0938C26.7157 24.0938 26.0312 23.4078 26.0312 22.5625C26.0312 20.8735 24.6577 19.5 22.9688 19.5C22.122 19.5 21.4375 18.814 21.4375 17.9688C21.4375 17.1235 22.122 16.4375 22.9688 16.4375C26.3467 16.4375 29.0938 19.1846 29.0938 22.5625C29.0938 23.4078 28.4093 24.0938 27.5625 24.0938Z"
          fill={color}
        />
        <Path
          d="M31.4366 5.71875L30.5791 2.28416C30.4076 1.60428 29.7966 1.125 29.0938 1.125H16.8438C16.1409 1.125 15.5299 1.60428 15.3584 2.28416L14.5009 5.71875H31.4366Z"
          fill={color}
        />
        <Path
          d="M41.3438 7.25H31.8194H14.1181H4.59375C2.06106 7.25 0 9.31106 0 11.8438V33.2812C0 35.8139 2.06106 37.875 4.59375 37.875H41.3438C43.8764 37.875 45.9375 35.8139 45.9375 33.2812V11.8438C45.9375 9.31106 43.8764 7.25 41.3438 7.25ZM7.65625 16.4375C6.80947 16.4375 6.125 15.7515 6.125 14.9062C6.125 14.061 6.80947 13.375 7.65625 13.375C8.50303 13.375 9.1875 14.061 9.1875 14.9062C9.1875 15.7515 8.50303 16.4375 7.65625 16.4375ZM22.9688 33.2812C17.0597 33.2812 12.25 28.4731 12.25 22.5625C12.25 16.6519 17.0597 11.8438 22.9688 11.8438C28.8778 11.8438 33.6875 16.6519 33.6875 22.5625C33.6875 28.4731 28.8778 33.2812 22.9688 33.2812Z"
          fill={color}
        />
      </Svg>
    ),
    puzzle: (
      <Svg width={width} height={height} viewBox="0 0 51 43" fill="none">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 7.23823H22.2245V12.9384C22.9969 12.9384 23.7376 13.2452 24.2837 13.7914C24.8298 14.3375 25.1366 15.0782 25.1366 15.8505C25.1366 16.6229 24.8298 17.3636 24.2837 17.9097C23.7376 18.4558 22.9969 18.7626 22.2245 18.7626V23.1503H17.629C17.3519 22.3035 16.8143 21.5658 16.0929 21.0428C15.3715 20.5198 14.5033 20.2382 13.6123 20.2382C12.7212 20.2382 11.853 20.5198 11.1316 21.0428C10.4102 21.5658 9.8726 22.3035 9.59555 23.1503H5V7.23823ZM22.2245 35.9873C21.4522 35.9873 20.7115 35.6805 20.1653 35.1344C19.6192 34.5882 19.3124 33.8475 19.3124 33.0752C19.3124 32.3029 19.6192 31.5622 20.1653 31.016C20.7115 30.4699 21.4522 30.1631 22.2245 30.1631V24.4629H27.9247C27.9247 25.2352 28.2315 25.976 28.7776 26.5221C29.3238 27.0682 30.0645 27.375 30.8368 27.375C31.6091 27.375 32.3499 27.0682 32.896 26.5221C33.4421 25.976 33.7489 25.2352 33.7489 24.4629H39.4491V41.6875H22.2245V35.9873ZM5 24.4629V41.6875H20.912V37.0919C20.0651 36.8148 19.3275 36.2772 18.8045 35.5558C18.2815 34.8344 17.9998 33.9662 17.9998 33.0752C17.9998 32.1841 18.2815 31.3159 18.8045 30.5945C19.3275 29.8731 20.0651 29.3355 20.912 29.0585V24.4629H16.5244C16.5244 23.6906 16.2176 22.9499 15.6715 22.4037C15.1253 21.8576 14.3846 21.5508 13.6123 21.5508C12.84 21.5508 12.0993 21.8576 11.5531 22.4037C11.007 22.9499 10.7002 23.6906 10.7002 24.4629H5ZM28.463 1H45.6875V18.2246H39.9874C39.9874 18.9969 39.6806 19.7376 39.1345 20.2838C38.5883 20.8299 37.8476 21.1367 37.0753 21.1367C36.3029 21.1367 35.5622 20.8299 35.0161 20.2838C34.47 19.7376 34.1632 18.9969 34.1632 18.2246H28.463V12.5245C29.2353 12.5245 29.976 12.2177 30.5222 11.6716C31.0683 11.1254 31.3751 10.3847 31.3751 9.61238C31.3751 8.84004 31.0683 8.09933 30.5222 7.55321C29.976 7.00708 29.2353 6.70027 28.463 6.70027V1Z"
          fill="#53A6C4"
        />
      </Svg>
    ),
    compass: (
      <Svg width={width} height={height} viewBox="0 0 51 43" fill="none">
        <Path
          d="M24.2855 24.0796C24.2855 23.6847 24.1317 23.3135 23.8525 23.0343C23.5733 22.7551 23.202 22.6013 22.8072 22.6013C22.4125 22.6013 22.0411 22.7551 21.762 23.0343C21.4828 23.3135 21.329 23.6848 21.329 24.0796C21.329 24.4744 21.4827 24.8457 21.762 25.1249C22.0411 25.404 22.4125 25.5578 22.8072 25.5578C23.202 25.5578 23.5733 25.404 23.8525 25.1249C24.1317 24.8457 24.2855 24.4744 24.2855 24.0796Z"
          fill={color}
        />
        <Path
          d="M36.1061 10.7807C33.3934 8.06799 29.998 6.28603 26.3073 5.59524C26.5586 5.08061 26.6998 4.50273 26.6998 3.89252C26.6998 1.74619 24.9537 0 22.8073 0C20.661 0 18.9148 1.74619 18.9148 3.89252C18.9148 4.50281 19.056 5.08061 19.3074 5.59524C15.6167 6.28603 12.2213 8.06799 9.50855 10.7807C5.95627 14.3329 4 19.0559 4 24.0795C4 29.1031 5.95627 33.826 9.50855 37.3783C13.0608 40.9306 17.7837 42.8868 22.8073 42.8868C27.831 42.8868 32.5539 40.9306 36.1061 37.3783C39.6584 33.826 41.6147 29.1031 41.6147 24.0795C41.6147 19.0559 39.6584 14.3329 36.1061 10.7807ZM22.8073 2.51287C23.5681 2.51287 24.187 3.13179 24.187 3.89252C24.187 4.65325 23.5681 5.27217 22.8073 5.27217C22.0466 5.27217 21.4277 4.65325 21.4277 3.89252C21.4277 3.13179 22.0466 2.51287 22.8073 2.51287ZM22.8073 38.3618C14.932 38.3618 8.52501 31.9548 8.52501 24.0795C8.52501 16.2042 14.932 9.79718 22.8073 9.79718C30.6827 9.79718 37.0896 16.2042 37.0896 24.0795C37.0896 31.9548 30.6827 38.3618 22.8073 38.3618Z"
          fill={color}
        />
        <Path
          d="M32.4409 22.823H34.5101C33.925 17.3348 29.552 12.9618 24.0638 12.3767V14.4459H21.5509V12.3767C16.0626 12.9618 11.6896 17.3348 11.1046 22.823H13.1737V25.3359H11.1046C11.6896 30.8242 16.0626 35.1972 21.5509 35.7822V33.7131H24.0638V35.7822C29.552 35.1972 33.925 30.8242 34.5101 25.3359H32.4409V22.823ZM23.3715 28.0312C23.1862 28.0573 22.9978 28.0706 22.8073 28.0706C21.7412 28.0706 20.739 27.6554 19.9852 26.9016C19.2313 26.1478 18.8162 25.1456 18.8162 24.0795C18.8162 23.889 18.8295 23.7006 18.8556 23.5153L15.9116 17.1837L22.2432 20.1277C22.4286 20.1016 22.6169 20.0883 22.8073 20.0883C23.8735 20.0883 24.8757 20.5035 25.6294 21.2573C26.3832 22.0111 26.7984 23.0134 26.7984 24.0794C26.7984 24.2699 26.7851 24.4583 26.7591 24.6435L29.7031 30.975L23.3715 28.0312Z"
          fill={color}
        />
      </Svg>
    ),
    binoculars: (
      <Svg width={width} height={height} viewBox="0 0 51 43" fill="none">
        <Path
          d="M20.6519 7.93157C19.5464 7.4411 18.293 7.16861 17.0551 7.16861C16.3388 7.16861 15.6537 7.25425 15.0076 7.41774C14.7507 7.48002 14.5015 7.26204 14.5404 6.99734L14.6339 6.24995C14.7117 5.0043 16.1364 4 17.8414 4C19.5931 4 21.2669 5.05101 21.2669 6.35116L21.1891 7.5968C21.1657 7.86929 20.8932 8.04057 20.6519 7.93157Z"
          fill="#FCAB28"
        />
        <Path
          d="M6.06228 29.3489L6.10121 29.1231C6.10121 29.1076 6.10899 29.092 6.10899 29.0764L7.54927 21.7583C7.73612 20.0689 8.9662 18.5974 10.7568 17.6866L11.4419 12.3692C11.4419 10.1115 13.9955 8.26636 17.0551 8.26636C20.1147 8.26636 23.0264 10.0959 23.0264 12.3614C23.0264 12.4704 23.0186 12.5872 23.0031 12.6962L22.9641 13.2723C23.5714 13.0543 24.2643 12.9297 24.9961 12.9297C25.7357 12.9297 26.4286 13.0543 27.0436 13.2801L27.0047 12.6962C26.9891 12.5872 26.9814 12.4782 26.9814 12.3614C26.9814 10.1037 29.9008 8.26636 32.9527 8.26636C36.0123 8.26636 38.5659 10.1115 38.5659 12.3692L39.251 17.6866C41.0416 18.5897 42.2717 20.0689 42.4585 21.7583L43.8988 29.0764C43.8988 29.092 43.9066 29.1076 43.9066 29.1231L43.9455 29.3489C43.9766 29.5825 44 29.816 44 30.0574C44 33.5296 40.3643 36.3479 35.8877 36.3479C31.4112 36.3479 27.7755 33.5296 27.7755 30.0574V27.1768C26.9736 27.5505 26.016 27.7607 24.9961 27.7607C23.9762 27.7607 23.0264 27.5505 22.2245 27.1846V30.0574C22.2245 33.5296 18.5888 36.3479 14.1123 36.3479C9.64352 36.3479 6 33.5296 6 30.0574C6.00779 29.816 6.03114 29.5825 6.06228 29.3489ZM35.8877 34.0824C39.0563 34.0824 41.7345 32.2373 41.7345 30.0574C41.7345 27.8697 39.0563 26.0324 35.8877 26.0324C32.7191 26.0324 30.041 27.8775 30.041 30.0574C30.041 32.2373 32.7191 34.0824 35.8877 34.0824ZM24.9961 25.7677C26.3274 25.7677 27.4095 25.0125 27.4095 24.0861C27.4095 23.1596 26.3274 22.4044 24.9961 22.4044C23.6648 22.4044 22.5827 23.1596 22.5827 24.0861C22.5827 25.0125 23.6648 25.7677 24.9961 25.7677ZM14.1201 34.0824C17.2887 34.0824 19.9668 32.2373 19.9668 30.0574C19.9668 27.8697 17.2887 26.0324 14.1201 26.0324C10.9514 26.0324 8.2733 27.8775 8.2733 30.0574C8.2733 32.2373 10.9514 34.0824 14.1201 34.0824Z"
          fill="#FCAB28"
        />
        <Path
          d="M35.008 7.41777C34.3618 7.25428 33.6689 7.16864 32.9605 7.16864C31.7226 7.16864 30.4614 7.44113 29.3637 7.9316C29.1223 8.04059 28.8498 7.86932 28.8343 7.60462L28.7564 6.35897C28.7564 5.05883 30.4303 4.00781 32.1819 4.00781C33.8869 4.00781 35.3038 5.01212 35.3895 6.25776L35.4829 7.00515C35.514 7.26206 35.2727 7.48784 35.008 7.41777Z"
          fill="#FCAB28"
        />
      </Svg>
    ),
    backpack: (
      <Svg width={width} height={height} viewBox="0 0 51 43" fill="none">
        <Path
          d="M20.1171 6.17788C20.8084 4.94083 21.8412 3.92897 23.0921 3.26323C23.5749 3.93305 23.7559 5.01928 23.6473 6.50376C24.9239 6.5835 26.0365 6.68426 27.2258 6.8176C27.3692 5.47895 27.0084 4.13492 26.2138 3.04806C25.4193 1.9612 24.2481 1.20958 22.9291 0.940007C22.0524 0.666587 21.13 0.570705 20.2159 0.65797C19.3017 0.745234 18.4141 1.01389 17.605 1.44823C16.7959 1.88257 16.0816 2.47388 15.5037 3.18757C14.9258 3.90126 14.496 4.723 14.2395 5.60475C14.4394 5.59836 19.2453 6.08368 20.1171 6.17788Z"
          fill="#ECBF07"
        />
        <Path
          d="M32.7837 13.5581L32.4155 13.9685C31.2096 16.8121 29.5548 20.1935 29.5976 23.3341L29.1509 36.9057C29.1386 37.309 29.2224 37.7094 29.3953 38.074C29.5682 38.4385 29.8252 38.7568 30.1452 39.0025C30.4652 39.2483 30.839 39.4144 31.2358 39.4874C31.6327 39.5603 32.0412 39.5379 32.4276 39.422C33.9602 39.0147 35.5088 36.882 35.1732 33.9186L35.8431 11.428C34.6701 11.8878 33.6219 12.6176 32.7837 13.5581Z"
          fill="#ECBF07"
        />
        <Path
          d="M38.3897 3.72192C38.3646 3.71523 38.3181 3.70779 38.2992 3.70379L32.9888 3.00983C30.4415 2.67472 28.7789 5.67288 28.6018 6.99871C30.6701 7.23922 32.7009 7.73374 34.6483 8.47108C34.9438 7.63879 35.3695 6.85868 35.9095 6.15984C36.1576 5.84284 36.4551 5.56779 36.7905 5.34522C38.0095 6.87795 41.1656 12.7918 38.1303 30.5634C37.9454 31.7903 37.3266 32.9101 36.3863 33.7195C36.475 34.8309 36.3729 35.9494 36.0845 37.0264C37.5771 36.2332 38.8526 35.0864 39.7994 33.6862C40.7462 32.286 41.3355 30.6751 41.5156 28.9945L42.8553 16.853C43.8811 5.45985 39.144 3.8969 38.3897 3.72192Z"
          fill="#ECBF07"
        />
        <Path
          d="M17.0275 22.9177C17.2521 22.7414 17.3976 22.4831 17.4318 22.1996L17.637 20.3771L17.643 20.2987L17.8965 17.9815C17.9124 17.8412 17.9006 17.6991 17.8616 17.5634C17.8226 17.4277 17.7573 17.301 17.6693 17.1906C17.5813 17.0801 17.4724 16.9881 17.3488 16.9199C17.2252 16.8516 17.0893 16.8083 16.949 16.7926C16.8751 16.7785 16.0837 16.694 16.0318 16.6961C15.7659 16.6954 15.5092 16.793 15.3109 16.9701C15.1127 17.1472 14.9868 17.3913 14.9576 17.6556C14.8415 18.7346 14.6019 20.7963 14.4869 21.8676C14.4594 22.1174 14.5196 22.3691 14.6572 22.5794C14.7948 22.7897 15.0013 22.9455 15.2413 23.0203L15.3861 21.6263C15.3928 21.5474 15.4154 21.4706 15.4524 21.4006C15.4894 21.3306 15.54 21.2687 15.6014 21.2186C15.6627 21.1685 15.7335 21.1313 15.8095 21.109C15.8855 21.0868 15.9652 21.0801 16.0438 21.0892C16.1227 21.0975 16.1992 21.1212 16.2689 21.159C16.3386 21.1969 16.4002 21.2481 16.4501 21.3097C16.4999 21.3714 16.5372 21.4423 16.5596 21.5184C16.5821 21.5944 16.5893 21.6742 16.5809 21.753L16.4361 23.153C16.653 23.1385 16.8599 23.0562 17.0275 22.9177Z"
          fill="#ECBF07"
        />
        <Path
          d="M7.37225 18.5184L13.5637 19.2245L13.7568 17.5227C13.7893 17.2237 13.8806 16.934 14.0255 16.6703C14.1703 16.4067 14.3658 16.1742 14.6008 15.9864C14.8358 15.7985 15.1055 15.6589 15.3946 15.5757C15.6837 15.4924 15.9863 15.4671 16.2852 15.5011L17.0818 15.5917C17.6833 15.6593 18.2335 15.963 18.6114 16.4359C18.9893 16.9088 19.164 17.5124 19.0973 18.1141L18.9041 19.8339L22.5369 20.2563C23.2792 20.3421 24.0312 20.2481 24.7296 19.9824C25.428 19.7167 26.0523 19.2871 26.5499 18.7296L31.4378 13.2563C32.498 11.9301 33.8852 10.9028 35.4629 10.2753C34.9022 9.88582 34.2884 9.57894 33.6405 9.36405C31.8719 8.59014 26.2909 7.79099 22.9171 7.66242L14.7101 6.84766C14.0377 6.73603 13.3471 6.83953 12.7369 7.1434C10.6911 8.17528 5.91784 11.1744 5.7851 16.7564C5.79434 17.1916 5.95971 17.609 6.25107 17.9325C6.54242 18.2559 6.94034 18.4639 7.37225 18.5184Z"
          fill="#ECBF07"
        />
        <Path
          d="M28.3904 23.2918C28.4655 21.0355 28.9865 18.8164 29.9232 16.7625L27.4491 19.5322C26.8993 20.1491 26.2253 20.6428 25.4713 20.9808C24.7173 21.3188 23.9003 21.4935 23.074 21.4935C22.673 21.5366 19.2476 21.0699 18.7714 21.0349L18.6266 22.3322C18.5848 22.6569 18.4754 22.9693 18.3053 23.249C18.1352 23.5288 17.9084 23.7697 17.6393 23.9563C17.3703 24.1429 17.0651 24.2709 16.7435 24.3321C16.4219 24.3933 16.091 24.3863 15.7722 24.3115C15.4332 24.3296 15.0942 24.2746 14.7782 24.1502C14.4623 24.0259 14.1767 23.835 13.941 23.5907C13.7052 23.3463 13.5247 23.0542 13.4117 22.734C13.2987 22.4138 13.2559 22.073 13.2861 21.7348L13.4308 20.4314L7.23341 19.7193C5.39571 24.359 3.12308 36.9224 7.61337 38.9935C7.63772 39.0056 10.2929 40.8823 16.9791 40.9427H23.412C25.3226 40.9464 27.1952 40.4087 28.8128 39.3918C28.2235 38.6861 27.9142 37.7884 27.9438 36.8694L28.3904 23.2918Z"
          fill="#ECBF07"
        />
      </Svg>
    ),
  };

  return icons[name] || null;
};

const CharacterSelection: React.FC = () => {
  const userId = useTypedSelector((state: RootState) => state.user?.userId);

  const groupId = useTypedSelector((state) => state.group?.info?.groupId);
  const theme = useTypedSelector((state) => state.group?.info?.theme);

  const players = useTypedSelector(
    (state) => state.group?.info?.players || {},
    deepEqualityCheck,
  );

  const themes = useTypedSelector((state) => state.themeInfo?.themes);
  const characters = useTypedSelector((state) => state.themeInfo?.characters);

  const [selectedCharacter, setSelectedCharacter] = useState<TCharacter | null>(
    null,
  );

  //setting the default character to the first character in the theme
  useEffect(() => {
    if (theme && themes && characters) {
      const defaultCharacterKey = Object.values(themes[theme].characters)[0];
      const defaultCharacter = characters[defaultCharacterKey];
      setSelectedCharacter(defaultCharacter);
    }
  }, [theme, themes, characters]);

  const playersOnDevice = Object.values(players).filter((player) => {
    return player.userId == userId || player.parentUserId == userId;
  });

  const handleChooseCharacter = (selectedCharacter: TCharacter) => {
    setSelectedCharacter(selectedCharacter);
  };

  const handleCharacterSelected = async (player: TGroupPlayer) => {
    if (!selectedCharacter) {
      return navigationRef.simpleAlert('Please select a character');
    }

    if (!userId) {
      return navigationRef.simpleAlert('Please sign in');
    }

    await selectCharacter(
      selectedCharacter?.character ?? 'Shutterbug',
      player?.userId || 'error',
      userId,
      selectedCharacter?.role_type ?? 'standard-1',
    );

    setSelectedCharacter(null);

    if (playersOnDevice.every((devicePlayer) => devicePlayer.character)) {
      console.log('Character Selection: navigate to main game stack called');
      return navigationRef.navigate(
        'MainGameStack',
        null,
        'character selection 1',
      );
    }
  };

  console.log({playersOnDevice});

  const currentPlayer =
    (playersOnDevice &&
      playersOnDevice?.length > 0 &&
      playersOnDevice?.find?.((devPlayer) => !devPlayer.character)) ||
    false;

  console.log({playersOnDevice, currentPlayer});

  if (!groupId || !currentPlayer) {
    console.log('showing Loading Your Hunt because', {
      groupId,
      currentPlayer,
      playersOnDevice,
      userId,
    });

    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <BodyText
          text={'Loading Your Hunt'}
          textStyle={{textAlign: 'center', marginBottom: 0}}
        />
        <BodyText
          text={`Join Code: ${groupId}`}
          textStyle={{textAlign: 'center', marginBottom: 10}}
        />
        <CustomActivityIndicator />
        <ContinueButton
          text={'Reload'}
          width={150}
          containerStyle={{marginTop: 100}}
          handlePress={() => {
            navigationRef.navigate(
              'MainGameStack',
              null,
              'character selection 3',
            );
          }}
        />
      </View>
    );
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: constants?.color?.white,
      }}>
      <ScrollView style={{flex: 1}}>
        <View
          testID="chooseYourCharacter"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text
            style={{
              color: constants?.color?.gray3,
              fontFamily: constants?.font.JakartaBold,
              fontSize: 20,
              marginTop: 10,
              textAlign: 'center',
            }}>
            {`${currentPlayer.firstName}! Select Your Role`}
          </Text>
          <Text
            style={{
              color: constants?.color?.black,
              fontFamily: constants?.font.Jakarta,
              fontSize: 10,
              marginTop: 7,
              textAlign: 'center',
            }}>
            {`Your role will determine the type of personalized bonus challenges you get throughout the hunt.`}
          </Text>
        </View>
        <View
          style={{
            alignItems: 'center',
            marginTop: 10,
          }}>
          {!!theme &&
            Object.values(themes![theme].characters)?.map?.((key, index) => {
              const character = characters![key] || {};
              const color = characterColors[index % characterColors.length];
              const characterIcon =
                characterIcons[index % characterColors.length];
              return (
                <CharacterButton
                  key={character.character}
                  characterObj={character}
                  chooseCharacter={() => {
                    handleChooseCharacter(character);
                  }}
                  selectedCharacter={selectedCharacter}
                  color={color}
                  icon={characterIcon}
                />
              );
            })}
        </View>
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          left: 20,
          right: 20,
          bottom: 40,
          flex: 1,
          height: 50,
        }}>
        {!!selectedCharacter && (
          <ContinueButton
            background={constants?.color?.orange}
            text={'Pick Role'}
            handlePress={() => {
              handleCharacterSelected(currentPlayer);
            }}
          />
        )}
      </View>
    </View>
  );
};

export default CharacterSelection;

const characterColors = [
  '#E87722', // orange
  '#53A6C4', // blue
  '#6AAEAA', // teal
  '#E7B506', // yellow
  '#FCAB28', // orange/yellow
];

const characterIcons = [
  'camera',
  'puzzle',
  'compass',
  'backpack',
  'binoculars',
];

import React from 'react';

import {View, Text, TouchableOpacity, Linking} from 'react-native';

import constants from 'src/constants';
import useTypedSelector from 'src/Hooks/useTypedSelector';

interface CallSupportLineProps {
  backgroundColor?: string;
  message?: string;
  color?: string;
  showBottomShadow?: boolean;
}

const CallSupportLine: React.FC<CallSupportLineProps> = ({
  backgroundColor = constants?.color.orange,
  message = 'Get Live Support At',
  color = 'white',
  showBottomShadow = false,
}) => {
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );
  const countryCode = useTypedSelector(
    (state) => state?.user?.info?.countryCode,
  );

  const companyColor = useTypedSelector(
    (state) => state.event?.info?.branding?.eventColor,
  );

  let backgroundColorOverride = backgroundColor;
  if (companyColor) {
    backgroundColorOverride = companyColor;
  }

  let phoneNumber = 'tel://+18332027626';
  let phoneNumberFormatted = '1-833-202-7626';
  if (countryCode === 'CA' || countryCode === 'CAN') {
    phoneNumber = 'tel://+17203403264';
    phoneNumberFormatted = '(720) 340-3264';
  } else if (countryCode === 'AU' || countryCode === 'AUS') {
    phoneNumber = 'tel://+61284171042';
    phoneNumberFormatted = '+61 2 8417 1042';
  }

  const shadow = showBottomShadow
    ? {
        elevation: 3,
        shadowColor: constants?.color.gray2,
        shadowRadius: 2,
        shadowOpacity: 0.3,
      }
    : {};

  if (playTestState === false) {
    return (
      <TouchableOpacity
        onPress={() => Linking.openURL(phoneNumber)}
        style={{
          width: '100%',
          backgroundColor: backgroundColorOverride,
          ...shadow,
        }}>
        <Text
          style={{
            color,
            textAlign: 'center',
            marginTop: 3,
            marginBottom: 3,
            fontSize: 14,
            fontFamily: constants?.font.circular,
          }}>
          {message + ' '}
          <Text style={{textDecorationLine: 'underline'}}>
            {phoneNumberFormatted}
          </Text>
        </Text>
      </TouchableOpacity>
    );
  } else {
    return <View />;
  }
};

export default CallSupportLine;

import React from 'react';
import {
  TouchableOpacity,
  View,
  Image,
  Text,
  SafeAreaView,
  ScrollView,
} from 'react-native';
import {BodyText} from 'src/Modules/CommonView';
import {WanderlistStop} from 'src/Modules/WanderlistStop';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import useParams from 'src/Hooks/useParams';
import LocalMap from './LocalMap';

import signInImage from 'src/Images/signInImage.png';
import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {SimpleHeaderBar} from 'src/HeaderComponent/HeaderComponents';
import {apiFavoriteLocation} from 'src/Utils/apiCalls';
import {useUserId} from 'src/Hooks/reduxHooks';

export default function Wanderlist() {
  const {wanderlistId} = useParams<'WanderList'>();
  const userId = useUserId();

  const wanderlists = useTypedSelector(
    (state) => state.local.wanderlists || [],
  );

  const localFavorites = useTypedSelector(
    (state) => state.local.localFavorites,
  );

  const isFavorited = localFavorites?.includes?.(wanderlistId + '');

  const currentWanderlist = wanderlists.find(
    (wanderlist) => wanderlist.hunt_id === wanderlistId,
  );

  const wanderlistStops = currentWanderlist?.wander_list_locations || [];

  const defaultPhotoURL =
    'https://photos.letsroam.com/scavenger_hunt_locations/';

  const wanderlistPhoto = currentWanderlist?.huntMediumPhotoURL?.includes(
    'firebase',
  )
    ? currentWanderlist.huntMediumPhotoURL
    : defaultPhotoURL + currentWanderlist?.huntMediumPhotoURL;

  return (
    <SafeAreaView style={{flex: 1, backgroundColor: 'white'}}>
      <SimpleHeaderBar showBack></SimpleHeaderBar>
      <ScrollView>
        <View style={{height: 200}}>
          <LocalMap
            showWanderlistLocations={true}
            wanderlistStops={wanderlistStops}
          />
        </View>
        <View style={{paddingHorizontal: 24}}>
          <BodyText
            text="WANDERLIST"
            textStyle={{
              color: constants.color.orange,
              fontFamily: 'JakartaBold',
              fontSize: 11,
              lineHeight: 11,
              marginTop: 0,
              marginLeft: 0,
              letterSpacing: 1.1,
            }}
          />
          {!!currentWanderlist?.name && (
            <BodyText
              text={currentWanderlist?.name}
              textStyle={{
                color: constants.color.black,
                fontFamily: 'JakartaBold',
                fontSize: 24,
                lineHeight: 24,
                marginTop: 12,
                marginLeft: 0,
              }}
            />
          )}
          {!!currentWanderlist?.long_description && (
            <BodyText
              text={currentWanderlist?.long_description}
              textStyle={{
                color: constants.color.black,
                fontFamily: 'Jakarta',
                fontSize: 15,
                lineHeight: 21,
                marginTop: 12,
                marginLeft: 0,
              }}
            />
          )}
          <Image
            source={{uri: wanderlistPhoto}}
            style={{
              height: 150,
              resizeMode: 'cover',
              marginTop: 24,
              borderRadius: 8,
            }}
          />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: 24,
            }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <Image
                source={signInImage}
                style={{
                  width: 45,
                  height: 45,
                  resizeMode: 'contain',
                }}
              />
              <View style={{marginLeft: 14}}>
                <Text
                  style={{
                    color: '#A7A8AA',
                    fontFamily: 'Jakarta',
                    fontSize: 14,
                    fontStyle: 'italic',
                    lineHeight: 16,
                  }}>
                  Creator
                </Text>
                <Text
                  style={{
                    color: constants.color.orange,
                    fontFamily: 'JakartaBold',
                    fontSize: 20,
                    lineHeight: 20,
                    marginTop: 3,
                  }}>
                  LET&apos;S ROAM
                </Text>
              </View>
            </View>
            <TouchableOpacity
              style={{
                borderWidth: 1,
                borderColor: isFavorited
                  ? constants.color.teal
                  : constants.color.black,
                backgroundColor: isFavorited
                  ? constants.color.teal
                  : 'transparent',
                paddingVertical: 10,
                maxWidth: 130,
                borderRadius: 5,
                flex: 1,
                justifyContent: 'center',
              }}
              onPress={() => {
                // favorite
                if (userId && wanderlistId) {
                  apiFavoriteLocation({
                    user_id: userId,
                    product_id: wanderlistId,
                    product_type: 'wanderlist',
                    favorited: !isFavorited,
                  });
                }
                const localDataRefetch = constants.localDataRefetch;
                localDataRefetch();

                // edit
                // navigationRef.navigate(
                //   'WanderlistAddLocation',
                //   {isNewWanderlist: false},
                //   'WanderlistCreate.tsx',
                // );
              }}>
              <Text
                style={{
                  textAlign: 'center',
                  marginVertical: 0,
                  fontFamily: 'Jakarta',
                }}>
                {`Favorite${isFavorited ? 'd' : ''}`}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{}}>
            {wanderlistStops?.map((stop, index) => (
              <WanderlistStop key={index} stop={stop} />
            ))}
          </View>
          <View>
            <Text
              style={{
                color: constants.color.black,
                fontFamily: 'JakartaBold',
                fontSize: 17,
                lineHeight: 16,
                marginTop: 24,
              }}>
              You might also like
            </Text>

            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}>
              {wanderlists?.map((wanderlist) => {
                const defaultPhotoURL =
                  'https://photos.letsroam.com/scavenger_hunt_locations/';

                const wanderlistPhoto =
                  wanderlist?.huntMediumPhotoURL?.includes('firebase')
                    ? wanderlist.huntMediumPhotoURL
                    : defaultPhotoURL + wanderlist?.huntMediumPhotoURL;

                console.log('wanderlistPhoto', wanderlistPhoto);
                return (
                  <WanderlistCardSmall
                    key={wanderlist.hunt_id}
                    imgSource={wanderlistPhoto}
                    title={wanderlist.name || 'Wander List'}
                  />
                );
              })}
            </View>
          </View>
        </View>
      </ScrollView>
      <ModalOmniBar
        left={{
          icon: 'arrow-left-circle',
          handlePress: () =>
            navigationRef.navigate(
              'Wanderlists',
              null,
              'LocalCreateLocationModal.tsx',
            ),
          text: 'Go back',
        }}
        containerStyle={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
    </SafeAreaView>
  );
}

interface WanderlistCardSmallProps {
  imgSource: string;
  title: string;
}

const WanderlistCardSmall: React.FC<WanderlistCardSmallProps> = ({
  imgSource,
  title,
}) => {
  return (
    <View
      style={{
        flexBasis: '31%',
        marginBottom: 16,
        alignItems: 'center',
        marginTop: 15,
      }}>
      <Image
        source={{uri: imgSource}}
        style={{
          height: 125,
          resizeMode: 'cover',
          borderRadius: 8,
          width: '100%',
        }}
      />
      <Text style={{textAlign: 'center', fontSize: 12, marginTop: 11}}>
        {title}
      </Text>
    </View>
  );
};

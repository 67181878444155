import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants'; /* eslint-disable no-undef */
import React, {useEffect} from 'react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import SimpleLineIcon from 'react-native-vector-icons/SimpleLineIcons';
import {Platform, View, Alert, useWindowDimensions, Image} from 'react-native';

import 'react-native-get-random-values';

import {statusCodes} from 'src/Utils/googleAuth';
import ExpoImage from 'src/Utils/ExpoImage';

/////////////////////
// Utils ////////////

import {
  configureGoogleSignIn,
  tryGoogleLogin,
  tryAppleLogin,
  tryFacebookLogout,
  tryFirebaseLogout,
  tryFacebookLogin,
} from 'src/Utils/loginFunctions';

//////////////////////
// Components ////////
import {BodyText, ContinueButton} from 'src/Modules/CommonView';
import {TouchableOpacity} from 'react-native';
import {loginAndCreateUser} from 'src/Utils/loginFunctions';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {setPreviousVisit} from 'src/Redux/reducers/current_screen.reducer';
import {useUserId} from 'src/Hooks/reduxHooks';
import {
  cloudFlareOptimizeImage,
  dispatchAction,
  getReduxState,
} from 'src/Utils/helpers';
import useLocalState from 'src/Hooks/useLocalState';
import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {logOutUser} from 'src/Redux/reducers/user_info.reducer';
import useParams from 'src/Hooks/useParams';
import {getDataAtPath} from 'src/Utils/fireUtils';
import {getCurrentScreen} from 'src/Nav/navigationHelpers';
import {TUser} from 'src/types/TUser';
import {TUserLogin} from 'src/types/TUserLogin';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';

const LoginModal: React.FC<{isFullScreen?: boolean} | undefined> = (props) => {
  const isFullScreen = props?.isFullScreen || false;

  const {message, navigateOnCloseRoute} = useParams<'LoginModal'>();

  const userId = useUserId();

  console.log({userId});

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );
  const hideFacebook = useTypedSelector(
    (state) => state.app_info?.hideFacebook,
  );
  const hideGoogle = useTypedSelector((state) => state.app_info?.hideGoogle);
  const hideText = useTypedSelector((state) => state.app_info?.hideText);
  const hideEmail = useTypedSelector((state) => state.app_info?.hideEmail);

  const hasPass = !!useExplorerPass();

  //whether or not the user has been greeted by the intro slider
  const introSliderViewed = getReduxState(
    (state) => state.user?.introSliderViewed,
  );

  // whether or not from groupon
  const [voucherRedemption] = useLocalState('voucher_redemption', false);
  // whether or not from groupon

  const previousVisit = useTypedSelector(
    (state) => state.current_screen?.previousVisit,
  );

  console.log('LoginModal', {voucherRedemption, previousVisit});

  useEffect(() => {
    if (!previousVisit) {
      console.log('setting previous visit');
      mmkvStorage.set('previousVisit', 'true');
      dispatchAction(setPreviousVisit(1));
    }
  }, []);

  useEffect(() => {
    if (!!userId) {
      // eslint-disable-next-line no-use-before-define
      getDataAtPath(`users/${userId}`)
        .then((userInfo) => {
          // eslint-disable-next-line no-use-before-define
          handleNavigationOnDone(userInfo as TUser, 'userId Effect');
        })
        .catch((getUserInfoError) => {
          console.log({getUserInfoError});
        });
    }
    try {
      configureGoogleSignIn();
      tryFirebaseLogout();
      // eslint-disable-next-line no-use-before-define
    } catch (error: unknown) {
      console.log(
        'Sign in failed',
        error instanceof Error ? error.message : String(error),
      );
    }
  }, [userId]);

  ////////////////////
  /// move to API calls...
  const enrollInNewsletter = async (
    email: string,
    first: string,
    last: string,
  ) => {
    try {
      const url = `https://www.letsroam.com/api/v1/newsletter/subscribe_no_drip?email=${email}&first_name=${first}&last_name=${last}&source=lr_app_login`;
      fetch(url);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const onFBClick = () => {
    console.log('facebook clicked');
    if (Platform.OS == 'web') {
      return alert('Only Google Sign In is supported on web demos.');
    }

    tryFacebookLogin()
      .then((facebookUser) => {
        console.log({facebookUser});
        if (facebookUser && facebookUser?.email) {
          const email = facebookUser?.email || '';
          const first_name = facebookUser?.firstName || '';
          const last_name = facebookUser?.lastName || '';
          const url = facebookUser?.imageURL || '';

          console.log('-----------------------------------------------');
          console.log('-----------------------------------------------');
          console.log('------------ the facebook user info -------------');
          console.log('-----------------------------------------------');
          console.log({facebookUser});
          // should send facebook token as well??
          // eslint-disable-next-line no-use-before-define
          loginUser({
            email: email || '',
            contactEmail: email,
            firstName: first_name || 'User',
            lastName: last_name || 'User',
            photoUrl: url || '',
          });
          enrollInNewsletter(email, first_name, last_name);
        } else {
          Alert.alert(
            'Can not Log In',
            'Could not log into facebook. If the error persists, please contact Support: 1 (833) 202-7626',
          );
          throw new Error('Unable to sign in');
        }
      })
      .catch((error) => {
        console.log('FB Login Error:', error);
        if (
          error &&
          (error + '')?.includes?.('User logged in as different Facebook user')
        ) {
          tryFacebookLogout();
          onFBClick();
        } else {
          let alertMessage = error ? JSON.stringify(error) : 'Login failed';

          alertMessage = error instanceof Error ? error.message : String(error);

          if (error.isCancelled) {
            // setLoading(false);
          } else {
            // eslint-disable-next-line no-use-before-define
            alertAndResetLoading('Facebook login error: ', alertMessage);
          }
        }
      });
  };
  const onAppleClick = async () => {
    console.log('apple clicked');
    if (Platform.OS == 'web') {
      return alert('Only Google Sign In is supported on web demos.');
    }
    const userInfo = await tryAppleLogin();
    // eslint-disable-next-line no-use-before-define
    if (userInfo && userInfo?.email != null) {
      loginUser(userInfo);
    } else {
      /* empty */
    }
  };
  const alertAndResetLoading = (message: string, secondLine: string) => {
    secondLine ? Alert.alert(message, secondLine) : Alert.alert(message);
  };

  const onGoogleClick = async () => {
    if (Platform.OS == 'web') {
      // eslint-disable-next-line no-use-before-define
      return mockLogin();
    }
    try {
      const userInfo = await tryGoogleLogin();
      if (userInfo?.data?.user) {
        const {user} = userInfo?.data;

        console.log('-----------------------------------------------');
        console.log('-----------------------------------------------');
        console.log('------------ the google user info -------------');
        console.log('-----------------------------------------------');
        console.log({userInfo});
        const email = user?.email || '';
        const familyName = user?.familyName || '';
        const givenName = user?.givenName || '';
        const photo = user?.photo || '';
        const id = user?.id || '';

        // eslint-disable-next-line no-use-before-define
        loginUser({
          email,
          contactEmail: email,
          firstName: givenName || 'User',
          lastName: familyName || 'User',
          googleId: id,
          photoUrl: photo || '',
        });
        enrollInNewsletter(email, givenName, familyName);
      } else {
        throw new Error('Unable to sign in');
      }
    } catch (error: unknown) {
      if (!(error instanceof Error) || !('code' in error)) {
        return false;
      }
      console.log({error});
      if (
        error.code === statusCodes.SIGN_IN_CANCELLED ||
        (error.message || '')?.includes?.('canceled')
      ) {
        //console.log('Sign in cancelled')
        return false;
      } else if (error.code === statusCodes.IN_PROGRESS) {
        //console.log('Sign in progress')
        return false;
      } else if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
        //console.log('No Play Services')
        return false;
      } else {
        //console.log('A google sign in error occurred', error)
        return false;
      }
    }
  };

  const handleNavigationOnDone = (userInfo: TUser, source: string) => {
    const currentScreen = getCurrentScreen();

    if (!userInfo) {
      const backupUserInfo = getReduxState((state) => state?.user?.info);
      if (backupUserInfo) {
        userInfo = backupUserInfo;
      } else {
        navigationRef.navigate('LandingPageMain', null, 'LoginModal 5');
        return;
      }
    }

    console.log(`handleNavigationOnDone: ${source}`, {userInfo});

    const hasCustomInfo =
      !!userInfo?.birthday ||
      !!userInfo?.home_zip_code ||
      !!userInfo?.gender ||
      !!userInfo?.contactPhone ||
      !!userInfo?.contactPhoneNonLogin;
    console.log({hasCustomInfo});
    const askForAdditionalInfo = playTestState == false && !hasCustomInfo;
    console.log({hasCustomInfo, userInfo});
    if (isFullScreen) {
      console.log('is FUll screen');
      if (
        askForAdditionalInfo &&
        playTestState == false &&
        !window?.location?.href?.includes?.('isDemo')
      ) {
        console.log(
          'handleNavigationOnDone: Going to LoginAdditionalInfoFullScreenPage',
        );
        return navigationRef.navigate(
          'LoginAdditionalInfoFullScreenPage',
          {
            showFreeTrialScreen: !hasPass && !!introSliderViewed,
          },
          'LandingPage/LoginModal.tsx',
        );
      } else {
        if (!hasPass && !!introSliderViewed) {
          navigationRef.navigate(
            playTestState == false ? 'FreeTrialScreen' : 'LandingPageMain',
            null,
            'LoginModal 1',
          );
        } else {
          navigationRef.navigate('LandingPageMain', null, 'LoginModal 1');
        }
      }
    } else if (navigateOnCloseRoute) {
      console.log('handleNavigationOnDone: Going to navigateOnCloseRoute');
      return navigationRef.navigate(
        navigateOnCloseRoute,
        null,
        'LoginModal 203',
      );
    } else {
      console.log(
        'handleNavigationOnDone: Going to LoginAdditionalInformationForm',
      );

      if (
        askForAdditionalInfo &&
        playTestState == false &&
        !window?.location?.href?.includes?.('isDemo') &&
        Platform.OS != 'web'
      ) {
        console.log('LoginAdditionalInformationForm');
        navigationRef.navigate(
          'LoginAdditionalInformationForm',
          null,
          'LandingPage/LoginModal.tsx',
        );
      } else if (currentScreen === 'EmailSignInBlockingPage') {
        if (!hasPass && !!introSliderViewed && playTestState == false) {
          navigationRef.navigate(
            playTestState == false ? 'FreeTrialScreen' : 'LandingPageMain',
            null,
            'LoginModal 1',
          );
        } else {
          navigationRef.navigate('LandingPageMain', null, 'LoginModal 1');
        }
      } else {
        navigationRef?.goBack('LandingPage/LoginModal.tsx');
      }
    }
  };

  const loginUser = async (params: TUserLogin) => {
    console.log('loginUser', {params});
    const userInfo = await loginAndCreateUser(params);
    console.log('loginAndCreateUser done', {userInfo});
    // eslint-disable-next-line no-use-before-define
    handleNavigationOnDone(userInfo as TUser, 'loginUser function');
  };

  const insets = useSafeAreaInsets();
  const {width} = useWindowDimensions();

  const mockLogin = () => {
    loginUser({
      email: 'test@letsroam.com',
      contactEmail: 'tes@letsroam.com',
      contactPhoneNonLogin: '+14078089624',
      firstName: 'Michael',
      lastName: 'Harding',
    });
  };

  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'flex-end',
        backgroundColor: 'red',
      }}>
      <View
        style={{
          flex: 1,
          backgroundColor: constants?.color.gray3,
          position: 'relative',
        }}>
        <ExpoImage
          source={{
            // explorers in front of globe
            uri: cloudFlareOptimizeImage(
              'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1683567099_large.jpg',
              720,
              100,
            ),
          }}
          style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}
          resizeMode={'cover'}
        />
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: constants?.color.gray3_30,
          }}
        />
        {!isFullScreen && (
          <TouchableOpacity
            onPress={() => {
              navigationRef.navigate('LandingPageMain', null, 'LoginModal2');
            }}>
            <SimpleLineIcon
              name={'close'}
              size={30}
              color={constants?.color.gray1}
              style={{marginTop: insets.top, marginLeft: 20}}
            />
          </TouchableOpacity>
        )}
        <View
          style={{
            flex: 1,
            width,
            paddingLeft: 70,
            paddingRight: 70,
            paddingBottom: 40,
            marginTop: !!isFullScreen ? insets.top + 20 : 0,
          }}>
          <Image
            source={require('src/Images/logo_stacked_orange.png')}
            style={{
              flex: 1,
              width: width - 100,
              resizeMode: 'contain',
            }}
          />
        </View>
        {/* <SafeAreaView>
         
        </SafeAreaView> */}
      </View>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: constants?.color.white1,
          paddingBottom: insets.bottom,
        }}>
        <View style={{alignItems: 'center'}}>
          <View style={{alignItems: 'center'}}>
            <BodyText
              text={message || 'Sign in to start your next adventure'}
              textStyle={{
                fontSize: message ? 20 : 16,
                color: constants?.color.gray3,
                textAlign: 'center',
                paddingBottom: 10,
                paddingTop: message ? 20 : 10,
              }}
            />
          </View>
          {!hideFacebook && (
            <ContinueButton
              handlePress={onFBClick}
              containerStyle={{
                width: width - 100,
                marginTop: 10,
                justifyContent: 'center',
              }}
              background={'#4267B2'}
              testID={'facebookButton'}
              text={'Continue With Facebook'}
              iconImage={require('src/Images/Icons/facebook_logo.png')}
              iconImageStyle={{}}
            />
          )}
          {!hideGoogle && (
            <ContinueButton
              handlePress={onGoogleClick}
              background={'#dd4b39'}
              containerStyle={{
                width: width - 100,
                marginTop: 10,
                justifyContent: 'center',
              }}
              testID={'googleButton'}
              text={'Continue With Google'}
              iconImage={require('src/Images/googleIcon.png')}
            />
          )}
          {!hideText && (
            <ContinueButton
              handlePress={() => {
                if (Platform.OS == 'web') {
                  return mockLogin();
                }
                return navigationRef.navigate(
                  'LoginModalTextForm',
                  {
                    loginUser: loginUser,
                    loginType: 'text',
                    facebookPress: () => {
                      navigationRef?.goBack('LandingPage/LoginModal.tsx');
                      onFBClick();
                    },
                    googlePress: () => {
                      navigationRef?.goBack('LandingPage/LoginModal.tsx');
                      onGoogleClick();
                    },
                    onAppleClick: () => {
                      navigationRef?.goBack('LandingPage/LoginModal.tsx');
                      onAppleClick();
                    },
                  },
                  'LoginModal Continue Press',
                );
              }}
              testID={'textButton'}
              background={constants?.color?.gray2}
              containerStyle={{
                width: width - 100,
                marginTop: 10,
                justifyContent: 'center',
              }}
              iconImage={require('src/Images/Icons/phone.png')}
              text={'Continue With Text'}
            />
          )}
          {!hideEmail && (
            <ContinueButton
              handlePress={() => {
                if (Platform.OS == 'web') {
                  return mockLogin();
                }
                return navigationRef.navigate(
                  'LoginModalTextForm',
                  {
                    loginUser: loginUser,
                    loginType: 'email',
                    facebookPress: () => {
                      navigationRef?.goBack('LandingPage/LoginModal.tsx');
                      onFBClick();
                    },
                    googlePress: () => {
                      navigationRef?.goBack('LandingPage/LoginModal.tsx');
                      onGoogleClick();
                    },
                    onAppleClick: () => {
                      navigationRef?.goBack('LandingPage/LoginModal.tsx');
                      onAppleClick();
                    },
                  },
                  'LoginModal Continue Press 432',
                );
              }}
              testID={'emailButton'}
              background={'#764abc'}
              containerStyle={{
                width: width - 100,
                marginTop: 10,
                justifyContent: 'center',
              }}
              iconImageStyle={{height: 16, width: 16}}
              text={'Continue with Email Link'}
              iconImage={require('src/Images/Icons/mail-2.png')}
            />
          )}
          {Platform.OS === 'ios' && (!!__DEV__ || !!playTestState) && (
            <ContinueButton
              handlePress={onAppleClick}
              background={'#000'}
              containerStyle={{
                width: width - 100,
                marginTop: 10,
                justifyContent: 'center',
              }}
              iconImageStyle={{height: 20, width: 20}}
              text={'Sign in with Apple'}
              iconImage={require('src/Images/Icons/apple.png')}
            />
          )}

          <TouchableOpacity
            onPress={() => {
              // Go to main modal stack
              dispatchAction(logOutUser());
              navigationRef.navigate('LandingPageMain', null, 'LoginModal3');
            }}
            style={{}}>
            <BodyText
              text={isFullScreen ? 'Skip' : 'Close'}
              textStyle={{
                fontSize: isFullScreen ? 14 : 16,
                color: constants?.color.gray3,
                textDecorationLine: 'underline',
                marginTop: 40,
                marginBottom: 5,
              }}
            />
          </TouchableOpacity>

          {/* mock function for login */}
          <TouchableOpacity
            style={{
              height: 10,
              width: 10,
              backgroundColor: __DEV__
                ? constants?.color.gray3_30
                : 'transparent',
              right: -45,
              top: 0,
              position: 'absolute',
            }}
            onPress={() => {
              setTimeout(() => {
                mockLogin();
              }, 500);
            }}
            testID={'mockLogin'}></TouchableOpacity>
        </View>
      </View>
    </View>
  );
};
//eslint-disable-next-line
export default LoginModal;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import {Alert, ScrollView, TouchableOpacity, View} from 'react-native';
import React from 'react';
import {joinHunt} from 'src/Utils/huntFunctions';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

/////////////////////////////////////////////
///////  components
import {BodyText} from 'src/Modules/CommonView';
import ExpoImage from 'src/Utils/ExpoImage';

import {useFirebaseData} from 'src/Utils/fireUtils';

import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {TPhoto} from 'src/types/TPhoto';
import {TPastGroup, isTPastGroup} from 'src/types/TPastGroup';

const PastHuntsModal: React.FC = () => {
  const userId = useTypedSelector((state) => state.user?.userId);

  const {width} = useWindowDimensionsSafe();

  // comes in as a string
  const past_hunts =
    useFirebaseData(
      `extendedUserData/${userId}/past_hunts`,
      null, // no side effect
      null, // no order by
      null, // no order by
      false, // hide logs
      !userId, // suppress if not userId
    ) || '{}';

  console.log({past_hunts});
  const formattedPastHunts: TPastGroup[] = [];
  if (past_hunts) {
    try {
      const decoded_hunts = JSON.parse(past_hunts);
      if (Array.isArray(decoded_hunts)) {
        decoded_hunts.map((pastHunt) => {
          if (isTPastGroup(pastHunt, true)) {
            formattedPastHunts.push(pastHunt);
          }
        });
      }
    } catch (error: unknown) {
      console.log('could not parse past hunts:', error);
    }
  }

  return (
    <ScrollView style={{}}>
      <View style={{display: 'flex', alignItems: 'center', marginBottom: 150}}>
        {!!formattedPastHunts &&
          formattedPastHunts?.map?.((past_hunt: TPastGroup, index) => {
            // console.log(past_hunt)
            const {
              huntPhotoURL = null,
              hunt_name = 'Scavenger Hunt',
              group_photo_count = 0,
              group_photos,
            } = past_hunt;
            let group_name = past_hunt?.group_name || '';
            if (
              !group_name?.includes?.('team') &&
              !group_name?.includes?.('Team')
            ) {
              group_name = 'Team: ' + group_name;
            }
            let formatted_group_photos: TPhoto[] | [] = [];
            if (group_photos) {
              try {
                formatted_group_photos = JSON.parse(group_photos);
              } catch {
                console.log('could not parse user photos');
              }
            }
            // console.log({formatted_group_photos})
            return (
              <TouchableOpacity
                key={`Past Hunts ${index} ${hunt_name}`}
                activeOpacity={0.8}
                style={{
                  backgroundColor: 'black',
                  width: width - 40,
                  height: 110,
                  marginTop: 20,
                  position: 'relative',
                }}
                onPress={() => {
                  const options = {
                    handleConfirm: async () => {
                      navigationRef.goBack('Past hunts');
                      if (!formatted_group_photos) {
                        return Alert.alert(
                          'No Photos',
                          'There are no photos available for this hunt. Contact support if you have taken photos.',
                        );
                      }
                      navigationRef.navigate(
                        'PhotosModal',
                        {
                          photoData: formatted_group_photos,
                        },
                        'PastHunts',
                      );
                    },
                    confirmText: 'See Hunt Photos',
                    cancelText: 'Rejoin Hunt',
                    handleCancel: async () => {
                      console.log({past_hunt});

                      await joinHunt(
                        past_hunt.group_id || '', // group id
                        userId || '', // user id
                        'Rejoining Hunt', //loading reason
                      );
                    },
                    thirdOption: 'Cancel',
                    handleThird: () => {
                      navigationRef.goBack('Past Hunts');
                    },
                    confirmColor: constants?.color?.orange,
                    cancelColor: constants?.color?.blue,
                    titleImage: {
                      uri: cloudFlareOptimizeImage(huntPhotoURL || ''),
                    },
                    titleText: group_name,
                    titleBodyText:
                      'What info do you want to see about this hunt?',
                  };

                  navigationRef.navigate(
                    'ConfirmModal',
                    options,
                    'PastHunts 2',
                  );
                  // Alert.alert('hi')
                }}>
                <ExpoImage
                  resizeMode={'cover'}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  source={{uri: cloudFlareOptimizeImage(huntPhotoURL || '')}}
                />
                <View
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: constants?.color?.gray3_50,
                    overflow: 'hidden',
                  }}>
                  <BodyText
                    text={hunt_name || 'Scavenger Hunt'}
                    textStyle={{
                      textAlign: 'center',
                      fontWeight: '700',
                      marginBottom: 10,
                      color: 'white',
                      fontSize: 24,
                      lineHeight: 24,
                    }}
                  />
                  <BodyText
                    text={group_name}
                    textStyle={{
                      textAlign: 'center',
                      fontWeight: '700',
                      marginBottom: 10,
                      color: 'white',
                      fontSize: 22,
                      lineHeight: 20,
                    }}
                  />
                  <BodyText
                    text={`View Hunt Photos (${group_photo_count})`}
                    textStyle={{
                      position: 'absolute',
                      left: -20,
                      right: -20,
                      bottom: 0,
                      height: 30,
                      backgroundColor: constants?.color?.gray3_80,
                      textAlign: 'center',
                      color: 'white',
                      paddingTop: 8,
                      fontSize: 16,
                      fontWeight: '700',
                    }}
                  />
                </View>
              </TouchableOpacity>
            );
          })}
        {!formattedPastHunts && (
          <View style={{flex: 1, alignContent: 'center'}}>
            <BodyText
              text={
                'No Previous Scavenger Hunts Detected! Swipe down to refresh this screen!'
              }
              center
              textStyle={{fontSize: 20, lineHeight: 20, marginTop: 20}}
            />
          </View>
        )}
      </View>
    </ScrollView>
  );
};

export default PastHuntsModal;

import {FirebaseDynamicLinksTypes} from '@react-native-firebase/dynamic-links';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {TNavigationRouteParams} from 'src/types/TNavigationRouteParams';

interface FirebaseListeners {
  [listenerId: string]: string;
}

interface CurrentScreenState {
  currentScreen: keyof TNavigationRouteParams;
  previousScreen: keyof TNavigationRouteParams;
  previousPreviousScreen: keyof TNavigationRouteParams;
  alertOpen: boolean;
  loadingIndicatorOpen: string | false;
  previousVisit: number;
  delayedDeepLink: FirebaseDynamicLinksTypes.DynamicLink | null;
  hasSeenTurtorial: boolean;
  currentScreenChanges: number;
  roundedCurrentScreenChanges: number;
  hasCameraPermission: boolean;
  autoSavePhotos: boolean;
  photoTaken: number;
  firebaseListeners: FirebaseListeners;
  isDrawerOpen: boolean;
  appStateVisible: 'active' | 'background' | 'inactive' | 'unknown';
}

const initialState: CurrentScreenState = {
  currentScreen: 'AppLoadingPage',
  previousScreen: 'AppLoadingPage',
  previousPreviousScreen: 'AppLoadingPage',
  alertOpen: false,
  loadingIndicatorOpen: false,
  previousVisit: 0,
  delayedDeepLink: null,
  hasSeenTurtorial: false,
  currentScreenChanges: 0,
  roundedCurrentScreenChanges: 0,
  hasCameraPermission: false,
  autoSavePhotos: true,
  photoTaken: 0,
  firebaseListeners: {},
  isDrawerOpen: false,
  appStateVisible: 'active',
};

const currentScreenSlice = createSlice({
  name: 'current_screen',
  initialState,
  reducers: {
    setCurrentScreen(
      state,
      action: PayloadAction<keyof TNavigationRouteParams>,
    ) {
      if (action.payload !== state.currentScreen) {
        try {
          state.currentScreenChanges = state.currentScreenChanges + 1;
          state.roundedCurrentScreenChanges = Math.floor(
            state.currentScreenChanges / 10,
          );
        } catch (e) {
          console.warn('setCurrentScreen error', e);
          state.currentScreenChanges = 0;
          state.roundedCurrentScreenChanges = 0;
        }
      }
      state.previousPreviousScreen = state.previousScreen;
      state.previousScreen = state.currentScreen;
      state.currentScreen = action.payload;
    },
    setAlertOpen(state, action: PayloadAction<boolean>) {
      state.alertOpen = action.payload;
    },
    setPreviousVisit(state, action: PayloadAction<number>) {
      state.previousVisit = action.payload;
    },
    setLoadingIndicatorOpen(state, action: PayloadAction<string | false>) {
      state.loadingIndicatorOpen = action.payload;
    },
    setDelayedDeepLink(
      state,
      action: PayloadAction<FirebaseDynamicLinksTypes.DynamicLink | null>,
    ) {
      state.delayedDeepLink = action.payload;
    },
    setHasSeenTutorial(state, action: PayloadAction<boolean>) {
      state.hasSeenTurtorial = action.payload;
    },
    setHasCameraPermissions(state, action: PayloadAction<boolean>) {
      state.hasCameraPermission = action.payload;
    },
    setAutoSavePhotos(state, action: PayloadAction<boolean>) {
      state.autoSavePhotos = action.payload;
    },
    setPhotoTaken(state, action: PayloadAction<number>) {
      state.photoTaken = action.payload;
    },
    saveFirebaseListener(state, action: PayloadAction<FirebaseListeners>) {
      state.firebaseListeners = {
        ...state.firebaseListeners,
        ...action.payload,
      };
    },
    deleteFirebaseListener(state, action: PayloadAction<number>) {
      const newFirebaseListeners = {...state.firebaseListeners};
      delete newFirebaseListeners[action.payload];
      state.firebaseListeners = newFirebaseListeners;
    },
    setIsDrawerOpen(state, action: PayloadAction<boolean>) {
      state.isDrawerOpen = action.payload;
    },
    setReduxAppStateVisible(state, action: PayloadAction<string>) {
      state.appStateVisible =
        action.payload as CurrentScreenState['appStateVisible'];
    },
  },
});

export const {
  setCurrentScreen,
  setAlertOpen,
  setLoadingIndicatorOpen,
  setPreviousVisit,
  setDelayedDeepLink,
  setHasSeenTutorial,
  setHasCameraPermissions,
  setAutoSavePhotos,
  setPhotoTaken,
  saveFirebaseListener,
  deleteFirebaseListener,
  setIsDrawerOpen,
  setReduxAppStateVisible,
} = currentScreenSlice.actions;

export default currentScreenSlice.reducer;

import React, {FC} from 'react';
import {View, Text} from 'react-native';
import ExpoImage from 'src/Utils/ExpoImage';

interface SimpleUserIconProps {
  userPhoto?: string | null;
  userName?: string;
  style?: {height?: number};
}

const SimpleUserIcon: FC<SimpleUserIconProps> = ({
  userPhoto = null,
  userName = '',
  style = {},
}) => {
  const size = style?.height || 25;

  return (
    <View>
      <View
        style={{
          width: size,
          height: size,
          backgroundColor: 'green',
          borderRadius: 12.5,
          position: 'relative',
        }}>
        <Text
          style={{
            color: 'white',
            fontSize: 16,
            lineHeight: size,
            width: size,
            textAlign: 'center',
          }}>
          {userName?.charAt?.(0)?.toUpperCase?.() || 'U'}
        </Text>
        {userPhoto ? (
          <ExpoImage
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: size / 2,
            }}
            source={{uri: userPhoto}}
            resizeMode="contain"
          />
        ) : null}
      </View>
    </View>
  );
};

export default SimpleUserIcon;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState, useEffect, useCallback} from 'react';
import {TextStyle, View, ViewStyle} from 'react-native';

import LineIcon from 'react-native-vector-icons/SimpleLineIcons';

import {BodyText} from 'src/Modules/CommonView/Text';

interface TimerProps {
  seconds: number;
  countUp: boolean;
  startTime: number;
  onComplete: () => void;
  highlightTimeLimit?: number;
  badge?: string; // You might want to specify a more specific type if 'badge' has specific values
  containerStyle?: ViewStyle;
  textStyle?: TextStyle;
}

const Timer: React.FC<TimerProps> = ({
  seconds: timerSeconds,
  countUp,
  startTime,
  onComplete,
  highlightTimeLimit,
  badge,
  containerStyle = {},
  textStyle = {},
}) => {
  // console.log('game timer', {
  //   startTime,
  //   timerSeconds,
  // });
  const calculateTimeRemaining = useCallback(() => {
    return timerSeconds - (Date.now() - startTime) / 1000;
  }, [startTime, timerSeconds]);

  const [timeRemaining, setTimeRemaining] = useState<number>(
    calculateTimeRemaining(),
  );
  const [highlight, setHighlight] = useState<boolean>(false);

  const handleTimerDone = () => {
    onComplete && onComplete?.();
  };

  const huntPaused = useTypedSelector(
    (state) => state?.group?.info?.huntPaused,
  );

  const calculateAndSetTimeRemaining = useCallback(
    (clear: () => void) => {
      // don't finish if hunt paused
      if (huntPaused) {
        return 100;
      }

      const newTimeRemaining = calculateTimeRemaining();
      // console.log({newTimeRemaining});
      if (newTimeRemaining > 0) {
        const newHighlight = !highlightTimeLimit
          ? false
          : countUp
          ? highlightTimeLimit < timeRemaining
          : highlightTimeLimit > timeRemaining;

        setHighlight(newHighlight);
      } else {
        handleTimerDone();
        clear();
      }
      setTimeRemaining(newTimeRemaining);
    },
    [startTime, timerSeconds, huntPaused],
  );

  useEffect(() => {
    const timer = setInterval(() => {
      calculateAndSetTimeRemaining(() => {
        clearInterval(timer);
      });
    }, 1000);

    return () => {
      timer && clearInterval(timer);
    };
  }, [huntPaused, timerSeconds]);

  const formatTimeMinutes = (timeRemaining: number) => {
    const timeMinutes =
      (timeRemaining &&
        timeRemaining > 0 &&
        `${Math?.floor(timeRemaining / 60)}`) ||
      '00';
    return timeMinutes?.length <= 1 ? `0${timeMinutes}` : timeMinutes;
  };

  const formatTimeSeconds = (timeRemaining: number) => {
    const timeSeconds =
      (timeRemaining &&
        timeRemaining > 0 &&
        `${Math?.floor(timeRemaining) % 60}`) ||
      '00';
    return timeSeconds?.length <= 1 ? `0${timeSeconds}` : timeSeconds;
  };

  const formatTime = (timeRemaining: number) => {
    const timeMinutes = formatTimeMinutes(timeRemaining);
    const timeSeconds = formatTimeSeconds(timeRemaining);
    return `${timeMinutes}:${timeSeconds}`;
  };

  let timeFormatted = formatTime(Math?.floor(timeRemaining));

  if (timeFormatted == '00:00') {
    timeFormatted = '';
  }
  return (
    <View
      style={{
        alignItems: 'flex-end',
        backgroundColor: constants?.color?.yellow,
        flexDirection: 'row',
        justifyContent: 'center',
        minWidth: 100,
        paddingHorizontal: 5,
        paddingVertical: 5,
        position: 'relative',
        ...containerStyle,
        ...(highlight ? {backgroundColor: constants?.color?.red} : {}),
      }}>
      <LineIcon
        name={'clock'}
        size={16}
        color={
          highlight
            ? constants?.color?.white
            : textStyle.color || constants?.color?.gray3
        }
      />
      <BodyText
        text={timeFormatted}
        textStyle={{
          marginTop: 0,
          minWidth: 44,
          marginRight: 0,
          ...textStyle,
          ...(highlight ? {color: constants?.color?.white} : {}),
        }}
      />
      {!!badge && (
        <View
          style={{
            alignItems: 'center',
            backgroundColor: constants?.color?.red,
            borderRadius: 5.5,
            height: 11,
            position: 'absolute',
            top: -4,
            left: -4.5,
            width: 11,
          }}
        />
      )}
    </View>
  );
};

export default Timer;

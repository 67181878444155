import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {FunctionComponent} from 'react';
import {View, Text, TouchableOpacity, Platform, StyleSheet} from 'react-native';
import ExpoImage from 'src/Utils/ExpoImage';
import {useUserId} from 'src/Hooks/reduxHooks';
const signInImage = require('src/Images/signInImage.png');

interface UserProfileIconProps {
  handleSignInPress?: () => void;
  handleIconPress?: () => void;
  color?: string;
  deletable?: boolean;
  addable?: boolean;
  showName?: boolean;
  isTeamIcon?: boolean;
  textColor?: string;
  imageStyle?: object;
  containerStyle?: object;
  score?: number;
  rank?: string;
  handlePress?: () => void;
  showCameraIcon?: boolean;
  nameOverride?: string;
  user?: {
    info: {
      photoUrl?: string | null;
    };
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: 95,
    elevation: 4,
    shadowColor: Platform.OS !== 'web' ? 'black' : 'transparent',
    shadowOffset: {width: 0, height: 0},
    shadowRadius: 6,
    shadowOpacity: 0.25,
  },
  profileImage: {
    height: 68,
    width: 68,
    borderRadius: 34,
    borderWidth: 3,
    borderColor: constants?.color?.yellow,
  },
  nameWrap: {
    justifyContent: 'center',
    height: 20,
  },
  userName: {
    textAlign: 'center',
    color: constants?.color?.gray3,
    justifyContent: 'center',
    fontFamily: constants?.font.circular,
    marginTop: 2,
    fontSize: 12,
    fontWeight: '700',
  },
});

const UserProfileIcon: FunctionComponent<UserProfileIconProps> = ({
  handleSignInPress,
  handleIconPress,
  color,
  deletable,
  addable = false,
  showName,
  isTeamIcon = false,
  textColor = constants?.color?.gray3,
  imageStyle,
  containerStyle,
  score,
  rank,
  handlePress,
  showCameraIcon = false,
  nameOverride,
  user,
}) => {
  const userId = useUserId();
  const userNameFromState = useTypedSelector(
    (state) => state?.user?.info?.firstName,
  );
  let userName = nameOverride || userNameFromState || 'Log In';
  userName = userName?.length > 15 ? userName.slice(0, 13) + '..' : userName;
  const pressHandler = handleSignInPress || handlePress;
  const imageSource = useTypedSelector(
    (state) =>
      state.user?.info?.customPhotoUrl ||
      state.user?.info?.photoUrl ||
      signInImage,
  );

  const iconColor = color || constants?.color?.blue;
  const portraitColor =
    useTypedSelector((state) => state?.user?.info?.portraitColor) ||
    constants?.color?.blue;

  // user.info.photoUrl will be a user photo or a team photo
  // if we don't have that and it's a team, show default LR signInImage
  const userPortrait = () => (
    <ExpoImage
      source={user?.info?.photoUrl || (isTeamIcon ? signInImage : imageSource)}
      style={{
        ...styles.profileImage,
        position: 'absolute',
        top: 0,
        borderColor: iconColor,
        ...imageStyle,
      }}
    />
  );

  const colorPortrait = () => {
    const loggedIn = !userId;

    // userPortrait and colorPortrait absolutely position over default
    //if neither conditions met, default ExpoImage below shown
    if (loggedIn) {
      return (
        <View
          style={{
            ...styles.profileImage,
            position: 'absolute',
            top: 0,
            borderColor: iconColor,
            ...imageStyle,
            backgroundColor: portraitColor,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text style={{color: 'white', fontSize: 30}}>
            {userName.charAt(0).toUpperCase?.()}
          </Text>
        </View>
      );
    }
  };

  return (
    <TouchableOpacity
      onPress={pressHandler}
      activeOpacity={0.9}
      disabled={!pressHandler && !handleIconPress && !handleSignInPress}>
      {!!rank && (
        <Text
          style={{
            fontFamily: constants?.font.circular,
            fontSize: 14,
            color: constants?.color?.gray3,
            textAlign: 'center',
          }}>
          {rank}
        </Text>
      )}

      <View style={{...styles.container, ...containerStyle}}>
        <ExpoImage
          source={signInImage}
          style={{
            ...styles.profileImage,
            borderColor: iconColor,
            ...imageStyle,
          }}
        />

        {
          //create user profileImage
          //use photoUrl if it exists, otherwise create an icon with background color and Name
          !!imageSource ? userPortrait() : colorPortrait()
        }

        {!!showName && !showCameraIcon && (
          <View style={styles.nameWrap}>
            <Text style={{...styles.userName, color: textColor}}>
              {userName}
            </Text>
          </View>
        )}
        {!!showCameraIcon && (
          <View style={styles.nameWrap}>
            <Text
              style={{
                ...styles.userName,
                marginTop: 5,
                fontSize: 14,
                color: constants?.color?.blue,
              }}>
              {'Change Profile Photo'}
            </Text>
          </View>
        )}

        {!!score && (
          <Text
            style={{
              fontFamily: constants?.font.circular,
              fontSize: 10,
              color: constants?.color?.gray3,
            }}>
            {'Score: ' + score}
          </Text>
        )}

        {!!addable && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 10,
              width: 20,
              height: 20,
              backgroundColor: constants?.color?.white,
              borderRadius: 10,
              borderColor: iconColor,
              borderWidth: 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text
              style={{
                color: iconColor,
                lineHeight: 16,
                textAlign: 'center',
                fontSize: 14,
                fontFamily: constants?.font.circular,
              }}>
              +
            </Text>
          </View>
        )}

        {!!deletable && (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 10,
              width: 20,
              height: 20,
              backgroundColor: constants?.color?.white,
              borderRadius: 10,
              borderColor: iconColor,
              borderWidth: 2,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Text
              style={{
                color: iconColor,
                lineHeight: 16,
                textAlign: 'center',
                fontSize: 14,
                fontFamily: constants?.font.circular,
              }}>
              x
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default UserProfileIcon;

import React from 'react';
import {View, Image, Text} from 'react-native';
import constants from 'src/constants';

interface CreatorProps {
  name?: string;
  image?: string;
  width: number;
  nameStyle?: object;
}

export const Creator: React.FC<CreatorProps> = ({
  name = "LET'S ROAM",
  image = require('src/Images/signInImage.png'),
  width = 45,
  nameStyle = {},
}) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      <Image
        source={image}
        style={{
          width: width,
          height: width,
          resizeMode: 'contain',
          borderRadius: width / 2,
        }}
      />
      <View style={{marginLeft: 14}}>
        <Text
          style={{
            color: '#A7A8AA',
            fontFamily: 'Jakarta',
            fontSize: 12,
            fontStyle: 'italic',
            lineHeight: 14,
          }}>
          Creator
        </Text>
        <Text
          style={{
            color: constants.color.orange,
            fontFamily: 'JakartaBold',
            fontSize: 16,
            lineHeight: 18,
            marginTop: 3,
            ...nameStyle,
          }}>
          {name}
        </Text>
      </View>
    </View>
  );
};

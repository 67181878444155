import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {setLoadingIndicatorOpen} from 'src/Redux/reducers/current_screen.reducer';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {dispatchAction} from 'src/Utils/helpers';

const LoadingIndicator = () => {
  const loadingIndicatorOpen = useTypedSelector(
    (state) => state.current_screen.loadingIndicatorOpen,
  );

  // autoclosing
  useEffect(() => {
    if (loadingIndicatorOpen) {
      setTimeout(() => {
        dispatchAction(setLoadingIndicatorOpen(false));
      }, 7000);
    }
  }, [loadingIndicatorOpen]);

  if (!loadingIndicatorOpen) {
    return <View style={{}}></View>;
  }

  return (
    <View
      pointerEvents={'box-none'}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: constants?.color.gray3_30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <View
        style={{
          backgroundColor: constants?.color.orange,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 20,
          padding: 20,
        }}>
        <Text style={{color: 'white', fontSize: 20, paddingBottom: 10}}>
          {typeof loadingIndicatorOpen == 'string'
            ? loadingIndicatorOpen
            : 'Loading'}
        </Text>
        <CustomActivityIndicator color={'white'} size={30} />
      </View>
    </View>
  );
};

export default LoadingIndicator;

import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import {Platform, Text, TouchableOpacity, View} from 'react-native';

import ExpoImage from 'src/Utils/ExpoImage';
import React from 'react';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {useUserId} from 'src/Hooks/reduxHooks';
import {useExplorerPass} from 'src/Utils/explorerPassHelper';
import getAppType from 'src/Utils/getAppType';

// Define the type for the component's props
type FoxShopIconProps = {
  coinsTestID?: string;
};

const ticketsImage = require('src/Images/standardTicketIcon.png');
// single coin with fox in profile direction

// Define the functional component with its props
const FoxShopIcon: React.FC<FoxShopIconProps> = () => {
  const tickets = useTypedSelector(
    (state) => state.user?.info?.tickets,
    deepEqualityCheck,
  );

  // tracks if user has analytics tracked
  const googleAnalyticsPurchaseLogged = useTypedSelector(
    (state) => state.user?.info?.googleAnalyticsPurchaseLogged,
  );

  const userId = useUserId();
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const pass = useExplorerPass();

  const hasValidExplorerPass = !!pass;

  const handleShopPress = () => {
    !!userId
      ? hasValidExplorerPass
        ? navigationRef.navigate('ProfilePage', null, 'FoxShopIcon')
        : getAppType() == 'aa'
        ? navigationRef.navigate('PurchaseModal', {}, 'FoxShopIcon')
        : !!allTicketCount
        ? navigationRef.navigate(
            'ConfirmModal',
            {
              handleCancel: async () => {
                navigationRef.navigate('PurchaseModal', {}, 'FoxShopIcon');
              },
              titleText: `You have ${allTicketCount} tickets`,
              titleBodyText: `You have enough tickets to start a group. To get started navigate to a hunt and click "Start Creating A Group".`,
              cancelText: 'Open Store To Buy More Tickets',
              confirmText: 'Close Store',
              confirmColor: constants?.color?.orange,
              cancelColor: constants?.color?.gray3,
            },
            'FoxShopIcon',
          )
        : navigationRef.navigate('PurchaseModal', {}, 'FoxShopIcon')
      : navigationRef.navigate(
          'LoginModal',
          {
            message: 'You must log in to see your tickets',
          },
          'FoxShopIcon',
        );
  };

  let allTicketCount = 0;
  if (tickets) {
    try {
      const ticketsQuanties = Object.values(tickets);
      ticketsQuanties?.map?.((quantity) => {
        allTicketCount += parseInt(quantity + '');
      });
    } catch (e) {
      // can't parse tickets
    }
  }

  const utm_source = useTypedSelector((state) => state.app_info?.utm_source);
  const utmTestID = !!utm_source ? `utm_source-${utm_source}` : undefined;

  console.log('FoxShopIcon rerender', {utm_source, utmTestID});

  if (playTestState) {
    return <View></View>;
  }

  return (
    <TouchableOpacity
      testID={'foxShopIcon'}
      onPress={() => handleShopPress()}
      style={{
        marginHorizontal: 10,
        paddingBottom: 3,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <View testID={utmTestID}>
        <View
          style={{marginRight: 4}}
          testID={
            googleAnalyticsPurchaseLogged
              ? 'googleAnalyticsPurchaseLogged'
              : undefined
          }>
          {!hasValidExplorerPass && getAppType() == 'lr' && (
            <View
              testID="tickets"
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Text
                testID={'ticketCount'}
                style={{
                  color: constants?.color?.gray3,
                  fontFamily: constants?.font.circular,
                  fontSize: 16,
                  textAlign: 'right',
                  marginLeft: 9,
                }}>
                {allTicketCount}
              </Text>
              <ExpoImage
                style={{height: 40, width: 40}}
                source={ticketsImage}
                resizeMode={'contain'}
              />
            </View>
          )}
          {!!hasValidExplorerPass && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Text
                testID={'isMember'}
                style={{
                  color: constants?.color?.yellow,
                  fontFamily: constants?.font.circularBold,
                  fontSize: 16,
                  textAlign: Platform.OS === "web" ? "left" : "right",
                  marginLeft: Platform.OS === "web" ? 0 : 9,
                  width: 70,
                }}>
                Member
              </Text>
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default FoxShopIcon;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {TouchableOpacity, View, Text} from 'react-native';
import ExpoImage from 'src/Utils/ExpoImage';

////////////// utils

import {formatNumberDisplayAmount} from 'src/Utils/formatHelper';

/////////////// components
import {navigationRef} from 'src/Nav/navigationHelpers';
import {BodyText, ClassicIconButton} from 'src/Modules/CommonView';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {usePercentageComplete} from 'src/Hooks/gameHooks';
import getAppType from 'src/Utils/getAppType';

interface HeaderProps {
  mini?: boolean;
}

const Header: React.FC<HeaderProps> = ({mini}) => {
  const classicChallengeList =
    useTypedSelector((state) => state.game_v2?.classicChallengeList) || [];

  const indoor_hunt = useTypedSelector(
    (state) => state.group?.info?.indoor_hunt,
  );
  const teamName =
    useTypedSelector((state) => state.group?.info?.teamName) || '';
  const groupPhoto =
    useTypedSelector((state) => state.group?.info?.groupPhoto) || '';
  const score = useTypedSelector((state) => state.group?.info?.score) || 0;
  const companyColor = useTypedSelector(
    (state) => state.event?.info?.branding?.eventColor,
  );

  if (!classicChallengeList) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: constants?.color?.white,
        }}>
        <CustomActivityIndicator />
      </View>
    );
  }

  const completionPercent = usePercentageComplete() || 0;

  const testID = `${completionPercent.toFixed(0)}Complete`;

  console.log({testID});

  if (mini) {
    return (
      <View
        testID={testID}
        style={{
          borderTopWidth: 1,
          borderTopColor: constants?.color?.white1,
          backgroundColor: constants?.color?.white,
          borderBottomLeftRadius: 20,
          borderBottomRightRadius: 20,
          position: 'relative',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
          paddingVertical: 5,
          paddingHorizontal: 10,
        }}>
        <ClassicIconButton
          handlePress={() =>
            navigationRef.navigate(
              'ConfirmModal',
              {
                titleText: '',
                confirmText: `Start Challenges`,
                showCloseIcon: true,
                children: (
                  <View>
                    <BodyText
                      textStyle={{fontWeight: '700'}}
                      text="Objective"
                    />
                    <BodyText text="For your spot on the leaderboard earn as many points as possible by finding clues hidden in locations around town and completing all the challenges in time." />
                    <BodyText
                      textStyle={{fontWeight: '700'}}
                      text="Some Rules For Your Hunt"
                    />
                    <BodyText
                      textStyle={{paddingLeft: 10}}
                      text="1. First find the location and then solve challenges to earn points."
                    />
                    <BodyText
                      textStyle={{paddingLeft: 10}}
                      text="2. Earn points by answering questions at each location as quickly as possible and as accurately as possible."
                    />
                    <BodyText
                      textStyle={{paddingLeft: 10}}
                      text="3. When you are done with your first site move on to the next one. It is not a race between sites so take your time."
                    />
                    <BodyText
                      textStyle={{paddingLeft: 10}}
                      text="4. Feel free to pause your hunt to take a break or explore."
                    />
                  </View>
                ),
              },
              'ScavengerHunt_v2/Game/HuntIntro.tsx',
            )
          }
          text={`${formatNumberDisplayAmount(score)} pts`}
          icon="flash"
          color="teal"
          iconColor="white"
        />
        <View>
          <BodyText
            text={teamName}
            textStyle={{
              fontWeight: '700',
              textAlign: 'center',
              fontSize: 18,
              lineHeight: 20,
            }}
          />
          <BodyText
            text={`${completionPercent.toFixed(0)}Complete`}
            textStyle={{
              color: constants?.color?.gray2,
              marginTop: 10,
              marginBottom: 5,
              textAlign: 'center',
            }}
          />
        </View>
        {!indoor_hunt && (
          <ClassicIconButton
            handlePress={() =>
              navigationRef.navigate(
                'NavigationChallengeModal',
                {},
                'ScavengerHunt_v2/Classic/Header.tsx',
              )
            }
            text="Map"
            icon="map-o"
            color="blue"
            iconColor="white"
          />
        )}
        {!!indoor_hunt && (
          <ClassicIconButton
            handlePress={() =>
              navigationRef.navigate(
                'PlayerDashboard_v2',
                null,
                'ScavengerHunt_v2/Classic/Header.tsx',
              )
            }
            text="Dashboard"
            icon="info"
            color="blue"
            iconColor="white"
          />
        )}
      </View>
    );
  }

  return (
    <View
      testID={testID}
      style={{
        borderTopWidth: 1,
        borderTopColor: constants?.color?.white1,
        backgroundColor: constants?.color?.white,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        position: 'relative',
      }}>
      <View style={{paddingTop: 5, paddingBottom: 10}}>
        <BodyText
          text={teamName}
          textStyle={{
            fontWeight: '700',
            textAlign: 'center',
            fontSize: 18,
            lineHeight: 20,
          }}
        />
      </View>
      <View style={{flexDirection: 'row', height: 120}}>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 10,
          }}>
          <ClassicIconButton
            handlePress={() =>
              navigationRef.navigate(
                'PlayerScoreModal',
                null,
                'ScavengerHunt_v2/Classic/Header.tsx',
              )
            }
            text={`${formatNumberDisplayAmount(score)} pts`}
            icon="flash"
            color="blue"
            colorOverride={companyColor}
            iconColor={'white'}
          />
        </View>
        <View
          style={{
            flex: 1,
            minWidth: 120,
            maxWidth: 160,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <ProgressBar
            percentage={completionPercent}
            centerImageUrl={groupPhoto}
            handlePress={() =>
              navigationRef.navigate(
                'PlayerDashboard_v2',
                null,
                'ScavengerHunt_v2/Classic/Header.tsx',
              )
            }
          />
        </View>
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
          }}>
          {!indoor_hunt && (
            <ClassicIconButton
              testID="mapButton"
              handlePress={() =>
                navigationRef.navigate(
                  'NavigationChallengeModal',
                  {},
                  'ScavengerHunt_v2/Classic/Header.tsx',
                )
              }
              text="Map"
              icon="map-o"
              color={'blue'}
              colorOverride={companyColor}
              iconColor={'white'}
            />
          )}
          {!!indoor_hunt && (
            <ClassicIconButton
              handlePress={() =>
                navigationRef.navigate(
                  'PlayerDashboard_v2',
                  null,
                  'ScavengerHunt_v2/Classic/Header.tsx',
                )
              }
              text="Dashboard"
              icon="info"
              color="blue"
              iconColor={'white'}
            />
          )}
        </View>
      </View>
      <View>
        <BodyText
          text={
            completionPercent > 95
              ? 'Great Job! You Can Now Finish Your Hunt'
              : `${completionPercent.toFixed(0)}% Complete`
          }
          textStyle={{
            color: constants?.color?.gray2,
            marginTop: 15,
            marginBottom: 15,
            textAlign: 'center',
          }}
        />
      </View>
    </View>
  );
};

interface ProgressBarProps {
  percentage: number;
  centerImageUrl?: string;
  handlePress?: () => void;
  size?: number;
  lineWidth?: number;
  innerText?: string | number;
}
export const ProgressBar: React.FC<ProgressBarProps> = ({
  percentage,
  centerImageUrl,
  handlePress,
  size = 110,
  lineWidth = 7,
  innerText,
}) => {
  const deg = (percentage / 100) * 360;

  const isLR = getAppType() === 'lr';

  return (
    <TouchableOpacity
      onPress={handlePress}
      disabled={!handlePress}
      activeOpacity={0.9}>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: isLR ? constants?.color?.orange : '#9A5D70',
          width: size,
          height: size,
          borderRadius: size / 2,
          position: 'relative',
        }}>
        <View
          style={{
            position: 'absolute',
            width: size,
            height: size,
            transform: [
              {
                rotate: percentage <= 50 ? `${deg}deg` : '0deg',
              },
            ],
          }}>
          <View
            style={{
              overflow: 'hidden',
              backgroundColor: constants?.color?.white1,
              borderTopRightRadius: size,
              borderBottomRightRadius: size,
              position: 'relative',
              width: size / 2,
              height: size,
              marginLeft: size / 2,
            }}
          />
        </View>
        <View
          style={{
            position: 'absolute',
            width: size,
            height: size,
            transform: [
              {
                rotate: percentage > 50 ? `${deg - 180}deg` : '0deg',
              },
            ],
          }}>
          <View
            style={{
              overflow: 'hidden',
              backgroundColor: constants?.color?.white1,
              borderTopLeftRadius: size,
              borderBottomLeftRadius: size,
              width: size / 2,
              height: size,
              marginRight: size / 2,
            }}
          />
        </View>
        {percentage >= 50 && (
          <View
            style={{
              position: 'absolute',
              width: size,
              height: size,
            }}>
            <View
              style={{
                overflow: 'hidden',
                backgroundColor: isLR ? constants?.color?.orange : '#9A5D70',
                borderTopRightRadius: size,
                borderBottomRightRadius: size,
                width: size / 2,
                height: size,
                marginLeft: size / 2,
              }}
            />
          </View>
        )}
        <View
          style={{
            backgroundColor: constants?.color?.white,
            width: size - lineWidth * 2,
            height: size - lineWidth * 2,
            borderRadius: (size - lineWidth * 2) / 2,
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          {!!innerText && (
            <Text
              style={{
                fontSize: 12,
                fontFamily: 'JakartaBold',
                color: '#505759',
              }}>
              +{innerText}
            </Text>
          )}
        </View>
        {!!centerImageUrl && (
          <ExpoImage
            resizeMode={'cover'}
            style={{
              position: 'absolute',
              width: size - 26,
              height: size - 26,
              borderRadius: (size - 26) / 2,
            }}
            source={{uri: centerImageUrl}}
          />
        )}
      </View>

      <View
        style={{
          position: 'absolute',
          width: size,
          height: size,
        }}>
        <View style={{flex: 1, position: 'relative', alignItems: 'center'}}>
          <View
            style={{
              position: 'absolute',
              top: 0,
              width: lineWidth,
              height: lineWidth,
              borderRadius: lineWidth / 2,
              backgroundColor: isLR ? constants?.color?.orange : '#9A5D70',
            }}
          />
        </View>
      </View>

      <View
        style={{
          position: 'absolute',
          width: size,
          height: size,
          transform: [
            {
              rotate: `${deg}deg`,
            },
          ],
        }}>
        <View style={{flex: 1, position: 'relative', alignItems: 'center'}}>
          <View
            style={{
              position: 'absolute',
              top: 0,
              width: lineWidth,
              height: lineWidth,
              borderRadius: lineWidth / 2,
              backgroundColor: isLR ? constants?.color?.orange : '#9A5D70',
            }}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default Header;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React from 'react';
import {
  View,
  Image,
  Text,
  ImageBackground,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import SimpleStarRating from 'src/Modules/CommonView/SimpleStarRating';
import {TGroupInfo} from 'src/types/TGroupInfo';
const signInImage = require('src/Images/signInImage.png');

type EventLeaderboardCompactProps = {
  groupsOverride?: TGroupInfo[];
  backgroundStyle?: ViewStyle;
  title?: string;
  body?: string | null;
};

const EventLeaderboardCompact: React.FC<EventLeaderboardCompactProps> = ({
  groupsOverride,
  backgroundStyle = {},
  title = 'Leaderboard',
  body,
}) => {
  const backupGroups = useTypedSelector((state) => state.event?.groups);
  const groups = groupsOverride || backupGroups;
  const hideLeaderboard = useTypedSelector(
    (state) => state.event?.info?.hideLeaderboard,
  );

  let place = 0;
  let previousHigherScore = 0;

  if (!groups) {
    return <></>;
  }

  const rankedGroups =
    !!groups &&
    groups
      .slice()
      ?.sort((a, b) => {
        const aScore = !!a?.score ? 1 * a?.score : 0;
        const bScore = !!b?.score ? 1 * b?.score : 0;
        if (aScore < bScore) {
          return 1;
        }
        if (bScore < aScore) {
          return -1;
        }
        return 0;
      })
      ?.map?.((group) => {
        const newGroup = {...group};

        if (previousHigherScore != newGroup.score) {
          previousHigherScore = group.score || 0;
          place = place + 1;
          newGroup.place = place;
        }

        return newGroup;
      });

  console.log('EventLeaderboardCompact', {rankedGroups});
  return (
    <ImageBackground
      source={require('src/Images/Rank/leaderboard.jpg')}
      resizeMode={'stretch'}
      style={{
        flex: 1,
        marginTop: 20,
        alignItems: 'center',
        ...backgroundStyle,
      }}>
      <Image
        source={require('src/Images/happyTrophy.png')}
        style={{height: 40, width: 60, marginTop: 20}}
      />
      {!hideLeaderboard && (
        <Text
          style={{
            color: 'white',
            fontSize: 18,
            fontFamily: constants?.font.circularBold,
          }}>
          {title}
        </Text>
      )}
      {!!body && (
        <Text
          style={{
            color: 'white',
            fontSize: 16,
            fontFamily: constants?.font.circularBold,
            textAlign: 'center',
            padding: 10,
          }}>
          {body}
        </Text>
      )}
      {!hideLeaderboard && (
        <View style={{display: 'flex', flexDirection: 'row', marginTop: 20}}>
          <PlacedTeam
            rank={2}
            photoUrl={rankedGroups?.[1]?.groupPhoto}
            name={rankedGroups?.[1]?.teamName}
            score={rankedGroups?.[1]?.score}
          />
          <PlacedTeam
            rank={1}
            photoUrl={rankedGroups?.[0]?.groupPhoto}
            name={rankedGroups?.[0]?.teamName}
            score={rankedGroups?.[0]?.score}
          />
          <PlacedTeam
            rank={3}
            photoUrl={rankedGroups?.[2]?.groupPhoto}
            name={rankedGroups?.[2]?.teamName}
            score={rankedGroups?.[2]?.score}
          />
        </View>
      )}
    </ImageBackground>
  );
};

// note rank is sometimes different then place
// if all teams have same score
export const PlacedTeam = ({
  rank,
  photoUrl,
  isLocal = true,
  name,
  score,
  handlePress,
  darkText = false,
}: {
  rank: number;
  photoUrl?: string | null;
  isLocal?: boolean;
  name?: string;
  score?: number | null;
  handlePress?: Function | null;
  darkText?: boolean;
}): JSX.Element => {
  if (!photoUrl && isLocal) {
    return <View style={{flex: 1}}></View>;
  }

  let backgroundColor = constants?.color?.teal;
  if (rank == 1) {
    backgroundColor = constants?.color?.yellow;
  } else if (rank == 2) {
    backgroundColor = constants?.color?.blue;
  } else {
    backgroundColor = constants?.color?.teal;
  }

  const isMiddle = rank === 1;

  const starColor = [
    constants?.color?.yellow,
    constants?.color?.orange,
    constants.color?.gray3,
  ];

  const boxShadow = {
    shadowColor: 'rgba(80, 87, 89, 0.20)',
    elevation: 2,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 1,
    shadowRadius: 4,
  };

  return (
    <TouchableOpacity
      style={{flex: 1, display: 'flex', alignItems: 'center'}}
      // @ts-ignore
      onPress={handlePress || undefined}>
      <>
        <View
          style={{
            borderRadius: isMiddle ? 46 : 33,
            overflow: 'hidden',
            borderColor: isMiddle ? constants?.color?.yellow : 'white',
            borderWidth: 3,
            backgroundColor: 'red',
            ...boxShadow,
          }}>
          <Image
            source={
              typeof photoUrl === 'string' && photoUrl.trim() !== ''
                ? {uri: photoUrl}
                : signInImage
            }
            style={{
              width: isMiddle ? 80 : 60,
              height: isMiddle ? 80 : 60,
              backgroundColor,
            }}
          />
        </View>
        <View
          style={{
            top: isLocal ? -10 : -15,
            padding: 3,
            borderRadius: 12,
            width: isLocal ? 24 : 40,
            height: isLocal ? 24 : 34,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: isLocal ? backgroundColor : 'transparent',
          }}>
          {!isLocal && (
            <SimpleStarRating
              size={34}
              color={starColor[rank - 1]}
              containerStyle={{position: 'absolute', margin: 'auto'}}
            />
          )}
          <Text
            style={{
              textAlign: 'center',
              color: isLocal
                ? constants?.color?.gray3
                : constants?.color?.white,
              fontFamily: constants?.font?.circularBold,
              fontSize: 14,
            }}>
            {rank}
          </Text>
        </View>
        <Text
          style={{
            textAlign: 'center',
            color: darkText ? constants?.color?.black : constants?.color?.white,
            fontFamily: isLocal
              ? constants?.font?.circularBold
              : constants?.font?.JakartaBold,
            fontSize: 14,
            maxWidth: 140,
            lineHeight: 14,
          }}>
          {name}
        </Text>
        <Text
          testID={'rank-' + rank + '-pts'}
          style={{
            textAlign: 'center',
            color: darkText ? constants?.color?.black : constants?.color?.white,
            fontFamily: isLocal
              ? constants?.font?.circularBold
              : constants?.font?.Jakarta,
            fontSize: 10,
          }}>
          {Math.round(score || 0)} pts
        </Text>
      </>
    </TouchableOpacity>
  );
};

export default EventLeaderboardCompact;

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect} from 'react';

import {View, Linking} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

///////////////////////////
///////////// components
import {BodyText} from 'src/Modules/CommonView';
import TextMultiChoice from 'src/ScavengerHunt_v2/Challenges/TextMultiChoice';
import LinkChallenge from 'src/ScavengerHunt_v2/Challenges/LinkChallenge';
import PhotoVideo from 'src/ScavengerHunt_v2/Challenges/PhotoVideo';
import NumberGuesser from 'src/ScavengerHunt_v2/Challenges/NumberGuesser';
import LetterScramble from 'src/ScavengerHunt_v2/Challenges/LetterScramble';

import {ContinueButton} from 'src/Modules/CommonView/Buttons';
import ExpoImage from 'src/Utils/ExpoImage';

import {useChallenge} from 'src/Hooks/gameHooks';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {dispatchAction} from 'src/Utils/helpers';
import {setGameHeaderMessageOverride} from 'src/Redux/reducers/game_v2.reducer';
import {TAudioTourItem} from 'src/types/TAudioTourItem';
import TextQuestion from '../Challenges/TextQuestion';

interface AudioChallengeProps {
  currentAudioTourItem: TAudioTourItem; // You might want to specify a more specific type for this prop
  handleNext: () => void;
}

const AudioChallenge: React.FC<AudioChallengeProps> = ({
  currentAudioTourItem,
  handleNext,
}) => {
  console.log('AudioChallenge');
  const {width} = useWindowDimensionsSafe();

  const challenge = useChallenge();

  const currentScreenIsTheChallengeAttemptAndResultModal = useTypedSelector(
    (state) =>
      state.current_screen?.currentScreen === 'ChallengeAttemptAndResultModal',
  );

  useEffect(() => {
    // auto navigation if someone else completes the challenge
    if (
      !!challenge?.completeTime &&
      currentScreenIsTheChallengeAttemptAndResultModal
    ) {
      navigationRef?.goBack('ScavengerHunt_v2/AudioTour/AudioChallenge.tsx');
      navigationRef.navigate(
        'ChallengeAttemptAndResultModal',
        {
          challengeId: challenge.challengeId,
        },
        'ScavengerHunt_v2/AudioTour/AudioChallenge.tsx',
      );
      return;
    }
  }, [
    challenge?.completeTime,
    currentScreenIsTheChallengeAttemptAndResultModal,
  ]);

  const handleOnComplete = () => {
    console.log('handleOnComplete');

    handleNext?.();
  };

  const type = challenge?.type;
  const question = challenge?.question;
  const hintURL = challenge?.hintURL;
  const challengePhoto =
    challenge?.challengePhoto || challenge?.challenge_photo;
  const answers = challenge?.answers || [];
  const challengeId = challenge?.challengeId;
  const maxPoints = challenge?.maxPoints || 0;

  console.log({type, challenge});

  const basePhotoUrl = 'photos.letsroam.com';
  const largeDisplayedPhoto = challengePhoto
    ? `https://${basePhotoUrl}${challengePhoto && challengePhoto.substring(1)}`
    : null;

  console.log({largeDisplayedPhoto});

  let previousWrongAttempts = 0;
  answers?.map?.((answer) => {
    if (answer?.selectedAt && !answer?.correct) {
      previousWrongAttempts++;
    }
  });

  const maxPointsStillPossible = Math.round(
    maxPoints *
      ((answers?.length - previousWrongAttempts) / (answers?.length || 1)),
  );

  const isOnRealChallenge =
    currentAudioTourItem?.type == 'challenge' &&
    challenge?.type !== 'audioBlock';

  const autoHideText = useTypedSelector(
    (state) => state.group?.info?.autoHideText,
  );

  console.log({challengeId, challenge, autoHideText});

  useEffect(() => {
    let newGameMessageOverride: string | null = null;
    if (currentAudioTourItem?.type == 'navigationChallenge') {
      newGameMessageOverride = 'Navigate To Location';
    } else if (currentAudioTourItem?.type == 'huntIntro') {
      newGameMessageOverride = 'Audio Tour Intro';
    } else if (currentAudioTourItem?.type == 'huntOutro') {
      newGameMessageOverride = 'Audio Tour Outro';
    } else if (isOnRealChallenge) {
      newGameMessageOverride = 'Complete Challenge';
      if (type === 'true_false' || type === 'multiple_choice') {
        newGameMessageOverride = 'Complete Trivia Challenge';
      } else if (type === 'photo') {
        newGameMessageOverride = 'Complete Photo Challenge';
      } else if (type === 'video') {
        newGameMessageOverride = 'Complete Video Challenge';
      }
    } else {
      newGameMessageOverride = 'Additional Information';
    }

    console.log({newGameMessageOverride, currentAudioTourItem});

    dispatchAction(setGameHeaderMessageOverride(newGameMessageOverride));

    return () => {
      dispatchAction(setGameHeaderMessageOverride(null));
    };
  }, [
    maxPointsStillPossible,
    currentAudioTourItem?.type,
    type,
    isOnRealChallenge,
  ]);

  if (!isOnRealChallenge && !!autoHideText) {
    return <></>;
  }

  return (
    <View
      style={{
        width,
        backgroundColor: constants?.color?.white,
        borderRadius: 10,
        paddingVertical: 5,
        position: 'relative',
        minHeight: 200,
      }}>
      <View style={{marginHorizontal: 10, paddingBottom: 10}}>
        <BodyText
          text={
            !!isOnRealChallenge
              ? `Challenge: ${
                  maxPointsStillPossible || maxPoints
                } / ${maxPoints} Pts Possible`
              : 'Information'
          }
          textStyle={{
            textAlign: 'center',
            fontWeight: '700',
            fontSize: 18,
            lineHeight: 20,
            marginTop: 20,
          }}
        />

        {!!largeDisplayedPhoto && (
          <View>
            <ExpoImage
              source={{uri: largeDisplayedPhoto}}
              style={{
                width: width - 20,
                height: 150,
                marginTop: 10,
                marginBottom: 10,
              }}
              resizeMode={'cover'}
            />
          </View>
        )}
        <BodyText text={question || currentAudioTourItem?.text || ''} />

        {(type === 'true_false' || type === 'multiple_choice') && (
          <TextMultiChoice
            onComplete={handleOnComplete}
            singleCompletion
            allowMultiGuess={type !== 'true_false'}
            noTimer
          />
        )}
        {type === 'link' && (
          <LinkChallenge
            onComplete={handleOnComplete}
            singleCompletion
            allowMultiGuess
            noTimer
          />
        )}
        {(type === 'photo' || type === 'video') && (
          <PhotoVideo
            onComplete={handleOnComplete}
            hideCameraIcon
            containerStyle={{flex: undefined}}
            hideShare
          />
        )}
        {(type === 'number_guesser' || type === 'numberguesser') && (
          <NumberGuesser
            onComplete={handleOnComplete}
            singleCompletion
            containerStyle={{flex: undefined}}
          />
        )}
        {type === 'fill_in_the_blank' && (
          <LetterScramble
            onComplete={handleOnComplete}
            singleCompletion
            containerStyle={{flex: undefined, marginBottom: 40}}
          />
        )}
        {type === 'fill_in_the_blank_traditional' && (
          <TextQuestion
            onComplete={handleOnComplete}
            showBlanks={true}
            containerStyle={{flex: undefined, marginBottom: 40}}
          />
        )}
        {!!hintURL && (
          <ContinueButton
            text={'Need A Hint: Tap Here!'}
            handlePress={() => {
              Linking?.openURL(hintURL);
            }}
          />
        )}
      </View>
    </View>
  );
};

export default AudioChallenge;

import constants from 'src/constants';
import React, {useState, ReactElement} from 'react';
import {
  Text,
  View,
  TextInput,
  NativeSyntheticEvent,
  TextInputContentSizeChangeEventData,
  ViewStyle,
} from 'react-native';

interface ViewStyleWithTextAlign extends ViewStyle {
  textAlign?: 'center' | 'left' | 'right' | undefined;
}

interface TextEntryProps {
  renderElement?: boolean;
  handleChange?: (_text: string) => void;
  value?: string;
  label?: string;
  inputStyle?: ViewStyleWithTextAlign;
  color?: string;
  placeholder?: string;
  placeHolder?: string; // Note: It's a bit unusual to have both "placeholder" and "placeHolder", consider consolidating them.
  containerStyle?: ViewStyle;
  multiline?: boolean;
  testID?: string;
  autoCorrect?: boolean;
  underlineColorAndroid?: string;
}

export const TextEntry: React.FC<TextEntryProps> = ({
  renderElement = true,
  handleChange = () => null,
  value = '',
  label = '',
  inputStyle = {} as ViewStyleWithTextAlign,
  color = constants?.color?.gray3,
  placeholder = '',
  placeHolder = '',
  containerStyle = {},
  multiline = false,
  testID = '',
}): ReactElement | null => {
  const minHeight: number =
    (inputStyle?.height as number) || (multiline ? 40 : 25);
  const [height, setHeight] = useState<number>(minHeight);

  if (!renderElement) {
    return null;
  }

  const inputStyleWithoutTextAlign: ViewStyle = {
    ...inputStyle,
  };

  return (
    <View style={containerStyle}>
      {!!label && (
        <Text
          style={{
            color: color,
            fontFamily: constants?.font.circular,
            marginVertical: 5,
            textAlign: 'left',
            width: 220,
          }}>
          {label}
        </Text>
      )}
      <TextInput
        style={{
          backgroundColor: constants?.color?.white1,
          color: color,
          marginBottom: 5,
          width: 220,
          ...inputStyleWithoutTextAlign,
          height,
        }}
        testID={testID}
        onContentSizeChange={(
          event: NativeSyntheticEvent<TextInputContentSizeChangeEventData>,
        ) => {
          if (!multiline) {
            return;
          }
          setHeight(
            Math.max(minHeight, event.nativeEvent.contentSize.height + 20),
          );
        }}
        allowFontScaling={false}
        textAlign={inputStyle?.textAlign || 'center'}
        multiline={multiline}
        placeholder={placeHolder || placeholder}
        value={value}
        autoCorrect={false}
        underlineColorAndroid={'transparent'}
        onChangeText={(textValue) => handleChange(textValue)}
        blurOnSubmit
      />
    </View>
  );
};

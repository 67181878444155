import constants from 'src/constants';
import 'react-native-get-random-values';

import {Alert, Platform, SafeAreaView, useWindowDimensions} from 'react-native';
import React, {useRef} from 'react';

//splash screen controls import for app loading
import * as SplashScreen from 'expo-splash-screen';

//redux actions

import {useFocusEffect} from '@react-navigation/native';

import {setHostMaximized} from 'src/Redux/reducers/event.reducer';
import {messaging} from 'src/Utils/db';

import LottieView from 'lottie-react-native';

import {setPreviousVisit} from 'src/Redux/reducers/current_screen.reducer';

import {setDeviceUUID} from 'src/Redux/reducers/app_info.reducer';
import {mmkvStorage} from 'src/Utils/mmkvStorage';

let LaunchArguments: {value: () => TJSONValue};

if (Platform.OS !== 'web') {
  const ReactNativeLaunchArguments = require('react-native-launch-arguments');
  LaunchArguments = ReactNativeLaunchArguments?.LaunchArguments;
  console.log({LaunchArguments});
} else {
  LaunchArguments = {
    value: () => {
      return {};
    },
  };
}
import {logOutUser} from 'src/Redux/reducers/user_info.reducer';
import {navigationRef} from 'src/Nav/navigationHelpers';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {logAttributesForCrashlytics} from 'src/Utils/fireUtils';

import {v4 as uuidv4} from 'uuid';
import {clearErrors} from 'src/Redux/reducers/logs.reducer';
import {resetHomeView} from 'src/Redux/reducers/home.reducer';
import {dispatchAction, getReduxState} from 'src/Utils/helpers';

const AppLoadingPage: React.FC = () => {
  const {width} = useWindowDimensions();

  const hasHiddenSplashRef = useRef<boolean>(false);

  const hideSplashIfNecessary = async () => {
    if (hasHiddenSplashRef.current) {
      return;
    }
    hasHiddenSplashRef.current = true;
    try {
      await SplashScreen?.hideAsync?.();
    } catch (error: unknown) {
      console.error('error hiding splash screen', error);
    }
  };

  useFocusEffect(() => {
    hideSplashIfNecessary();

    dispatchAction(clearErrors());
    async function loadApp() {
      try {
        // resetting host to minimized state
        const hostMaximized = getReduxState(
          (state) => state.event?.hostMaximized,
        );
        if (hostMaximized) {
          dispatchAction(setHostMaximized(false));
        }

        dispatchAction(resetHomeView());

        const launchArgs = LaunchArguments?.value?.() || {};

        console.log('AppLoadingPage: Started!', {launchArgs});
        if (launchArgs?.deleteStorage) {
          console.log('CLEARING STORAGE');
          dispatchAction(logOutUser());
          mmkvStorage?.clearAll?.();
          dispatchAction({type: 'HARD_RESET'});
        }

        //cycling through async storage to do conditional logic and redux stating based on saved data

        let deviceUUID = mmkvStorage.getString('deviceUUID');
        const previousVisit = !!mmkvStorage.getString('previousVisit')
          ? true
          : false;

        console.log({
          deviceUUID,
          previousVisit,
        });

        if (!deviceUUID) {
          deviceUUID = uuidv4()?.substring(0, 8);
          try {
            if (deviceUUID) {
              messaging().subscribeToTopic(deviceUUID);
            }
          } catch (error: unknown) {
            console.warn(
              'We could not subscribe to the device UUID topic: ',
              error,
            );
          }
          mmkvStorage.set('deviceUUID', deviceUUID);
        }

        AsyncStorage.setItem('deviceUUID', deviceUUID);

        // updating device UUID if it changed
        const deviceUUIDFromState = getReduxState(
          (state) => state.app_info?.deviceUUID,
        );
        if (deviceUUIDFromState != deviceUUID) {
          dispatchAction(setDeviceUUID(deviceUUID));
        }

        logAttributesForCrashlytics('deviceUUID', deviceUUID);

        logAttributesForCrashlytics(
          'screenRecordingURL',
          'https://hventures2.letsroam.com/admin/users/see_live/' + deviceUUID,
        );

        console.log({previousVisit});

        dispatchAction(setPreviousVisit(1));

        console.log('AppLoadingPage: Done!');
        if (
          previousVisit == true ||
          window?.location?.href?.includes('isDemo')
        ) {
          setTimeout(() => {
            navigationRef.navigate('LandingPageMain', null, 'App Loading Page');
          }, 2000);
        } else {
          setTimeout(() => {
            navigationRef.navigate('AppIntroSwiper', null, 'App Loading Page');
          }, 2000);
        }
      } catch (error: unknown) {
        if (__DEV__) {
          Alert.alert(
            'Dev alert: the app loading page had an error and defaulted to intro swiper',
          );
          console.log({error});
        }
        hideSplashIfNecessary();
        navigationRef.navigate(
          'AppIntroSwiper',
          null,
          'LandingPage/AppLoadingPage.tsx',
        );
      }
    }

    loadApp();
  });

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: constants?.color.orange,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {/* {!isDetox && ( */}
      <LottieView
        source={require('src/Lottie/logo_explode.json')}
        autoPlay
        loop={false}
        style={{width}}
        speed={1}
      />
      {/* )} */}
    </SafeAreaView>
  );
};

// eslint-disable-next-line
export default AppLoadingPage;

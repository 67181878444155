import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useRef, useState} from 'react';
import {
  Animated,
  ImageBackground,
  PanResponder,
  Platform,
  ScrollView,
  Text,
  View,
} from 'react-native';

//////////////////////////
///////  components
import ChallengesList from 'src/ScavengerHunt_v2/Classic/ChallengesList';

import HuntOutro from 'src/ScavengerHunt_v2/Game/HuntOutro';
import {useLocation} from 'src/Hooks/gameHooks';
import {ProgressBar} from 'src/ScavengerHunt_v2/Classic/Header';
import {InLineIconButton} from 'src/Modules/CommonView/Buttons';
import {navigationRef} from 'src/Nav/navigationHelpers';

import {TouchableOpacity} from 'react-native-gesture-handler';
import {setLocationCompleteTime} from 'src/Utils/huntFunctions_v2';
import AllAnswersModal from './AllAnswersModal';
import ClickableImageChallenges from './ClickableImageChallenges';

const LocationChallenges: React.FC = () => {
  console.log('LocationChallenges render');
  const timerFinish = useTypedSelector((state) => state.game_v2?.timerFinish);

  //Collapse challenge list for hunt types that support clickable polygons
  const showClickableImage = useTypedSelector((state) =>
    ['escape_room'].includes(state.group?.info?.huntType || ''),
  );

  const hasHostControl = useTypedSelector(
    (state) => state.event?.info?.hasHostControl,
  );

  const showAnswers = useTypedSelector(
    (state) => state.event?.info?.showAnswers || false,
  );

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  const groupPhoto = useTypedSelector(
    (state) => state.group?.info?.groupPhoto || '',
  );

  const location = useLocation();
  const challengeList = location?.challengeList || [];
  const photoLarge = location?.photoLarge || '';

  const isLRAdmin = useTypedSelector(
    (state) => __DEV__ || state.user?.info?.email?.includes?.('letsroam'),
  );

  // this complicated bit of code is to reduce a ton of re-renders
  const {
    availableChallengeIds,
    completedChallengeIds,
    challengeCompletionPercent,
  } = useTypedSelector((state) => {
    const allChallenges = state.game_v2?.allChallenges;

    // These will hold the IDs of available and completed challenges.
    const availableChallengeIds: string[] = [];
    const completedChallengeIds: string[] = [];

    challengeList.length &&
      challengeList.forEach((key: string) => {
        const locationChallenge = allChallenges && allChallenges[key];
        const isDone =
          locationChallenge?.completeTime || locationChallenge?.photoUrl;

        if (isDone) {
          locationChallenge && completedChallengeIds.push(key);
        } else {
          locationChallenge && availableChallengeIds.push(key);
        }
      });

    // Note that it's critical to ensure `challengeList.length` is not 0 to avoid NaN.
    const challengeCompletionPercent =
      challengeList.length > 0
        ? 5 +
          Math.max(
            5,
            (100 * completedChallengeIds.length) / challengeList.length,
          )
        : 0;

    return {
      availableChallengeIds,
      completedChallengeIds,
      challengeCompletionPercent,
    };
  }, deepEqualityCheck);

  const basePhotoUrl = 'photos.letsroam.com';
  const largeDisplayedPhoto = photoLarge
    ? photoLarge.includes(basePhotoUrl) ||
      photoLarge.includes('photos.scavengerhunt.com') ||
      photoLarge.includes('firebasestorage.googleapis.com')
      ? photoLarge
      : `https://${basePhotoUrl}${photoLarge && photoLarge.substring(1)}`
    : groupPhoto;

  console.log('LocationChallenges');

  if (timerFinish) {
    return <HuntOutro />;
  }

  if (showAnswers && hasHostControl) {
    return <AllAnswersModal />;
  }

  if (showClickableImage) {
    return (
      <ClickableImageChallenges availableChallengeIds={availableChallengeIds} />
    );
  }

  return (
    <ImageBackground
      style={{flex: 1}}
      source={{
        uri: !isHybridHunt || !largeDisplayedPhoto ? '' : largeDisplayedPhoto,
      }}>
      <View
        style={{
          flex: 1,
          backgroundColor: !!isHybridHunt ? '' : constants?.color?.white1,
          position: 'relative',
        }}>
        {!!isLRAdmin && (
          <TouchableOpacity
            onPress={() => {
              if (location?.locationId) {
                setLocationCompleteTime(location?.locationId);
              }
            }}
            style={{
              position: 'relative',
              marginTop: 10,
              zIndex: 201,
              width: 20,
              height: 20,
              backgroundColor: 'red',
            }}
          />
        )}
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={{flex: 1, zIndex: 200}}>
          {!isHybridHunt && (
            <View
              style={{
                flexDirection: 'row',
                marginHorizontal: 10,
                justifyContent: 'space-around',
                alignItems: 'center',
                marginTop: 30,
              }}>
              {!!challengeList.length && (
                <ProgressBar
                  percentage={challengeCompletionPercent}
                  size={140}
                  centerImageUrl={largeDisplayedPhoto}
                  handlePress={() =>
                    navigationRef.navigate(
                      'FullScreenNavigationHelpModal',
                      null,
                      'ScavengerHunt_v2/Game/LocationChallenges.tsx',
                    )
                  }
                  // handlePress={() => navigationRef.navigate('PlayerDashboard_v2', null, 'ScavengerHunt_v2/Game/LocationChallenges.tsx')}
                />
              )}
              <View style={{flex: 1, marginLeft: 16}}>
                <Text
                  style={{
                    fontFamily: 'JakartaBold',
                    fontSize: 24,
                    lineHeight: 29,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  ellipsizeMode="tail">
                  {location?.name}
                </Text>
                <View>
                  <InLineIconButton
                    handlePress={() =>
                      navigationRef.navigate(
                        'FullScreenNavigationHelpModal',
                        null,
                        'ScavengerHunt_v2/Game/LocationChallenges.tsx',
                      )
                    }
                    text={`See map`}
                    icon="map"
                    color={constants?.color?.orange}
                    inline
                  />
                </View>
              </View>
            </View>
          )}
          <View
            style={{
              flex: 1,
              paddingBottom: !!isHybridHunt ? 0 : 60,
              marginTop: !!isHybridHunt ? 20 : 0,
            }}>
            <ChallengesList
              almostEndOfHunt={false}
              availableChallengeIds={availableChallengeIds}
              completedChallengeIds={completedChallengeIds}
              challengeHeader={
                !!isHybridHunt ? 'Instructions' : 'Site Challenges'
              }
              challengeHeaderStyle={{
                textAlign: 'left',
                fontSize: 24,
                lineHeight: 29,
                fontFamily: 'JakartaBold',
              }}
              challengeHeaderBody={
                'Complete all the challenges here to move on. Earn points for speed and accuracy at each site.'
              }
              challengeHeaderBodyStyle={{
                textAlign: 'left',
                fontSize: 15,
                lineHeight: 21,
                fontFamily: 'Jakarta',
                marginHorizontal: 20,
              }}
              containerStyle={
                isHybridHunt
                  ? {
                      backgroundColor: constants?.color?.white,
                      borderTopLeftRadius: 20,
                      borderTopRightRadius: 20,
                      paddingBottom: Math.max(
                        50,
                        400 - challengeList.length * 100,
                      ),
                    }
                  : {}
              }
            />
          </View>
        </ScrollView>
      </View>
    </ImageBackground>
  );
};

export default LocationChallenges;

import {
  useSelector as originalUseSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import {RootState} from 'src/Redux/reducers/rootReducer';

const useTypedSelector: TypedUseSelectorHook<RootState> = originalUseSelector;

export const deepEqualityCheck = (a: unknown, b: unknown): boolean => {
  const aValue = typeof a == 'object' ? JSON.stringify(a) : a;
  const bValue = typeof b == 'object' ? JSON.stringify(b) : b;
  return aValue === bValue;
};

export default useTypedSelector;

import AsyncStorage from '@react-native-async-storage/async-storage';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Platform} from 'react-native';
import {localHuntList} from 'src/Data/HuntLocationsLocal';
import {TCondensedHunt} from 'src/types/TCondensedHunt';
import {THunt} from 'src/types/THunt';

// Define the CondensedHunt type with required properties

interface HuntsState {
  hunts: THunt[];
  huntsList: THunt[];
  currentHunt: THunt | null;
}

const initialState: HuntsState = {
  hunts: localHuntList,
  huntsList: localHuntList,
  currentHunt: null,
};

const huntsSlice = createSlice({
  name: 'hunts',
  initialState,
  reducers: {
    setHunts(state, {payload: hunts}: PayloadAction<THunt[]>) {
      state.hunts = hunts;
      // condensed hunts are only used on android
      if (hunts && Platform.OS === 'ios') {
        const condensedHunts: TCondensedHunt[] = [];
        hunts.forEach((hunt) => {
          if (
            hunt.hunt_type !== 'barHunt' &&
            hunt.hunt_type !== 'inHome' &&
            !!hunt.lat &&
            !!hunt.long &&
            hunt.name &&
            hunt.lat &&
            hunt.long
          ) {
            condensedHunts.push({
              name: hunt.name,
              lat: hunt.lat,
              long: hunt.long,
              hunt_medium_photo: hunt.huntMediumPhotoURL || '',
              hunt_id: hunt.hunt_id || '',
              hunt_type: hunt.hunt_type || '',
            });
          }
        });
        const condensedHuntsString = JSON.stringify(condensedHunts);
        AsyncStorage.setItem('condensedHunts', condensedHuntsString);
        console.log(
          'condensed hunts set, length: ',
          condensedHuntsString?.length,
        );
      }
    },
    setHuntsList(state, {payload: huntsList}: PayloadAction<THunt[]>) {
      state.huntsList = huntsList;
    },
    setCurrentHunt(state, {payload: currentHunt}: PayloadAction<THunt | null>) {
      state.currentHunt = currentHunt;
    },
  },
});

export const {setHunts, setHuntsList, setCurrentHunt} = huntsSlice.actions;

export default huntsSlice.reducer;

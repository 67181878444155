import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants'; /* eslint-disable no-use-before-define */
import React, {useState, useEffect, useCallback, useRef} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Image,
  useWindowDimensions,
  Alert,
  TextInput,
} from 'react-native';
import {useSharedValue, withTiming, Easing} from 'react-native-reanimated';
import {Slider} from 'react-native-awesome-slider';

import {AVPlaybackStatus, AVPlaybackStatusSuccess, Audio} from 'expo-av';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import FoundationIcon from 'react-native-vector-icons/Foundation';

import moment from 'moment';
import {useChallenge} from 'src/Hooks/gameHooks';
import ModalSelector from 'react-native-modal-selector';
import {setDataAtPath} from 'src/Utils/fireUtils';

import {finishHunt} from 'src/Utils/huntFunctions';
import {TAudioTourItem} from 'src/types/TAudioTourItem';

const playbackSpeedOptions = [
  {key: 0.1, section: true, label: 'Playback Speed Options'},
  {key: 0.5, label: '0.5x', value: 0.5},
  {key: 0.75, label: '0.75x', value: 0.75},
  {key: 0.9, label: '0.9x', value: 0.9},
  {key: 1, label: '1x', value: 1},
  {key: 1.1, label: '1.1x', value: 1.1},
  {key: 1.25, label: '1.25x', value: 1.25},
  {key: 1.5, label: '1.5x', value: 1.5},
  {key: 2, label: '2x', value: 2},
];

function formatTime(secondsOnly = 0) {
  const duration = moment.duration(secondsOnly * 1000);
  let minutes = duration.minutes();
  let seconds = duration.seconds();
  if (isNaN(minutes)) {
    minutes = 0;
    seconds = 0;
  }
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

interface AudioPlayerProps {
  currentAudioTourItem?: TAudioTourItem | null; // You might want to specify a more specific type for this prop
  handleBack: () => void;
  handleNext: () => void;
  currentAudioTourIndex: number;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({
  currentAudioTourItem,
  handleBack,
  handleNext,
  currentAudioTourIndex,
}) => {
  const currentAudioTourUUID = currentAudioTourItem?.uuid;
  const playtime_seconds = currentAudioTourItem?.playtime_seconds || 0;
  const recording_url = currentAudioTourItem?.recording_url;

  console.log('AudioPlayer rerender', {currentAudioTourUUID});

  const groupId = useTypedSelector((state) => state.group?.info?.groupId);
  const playBackSpeed =
    useTypedSelector((state) => state.group?.info?.playBackSpeed) || 1;

  const autoHideText = useTypedSelector(
    (state) => state.group?.info?.autoHideText,
  );

  // console.log({currentAudioTourItem, playBackSpeed});

  const progress = useSharedValue(0);
  const min = useSharedValue(0);
  const duration = useSharedValue(playtime_seconds);

  const isSliding = useRef<boolean>(false);

  // used to stop double plays
  const currentAudioId = useRef<string | null>(null);

  const soundRef = useRef<Audio.Sound | null>();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const positionRef = useRef<number>(0);
  const timeElaspsedTextElementRef = useRef<TextInput | null>(null);

  const [audioDoneRecordingURL, setAudioDoneRecordingURL] = useState<
    string | null
  >(null);

  const [showWarning, setShowWarning] = useState<string | null>(null);

  const challenge = useChallenge();

  const playBackSpeedSelectorRef = useRef<ModalSelector | null>(null);

  useEffect(() => {
    if (soundRef?.current) {
      soundRef?.current.setRateAsync(playBackSpeed || 1, true);
    }
  });

  const isChallengeCloseInformationLocationId = useTypedSelector(
    (state) => state?.game_v2?.isChallengeCloseInformation?.locationId,
  );
  const isLocationCloseInformationIsClose = useTypedSelector(
    (state) => state?.game_v2?.isChallengeCloseInformation?.isClose,
  );

  // set in DistanceAway component
  console.log({
    isChallengeCloseInformationLocationId,
    isLocationCloseInformationIsClose,
  });

  // console.log({challenge});

  const determineIfShouldNotMoveOne = useCallback(
    (showNonLoacationWarning: boolean) => {
      if (currentAudioTourItem?.type == 'challenge') {
        if (challenge?.type == 'audioBlock') {
          // progress because audio block done
          console.log('can move on 1');
        } else if (challenge?.completeTime) {
          // progress because challenge completed
          console.log('can move on 2');
        } else {
          const message = `Complete challenge here or top skip forward.`;
          showNonLoacationWarning && setShowWarning(message);
          return message;
        }
      } else if (currentAudioTourItem?.type == 'navigationChallenge') {
        if (
          isChallengeCloseInformationLocationId ==
            currentAudioTourItem?.locationId &&
          isLocationCloseInformationIsClose
        ) {
          // should move on
        } else {
          const message = `Please get closer to the location or tap skip forward`;
          setShowWarning(null);
          return message;
        }
      }
      console.log('can move on 3');
    },
    [
      audioDoneRecordingURL,
      JSON.stringify(currentAudioTourItem || {}),
      JSON.stringify(challenge || {}),
      isChallengeCloseInformationLocationId,
      isLocationCloseInformationIsClose,
    ],
  );

  useEffect(() => {
    console.log('checking if audioDone', {
      audioDoneRecordingURL,
      currentAudioTourIndex,
      progress,
    });
    if (
      !!audioDoneRecordingURL &&
      audioDoneRecordingURL == currentAudioTourItem?.recording_url &&
      progress.value > 1
    ) {
      const shouldMoveOn = determineIfShouldNotMoveOne(false);

      console.log('audioDone is Done', {
        audioDoneRecordingURL,
        currentAudioTourIndex,
        shouldMoveOn,
      });

      if (shouldMoveOn) {
        console.log('skip', {shouldMoveOn});
        return;
      }

      if (currentAudioTourItem?.type == 'huntOutro') {
        finishHunt();
        return;
      }

      console.log('audioDoneCallback');
      handleNext?.();
    } else {
      return console.log('audioDone is not Done', {
        audioDoneRecordingURL,
        currentAudioTourIndex,
      });
    }
  }, [
    audioDoneRecordingURL,
    JSON.stringify(currentAudioTourItem || {}),
    JSON.stringify(challenge || {}),
    isChallengeCloseInformationLocationId,
    isLocationCloseInformationIsClose,
  ]);

  async function loadAudio(recording_url: string, audioRecordingId: string) {
    console.log('Loading Audio', {recording_url});
    await Audio.setAudioModeAsync({playsInSilentModeIOS: true});

    const {sound} = await Audio.Sound.createAsync(
      {
        uri: recording_url,
      },
      {shouldPlay: false},
    );
    soundRef.current = sound;

    sound.setOnPlaybackStatusUpdate((statusOrError: AVPlaybackStatus) => {
      if ('error' in statusOrError) {
        console.error('setOnPlaybackStatusUpdate error', {statusOrError});
        return;
      }
      const status = statusOrError as AVPlaybackStatusSuccess;

      if (currentAudioId.current != audioRecordingId) {
        soundRef?.current?.pauseAsync?.();
        return console.log('track was double playing, so paused wrong track', {
          currentAudioId,
          audioRecordingId,
        });
      }

      if (isSliding.current) {
        return console.log(`is sliding so don't update position`);
      }

      if (status?.didJustFinish) {
        // add logic based on data
        console.log('just finished playing', {
          text: currentAudioTourItem?.text,
          recording_url,
        });
        setAudioDoneRecordingURL(recording_url);
      }

      const positionSeconds = status.positionMillis / 1000;

      duration.value = status?.durationMillis
        ? status?.durationMillis / 1000
        : 1000;

      console.log('setting new position', {positionSeconds});
      if (positionSeconds > positionRef?.current && playBackSpeed <= 1) {
        progress.value = withTiming(positionSeconds, {
          duration: 300,
          easing: Easing.ease,
        });
      } else {
        progress.value = positionSeconds;
      }

      // console.log({status});
      setIsPlaying(status.isPlaying);

      positionRef.current = positionSeconds;

      // console.log({timeElaspsedTextElementRef});
      //

      timeElaspsedTextElementRef.current?.setNativeProps({
        text: formatTime(positionSeconds),
      });
    });

    playPause();
  }

  const reloadAudio = () => {
    console.log('reloading audio');
    if (!recording_url) {
      return console.log('no recording url');
    }
    setShowWarning(null);
    currentAudioId.current = String(
      Math.floor(Math.random() * 90000000000000) + 100000000000,
    );
    return loadAudio(recording_url, currentAudioId.current);
  };

  useEffect(() => {
    // reseting stats on audio source change
    duration.value = 0;
    progress.value = 0;
    setIsPlaying(false);
    positionRef.current = 0;
    timeElaspsedTextElementRef.current?.setNativeProps({
      text: formatTime(0),
    });

    // autoplay but just not for first one!
    // when new text is loaded
    if (
      !!currentAudioTourItem?.audioTourIndex &&
      currentAudioTourItem?.audioTourIndex > 0
    ) {
      // setting a random id
      reloadAudio();
    }

    return () => {
      const cleanUp = async () => {
        if (soundRef.current) {
          console.log('Unloading Sound', {});
          await soundRef.current?.pauseAsync();
          await soundRef.current.unloadAsync();
        } else {
          console.log('Not Unloading Sound');
        }
      };
      cleanUp();
    };
  }, [recording_url]);

  const playPause = async () => {
    if (!recording_url) {
      return console.log('no recording url');
    }
    console.log('playPause');
    // skip forward for errors
    if (!soundRef?.current) {
      console.log('reloading audio');
      setShowWarning(null);
      currentAudioId.current =
        '' + Math.floor(Math.random() * 90000000000000) + 100000000000;
      return loadAudio(recording_url, currentAudioId.current);
    }

    try {
      await soundRef?.current.setRateAsync(playBackSpeed || 1, true);
      isPlaying
        ? await soundRef.current.pauseAsync()
        : await soundRef.current.playAsync();
    } catch (audioLoadError) {
      if (!isPlaying) {
        reloadAudio();
      }
      console.warn({audioLoadError});
    }

    if (!isPlaying) {
      setAudioDoneRecordingURL(null);
    }
    setIsPlaying(!isPlaying);
    progress.value = positionRef.current;
  };

  const seekForward = async () => {
    const newPosition = positionRef.current + 15000;
    await soundRef?.current?.setPositionAsync(newPosition);
  };

  const seekBackward = async () => {
    const newPosition = Math.max(0, positionRef.current - 15000);

    await soundRef?.current?.setPositionAsync(newPosition);
  };

  const onSlidingStart = async () => {
    isSliding.current = true;
  };

  const onSliderValueChange = async (timeSeconds: number) => {
    isSliding.current = false;
    const seekPositionMilli = timeSeconds * 1000;

    console.log('onSliderValueChange', {seekPositionMilli});

    await soundRef?.current?.setPositionAsync(seekPositionMilli);
  };

  const {width} = useWindowDimensions();

  return (
    <>
      {!!showWarning && (
        <TouchableOpacity onPress={() => handleNext()}>
          <Text style={styles.showWarning}>{showWarning}</Text>
        </TouchableOpacity>
      )}
      <View style={styles.container}>
        <Slider
          style={{width: width - 40}}
          progress={progress}
          minimumValue={min}
          maximumValue={duration}
          onSlidingStart={onSlidingStart}
          onSlidingComplete={onSliderValueChange}
          theme={{
            minimumTrackTintColor: constants?.color.orange,
            bubbleBackgroundColor: constants?.color.orange,
          }}
        />
        <View style={styles.timeCountersHolder}>
          <TextInput
            style={styles.timeCounters}
            ref={timeElaspsedTextElementRef}
            value={formatTime(positionRef.current || 0)}
          />

          <Text style={styles.timeCounters}>
            {formatTime(duration.value || 100)}
          </Text>
        </View>
        <View style={styles.buttonHolder}>
          <TouchableOpacity
            style={{padding: 7}}
            onPress={() => {
              const path = `scavengerhunt/groups/${
                groupId || 'error'
              }/autoHideText`;
              const newAutoHide = !autoHideText;
              setDataAtPath(path || 'error', newAutoHide);
              console.log({path, newAutoHide});
            }}>
            <FoundationIcon
              name={!autoHideText ? 'page' : 'page-delete'}
              size={20}
              color={constants?.color?.orange}
              // style={{ marginHorizontal: 7 }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{padding: 5}}
            onPress={() => {
              handleBack();
            }}>
            <FontAwesomeIcon
              name={'backward'}
              size={27}
              color={constants?.color?.orange}
              // style={{ marginHorizontal: 7 }}
            />
          </TouchableOpacity>
          <TouchableOpacity style={{padding: 7}} onPress={seekBackward}>
            <Image
              style={styles.seekButton}
              source={require('src/Images/Icons/forward15Icon.png')}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (!!audioDoneRecordingURL && !isPlaying) {
                const canNotMoveOnReason = determineIfShouldNotMoveOne(true);
                console.log({canNotMoveOnReason});
                if (canNotMoveOnReason) {
                  return Alert.alert('To Move On', canNotMoveOnReason);
                }
              }
              playPause();
            }}
            style={styles.playPauseButton}>
            <Icon
              name={isPlaying ? 'pause' : 'play'}
              size={40}
              color={constants?.color?.white}
              // style={{ marginHorizontal: 7 }}
            />
          </TouchableOpacity>
          <TouchableOpacity style={{padding: 7}} onPress={seekForward}>
            <Image
              style={styles.seekButton}
              source={require('src/Images/Icons/forward15Icon.png')}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{padding: 5}}
            onPress={() => {
              handleNext();
            }}>
            <FontAwesomeIcon
              name={'forward'}
              size={27}
              color={constants?.color?.orange}
              // style={{ marginHorizontal: 7 }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={{padding: 5}}
            onPress={() => {
              playBackSpeedSelectorRef?.current?.open?.();
            }}>
            <Text
              style={{
                color: constants?.color.orange,
                fontSize: 20,
                textAlign: 'center',
              }}>
              {playBackSpeed || 1}x
            </Text>
          </TouchableOpacity>
        </View>
        <ModalSelector
          data={playbackSpeedOptions}
          ref={playBackSpeedSelectorRef}
          onChange={(item) => {
            const {value} = item;
            console.log('setPlayBackSpeed', {value});

            setDataAtPath(
              `scavengerhunt/groups/${groupId || 'error'}/playBackSpeed`,
              value,
            );
          }}
          cancelText={'Cancel'}
          customSelector={<View />}
          overlayStyle={{justifyContent: 'flex-end'}}
        />
      </View>
    </>
  );
};

export default AudioPlayer;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20,
    paddingTop: 10,
  },
  showWarning: {
    backgroundColor: constants?.color.teal,
    marginBottom: 5,
    color: 'white',
    textAlign: 'center',
    fontSize: 14,
    height: 30,
    lineHeight: 20,
    paddingVertical: 5,
  },
  buttonHolder: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  playPauseButton: {
    width: 70,
    height: 70,
    backgroundColor: constants?.color.orange,
    borderRadius: 35,
    justifyContent: 'center',
    alignItems: 'center',

    marginHorizontal: 10,
  },
  seekButton: {
    width: 45,
    height: 45,
  },
  timeCountersHolder: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: 10,
    marginTop: 10,
  },
  timeCounters: {
    fontFamily: 'Jakarta',
    fontSize: 13,
    color: constants?.color.orange,
  },
});

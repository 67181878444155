import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState} from 'react';

import {View, Alert, ScrollView, TouchableOpacity} from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import ExpoImage from 'src/Utils/ExpoImage';

///////////////   utils

import {pauseHunt} from 'src/Utils/huntFunctions';

import {PrimaryButton, FooterOverlay} from 'src/Modules/CommonView';

///////////////   components

import Photos from 'src/Modules/CommonGame/Photos';

import {BodyText} from 'src/Modules/CommonView';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {
  useLocation,
  useLocationId,
  usePercentageComplete,
} from 'src/Hooks/gameHooks';
import {TPhoto} from 'src/types/TPhoto';
import {
  setLocationCompleteTime,
  setNavigationCompleteTime,
} from 'src/Utils/huntFunctions_v2';

const Dashboard = () => {
  const {width} = useWindowDimensionsSafe();

  const playerChallengePhotos =
    useTypedSelector(
      (state) => state.playerChallenges?.playerChallengePhotos,
    ) || [];
  const allChallenges = useTypedSelector(
    (state) => state.game_v2?.allChallenges,
  );

  const timerFinish = useTypedSelector((state) => state.game_v2?.timerFinish);
  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const [showPhotos, setShowPhotos] = useState<boolean>(false);

  const groupPhoto = useTypedSelector(
    (state) => state.group?.info?.groupPhoto || '',
  );

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  const eventId = useTypedSelector((state) => state.group?.info?.eventId);
  const hidePhotos = useTypedSelector((state) => state.event?.info?.hidePhotos);
  const hideLeaderboard = useTypedSelector(
    (state) => state.event?.info?.hideLeaderboard,
  );

  const groupId = useTypedSelector((state) => state?.group?.info?.groupId);

  const locationPhotos = allChallenges
    ? Object.values(allChallenges).filter((challenge) => {
        return (
          (challenge.type === 'photo' || challenge.type === 'video') &&
          challenge.photoUrl
        );
      })
    : [];

  // @ts-ignore
  const photoObjects: TPhoto[] = [...playerChallengePhotos, ...locationPhotos];
  groupPhoto &&
    photoObjects?.unshift?.({
      UUID: 'groupPhoto',
      photoUrl: groupPhoto,
      challenge: 'Have one player take an epic team photo to start your hunt.',
    });

  const percentageComplete = usePercentageComplete();

  const corporateBrandingUrl = useTypedSelector(
    (state) => state.game_v2?.corporateBrandingUrl,
  );

  const currentLocationId = useLocationId();
  const location = useLocation();

  if (showPhotos) {
    return (
      <Photos
        photoData={photoObjects || null}
        handleBack={() => {
          setShowPhotos(false);
        }}
      />
    );
  }

  const handleNextLocationPress = () => {
    if (!location) {
      return console.error('no location', 'handleNextLocationPress');
    }

    Alert.alert(
      'Are You Sure?',
      'This will advance all players to the next location or challenge.',
      [
        {
          text: 'Cancel',
          onPress: () => null,
        },
        {
          text: 'Yes',
          onPress: async () => {
            if (!currentLocationId) {
              return navigationRef?.goBack(
                'ScavengerHunt_v2/Game/ReportModal.tsx',
              );
            }

            await setNavigationCompleteTime(currentLocationId, location, 0);
            await setLocationCompleteTime(currentLocationId);
            Alert.alert(
              'You have been moved to the next location',
              'We are sorry that there was an issue with our hunt. Our writers have been notified. Just click next.',
            );
            setTimeout(() => {
              navigationRef?.goBack('ScavengerHunt_v2/Game/ReportModal.tsx');
            }, 1000);
          },
        },
      ],
    );
  };

  if (showPhotos) {
    return (
      <Photos
        photoData={photoObjects || null}
        handleBack={() => {
          setShowPhotos(false);
        }}
      />
    );
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: constants?.color?.white,
        position: 'relative',
      }}>
      <ScrollView style={{flex: 1}}>
        {!corporateBrandingUrl && (
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <BodyText
              text={'Dashboard'}
              textStyle={{
                fontWeight: '700',
                fontSize: 18,
                lineHeight: 20,
              }}
            />
          </View>
        )}
        {!!corporateBrandingUrl && (
          <View
            style={{
              height: 80,
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: 80,
            }}>
            <ExpoImage
              style={{height: 80, width}}
              source={{uri: corporateBrandingUrl}}
              resizeMode={'contain'}
            />
          </View>
        )}
        <View
          style={{
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: width - 10,
            margin: 5,
          }}>
          {!!eventId && !isHybridHunt && (
            <DashTile
              icon={'users'}
              text={`Event Dashboard`}
              iconColor={constants?.color?.purple}
              handlePress={() =>
                navigationRef.navigate(
                  'EventDashboard',
                  {},
                  'ScavengerHunt_v2/Game/PlayerDash.tsx',
                )
              }
            />
          )}
          {!timerFinish && (
            <DashTile
              icon={'plus-circle'}
              text={'Add Players'}
              iconColor={constants?.color?.orange}
              handlePress={() => {
                navigationRef.navigate(
                  'AddPlayerModal',
                  null,
                  'ScavengerHunt_v2/Game/PlayerDash.tsx',
                );
              }}
            />
          )}
          {!timerFinish && !!groupId && (
            <DashTile
              icon={'pause-circle'}
              text={'Pause Hunt'}
              iconColor={constants?.color?.teal}
              handlePress={() => {
                pauseHunt(groupId);
              }}
            />
          )}
          {!timerFinish && (
            <DashTile
              icon={'minus-circle'}
              text={'Remove Players'}
              iconColor={'red'}
              handlePress={() => {
                navigationRef.navigate(
                  'GameLobbyPlayersModal',
                  null,
                  'ScavengerHunt_v2/Game/PlayerDash.tsx',
                );
              }}
            />
          )}
          {!isHybridHunt && !hidePhotos && (
            <DashTile
              icon={'aperture'}
              iconColor={constants?.color?.blue}
              text={'All Photos'}
              handlePress={() => {
                photoObjects?.length > 0
                  ? setShowPhotos(true)
                  : Alert.alert(`You haven't taken photos yet!`);
              }}
            />
          )}
          <DashTile
            icon={'life-buoy'}
            iconColor={constants?.color?.red}
            text={'Help'}
            handlePress={() => {
              navigationRef.navigate(
                'FAQModal',
                null,
                'ScavengerHunt_v2/Game/PlayerDash.tsx',
              );
            }}
          />
          {!playTestState && (
            <DashTile
              icon={'help-circle'}
              text={`FAQ`}
              iconColor={constants?.color?.magenta}
              handlePress={() => {
                navigationRef.navigate(
                  'FAQModal',
                  null,
                  'ScavengerHunt_v2/Game/PlayerDash.tsx',
                );
              }}
            />
          )}
          {!playTestState && (
            <DashTile
              icon={'help-circle'}
              text={`Call Support`}
              iconColor={constants?.color?.magenta}
              handlePress={() => {
                navigationRef.navigate(
                  'SupportModal',
                  {},
                  'ScavengerHunt_v2/Game/PlayerDash.tsx',
                );
              }}
            />
          )}
          {!!eventId && !isHybridHunt && !hideLeaderboard && (
            <DashTile
              icon={'call'}
              text={`Leaderboard`}
              iconColor={constants?.color?.purple}
              handlePress={() =>
                navigationRef.navigate(
                  'EventDashboard',
                  {},
                  'ScavengerHunt_v2/Game/PlayerDash.tsx',
                )
              }
            />
          )}
          {!!location && !!currentLocationId && !isHybridHunt && (
            <DashTile
              icon={'skip-forward'}
              text={`Skip Location`}
              iconColor={constants?.color?.purple}
              handlePress={() => handleNextLocationPress()}
            />
          )}
        </View>
      </ScrollView>

      <FooterOverlay>
        <PrimaryButton
          background={constants?.color?.gray3}
          text={'Back'}
          handlePress={() => {
            navigationRef.goBack('PlayerDash');
          }}
        />
      </FooterOverlay>
    </View>
  );
};

const DashTile = ({
  icon = '',
  iconColor = '',
  text = '',
  handlePress = () => {},
}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <TouchableOpacity
      onPress={handlePress}
      disabled={!handlePress}
      style={{
        width: (width - 30) / 2,
        margin: 5,
        backgroundColor: constants?.color?.white1,
        paddingHorizontal: 15,
        paddingBottom: 20,
        paddingTop: 10,
        borderRadius: 10,
      }}>
      <FeatherIcon
        name={icon}
        size={22}
        color={iconColor || constants?.color?.orange}
      />
      <BodyText
        text={text}
        textStyle={{marginLeft: 0, marginTop: 10, fontWeight: '700'}}
      />
    </TouchableOpacity>
  );
};

export default Dashboard;

import {
  Alert,
  ImageBackground,
  Share,
  Text,
  View,
  ImageSourcePropType,
} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import React, {useEffect, useState} from 'react';
import {TopTab, TopTabButton} from 'src/Modules/TabViews';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {
  apiCheckAnyChallenge,
  apiFavoriteLocation,
  apiGetLocalGuideDetails,
  apiSignGuestBook,
} from '../Utils/apiCalls';
import {
  setLocalChallenges,
  setShowCreateChallengeModal,
  updateSelectedLocalLocationId,
} from '../Redux/reducers/local.reducer';
import {analytics} from 'src/Utils/db';

import {BodyText} from 'src/Modules/CommonView/Text';
import {ButtonIcon} from 'src/Modules/ButtonMenu';
import ButtonMenu from 'src/Modules/ButtonMenu';
import ChallengeFeedbackElement from '../ScavengerHunt_v2/Game/ChallengeFeedbackElement';
import Geocoder from 'react-native-geocoding';
import GetDirectionsCard from 'src/Modules/GetDirectionsCard';
import {HeaderAndCarouselView} from 'src/Modules/CommonView/HeaderAndCarouselView';
import LocalChallenges from './LocalChallenges';
import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';
import SimpleStarRating from 'src/Modules/CommonView/SimpleStarRating';
import TagInput from 'src/Modules/CommonView/TagInput';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import {localProfileBackups} from 'src/Data/LocalProfiles';

import {orderHuntsByProximity} from '../Utils/distanceHelpers';

import {useQuery} from '@tanstack/react-query';
import {useUserId} from '../Hooks/reduxHooks';
import {SimpleHeaderBar} from 'src/HeaderComponent/HeaderComponents';
import {SafeAreaView} from 'react-native-safe-area-context';
import {logCustomAnalyticsEvent} from '../Utils/fireUtils';
import {navigationRef} from '../Nav/navigationHelpers';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import openURLInBrowser from 'src/Utils/openURLInBrowser';
import constants from 'src/constants';
import {TLocalLocation} from 'src/types/TLocalLocation';
import {TChallenge, isTChallenge} from 'src/types/TChallenge';
import {TLocation} from 'src/types/TLocation';
import {LinearGradient} from 'expo-linear-gradient';
import {Creator} from 'src/Modules/CommonView/Creator';
import {CircleOverlapImages, SocialButton} from 'src/Modules/CommonView';
import StylizedBox from 'src/Assistant/StylizedBox';
import {dispatchAction} from 'src/Utils/helpers';
import getAppType from 'src/Utils/getAppType';

// Initialize the module (needs to be done only once)
// cSpell: disable next-line
Geocoder.init('AIzaSyBl9aZnUxqdmA_6o6Rr6zSDadHdPVL3ClQ'); // use a valid API key

interface AdditionalGuideContent {
  guide_short_description: string;
  guide_tips: string;
  submitted_by_user_name: string;
  submitted_by_user_image: string;
}

interface GuideContent {
  approved: string;
  content: string;
  flagged: string;
  id: string;
  location_id: string;
  submitted_by_user_id: string;
  submitted_by_user_image: string;
  submitted_by_user_name: string;
  type: string;
}

const LocalSiteModal: React.FC = () => {
  const {width} = useWindowDimensionsSafe();
  const email = useTypedSelector((state) => state?.user?.info?.email);
  const userId = useUserId();
  const photoUrl = useTypedSelector((state) => state?.user?.info?.photoUrl);

  const firstName = useTypedSelector((state) => state?.user?.info?.firstName);

  const customPhotoUrl = useTypedSelector(
    (state) => state?.user?.info?.customPhotoUrl,
  );

  const showCreateChallengeModal = useTypedSelector(
    (state) => state.local.showCreateChallengeModal,
  );

  const isAdmin =
    __DEV__ ||
    email?.includes('@letsroam.com') ||
    email?.includes('mfharding@gmail.com');

  const selectedLocalLocationId = useTypedSelector(
    (state) => state.local.selectedLocalLocationId,
  );

  const [guideContent, setGuideContent] = useState<GuideContent[] | null>(null);
  const [fullGuideInfo, setFullGuideInfo] = useState<
    AdditionalGuideContent | object
  >({});

  console.log('LocalSiteModal guide content', guideContent);
  const visitorImages: string[] = [];

  if (guideContent && guideContent?.length > 0) {
    guideContent?.map((item) => {
      if (item?.submitted_by_user_image) {
        visitorImages.push(item?.submitted_by_user_image);
      }
    });
  }

  const selectedLocation = useTypedSelector((state) => {
    const id = state.local.selectedLocalLocationId;
    return id ? state.local.localLocations[id] || {id} : ({} as TLocalLocation);
  });

  console.log({
    selectedLocalLocationId,
    selectedLocation,
    showCreateChallengeModal,
  });

  const locationAllData = {...selectedLocation, ...fullGuideInfo};

  const {
    title,
    id,
    guide_description,
    guide_short_description,
    guide_tips,
    photo,
    address,
    lat,
    long,
    rating = 1,
    tag_ids,
    submitted_by_user_id,
  } = {...selectedLocation, ...fullGuideInfo};

  let {submitted_by_user_name, submitted_by_user_image} = {
    ...selectedLocation,
    ...fullGuideInfo,
  };

  useEffect(() => {
    analytics().logViewItem({
      items: [
        {
          item_brand: 'local_site',
          item_name: title,
        },
      ],
    });
  }, [title]);

  const localChallengesList = useTypedSelector(
    (state) => state.local.localChallengesList,
  );
  const stopChallenges = localChallengesList.filter((challenge) => {
    return String(challenge.locationId) == String(id);
  });

  const localCompletionsList = useTypedSelector(
    (state) => state.local?.localCompletionsList,
  );

  console.log({localCompletionsList});

  const hasSignedGuestBook = !!localCompletionsList?.filter(
    (completion) =>
      String(completion.location_id) == String(id) &&
      completion.challenge_type == 'guide_guest_book_sign',
  )?.[0];

  const localFavorites = useTypedSelector(
    (state) => state.local.localFavorites,
  );

  const isFavorited = localFavorites?.includes?.(selectedLocalLocationId + '');

  const localDataRefetch = constants?.localDataRefetch;
  console.log({localDataRefetch});

  const challengeCount = (stopChallenges?.length || 0) + 1;

  const completions = localCompletionsList.filter(
    (completion) =>
      String(completion.location_id) == String(selectedLocation.id) &&
      completion.points_earned > 0,
  );

  console.log({completions});

  console.log('LocalSiteModal', {rating, completions});
  // constants?.devAlert(completions);

  let totalPoints = !!completions?.[0] ? 200 : 0;

  const completedChallengesCount =
    (stopChallenges?.filter((challenge) => {
      const completions = localCompletionsList.filter((completion) => {
        const isCompletion =
          completion.challenge_id == challenge.challenge_id &&
          completion.points_earned > 0;

        return isCompletion;
      });

      const completion = completions?.[0];

      const points_earned = completion?.points_earned || 0;

      totalPoints += Number(points_earned);
      const isComplete = !!completion;
      return isComplete;
    })?.length || 0) + Number(hasSignedGuestBook);

  if (hasSignedGuestBook) {
    totalPoints = totalPoints + 200;
  }

  const challengesLeft = challengeCount - completedChallengesCount;

  const handleShare = () => {
    Share.share({
      title: `Let's Roam Local Site`,
      message: `Explore ${title}, answer trivia, and complete hilarious photo challenges with me on Let's Roam Local!`,
      url: 'https://letsroam.page.link/local',
    });
  };

  const {data, refetch} = useQuery({
    queryKey: ['localLocationDataCall' + userId + id],
    queryFn: async () => {
      const localGuideDetailsAPIResponse = await apiGetLocalGuideDetails({
        location_id: String(id),
        user_id: userId || '',
      });
      console.log('pulling query data localGuideDetailsAPIResponse', {
        localGuideDetailsAPIResponse,
      });
      return localGuideDetailsAPIResponse;
    },
  });

  const handleAddChallenge = () => {
    navigationRef.navigate(
      'LocalCreateChallengeModal',
      null,
      'Local/LocalSiteModal.tsx',
    );
  };

  const updateQueryData = async (
    checkIfChallengesDone: boolean,
    additionalPoints: number,
  ) => {
    refetch();
    await localDataRefetch();
    if (checkIfChallengesDone && challengesLeft == 1) {
      setTimeout(() => {
        navigationRef.navigate(
          'ShareModal',
          {
            showCorrectPoints: totalPoints,
            shareOrigin: 'local',
            titleOverride: `Congrats you earned ${
              totalPoints + additionalPoints
            } point!`,
            shareOverride: handleShare,
            shareFeedbackTextOverride: (
              <Text>
                Congrats, on answering all the challenges at this location. Earn
                even more points by{' '}
                <TouchableWithoutFeedback onPress={() => handleShare()}>
                  <Text
                    style={{
                      // color: constants?.color?.teal,
                      textDecorationLine: 'underline',
                      color: 'white',
                    }}>
                    sharing
                  </Text>
                </TouchableWithoutFeedback>
                ,{' '}
                <TouchableWithoutFeedback
                  onPress={() => {
                    navigationRef?.goBack('Local/LocalSiteModal.tsx');
                    handleAddChallenge();
                  }}>
                  <Text
                    style={{
                      // color: constants?.color?.teal,
                      textDecorationLine: 'underline',
                      color: 'white',
                    }}>
                    suggesting new challenges for this location
                  </Text>
                </TouchableWithoutFeedback>
                ,{' '}
                <TouchableWithoutFeedback
                  onPress={() => {
                    navigationRef?.goBack('Local/LocalSiteModal.tsx');
                    navigationRef?.goBack('Local/LocalSiteModal.tsx');
                    navigationRef.navigate(
                      'LocalCreateLocationModal',
                      null,
                      'Local/LocalSiteModal.tsx',
                    );
                  }}>
                  <Text
                    style={{
                      // color: constants?.color?.teal,
                      textDecorationLine: 'underline',
                      color: 'white',
                    }}>
                    suggesting new locations for the guide.
                  </Text>
                </TouchableWithoutFeedback>
              </Text>
            ),
          },
          'LocalSiteModal 123',
        );
      }, 2000);
    }
  };

  const signGuestBook = () => {
    if (!userId) {
      return Alert.alert(
        'Please Sign In',
        'You must be signed in, in order to sign the guide!',
      );
    }
    console.log('guest book pressed');
    navigationRef.navigate(
      'ConfirmModal',
      {
        titleText: `Signing Guest Book for ${title}`,
        titleBodyText: `You are signing the guest book for this location. Write something fun or hopeful. All comments are moderated by our team.`,
        confirmText: `Sign Guest Book (+100 Points)`,
        titleIcon: 'book',
        showPrompt: true,
        multiline: true,
        placeholder: 'Write something fun or useful!',
        cancelText: 'Cancel',
        handleConfirm: async (content, __, handleDone) => {
          if (!content || content?.length < 10) {
            return Alert.alert(
              'Error!',
              'Please write more for the guest book.',
            );
          }
          const response = await apiSignGuestBook({
            location_id: String(id),
            content,
            submitted_by_user_id: userId,
            submitted_by_user_name: firstName || 'Anynomous',
            submitted_by_user_image: customPhotoUrl || photoUrl || null,
          });
          if (response && response?.error) {
            return Alert.alert('Error!', response?.error);
          }
          updateQueryData(true, 200);
          refetch();
          handleDone();
          Alert.alert('Thanks', 'You’ve signed the guestbook!');

          // Alert.alert(JSON.stringify(response));
        },
      },
      'LocalSiteModal',
    );
  };

  useEffect(() => {
    if (showCreateChallengeModal && title) {
      dispatchAction(setShowCreateChallengeModal(false));
      navigationRef.navigate(
        'ConfirmModal',
        {
          titleText: `Thank You For Making A New Location!`,
          titleBodyText: `You can make your location even better by creating challenges at it or signing the guest book.`,
          titleIcon: 'book',
          placeholder: 'Write something fun or useful!',
          confirmText: `Add Challenge To Location`,
          handleConfirm: async (_, __, handleDone) => {
            // Alert.alert(JSON.stringify(response));
            if (typeof handleDone == 'function') {
              handleDone();
            }
            handleAddChallenge();
          },
          cancelText: 'Sign Guest Book',
          cancelColor: constants?.color?.teal,
          handleCancel: () => {
            signGuestBook();
          },
          thirdOption: 'Maybe Later',
        },
        'LocalSiteModal.tsx',
      );
    }
  }, [showCreateChallengeModal, title]);

  // Alert.alert('issue', JSON.stringify(selectedLocation));

  if (!submitted_by_user_name) {
    const backupProfile = localProfileBackups[id % 20];
    submitted_by_user_name = backupProfile?.submitted_by_user_name;
    submitted_by_user_image = backupProfile?.submitted_by_user_image;
    // Alert.alert(JSON.stringify(submitted_by_user_image));
  }

  useEffect(() => {
    // Alert.alert(JSON.stringify(data?.full_guide_info));
    if (data?.guide_content) {
      setGuideContent(data?.guide_content || []);
    }
    if (data?.full_guide_info) {
      setFullGuideInfo(data?.full_guide_info || []);
    }
    if (data?.questions) {
      const newQuestions: Record<string, TChallenge> = {};
      data?.questions?.map?.((question: TChallenge) => {
        const newQuestion = {...question};
        if (newQuestion?.points) {
          newQuestion.points = Number(newQuestion?.points);
          newQuestion.db_id = String(newQuestion?.challengeId);
        }
        if (newQuestion?.coin_value) {
          newQuestion.coin_value = Number(newQuestion?.coin_value);
        }
        if (newQuestion?.lightning_timer_seconds) {
          newQuestion.lightning_timer_seconds = Number(
            newQuestion?.lightning_timer_seconds,
          );
        }

        if (newQuestion?.challenge_id) {
          newQuestion.challengeId = newQuestion?.challenge_id;
        }
        if (newQuestion?.challenge_medium) {
          newQuestion.type = newQuestion?.challenge_medium;
        }
        if (!newQuestion?.answers) {
          newQuestion.answers = [];
        } else {
          try {
            newQuestion.answers = Object.values(
              // @ts-ignore fixing data coming in
              JSON.parse(newQuestion?.answers),
            );
          } catch (e) {
            console.error('error parsing answers', {newQuestion});
          }
        }

        if (!isTChallenge(newQuestion, true)) {
          return console.error('invalid question 1', {newQuestion});
        } else if (!newQuestion?.challenge_id) {
          return console.error('invalid question 2', {question});
        }

        newQuestions[newQuestion.challenge_id] = newQuestion;
      });
      console.log('setting local challenges', {newQuestions});
      dispatchAction(setLocalChallenges(newQuestions));
    }
  }, [data]);

  const cardPress = (location: TLocalLocation) => {
    dispatchAction(updateSelectedLocalLocationId(String(location.id)));
  };

  const hunts = useTypedSelector((state) => state.hunts?.huntsList);

  const nearbyHunts = orderHuntsByProximity(hunts, {
    latitude: lat,
    longitude: long,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  });

  const wasSubmittedByUser = submitted_by_user_id == userId && !!userId;
  const [currentView, setCurrentView] = useState<string>(
    wasSubmittedByUser ? 'questions' : 'about',
  );

  const startChallenges = () => {
    console.log({localCompletionsList});

    const completion = completions?.[0];

    const handleThere = async () => {
      console.log('handle there');
      const checkParams = {
        location_id: id,
        photo_url: '',
        share_UUID: '',
        points_earned: 200,
        challenge_page: 'local',
        challenge_medium: 'guide_check_in',
        challenge_type: 'guide_check_in',
      };

      logCustomAnalyticsEvent('local_spot_checkin', {
        userId,
        ...checkParams,
        ...locationAllData,
      });

      console.log({checkParams});
      navigationRef?.goBack('Local/LocalSiteModal.tsx');
      setCurrentView('questions');
      await apiCheckAnyChallenge(checkParams);
      refetch();
      constants?.localDataRefetch?.();

      Alert.alert(
        'Congrats',
        'You can now solve the challenges at this location!',
      );
    };

    if (wasSubmittedByUser) {
      setCurrentView('questions');
    } else if (!completion) {
      const locationOverride: TLocation = {
        ...selectedLocation,
        locationId: String(selectedLocation.id),
        address: selectedLocation.address || undefined,
        photoLarge: selectedLocation.photo,
        photoThumb: selectedLocation.photo,
        name: selectedLocation.title,
        description: `Navigate to this location, so you can complete the challenges!`,
        challengeCount: challengeCount,
        isLocal: true,
        totalLocationPoints: 200,
        checkInOverride: (isClose: boolean, distanceAway: number) => {
          if (isClose) {
            handleThere();
          } else if (
            distanceAway < 1.5 * 5280 ||
            __DEV__ ||
            email?.includes('letsroam')
          ) {
            Alert.alert(
              `You're not quite there yet!`,
              'In order to answer the challenges, get as close as possible to the location.' +
                (__DEV__ || email?.includes('letsroam')
                  ? ' You can check in at a further distance since you are an admin!'
                  : ''),
              [
                {
                  text: 'Continue Looking For Spot (+100 pts)',
                  onPress: () => true,
                },
                {
                  text: 'Check In Anyway (+10 pts)',
                  onPress: async () => {
                    handleThere();
                  },
                },
              ],
            );
          } else {
            Alert.alert(
              'You Are Too Far Away',
              `You are too far away to start the challenges. Please navigate closer. You are ${distanceAway} feet away.`,
            );
          }
        },
      };
      navigationRef.navigate(
        'NavigationChallengeModal',
        {
          locationOverride,
        },
        'LocalSiteModal.tsx',
      );
    } else {
      setCurrentView('questions');
    }
  };

  const buttons = [
    {
      name: 'pencil',
      onPress: handleAddChallenge,
      title: 'Add A Challenge To Location',
    },

    {
      name: 'book',
      onPress: () => signGuestBook(),
      title: 'Sign Guest Book',
    },
  ];

  if (isAdmin) {
    buttons.push({
      name: 'user',
      onPress: () =>
        openURLInBrowser(
          `https://hventures2.letsroam.com/admin/app_dashboard/#/local/${id}`,
        ),
      title: 'Edit In Admin Dash',
    });
  }

  return (
    <SafeAreaView
      testID="localSiteModal"
      style={{
        flex: 1,
        alignItems: 'flex-start',
        backgroundColor: constants?.color.white1,
      }}>
      <SimpleHeaderBar showScore score={totalPoints || 0} />
      {getAppType() == 'lr' && (
        <TopTab>
          <TopTabButton
            text={'About'}
            active={currentView === 'about'}
            handlePress={() => {
              setCurrentView('about');
            }}
            index={0}
          />

          <TopTabButton
            text={`${challengeCount} Challenges`}
            active={currentView === 'questions'}
            handlePress={() => {
              startChallenges();
            }}
            index={1}
          />
        </TopTab>
      )}
      <ScrollView keyboardShouldPersistTaps="always">
        {currentView === 'about' && (
          <View>
            <View
              style={{
                flex: 1,
                backgroundColor: constants?.color.white1,
                width,
              }}>
              <ImageBackground
                source={{
                  uri: photo?.replace('./', 'https://photos.letsroam.com/'),
                }}
                style={{width, height: 250}}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'absolute',
                    top: 8,
                    left: 24,
                  }}>
                  <SocialButton
                    handlePress={() =>
                      Share.share({
                        title: `Let's Roam Local Site`,
                        message: `Check out ${title} in Let's Roam Local and do fun challenges!`,
                        url: 'https://letsroam.page.link/local',
                      })
                    }
                    text={`Share`}
                    icon={
                      require('src/Images/Icons/share.png') as ImageSourcePropType
                    }
                    color="lightblue"
                  />
                  <SocialButton
                    handlePress={async () => {
                      if (userId && selectedLocalLocationId) {
                        apiFavoriteLocation({
                          user_id: userId,
                          product_id: selectedLocalLocationId,
                          product_type: 'local_site',
                          favorited: !isFavorited,
                        });
                      }
                      const localDataRefetch = constants.localDataRefetch;
                      localDataRefetch();
                      // Alert.alert('This Location')
                    }}
                    text={isFavorited ? 'Saved' : 'Save'}
                    icon={require('src/Images/Icons/heart.png')}
                    color={'lightblue'}
                    // color={isFavorited ? 'red' : 'blue'}
                  />
                </View>
                <LinearGradient
                  colors={['#F9F7F2', 'rgba(249, 247, 242, 0.00)']}
                  start={{x: 0, y: 1}}
                  end={{x: 0, y: 0}}
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    height: '50%',
                    width: '100%',
                  }}>
                  <BodyText
                    testID="localGuideStop"
                    text="LOCAL GUIDE STOP"
                    textStyle={{
                      color: constants.color.orange,
                      fontFamily: 'JakartaBold',
                      fontSize: 11,
                      lineHeight: 11,
                      marginTop: 0,
                      marginLeft: 0,
                      letterSpacing: 1.1,
                      position: 'absolute',
                      bottom: 8,
                      left: 24,
                    }}
                  />
                </LinearGradient>
              </ImageBackground>
              <View style={{paddingHorizontal: 24}}>
                <BodyText
                  text={selectedLocation?.title}
                  textStyle={{
                    color: constants.color.black,
                    fontFamily: 'JakartaBold',
                    fontSize: 28,
                    lineHeight: 28,
                    marginTop: 12,
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
                <BodyText
                  text={guide_description || guide_short_description}
                  textStyle={{
                    color: constants.color.black,
                    fontFamily: 'Jakarta',
                    fontSize: 15,
                    lineHeight: 21,
                    marginTop: 12,
                    marginLeft: 0,
                    marginRight: 0,
                  }}
                />
              </View>

              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  paddingHorizontal: 5,
                  marginTop: 12,
                }}>
                <Creator
                  name={
                    (submitted_by_user_name || 'Michael')
                      ?.toUpperCase()
                      ?.split(' ')?.[0]
                  }
                  image={submitted_by_user_image}
                  width={36}
                  nameStyle={{fontSize: 15}}
                />

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}>
                  <CircleOverlapImages
                    containerStyle={{marginTop: 0}}
                    images={visitorImages}
                  />
                  <View style={{marginLeft: 5}}>
                    <Text
                      style={{
                        color: '#A7A8AA',
                        fontFamily: 'Jakarta',
                        fontSize: 12,
                        fontStyle: 'italic',
                        lineHeight: 16,
                        marginRight: 10,
                      }}>
                      Visited by
                    </Text>
                    <Text
                      style={{
                        color: constants.color.orange,
                        fontFamily: 'JakartaBold',
                        fontSize: 12,
                        lineHeight: 20,
                        marginTop: 3,
                      }}>
                      {guideContent && guideContent?.length > 10
                        ? `${guideContent?.length}+`
                        : '10+'}{' '}
                      members
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width,
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: constants?.color?.orange,
                    borderStyle: 'solid',
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                    padding: 10,
                    paddingBottom: 15,
                    marginTop: 20,
                  }}>
                  <SimpleStarRating
                    rating={Math.floor(rating || 1)}
                    containerStyle={{minWidth: 60}}
                  />

                  <View>
                    <BodyText
                      textStyle={{
                        color: '#505759',
                        fontWeight: 'bold',
                        fontFamily: 'JakartaBold',
                        fontSize: 16,
                        lineHeight: 20,
                        marginBottom: 0,
                        paddingBottom: 0,
                      }}
                      text={`Let's Roam ${Math.max(rating, 1)} Star Site`}
                    />
                    <BodyText
                      text={
                        rating == 3
                          ? 'Worth Planning To See'
                          : rating == 2
                          ? 'Worth A Detour'
                          : rating == -2
                          ? 'Waiting For Review'
                          : 'Worth A Stop'
                      }
                      textStyle={{
                        marginTop: 5,
                        marginBottom: 5,
                        fontSize: 14,
                        fontStyle: 'italic',
                        fontFamily: 'Jakarta',
                      }}
                    />
                  </View>
                </View>
              </View>
              {!!guide_tips && (
                <View style={{marginTop: 20, paddingHorizontal: 24}}>
                  <BodyText
                    text={'Know Before You Go'}
                    textStyle={{
                      color: constants.color.black,
                      fontFamily: 'JakartaBold',
                      fontWeight: 'bold',
                      fontSize: 17,
                      lineHeight: 16,
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  />
                  <BodyText
                    text={guide_tips}
                    textStyle={{
                      fontSize: 15,
                      lineHeight: 21,
                      fontFamily: 'Jakarta',
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  />
                </View>
              )}
              {!!tag_ids && tag_ids.length > 0 && (
                <View style={{paddingHorizontal: 24, marginTop: 20}}>
                  <BodyText
                    text={'Tags'}
                    textStyle={{
                      color: constants.color.black,
                      fontFamily: 'JakartaBold',
                      fontWeight: 'bold',
                      fontSize: 17,
                      lineHeight: 16,
                      marginLeft: 0,
                      marginRight: 0,
                    }}
                  />
                  <TagInput tag_ids={tag_ids} />
                </View>
              )}

              <GetDirectionsCard
                starting_location={address || 'Guide Location'}
                lat={lat || 0}
                long={long || 0}
                title={'Location:'}
                width={width - 20}
                style={{borderRadius: 10, marginTop: 30}}
                // hunt={currentHunt}
              />
            </View>
            <View style={{backgroundColor: constants?.color.white1}}>
              <HeaderAndCarouselView
                title={'Nearby Scavenger Hunts'}
                subTitle={
                  'Scavenger hunts are fully built activities with stories consisting of 8+ exclusive sites.'
                }
                type={'hunt_card'}
                initialMaxLength={7}
                extraMargin
                cardPress={cardPress}
                data={nearbyHunts?.filter((hunt) => hunt.hunt_type != 'inHome')}
              />
            </View>
          </View>
        )}

        {!hasSignedGuestBook && (
          <StylizedBox
            buttonContainerStyles={{marginBottom: 60}}
            containerStyles={{marginTop: 20, paddingHorizontal: 12}}
            backgroundContainerStyles={{
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
            }}
            ctaText="Sign"
            title="Sign the guestbook"
            backgroundColor={constants.color.orange}
            description={
              'Don’t forget to sign the guestbook to leave your mark on this local stop.'
            }
            onPress={() => {
              signGuestBook();
            }}
          />
        )}
        {currentView == 'questions' && (
          <LocalChallenges
            stopChallenges={stopChallenges}
            refetch={refetch}
            hasSignedGuestBook={hasSignedGuestBook}
            signGuestBook={signGuestBook}
            updateQueryData={updateQueryData}
            handleAddChallenge={handleAddChallenge}
            // wasSubmittedByUser={wasSubmittedByUser}
            // completionPercent={completionPercent}
            challengesLeft={challengesLeft}
            title={title}
          />
        )}
        <ChallengeFeedbackElement
          textStyle={{
            fontWeight: 'bold',
            fontSize: 18,
            textAlign: 'left',
            marginLeft: 0,
          }}
          containerStyle={{}}
        />
        <View style={{width: 200, height: 200}} />
      </ScrollView>
      <ModalOmniBar
        omniButton={
          currentView == 'about' && getAppType() == 'lr'
            ? {
                icon: require('src/Images/logo.png'),

                //icon: 'check',
                iconLibrary: 'FontAwesome',
                color: constants?.color?.orange,
                handlePress: () => {
                  startChallenges();
                },
                text: wasSubmittedByUser
                  ? 'Edit Site Challenges'
                  : 'Start Site Challenges',
              }
            : undefined
        }
        containerStyle={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
      <ButtonMenu
        style={{right: 30, bottom: 7}}
        // buttonTitle={'Contribute'}
        buttons={buttons}
      />
      <View
        style={{
          position: 'absolute',
          left: 30,
          bottom: 7,
        }}>
        <ButtonIcon
          name={'arrow-left'}
          onPress={() => {
            if (currentView == 'about') {
              navigationRef.navigate('LandingPageMain', null, 'LocalSiteModal');
            } else {
              setCurrentView('about');
            }
          }}
        />
        <Text style={{textAlign: 'center', fontSize: 10, marginTop: 2}}>
          {'Back'}
        </Text>
      </View>
    </SafeAreaView>
  );
};

export default LocalSiteModal;

import React from 'react';
import {View, Image, ImageBackground} from 'react-native';
import {BodyText, ContinueButton} from 'src/Modules/CommonView';

const StylizedBox = ({
  showChatIcon = false,
  title = '',
  description = '',
  ctaText = '',
  onPress = () => {},
  containerStyles = {},
  textStyles = {},
  buttonContainerStyles = {},
  backgroundContainerStyles = {},
  backgroundColor = '#6AAEAA',
  showAltCTA = false,
}) => {
  const chatBubble = require('src/Images/Icons/chat_bubble.png');
  return (
    <View style={{paddingHorizontal: 24, marginTop: 20, ...containerStyles}}>
      {!!showChatIcon && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#6AAEAA',
            width: 114,
            height: 72,
            top: 25,
            borderTopLeftRadius: 73,
            borderTopRightRadius: 73,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            marginBottom: 0,
            overflow: 'hidden',
            alignSelf: 'center',
            zIndex: 100,
          }}>
          <Image
            source={chatBubble}
            style={{
              width: 46,
              height: 46,
              resizeMode: 'contain',
            }}
          />
        </View>
      )}
      <ImageBackground
        source={require('src/Images/stylized_bg.png')}
        resizeMode={'stretch'}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 0,
          backgroundColor: backgroundColor,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          ...backgroundContainerStyles,
        }}>
        <BodyText
          text={title}
          textStyle={{
            color: 'white',
            lineHeight: 20,
            marginTop: 50,
            marginLeft: 0,
            marginRight: 0,
            fontSize: 20,
            fontWeight: '800',
            fontFamily: 'JakartaBold',
            ...textStyles,
          }}
        />
        <BodyText
          text={description}
          textStyle={{
            color: 'white',
            width: 250,
            textAlign: 'center',
            marginTop: 6,
            fontSize: 14,
            fontFamily: 'Jakarta',
            ...textStyles,
          }}
        />
        <ContinueButton
          text={ctaText}
          textStyle={{fontFamily: 'JakartaBold'}}
          testID={'askMyPA'}
          background={'black'}
          containerStyle={{
            marginTop: 16,
            marginBottom: 30,
            borderRadius: 6,
            ...buttonContainerStyles,
          }}
          width={290}
          height={50}
          handlePress={() => {
            onPress();
          }}
        />
      </ImageBackground>
      {!!showAltCTA && (
        <View
          style={{
            backgroundColor: '#4F8584',
            paddingVertical: 15,
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Image
            source={require('src/Images/arrow_vector.png')}
            style={{
              width: 10,
              height: 10,
              resizeMode: 'cover',
            }}
          />
        </View>
      )}
    </View>
  );
};

export default StylizedBox;

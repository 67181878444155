import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect} from 'react';
import {Alert, ScrollView, View} from 'react-native';

//////////////////////////
///////  components
import ChallengesList from 'src/ScavengerHunt_v2/Classic/ChallengesList';
import Header from 'src/ScavengerHunt_v2/Classic/Header';

import {setHuntTimerStart} from 'src/Utils/huntFunctions_classic';

import HuntOutro from 'src/ScavengerHunt_v2/Game/HuntOutro';

const ClassicChallenges: React.FC = () => {
  const timerFinish = useTypedSelector((state) => state.game_v2?.timerFinish);

  const timerStart = useTypedSelector((state) => state.game_v2?.timerStart);

  const timerLimitMinutes =
    useTypedSelector((state) => state.game_v2?.timerLimitMinutes) || 60;

  useEffect(() => {
    // start the timer if it is not active
    if (!timerStart) {
      console.log('----------- set start time -----------------');
      setHuntTimerStart();
    }

    // start the timer if it is not active
    if (timerStart && !timerFinish) {
      const timePassedSinceStart = (Date.now() - timerStart) / 1000;
      if (timePassedSinceStart >= timerLimitMinutes * 60) {
        setHuntTimerStart();
        // navigationRef.devAlert({
        //   timePassedSinceStart,
        //   timerLimitMinutes,
        //   timerStart,
        //   timerFinish,
        // });

        Alert.alert(`Time's Up!!`);
      }
    }
  }, []);

  const almostEndOfHunt =
    timerStart &&
    timerLimitMinutes * 60 * 1000 - (Date.now() - timerStart) <
      constants?.checkoutTimeLimit * 60 * 1000;

  const classicChallengeList =
    useTypedSelector((state) => state.game_v2?.classicChallengeList) || [];

  const {availableChallengeIds, completedChallengeIds} = useTypedSelector(
    (state) => {
      const allChallenges = state.game_v2?.allChallenges;
      const playerChallenges = Object?.values(
        state?.playerChallenges?.playerChallengeData || {},
      );

      // These will hold the IDs of available and completed challenges.
      const availableChallengeIds: string[] = [];
      const completedChallengeIds: string[] = [];

      classicChallengeList.length &&
        classicChallengeList.forEach((key: string) => {
          const locationChallenge = allChallenges && allChallenges[key];
          const isDone =
            locationChallenge?.completeTime || locationChallenge?.photoUrl;

          if (isDone) {
            locationChallenge && completedChallengeIds.push(key);
          } else if (locationChallenge?.show ?? true) {
            locationChallenge && availableChallengeIds.push(key);
          }
        });

      playerChallenges?.length &&
        playerChallenges?.forEach((playerChallenge) => {
          const isDone =
            playerChallenge?.completeTime || playerChallenge?.photoUrl;

          if (isDone) {
            console.log('am i in here', playerChallenge?.challengeId);
            completedChallengeIds.push(playerChallenge?.challengeId);
          }
        });

      return {
        availableChallengeIds,
        completedChallengeIds,
      };
    },
    deepEqualityCheck,
  );

  console.log({availableChallengeIds, completedChallengeIds});

  if (timerFinish) {
    return <HuntOutro />;
  }

  return (
    <View style={{flex: 1, backgroundColor: constants?.color?.white1}}>
      <ScrollView style={{flex: 1}}>
        <Header />
        <View style={{flex: 1, paddingBottom: 60}}>
          <ChallengesList
            almostEndOfHunt={almostEndOfHunt || false}
            availableChallengeIds={availableChallengeIds}
            completedChallengeIds={completedChallengeIds}
          />
        </View>
      </ScrollView>
    </View>
  );
};

export default ClassicChallenges;

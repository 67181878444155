import constants from 'src/constants';
import React from 'react';
import {Platform, TouchableOpacity, View, ViewStyle} from 'react-native';

import {BodyText} from 'src/Modules/CommonView';
import {joinHunt} from 'src/Utils/huntFunctions';

import moment from 'moment';
import {useUserId} from 'src/Hooks/reduxHooks';
import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

interface ContinueHuntCardProps {
  warning?: boolean;
  containerStyle?: ViewStyle;
  width?: number;
  clickText?: string;
  cardText?: string;
  isEventHome?: boolean;
}

const ContinueHuntCard: React.FC<ContinueHuntCardProps> = ({
  warning = false,
  width,
  clickText = 'Continue',
  cardText,
  isEventHome = false,
}) => {
  console.log('ContinueHuntCard render');
  const {width: windowWidth} = useWindowDimensionsSafe();

  let showPreviousHuntCard = false;

  const isHybridHunt = useTypedSelector(
    (state) =>
      [
        'coworker_feud',
        'escape_room',
        'murder_mystery',
        'virtual_office_games',
      ].includes(state.user?.info?.currentHuntInfo?.huntType || ''),
    deepEqualityCheck,
  );

  const currentHuntInfo = useTypedSelector(
    (state) => state.user?.info?.currentHuntInfo,
    deepEqualityCheck,
  );

  const userId = useUserId();

  const eventId = useTypedSelector((state) => state.event?.eventId);

  const dateEventLastJoined = useTypedSelector(
    (state) =>
      state.user?.info?.currentHuntInfo?.dateEventLastJoined ||
      state.user?.info?.currentHuntInfo?.dateEventCreated,
  );

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    const formattedDate = date.toLocaleDateString('en-US', options);

    return formattedDate;
  };

  if (dateEventLastJoined && userId) {
    const a = moment();
    const b = moment(dateEventLastJoined);
    const diffHours = a?.diff?.(b, 'hours');
    console.log({diffHours});
    if (dateEventLastJoined && diffHours < 24 * 5) {
      console.log('ContinueHuntCard', {diffHours});
      showPreviousHuntCard = true;
    }
  }

  const handleJoinHuntPress = async (groupId: string) => {
    joinHunt(groupId, userId, 'Rejoining Hunt');
  };

  if (!showPreviousHuntCard) {
    console.log('not showing continue hunt card', {currentHuntInfo});
    return <View></View>;
  }

  console.log('showing continue hunt card', {currentHuntInfo, eventId});

  // if we're on the event home we want to make sure
  // these match so we're rejoining the correct team / hunt
  if (
    (Platform.OS === 'web' || isEventHome) &&
    eventId &&
    eventId !== currentHuntInfo?.eventId
  ) {
    return <View></View>;
  }

  return (
    <TouchableOpacity
      testID="continueHunt"
      onPress={() => handleJoinHuntPress(currentHuntInfo?.groupId || '')}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 10,
          marginTop: 10,
        }}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            backgroundColor: '#D9D9D9',
            alignItems: 'center',
            paddingVertical: 12,
            paddingHorizontal: 15,
            borderRadius: 6,
            width: (width || windowWidth) - 80,
            flex: 1,
            shadowColor: '#000',
            shadowOffset: {width: 0, height: 1},
            shadowOpacity: 0.3,
            shadowRadius: 3,
            borderColor: constants.color.gray2,
            borderWidth: 1,
          }}>
          <View
            style={{
              backgroundColor: constants?.color?.orange,
              height: 30,
              marginHorizontal: 5,
              borderRadius: 5,
              justifyContent: 'center',
              alignItems: 'center',
              width: 130,
            }}>
            <BodyText
              text={clickText}
              textStyle={{
                color: constants?.color?.white,
                fontFamily: 'JakartaBold',
                fontSize: 15,
                fontWeight: '500',
                marginTop: 0,
                marginBottom: 0,
                padding: 0,
              }}
            />
          </View>
          <BodyText
            text={
              cardText
                ? cardText
                : warning
                ? `Warning: You already started a ${
                    isHybridHunt
                      ? (currentHuntInfo?.huntName + ' ' || '') + 'event'
                      : (currentHuntInfo?.huntCity + ' ' || '') +
                        'scavenger hunt'
                  } recently on ${
                    currentHuntInfo?.dateEventLastJoined || 'unknown'
                  }! To continue this ${
                    isHybridHunt ? 'event' : 'hunt'
                  }, please tap here >>`
                : `Continue your ${
                    isHybridHunt
                      ? (currentHuntInfo?.huntName + ' ' || '') + 'event'
                      : (currentHuntInfo?.huntCity + ' ' || '') +
                        'scavenger hunt'
                  } started on ${
                    currentHuntInfo?.dateEventLastJoined
                      ? formatDate(currentHuntInfo?.dateEventLastJoined)
                      : 'unknown'
                  } `
            }
            textStyle={{
              color: 'black',
              fontSize: 12,
              fontWeight: '500',
              marginBottom: 5,
              padding: 0,
              fontFamily: 'Jakarta',
              flex: 1,
            }}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default ContinueHuntCard;

import * as t from 'io-ts';
import {validateResult} from 'src/types/typesHelpers';

// io-ts type for completion in Photo
const CompletionIO = t.partial({
  photoUrl: t.string,
});

// io-ts type for Photo
const TPhotoIO = t.partial({
  UUID: t.union([t.string, t.null]),
  thumbnail: t.union([t.string, t.null]),
  share_photo: t.union([t.string, t.null]),
  photoURL: t.union([t.string, t.null]),
  photoUrl: t.union([t.string, t.null]),
  downloadURL: t.union([t.string, t.null]),
  completion: CompletionIO,
  completions: t.record(t.string, t.boolean),
  challengeText: t.union([t.string, t.null]),
  challenge: t.union([t.string, t.null]),
  question: t.union([t.string, t.null]),
  task: t.union([t.string, t.null]),
  description: t.union([t.string, t.null]),
  city: t.union([t.string, t.null]),
  state: t.union([t.string, t.null]),
  type: t.union([t.string, t.null]),
  id: t.union([t.string, t.null]),
  userId: t.union([t.string, t.null]),
  email: t.union([t.string, t.null]),
  groupId: t.union([t.string, t.null]),
  shareUUID: t.union([t.string, t.null]), // uuid for sharing completed challenge
  challenge_id: t.union([t.string, t.null]),
  path: t.union([t.string, t.null]),
});

// Derive the TypeScript type from the io-ts type
export type TPhoto = t.TypeOf<typeof TPhotoIO>;

// Type guard for runtime validation
export const isTPhoto = (
  input: unknown,
  showErrors: boolean,
): input is TPhoto => {
  return validateResult(input, TPhotoIO, 'TPhoto', showErrors);
};

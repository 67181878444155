import React from 'react';
import {ActivityIndicator, Platform, Text, ViewStyle} from 'react-native';

// importing detox
let isDetoxSync: () => boolean;
if (Platform.OS !== 'web') {
  const detoxModule = require('react-native-is-detox');
  isDetoxSync = detoxModule.isDetoxSync;
} else {
  isDetoxSync = () => false;
}

interface CustomActivityIndicatorProps {
  color?: string;
  style?: ViewStyle;
  size?: 'small' | 'large' | number;
  animating?: boolean;
}

const CustomActivityIndicator: React.FC<CustomActivityIndicatorProps> = (
  props = {},
) => {
  if (isDetoxSync()) {
    console.log('hiding activity indicator on detox');
    return <Text style={{color: props?.color || 'black'}}>Loading Mock..</Text>;
  }
  return <ActivityIndicator {...props} />;
};

export default CustomActivityIndicator;

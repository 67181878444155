import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useEffect} from 'react';
import {StyleSheet, View, Platform} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {
  PanGestureHandler,
  PanGestureHandlerGestureEvent,
} from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  withSpring,
  useSharedValue,
} from 'react-native-reanimated';
import {mmkvStorage} from 'src/Utils/mmkvStorage';

const topPadding = 140;
const bottomPadding = 100;
const sidePadding = 40;

interface CTX {
  [key: string]: unknown;
  x: number;
  y: number;
  startX: number;
  startY: number;
}

interface DraggableButtonProps {
  children: React.ReactNode; // Children is typically of type ReactNode in React.
  size: number; // Assuming size can be a number (e.g., pixel value) or string (e.g., '2em'). Adjust if needed.
}

const DraggableButton: React.FC<DraggableButtonProps> = ({children, size}) => {
  const getInitialValue = (label = 'string') => {
    const initialValue = mmkvStorage.getString(label);
    if (initialValue === '1') {
      return true;
    } else if (initialValue === '0') {
      return false;
    } else {
      // unset defaults to top
      return true;
    }
  };
  const {width, height} = useWindowDimensionsSafe();
  const snapPointsX = [0, width - size - sidePadding];
  const snapPointsY = [0, height - size - topPadding - bottomPadding];

  const translateX = useSharedValue(-100);
  const translateY = useSharedValue(-100);

  // console.log({translateX, translateY});

  const [xActivated, setXActivated] = React.useState<boolean>(
    getInitialValue('xDefault'),
  );
  const [yActivated, setYActivated] = React.useState<boolean>(
    getInitialValue('yDefault'),
  );

  const setDefaults = (xNew: boolean, yNew: boolean) => {
    console.log('onGestureEvent onEndEvent runOnJS', {
      xNew,
      yNew,
    });
    setXActivated(xNew);
    setYActivated(yNew);
  };

  const log = (a: unknown, b?: unknown) => {
    console.log(a, b);
  };

  const onGestureEvent = useAnimatedGestureHandler<
    PanGestureHandlerGestureEvent,
    CTX
  >({
    onStart: (_event, ctx: CTX) => {
      runOnJS(log)('onGestureEvent onStart');
      try {
        ctx.x = translateX.value;
        ctx.y = translateY.value;
      } catch (onStartError) {
        runOnJS(log)({onStartError});
      }
    },
    onActive: ({translationX, translationY}, ctx: CTX) => {
      runOnJS(log)('onGestureEvent onActive');
      translateX.value = ctx.x + translationX;
      translateY.value = ctx.y + translationY;
    },
    onEnd: async (onEndEvent) => {
      const {absoluteX, absoluteY, velocityX, velocityY} = onEndEvent;
      const xActivatedNew = absoluteX + 5 * velocityX > width / 2;
      const yActivatedNew = absoluteY + 5 * velocityY > height / 2;
      const snapPointX = !!xActivatedNew ? snapPointsX[1] : snapPointsX[0];
      const snapPointY = !!yActivatedNew ? snapPointsY[1] : snapPointsY[0];
      runOnJS(log)('onGestureEvent onEndEvent', {
        snapPointX,
        snapPointY,
        xActivatedNew,
        yActivatedNew,
      });
      translateX.value = withSpring(snapPointX, {velocity: velocityX * 2});
      translateY.value = withSpring(snapPointY, {velocity: velocityY * 2});
      try {
        runOnJS(setDefaults)(xActivatedNew, yActivatedNew);
      } catch (onEndError) {
        runOnJS(log)({onEndError});
      }
    },
  });

  useEffect(() => {
    console.log('useEffect draggable button', {xActivated, yActivated});
    mmkvStorage.set('xDefault', xActivated ? '1' : '0');
    mmkvStorage.set('yDefault', yActivated ? '1' : '0');
    console.log('useEffect draggable button done');
  }, [xActivated, yActivated]);

  const handleSize = () => {
    console.log('initial mount draggable buttom', {xActivated, yActivated});
    translateX.value = snapPointsX[xActivated ? 1 : 0];
    translateY.value = snapPointsY[yActivated ? 1 : 0];
  };

  useEffect(() => {
    handleSize();
  }, [size, width]);

  console.log({size});

  // testing if a modal is open
  const isModal = useTypedSelector(
    (state) =>
      [
        'FoxShop',
        'PhotoVideoCamera',
        'QuestionFeedBackModal',
        'LoginPage',
        'LoginModal',
      ]?.includes?.(state.current_screen?.currentScreen) ||
      state.current_screen?.currentScreen?.includes('Modal') ||
      state.current_screen?.isDrawerOpen,
  );

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{translateX: translateX.value}, {translateY: translateY.value}],
  }));

  if (isModal) {
    return <View></View>;
  }

  return (
    // eslint-disable-next-line no-use-before-define
    <Animated.View style={styles.container} pointerEvents={'box-none'}>
      <PanGestureHandler
        onGestureEvent={Platform.OS != 'web' ? onGestureEvent : () => {}}>
        <Animated.View style={animatedStyle}>
          <Animated.View style={{width: size, position: 'absolute', top: 0}}>
            {children}
          </Animated.View>
        </Animated.View>
      </PanGestureHandler>
    </Animated.View>
  );
};

export default DraggableButton;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    top: topPadding,
    left: sidePadding / 2,
    bottom: bottomPadding,
    right: sidePadding / 2,
  },
});

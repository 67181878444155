import {TThemesState} from 'src/types/TTheme';

export const defaultThemes: TThemesState = {
  themes: {
    'Team Building': {
      theme: 'Team Building',
      text: 'Team Building',
      short_description: '',
      description: '',
      coin_price: 0,
      dollar_price: 0,
      show_in_backpack: true,
      published: true,
      icon_source: '',
      default_players: 2,
      characters: [
        'team-building-1',
        'team-building-2',
        'team-building-3',
        'team-building-4',
        'team-building-5',
      ],
    },
    'Date Night': {
      theme: 'Date Night',
      text: 'Date Night',
      short_description: '',
      description: '',
      coin_price: 0,
      dollar_price: 0,
      show_in_backpack: true,
      published: true,
      icon_source: '',
      default_players: 2,
      characters: [
        'date-night-1',
        'date-night-2',
        'date-night-3',
        'date-night-4',
        'date-night-5',
      ],
    },
    Bachelorette: {
      theme: 'Bachelorette',
      text: 'Bachelorette',
      short_description: '',
      description: '',
      coin_price: 0,
      dollar_price: 0,
      show_in_backpack: true,
      published: true,
      icon_source: '',
      default_players: 2,
      characters: [
        'bachelorette-1',
        'bachelorette-2',
        'bachelorette-3',
        'bachelorette-4',
        'bachelorette-5',
      ],
    },
    Bachelor: {
      theme: 'Bachelor',
      text: 'Bachelor',
      short_description: '',
      description: '',
      coin_price: 0,
      dollar_price: 0,
      show_in_backpack: true,
      published: true,
      icon_source: '',
      default_players: 2,
      characters: ['bachelor-1', 'bachelor-2', 'bachelor-3', 'bachelor-4'],
    },
    Standard: {
      theme: 'Standard',
      text: 'Standard',
      short_description: '',
      description: '',
      coin_price: 0,
      dollar_price: 0,
      show_in_backpack: true,
      published: true,
      icon_source: '',
      default_players: 2,
      characters: [
        'standard-1',
        'standard-2',
        'standard-3',
        'standard-4',
        'standard-5',
      ],
    },
    Birthday: {
      theme: 'Birthday',
      text: 'Birthday',
      short_description: '',
      description: '',
      coin_price: 0,
      dollar_price: 0,
      show_in_backpack: true,
      published: true,
      icon_source: '',
      default_players: 2,
      characters: [
        'birthday-1',
        'birthday-2',
        'birthday-3',
        'birthday-4',
        'birthday-5',
      ],
    },
    'In-Home': {
      theme: 'In-Home',
      text: 'In-Home',
      short_description: '',
      description: '',
      coin_price: 0,
      dollar_price: 0,
      show_in_backpack: true,
      published: true,
      icon_source: '',
      default_players: 2,
      characters: [
        'in-home-1',
        'in-home-2',
        'in-home-3',
        'in-home-4',
        'in-home-5',
        'in-home-6',
      ],
    },
  },
  characters: {
    'team-building-1': {
      theme: 'Team Building',
      character: 'Photographer',
      description:
        'Will take additional fun photos and team pictures throughout the hunt',
      role_type: 'team-building-1',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1733867766208%2Fcamera.png?alt=media&token=f045ad70-44a1-4d18-b2bb-9e3988ec3055',
    },
    'team-building-2': {
      theme: 'Team Building',
      character: 'Quizmaster',
      description:
        'Will solve guess where, emoji decoder, and trivia challenges',
      role_type: 'team-building-2',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379652394%2Ficonmonstr-layer-multiple-lined.svg?alt=media&token=e65cdbc2-78d0-4e16-a806-4bf82a12a9d3',
    },
    'team-building-3': {
      theme: 'Team Building',
      character: 'Explorer',
      description: 'Wil tackle a mix of all the challenges from other roles',
      role_type: 'team-building-3',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379662655%2Ficonmonstr-duplicate-filled.svg?alt=media&token=cf186e75-94f1-49fe-acc1-e92e926c9b18',
    },
    'team-building-4': {
      theme: 'Team Building',
      character: 'Youngster',
      description:
        ' Will have easy and fun trivia, true/false, and emoji decoder challenges for kids. Perfect for kids 10 and under!',
      role_type: 'team-building-4',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727473948719%2Ficonmonstr-restore-lined.svg?alt=media&token=ea854a2b-997e-47f3-bbb0-925e78bdc927',
    },
    'team-building-5': {
      theme: 'Team Building',
      character: 'Navigator',
      description:
        'Will answer guess where challenges, world trivia, and geography based trivia',
      role_type: 'team-building-5',
      source: '',
    },
    'date-night-1': {
      theme: 'Date Night',
      character: 'The Keeper',
      description:
        'Will take additional fun photos and team pictures throughout the hunt',
      role_type: 'date-night-1',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379633862%2Ficonmonstr-restore-lined.svg?alt=media&token=b4781195-33ca-4386-9ba1-ef01e70eb3c1',
    },
    'date-night-2': {
      theme: 'Date Night',
      character: 'Heartthrobb',
      description:
        'Will solve guess where, emoji decoder, and trivia challenges',

      role_type: 'date-night-2',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379652394%2Ficonmonstr-layer-multiple-lined.svg?alt=media&token=e65cdbc2-78d0-4e16-a806-4bf82a12a9d3',
    },
    'date-night-3': {
      theme: 'Date Night',
      character: 'Romantic',
      description: 'Will tackle a mix of challenges from all the other roles',
      role_type: 'date-night-3',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379662655%2Ficonmonstr-duplicate-filled.svg?alt=media&token=cf186e75-94f1-49fe-acc1-e92e926c9b18',
    },
    'date-night-4': {
      theme: 'Date Night',
      character: 'Kiddos (10 & Under)',
      description:
        'Will answer guess where challenges, world trivia, and geography-based trivia',

      role_type: 'date-night-4',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727473948719%2Ficonmonstr-restore-lined.svg?alt=media&token=ea854a2b-997e-47f3-bbb0-925e78bdc927',
    },
    'date-night-5': {
      theme: 'Date Night',
      character: 'Navigator',
      description:
        'This user will get guess where challenges, world trivia, geography based trivia',
      role_type: 'date-night-5',
      source: '',
    },
    'bachelorette-1': {
      theme: 'Bachelorette',
      character: 'The Photographer',
      description:
        'Get ready to capture extra special moments as you take additional fun photos and team pictures throughout the hunt',
      role_type: 'bachelorette-1',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379633862%2Ficonmonstr-restore-lined.svg?alt=media&token=b4781195-33ca-4386-9ba1-ef01e70eb3c1',
    },
    'bachelorette-2': {
      theme: 'Bachelorette',
      character: 'Wedding Planner',
      description:
        'Like a true wedding planner, this role has their finger on the pulse, especially their trivia! You will answer pop culture and historical challenges, including guess where, emoji decoder, and trivia challenges',
      role_type: 'bachelorette-2',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379652394%2Ficonmonstr-layer-multiple-lined.svg?alt=media&token=e65cdbc2-78d0-4e16-a806-4bf82a12a9d3',
    },
    'bachelorette-3': {
      theme: 'Bachelorette',
      character: 'Bridal Party',
      description:
        'Like an actual bridal party, this role has fun mix! You wll answer challenges of all types from the other roles!',
      role_type: 'bachelorette-3',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379662655%2Ficonmonstr-duplicate-filled.svg?alt=media&token=cf186e75-94f1-49fe-acc1-e92e926c9b18',
    },
    'bachelorette-4': {
      theme: 'Bachelorette',
      character: 'Dancing Queen',
      description:
        'On the floor or on the hunt, you leasd the way, so you better be good at directions! You willget guess where challenges, world trivia, geography based trivia',
      role_type: 'bachelorette-4',
      source: '',
    },
    'bachelorette-5': {
      theme: 'Bachelorette',
      character: 'The Flower Girl',
      description:
        'The youngest of the brides bunch and perfect for kids 10 & under! You will have easy and fun trivia, true/false, and emoji decoder challenges for kids.',
      role_type: 'bachelorette-5',
      source: '',
    },
    'bachelor-1': {
      theme: 'Bachelor',
      character: 'Group Photographer',
      description: 'You will take group photos',
      role_type: 'bachelor-1',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379633862%2Ficonmonstr-restore-lined.svg?alt=media&token=b4781195-33ca-4386-9ba1-ef01e70eb3c1',
    },
    'bachelor-2': {
      theme: 'Bachelor',
      character: 'Trivia Expert',
      description: 'Guess where, emoji decoder, and trivia challenges',
      role_type: 'bachelor-2',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379652394%2Ficonmonstr-layer-multiple-lined.svg?alt=media&token=e65cdbc2-78d0-4e16-a806-4bf82a12a9d3',
    },
    'bachelor-3': {
      theme: 'Bachelor',
      character: 'Balanced Explorer',
      description: 'Get a mix of all the challenge types!',
      role_type: 'bachelor-3',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379662655%2Ficonmonstr-duplicate-filled.svg?alt=media&token=cf186e75-94f1-49fe-acc1-e92e926c9b18',
    },
    'bachelor-4': {
      theme: 'Bachelor',
      character: 'Navigator',
      description:
        'This user will get guess where challenges, world trivia, geography based trivia',
      role_type: 'bachelor-4',
      source: '',
    },
    'standard-1': {
      theme: 'Standard',
      character: 'Shutterbug',
      description:
        'Will take additional fun photos and team pictures throughout the hunt',
      role_type: 'standard-1',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379633862%2Ficonmonstr-restore-lined.svg?alt=media&token=b4781195-33ca-4386-9ba1-ef01e70eb3c1',
    },
    'standard-2': {
      theme: 'Standard',
      character: 'Quizmaster',
      description:
        'Will solve guess where, emoji decoder, and trivia challenges',
      role_type: 'standard-2',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379652394%2Ficonmonstr-layer-multiple-lined.svg?alt=media&token=e65cdbc2-78d0-4e16-a806-4bf82a12a9d3',
    },
    'standard-3': {
      theme: 'Standard',
      character: 'Explorer',
      description: 'Will tackle a mix of challenges from all the other roles',
      role_type: 'standard-3',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379662655%2Ficonmonstr-duplicate-filled.svg?alt=media&token=cf186e75-94f1-49fe-acc1-e92e926c9b18',
    },
    'standard-4': {
      theme: 'Standard',
      character: 'Youngster ',
      description:
        'Perfect for kids 10 and under! Will have easy and fun trivia, true/false, and emoji decoder challenges for kids. ',
      role_type: 'standard-4',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727473948719%2Ficonmonstr-restore-lined.svg?alt=media&token=ea854a2b-997e-47f3-bbb0-925e78bdc927',
    },
    'standard-5': {
      theme: 'Standard',
      character: 'Navigator',
      description:
        'Will answer guess where challenges, world trivia, and geography-based trivia',
      role_type: 'standard-5',
      source: '',
    },
    'birthday-1': {
      theme: 'Birthday',
      character: 'Surprise Shutterbug',
      description: 'Photo Challenges | Video Challenges',
      role_type: 'birthday-1',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379633862%2Ficonmonstr-restore-lined.svg?alt=media&token=b4781195-33ca-4386-9ba1-ef01e70eb3c1',
    },
    'birthday-2': {
      theme: 'Birthday',
      character: 'Wish Whiz',
      description: 'Trivia Challenges | Guess Where | Emoji Decoder',
      role_type: 'birthday-2',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379652394%2Ficonmonstr-layer-multiple-lined.svg?alt=media&token=e65cdbc2-78d0-4e16-a806-4bf82a12a9d3',
    },
    'birthday-3': {
      theme: 'Birthday',
      character: 'Confetti Conqueror',
      description:
        'Trivia Challenges | Guess Where | Emoji Decoder | Photo Challenges | Video Challenges ',
      role_type: 'birthday-3',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379662655%2Ficonmonstr-duplicate-filled.svg?alt=media&token=cf186e75-94f1-49fe-acc1-e92e926c9b18',
    },
    'birthday-4': {
      theme: 'Birthday',
      character: 'Pinata Prodigy | 10 & Under',
      description:
        'Players 10 & Under | Kid-Friendly Trivia | Kid-Themed Emoji Decoder | Fun Photo Challenges',
      role_type: 'birthday-4',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727473948719%2Ficonmonstr-restore-lined.svg?alt=media&token=ea854a2b-997e-47f3-bbb0-925e78bdc927',
    },
    'birthday-5': {
      theme: 'Birthday',
      character: 'Party Pathfinder',
      description: 'Geography Trivia | Guess Where Challenges',
      role_type: 'birthday-5',
      source: '',
    },
    'in-home-1': {
      theme: 'In-Home',
      character: 'Group Photographer',
      description: 'You will take group photos',
      role_type: 'in-home-1',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379633862%2Ficonmonstr-restore-lined.svg?alt=media&token=b4781195-33ca-4386-9ba1-ef01e70eb3c1',
    },
    'in-home-2': {
      theme: 'In-Home',
      character: 'Trivia Expert',
      description: 'Guess where, emoji decoder, and trivia challenges',
      role_type: 'in-home-2',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379652394%2Ficonmonstr-layer-multiple-lined.svg?alt=media&token=e65cdbc2-78d0-4e16-a806-4bf82a12a9d3',
    },
    'in-home-3': {
      theme: 'In-Home',
      character: 'Balanced Explorer',
      description: 'Get a mix of all the challenge types!',
      role_type: 'in-home-3',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727379662655%2Ficonmonstr-duplicate-filled.svg?alt=media&token=cf186e75-94f1-49fe-acc1-e92e926c9b18',
    },
    'in-home-4': {
      theme: 'In-Home',
      character: 'Youngster',
      description:
        'This role will have easy trivia, true/false, and emoji decoder challenges for kids',
      role_type: 'in-home-4',
      source:
        'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FcustomCharacters%2F1727473948719%2Ficonmonstr-restore-lined.svg?alt=media&token=ea854a2b-997e-47f3-bbb0-925e78bdc927',
    },
    'in-home-5': {
      theme: 'In-Home',
      character: 'Navigator',
      description:
        'This user will get guess where challenges, world trivia, geography based trivia',
      role_type: 'in-home-5',
      source: '',
    },
    'in-home-6': {
      theme: 'In-Home',
      character: 'Puzzle master',
      description:
        'Hard puzzles to decode like riddles, hard emoji decoders, word scramble and the like',
      role_type: 'in-home-6',
      source: '',
    },
  },
};

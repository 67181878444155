import constants from 'src/constants';
import React, {useEffect} from 'react';
import {View, ScrollView, Platform} from 'react-native';

import HomeActivityLists from 'src/LandingPage/Home/HomeActivityLists';

import HuntSearchView from 'src/LandingPage/Home/HuntSearchView';

import {setCurrentGroupId} from 'src/Redux/reducers/user_info.reducer';
import {clearGroupInfo} from 'src/Redux/reducers/groupInfo.reducer';

import {setPreviousVisit} from 'src/Redux/reducers/current_screen.reducer';
import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {dispatchAction} from 'src/Utils/helpers';
import HomeLandingMap from './HomeLandingMap';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import DelayedLoad from 'src/Nav/DelayedLoad';
import {joinHunt, startHunt} from 'src/Utils/huntFunctions';
import {useUserId} from 'src/Hooks/reduxHooks';

const HomeLandingTab: React.FC = () => {
  console.log('HomeLandingTab render');

  const eventId = useTypedSelector((state) => state.event.eventId);
  const groupId = useTypedSelector(
    (state) => state.user?.info?.currentHuntInfo?.groupId,
  );
  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.event?.info?.hunt_type || ''),
  );
  const huntId = useTypedSelector((state) => state.event?.info?.hunt_id);
  const userId = useUserId();

  const checkPreviousVisit = async () => {
    const previousVisit = !!(await mmkvStorage.getString('previousVisit'))
      ? true
      : false;
    if (!previousVisit) {
      setTimeout(() => {
        mmkvStorage.set('previousVisit', 'true');
        dispatchAction(setPreviousVisit(1));
      }, 500);
    }
  };

  useEffect(() => {
    checkPreviousVisit();
  }, []);

  useEffect(() => {
    dispatchAction(setCurrentGroupId(null));
    dispatchAction(clearGroupInfo());
  }, []);

  useEffect(() => {
    const joinAndStartHunt = async () => {
      if (
        Platform.OS === 'web' &&
        window?.location?.href?.includes('inAppDemo') &&
        huntId &&
        eventId &&
        isHybridHunt
      ) {
        await joinHunt(
          `group1${eventId.replace('-demo', '')}`,
          userId,
          'in app demo',
        );
        startHunt(`group1${eventId.replace('-demo', '')}`);
      }
    };
    joinAndStartHunt();
  }, [huntId]);

  return (
    <View
      style={{
        flex: 1,
        position: 'relative',
        backgroundColor: constants?.color?.white1,
      }}>
      <ScrollView nativeID="homeScroll" testID="homeScroll">
        <>
          <HomeLandingMapWrapper />
          <HuntSearchView />
          {/* brining in everyhing from scavenger hunts and down */}
          <HomeActivityLists />
        </>
      </ScrollView>
    </View>
  );
};

const HomeLandingMapWrapper = () => {
  const homeSearchText = useTypedSelector(
    (state) => state.home?.homeSearchText,
  );
  if (homeSearchText) {
    return <></>;
  }
  console.log('HomeLandingMapWrapper render');

  return (
    <DelayedLoad delay={100} loadingStyle={{height: 200}}>
      <HomeLandingMap isModal={false} />
    </DelayedLoad>
  );
};

export default HomeLandingTab;

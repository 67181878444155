import * as t from 'io-ts';
import {THuntIO} from 'src/types/THunt';
import {validateResult} from 'src/types/typesHelpers';

// Assuming you've imported HuntIO from before as:
// import { HuntIO } from 'path-to-your-hunt-definition';
const TEventGroupScoreIO = t.partial({
  points: t.union([t.number, t.null]),
  groupId: t.string,
});

// io-ts type for EventInfo
const TEventInfoIO = t.intersection([
  THuntIO, // This extends the HuntIO type
  t.type({
    eventId: t.string,
  }),
  t.partial({
    company: t.string,
    groupType: t.string,
    locateTeamsOff: t.boolean,
    teamPhotosOff: t.boolean,
    groupScores: t.union([t.record(t.string, TEventGroupScoreIO), t.null]),
    companyURL: t.string,
    packageType: t.string,
    allRoutes: t.array(t.record(t.string, t.any)),
    hostName: t.union([t.string, t.null]),
    appCurrentScreenOverride: t.union([t.string, t.null]), // used to override screen by hosts
    hostOnline: t.union([t.boolean, t.number]), // used to show if hosts are online
    videoHostOnlineUnix: t.union([t.number, t.number]),
  }),
]);

// Derive the TypeScript type from the io-ts type
export type TEventInfo = t.TypeOf<typeof TEventInfoIO>;

// Type guard for runtime validation
export const isTEventInfo = (
  input: unknown,
  showErrors: boolean,
): input is TEventInfo => {
  return validateResult(input, TEventInfoIO, 'TEventInfo', showErrors);
};

import useTypedSelector from 'src/Hooks/useTypedSelector';
import React, {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import {setHunts, setHuntsList} from 'src/Redux/reducers/hunts.reducer';
import {setThemes} from 'src/Redux/reducers/themes.reducer';
import {saveBackupRegion} from 'src/Redux/reducers/user_location.reducer';
import {apiGetAllHunts, apiGetHuntThemes} from 'src/Utils/apiCalls';
import {orderHuntsByProximity} from 'src/Utils/distanceHelpers';
import {isTThemesState} from 'src/types/TTheme';
import {THunt} from 'src/types/THunt';
import {dispatchAction} from 'src/Utils/helpers';

const HuntsController: React.FC = () => {
  console.log('HuntsController render');
  // Getting data

  const homeSearchText = useTypedSelector((state) => state.home.homeSearchText);
  const roundedLat = useTypedSelector(
    (state) => state.location.roundedRegion?.latitude,
  );
  const roundedLong = useTypedSelector(
    (state) => state.location.roundedRegion?.longitude,
  );

  console.log('HuntsController', {
    roundedLat,
    homeSearchText,
    roundedLong,
  });
  const hunts = useTypedSelector((state) => state.hunts.hunts);

  // Fetching hunt list
  useQuery({
    queryKey: [`scavengerHunts`],
    queryFn: async () => {
      const huntData = await apiGetAllHunts();
      if (!huntData) {
        console.error('apiGetAllHunts failed');
        throw new Error('no local locations');
      } else {
        console.log('apiGetAllHunts succeeded');

        if (JSON.stringify(hunts) !== JSON.stringify(huntData)) {
          console.log('updating hunts');
          dispatchAction(setHunts(huntData));
        }
      }
      return huntData;
    },
  });

  useEffect(() => {
    if (!hunts) return;
    let sortedHunts: THunt[] = orderHuntsByProximity(hunts, {
      latitude: roundedLat ?? 0,
      longitude: roundedLong ?? 0,
      latitudeDelta: 0.05,
      longitudeDelta: 0.05,
    });

    if (homeSearchText) {
      console.log(sortedHunts.length);
      sortedHunts =
        sortedHunts.filter((hunt) => {
          // console.log('hunt: ', hunt)
          const casedText =
            typeof homeSearchText == 'string'
              ? homeSearchText?.toLowerCase?.()
              : homeSearchText;

          if (!casedText) {
            return hunt;
          }
          const city =
            typeof hunt.city == 'string'
              ? hunt.city?.toLowerCase?.()
              : hunt.city;
          const name =
            typeof hunt.name == 'string'
              ? hunt.name?.toLowerCase?.()
              : hunt.name;
          const state =
            typeof hunt.state == 'string'
              ? hunt.state?.toLowerCase?.()
              : hunt.state;

          // Filtering hunts by type
          // If there is a search
          if (
            (casedText?.includes('indoor') || casedText?.includes('in home')) &&
            hunt.hunt_type == 'inHome'
          ) {
            console.log(hunt, 'YESSSS');
            if (
              !hunt?.name?.includes('Office') &&
              !hunt?.name?.includes('Team')
            ) {
              return hunt;
            }
          }
          if (casedText?.includes('outdoor') && hunt.hunt_type == 'scavaHunt') {
            return hunt;
          }

          if (casedText?.includes('bar h') && hunt.hunt_type == 'barHunt') {
            return hunt;
          }

          if (casedText?.includes('art') && hunt.hunt_type == 'artWalk') {
            return hunt;
          }

          if (casedText?.includes('ghost') && hunt.hunt_type == 'ghostHunt') {
            return hunt;
          }

          if (casedText?.includes('zoo') && hunt.hunt_type == 'zooHunt') {
            return hunt;
          }

          if (name?.includes?.(casedText)) {
            return hunt;
          }

          if (state?.includes?.(casedText)) {
            return hunt;
          }

          if (city?.includes?.(casedText)) {
            return hunt;
          }
        }) || [];
    }
    dispatchAction(setHuntsList(sortedHunts));
  }, [JSON.stringify(hunts || {}), roundedLat, roundedLong, homeSearchText]);

  // Getting hunt themes
  useEffect(() => {
    apiGetHuntThemes().then((themeData) => {
      console.log('==========================');
      console.log('theme data loaded');
      console.log('==========================');
      if (themeData && typeof themeData === 'object') {
        const isValidThemeData = isTThemesState(themeData);
        console.log('setting the theme data', {themeData, isValidThemeData});

        if (isValidThemeData) {
          dispatchAction(setThemes(themeData));
        } else {
          console.error('Invalid Theme Data');
        }
      }

      // additional geolocation data that is sent in the api call
      if (
        'geo_location' in themeData &&
        !!themeData?.geo_location &&
        typeof themeData?.geo_location == 'object' &&
        'lat' in themeData?.geo_location &&
        'long' in themeData?.geo_location &&
        !isNaN(Number(themeData?.geo_location?.lat)) &&
        !isNaN(Number(themeData?.geo_location?.long))
      ) {
        dispatchAction(
          saveBackupRegion({
            lat: Number(themeData?.geo_location?.lat),
            long: Number(themeData?.geo_location?.long),
          }),
        );
      }
    });
  }, []);

  return <></>;
};

export default HuntsController;

export const UserProfileChallenges: string[] = [
  'Random Challenge: Capture a profile photo of you dancing.',
  'Random Challenge: Take a profile photo of you looking your best.',
  'Random Challenge: Pose for your profile photo as though modeling for a statue.',
  'Random Challenge: Take a profile photo of you making a WILD face.',
  'Random Challenge: Take a profile photo of you being shocked.',
  'Random Challenge: Get a profile pic of you towering over something in the distance.',
  'Random Challenge: Take a profile pic of you channeling your spirit animal',
  'Random Challenge: Take a profile pic of you looking fit to meet the Queen.',
];

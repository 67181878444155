import React from 'react';

import {View, Text, TouchableOpacity, Platform} from 'react-native';

import constants from 'src/constants';
import useTypedSelector from 'src/Hooks/useTypedSelector';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {navigationRef} from 'src/Nav/navigationHelpers';

// importing detox
let isDetoxSync: () => boolean;
if (Platform.OS !== 'web') {
  const detoxModule = require('react-native-is-detox');
  isDetoxSync = detoxModule.isDetoxSync;
} else {
  isDetoxSync = () => false;
}

const HeaderErrorWarning: React.FC = () => {
  return <></>;
  const hasGPSLocationData = useTypedSelector(
    (state) => state.location?.hasGPSLocationData,
  );

  const isOnLoadingScreens = useTypedSelector(
    (state) =>
      [
        'AppLoadingPage',
        'AppIntroSwiper',
        'LoginPage',
        'ModalStack',
        'LoginModalTextForm',
        'LoginAdditionalInformationForm',
        'LoginAdditionalInfoFullScreenPage',
        'FreeTrialScreen',
      ]?.includes(state.current_screen?.currentScreen) ||
      !state.current_screen?.currentScreen,
  );

  const errorsLength =
    useTypedSelector((state) => state.logs?.errorLogs?.length) || 0;
  const isDetox = isDetoxSync();

  let message: string | null = null;

  const {top} = useSafeAreaInsets();

  // console.log({errorLogs});

  if (errorsLength && __DEV__ && Platform.OS !== 'web') {
    message = `${errorsLength} Errors`;
  }

  // if (
  //   !hasGPSLocationData &&
  //   Platform.OS != 'web' &&
  //   !isDetox &&
  //   !playTestState &&
  //   !isOnLoadingScreens &&
  //   Platform.OS == 'ios'
  // ) {
  //   message =
  //     'Warning: Location services are off \nPlease enable location permissions by clicking here';
  //   hasLocationError = true;
  // } else {
  //   hasLocationError = false;
  // }

  console.log('HeaderErrorWarning render', {
    hasGPSLocationData,
    message,
    errorLogsLength: errorsLength,
    isDetox,
    isOnLoadingScreens,
  });

  if (!message) {
    return <View />;
  }

  return (
    <TouchableOpacity
      testID={'errorMessage'}
      onPress={() =>
        navigationRef.navigate(
          'ShowLogsModal',
          null,
          'HeaderComponent/HeaderErrorWarning.tsx',
        )
      }
      style={{
        width: '100%',
        backgroundColor: 'red',
        paddingTop: top,
      }}>
      <Text
        style={{
          color: 'white',
          textAlign: 'center',
          marginTop: 3,
          marginBottom: 3,
          fontSize: 14,
          fontFamily: constants?.font.circular,
        }}>
        {message}
      </Text>
    </TouchableOpacity>
  );
};

export default HeaderErrorWarning;

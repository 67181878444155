import * as t from 'io-ts';
import {validateResult} from 'src/types/typesHelpers';

const TReviewIO = t.partial({
  OS: t.string,
  huntSocialBonusDone: t.boolean,
  r_app: t.number,
  r_hunt: t.number,
});

const TBonusIO = t.partial({
  bonusId: t.string,
  bonusType: t.string,
  points: t.number,
  pointsEarned: t.number,
});

const TBonusesIO = t.record(t.string, TBonusIO);

// io-ts type for GroupPlayer
export const TGroupPlayerIO = t.intersection([
  t.type({}),
  t.partial({
    userId: t.string,
    payingUserId: t.string,
    parentEmail: t.union([t.string, t.null]),
    parentUserId: t.string,
    firstName: t.string,
    groupId: t.string,
    photoColor: t.union([t.string, t.null]),
    OS: t.union([t.string, t.null]),
    timeAdded: t.number,
    questionType: t.union([t.string, t.null]),
    character: t.union([t.string, t.null]),
    question_type: t.union([t.string, t.null]),
    points: t.union([t.number, t.null]),
    achievements: t.union([t.array(t.string), t.null]),
    bonuses: TBonusesIO,
    reviews: TReviewIO,
    photoUrl: t.union([t.string, t.null]),
    hasReviewedHybridCharacter: t.union([t.boolean, t.null]),
    hasSeenHybridCharacterList: t.union([t.boolean, t.null]),
    // reminder
    // this is the group player object
    // TGroupInfo is below
  }),
]);

// Derive the TypeScript type from the io-ts type for GroupPlayer
export type TGroupPlayer = t.TypeOf<typeof TGroupPlayerIO>;

// for hybrid murder mysteries
export const TCustomCharacterIO = t.type({
  name: t.string,
  description: t.string,
  intro: t.string,
  image: t.string,
  title: t.string,
  secret: t.string,
});
export type TCustomCharacter = t.TypeOf<typeof TCustomCharacterIO>;

// io-ts type for GroupInfo

// Using intersection to combine mandatory and optional properties for GroupInfo
const TGroupInfoIO = t.intersection([
  t.type({
    groupId: t.string,
    hunt_v2: t.boolean,
    hunt_v3: t.boolean,
    hunt_v4: t.boolean,
    teamName: t.string,
    isBarHunt: t.boolean,
    huntType: t.union([t.string, t.null]),
    theme: t.string,
    createdBy: t.union([t.string, t.null]),
    discoverable: t.boolean,
    huntName: t.string,
    huntCity: t.string,
    dateTimeEventCreated: t.number,
    dateEventCreated: t.string,
    startingLocation: t.string,
    isCoOp: t.boolean, // Assuming integer values like 1, etc.
    isTimed: t.boolean,
    isSocial: t.boolean,
    huntLength: t.string,
    huntStarted: t.boolean,
  }),
  t.partial({
    players: t.record(t.string, TGroupPlayerIO), // Since structure of player is not provided
    createdByName: t.union([t.string, t.null]),
    classic_hunt: t.union([t.boolean, t.null]),
    deleted: t.union([t.boolean, t.number, t.null]),
    huntId: t.union([t.string, t.null]), //not defined for corporate hunts not based on a live hunt
    indoor_hunt: t.union([t.boolean, t.null]),
    joinCode: t.string,
    prePaidJoinCode: t.union([t.string, t.null]),
    huntType: t.union([t.string, t.null]),
    prePaidPlayers: t.union([t.number, t.null]), // Assuming it's a number, modify if different
    eventDate: t.union([t.number, t.null]), // Assuming it's a timestamp, modify if different
    isFree: t.union([t.boolean, t.null]),
    passId: t.union([t.string, t.null]),
    passPlayers: t.union([t.number, t.string, t.null]),
    routeId: t.union([t.string, t.null]),
    routeIdOverride: t.union([t.string, t.null]),
    city: t.union([t.string, t.null]),
    lat: t.union([t.number, t.null]),
    long: t.union([t.number, t.null]),
    huntPhoto: t.union([t.string, t.null]),
    route: t.union([t.number, t.null]),
    description: t.string,
    position: t.union([
      t.record(
        t.string,
        t.union([t.number, t.string, t.record(t.string, t.any)]),
      ),
      t.null,
    ]), // Since structure is not provided
    completionData: t.union([t.record(t.string, t.string), t.null]), // Since structure is not provided
    huntVersion: t.string,
    huntIntroDone: t.union([t.boolean, t.null]),
    eventId: t.union([t.string, t.null]),
    eventStartTime: t.union([t.string, t.null]),
    eventEndTime: t.union([t.string, t.null]),
    score: t.union([t.number, t.null]),
    huntPaused: t.union([t.boolean, t.null]),
    huntPausedTime: t.union([t.number, t.null]),
    huntFinished: t.union([t.boolean, t.null]),
    voucher_code: t.union([t.string, t.null]),
    groupPhoto: t.union([t.string, t.null]), // the group photo taken at the beginning of the hunt
    rank: t.union([t.number, t.null]), // what is there current rank
    points: t.record(t.string, t.number), // individual point contributions
    rankAtFinish: t.union([t.number, t.null]), // what place did they finish in
    easyGroupLevelJoinCode: t.union([t.string, t.null]), // used for joining, just group id but shorter
    barHunt: t.union([t.boolean, t.null]), // is it a bar hunt
    playBackSpeed: t.union([t.number, t.null]), // audio tour setting
    currentAudioTourIndex: t.union([t.number, t.null]), // audio tour only, what stop are they on?
    autoHideText: t.union([t.boolean, t.null]), // audio tour setting for hiding text when audio playing
    place: t.union([t.number, t.null]), // only used for corporate groups for list ranks
    backgroundColor: t.union([t.string, t.null]), // only used for corporate groups for list ranks
    ordinal_place: t.union([t.number, t.null]),
    customCharacters: t.union([t.record(t.string, TCustomCharacterIO), t.null]),
    huntTimeExtendedCount: t.union([t.number, t.null]),
  }),
]);

// Derive the TypeScript type from the io-ts type for GroupInfo
export type TGroupInfo = t.TypeOf<typeof TGroupInfoIO>;

// Type guard for runtime validation for GroupPlayer
export const isTGroupPlayer = (input: unknown): input is TGroupPlayer => {
  return validateResult(input, TGroupPlayerIO, 'TGroupPlayer', false);
};

// Type guard for runtime validation for GroupInfo
export const isTGroupInfo = (
  input: unknown,
  logErrors: boolean,
): input is TGroupInfo => {
  return validateResult(input, TGroupInfoIO, 'TGroupInfo', logErrors);
};

import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {ReactNode} from 'react';
import {View, ScrollView} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import Icon from 'react-native-vector-icons/FontAwesome';
import {DealCard, BodyText, ContinueButton} from 'src/Modules/CommonView';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';

const memberImage = {
  uri: cloudFlareOptimizeImage(
    'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648577715_large.jpg',
  ),
};

const AboutExplorerPass: React.FC = () => {
  const {width} = useWindowDimensionsSafe();
  const userId = useTypedSelector((state) => state.user?.userId);

  const handlePurchasePress = () => {
    if (!userId) {
      return navigationRef.navigate(
        'LoginModal',
        {
          message: 'You must log in to get an explorer pass',
          navigateOnCloseRoute: 'ExplorerPassSubscriptionsListModal',
        },
        'AboutExplorerPass 1234',
      );
    }
    navigationRef.navigate(
      'ExplorerPassSubscriptionsListModal',
      null,
      'ExplorerPass/AboutExplorerPass.tsx',
    );
  };

  const numberOfCities = useTypedSelector((state) => {
    const huntsLength =
      Math.round(state?.hunts?.huntsList?.length / 100) * 100 || 400;
    return huntsLength;
  });

  return (
    <ScrollView style={{flex: 1}}>
      <View style={{marginTop: 5}}>
        <DealCard
          title={'Get it All'}
          description={'Explore & Connect Around the World'}
          buttonText={'Become A Member'}
          minHeight={150}
          color={constants?.color?.orange}
          imageSource={memberImage}
          handlePress={handlePurchasePress}
        />

        <RowWrap>
          <RowIcon icon={'compass'} color={constants?.color?.orange} />
          <View style={{width: width - 100}}>
            <BodyText
              text={`Explore ${numberOfCities}+ cities around the world`}
              textStyle={{marginLeft: 0, fontWeight: '700'}}
            />
            <View style={{flexDirection: 'row'}}>
              <>
                <BulletItem
                  text={
                    'Get unlimited access to Scavenger Hunts, Bar Hunts, and Ghost Tours'
                  }
                />
                <BulletItem text={'Unlock member pricing on large events'} />
                <BulletItem text={'Multiple activities in most cities'} />
              </>
            </View>
          </View>
        </RowWrap>
        <RowWrap>
          <RowIcon icon={'star'} color={constants?.color?.yellow} />
          <View style={{width: width - 100}}>
            <BodyText
              text={'Bring Your Friends'}
              textStyle={{marginLeft: 0, fontWeight: '700'}}
            />
            <View style={{flexDirection: 'row'}}>
              <>
                <BulletItem text={'Invite up to 5 friends every hunt'} />

                <BulletItem text={'Bring different friends every time'} />
              </>
            </View>
          </View>
        </RowWrap>

        <RowWrap>
          <RowIcon icon={'flag'} color={constants?.color?.blue} />
          <View style={{width: width - 100}}>
            <BodyText
              text={'Unlock Real Prizes'}
              textStyle={{marginLeft: 0, fontWeight: '700'}}
            />
            <View style={{flexDirection: 'row'}}>
              <View>
                <BulletItem
                  text={'Unlock physical prizes for completing hunts'}
                />

                <BulletItem text={'Win shirts, swag, and more'} />
              </View>
            </View>
          </View>
        </RowWrap>

        <View
          style={{
            backgroundColor: constants?.color?.white1,
            flex: 1,
            minHeight: 100,
            paddingBottom: 60,
            paddingTop: 20,
          }}>
          <View style={{alignItems: 'center'}}>
            <ContinueButton
              text={'See Explorer Pass Options'}
              handlePress={handlePurchasePress}
              containerStyle={{width: 260}}
            />
            <ContinueButton
              text={'See Individual Ticket Options'}
              handlePress={() => {
                if (!userId) {
                  return navigationRef.navigate(
                    'LoginModal',
                    {
                      navigateOnCloseRoute: 'PurchaseModal',
                      message: 'You must log in to get tickets',
                    },
                    'AboutExplorerPass 1234',
                  );
                }
                navigationRef.navigate(
                  'PurchaseModal',
                  {},
                  'ExplorerPass/AboutExplorerPass.tsx',
                );
              }}
              containerStyle={{width: 260}}
              background={constants?.color?.gray3}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const RowWrap = ({children}: {children: ReactNode}) => {
  const {width} = useWindowDimensionsSafe();
  return (
    <View
      style={{
        alignItems: 'center',
        backgroundColor: constants?.color?.white,
        borderRadius: 10,
        marginVertical: 5,
        marginHorizontal: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 10,
        paddingHorizontal: 5,
        paddingVertical: 5,
        width: width - 20,
      }}>
      {children}
    </View>
  );
};

const RowIcon = ({icon, color}: {icon: string; color: string}) => {
  return (
    <View
      style={{
        width: 70,
        height: 70,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Icon name={icon} size={40} color={color} />
    </View>
  );
};

export const BulletItem = ({
  text,
  check,
  fontSize = 14,
  marginBottom = 0,
}: {
  text: string;
  check?: boolean;
  fontSize?: number;
  marginBottom?: number;
}) => {
  return (
    <View style={{flexDirection: 'row', marginBottom}}>
      <BodyText
        text={check ? '\u2713' : '\u2022'}
        textStyle={{
          fontWeight: '700',
          marginHorizontal: 0,
        }}
      />
      <BodyText
        text={text}
        textStyle={{
          marginLeft: 5,
          fontSize,
        }}
      />
    </View>
  );
};

export default AboutExplorerPass;

import React from 'react';
import {Alert, Linking} from 'react-native';
import * as MediaLibrary from 'expo-media-library';

/**
 * Asynchronously saves an image or video to the device's camera roll.
 * @param {string} uri - The URI of the image or video to be saved.
 * @param {string} type - The type of the media ('photo' or 'video').
 * @param {boolean} showOutcome - Whether to show the outcome in an alert.
 */

export const saveLocalMediaToCameraRoll = async (uri: string) => {
  const response = await MediaLibrary.requestPermissionsAsync();
  console.log('saveLocalMediaToCameraRoll', {response, uri});

  const saveResponse = await MediaLibrary.saveToLibraryAsync(uri);

  return console.log('photo saved', {saveResponse});
};

export const saveToCameraRoll = async (
  uri: string,
  type: 'photo' | 'video' = 'photo',
  showOutcome = false,
) => {
  console.log('saveToCameraRoll', {uri});

  try {
    const response = await MediaLibrary.requestPermissionsAsync();
    console.log('save photo', {response, uri, showOutcome});

    if (response?.status === 'granted') {
      const mediaResponse = await fetch(uri);
      const blob = await mediaResponse.blob();
      const localURL = URL.createObjectURL(blob);

      console.log('saving photo!!', {localURL});

      const saveSuccess = await MediaLibrary.saveToLibraryAsync(localURL);

      console.log(saveSuccess);

      if (showOutcome) {
        Alert.alert(`${type === 'video' ? 'Video' : 'Photo'} Saved`);
      }
    } else {
      Alert.alert(
        'You need to enable photo permissions',
        'Please enable photo permissions in your device settings in order to download your content',
        [
          {
            text: 'Cancel',
            onPress: () => console.log('Cancel Pressed'),
            style: 'cancel',
          },
          {
            text: 'Settings',
            onPress: () =>
              Linking?.openSettings?.().catch((error) =>
                console.warn('cannot open settings', error),
              ),
          },
        ],
      );
    }
  } catch (error: unknown) {
    console.log(error);
  }
};
